// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/leaderboards/leaderboards/v1/leaderboards.proto" (package "sparx.leaderboards.leaderboards.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Leaderboards } from './leaderboards';
import type { GetLeaderboardForTeacherRequest } from './leaderboards';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetLeaderboardResponse } from './leaderboards';
import type { GetLeaderboardRequest } from './leaderboards';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.leaderboards.leaderboards.v1.Leaderboards
 */
export interface ILeaderboardsClient {
  /**
   * GetLeaderboard returns the requested leaderboard for the specified student and product
   *
   * Only students that have not opted out of leaderboards for that product will be returned. The
   * calling student's id will be returned but all other ids will be blanked. Students are
   * returned in leaderboard order, with the calling student first of any tied students.
   *
   * @generated from protobuf rpc: GetLeaderboard(sparx.leaderboards.leaderboards.v1.GetLeaderboardRequest) returns (sparx.leaderboards.leaderboards.v1.GetLeaderboardResponse);
   */
  getLeaderboard(
    input: GetLeaderboardRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeaderboardRequest, GetLeaderboardResponse>;
  /**
   * GetLeaderboardForTeacher returns the requested leaderboard for the specified product
   *
   * Only students that have not opted out of leaderboards for that product will be returned. All
   * student ids will be returned.
   *
   * @generated from protobuf rpc: GetLeaderboardForTeacher(sparx.leaderboards.leaderboards.v1.GetLeaderboardForTeacherRequest) returns (sparx.leaderboards.leaderboards.v1.GetLeaderboardResponse);
   */
  getLeaderboardForTeacher(
    input: GetLeaderboardForTeacherRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeaderboardForTeacherRequest, GetLeaderboardResponse>;
}
/**
 * @generated from protobuf service sparx.leaderboards.leaderboards.v1.Leaderboards
 */
export class LeaderboardsClient implements ILeaderboardsClient, ServiceInfo {
  typeName = Leaderboards.typeName;
  methods = Leaderboards.methods;
  options = Leaderboards.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetLeaderboard returns the requested leaderboard for the specified student and product
   *
   * Only students that have not opted out of leaderboards for that product will be returned. The
   * calling student's id will be returned but all other ids will be blanked. Students are
   * returned in leaderboard order, with the calling student first of any tied students.
   *
   * @generated from protobuf rpc: GetLeaderboard(sparx.leaderboards.leaderboards.v1.GetLeaderboardRequest) returns (sparx.leaderboards.leaderboards.v1.GetLeaderboardResponse);
   */
  getLeaderboard(
    input: GetLeaderboardRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeaderboardRequest, GetLeaderboardResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetLeaderboardRequest, GetLeaderboardResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetLeaderboardForTeacher returns the requested leaderboard for the specified product
   *
   * Only students that have not opted out of leaderboards for that product will be returned. All
   * student ids will be returned.
   *
   * @generated from protobuf rpc: GetLeaderboardForTeacher(sparx.leaderboards.leaderboards.v1.GetLeaderboardForTeacherRequest) returns (sparx.leaderboards.leaderboards.v1.GetLeaderboardResponse);
   */
  getLeaderboardForTeacher(
    input: GetLeaderboardForTeacherRequest,
    options?: RpcOptions,
  ): UnaryCall<GetLeaderboardForTeacherRequest, GetLeaderboardResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetLeaderboardForTeacherRequest,
      GetLeaderboardResponse
    >('unary', this._transport, method, opt, input);
  }
}
