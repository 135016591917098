import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useClearAlert } from 'components/alert/alert';
import { Button } from 'components/buttons/button';
import { AnalyticEventFields } from 'components/client-events/client-event-provider';
import styles from 'components/tasks/panel.module.css';
import { FCWithOptionalChildren } from 'utils/props';

interface IConfirmAlertProps {
  title: string;
  onConfirm: () => void;
  rejectText?: string;
  confirmText?: string;
  size?: 'small' | 'medium' | 'large';
  closeOnConfirm?: boolean;
  confirmDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  // Analytics event to send on confirm.
  confirmAnalytics: AnalyticEventFields | undefined;
  // Analytics event to send on cancel.
  rejectAnalytics: AnalyticEventFields | undefined;
}

export const ConfirmAlert: FCWithOptionalChildren<IConfirmAlertProps> = ({
  title,
  onConfirm,
  rejectText,
  confirmText,
  children,
  size,
  closeOnConfirm,
  confirmDisabled,
  onClick,
  confirmAnalytics,
  rejectAnalytics,
}) => {
  const close = useClearAlert();
  return (
    <div
      className={classNames(
        styles.ConfirmAlert,
        size === 'medium' && styles.ConfirmAlertMedium,
        size === 'large' && styles.ConfirmAlertLarge,
      )}
      onClick={onClick}
    >
      <div className={styles.ExitButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2 className={styles.ModalTitle}>{title}</h2>
      {children}
      <div className={styles.Buttons}>
        <Button variant="secondary" onClick={close} analyticsEvent={rejectAnalytics}>
          {rejectText || 'No'}
        </Button>
        <Button
          color="danger"
          autoFocus={true}
          disabled={confirmDisabled}
          onClick={() => {
            if (!confirmDisabled) {
              onConfirm();
              closeOnConfirm && close();
            }
          }}
          analyticsEvent={confirmAnalytics}
        >
          {confirmText || 'Yes'}
        </Button>
      </div>
    </div>
  );
};
