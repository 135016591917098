// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/notifications/v1/notifications.proto" (package "sparx.reading.users.notifications.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Notifications } from './notifications';
import type { SetNotificationStatusResponse } from './notifications';
import type { SetNotificationStatusRequest } from './notifications';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetNotificationsResponse } from './notifications';
import type { GetNotificationsRequest } from './notifications';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.users.notifications.v1.Notifications
 */
export interface INotificationsClient {
  /**
   * @generated from protobuf rpc: GetNotifications(sparx.reading.users.notifications.v1.GetNotificationsRequest) returns (sparx.reading.users.notifications.v1.GetNotificationsResponse);
   */
  getNotifications(
    input: GetNotificationsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetNotificationsRequest, GetNotificationsResponse>;
  /**
   * SetNotificationStatus marks a given notification as read. Does nothing
   * if called multiple times for the same notification.
   *
   * @generated from protobuf rpc: SetNotificationStatus(sparx.reading.users.notifications.v1.SetNotificationStatusRequest) returns (sparx.reading.users.notifications.v1.SetNotificationStatusResponse);
   */
  setNotificationStatus(
    input: SetNotificationStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<SetNotificationStatusRequest, SetNotificationStatusResponse>;
}
/**
 * @generated from protobuf service sparx.reading.users.notifications.v1.Notifications
 */
export class NotificationsClient implements INotificationsClient, ServiceInfo {
  typeName = Notifications.typeName;
  methods = Notifications.methods;
  options = Notifications.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetNotifications(sparx.reading.users.notifications.v1.GetNotificationsRequest) returns (sparx.reading.users.notifications.v1.GetNotificationsResponse);
   */
  getNotifications(
    input: GetNotificationsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetNotificationsRequest, GetNotificationsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetNotificationsRequest, GetNotificationsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * SetNotificationStatus marks a given notification as read. Does nothing
   * if called multiple times for the same notification.
   *
   * @generated from protobuf rpc: SetNotificationStatus(sparx.reading.users.notifications.v1.SetNotificationStatusRequest) returns (sparx.reading.users.notifications.v1.SetNotificationStatusResponse);
   */
  setNotificationStatus(
    input: SetNotificationStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<SetNotificationStatusRequest, SetNotificationStatusResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SetNotificationStatusRequest,
      SetNotificationStatusResponse
    >('unary', this._transport, method, opt, input);
  }
}
