import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { getImageURL } from 'api';
import classNames from 'classnames';
import { RatingImage } from 'components/buttons/rating-button';
import { difficultyRatings } from 'components/rating/happinessrating';
import { useAtom } from 'jotai';

import { selectedDifficultyAtom, selectedRatingAtom } from '../atoms';
import { Stars } from '../interactive/stars/stars';
import styles from './book-cover.module.css';

type Props = {
  bookMetadata: MetadataAbridged;
};

export const BookCover = ({ bookMetadata }: Props) => {
  const [selectedRating] = useAtom(selectedRatingAtom);
  const [selectedDifficulty] = useAtom(selectedDifficultyAtom);

  const bookCover =
    bookMetadata.coverImage?.source.oneofKind === 'name'
      ? getImageURL(bookMetadata.name, bookMetadata.coverImage.source.name)
      : '';

  const difficultyConfig = selectedDifficulty ? difficultyRatings[selectedDifficulty] : undefined;

  return (
    <div className={styles.Book}>
      {selectedRating !== undefined && difficultyConfig && (
        <div className={styles.RatingsContainer}>
          <Stars rating={selectedRating} />

          <div className={styles.DifficultyRating}>
            <RatingImage image={difficultyConfig.image} />
          </div>
        </div>
      )}
      <div className={classNames(styles.Page, styles.Back)} />
      <div className={classNames(styles.Page, styles.Page6)} />
      <div className={classNames(styles.Page, styles.Page5)} />
      <div className={classNames(styles.Page, styles.Page4)} />
      <div className={classNames(styles.Page, styles.Page3)} />
      <div className={classNames(styles.Page, styles.Page2)} />
      <div className={classNames(styles.Page, styles.Page1)} />
      <div
        className={classNames(styles.Page, styles.Front)}
        style={{ '--cover-image-url': `url(${bookCover})` } as React.CSSProperties}
      />
    </div>
  );
};
