import { INotificationWrapperProps, Notification } from './notification';

export const GoldReaderDisabledNotification: React.FC<INotificationWrapperProps> = ({
  notification,
}) => {
  return (
    <Notification
      notification={notification}
      title={'Your teacher has disabled Gold Reader.'}
      hideClickMessage
    />
  );
};
