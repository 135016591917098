import classNames from 'classnames';

import styles from './homework-progress-bar.module.css';

type Props = {
  completedCount: number;
  startedCount: number;
  notStartedCount: number;
};

export const HomeworkProgressBar = ({ completedCount, startedCount, notStartedCount }: Props) => {
  const totalCount = completedCount + startedCount + notStartedCount;
  const completedPercent = (completedCount / totalCount) * 100;
  const startedPercent = (startedCount / totalCount) * 100;
  const notStartedPercent = (notStartedCount / totalCount) * 100;

  return (
    <div className={styles.Container}>
      <div className={styles.CountContainer}>
        {completedCount > 0 && (
          <div className={styles.Section} style={{ width: completedPercent + '%' }}>
            {completedCount}
          </div>
        )}
        {startedCount > 0 && (
          <div className={styles.Section} style={{ width: startedPercent + '%' }}>
            {startedCount}
          </div>
        )}
        {notStartedCount > 0 && (
          <div
            className={classNames(styles.Section, styles.NotStartedCount)}
            style={{ width: notStartedPercent + '%' }}
          >
            {completedCount === 0 && startedCount == 0 && <div className={styles.TextLeft}>0</div>}
            <div>{notStartedCount}</div>
          </div>
        )}
      </div>

      <div className={styles.BarContainer}>
        {completedCount > 0 && (
          <div
            className={classNames(styles.Section, styles.CompletedSection)}
            style={{ width: completedPercent + '%' }}
          />
        )}
        {startedCount > 0 && (
          <div
            className={classNames(styles.Section, styles.StartedSection)}
            style={{ width: startedPercent + '%' }}
          />
        )}
      </div>
    </div>
  );
};
