import { Link } from 'components/link';

import styles from './shared.module.css';

export const Reset = () => {
  return (
    <>
      <div className={styles.Box}>
        <p>You&apos;ve now reset your Sparx Reader site for the new academic year.</p>

        <p>
          We recommend that you proceed to{' '}
          <Link
            to="/teacher/new-year-setup/import-classes-and-students"
            analyticsEvent={undefined}
            className={styles.Link}
          >
            import your class and students
          </Link>{' '}
          if you haven&apos;t done so already.
        </p>
      </div>
    </>
  );
};
