// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/feedback/v1/feedback.proto" (package "sparx.reading.feedback.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { SentimentRating } from '../../../interaction/feedback/v1/feedback';
/**
 * @generated from protobuf message sparx.reading.feedback.v1.SubmitUserSentimentFeedbackRequest
 */
export interface SubmitUserSentimentFeedbackRequest {
  /**
   * The users sentiment for feedback.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.SentimentRating sentiment = 1;
   */
  sentiment: SentimentRating;
  /**
   * A user entered comment containing the feedback. May be blank.
   *
   * @generated from protobuf field: string comment = 2;
   */
  comment: string;
  /**
   * Additional context from the event or interaction
   *
   * @generated from protobuf field: map<string, string> labels = 3;
   */
  labels: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.SubmitUserSentimentFeedbackResponse
 */
export interface SubmitUserSentimentFeedbackResponse {}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.GetTeacherSurveyRequest
 */
export interface GetTeacherSurveyRequest {
  /**
   * The identifier for the survey, e.g. 'reading-matters-pre-course', 'reading-matters-post-course'.
   *
   * @generated from protobuf field: string survey_title = 1;
   */
  surveyTitle: string;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.TeacherSurvey
 */
export interface TeacherSurvey {
  /**
   * A UUID that's unique to the (survey_title, user_id) pair, i.e.
   * representing a user's answers to a specific survey.
   *
   * @generated from protobuf field: string survey_id = 1;
   */
  surveyId: string;
  /**
   * The identifier for a given survey, e.g. 'reading-matters-pre-course'.
   *
   * @generated from protobuf field: string survey_title = 6;
   */
  surveyTitle: string;
  /**
   * The user ID of the survey.
   *
   * @generated from protobuf field: string user_id = 2;
   */
  userId: string;
  /**
   * The questions and answers given by the user in this survey.
   *
   * @generated from protobuf field: repeated sparx.reading.feedback.v1.TeacherSurveyResponse responses = 3;
   */
  responses: TeacherSurveyResponse[];
  /**
   * The time the user submitted their first response to the survey.
   *
   * @generated from protobuf field: google.protobuf.Timestamp started_time = 4;
   */
  startedTime?: Timestamp;
  /**
   * The time the user submitted the final response to the survey.
   *
   * @generated from protobuf field: optional google.protobuf.Timestamp finished_time = 5;
   */
  finishedTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.TeacherSurveyResponse
 */
export interface TeacherSurveyResponse {
  /**
   * Will exactly match the parent TeacherSurvey survey_id: a link between a user and a given survey.
   *
   * @generated from protobuf field: string survey_id = 1;
   */
  surveyId: string;
  /**
   * An ordered identifier for a grouping of questions in a survey. Can be
   * asked on separate pages of the survey and submitted separately.
   *
   * @generated from protobuf field: int32 section = 2;
   */
  section: number;
  /**
   * The order in which the questions were displayed in the section.
   *
   * @generated from protobuf field: int32 sequence = 3;
   */
  sequence: number;
  /**
   * The question text recorded at the time the user was asked the question.
   *
   * @generated from protobuf field: string question = 4;
   */
  question: string;
  /**
   * The user's answer to the question.
   *
   * @generated from protobuf field: string answer = 5;
   */
  answer: string;
  /**
   * The type of question asked. One of 'multi-choice', 'radio', 'rating', 'ranking'.
   *
   * @generated from protobuf field: string type = 6;
   */
  type: string;
  /**
   * The time that this section of questions was submitted.
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 7;
   */
  createTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.StartTeacherSurveyRequest
 */
export interface StartTeacherSurveyRequest {
  /**
   * The identifier for the started survey, e.g. 'reading-matters-pre-course'.
   *
   * @generated from protobuf field: string survey_title = 1;
   */
  surveyTitle: string;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.StartTeacherSurveyResponse
 */
export interface StartTeacherSurveyResponse {
  /**
   * @generated from protobuf field: sparx.reading.feedback.v1.TeacherSurvey survey = 1;
   */
  survey?: TeacherSurvey;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesRequest
 */
export interface SubmitTeacherSurveySectionResponsesRequest {
  /**
   * The identifier for the survey being answered, e.g. 'reading-matters-pre-course'.
   *
   * @generated from protobuf field: string survey_title = 5;
   */
  surveyTitle: string;
  /**
   * The UUID linking the user's responses to a given survey title.
   *
   * @generated from protobuf field: string survey_id = 1;
   */
  surveyId: string;
  /**
   * The group of questions that are being submitted.
   *
   * @generated from protobuf field: int32 section = 2;
   */
  section: number;
  /**
   * The responses to each question in this section.
   *
   * @generated from protobuf field: repeated sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesRequest.Response responses = 3;
   */
  responses: SubmitTeacherSurveySectionResponsesRequest_Response[];
  /**
   * Whether this section is the last in the survey. Will not be validated on
   * the server. If true, the survey will be marked as complete.
   *
   * @generated from protobuf field: bool is_final_section = 4;
   */
  isFinalSection: boolean;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesRequest.Response
 */
export interface SubmitTeacherSurveySectionResponsesRequest_Response {
  /**
   * The question text.
   *
   * @generated from protobuf field: string question = 1;
   */
  question: string;
  /**
   * The stringified JSON configuration for the question. E.g. if the type is
   * 'multi-choice' or 'ranking, this would include all of the options.
   *
   * @generated from protobuf field: string question_config = 2;
   */
  questionConfig: string;
  /**
   * The selected option/rating or entered text for the answer, depending on the question type.
   *
   * @generated from protobuf field: string answer = 3;
   */
  answer: string;
  /**
   * The type of question asked. One of 'multi-choice', 'radio', 'rating', 'ranking'.
   *
   * @generated from protobuf field: string type = 4;
   */
  type: string;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesResponse
 */
export interface SubmitTeacherSurveySectionResponsesResponse {
  /**
   * The updated teacher survey
   *
   * @generated from protobuf field: sparx.reading.feedback.v1.TeacherSurvey survey = 1;
   */
  survey?: TeacherSurvey;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.VideoWatch
 */
export interface VideoWatch {
  /**
   * The user who watched the video.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * A non-UUID identifier for the video, e.g. 'the_power_of_reading'.
   *
   * @generated from protobuf field: string video_id = 2;
   */
  videoId: string;
  /**
   * The first time the user watched the end of this video, or null if they've
   * never watched the end of the video.
   *
   * @generated from protobuf field: google.protobuf.Timestamp complete_time = 3;
   */
  completeTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.RecordVideoWatchRequest
 */
export interface RecordVideoWatchRequest {
  /**
   * A non-UUID identifier of a video, e.g. 'the_power_of_reading'.
   *
   * @generated from protobuf field: string video_id = 1;
   */
  videoId: string;
  /**
   * Whether the teacher has started or finished watching the video.
   * If the action is VIDEO_WATCH_ACTION_COMPLETE this video will be marked as
   * complete at the time this action is processed.
   *
   * @generated from protobuf field: sparx.reading.feedback.v1.VideoWatchAction action = 2;
   */
  action: VideoWatchAction;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.RecordVideoWatchResponse
 */
export interface RecordVideoWatchResponse {
  /**
   * The updated video watch event.
   *
   * @generated from protobuf field: sparx.reading.feedback.v1.VideoWatch video_watch = 1;
   */
  videoWatch?: VideoWatch;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.GetVideoWatchRequest
 */
export interface GetVideoWatchRequest {
  /**
   * A non-UUID identifier of a video, e.g. 'the_power_of_reading'.
   *
   * @generated from protobuf field: string video_id = 1;
   */
  videoId: string;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.BatchGetVideoWatchesRequest
 */
export interface BatchGetVideoWatchesRequest {
  /**
   * @generated from protobuf field: repeated sparx.reading.feedback.v1.GetVideoWatchRequest requests = 1;
   */
  requests: GetVideoWatchRequest[];
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.BatchGetVideoWatchesResponse
 */
export interface BatchGetVideoWatchesResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.feedback.v1.VideoWatch video_watches = 1;
   */
  videoWatches: VideoWatch[];
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.ResetUserTeacherSurveyRequest
 */
export interface ResetUserTeacherSurveyRequest {}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.ResetUserTeacherSurveyResponse
 */
export interface ResetUserTeacherSurveyResponse {}
// Deprecated messages

/**
 * @deprecated
 * @generated from protobuf message sparx.reading.feedback.v1.DismissUserSentimentFeedbackRequest
 */
export interface DismissUserSentimentFeedbackRequest {
  /**
   * The users sentiment for feedback.
   *
   * @generated from protobuf field: sparx.interaction.feedback.v1.SentimentRating sentiment = 1;
   */
  sentiment: SentimentRating;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.feedback.v1.DismissUserSentimentFeedbackResponse
 */
export interface DismissUserSentimentFeedbackResponse {}
/**
 * @generated from protobuf enum sparx.reading.feedback.v1.VideoWatchAction
 */
export enum VideoWatchAction {
  /**
   * @generated from protobuf enum value: VIDEO_WATCH_ACTION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Start watching a video.
   *
   * @generated from protobuf enum value: VIDEO_WATCH_ACTION_START = 1;
   */
  START = 1,
  /**
   * Watch the end of the video.
   *
   * @generated from protobuf enum value: VIDEO_WATCH_ACTION_COMPLETE = 2;
   */
  COMPLETE = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class SubmitUserSentimentFeedbackRequest$Type extends MessageType<SubmitUserSentimentFeedbackRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.SubmitUserSentimentFeedbackRequest', [
      {
        no: 1,
        name: 'sentiment',
        kind: 'enum',
        T: () => [
          'sparx.interaction.feedback.v1.SentimentRating',
          SentimentRating,
          'SENTIMENT_RATING_',
        ],
      },
      { no: 2, name: 'comment', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'labels',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.SubmitUserSentimentFeedbackRequest
 */
export const SubmitUserSentimentFeedbackRequest =
  new SubmitUserSentimentFeedbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitUserSentimentFeedbackResponse$Type extends MessageType<SubmitUserSentimentFeedbackResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.SubmitUserSentimentFeedbackResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.SubmitUserSentimentFeedbackResponse
 */
export const SubmitUserSentimentFeedbackResponse =
  new SubmitUserSentimentFeedbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTeacherSurveyRequest$Type extends MessageType<GetTeacherSurveyRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.GetTeacherSurveyRequest', [
      {
        no: 1,
        name: 'survey_title',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.GetTeacherSurveyRequest
 */
export const GetTeacherSurveyRequest = new GetTeacherSurveyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeacherSurvey$Type extends MessageType<TeacherSurvey> {
  constructor() {
    super('sparx.reading.feedback.v1.TeacherSurvey', [
      { no: 1, name: 'survey_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'survey_title',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'responses',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TeacherSurveyResponse,
      },
      { no: 4, name: 'started_time', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'finished_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.TeacherSurvey
 */
export const TeacherSurvey = new TeacherSurvey$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TeacherSurveyResponse$Type extends MessageType<TeacherSurveyResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.TeacherSurveyResponse', [
      { no: 1, name: 'survey_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'section', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'sequence', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'question', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'answer', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 6, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'create_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.TeacherSurveyResponse
 */
export const TeacherSurveyResponse = new TeacherSurveyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartTeacherSurveyRequest$Type extends MessageType<StartTeacherSurveyRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.StartTeacherSurveyRequest', [
      {
        no: 1,
        name: 'survey_title',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.StartTeacherSurveyRequest
 */
export const StartTeacherSurveyRequest = new StartTeacherSurveyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartTeacherSurveyResponse$Type extends MessageType<StartTeacherSurveyResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.StartTeacherSurveyResponse', [
      { no: 1, name: 'survey', kind: 'message', T: () => TeacherSurvey },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.StartTeacherSurveyResponse
 */
export const StartTeacherSurveyResponse = new StartTeacherSurveyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitTeacherSurveySectionResponsesRequest$Type extends MessageType<SubmitTeacherSurveySectionResponsesRequest> {
  constructor() {
    super(
      'sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesRequest',
      [
        {
          no: 5,
          name: 'survey_title',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 1,
          name: 'survey_id',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 2, name: 'section', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
        {
          no: 3,
          name: 'responses',
          kind: 'message',
          repeat: 1 /*RepeatType.PACKED*/,
          T: () => SubmitTeacherSurveySectionResponsesRequest_Response,
        },
        {
          no: 4,
          name: 'is_final_section',
          kind: 'scalar',
          T: 8 /*ScalarType.BOOL*/,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesRequest
 */
export const SubmitTeacherSurveySectionResponsesRequest =
  new SubmitTeacherSurveySectionResponsesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitTeacherSurveySectionResponsesRequest_Response$Type extends MessageType<SubmitTeacherSurveySectionResponsesRequest_Response> {
  constructor() {
    super(
      'sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesRequest.Response',
      [
        { no: 1, name: 'question', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: 'question_config',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        { no: 3, name: 'answer', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 4, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesRequest.Response
 */
export const SubmitTeacherSurveySectionResponsesRequest_Response =
  new SubmitTeacherSurveySectionResponsesRequest_Response$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitTeacherSurveySectionResponsesResponse$Type extends MessageType<SubmitTeacherSurveySectionResponsesResponse> {
  constructor() {
    super(
      'sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesResponse',
      [{ no: 1, name: 'survey', kind: 'message', T: () => TeacherSurvey }],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesResponse
 */
export const SubmitTeacherSurveySectionResponsesResponse =
  new SubmitTeacherSurveySectionResponsesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoWatch$Type extends MessageType<VideoWatch> {
  constructor() {
    super('sparx.reading.feedback.v1.VideoWatch', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'video_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'complete_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.VideoWatch
 */
export const VideoWatch = new VideoWatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordVideoWatchRequest$Type extends MessageType<RecordVideoWatchRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.RecordVideoWatchRequest', [
      { no: 1, name: 'video_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'action',
        kind: 'enum',
        T: () => [
          'sparx.reading.feedback.v1.VideoWatchAction',
          VideoWatchAction,
          'VIDEO_WATCH_ACTION_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.RecordVideoWatchRequest
 */
export const RecordVideoWatchRequest = new RecordVideoWatchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecordVideoWatchResponse$Type extends MessageType<RecordVideoWatchResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.RecordVideoWatchResponse', [
      { no: 1, name: 'video_watch', kind: 'message', T: () => VideoWatch },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.RecordVideoWatchResponse
 */
export const RecordVideoWatchResponse = new RecordVideoWatchResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetVideoWatchRequest$Type extends MessageType<GetVideoWatchRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.GetVideoWatchRequest', [
      { no: 1, name: 'video_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.GetVideoWatchRequest
 */
export const GetVideoWatchRequest = new GetVideoWatchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetVideoWatchesRequest$Type extends MessageType<BatchGetVideoWatchesRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.BatchGetVideoWatchesRequest', [
      {
        no: 1,
        name: 'requests',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GetVideoWatchRequest,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.BatchGetVideoWatchesRequest
 */
export const BatchGetVideoWatchesRequest =
  new BatchGetVideoWatchesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetVideoWatchesResponse$Type extends MessageType<BatchGetVideoWatchesResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.BatchGetVideoWatchesResponse', [
      {
        no: 1,
        name: 'video_watches',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => VideoWatch,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.BatchGetVideoWatchesResponse
 */
export const BatchGetVideoWatchesResponse =
  new BatchGetVideoWatchesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetUserTeacherSurveyRequest$Type extends MessageType<ResetUserTeacherSurveyRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.ResetUserTeacherSurveyRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.ResetUserTeacherSurveyRequest
 */
export const ResetUserTeacherSurveyRequest =
  new ResetUserTeacherSurveyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetUserTeacherSurveyResponse$Type extends MessageType<ResetUserTeacherSurveyResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.ResetUserTeacherSurveyResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.ResetUserTeacherSurveyResponse
 */
export const ResetUserTeacherSurveyResponse =
  new ResetUserTeacherSurveyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DismissUserSentimentFeedbackRequest$Type extends MessageType<DismissUserSentimentFeedbackRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.DismissUserSentimentFeedbackRequest', [
      {
        no: 1,
        name: 'sentiment',
        kind: 'enum',
        T: () => [
          'sparx.interaction.feedback.v1.SentimentRating',
          SentimentRating,
          'SENTIMENT_RATING_',
        ],
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.DismissUserSentimentFeedbackRequest
 */
export const DismissUserSentimentFeedbackRequest =
  new DismissUserSentimentFeedbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DismissUserSentimentFeedbackResponse$Type extends MessageType<DismissUserSentimentFeedbackResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.DismissUserSentimentFeedbackResponse', []);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.DismissUserSentimentFeedbackResponse
 */
export const DismissUserSentimentFeedbackResponse =
  new DismissUserSentimentFeedbackResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.feedback.v1.Feedback
 */
export const Feedback = new ServiceType('sparx.reading.feedback.v1.Feedback', [
  {
    name: 'SubmitUserSentimentFeedback',
    options: {},
    I: SubmitUserSentimentFeedbackRequest,
    O: SubmitUserSentimentFeedbackResponse,
  },
  {
    name: 'DismissUserSentimentFeedback',
    options: {},
    I: DismissUserSentimentFeedbackRequest,
    O: DismissUserSentimentFeedbackResponse,
  },
  {
    name: 'GetTeacherSurvey',
    options: {},
    I: GetTeacherSurveyRequest,
    O: TeacherSurvey,
  },
  {
    name: 'StartTeacherSurvey',
    options: {},
    I: StartTeacherSurveyRequest,
    O: StartTeacherSurveyResponse,
  },
  {
    name: 'SubmitTeacherSurveySectionResponses',
    options: {},
    I: SubmitTeacherSurveySectionResponsesRequest,
    O: SubmitTeacherSurveySectionResponsesResponse,
  },
  {
    name: 'ResetUserTeacherSurvey',
    options: {},
    I: ResetUserTeacherSurveyRequest,
    O: ResetUserTeacherSurveyResponse,
  },
  {
    name: 'RecordVideoWatch',
    options: {},
    I: RecordVideoWatchRequest,
    O: RecordVideoWatchResponse,
  },
  {
    name: 'BatchGetVideoWatches',
    options: {},
    I: BatchGetVideoWatchesRequest,
    O: BatchGetVideoWatchesResponse,
  },
]);
