import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { Button } from 'components/buttons';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { Link } from 'components/link';
import { TableContainer } from 'components/table/table-container';
import { TableCell, TableRow } from 'components/table/table-row';
import { useEnglishStudentGroups } from 'queries/management';
import { timestampToMoment } from 'utils/time';

import { clickedViewHandIn, closedModal } from './events';
import styles from './unviewed-homework-modal.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  homeworks: Homework[];
};

export const UnviewedHomeworkModal = ({ isOpen, onClose, homeworks }: Props) => {
  const { sendEvent } = useClientEvent();
  const { data: studentGroups } = useEnglishStudentGroups();

  const close = () => {
    sendEvent(closedModal());
    onClose();
  };

  const rows = homeworks.map(homework => {
    const studentGroup = studentGroups?.find(group => {
      return group.name.split('/')[3] === homework.studentGroupId;
    });
    return {
      ...homework,
      studentGroup,
    };
  });

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Modal.Content width="3xl">
        <Modal.Title>Unviewed homework</Modal.Title>
        <Modal.Body>
          <TableContainer
            maxHeight={300}
            columns={[
              { name: 'Class', width: '1fr' },
              { name: 'Due', width: '1fr' },
              { name: '', width: '1fr' },
            ]}
          >
            {rows.map(row => (
              <TableRow key={row.homeworkId}>
                <TableCell>{row.studentGroup?.displayName}</TableCell>
                <TableCell>{row.endDate && timestampToMoment(row.endDate).fromNow()}</TableCell>
                <TableCell>
                  <Link
                    to={`/teacher/classes/homework?group=${row.studentGroupId}&homework=${row.homeworkId}`}
                    analyticsEvent={clickedViewHandIn(row.studentGroupId, row.homeworkId)}
                    className={styles.HandInLink}
                  >
                    View Hand-in &gt;
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button analyticsEvent={undefined} onClick={close} variant="secondary">
            Close
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
