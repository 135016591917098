import { Helmet } from 'react-helmet';

interface ITitleProps {
  children: string;
}

export const Title = ({ children }: ITitleProps) => (
  <Helmet>
    <title>{children ? `${children} - Sparx Reader` : 'Sparx Reader'}</title>
  </Helmet>
);
