import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClearAlert } from 'components/alert/alert';
import styles from 'components/alert/library-book-panel.module.css';
import { Button } from 'components/buttons/button';
import modalStyles from 'components/tasks/panel.module.css';
import { useEffect, useRef, useState } from 'react';

interface IPageCountCheckAlertProps {
  submit: (startPage: number, endPage: number) => void;
  expectedPageCount?: number;
}

export const PageCountCheckAlert: React.FC<IPageCountCheckAlertProps> = ({
  submit,
  expectedPageCount,
}) => {
  const close = useClearAlert();
  const [endPageValue, setEndPageValue] = useState('');
  const [startPageValue, setStartPageValue] = useState('');
  const inputField = useRef<HTMLInputElement>(null);
  const endPage = parseInt(endPageValue);
  const startPage = parseInt(startPageValue);
  const submitInputField = () => {
    if (startPage && endPage) {
      submit(startPage, endPage);
    }
  };

  const invalid: boolean =
    !!expectedPageCount &&
    endPage >= 100 &&
    (endPage < expectedPageCount * 0.5 || endPage > expectedPageCount * 1.5);
  const disabled =
    endPageValue === '' ||
    endPage >= 2000 ||
    endPage <= 0 ||
    startPageValue === '' ||
    startPage <= 0 ||
    startPage >= 2000 ||
    startPage >= endPage;

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !disabled) {
      submitInputField();
    }
    if (e.key === 'Escape') {
      close();
    }
  };

  // Focus the field when the modal loads
  useEffect(() => {
    if (inputField.current) {
      inputField.current.focus();
    }
  }, [inputField]);

  return (
    <div className={styles.BookModalCheck}>
      <div className={modalStyles.ExitButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2 style={{ marginTop: 0, marginBottom: 20 }}>Add new book</h2>
      <div className={styles.BookCheckQuestion}>
        {invalid && (
          <div className={styles.BookCheckIncorrect}>The end page is outside of expected range</div>
        )}
        {startPage >= endPage && (
          <div className={styles.BookCheckIncorrect}>Start page must be before end page</div>
        )}
        <div className={styles.BookInputLabel}>What page does the story begin on?</div>
        <input
          data-hj-whitelist
          className={'text-input ' + styles.BookInputField}
          type="number"
          max={10000}
          min={1}
          step={1}
          onChange={e => setStartPageValue(e.target.value.replace(/[^0-9]/g, ''))}
          value={startPageValue}
          onKeyDown={handleKeyDown}
          ref={inputField}
        />
        <div className={styles.BookInputLabel}>What page does the story end on?</div>
        <input
          data-hj-whitelist
          className={'text-input ' + styles.BookInputField}
          type="number"
          max={10000}
          min={1}
          step={1}
          onChange={e => setEndPageValue(e.target.value.replace(/[^0-9]/g, ''))}
          value={endPageValue}
          onKeyDown={handleKeyDown}
        />
        <div className={styles.BookInputNote}>
          This might be before the last page in the book
          <br />
          if there are extra pages after the end.
        </div>
        <div className={styles.BookButtons}>
          <div className={styles.BookButtonsButtons}>
            <Button
              variant="secondary"
              onClick={close}
              style={{ marginRight: 10 }}
              analyticsEvent={undefined}
            >
              Close
            </Button>
            <Button onClick={submitInputField} disabled={disabled} analyticsEvent={undefined}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
