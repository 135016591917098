import { faBackward, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputQuestion_Type } from '@sparx/api/apis/sparx/reading/content/v1/books';
import { GetBookResponse } from '@sparx/api/apis/sparx/reading/content/v1/service';
import {
  PaperbackReadingTask_DeliveryFlag,
  PaperbackReadingTask_State_Stage,
} from '@sparx/api/apis/sparx/reading/tasks/v1/paperback';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { getAudioURL } from 'api';
import classNames from 'classnames/bind';
import { BookDescriptor } from 'components/alert/library-book-panel';
import { Modal } from 'components/alert/modal';
import { bookSettingsToStylesOuter } from 'components/book/book-settings';
import { BookV2 } from 'components/bookv2/bookv2';
import { Button } from 'components/buttons/button';
import { DefinitionProvider } from 'components/contextual-definitions/definition-provider';
import { ErrorMessage } from 'components/errors/error-message';
import { Loading } from 'components/loading/loading';
import { PaperbackAnswerInput } from 'components/tasks/paperback-answer-panel';
import { PaperbackReadingPanel } from 'components/tasks/paperback-reading-panel';
import { ScoredTaskCompleteModal } from 'components/tasks/task-complete-modal';
import { Title } from 'components/title/title';
import { useIsUserOnboarded, useServerOffset, useUserSettings } from 'queries/session';
import { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useKeypressScroll } from 'utils/hooks';
import { scrollAfterTimeout } from 'utils/scroll';
import { usePaperbackReadingActions } from 'utils/tasks';
import { CursorRuler } from 'views/student/task-view/components/cursor-ruler';
import { PaperbackAudioControl } from 'views/student/task-view/components/paperback-audio-control';
import styles from 'views/student/task-view/paperback-reading-task-view.module.css';
import taskStyles from 'views/student/task-view/task-view.module.css';

const cx = classNames.bind(taskStyles);

interface IPaperbackReadingTaskViewProps {
  task: Task;
  book: GetBookResponse;
  bookMeta: MetadataAbridged;
}

const getLastWord = (val: string | undefined): string => {
  if (!val) {
    return '';
  }
  // replace html tag, then replace non alphanumeric characters
  const split = val
    .toLowerCase()
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/[^0-9a-z\s]/g, '')
    .trim()
    .split(' ');
  return split[split.length - 1];
};

export const PaperbackReadingTaskView: React.FC<IPaperbackReadingTaskViewProps> = ({
  task,
  book,
  bookMeta,
}) => {
  const actions = usePaperbackReadingActions(task.taskId);
  const bookID = book.bookV2?.bookId || '';

  const completedOnboarding = useIsUserOnboarded();
  const { data: settings } = useUserSettings();
  const { isLoading: loadingServerTime } = useServerOffset();

  const taskState =
    task.state?.state.oneofKind === 'paperback' ? task.state?.state.paperback : undefined;
  const spec = task.spec?.spec.oneofKind === 'paperback' ? task.spec?.spec.paperback : undefined;
  const isReadingTest = spec?.flags?.includes(PaperbackReadingTask_DeliveryFlag.READING_TEST);

  // Scroll to the start bookmark on page load
  useEffect(() => {
    if (bookMeta) {
      scrollAfterTimeout('bookmark-start', 500, {
        block: 'start',
      });
    }
  }, [bookMeta]);

  // Setup keypress listeners for book scrolling
  useKeypressScroll('book-scroll');

  const [selectedPart, setSelectedPart] = useState<{ id: string; text: string } | undefined>();
  if (loadingServerTime) {
    return <Loading />;
  }
  if (!taskState || !book || !taskState.book) {
    return <ErrorMessage message="Cannot load task" />;
  }

  const selectableBook = taskState?.currentStage === PaperbackReadingTask_State_Stage.QUESTIONS;
  const useLastWord = taskState.currentQuestion?.type !== InputQuestion_Type.SELECT_SENTENCE;
  const forcedAnswer = selectableBook
    ? useLastWord
      ? getLastWord(selectedPart?.text)
      : selectedPart?.text
    : undefined;

  const isRewindModalOpen =
    taskState.currentStage === PaperbackReadingTask_State_Stage.REWOUND_BOOK;
  const isWarningModalOpen = taskState.currentStage === PaperbackReadingTask_State_Stage.WARNING;
  const isTooFastModalOpen = taskState.currentStage === PaperbackReadingTask_State_Stage.TOOFAST;
  const modalOpen = isWarningModalOpen || isTooFastModalOpen || isRewindModalOpen;

  let popup: React.ReactNode = null;
  let hideContent = false;
  let questionBookSelectable = true;
  switch (taskState.currentStage) {
    case PaperbackReadingTask_State_Stage.QUESTIONS:
    case PaperbackReadingTask_State_Stage.QUESTION_RESULT:
      if (taskState.currentQuestion && !isModalOpen(task)) {
        questionBookSelectable =
          taskState.currentQuestion && taskState.currentQuestion.options.length === 0;
        hideContent = !questionBookSelectable && !taskState.showTextInQuestions; // Flag to override and show text
        popup = (
          <PaperbackAnswerInput
            question={taskState.currentQuestion}
            forcedValue={forcedAnswer}
            submit={(answer, questionID) => {
              actions.answerQuestion(answer, questionID);
              setSelectedPart(undefined);
            }}
            finishViewingResult={actions.finishViewingResult}
            questionIndex={taskState.questionsIssued}
            questionTimeout={taskState?.currentQuestionTimeout}
            result={
              taskState &&
              taskState.currentStage === PaperbackReadingTask_State_Stage.QUESTION_RESULT
                ? taskState.results[taskState.results.length - 1]
                : undefined
            }
            bigOptions={!!taskState.currentQuestion.options.find(a => a.length > 30)}
          />
        );
      }
      break;
  }

  const bookPageMessages = {
    [taskState?.book?.startPartId]: isReadingTest ? undefined : (
      <>
        <div className={styles.Bookmark}>
          <div className={`${styles.BookmarkScrollPoint} bookmark-start`} />
          <FontAwesomeIcon icon={faBookmark} />
          Start reading here
        </div>

        {taskState.book.audioClipFilename && (
          <PaperbackAudioControl
            bookID={book.bookV2?.bookId || ''}
            taskID={task.taskId}
            audioClip={taskState.book.audioClipFilename}
            audioURL={getAudioURL}
            onFinish={actions.finishReading}
            active={taskState.currentStage === PaperbackReadingTask_State_Stage.READING}
          />
        )}
      </>
    ),
    [taskState?.book?.endPartId]: (
      <>
        <div className={`${styles.Bookmark} ${styles.BookmarkAfter}`}>
          <FontAwesomeIcon icon={faBookmark} />
          Stop reading here
        </div>
        {taskState?.currentStage !== PaperbackReadingTask_State_Stage.QUESTIONS &&
          taskState?.currentStage !== PaperbackReadingTask_State_Stage.QUESTION_RESULT && (
            <PaperbackReadingPanel
              taskID={task.taskId}
              finishReading={actions.finishReading}
              inline={true}
            />
          )}
      </>
    ),
  };

  // Only show the CAD functionality if the user is READING
  const disableCAD = taskState?.currentStage !== PaperbackReadingTask_State_Stage.READING;

  const bookElement = (
    <DefinitionProvider bookId={bookID} disabled={disableCAD}>
      <Title>{bookMeta.title || ''}</Title>
      <BookDescriptor book={bookMeta} noMeta={true} small />
      <BookV2
        bookID={bookID}
        startID={taskState.book.startPartId}
        endID={taskState.book.endPartId}
        taskID={task.taskId}
        beforeSections={isReadingTest ? 0 : 1}
        afterSections={isReadingTest ? 0 : 2}
        spanSelectable={selectableBook && questionBookSelectable}
        onSpanClick={
          selectableBook
            ? span =>
                setSelectedPart({ id: span.spanId, text: span.runs.map(r => r.content).join('') })
            : undefined
        }
        spanClasses={selectedPart ? { [selectedPart.id]: styles.HighlightedText } : {}}
        injectedElements={bookPageMessages}
        disableContextualDefinitions={!completedOnboarding}
      />
    </DefinitionProvider>
  );

  const fadedContent = isModalOpen(task) || modalOpen || hideContent;

  return (
    <>
      <div className={'scrollable'} id="book-scroll" style={bookSettingsToStylesOuter(settings)}>
        <div
          className={cx(taskStyles.ReadingContent, 'content', styles.Container, {
            [taskStyles.ReadingContentFaded]: fadedContent,
          })}
        >
          {bookElement || (
            <div className={styles.BookDescriptorContainer}>
              {bookMeta && <BookDescriptor book={bookMeta} />}
            </div>
          )}
        </div>
        {!!bookElement && !fadedContent && <CursorRuler />}
        {isRewindModalOpen && (
          <Modal className={styles.WarningModal}>
            <FontAwesomeIcon icon={faBackward} className={styles.WarningModalIcon} />
            <div className={styles.WarningText}>
              <p>
                <strong>Let&apos;s try again</strong>
              </p>
              <p>
                We&apos;ve moved you back in the story. Try reading more carefully. If you
                can&apos;t get the questions right, you could try a different book.
              </p>
            </div>
            <Button
              analyticsEvent={undefined}
              onClick={actions.finishWarning}
              loading={actions.isLoading}
            >
              Start
            </Button>
          </Modal>
        )}
        {isWarningModalOpen && (
          <Modal>
            <div className={styles.WarningText}>
              <p>You&apos;re about to be asked some questions about what you have just read.</p>
            </div>
            <Button
              analyticsEvent={undefined}
              onClick={actions.finishWarning}
              loading={actions.isLoading}
            >
              Start
            </Button>
          </Modal>
        )}
        {isTooFastModalOpen && (
          <Modal className={styles.WarningModal}>
            <h2 className={styles.WarningTitle}>Did you read carefully?</h2>
            <div className={styles.WarningText}>
              <p>
                You will get SRP <strong>faster</strong> if you read carefully.
              </p>
            </div>
            <Button
              analyticsEvent={undefined}
              className={styles.WarningButton}
              onClick={actions.tooFastReturnToBook}
              loading={actions.isLoading}
            >
              I would like to read it more carefully.
              <br />
              Take me back to the book.
            </Button>
            <div style={{ height: 10 }} />
            <Button
              analyticsEvent={undefined}
              className={styles.WarningButton}
              onClick={actions.tooFastSkipAhead}
              variant="secondary"
              loading={actions.isLoading}
            >
              Yes, ask me the questions.
            </Button>
          </Modal>
        )}
      </div>
      <TransitionGroup component="div">
        {popup && (
          <CSSTransition classNames="slideup" timeout={600}>
            {popup}
          </CSSTransition>
        )}
      </TransitionGroup>
      <ScoredTaskCompleteModal
        state={task.state}
        retry={actions.retry}
        isOpen={isModalOpen(task)}
        task={task}
      />
    </>
  );
};

const isModalOpen = (task: Task) =>
  task.state?.state.oneofKind === 'paperback' && task.state.state.paperback?.finished;
