import { faCheck, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Package } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import classNames from 'classnames';
import { Button } from 'components/buttons/button';
import { HomeBox } from 'components/home-box/home-box';
import { Loading } from 'components/loading/loading';
import { usePackageTasks } from 'queries/tasks';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import styles from 'views/student/library-view/subviews/extra-package.module.css';
import { pathForView } from 'views/views';

export const ExtraPackage = ({ pkg }: { pkg: Package }) => {
  const navigate = useNavigate();
  const { data: tasks, isLoading } = usePackageTasks(pkg.packageId);

  const onClick = () => {
    const firstIncomplete = tasks?.tasks.find(t => !t.state?.completed);
    if (firstIncomplete) {
      navigate({
        pathname: pathForView(View.Task),
        search: `?id=${firstIncomplete.taskId}`,
      });
    }
  };

  let content = <Loading />;
  if (!isLoading && tasks) {
    const total = tasks.tasks.length;
    const started = Boolean(tasks.tasks.find(t => t.state));
    const completed = tasks.tasks.filter(t => t.state?.completed).length || 0;
    content = (
      <>
        <div className={styles.ExtraPackageStatus}>
          <div className={styles.ExtraPackageName}>
            <div className={styles.ExtraPackageTitle}>{pkg.title}</div>
            <span>
              {completed} / {total} completed
            </span>
          </div>
          <div className={styles.ExtraPackageTasks}>
            {tasks.tasks.map(tsk => (
              <TaskBox completed={tsk.state?.completed} key={tsk.taskId} />
            ))}
          </div>
        </div>
        {total !== completed && (
          <Button
            analyticsEvent={undefined}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={onClick}
            className={styles.ExtraPackageCTA}
            variant="secondary"
          >
            {started ? 'Continue' : 'Start'}
          </Button>
        )}
      </>
    );
  }

  return (
    <HomeBox variant="dark" lgw={100} className={styles.ExtraPackage}>
      {content}
    </HomeBox>
  );
};

const TaskBox = ({ completed }: { completed?: boolean }) => (
  <div className={classNames(styles.TaskBox, completed && styles.TaskBoxChecked)}>
    <FontAwesomeIcon icon={faCheck} />
  </div>
);
