import classNames from 'classnames';
import styles from 'components/table/table.module.css';
import { FCWithChildren, FCWithOptionalChildren } from 'utils/props';

interface ITableRowProps {
  id?: string;
  className?: string;
  onClick?: () => void;
  color?: 'green' | 'red';
}

export const TableRow: FCWithChildren<ITableRowProps> = ({
  id,
  children,
  onClick,
  className,
  color,
}) => (
  <div
    id={id}
    className={classNames(
      styles.Row,
      {
        [styles.RowClickable]: onClick,
        [styles.RowGreen]: color === 'green',
        [styles.RowRed]: color === 'red',
      },
      className,
    )}
    onClick={onClick}
    role="row"
  >
    {children}
  </div>
);

interface ITableCellProps {
  sticky?: boolean;
  variant?: 'default' | 'faded' | 'faded-red';
  align?: 'left' | 'center' | 'right';
  className?: string;
  addBorderLeft?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  withLink?: boolean;
}

export const TableCell: FCWithOptionalChildren<ITableCellProps> = ({
  children,
  sticky,
  variant,
  align,
  className,
  addBorderLeft,
  onClick,
  withLink,
}) => (
  <div
    onClick={onClick}
    className={classNames(
      styles.TableCell,
      {
        [styles.ColumnSticky]: sticky,
        [styles.CellAlignRight]: align === 'right',
        [styles.CellAlignCenter]: align === 'center',
        [styles.CellBorderLeft]: addBorderLeft,
        [styles.WithLink]: withLink,
      },
      className,
    )}
  >
    {variant === 'faded-red' && (
      <div className={classNames(styles.FadedOverlay, styles.FadedRedOverlay)} />
    )}
    {variant === 'faded' && <div className={styles.FadedOverlay} />}
    {children}
  </div>
);

export const TableCellEmpty = () => <span className={styles.TableCellEmpty}>-</span>;

export const TableRowFull: FCWithChildren = ({ children }) => (
  <TableRow>
    <div className={styles.TableCell} style={{ gridColumn: '-1 / 1' }}>
      {children}
    </div>
  </TableRow>
);
