import { useState } from 'react';

import { OpenButton } from './open-button/open-button';
import { Panel } from './panel/panel';

export const ReaderDevTools = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!isOpen && <OpenButton onOpen={() => setIsOpen(true)} />}

      <Panel isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
