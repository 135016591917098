import { isWordBlocked } from 'components/contextual-definitions/blocklist';
import { gatherContextAroundElement } from 'components/contextual-definitions/context-gatherer';
import styles from 'components/contextual-definitions/definition-provider.module.css';
import { WordPopover } from 'components/contextual-definitions/word-popover';
import { SelectedWord } from 'queries/definitions';
import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Flags, useBooleanFlag } from 'utils/featureflags';
import { FCWithChildren } from 'utils/props';

export interface CADContext {
  selectWord: (ref: HTMLSpanElement, partID: string) => void;
  selected?: HTMLSpanElement;
  bookId: string;
  disabled?: boolean;
}

export const CADContext = React.createContext<CADContext | undefined>(undefined);

export const useCADContext = () => React.useContext(CADContext);

export interface SelectionWithRef {
  ref: HTMLSpanElement;
  word: SelectedWord;
}

interface DefinitionProviderProps {
  bookId: string;
  disabled?: boolean;
}

export const DefinitionProvider: FCWithChildren<DefinitionProviderProps> = ({
  children,
  bookId,
  disabled,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [selected, setSelected] = useState<SelectionWithRef | undefined>(undefined);

  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setContainerElement(containerRef.current);
  }, [containerRef]);

  // If disabled, just return the children
  const isEnabled = useBooleanFlag(Flags.ContextAwareDefinitions);
  if (!isEnabled) {
    return <>{children}</>;
  }

  const selectWord = (element: HTMLSpanElement, partId: string) => {
    if (disabled) return; // ignore

    const word = element.textContent;
    if (!word) return; // ignore

    const context = gatherContextAroundElement(element, containerRef.current);
    console.info('Selected word:', word, 'with context:', context);

    setSelected({
      ref: element,
      word: {
        word: word,
        partId,
        context,
        blocked: isWordBlocked(word),
        bookId,
      },
    });
  };

  return (
    <CADContext.Provider
      value={{
        bookId,
        selected: selected?.ref,
        selectWord,
        disabled,
      }}
    >
      <div ref={containerRef} className={styles.Container}>
        <TransitionGroup component="div">
          {selected && (
            <CSSTransition
              classNames={{
                exit: styles.PopoverExit,
                exitActive: styles.PopoverExitActive,
              }}
              timeout={500}
              key={selected.word.word + selected.word.context}
            >
              <WordPopover
                selected={selected}
                containerRef={containerElement}
                onClose={() => setSelected(undefined)}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
        {children}
      </div>
    </CADContext.Provider>
  );
};
