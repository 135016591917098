import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClearAlert } from 'components/alert/alert';
import { Button } from 'components/buttons/button';
import styles from 'components/tasks/panel.module.css';
import { useIsUserOnboarded } from 'queries/session';
import { FCWithOptionalChildren } from 'utils/props';

export const HomeworkAlert: FCWithOptionalChildren = ({ children }) => {
  const close = useClearAlert();
  const onboarded = useIsUserOnboarded();

  return (
    <div className={styles.OnboardingModal}>
      <div className={styles.ExitButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2 className={styles.ModalTitle}>Current Task</h2>
      <p style={{ maxWidth: 500 }}>
        This is your active task. You can complete it by gaining SRP which are awarded for reading
        books.{' '}
        {onboarded ? (
          <>Check out the library to find your next book to read.</>
        ) : (
          <>
            Once you have completed the introductory tasks you can access the Library to find your
            next book to read.
          </>
        )}
      </p>
      <div className={styles.Buttons} style={{ marginTop: 25 }}>
        {onboarded && children}
        <Button
          variant="secondary"
          onClick={close}
          analyticsEvent={{
            category: 'library',
            action: 'close task info modal',
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};
