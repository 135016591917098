import { Loading } from 'components/loading/loading';
import { useGetSubscriptionDetails, useSubscriptionLockout } from 'queries/subscriptions';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { View } from 'views';
import { pathForView, useCurrentView } from 'views/views';

export const SubscriptionLocked = ({ children }: PropsWithChildren) => {
  // Wait until the subscription details are loaded, so we know whether we can lock
  // the teacher view
  const { isLoading: isSubscriptionDetailsLoading } = useGetSubscriptionDetails();
  const subscriptionLockout = useSubscriptionLockout();
  const location = useCurrentView();

  if (isSubscriptionDetailsLoading || subscriptionLockout === undefined) {
    return <Loading />;
  }
  // If not on the subscription page, and the subscription is locked, redirect to the subscription page
  if (subscriptionLockout && location?.view !== View.SchoolSubscription) {
    return <Navigate to={pathForView(View.SchoolSubscription)} replace />;
  }
  return children;
};
