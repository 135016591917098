import classNames from 'classnames';

import styles from './booklog-task-view.module.css';

interface IBooklogInputProps {
  label: string | React.ReactNode;
  value: string;
  setValue?: (val: string) => void;
  filter?: RegExp;
  invalid?: boolean;
  readonly?: boolean;
  short?: boolean;
  postFix?: string;
}

export const BooklogInput = ({
  label,
  value,
  setValue,
  filter,
  invalid,
  readonly,
  short,
  postFix,
}: IBooklogInputProps) => {
  const changeValue = (val: string) => setValue?.(filter ? val.replace(filter, '') : val);
  return (
    <div className={styles.FormInput}>
      <div className={styles.FormInputLabel}>{label}</div>
      <input
        readOnly={readonly}
        className={classNames(
          'text-input',
          styles.FormInputField,
          short && styles.FormInputFieldShort,
          invalid && styles.FormInputFieldInvalid,
        )}
        value={value}
        onChange={e => changeValue(e.target.value)}
      />
      {postFix && <div className={styles.PostFix}>{postFix}</div>}
    </div>
  );
};
