import { useEffect } from 'react';

// A hook for registering cleanup functions that will be called on logout.
// The registered functions will only be called if logging out when the
// registering component is still mounted.
const cleanupFunctions = new Set<() => void>();
export const useRegisterLogoutCleanup = (cleanup: () => void) => {
  useEffect(() => {
    cleanupFunctions.add(cleanup);
    return () => {
      cleanupFunctions.delete(cleanup);
    };
  }, [cleanup]);
};

// Call this function to trigger all registered cleanup functions.
export const callLogoutCleanup = () => {
  cleanupFunctions.forEach(fn => fn());
};
