import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import styles from 'views/teacher/components/teacher-view.module.css';

export interface TeacherContentProps {
  narrow?: boolean;
  before?: ReactNode;
}

export const TeacherContent = ({
  narrow,
  children,
  before,
}: PropsWithChildren<TeacherContentProps>) => {
  return (
    <>
      {before}
      <div className={classNames(styles.ContentInner, { [styles.ContentInnerNarrow]: narrow })}>
        {children}
      </div>
    </>
  );
};

export const TeacherMaxWidthContainer = ({ children }: PropsWithChildren) => {
  return <div className={styles.TeacherMaxWidthContainer}>{children}</div>;
};
