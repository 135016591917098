import classNames from 'classnames';
import { TableCell } from 'components/table/table-row';

import { Bar, IRow } from './hand-in-summary';
import styles from './hand-in-summary.module.css';

export const HandInSummaryCompletion = ({ row, bars }: { row: IRow; bars: Array<Bar> }) => {
  // Return an empty cell for a partial hand-in summary row: it means homework
  // data hasn't loaded yet.
  if (row.type === 'partial') return <TableCell />;

  const { homework, completion, numStudents } = row;

  if (homework === undefined) {
    return <TableCell className={styles.NoHomework}>No current homework</TableCell>;
  }
  if (homework.targetExperience === 0) {
    return <TableCell className={styles.OptionalHomework}>Homework is optional</TableCell>;
  }
  if (numStudents === 0) {
    return <TableCell>No students in class</TableCell>;
  }

  // Proportional values
  const proportions = bars.map(bar =>
    completion[bar.type] === 0 ? 0 : completion[bar.type] / numStudents,
  );

  // Cumulative proportional values, in reverse order
  const cumulative = proportions
    .reduce<number[]>((sums, next, index) => {
      sums.push(next + (sums[index - 1] || 0));
      return sums;
    }, [])
    .reverse();

  return (
    <TableCell>
      <div className={styles.Labels}>
        {bars.map((bar, i) => (
          <div key={i} className={styles.Label} style={{ width: `${proportions[i] * 100}%` }}>
            {/* To avoid labels overlapping, do not show 0 values after the first bar */}
            {completion[bar.type] === 0 && i > 0 ? null : completion[bar.type]}
          </div>
        ))}
      </div>
      <div className={styles.Bars}>
        {cumulative.map((cp, i) => {
          if (cp === 0) {
            return null;
          }
          // Get the colours in reverse order
          const bar = bars[bars.length - i - 1];
          return (
            <div
              key={i}
              className={classNames(styles.Bar, bar.className)}
              style={{ transform: `scaleX(${cp})` }}
            />
          );
        })}
      </div>
    </TableCell>
  );
};
