// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/tasks/v1/tasks.proto" (package "sparx.reading.tasks.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { ExperienceUpdate } from '../../users/v1/experience';
import { Homework } from './homework';
import { StudentBook } from '../../content/v1/service';
import { PaperbackReadingTask_TaskAttemptState } from './paperback';
import { StudentHomeworkTaskAttempt } from './homework';
import { AssessmentTask_Action } from './assessment';
import { BooklogTask_Action } from './booklog';
import { PaperbackReadingTask_Action } from './paperback';
import { AssessmentTask_State } from './assessment';
import { BooklogTask_State } from './booklog';
import { PaperbackReadingTask_State } from './paperback';
import { AssessmentTask_Spec } from './assessment';
import { BooklogTask_Spec } from './booklog';
import { PaperbackReadingTask_Spec } from './paperback';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.reading.tasks.v1.Package
 */
export interface Package {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string package_id = 2;
   */
  packageId: string;
  /**
   * @deprecated
   * @generated from protobuf field: string homework_id = 3 [deprecated = true];
   */
  homeworkId: string;
  /**
   * @generated from protobuf field: string title = 4;
   */
  title: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 5;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 6;
   */
  endDate?: Timestamp;
  /**
   * Book ID that this package is set for. This is used to tie a
   * package to a specific book, there should be no value for a
   * homework package.
   *
   * @generated from protobuf field: string book_id = 8;
   */
  bookId: string;
  /**
   * The type of the package. This value will generally be empty
   * for book packages, but will contain the 'type' of the
   * assessment form if this is an assessment package.
   *
   * @generated from protobuf field: string type = 9;
   */
  type: string;
  /**
   * Number of tasks in this package.
   *
   * @generated from protobuf field: int32 tasks_total = 10;
   */
  tasksTotal: number;
  /**
   * Number of tasks completed in this package.
   *
   * @generated from protobuf field: int32 tasks_complete = 11;
   */
  tasksComplete: number;
  /**
   * The tasks that are in this package.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Task tasks = 12;
   */
  tasks: Task[];
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.Task
 */
export interface Task {
  /**
   * @generated from protobuf field: string task_id = 1;
   */
  taskId: string;
  /**
   * @generated from protobuf field: int32 task_index = 2;
   */
  taskIndex: number;
  /**
   * @generated from protobuf field: string package_id = 3;
   */
  packageId: string;
  /**
   * @generated from protobuf field: string title = 4;
   */
  title: string;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.TaskState state = 5;
   */
  state?: TaskState;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.TaskSpec spec = 6;
   */
  spec?: TaskSpec;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.TaskType type = 7;
   */
  type: TaskType;
  /**
   * @generated from protobuf field: bool optional = 8;
   */
  optional: boolean;
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.TaskAttempt attempts = 9;
   */
  attempts: TaskAttempt[];
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.TaskResult
 */
export interface TaskResult {
  /**
   * @generated from protobuf field: float score = 1;
   */
  score: number;
  /**
   * @generated from protobuf field: float total = 2;
   */
  total: number;
  /**
   * @generated from protobuf field: float accuracy = 3;
   */
  accuracy: number;
  /**
   * @generated from protobuf field: bool passed = 4;
   */
  passed: boolean;
  /**
   * @generated from protobuf field: int32 attempt_index = 5;
   */
  attemptIndex: number;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.TaskSpec
 */
export interface TaskSpec {
  /**
   * @generated from protobuf oneof: spec
   */
  spec:
    | {
        oneofKind: 'paperback';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.PaperbackReadingTask.Spec paperback = 3;
         */
        paperback: PaperbackReadingTask_Spec;
      }
    | {
        oneofKind: 'booklog';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.BooklogTask.Spec booklog = 4;
         */
        booklog: BooklogTask_Spec;
      }
    | {
        oneofKind: 'assessment';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.AssessmentTask.Spec assessment = 5;
         */
        assessment: AssessmentTask_Spec;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.TaskState
 */
export interface TaskState {
  /**
   * Whether they can move onto the next task, either from answering
   * correctly or skipping ahead for ebooks.
   *
   * @generated from protobuf field: bool completed = 1;
   */
  completed: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp completion_date = 2;
   */
  completionDate?: Timestamp;
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.TaskResult results = 3;
   */
  results: TaskResult[];
  /**
   * @generated from protobuf oneof: state
   */
  state:
    | {
        oneofKind: 'paperback';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.PaperbackReadingTask.State paperback = 6;
         */
        paperback: PaperbackReadingTask_State;
      }
    | {
        oneofKind: 'booklog';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.BooklogTask.State booklog = 7;
         */
        booklog: BooklogTask_State;
      }
    | {
        oneofKind: 'assessment';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.AssessmentTask.State assessment = 12;
         */
        assessment: AssessmentTask_State;
      }
    | {
        oneofKind: undefined;
      };
  /**
   * @generated from protobuf field: double reading_time_seconds = 8;
   */
  readingTimeSeconds: number;
  /**
   * @generated from protobuf field: double question_time_seconds = 9;
   */
  questionTimeSeconds: number;
  /**
   * @generated from protobuf field: double writing_time_seconds = 11;
   */
  writingTimeSeconds: number;
  /**
   * @generated from protobuf field: int32 experience = 13;
   */
  experience: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_start_timestamp = 10;
   */
  lastStartTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.TaskAction
 */
export interface TaskAction {
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'retry';
        /**
         * @generated from protobuf field: bool retry = 1;
         */
        retry: boolean;
      }
    | {
        oneofKind: 'load';
        /**
         * @generated from protobuf field: bool load = 6;
         */
        load: boolean;
      }
    | {
        oneofKind: 'unload';
        /**
         * @generated from protobuf field: bool unload = 7;
         */
        unload: boolean;
      }
    | {
        oneofKind: 'paperback';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.PaperbackReadingTask.Action paperback = 4;
         */
        paperback: PaperbackReadingTask_Action;
      }
    | {
        oneofKind: 'booklog';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.BooklogTask.Action booklog = 5;
         */
        booklog: BooklogTask_Action;
      }
    | {
        oneofKind: 'assessment';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.AssessmentTask.Action assessment = 8;
         */
        assessment: AssessmentTask_Action;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.TaskAttempt
 */
export interface TaskAttempt {
  /**
   * The ID of the task attempt.
   *
   * @generated from protobuf field: string task_attempt_id = 1;
   */
  taskAttemptId: string;
  /**
   * The ID of the task that this attempt is for.
   *
   * @generated from protobuf field: string task_id = 2;
   */
  taskId: string;
  /**
   * The sequence of the attempt. Starts from 1.
   *
   * @generated from protobuf field: int32 sequence = 3;
   */
  sequence: number;
  /**
   * The result of the attempt.
   *
   * @generated from protobuf field: sparx.reading.tasks.v1.TaskAttemptResult result = 4;
   */
  result: TaskAttemptResult;
  /**
   * State specific to the type of task.
   *
   * @generated from protobuf field: sparx.reading.tasks.v1.TaskAttemptState state = 5;
   */
  state?: TaskAttemptState;
  /**
   * The timestamp for when it was attempted.
   *
   * @generated from protobuf field: google.protobuf.Timestamp attempted_at = 6;
   */
  attemptedAt?: Timestamp;
  /**
   * The homeworks that are associated with this attempt.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.StudentHomeworkTaskAttempt student_homework_task_attempts = 7;
   */
  studentHomeworkTaskAttempts: StudentHomeworkTaskAttempt[];
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.TaskAttemptState
 */
export interface TaskAttemptState {
  /**
   * @generated from protobuf oneof: state
   */
  state:
    | {
        oneofKind: 'paperback';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.PaperbackReadingTask.TaskAttemptState paperback = 1;
         */
        paperback: PaperbackReadingTask_TaskAttemptState;
      }
    | {
        oneofKind: undefined;
      };
  /**
   * The time taken to read the book in seconds.
   *
   * @generated from protobuf field: double reading_time_seconds = 2;
   */
  readingTimeSeconds: number;
  /**
   * The time taken to answer questions in seconds.
   *
   * @generated from protobuf field: double question_time_seconds = 3;
   */
  questionTimeSeconds: number;
  /**
   * The time taken to write question in seconds.
   *
   * @generated from protobuf field: double writing_time_seconds = 4;
   */
  writingTimeSeconds: number;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.GetBookTaskRequest
 */
export interface GetBookTaskRequest {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * If true, we will start a new attempt on the book.
   *
   * @generated from protobuf field: bool request_reread = 2;
   */
  requestReread: boolean;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.GetBookTaskResponse
 */
export interface GetBookTaskResponse {
  /**
   * When a new package is created as a result of this request, this will
   * contain the new package.
   *
   * @generated from protobuf field: sparx.reading.tasks.v1.Package new_package = 1;
   */
  newPackage?: Package;
  /**
   * When new tasks are created as a result of this request, this will
   * contain the new tasks.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Task new_tasks = 2;
   */
  newTasks: Task[];
  /**
   * When a student book has been changed as a result of this request,
   * this will contain the new state of the student book
   *
   * @generated from protobuf field: sparx.reading.content.v1.StudentBook student_book = 3;
   */
  studentBook?: StudentBook;
  /**
   * ID of the task that should be loaded.
   *
   * @generated from protobuf field: string load_task_id = 4;
   */
  loadTaskId: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.GetTaskRequest
 */
export interface GetTaskRequest {
  /**
   * The ID of the task to get.
   *
   * @generated from protobuf field: string task_id = 1;
   */
  taskId: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.GetTaskResponse
 */
export interface GetTaskResponse {
  /**
   * The task that was requested.
   *
   * @generated from protobuf field: sparx.reading.tasks.v1.Task task = 1;
   */
  task?: Task;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.ListTasksRequest
 */
export interface ListTasksRequest {
  /**
   * Package to select the tasks from.
   *
   * @generated from protobuf field: string package_id = 1;
   */
  packageId: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.ListTasksResponse
 */
export interface ListTasksResponse {
  /**
   * List of tasks requested.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Task tasks = 1;
   */
  tasks: Task[];
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.GetHomeworksRequest
 */
export interface GetHomeworksRequest {
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.GetHomeworksRequest.HomeworkRequestType type = 1;
   */
  type: GetHomeworksRequest_HomeworkRequestType;
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.GetHomeworksRequest.HomeworkRequestType
 */
export enum GetHomeworksRequest_HomeworkRequestType {
  /**
   * @generated from protobuf enum value: HOMEWORK_REQUEST_TYPE_UNSPECIFIED = 0;
   */
  HOMEWORK_REQUEST_TYPE_UNSPECIFIED = 0,
  /**
   * Only select authenticated users homeworks.
   *
   * @generated from protobuf enum value: MINE = 1;
   */
  MINE = 1,
  /**
   * Select all homeworks in the schools. Only teachers are able to call this
   * endpoint.
   *
   * @generated from protobuf enum value: ALL = 2;
   */
  ALL = 2,
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.GetHomeworksResponse
 */
export interface GetHomeworksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Homework homeworks = 1;
   */
  homeworks: Homework[];
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Package extra_packages = 2;
   */
  extraPackages: Package[];
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.SendTaskActionRequest
 */
export interface SendTaskActionRequest {
  /**
   * @generated from protobuf field: string task_id = 1;
   */
  taskId: string;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.TaskAction action = 2;
   */
  action?: TaskAction;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.SendTaskActionResponse
 */
export interface SendTaskActionResponse {
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.Task task = 1;
   */
  task?: Task;
  /**
   * Optional experience update if experience was awarded for this action.
   *
   * @generated from protobuf field: sparx.reading.users.v1.ExperienceUpdate experience_update = 2;
   */
  experienceUpdate?: ExperienceUpdate;
  /**
   * Optional student book update if it was relevant.
   *
   * @generated from protobuf field: sparx.reading.content.v1.StudentBook student_book = 3;
   */
  studentBook?: StudentBook;
  /**
   * Affected homeworks from the update (usually only one)
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Homework homeworks = 4;
   */
  homeworks: Homework[];
  /**
   * If the task was just completed
   *
   * @generated from protobuf field: bool just_completed = 5;
   */
  justCompleted: boolean;
  /**
   * If the book was just completed
   *
   * @generated from protobuf field: bool just_completed_book = 6;
   */
  justCompletedBook: boolean;
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.TaskType
 */
export enum TaskType {
  /**
   * @generated from protobuf enum value: TYPE_UNSPECIFIED = 0;
   */
  TYPE_UNSPECIFIED = 0,
  /**
   * @deprecated
   * @generated from protobuf enum value: TYPE_READING = 1 [deprecated = true];
   */
  TYPE_READING = 1,
  /**
   * @deprecated
   * @generated from protobuf enum value: TYPE_COMPREHENSION = 2 [deprecated = true];
   */
  TYPE_COMPREHENSION = 2,
  /**
   * @generated from protobuf enum value: TYPE_PAPERBACK_READING = 3;
   */
  TYPE_PAPERBACK_READING = 3,
  /**
   * @generated from protobuf enum value: TYPE_BOOKLOG = 4;
   */
  TYPE_BOOKLOG = 4,
  /**
   * @generated from protobuf enum value: TYPE_ASSESSMENT = 5;
   */
  TYPE_ASSESSMENT = 5,
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.TaskAttemptResult
 */
export enum TaskAttemptResult {
  /**
   * @generated from protobuf enum value: TASK_ATTEMPT_RESULT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: TASK_ATTEMPT_RESULT_PASSED = 1;
   */
  PASSED = 1,
  /**
   * @generated from protobuf enum value: TASK_ATTEMPT_RESULT_FAILED = 2;
   */
  FAILED = 2,
  /**
   * @generated from protobuf enum value: TASK_ATTEMPT_RESULT_SKIPPED = 3;
   */
  SKIPPED = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class Package$Type extends MessageType<Package> {
  constructor() {
    super('sparx.reading.tasks.v1.Package', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'start_date', kind: 'message', T: () => Timestamp },
      { no: 6, name: 'end_date', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 9, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 10,
        name: 'tasks_total',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 11,
        name: 'tasks_complete',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 12,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Task,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.Package
 */
export const Package = new Package$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Task$Type extends MessageType<Task> {
  constructor() {
    super('sparx.reading.tasks.v1.Task', [
      { no: 1, name: 'task_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'task_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'state', kind: 'message', T: () => TaskState },
      { no: 6, name: 'spec', kind: 'message', T: () => TaskSpec },
      {
        no: 7,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.reading.tasks.v1.TaskType', TaskType],
      },
      { no: 8, name: 'optional', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 9,
        name: 'attempts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TaskAttempt,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.Task
 */
export const Task = new Task$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskResult$Type extends MessageType<TaskResult> {
  constructor() {
    super('sparx.reading.tasks.v1.TaskResult', [
      { no: 1, name: 'score', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 2, name: 'total', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 3, name: 'accuracy', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 4, name: 'passed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'attempt_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.TaskResult
 */
export const TaskResult = new TaskResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskSpec$Type extends MessageType<TaskSpec> {
  constructor() {
    super('sparx.reading.tasks.v1.TaskSpec', [
      {
        no: 3,
        name: 'paperback',
        kind: 'message',
        oneof: 'spec',
        T: () => PaperbackReadingTask_Spec,
      },
      {
        no: 4,
        name: 'booklog',
        kind: 'message',
        oneof: 'spec',
        T: () => BooklogTask_Spec,
      },
      {
        no: 5,
        name: 'assessment',
        kind: 'message',
        oneof: 'spec',
        T: () => AssessmentTask_Spec,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.TaskSpec
 */
export const TaskSpec = new TaskSpec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskState$Type extends MessageType<TaskState> {
  constructor() {
    super('sparx.reading.tasks.v1.TaskState', [
      { no: 1, name: 'completed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'completion_date', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TaskResult,
      },
      {
        no: 6,
        name: 'paperback',
        kind: 'message',
        oneof: 'state',
        T: () => PaperbackReadingTask_State,
      },
      {
        no: 7,
        name: 'booklog',
        kind: 'message',
        oneof: 'state',
        T: () => BooklogTask_State,
      },
      {
        no: 12,
        name: 'assessment',
        kind: 'message',
        oneof: 'state',
        T: () => AssessmentTask_State,
      },
      {
        no: 8,
        name: 'reading_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 9,
        name: 'question_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 11,
        name: 'writing_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      { no: 13, name: 'experience', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 10,
        name: 'last_start_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.TaskState
 */
export const TaskState = new TaskState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskAction$Type extends MessageType<TaskAction> {
  constructor() {
    super('sparx.reading.tasks.v1.TaskAction', [
      {
        no: 1,
        name: 'retry',
        kind: 'scalar',
        oneof: 'action',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 6,
        name: 'load',
        kind: 'scalar',
        oneof: 'action',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 7,
        name: 'unload',
        kind: 'scalar',
        oneof: 'action',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'paperback',
        kind: 'message',
        oneof: 'action',
        T: () => PaperbackReadingTask_Action,
      },
      {
        no: 5,
        name: 'booklog',
        kind: 'message',
        oneof: 'action',
        T: () => BooklogTask_Action,
      },
      {
        no: 8,
        name: 'assessment',
        kind: 'message',
        oneof: 'action',
        T: () => AssessmentTask_Action,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.TaskAction
 */
export const TaskAction = new TaskAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskAttempt$Type extends MessageType<TaskAttempt> {
  constructor() {
    super('sparx.reading.tasks.v1.TaskAttempt', [
      {
        no: 1,
        name: 'task_attempt_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'task_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'sequence', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'result',
        kind: 'enum',
        T: () => [
          'sparx.reading.tasks.v1.TaskAttemptResult',
          TaskAttemptResult,
          'TASK_ATTEMPT_RESULT_',
        ],
      },
      { no: 5, name: 'state', kind: 'message', T: () => TaskAttemptState },
      { no: 6, name: 'attempted_at', kind: 'message', T: () => Timestamp },
      {
        no: 7,
        name: 'student_homework_task_attempts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentHomeworkTaskAttempt,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.TaskAttempt
 */
export const TaskAttempt = new TaskAttempt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TaskAttemptState$Type extends MessageType<TaskAttemptState> {
  constructor() {
    super('sparx.reading.tasks.v1.TaskAttemptState', [
      {
        no: 1,
        name: 'paperback',
        kind: 'message',
        oneof: 'state',
        T: () => PaperbackReadingTask_TaskAttemptState,
      },
      {
        no: 2,
        name: 'reading_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 3,
        name: 'question_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 4,
        name: 'writing_time_seconds',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.TaskAttemptState
 */
export const TaskAttemptState = new TaskAttemptState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookTaskRequest$Type extends MessageType<GetBookTaskRequest> {
  constructor() {
    super('sparx.reading.tasks.v1.GetBookTaskRequest', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'request_reread',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.GetBookTaskRequest
 */
export const GetBookTaskRequest = new GetBookTaskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookTaskResponse$Type extends MessageType<GetBookTaskResponse> {
  constructor() {
    super('sparx.reading.tasks.v1.GetBookTaskResponse', [
      { no: 1, name: 'new_package', kind: 'message', T: () => Package },
      {
        no: 2,
        name: 'new_tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Task,
      },
      { no: 3, name: 'student_book', kind: 'message', T: () => StudentBook },
      {
        no: 4,
        name: 'load_task_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.GetBookTaskResponse
 */
export const GetBookTaskResponse = new GetBookTaskResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaskRequest$Type extends MessageType<GetTaskRequest> {
  constructor() {
    super('sparx.reading.tasks.v1.GetTaskRequest', [
      {
        no: 1,
        name: 'task_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
        options: { 'sparx.api.validation.v1.field': { uuid: true } },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.GetTaskRequest
 */
export const GetTaskRequest = new GetTaskRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTaskResponse$Type extends MessageType<GetTaskResponse> {
  constructor() {
    super('sparx.reading.tasks.v1.GetTaskResponse', [
      { no: 1, name: 'task', kind: 'message', T: () => Task },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.GetTaskResponse
 */
export const GetTaskResponse = new GetTaskResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTasksRequest$Type extends MessageType<ListTasksRequest> {
  constructor() {
    super('sparx.reading.tasks.v1.ListTasksRequest', [
      { no: 1, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.ListTasksRequest
 */
export const ListTasksRequest = new ListTasksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTasksResponse$Type extends MessageType<ListTasksResponse> {
  constructor() {
    super('sparx.reading.tasks.v1.ListTasksResponse', [
      {
        no: 1,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Task,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.ListTasksResponse
 */
export const ListTasksResponse = new ListTasksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworksRequest$Type extends MessageType<GetHomeworksRequest> {
  constructor() {
    super('sparx.reading.tasks.v1.GetHomeworksRequest', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.reading.tasks.v1.GetHomeworksRequest.HomeworkRequestType',
          GetHomeworksRequest_HomeworkRequestType,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.GetHomeworksRequest
 */
export const GetHomeworksRequest = new GetHomeworksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeworksResponse$Type extends MessageType<GetHomeworksResponse> {
  constructor() {
    super('sparx.reading.tasks.v1.GetHomeworksResponse', [
      {
        no: 1,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Homework,
      },
      {
        no: 2,
        name: 'extra_packages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Package,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.GetHomeworksResponse
 */
export const GetHomeworksResponse = new GetHomeworksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendTaskActionRequest$Type extends MessageType<SendTaskActionRequest> {
  constructor() {
    super('sparx.reading.tasks.v1.SendTaskActionRequest', [
      {
        no: 1,
        name: 'task_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
        options: { 'sparx.api.validation.v1.field': { uuid: true } },
      },
      { no: 2, name: 'action', kind: 'message', T: () => TaskAction },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.SendTaskActionRequest
 */
export const SendTaskActionRequest = new SendTaskActionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendTaskActionResponse$Type extends MessageType<SendTaskActionResponse> {
  constructor() {
    super('sparx.reading.tasks.v1.SendTaskActionResponse', [
      { no: 1, name: 'task', kind: 'message', T: () => Task },
      {
        no: 2,
        name: 'experience_update',
        kind: 'message',
        T: () => ExperienceUpdate,
      },
      { no: 3, name: 'student_book', kind: 'message', T: () => StudentBook },
      {
        no: 4,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Homework,
      },
      {
        no: 5,
        name: 'just_completed',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 6,
        name: 'just_completed_book',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.SendTaskActionResponse
 */
export const SendTaskActionResponse = new SendTaskActionResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.tasks.v1.Tasks
 */
export const Tasks = new ServiceType('sparx.reading.tasks.v1.Tasks', [
  { name: 'GetTask', options: {}, I: GetTaskRequest, O: GetTaskResponse },
  { name: 'ListTasks', options: {}, I: ListTasksRequest, O: ListTasksResponse },
  {
    name: 'GetHomeworks',
    options: {},
    I: GetHomeworksRequest,
    O: GetHomeworksResponse,
  },
  {
    name: 'SendTaskAction',
    options: {},
    I: SendTaskActionRequest,
    O: SendTaskActionResponse,
  },
  {
    name: 'GetBookTask',
    options: {},
    I: GetBookTaskRequest,
    O: GetBookTaskResponse,
  },
]);
