import {
  AnalyticEventFields,
  useClientEvent,
} from 'components/client-events/client-event-provider';
// This component is the wrapper the eslint rule is recommending!
// eslint-disable-next-line no-restricted-imports
import { Link as RouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom';

type LinkProps = ReactRouterLinkProps & {
  // The analytics event to send on click. This is not an optional field so you
  // have to explicitly opt out of sending an event using undefined.
  analyticsEvent: AnalyticEventFields | undefined;
};

// A react router link wrapper that sends an analytics event on click.
export const Link: React.FC<LinkProps> = ({ children, analyticsEvent, onClick, ...props }) => {
  const { sendEvent } = useClientEvent();
  return (
    <RouterLink
      {...props}
      onClick={e => {
        onClick && onClick(e);
        analyticsEvent && sendEvent(analyticsEvent);
      }}
    >
      {children}
    </RouterLink>
  );
};
