import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { Alert } from '@sparx/sparx-design/components/alert/Alert';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { Stack } from '@sparx/sparx-design/components/stack/Stack';
import {
  getDuplicateRoleWarningMessage,
  getNoRoleWarningMessage,
  hasRole,
} from '@sparx/staff-manager';
import { useGetStaff, useListStaff, useUpsertStaff } from '@sparx/staff-manager/src/queries';
import { willStealUniqueRoles } from '@sparx/staff-manager/src/utils';
import { RoleSelector } from '@sparx/staff-manager/src/views/RoleSelector';
import { Button } from 'components/buttons';
import { addDays, addMonths, isAfter, isBefore } from 'date-fns';
import { combineQueryStates } from 'queries/client';
import { useAcademicYearStartDate, useSchoolCreateDate } from 'queries/schools';
import { useSession, useUpdateUserRolesDate } from 'queries/session';
import { Suspense, useMemo, useState } from 'react';
import { Flags, useBooleanFlag, useDateFlag } from 'utils/featureflags';
import { useTeacherAlert } from 'views/teacher/components/teacher-alert';
import { SuspenseStaffContextProvider } from 'views/teacher/staff-view/staff-manager-context';

import { toggleRole } from './toggleRole';

/**
 * RolePrompt is used to prompt teachers to update their staff manager roles and is designed to trigger,
 * at specific intervals, when a user logs into the Sparx Reader app.
 */
export const RolePrompt = () => {
  const [open, setOpen] = useState(true);
  return (
    <Suspense>
      <SuspenseStaffContextProvider>
        <RolePromptLoader isOpen={open} onClose={() => setOpen(false)} />
      </SuspenseStaffContextProvider>
    </Suspense>
  );
};

type RolePromptProps = {
  isOpen: boolean;
  onClose: () => void;
};

// Shows a role prompt when these conditions match:
// Unless overridden, don't show within 9 months of school creation, 21 days of
// school year start or if they've already been prompted this academic year.
// Suppressing and forcing the prompt are done using feature flags.
const RolePromptLoader = (props: RolePromptProps) => {
  const [now] = useState(Date.now());

  // Never show if this flag is set to true
  const suppressed = useBooleanFlag(Flags.SuppressRolePrompt);

  // Show if this date is in the past and after the last date shown, regardless of other criteria
  const overrideDate = useDateFlag(Flags.RolesDialogOverrideDate);

  const { data: session, ...sessionQueryState } = useSession();
  const { data: createDate, ...createDateQueryState } = useSchoolCreateDate();
  const { data: yearStartDate, ...yearStartQueryState } = useAcademicYearStartDate();
  const { data: staff } = useGetStaff(`staff/${session?.user?.sparxUserId}`, {
    suspense: false,
    enabled: Boolean(session?.user?.sparxUserId) && session?.user?.type === UserType.TEACHER,
  });

  const { isLoading, isError } = combineQueryStates(
    sessionQueryState,
    createDateQueryState,
    yearStartQueryState,
  );
  if (
    suppressed ||
    isLoading ||
    isError ||
    !staff ||
    !session?.user ||
    !createDate ||
    !yearStartDate
  ) {
    return null;
  }

  const lastPrompt = session.user.teacherRolesUpdateTime
    ? Timestamp.toDate(session.user.teacherRolesUpdateTime)
    : null;

  let shouldShow = false;
  if (overrideDate) {
    shouldShow = isAfter(now, overrideDate) && (!lastPrompt || isBefore(lastPrompt, overrideDate));
  } else {
    // Unless overridden, don't show within 9 months of school creation,
    // 21 days of school year start or if they've already been prompted this academic year
    const afterSchoolCreatedDelay = isAfter(now, addMonths(createDate, 9));
    const afterYearStart = isAfter(now, addDays(yearStartDate, 21));
    const notPromptedThisYear = !lastPrompt || isBefore(lastPrompt, yearStartDate);

    shouldShow = afterSchoolCreatedDelay && afterYearStart && notPromptedThisYear;
  }

  return shouldShow ? <RolePromptPresentation {...props} staffMember={staff} /> : null;
};

const RolePromptPresentation = (props: RolePromptProps & { staffMember: SchoolStaffMember }) => {
  const { isOpen, onClose, staffMember } = props;

  const [proposedRoles, setProposedRoles] = useState(staffMember.roles.slice());

  const { mutateAsync: update, status } = useUpsertStaff();
  const { mutate: setPromptShown } = useUpdateUserRolesDate();

  const { data: otherStaff } = useListStaff({
    select: data => data.filter(s => !staffMember || staffMember.name !== s.name),
  });
  const duplicateRoleWarning = useMemo(() => {
    const duplicateUniqueRoles = willStealUniqueRoles(proposedRoles, staffMember.roles, otherStaff);
    return duplicateUniqueRoles
      ? getDuplicateRoleWarningMessage(duplicateUniqueRoles, 'role-prompt')
      : undefined;
  }, [otherStaff, staffMember.roles, proposedRoles]);
  const noRolesWarning = useMemo(
    () => getNoRoleWarningMessage(proposedRoles, Product.SPARX_READER),
    [proposedRoles],
  );

  const toast = useTeacherAlert();

  return (
    <Modal isOpen={isOpen} onClose={onClose} overlayDismiss={false}>
      <Modal.Content width="xl">
        <Modal.Title>Please let us know if your role has changed</Modal.Title>
        <Modal.Body>
          <Stack direction="column" spacing={4}>
            <div>
              To optimise your Sparx experience, please review the role(s) that we hold for you
              below and update if necessary. All staff have the same administrative rights.
            </div>
            {noRolesWarning && (
              <Alert status="warning">
                <Alert.Icon />
                <div>{noRolesWarning}</div>
              </Alert>
            )}
            {duplicateRoleWarning && (
              <Alert status="warning">
                <Alert.Icon />
                <div>{duplicateRoleWarning}</div>
              </Alert>
            )}
            <RoleSelector
              compact
              hasRole={(role, product) => hasRole(proposedRoles, role, product)}
              toggle={(role, product) => setProposedRoles(r => toggleRole(r, { role, product }))}
            />
          </Stack>
        </Modal.Body>
        <Modal.Footer align="right">
          <Button
            variant="secondary"
            onClick={() => onClose()}
            analyticsEvent={{
              action: 'Dismissed User Role Prompt with "Not now"',
              category: 'user role prompt',
              labels: {
                staffName: staffMember.name,
              },
            }}
          >
            Not now
          </Button>
          <Button
            color="default"
            disabled={status === 'loading' || !!noRolesWarning}
            loading={status === 'loading'}
            onClick={() => {
              // Analytics
              update({
                staff: SchoolStaffMember.create({ name: staffMember.name, roles: proposedRoles }),
                updateMask: FieldMask.create({ paths: ['roles'] }),
              })
                .then(() => {
                  setPromptShown({ timestamp: Timestamp.now() });
                  toast({ type: 'success', message: 'Thanks for keeping us up to date!' });
                })
                .catch(() => {
                  toast({
                    type: 'warning',
                    message:
                      "We couldn't update your roles at this time. Please try again or use Staff Manager to update your details",
                  });
                })
                .finally(() => {
                  onClose();
                });
            }}
            analyticsEvent={{
              action: 'Dismissed User Role Prompt with "Save and continue"',
              category: 'user role prompt',
              labels: {
                staffName: staffMember.name,
              },
            }}
          >
            Save and continue
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
