// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/librarybooks/v1/librarybooks.proto" (package "sparx.reading.users.librarybooks.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { LibraryBooks } from './librarybooks';
import type { ListBookChoiceResponse } from './librarybooks';
import type { ListBookChoiceRequest } from './librarybooks';
import type { ListOnboardingLibraryBooksResponse } from './librarybooks';
import type { ListOnboardingLibraryBooksRequest } from './librarybooks';
import type { UpdateLibraryBookResponse } from './librarybooks';
import type { UpdateLibraryBookRequest } from './librarybooks';
import type { ListLibraryBooksResponse } from './librarybooks';
import type { ListLibraryBooksRequest } from './librarybooks';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { ListNewBooksResponse } from './librarybooks';
import type { ListNewBooksRequest } from './librarybooks';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.users.librarybooks.v1.LibraryBooks
 */
export interface ILibraryBooksClient {
  /**
   * Run the book choice algo and return any new books. An empty list means
   * user is not eligible for new books. If we cannot find any eligible books
   * or the user has not finished onboarding it will return an error.
   *
   * @generated from protobuf rpc: ListNewBooks(sparx.reading.users.librarybooks.v1.ListNewBooksRequest) returns (sparx.reading.users.librarybooks.v1.ListNewBooksResponse);
   */
  listNewBooks(
    input: ListNewBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListNewBooksRequest, ListNewBooksResponse>;
  /**
   * List student books combined with metadata. This does not contain
   * onboarding books so will fail if the user has not yet finished onboarding.
   *
   * @generated from protobuf rpc: ListLibraryBooks(sparx.reading.users.librarybooks.v1.ListLibraryBooksRequest) returns (sparx.reading.users.librarybooks.v1.ListLibraryBooksResponse);
   */
  listLibraryBooks(
    input: ListLibraryBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListLibraryBooksRequest, ListLibraryBooksResponse>;
  /**
   * Update a library book.
   *
   * @generated from protobuf rpc: UpdateLibraryBook(sparx.reading.users.librarybooks.v1.UpdateLibraryBookRequest) returns (sparx.reading.users.librarybooks.v1.UpdateLibraryBookResponse);
   */
  updateLibraryBook(
    input: UpdateLibraryBookRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateLibraryBookRequest, UpdateLibraryBookResponse>;
  /**
   * List training books for the user
   *
   * @generated from protobuf rpc: ListOnboardingLibraryBooks(sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksRequest) returns (sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksResponse);
   */
  listOnboardingLibraryBooks(
    input: ListOnboardingLibraryBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListOnboardingLibraryBooksRequest,
    ListOnboardingLibraryBooksResponse
  >;
  /**
   * Temporary endpoint for testing new book choice algorithm.
   *
   * @generated from protobuf rpc: ListBookChoice(sparx.reading.users.librarybooks.v1.ListBookChoiceRequest) returns (sparx.reading.users.librarybooks.v1.ListBookChoiceResponse);
   */
  listBookChoice(
    input: ListBookChoiceRequest,
    options?: RpcOptions,
  ): UnaryCall<ListBookChoiceRequest, ListBookChoiceResponse>;
}
/**
 * @generated from protobuf service sparx.reading.users.librarybooks.v1.LibraryBooks
 */
export class LibraryBooksClient implements ILibraryBooksClient, ServiceInfo {
  typeName = LibraryBooks.typeName;
  methods = LibraryBooks.methods;
  options = LibraryBooks.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Run the book choice algo and return any new books. An empty list means
   * user is not eligible for new books. If we cannot find any eligible books
   * or the user has not finished onboarding it will return an error.
   *
   * @generated from protobuf rpc: ListNewBooks(sparx.reading.users.librarybooks.v1.ListNewBooksRequest) returns (sparx.reading.users.librarybooks.v1.ListNewBooksResponse);
   */
  listNewBooks(
    input: ListNewBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListNewBooksRequest, ListNewBooksResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListNewBooksRequest, ListNewBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * List student books combined with metadata. This does not contain
   * onboarding books so will fail if the user has not yet finished onboarding.
   *
   * @generated from protobuf rpc: ListLibraryBooks(sparx.reading.users.librarybooks.v1.ListLibraryBooksRequest) returns (sparx.reading.users.librarybooks.v1.ListLibraryBooksResponse);
   */
  listLibraryBooks(
    input: ListLibraryBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListLibraryBooksRequest, ListLibraryBooksResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListLibraryBooksRequest, ListLibraryBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Update a library book.
   *
   * @generated from protobuf rpc: UpdateLibraryBook(sparx.reading.users.librarybooks.v1.UpdateLibraryBookRequest) returns (sparx.reading.users.librarybooks.v1.UpdateLibraryBookResponse);
   */
  updateLibraryBook(
    input: UpdateLibraryBookRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateLibraryBookRequest, UpdateLibraryBookResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateLibraryBookRequest, UpdateLibraryBookResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * List training books for the user
   *
   * @generated from protobuf rpc: ListOnboardingLibraryBooks(sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksRequest) returns (sparx.reading.users.librarybooks.v1.ListOnboardingLibraryBooksResponse);
   */
  listOnboardingLibraryBooks(
    input: ListOnboardingLibraryBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListOnboardingLibraryBooksRequest,
    ListOnboardingLibraryBooksResponse
  > {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListOnboardingLibraryBooksRequest,
      ListOnboardingLibraryBooksResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Temporary endpoint for testing new book choice algorithm.
   *
   * @generated from protobuf rpc: ListBookChoice(sparx.reading.users.librarybooks.v1.ListBookChoiceRequest) returns (sparx.reading.users.librarybooks.v1.ListBookChoiceResponse);
   */
  listBookChoice(
    input: ListBookChoiceRequest,
    options?: RpcOptions,
  ): UnaryCall<ListBookChoiceRequest, ListBookChoiceResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListBookChoiceRequest, ListBookChoiceResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
