import {
  GetWordDefinitionResponse,
  UserDefinitionState,
  WordDefinitionFeedback,
} from '@sparx/api/apis/sparx/reading/content/v1/definitions';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { definitionsClient } from 'api';
import { queryClient } from 'queries/client';

export interface SelectedWord {
  word: string;
  context: string;
  partId: string;
  blocked?: boolean;
  bookId?: string;
}

export const useUserDefinitionState = (
  bookId: string,
  options?: UseQueryOptions<UserDefinitionState, Error, UserDefinitionState, string[]>,
) =>
  useQuery({
    queryKey: ['user', 'definitions', bookId],
    queryFn: () => definitionsClient.getUserDefinitionState({ bookId }).response,
    enabled: Boolean(bookId),
    cacheTime: Infinity,
    staleTime: Infinity,
    ...options,
  });

export const alreadyHaveWord = (state: UserDefinitionState, bookID: string, word: SelectedWord) =>
  state.definitions.find(
    w =>
      w.word === word.word.toLowerCase().trim() && w.bookId === bookID && w.partId === word.partId,
  );

interface WordDefinitionResponse extends GetWordDefinitionResponse {
  existing?: boolean;
}

export const useWordDefinition = (
  bookId: string,
  word: SelectedWord,
  options?: UseQueryOptions<
    WordDefinitionResponse,
    Error,
    WordDefinitionResponse,
    [string, string, SelectedWord]
  >,
) => {
  const { data: state } = useUserDefinitionState(bookId);

  return useQuery(
    ['user', 'definitions', word],
    async () => {
      const found = state && alreadyHaveWord(state, bookId, word);
      if (found) {
        return { definition: found, existing: true };
      }
      // Request from server instead
      return definitionsClient.getWordDefinition({
        bookId: bookId,
        partId: word.partId,
        word: word.word,
        wordContext: word.context,
      }).response;
    },
    {
      enabled: Boolean(word && word.context),
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user', 'definitions', bookId] }),
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: 0,
      ...options,
    },
  );
};

export const useDefinitionFeedback = (definitionId: string) =>
  useMutation(
    ['user', 'definitions', definitionId, 'feedback'],
    async (feedback: WordDefinitionFeedback) =>
      definitionsClient.submitWordDefinitionFeedback({ definitionId, feedback }),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user', 'definitions'] }),
    },
  );
