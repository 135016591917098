import { ProgressWheel } from '@sparx/sparx-design/components';
import { AnalyticEventFields } from 'components/client-events/client-event-provider';
import { Link } from 'components/link';
import { Suspense } from 'react';
import { View } from 'views';
import {
  useGettingStartedHeaderLink,
  useGettingStartedProgress,
} from 'views/teacher/new-year-setup-view/hooks';
import { SuspenseStaffContextProvider } from 'views/teacher/staff-view/staff-manager-context';
import { TrainingContext } from 'views/teacher/teacher-training-view/training-context';
import { pathForView } from 'views/views';

import styles from './getting-started-progress.module.css';

const CATEGORY = 'getting-started-progress';

const clickedButton = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked button',
});

const Progress = () => {
  const { isGettingStarted, isLoading, isError } = useGettingStartedHeaderLink();
  const progress = useGettingStartedProgress();

  if (isLoading || progress.isLoading || !isGettingStarted || isError) {
    return null;
  }

  return (
    <Link to={pathForView(View.GettingStarted)} analyticsEvent={clickedButton()}>
      <div className={styles.Root}>
        <div className={styles.Label}>Getting started</div>
        <div className={styles.Progress}>
          <ProgressWheel
            completed={progress.completed}
            total={progress.total}
            hideProgressText={true}
            wheelSize={{ barWidth: 4, outerCircleRadius: 10 }}
            classes={{ innerCircle: styles.ProgressInnerCircle }}
          />
        </div>{' '}
        {progress.completed}/{progress.total}
      </div>
    </Link>
  );
};

/**
 * Section of the header that shows the user's progress towards getting started steps.
 * @constructor
 */
export const GettingStartedProgress = () => (
  <Suspense fallback={undefined}>
    <SuspenseStaffContextProvider>
      <TrainingContext>
        <Progress />
      </TrainingContext>
    </SuspenseStaffContextProvider>
  </Suspense>
);
