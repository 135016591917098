import { GetGoldReaderStateResponse } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { useQuery } from '@tanstack/react-query';
import { sessionsClient } from 'api';
import { queryClient } from 'queries/client';

export const useGoldReaderState = () =>
  useQuery(['user', 'gold'], () => sessionsClient.getGoldReaderState({}).response, {
    // We manually trigger a refetch only when experience has been awarded.
    staleTime: Infinity,
    cacheTime: Infinity,
    select: data => data.goldReaderState,
  });

export const useLastGoldReaderState = () =>
  useQuery<GetGoldReaderStateResponse>(['user', 'gold', 'previous']);

export const setPreviousGoldReaderState = () => {
  const previousState = queryClient.getQueryData(['user', 'gold']);
  if (previousState) {
    queryClient.setQueryData(['user', 'gold', 'previous'], previousState);
  }
};

export const useIsGoldReader = () => Boolean(useGoldReaderState()?.data?.passExpiry);
