import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'components/buttons/button';
import goldIcon from 'components/sections/gold_icon_white.png';
import silverIcon from 'components/sections/silver_icon_white.png';
import { Title } from 'components/title/title';
import {
  useCanStartNewBook,
  useListCompletedLibraryBooks,
  useListGoldReaderBooks,
  useListMyLibraryBooks,
} from 'queries/library-books';
import { useReaderLevel } from 'queries/session';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import {
  LibraryContent,
  LibraryTitle,
} from 'views/student/library-view/components/library-content';
import styles from 'views/student/library-view/subviews/book-list-view.module.css';
import { pathForView } from 'views/views';

import { BooksSection } from '../components/books-section';
import { MyBookShelf } from '../components/my-book-shelf';

export const BookListView = () => {
  const navigate = useNavigate();
  const readerLevel = useReaderLevel();
  const toAddGoldReaderBookView = () => navigate(pathForView(View.AddGoldReaderBook));

  const canStartNewBook = useCanStartNewBook();

  const { data: myBooks } = useListMyLibraryBooks();
  const { data: completedBooks } = useListCompletedLibraryBooks();
  const { data: goldReaderBooks } = useListGoldReaderBooks();

  return (
    <LibraryContent>
      <Title>Library</Title>
      <LibraryTitle>My ebook shelf</LibraryTitle>
      <MyBookShelf books={myBooks || []} canStartNewBook={canStartNewBook} />
      {goldReaderBooks && goldReaderBooks.length > 0 && (
        <BooksSection title={'Gold Reader books'} books={goldReaderBooks} />
      )}
      {readerLevel === 'gold' && (
        <div className={classNames(styles.GoldReaderAdd, styles.GoldReaderAddActive)}>
          <span>
            <img alt="" src={goldIcon} />
            You&apos;re a Gold Reader, you can add your own books
          </span>
          <Button color="danger" onClick={toAddGoldReaderBookView} analyticsEvent={undefined}>
            Add book <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      )}
      {readerLevel === 'silver' && (
        <div className={classNames(styles.GoldReaderAdd, styles.SilverReaderAddActive)}>
          <span>
            <img alt="" src={silverIcon} />
            You&apos;re a Silver Reader, you can add silver reader books
          </span>
          <Button color="danger" onClick={toAddGoldReaderBookView} analyticsEvent={undefined}>
            Add book <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      )}
      {readerLevel === 'default' && (
        <div className={styles.GoldReaderAdd}>
          <span>Gold Readers can add their own books</span>
          <Button onClick={toAddGoldReaderBookView} analyticsEvent={undefined}>
            Find out more
          </Button>
        </div>
      )}
      <BooksSection books={completedBooks} title={'Completed books'} />
    </LibraryContent>
  );
};
