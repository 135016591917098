import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GetLeaderboardResponse,
  LeaderboardDateRange,
} from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { GetUserDisplayDataForCurrentUserRequest } from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay';
import { User } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { UseQueryResult } from '@tanstack/react-query';
import { HomeBox } from 'components/home-box/home-box';
import { useGetUserOptedInToLeagueTable, useStudentClassLeaderboard } from 'queries/leagueTables';
import { useUser } from 'queries/session';
import { useIsStudentDemoMode } from 'utils/student-demo';
import styles from 'views/student/library-view/subviews/home-view.module.css';

import { ErrorLoading, Loading } from '../../home-view';
import { CurrentPosition } from './components/current-position';
import { EarnSRP } from './components/earn-srp';
import { LeagueNotEnabled } from './components/league-not-enabled';
import { LeagueNotJoined } from './components/league-not-joined';

export const LeagueStat = () => {
  const user = useUser();
  const leagueTable = useStudentClassLeaderboard(LeaderboardDateRange.THIS_YEAR);
  const isStudentDemoMode = useIsStudentDemoMode();
  const optedInRequest = useGetUserOptedInToLeagueTable();

  return (
    <HomeBox>
      <h3>
        <FontAwesomeIcon icon={faCrown} />
        Leaderboard
      </h3>
      <div className={styles.StatBox}>
        {body(user, leagueTable, optedInRequest, isStudentDemoMode)}
      </div>
    </HomeBox>
  );
};

const body = (
  user: User | undefined,
  leagueTableRequest: UseQueryResult<GetLeaderboardResponse>,
  optedInRequest: UseQueryResult<GetUserDisplayDataForCurrentUserRequest>,
  isStudentDemoMode: boolean,
) => {
  const optedIn = optedInRequest.data;
  if (isStudentDemoMode) {
    return <LeagueNotEnabled />;
  }
  if (user && !user.studentGroupId) {
    return <EarnSRP />;
  }
  if (
    (leagueTableRequest.isLoading && leagueTableRequest.fetchStatus !== 'idle') ||
    optedInRequest.isLoading
  ) {
    return <Loading />;
  }
  if (leagueTableRequest.isError || optedInRequest.isError) {
    return <ErrorLoading name="leaderboard" />;
  }
  if (!optedIn) {
    return <LeagueNotJoined />;
  }

  for (const leaderboardUser of leagueTableRequest.data?.users || []) {
    if (leaderboardUser.isCurrentUser) {
      return <CurrentPosition leaderboardUser={leaderboardUser} />;
    }
  }

  return <EarnSRP />;
};
