import {
  BooklogTask_State_Stage,
  SubmitBooklogAnswer,
} from '@sparx/api/apis/sparx/reading/tasks/v1/booklog';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import classNames from 'classnames';
import { BookDescriptor } from 'components/alert/library-book-panel';
import { Button } from 'components/buttons/button';
import { ErrorMessage } from 'components/errors/error-message';
import { Loading } from 'components/loading/loading';
import { PaperbackAnswerInput } from 'components/tasks/paperback-answer-panel';
import { ScoredTaskCompleteModal } from 'components/tasks/task-complete-modal';
import { Title } from 'components/title/title';
import { useUserCanReadBook } from 'queries/session';
import { getBooklogTaskState, usePackageTasks } from 'queries/tasks';
import { useBooklogActions } from 'utils/tasks';
import { timestampToMoment } from 'utils/time';
import { CustomBookBlacklisted } from 'views/student/library-view/subviews/custom-book-page/custom-book-blacklisted';
import taskStyles from 'views/student/task-view/task-view.module.css';

import { BooklogTaskQuestions } from './booklog-task-questions';
import styles from './booklog-task-view.module.css';
import { StartInputSectionSilverReader } from './stages/stage-logging';
import { StageLoggingEnd } from './stages/stage-logging-end';
import { StageTimingOrPaused } from './stages/stage-timing-or-paused';
import { StageWriting } from './stages/stage-writing';
import { StartSession } from './stages/start-session';
import { getTimerStats } from './utils';
import { WizardStep, WizardSteps } from './wizard-steps';

interface IBooklogTaskViewProps {
  task: Task;
  bookMeta: MetadataAbridged;
}

export const BooklogTaskView = ({ task, bookMeta }: IBooklogTaskViewProps) => {
  const actions = useBooklogActions(task.taskId);
  const { allowedToRead, isSilverReaderBook } = useUserCanReadBook(bookMeta);
  const { data, isLoading } = usePackageTasks(task.packageId);

  const tasks = data?.tasks.filter(t => t.taskId !== task.taskId);
  tasks?.sort((a, b) =>
    timestampToMoment(a.state?.completionDate)?.isBefore(timestampToMoment(b.state?.completionDate))
      ? 1
      : -1,
  );

  const { state, isSilverReader } = getTimerStats(task);
  const stage = state?.stage;

  if (bookMeta.blacklisted) {
    return (
      <ErrorMessage
        message={`This book is not available`}
        backButtonLink="/library"
        backButtonText="Back to library"
      >
        <CustomBookBlacklisted />
      </ErrorMessage>
    );
  }
  if (!allowedToRead) {
    return (
      <ErrorMessage
        message={`You need to be a ${isSilverReaderBook ? 'Silver' : 'Gold'} Reader to read ${
          bookMeta.title
        }`}
        backButtonLink="/library"
        backButtonText="Back to library"
      >
        Once you have unlocked {isSilverReaderBook ? 'Silver' : 'Gold'} Reader you will be able to
        come back and read this book.
      </ErrorMessage>
    );
  }

  let content = null;

  switch (stage) {
    case BooklogTask_State_Stage.LOGGING:
      if (isSilverReader) {
        content = <StartInputSectionSilverReader onSubmit={actions.sendRangeAction} task={task} />;
      } else {
        content = <StartSession onSubmit={actions.sendRangeAction} task={task} />;
      }
      break;
    case BooklogTask_State_Stage.TIMING:
      content = <StageTimingOrPaused task={task} actions={actions} />;
      break;
    case BooklogTask_State_Stage.PAUSED:
      content = <StageTimingOrPaused task={task} actions={actions} />;
      break;
    case BooklogTask_State_Stage.LOGGING_END:
      content = (
        <StageLoggingEnd
          onSubmit={actions.sendRangeAction}
          onBack={actions.sendBackAction}
          task={task}
        />
      );
      break;
    case BooklogTask_State_Stage.QUESTIONS_WARNING:
      content = (
        <div className={styles.LogWarning}>
          <p>
            <strong>Warning</strong>
          </p>
          <p>
            Go back to{' '}
            <strong>page {state?.silverReaderProposedEndPage || state?.end?.page}</strong>.
          </p>
          <p>Please read you book carefully.</p>
          <p>You will lose Silver Reader if you do not answer the question correctly.</p>
          <Button
            analyticsEvent={undefined}
            className={styles.LogWarningButton}
            loading={actions.isLoading}
            onClick={() => actions.sendQuestionAnswer({ answer: '', questionId: '' })}
          >
            I understand
          </Button>
        </div>
      );
      break;

    case BooklogTask_State_Stage.WRITING:
      content = (
        <StageWriting
          onSubmit={actions.sendQuestionInput}
          onBack={actions.sendBackAction}
          task={task}
        />
      );
      break;
    case BooklogTask_State_Stage.QUESTIONS:
    case BooklogTask_State_Stage.QUESTIONS_RESULT:
      content = <BooklogQuestionSection onSubmit={actions.sendQuestionAnswer} task={task} />;
  }

  return (
    <div className={'scrollable ' + styles.BooklogContent}>
      <Title>{bookMeta?.title || ''}</Title>
      <div className={classNames(taskStyles.ReadingContent, 'content')}>
        <div className={styles.BookAndSteps}>
          <div className={styles.BookDescriptorContainer}>
            {bookMeta && <BookDescriptor book={bookMeta} noMeta={true} small />}
          </div>
          {stage !== BooklogTask_State_Stage.LOGGING && (
            <WizardSteps>
              <WizardStep
                active={
                  stage === BooklogTask_State_Stage.TIMING ||
                  stage === BooklogTask_State_Stage.PAUSED
                }
                done={Boolean(stage && (stage > 3 || stage === 2) && stage !== 9)}
                step={'read'}
                includeLine
              />
              <WizardStep
                active={stage === BooklogTask_State_Stage.LOGGING_END}
                done={Boolean(stage && (stage > 4 || stage === 2) && stage !== 9)}
                step={'record'}
                includeLine
              />
              <WizardStep
                active={
                  stage === BooklogTask_State_Stage.WRITING ||
                  stage === BooklogTask_State_Stage.QUESTIONS ||
                  stage === BooklogTask_State_Stage.QUESTIONS_RESULT
                }
                done={false}
                step={'question'}
                includeLine={false}
              />
            </WizardSteps>
          )}
        </div>
        {content}
        <div className={styles.Tasks}>
          {tasks && tasks.length > 0 && (
            <>
              <h2 className={styles.StageTitle}>Previous reading sessions</h2>
              {tasks.map(task => (
                <BooklogTaskQuestions key={task.taskId} task={task} />
              ))}
            </>
          )}
        </div>
        {isLoading && <Loading />}
        <div>
          <ScoredTaskCompleteModal
            state={task.state}
            retry={actions.retry}
            isOpen={task.state?.completed}
            task={task}
            requirementsMetMessage={!state?.seenQuestions.length}
            hideTaskCheck={state?.endedPrematurely}
          />
        </div>
      </div>
    </div>
  );
};

interface IBooklogQuestionSectionProps {
  task: Task;
  onSubmit: (req: SubmitBooklogAnswer) => void;
}

export const BooklogQuestionSection = ({ task, onSubmit }: IBooklogQuestionSectionProps) => {
  const booklogState = getBooklogTaskState(task);
  if (!booklogState) {
    return null;
  }
  if (task.state?.completed) {
    return null;
  }
  const { currentQuestion: question, lastQuestionResult: result } = booklogState;
  if (!question) {
    return <></>;
  }
  return (
    <div>
      <PaperbackAnswerInput
        question={question}
        questionIndex={booklogState.currentQuestionIndex || 1}
        submit={answer => onSubmit({ answer, questionId: question.questionId })}
        finishViewingResult={() => onSubmit({ answer: '', questionId: question.questionId })}
        inline={true}
        bigOptions={true}
        result={result}
      />
    </div>
  );
};
