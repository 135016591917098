import { GetQuestionResponse } from '@sparx/api/apis/sparx/reading/vocab/v1/vocab';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { vocabClient } from 'api';

export const useVocabQuestion = <TData = GetQuestionResponse>(
  options: UseQueryOptions<GetQuestionResponse, Error, TData>,
) =>
  useQuery<GetQuestionResponse, Error, TData>(
    ['vocab', 'assignment'],
    () => vocabClient.getQuestion({}).response,
    {
      staleTime: Infinity,
      retry: false, // one failure show error
      ...options,
    },
  );

export const useVocabState = () =>
  useQuery(['vocab', 'state'], () => vocabClient.getState({}).response, {
    staleTime: 100000,
    retry: 2,
  });
