// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/silver/v1/bookmarks.proto" (package "sparx.reading.silver.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Bookmarks } from './bookmarks';
import type { ListBookmarksResponse } from './bookmarks';
import type { ListBookmarksRequest } from './bookmarks';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GenerateBookmarksResponse } from './bookmarks';
import type { GenerateBookmarksRequest } from './bookmarks';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.silver.v1.Bookmarks
 */
export interface IBookmarksClient {
  /**
   * GenerateBookmarks generates bookmarks for the given student group.
   * It will also ensure that all of the students are silver readers.
   *
   * @generated from protobuf rpc: GenerateBookmarks(sparx.reading.silver.v1.GenerateBookmarksRequest) returns (sparx.reading.silver.v1.GenerateBookmarksResponse);
   */
  generateBookmarks(
    input: GenerateBookmarksRequest,
    options?: RpcOptions,
  ): UnaryCall<GenerateBookmarksRequest, GenerateBookmarksResponse>;
  /**
   * ListBookmarks lists all of the bookmarks for the given school.
   *
   * @generated from protobuf rpc: ListBookmarks(sparx.reading.silver.v1.ListBookmarksRequest) returns (sparx.reading.silver.v1.ListBookmarksResponse);
   */
  listBookmarks(
    input: ListBookmarksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListBookmarksRequest, ListBookmarksResponse>;
}
/**
 * @generated from protobuf service sparx.reading.silver.v1.Bookmarks
 */
export class BookmarksClient implements IBookmarksClient, ServiceInfo {
  typeName = Bookmarks.typeName;
  methods = Bookmarks.methods;
  options = Bookmarks.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GenerateBookmarks generates bookmarks for the given student group.
   * It will also ensure that all of the students are silver readers.
   *
   * @generated from protobuf rpc: GenerateBookmarks(sparx.reading.silver.v1.GenerateBookmarksRequest) returns (sparx.reading.silver.v1.GenerateBookmarksResponse);
   */
  generateBookmarks(
    input: GenerateBookmarksRequest,
    options?: RpcOptions,
  ): UnaryCall<GenerateBookmarksRequest, GenerateBookmarksResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GenerateBookmarksRequest, GenerateBookmarksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * ListBookmarks lists all of the bookmarks for the given school.
   *
   * @generated from protobuf rpc: ListBookmarks(sparx.reading.silver.v1.ListBookmarksRequest) returns (sparx.reading.silver.v1.ListBookmarksResponse);
   */
  listBookmarks(
    input: ListBookmarksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListBookmarksRequest, ListBookmarksResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListBookmarksRequest, ListBookmarksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
