import {
  CreateSchoolStaffMemberRequest,
  DeleteSchoolStaffMemberRequest,
  SchoolStaffMember,
  UpdateSchoolStaffMemberRequest,
} from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { IdentityUserType } from '@sparx/api/apis/sparx/school/v2/schoolactions';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { FieldMask } from '@sparx/api/google/protobuf/field_mask';
import { FetchQueryOptions, useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { StaffContext, useStaffContext } from './Context';
import {
  getKeyContactsStatus,
  productKeyContactRoles,
  productOrder,
  sharedKeyContactRoles,
  StaffKeyContacts,
} from './utils';

type UseStaffOptions<T = SchoolStaffMember[], Q = T> = Pick<
  UseQueryOptions<Q, unknown, T>,
  'suspense' | 'enabled' | 'select'
> & { refetchOnMount?: boolean | 'always' };

export type KeyContactData = {
  allStaff: SchoolStaffMember[];
  keyContacts: StaffKeyContacts;
  status: ReturnType<typeof getKeyContactsStatus>;
};

export const keyContactRoles = [...productKeyContactRoles, ...sharedKeyContactRoles] as const;

export const useStaffProducts = () => {
  // Get the school products, don't include the Curriculum
  const { school, defaultProduct } = useStaffContext();
  const products = school?.products.filter(
    p => p !== Product.SPARXMATHS_CURRICULUM && p !== Product.PRODUCT_UNKNOWN,
  ) || [defaultProduct];
  return products.sort((a, b) =>
    productOrder[a] > productOrder[b] ? 1 : productOrder[a] < productOrder[b] ? -1 : 0,
  );
};

const queryKey = ['staffv2.SchoolStaffMember'];
const getStaffQueryKey = (name: string) => [queryKey, name];

/**
 * Returns query options (including a query key) which can be passed to `useQuery` for fetching a staff list, given
 * dependencies for the staff client and a function for obtaining the school ID. Use this in any application which
 * is consuming the staff manager package but wants to make its own calls, to keep the query cache in sync.
 */
export const listStaffQuery = (
  deps: Pick<StaffContext, 'staffClient' | 'getSchoolID'>,
): FetchQueryOptions<SchoolStaffMember[]> => ({
  queryKey,
  queryFn: async () => {
    const { staffMembers } = await deps.staffClient.listSchoolStaffMembers({
      school: `schools/${await deps.getSchoolID()}`,
    }).response;
    return staffMembers;
  },
});

export const useListStaff = <T = SchoolStaffMember[]>(
  options: UseStaffOptions<T, SchoolStaffMember[]>,
) => {
  const { staffClient, getSchoolID } = useStaffContext();
  return useQuery({
    ...listStaffQuery({ staffClient, getSchoolID }),
    ...options,
  });
};

export const useGetStaff = (name: string, options: UseStaffOptions<SchoolStaffMember>) => {
  const { staffClient, getSchoolID } = useStaffContext();
  return useQuery(
    getStaffQueryKey(name),
    async () => {
      return staffClient.getSchoolStaffMember({
        school: `schools/${await getSchoolID()}`,
        name: name,
      }).response;
    },
    options,
  );
};

export const useStaffKeyContacts = (options: UseStaffOptions<KeyContactData>) => {
  const { defaultProduct } = useStaffContext();
  return useListStaff({
    ...options,
    select: (staffMembers): KeyContactData => {
      const keyContacts = staffMembers.reduce<StaffKeyContacts>((acc, sm) => {
        sm.roles.forEach(sr => {
          if (sr.product === defaultProduct && productKeyContactRoles.includes(sr.role)) {
            acc[sr.role] = sm;
          }
          if (sharedKeyContactRoles.includes(sr.role)) {
            acc[sr.role] = sm;
          }
        });
        return acc;
      }, {} as StaffKeyContacts);
      const status = getKeyContactsStatus(keyContacts);
      return {
        allStaff: staffMembers,
        keyContacts,
        status,
      };
    },
  });
};

const getUpsertFn =
  (ctx: StaffContext) => async (update: { staff: SchoolStaffMember; updateMask: FieldMask }) => {
    if (update.staff.name) {
      return await ctx.staffClient.updateSchoolStaffMember(
        UpdateSchoolStaffMemberRequest.create({
          staffMember: { ...update.staff, school: `schools/${await ctx.getSchoolID()}` },
          updateMask: update.updateMask,
          product: ctx.defaultProduct,
        }),
      ).response;
    }
    return await ctx.staffClient.createSchoolStaffMember(
      CreateSchoolStaffMemberRequest.create({
        staffMember: { ...update.staff, school: `schools/${await ctx.getSchoolID()}` },
        product: ctx.defaultProduct,
      }),
    ).response;
  };

export const useUpsertStaff = () => {
  const ctx = useStaffContext();
  const mutationFn = getUpsertFn(ctx);

  return useMutation({
    mutationFn,
    onSuccess: async data => {
      await ctx.queryClient.invalidateQueries(queryKey);
      ctx.queryClient.setQueryData(getStaffQueryKey(data.name), data);
      return;
    },
  });
};

const getDeleteFn = (ctx: StaffContext) => async (req: { name: string }) =>
  ctx.staffClient.deleteSchoolStaffMember(
    DeleteSchoolStaffMemberRequest.create({
      ...req,
      school: `schools/${await ctx.getSchoolID()}`,
    }),
  ).response;

export const useSafeDeleteStaff = (product: Product) => {
  const ctx = useStaffContext();
  return useMutation({
    mutationFn: async (data: SchoolStaffMember): Promise<SchoolStaffMember> => {
      if (data.name === '') {
        throw new Error('Name is required for delete operation');
      }

      // Shallow copy the input and update the roles
      data = { ...data };
      data.roles = data.roles.filter(r => r.product !== product);

      if (data.roles.length === 0) {
        return getDeleteFn(ctx)({ name: data.name });
      }

      const mask = FieldMask.create({ paths: ['roles'] });
      return getUpsertFn(ctx)({
        updateMask: mask,
        staff: data,
      });
    },
    onSuccess: async () => {
      await ctx.queryClient.invalidateQueries(queryKey);
      return;
    },
  });
};

export const useUnlinkSSOAccount = () => {
  const { schoolActionsClient, getSchoolID } = useStaffContext();
  return useMutation({
    mutationFn: async (staffName: string) => {
      if (staffName === '') {
        throw new Error('staff name is required to unlink sso account');
      }

      const schoolID = await getSchoolID();
      const schoolName = `schools/${schoolID}`;

      return schoolActionsClient.unlinkSSOIdentity({
        userId: staffName.replace(/staff\//, ''),
        userType: IdentityUserType.STAFF,
        schoolName,
      }).response;
    },
  });
};

export const useSendWelcomeEmails = () => {
  const { staffClient, getSchoolID, defaultProduct } = useStaffContext();
  return useMutation(async ({ names }: { names: string[] }) => {
    const school = await getSchoolID();
    return await Promise.all(
      names.map(name =>
        staffClient.sendWelcomeEmail({
          name,
          school: `schools/${school}`,
          product: defaultProduct,
        }),
      ),
    );
  });
};
