import {
  NotificationBook,
  NotificationBook_Type,
} from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import { useLibraryBook } from 'queries/library-books';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { View } from 'views';
import { pathForView } from 'views/views';

import { INotificationWrapperProps, Notification } from './notification';

interface Props extends INotificationWrapperProps {
  book: NotificationBook;
}

export const BookNotification: React.FC<Props> = ({ notification, book }) => {
  const navigate = useNavigate();
  const { data: libraryBook, isLoading } = useLibraryBook(book.bookId);
  if (isLoading || !libraryBook) {
    // We don't know what book it is :(
    return null;
  }

  let title = 'New chapters available';
  let content = (
    <>
      You can now read more of <i>{libraryBook.metadataAbridged?.title}</i>.
    </>
  );
  if (book.type === NotificationBook_Type.NEW_BOOK) {
    title = 'New book available';
    content = (
      <>
        <i>{libraryBook.metadataAbridged?.title}</i> is now available from the Library.
      </>
    );
  }

  const goToNotification = () => {
    const pathname = pathForView(
      book.type === NotificationBook_Type.NEW_BOOK ? View.Explore : View.Library,
    );

    return navigate({
      pathname,
      search: createSearchParams({
        book: libraryBook?.metadataAbridged?.name.replace('books/', '') || '',
      }).toString(),
    });
  };

  return (
    <Notification title={title} notification={notification} onClick={() => goToNotification()}>
      {content}
    </Notification>
  );
};
