// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/content/v1/assignment.proto" (package "sparx.reading.content.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { AssignmentService } from './assignment';
import type { ListAssignmentsResponse } from './assignment';
import type { ListAssignmentsRequest } from './assignment';
import type { UpdateAssignmentRequest } from './assignment';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { Assignment } from './assignment';
import type { CreateAssignmentRequest } from './assignment';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
// Resources
// users/*/assignments

/**
 * @generated from protobuf service sparx.reading.content.v1.AssignmentService
 */
export interface IAssignmentServiceClient {
  /**
   * Create an assignment
   *
   * @generated from protobuf rpc: CreateAssignment(sparx.reading.content.v1.CreateAssignmentRequest) returns (sparx.reading.content.v1.Assignment);
   */
  createAssignment(
    input: CreateAssignmentRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateAssignmentRequest, Assignment>;
  /**
   * Update an assignment
   *
   * @generated from protobuf rpc: UpdateAssignment(sparx.reading.content.v1.UpdateAssignmentRequest) returns (sparx.reading.content.v1.Assignment);
   */
  updateAssignment(
    input: UpdateAssignmentRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateAssignmentRequest, Assignment>;
  /**
   * List all assignments.
   * If a user id of '-' is provided, return all assignments for all users.
   * If a user id is provided, return all assignments for that user.
   *
   * @generated from protobuf rpc: ListAssignments(sparx.reading.content.v1.ListAssignmentsRequest) returns (sparx.reading.content.v1.ListAssignmentsResponse);
   */
  listAssignments(
    input: ListAssignmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssignmentsRequest, ListAssignmentsResponse>;
}
// Resources
// users/*/assignments

/**
 * @generated from protobuf service sparx.reading.content.v1.AssignmentService
 */
export class AssignmentServiceClient
  implements IAssignmentServiceClient, ServiceInfo
{
  typeName = AssignmentService.typeName;
  methods = AssignmentService.methods;
  options = AssignmentService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * Create an assignment
   *
   * @generated from protobuf rpc: CreateAssignment(sparx.reading.content.v1.CreateAssignmentRequest) returns (sparx.reading.content.v1.Assignment);
   */
  createAssignment(
    input: CreateAssignmentRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateAssignmentRequest, Assignment> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CreateAssignmentRequest, Assignment>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Update an assignment
   *
   * @generated from protobuf rpc: UpdateAssignment(sparx.reading.content.v1.UpdateAssignmentRequest) returns (sparx.reading.content.v1.Assignment);
   */
  updateAssignment(
    input: UpdateAssignmentRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateAssignmentRequest, Assignment> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateAssignmentRequest, Assignment>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * List all assignments.
   * If a user id of '-' is provided, return all assignments for all users.
   * If a user id is provided, return all assignments for that user.
   *
   * @generated from protobuf rpc: ListAssignments(sparx.reading.content.v1.ListAssignmentsRequest) returns (sparx.reading.content.v1.ListAssignmentsResponse);
   */
  listAssignments(
    input: ListAssignmentsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAssignmentsRequest, ListAssignmentsResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListAssignmentsRequest, ListAssignmentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
