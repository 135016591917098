import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'components/link';
import { useLocation } from 'react-router-dom';
import { View } from 'views';
import { pathForView } from 'views/views';

import { useSchoolSubscriptionBanner } from './hook';
import styles from './school-subscription-banner.module.css';

export const SchoolSubscriptionBanner = () => {
  const { show, kind } = useSchoolSubscriptionBanner();

  if (!show) return;

  const Component = {
    TRIAL_ENDING: TrialEndingBanner,
    SUBSCRIPTION_ENDING: SubscriptionEndingBanner,
    INTERIM: InterimBanner,
  }[kind];

  return <Component />;
};

type BannerContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const BannerContainer = ({ children, className }: BannerContainerProps) => (
  <div className={classNames(styles.Banner, className)}>
    <div className={styles.Icon}>
      <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
    </div>
    <div>{children}</div>
  </div>
);

const TrialEndingBanner = () => {
  const { pathname } = useLocation();

  const subscriptionPath = pathForView(View.SchoolSubscription);
  if (pathname.startsWith(subscriptionPath)) {
    return (
      <BannerContainer className={styles.ChunkyBanner}>
        <strong>Action required:</strong> Your trial is ending soon. Please tell us your Sparx
        Reader plans for next year.
      </BannerContainer>
    );
  }

  return (
    <BannerContainer>
      <strong>Action required:</strong>{' '}
      <Link
        to={pathForView(View.SchoolSubscription)}
        analyticsEvent={{
          category: 'subscription banner',
          action: 'click confirm subscription link',
        }}
        className={styles.Link}
      >
        Tell us your Sparx Reader plans
      </Link>{' '}
      for next year
    </BannerContainer>
  );
};

const SubscriptionEndingBanner = () => {
  const { pathname } = useLocation();

  const subscriptionPath = pathForView(View.SchoolSubscription);
  if (pathname.startsWith(subscriptionPath)) return;

  return (
    <BannerContainer>
      Your Sparx Reader subscription is ending soon.{' '}
      <Link
        to={subscriptionPath}
        analyticsEvent={{
          category: 'subscription banner',
          action: 'click confirm renewal link',
        }}
        className={styles.Link}
      >
        Confirm your renewal
      </Link>{' '}
      to continue using Sparx Reader.
    </BannerContainer>
  );
};

const InterimBanner = () => {
  const { pathname } = useLocation();

  const newYearSetupPath = pathForView(View.NewYearSetup);
  if (pathname === '/teacher' || pathname.startsWith(newYearSetupPath)) return;

  return (
    <BannerContainer>
      It&apos;s time to get your Sparx Reader site ready for this academic year.{' '}
      <Link
        to={newYearSetupPath}
        analyticsEvent={{
          category: 'interim banner',
          action: 'click setup link',
        }}
        className={styles.Link}
      >
        Set up your Sparx Reader site
      </Link>
    </BannerContainer>
  );
};
