import { ErrorMessage } from 'components/errors/error-message';
import { Loading } from 'components/loading/loading';
import { useStudentClassLeaderboard } from 'queries/leagueTables';
import { useUser } from 'queries/session';
import styles from 'views/student/league-view/league-view.module.css';

import { Banner } from '../components/banner';
import { LeagueTableRow } from '../components/row/league-table-row';
import { useScrollToUser } from './helpers';
import { LeaderboardProps } from './leaderboards';

export const StudentClassLeaderboard = ({ dateRange }: LeaderboardProps) => {
  const user = useUser();
  const { data, isLoading, isError } = useStudentClassLeaderboard(dateRange);
  useScrollToUser(isLoading);

  if (isError) {
    return <ErrorMessage message="Failed to load leaderboard" />;
  }

  if (isLoading || !user) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loading text="Loading leaderboard..." />
      </div>
    );
  }

  if (user && !user.studentGroupId) {
    return <>Nobody is on the leaderboard yet!</>;
  }

  return (
    <div className={styles.Block}>
      <Banner dateRange={dateRange} />

      {data.users.map((leaderboardUser, i) => (
        <LeagueTableRow key={i} leaderboardUser={leaderboardUser} />
      ))}
    </div>
  );
};
