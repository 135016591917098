import { AnalyticEventFields } from 'components/client-events/client-event-provider';

/** The event when starting a new book with the other choices offered to the
 * student by the algorithm. */
export const startNewBookEvent = (
  selectedBookName: string,
  otherBookNames: string[],
  bookChoiceAlgoVersion: string = 'v2',
  studentOnboarding: boolean = false,
): AnalyticEventFields => ({
  category: 'library',
  action: 'clicked start new book',
  labels: {
    selectedBook: selectedBookName,
    otherBookOptions: otherBookNames.join(','),
    bookChoiceAlgoVersion,
    onboarding: studentOnboarding.toString(),
  },
});
