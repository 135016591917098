import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { BookImage } from 'components/book/book-image';
import { Button } from 'components/buttons';
import { Loading } from 'components/loading/loading';
import { useGetBookTask } from 'queries/tasks';

import styles from './book.module.css';

export const Book = ({ book }: { book: LibraryBook }) => {
  const bookID = book.metadataAbridged?.name.replace('books/', '') || '';
  const openBook = useGetBookTask(bookID, {
    requestReread: true,
    refetchCache: true,
  });
  const doOpenBook = () => openBook.mutate();

  if (!book || !book.metadataAbridged) {
    return <Loading />;
  }

  const isStarting = !book.studentBook || book.studentBook.progress === 0;

  return (
    <div className={styles.Book} key={book.studentBook?.bookId}>
      <div className={styles.BookCoverContainer}>
        {book.metadataAbridged && (
          <BookImage book={book.metadataAbridged} className={styles.BookCover} />
        )}
      </div>
      <div className={styles.BookMetadata}>
        <div className={styles.Title}>{book.metadataAbridged?.title}</div>
        <div className={styles.Subtitle}>
          This training book will show you how to use Sparx Reader.
        </div>
        <div>
          {!book.studentBook || book.studentBook?.progress < 1 ? (
            <Button
              analyticsEvent={{
                category: 'onboarding',
                action: 'clicked ' + isStarting ? 'start' : 'continue' + '  book',
                labels: {
                  selectedBook: book.metadataAbridged.name,
                },
              }}
              onClick={doOpenBook}
            >
              {isStarting ? 'Start' : 'Continue'}
            </Button>
          ) : (
            <Button variant="success" analyticsEvent={undefined} disabled>
              Complete
              <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '10px' }} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
