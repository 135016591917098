import classNames from 'classnames';

import styles from './progress-segments.module.css';

export enum ProgressSegmentType {
  Incomplete,
  Success,
}

type Props = {
  segments: ProgressSegmentType[];
  background?: string;
};

export const ProgressSegments = ({ segments, background = 'var(--black-opacity-10)' }: Props) => {
  return (
    <div className={styles.Container}>
      {segments.map((segment, index) => (
        <div key={index} className={classNames(styles.Segment)} style={{ background }}>
          <div
            className={classNames(styles.Fill, styles.Success)}
            style={{ width: segment === ProgressSegmentType.Success ? '100%' : 0 }}
          />
        </div>
      ))}
    </div>
  );
};
