import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../popover.module.css';

export const Submitted = () => (
  <div>
    <FontAwesomeIcon icon={faCheckCircle} className={styles.SubmittedTick} />

    <p className={styles.SubmittedText}>Feedback submitted, thank you!</p>
  </div>
);
