import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/progress/progress.module.css';

interface IProgressBarProps {
  percent: number;
  width?: number | string;
  className?: string;
  barClassName?: string;
  hideTick?: boolean;
}

/**
 * ProgressBar displays a progress bar filled to the supplied percentage. If the
 * percentage is 100 then a tick will be displayed in the middle of the bar.
 * @constructor
 */
export const ProgressBar: React.FC<IProgressBarProps> = ({
  percent,
  width,
  className,
  barClassName,
  hideTick,
}) => (
  <div className={`${styles.ProgressBar} ${className}`} style={{ width: width || 150 }}>
    <div
      className={classNames(styles.ProgressBarComplete, barClassName)}
      style={{ width: `${percent}%` }}
    />
    {percent === 100 && !hideTick && (
      <div className={styles.ProgressBarTick}>
        <FontAwesomeIcon icon={faCheck} />
      </div>
    )}
  </div>
);
