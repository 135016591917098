import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faSpinner,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/panel/info.module.css';
import { FCWithChildren } from 'utils/props';

interface IInfoProps {
  style?: React.CSSProperties;
  className?: string;
  showIcon?: boolean;
  after?: React.ReactNode;
}

/**
 * TODO: these could 100% be combined
 */

export const Info: FCWithChildren<IInfoProps> = ({
  children,
  showIcon,
  style,
  className,
  after,
}) => (
  <div className={classNames(styles.Alert, styles.Info, className)} style={style}>
    {showIcon && (
      <div className={styles.Icon}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </div>
    )}
    <div className={styles.Content}>{children}</div>
    {after}
  </div>
);

export const Warning: FCWithChildren<IInfoProps> = ({
  children,
  after,
  showIcon,
  style,
  className,
}) => (
  <div className={classNames(styles.Alert, styles.Warning, className)} style={style}>
    {showIcon && (
      <div className={styles.Icon}>
        <FontAwesomeIcon icon={faExclamationCircle} />
      </div>
    )}
    <div className={styles.Content}>{children}</div>
    {after}
  </div>
);

export const Error: FCWithChildren<IInfoProps> = ({
  children,
  after,
  showIcon,
  style,
  className,
}) => (
  <div className={classNames(styles.Alert, styles.Error, className)} style={style}>
    {showIcon && (
      <div className={styles.Icon}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </div>
    )}
    <div className={styles.Content}>{children}</div>
    {after}
  </div>
);

export const Success: FCWithChildren<IInfoProps> = ({
  children,
  after,
  showIcon,
  style,
  className,
}) => (
  <div className={classNames(styles.Alert, styles.Success, className)} style={style}>
    {showIcon && (
      <div className={styles.Icon}>
        <FontAwesomeIcon icon={faCheckCircle} />
      </div>
    )}
    <div className={styles.Content}>{children}</div>
    {after}
  </div>
);

export const SuccessAlert: FCWithChildren = ({ children }) => (
  <div className={classNames(styles.Alert, styles.Success, styles.AlertPopup)}>
    <div className={styles.Icon}>
      <FontAwesomeIcon icon={faCheckCircle} />
    </div>
    <div className={styles.Content}>{children}</div>
  </div>
);

export const WarningAlert: FCWithChildren = ({ children }) => (
  <div className={classNames(styles.Alert, styles.Warning, styles.AlertPopup)}>
    <div className={styles.Icon}>
      <FontAwesomeIcon icon={faExclamationCircle} />
    </div>
    <div className={styles.Content}>{children}</div>
  </div>
);

export const LoadingAlert: FCWithChildren = ({ children }) => (
  <div
    className={classNames(styles.Alert, styles.Warning, styles.AlertPopup, styles.AlertPopupStatic)}
  >
    <div className={styles.Icon}>
      <FontAwesomeIcon icon={faSpinner} spin={true} />
    </div>
    <div className={styles.Content}>{children}</div>
  </div>
);
