import { PropsWithChildren } from 'react';
import { Flags, useBooleanFlag } from 'utils/featureflags';
import { NotFoundView } from 'views/common/not-found-view/not-found-view';

type Props = {
  flag: Flags;
};

export const RequiresFlag = ({ flag, children }: PropsWithChildren<Props>) => {
  const isEnabled = useBooleanFlag(flag);

  if (isEnabled) return <>{children}</>;

  return <NotFoundView />;
};
