// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/content/v1/service.proto" (package "sparx.reading.content.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Books } from './service';
import type { SoftDeleteBreakpointResponse } from './service';
import type { SoftDeleteBreakpointRequest } from './service';
import type { DeleteQuestionResponse } from './service';
import type { DeleteQuestionRequest } from './service';
import type { UpdateBookkeeperQuestionsResponse } from './service';
import type { UpdateBookkeeperQuestionsRequest } from './service';
import type { CreateAdminStudentBookResponse } from './service';
import type { CreateAdminStudentBookRequest } from './service';
import type { SetAdminStudentBookResponse } from './service';
import type { SetAdminStudentBookRequest } from './service';
import type { ListAdminStudentBooksResponse } from './service';
import type { ListAdminStudentBooksRequest } from './service';
import type { UpsertCustomBookQuestionsResponse } from './service';
import type { UpsertCustomBookQuestionsRequest } from './service';
import type { ListCustomBooksResponse } from './service';
import type { ListCustomBooksRequest } from './service';
import type { AssignCustomBookResponse } from './service';
import type { AssignCustomBookRequest } from './service';
import type { GetCustomBookResponse } from './service';
import type { GetCustomBookRequest } from './service';
import type { ApproveAllAnnotationsResponse } from './service';
import type { ApproveAllAnnotationsRequest } from './service';
import type { CommitBookAnnotationResponse } from './service';
import type { CommitBookAnnotationRequest } from './service';
import type { GetBookAnnotationsResponse } from './service';
import type { GetBookAnnotationsRequest } from './service';
import type { CheckBookLockResponse } from './service';
import type { CheckBookLockRequest } from './service';
import type { GetAvailableBooksResponse } from './service';
import type { GetAvailableBooksRequest } from './service';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetBookResponse } from './service';
import type { GetBookRequest } from './service';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.content.v1.Books
 */
export interface IBooksClient {
  /**
   * GetBook returns book information for a given book.
   *
   * @generated from protobuf rpc: GetBook(sparx.reading.content.v1.GetBookRequest) returns (sparx.reading.content.v1.GetBookResponse);
   */
  getBook(
    input: GetBookRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBookRequest, GetBookResponse>;
  /**
   * GetAvailableBooks returns the available book overview information.
   *
   * @generated from protobuf rpc: GetAvailableBooks(sparx.reading.content.v1.GetAvailableBooksRequest) returns (sparx.reading.content.v1.GetAvailableBooksResponse);
   */
  getAvailableBooks(
    input: GetAvailableBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAvailableBooksRequest, GetAvailableBooksResponse>;
  /**
   * @generated from protobuf rpc: CheckBookLock(sparx.reading.content.v1.CheckBookLockRequest) returns (sparx.reading.content.v1.CheckBookLockResponse);
   */
  checkBookLock(
    input: CheckBookLockRequest,
    options?: RpcOptions,
  ): UnaryCall<CheckBookLockRequest, CheckBookLockResponse>;
  /**
   * @generated from protobuf rpc: GetBookAnnotations(sparx.reading.content.v1.GetBookAnnotationsRequest) returns (sparx.reading.content.v1.GetBookAnnotationsResponse);
   */
  getBookAnnotations(
    input: GetBookAnnotationsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBookAnnotationsRequest, GetBookAnnotationsResponse>;
  /**
   * @generated from protobuf rpc: CommitBookAnnotation(sparx.reading.content.v1.CommitBookAnnotationRequest) returns (sparx.reading.content.v1.CommitBookAnnotationResponse);
   */
  commitBookAnnotation(
    input: CommitBookAnnotationRequest,
    options?: RpcOptions,
  ): UnaryCall<CommitBookAnnotationRequest, CommitBookAnnotationResponse>;
  /**
   * @generated from protobuf rpc: ApproveAllAnnotations(sparx.reading.content.v1.ApproveAllAnnotationsRequest) returns (sparx.reading.content.v1.ApproveAllAnnotationsResponse);
   */
  approveAllAnnotations(
    input: ApproveAllAnnotationsRequest,
    options?: RpcOptions,
  ): UnaryCall<ApproveAllAnnotationsRequest, ApproveAllAnnotationsResponse>;
  /**
   * @generated from protobuf rpc: GetCustomBook(sparx.reading.content.v1.GetCustomBookRequest) returns (sparx.reading.content.v1.GetCustomBookResponse);
   */
  getCustomBook(
    input: GetCustomBookRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCustomBookRequest, GetCustomBookResponse>;
  /**
   * @generated from protobuf rpc: AssignCustomBook(sparx.reading.content.v1.AssignCustomBookRequest) returns (sparx.reading.content.v1.AssignCustomBookResponse);
   */
  assignCustomBook(
    input: AssignCustomBookRequest,
    options?: RpcOptions,
  ): UnaryCall<AssignCustomBookRequest, AssignCustomBookResponse>;
  /**
   * ListCustomBooks lists all of the custom books on the system.
   * This endpoint is admin only.
   *
   * @generated from protobuf rpc: ListCustomBooks(sparx.reading.content.v1.ListCustomBooksRequest) returns (sparx.reading.content.v1.ListCustomBooksResponse);
   */
  listCustomBooks(
    input: ListCustomBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListCustomBooksRequest, ListCustomBooksResponse>;
  /**
   * @generated from protobuf rpc: UpsertCustomBookQuestions(sparx.reading.content.v1.UpsertCustomBookQuestionsRequest) returns (sparx.reading.content.v1.UpsertCustomBookQuestionsResponse);
   */
  upsertCustomBookQuestions(
    input: UpsertCustomBookQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpsertCustomBookQuestionsRequest,
    UpsertCustomBookQuestionsResponse
  >;
  /**
   * @generated from protobuf rpc: ListAdminStudentBooks(sparx.reading.content.v1.ListAdminStudentBooksRequest) returns (sparx.reading.content.v1.ListAdminStudentBooksResponse);
   */
  listAdminStudentBooks(
    input: ListAdminStudentBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAdminStudentBooksRequest, ListAdminStudentBooksResponse>;
  /**
   * @generated from protobuf rpc: SetAdminStudentBook(sparx.reading.content.v1.SetAdminStudentBookRequest) returns (sparx.reading.content.v1.SetAdminStudentBookResponse);
   */
  setAdminStudentBook(
    input: SetAdminStudentBookRequest,
    options?: RpcOptions,
  ): UnaryCall<SetAdminStudentBookRequest, SetAdminStudentBookResponse>;
  /**
   * CreateAdminStudentBook is an admin endpoint for assigning a book to a student.
   *
   * @generated from protobuf rpc: CreateAdminStudentBook(sparx.reading.content.v1.CreateAdminStudentBookRequest) returns (sparx.reading.content.v1.CreateAdminStudentBookResponse);
   */
  createAdminStudentBook(
    input: CreateAdminStudentBookRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateAdminStudentBookRequest, CreateAdminStudentBookResponse>;
  /**
   * @generated from protobuf rpc: UpdateBookkeeperQuestions(sparx.reading.content.v1.UpdateBookkeeperQuestionsRequest) returns (sparx.reading.content.v1.UpdateBookkeeperQuestionsResponse);
   */
  updateBookkeeperQuestions(
    input: UpdateBookkeeperQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateBookkeeperQuestionsRequest,
    UpdateBookkeeperQuestionsResponse
  >;
  /**
   * @generated from protobuf rpc: DeleteQuestion(sparx.reading.content.v1.DeleteQuestionRequest) returns (sparx.reading.content.v1.DeleteQuestionResponse);
   */
  deleteQuestion(
    input: DeleteQuestionRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteQuestionRequest, DeleteQuestionResponse>;
  /**
   * SoftDeleteBreakpoint marks the breakpoint as deleted in bookkeeper so it
   * will no longer be used when generating new tasks for students.
   *
   * @generated from protobuf rpc: SoftDeleteBreakpoint(sparx.reading.content.v1.SoftDeleteBreakpointRequest) returns (sparx.reading.content.v1.SoftDeleteBreakpointResponse);
   */
  softDeleteBreakpoint(
    input: SoftDeleteBreakpointRequest,
    options?: RpcOptions,
  ): UnaryCall<SoftDeleteBreakpointRequest, SoftDeleteBreakpointResponse>;
}
/**
 * @generated from protobuf service sparx.reading.content.v1.Books
 */
export class BooksClient implements IBooksClient, ServiceInfo {
  typeName = Books.typeName;
  methods = Books.methods;
  options = Books.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetBook returns book information for a given book.
   *
   * @generated from protobuf rpc: GetBook(sparx.reading.content.v1.GetBookRequest) returns (sparx.reading.content.v1.GetBookResponse);
   */
  getBook(
    input: GetBookRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBookRequest, GetBookResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetBookRequest, GetBookResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetAvailableBooks returns the available book overview information.
   *
   * @generated from protobuf rpc: GetAvailableBooks(sparx.reading.content.v1.GetAvailableBooksRequest) returns (sparx.reading.content.v1.GetAvailableBooksResponse);
   */
  getAvailableBooks(
    input: GetAvailableBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetAvailableBooksRequest, GetAvailableBooksResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetAvailableBooksRequest, GetAvailableBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: CheckBookLock(sparx.reading.content.v1.CheckBookLockRequest) returns (sparx.reading.content.v1.CheckBookLockResponse);
   */
  checkBookLock(
    input: CheckBookLockRequest,
    options?: RpcOptions,
  ): UnaryCall<CheckBookLockRequest, CheckBookLockResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<CheckBookLockRequest, CheckBookLockResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetBookAnnotations(sparx.reading.content.v1.GetBookAnnotationsRequest) returns (sparx.reading.content.v1.GetBookAnnotationsResponse);
   */
  getBookAnnotations(
    input: GetBookAnnotationsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBookAnnotationsRequest, GetBookAnnotationsResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetBookAnnotationsRequest,
      GetBookAnnotationsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: CommitBookAnnotation(sparx.reading.content.v1.CommitBookAnnotationRequest) returns (sparx.reading.content.v1.CommitBookAnnotationResponse);
   */
  commitBookAnnotation(
    input: CommitBookAnnotationRequest,
    options?: RpcOptions,
  ): UnaryCall<CommitBookAnnotationRequest, CommitBookAnnotationResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CommitBookAnnotationRequest,
      CommitBookAnnotationResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ApproveAllAnnotations(sparx.reading.content.v1.ApproveAllAnnotationsRequest) returns (sparx.reading.content.v1.ApproveAllAnnotationsResponse);
   */
  approveAllAnnotations(
    input: ApproveAllAnnotationsRequest,
    options?: RpcOptions,
  ): UnaryCall<ApproveAllAnnotationsRequest, ApproveAllAnnotationsResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ApproveAllAnnotationsRequest,
      ApproveAllAnnotationsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetCustomBook(sparx.reading.content.v1.GetCustomBookRequest) returns (sparx.reading.content.v1.GetCustomBookResponse);
   */
  getCustomBook(
    input: GetCustomBookRequest,
    options?: RpcOptions,
  ): UnaryCall<GetCustomBookRequest, GetCustomBookResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetCustomBookRequest, GetCustomBookResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: AssignCustomBook(sparx.reading.content.v1.AssignCustomBookRequest) returns (sparx.reading.content.v1.AssignCustomBookResponse);
   */
  assignCustomBook(
    input: AssignCustomBookRequest,
    options?: RpcOptions,
  ): UnaryCall<AssignCustomBookRequest, AssignCustomBookResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AssignCustomBookRequest, AssignCustomBookResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * ListCustomBooks lists all of the custom books on the system.
   * This endpoint is admin only.
   *
   * @generated from protobuf rpc: ListCustomBooks(sparx.reading.content.v1.ListCustomBooksRequest) returns (sparx.reading.content.v1.ListCustomBooksResponse);
   */
  listCustomBooks(
    input: ListCustomBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListCustomBooksRequest, ListCustomBooksResponse> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListCustomBooksRequest, ListCustomBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpsertCustomBookQuestions(sparx.reading.content.v1.UpsertCustomBookQuestionsRequest) returns (sparx.reading.content.v1.UpsertCustomBookQuestionsResponse);
   */
  upsertCustomBookQuestions(
    input: UpsertCustomBookQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpsertCustomBookQuestionsRequest,
    UpsertCustomBookQuestionsResponse
  > {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpsertCustomBookQuestionsRequest,
      UpsertCustomBookQuestionsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListAdminStudentBooks(sparx.reading.content.v1.ListAdminStudentBooksRequest) returns (sparx.reading.content.v1.ListAdminStudentBooksResponse);
   */
  listAdminStudentBooks(
    input: ListAdminStudentBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListAdminStudentBooksRequest, ListAdminStudentBooksResponse> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListAdminStudentBooksRequest,
      ListAdminStudentBooksResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: SetAdminStudentBook(sparx.reading.content.v1.SetAdminStudentBookRequest) returns (sparx.reading.content.v1.SetAdminStudentBookResponse);
   */
  setAdminStudentBook(
    input: SetAdminStudentBookRequest,
    options?: RpcOptions,
  ): UnaryCall<SetAdminStudentBookRequest, SetAdminStudentBookResponse> {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SetAdminStudentBookRequest,
      SetAdminStudentBookResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * CreateAdminStudentBook is an admin endpoint for assigning a book to a student.
   *
   * @generated from protobuf rpc: CreateAdminStudentBook(sparx.reading.content.v1.CreateAdminStudentBookRequest) returns (sparx.reading.content.v1.CreateAdminStudentBookResponse);
   */
  createAdminStudentBook(
    input: CreateAdminStudentBookRequest,
    options?: RpcOptions,
  ): UnaryCall<CreateAdminStudentBookRequest, CreateAdminStudentBookResponse> {
    const method = this.methods[12],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      CreateAdminStudentBookRequest,
      CreateAdminStudentBookResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateBookkeeperQuestions(sparx.reading.content.v1.UpdateBookkeeperQuestionsRequest) returns (sparx.reading.content.v1.UpdateBookkeeperQuestionsResponse);
   */
  updateBookkeeperQuestions(
    input: UpdateBookkeeperQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateBookkeeperQuestionsRequest,
    UpdateBookkeeperQuestionsResponse
  > {
    const method = this.methods[13],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateBookkeeperQuestionsRequest,
      UpdateBookkeeperQuestionsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: DeleteQuestion(sparx.reading.content.v1.DeleteQuestionRequest) returns (sparx.reading.content.v1.DeleteQuestionResponse);
   */
  deleteQuestion(
    input: DeleteQuestionRequest,
    options?: RpcOptions,
  ): UnaryCall<DeleteQuestionRequest, DeleteQuestionResponse> {
    const method = this.methods[14],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<DeleteQuestionRequest, DeleteQuestionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * SoftDeleteBreakpoint marks the breakpoint as deleted in bookkeeper so it
   * will no longer be used when generating new tasks for students.
   *
   * @generated from protobuf rpc: SoftDeleteBreakpoint(sparx.reading.content.v1.SoftDeleteBreakpointRequest) returns (sparx.reading.content.v1.SoftDeleteBreakpointResponse);
   */
  softDeleteBreakpoint(
    input: SoftDeleteBreakpointRequest,
    options?: RpcOptions,
  ): UnaryCall<SoftDeleteBreakpointRequest, SoftDeleteBreakpointResponse> {
    const method = this.methods[15],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SoftDeleteBreakpointRequest,
      SoftDeleteBreakpointResponse
    >('unary', this._transport, method, opt, input);
  }
}
