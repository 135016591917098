import styles from 'components/container/container.module.css';
import { FCWithChildren } from 'utils/props';

interface IContentProps {
  className?: string;
  style?: React.CSSProperties;
}

export const Container: FCWithChildren<IContentProps> = ({ children, className, style }) => (
  <div className={`${styles.Container} ${className}`} style={style}>
    {children}
  </div>
);
