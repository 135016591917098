import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { ErrorMessage } from 'components/errors/error-message';
import { useSelectedGroups } from 'hooks/selected-groups';
import { useTeacherClassLeaderboard } from 'queries/leagueTables';
import { useTeacherStudentsWithExperience } from 'queries/management';
import { useState } from 'react';
import { ChooseGroupView } from 'views/teacher/choose-group-view/choose-group-view';

import { LeagueTableRow } from '../components/row/league-table-row';
import { LoadingRow } from '../components/row/loading-row';
import { AllUsersOptedOut } from './all-users-opted-out';
import { findStudent } from './helpers';
import { LeaderboardProps } from './leaderboards';

export const TeacherClassLeaderboard = ({ dateRange }: LeaderboardProps) => {
  const [selectedGroups] = useSelectedGroups();
  const [studentGroupId] = useState(selectedGroups[0] ? selectedGroups[0]?.name.split('/')[3] : '');
  const {
    data,
    isLoading: isLoadingLeaderboard,
    isError,
    isFetched,
  } = useTeacherClassLeaderboard(studentGroupId, dateRange);
  const { data: students, isLoading: isLoadingStudents } = useTeacherStudentsWithExperience();

  if (isError) {
    return <ErrorMessage message="Failed to load leaderboard" />;
  }

  // If the query is disabled, there are no students in the class
  if (studentGroupId && !isFetched && !isLoadingLeaderboard) {
    return <>There are no students in this class</>;
  }

  const isLoading = studentGroupId && Boolean(isLoadingLeaderboard || isLoadingStudents);

  return (
    <AllUsersOptedOut users={data?.users}>
      {students &&
        data?.users.map((leaderboardUser, i) => (
          <LeagueTableRow
            key={i}
            leaderboardUser={leaderboardUser}
            student={findStudent(students, leaderboardUser)}
          />
        ))}

      {!studentGroupId && <ChooseGroupView title="Leaderboard" icon={faCrown} />}

      {isLoading && [...Array(20).keys()].map(i => <LoadingRow key={i} />)}
    </AllUsersOptedOut>
  );
};
