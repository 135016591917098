/* DO NOT EDIT
 *
 * Generated by go generate from schools/utils/annotations/annotations.ts.tpl
 * See schools/utils/annotation/README.md for more information
 *
 * DO NOT EDIT
 */
export enum AnnotationKeys {
  AuditLastUpdated = 'sparx.audit/last-updated',
  AuditLastUpdatedBy = 'sparx.audit/last-updated-by',
  HasTimestablesGames = 'sparx.sparxweb/tt-games-allowed',
  HegartyMathsID = 'sparx.hegartymaths/id',
  HegartyMathsImportedSchool = 'sparx.hegartymaths/imported-school',
  HubspotDealID = 'sparx.hubspot/deal-id',
  HubspotID = 'sparx.hubspot/id',
  LeaderboardOptOut = 'sparx.schools.leaderboards/ranking-opt-out',
  LoginMethodsStudentsDisableSparx = 'sparx.login-methods.students/disable-sparx-login',
  LoginMethodsStudentsSSODomain = 'sparx.login-methods.students/sso-domain',
  LoginMethodsStudentsSSOProvider = 'sparx.login-methods.students/sso-provider',
  LoginMethodsTeachersDisableSparx = 'sparx.login-methods.teachers/disable-sparx-login',
  LoginMethodsTeachersSSODomain = 'sparx.login-methods.teachers/sso-domain',
  LoginMethodsTeachersSSOProvider = 'sparx.login-methods.teachers/sso-provider',
  MathsPlannedStartDate = 'sparx.schools.homework/maths-planned-start-date',
  MultiProductSeniorLeaderWeeklyEmailEnabled = 'sparx.email/multi-product-senior-leader-weekly-enabled',
  MultiProductSeniorLeaderWeeklyLastSendTimestamp = 'sparx.email.multi-product-senior-leader-weekly/last-send-timestamp',
  MultiProductSeniorLeaderWeeklySendDayOverride = 'sparx.email.multi-product-senior-leader-weekly/send-day-override',
  MultiProductSeniorLeaderWeeklySendTimeOverride = 'sparx.email.multi-product-senior-leader-weekly/send-time-override',
  ParentEmailEnabled = 'sparx.email/parent-email-enabled',
  PricingPlanIsDefault = 'sparx.subscriptions/pricing-plan-default',
  ReaderLeaderboardOptOut = 'sparx.schools.leaderboards/reader-ranking-opt-out',
  ReplyToAddress = 'sparx.email/reply-to-email-address',
  RolloverCompleteTime = 'sparx.schools.rollover/complete-time',
  RolloverCompleteType = 'sparx.schools.rollover/complete-type',
  RolloverCompleteUser = 'sparx.schools.rollover/complete-user',
  RolloverForcePartial = 'sparx.schools.rollover/force-partial',
  RolloverInterimTime = 'sparx.schools.rollover/interim-time',
  RolloverStepPlannedHomeworkStartDate = 'sparx.rollover.step/planned-homework-start-date',
  RolloverStepReviewedHolidays = 'sparx.rollover.step/reviewed-holidays',
  RolloverStepReviewedKeyContacts = 'sparx.rollover.step/reviewed-keycontacts',
  RolloverStepReviewedStaff = 'sparx.rollover.step/reviewed-staff',
  RolloverTriggerTime = 'sparx.schools.rollover/trigger-time',
  SageID = 'sparx.sage/id',
  SchemeOfLearningTemplateEditing = 'sparx.settings/scheme-of-learning-template-editing',
  SchoolMembershipLegacyStaffId = 'sparx.staff.membership/legacy-staff-id',
  SchoolMembershipReaderUserId = 'sparx.staff.membership/reader-user-id',
  SchoolMembershipScienceUserId = 'sparx.staff.membership/science-user-id',
  SchoolName = 'sparx.schools/name',
  SchoolSubdomainEnabled = 'sparx.schools.subdomain/enabled',
  SeniorLeaderWeeklyEmailEnabled = 'sparx.email/senior-leader-weekly-enabled',
  SeniorLeaderWeeklyLastSendTimestamp = 'sparx.email.senior-leader-weekly/last-send-timestamp',
  SeniorLeaderWeeklySendDayOverride = 'sparx.email.senior-leader-weekly/send-day-override',
  SeniorLeaderWeeklySendTimeOverride = 'sparx.email.senior-leader-weekly/send-time-override',
  SparxMathsTeacherManualLockout = 'sparx.manual-lockout/teacher-portal',
  StaffPseudonymisationStartTime = 'sparx.staff.pseudonymise/pseudonymisation-start-time',
  StaffPseudonymisedTime = 'sparx.staff.pseudonymise/pseudonymisation-time',
  SubscriptionAIPUser = 'sparx.subscriptions/aip-user',
  SubscriptionPORequestLastSendTimestamp = 'sparx.subscriptions/po-last-send-timestamp',
  SubscriptionPurchaseOrderID = 'sparx.subscriptions/purchase-order-id',
  SubscriptionSalesInvoiceCreatedTimestamp = 'sparx.subscriptions/invoice-created-timestamp',
  SubscriptionSalesInvoiceCreationResult = 'sparx.subscriptions/create-invoice-result',
  SubscriptionSalesInvoiceCreationStarted = 'sparx.subscriptions/create-invoice-start',
  SubscriptionSalesInvoiceNumber = 'sparx.subscriptions/invoice-number',
  SubscriptionShouldSendPORequest = 'sparx.subscriptions/should-send-po',
  SyncController = 'sparx.sync/controller-name',
  TeacherPortalSelectedSSOProvider = 'sparx.teacherportal/selected-sso-provider',
  WeekStartDay = 'sparx.settings/week-start-day',
  WelcomeEmailProduct = 'sparx.staff.welcomeemail/product',
  WelcomeEmailRequested = 'sparx.staff.welcomeemail/requested',
  WelcomeEmailRequestedDataProtectionOfficer = 'sparx.staff.welcomeemail/dpo-requested',
  WelcomeEmailRequestedFinanceOfficer = 'sparx.staff.welcomeemail/financeofficer-requested',
  WelcomeEmailRequestedNetworkManager = 'sparx.staff.welcomeemail/networkmanager-requested',
  WelcomeEmailRequestedSeniorLeader = 'sparx.staff.welcomeemail/seniorleader-requested',
  WelcomeEmailSentTimeDataProtectionOfficer = 'sparx.staff.welcomeemail/dpo-senttime',
  WelcomeEmailSentTimeFinanceOfficer = 'sparx.staff.welcomeemail/financeofficer-senttime',
  WelcomeEmailSentTimeNetworkManager = 'sparx.staff.welcomeemail/networkmanager-senttime',
  WelcomeEmailSentTimeSeniorLeader = 'sparx.staff.welcomeemail/seniorleader-senttime',
  WondeAuthStatus = 'sparx.sync.wonde/auth-status',
  WondeAuthStatusChangeTime = 'sparx.sync.wonde/auth-status-change-time',
  WondeAutoSyncMultiProductEnabled = 'sparx.sync.wonde/auto-sync-mutli-product-enabled',
  WondeAutosyncEnabled = 'sparx.sync.wonde/autosync-enabled',
  WondeDomain = 'sparx.sync.wonde/domain',
  WondeID = 'sparx.sync.wonde/id',
  WondeLastAutoSyncFailedTime = 'sparx.sync.wonde/last-autosync-failed-time',
  WondeLastAutoSyncStart = 'sparx.sync.wonde/last-autosync-start',
  WondeLastAutoSyncSuccessTime = 'sparx.sync.wonde/last-autosync-success-time',
  WondeLastFailure = 'sparx.sync.wonde/last-failure',
  WondeLastSuccess = 'sparx.sync.wonde/last-success',
  WondeLastSyncStart = 'sparx.sync.wonde/last-sync-start',
  WondeMathsLastAutoSyncFailedTime = 'sparx.sync.wonde/maths-last-autosync-failed-time',
  WondeMathsLastAutoSyncStart = 'sparx.sync.wonde/maths-last-autosync-start',
  WondeMathsLastAutoSyncSuccessTime = 'sparx.sync.wonde/maths-last-autosync-success-time',
  WondeMathsLastFailure = 'sparx.sync.wonde/maths-last-failure',
  WondeMathsLastSuccess = 'sparx.sync.wonde/maths-last-success',
  WondeMathsLastSyncStart = 'sparx.sync.wonde/maths-last-sync-start',
  WondeParentImportPriorities = 'sparx.sync.wonde/parent-import-priorities',
  WondeReaderLastAutoSyncFailedTime = 'sparx.sync.wonde/reader-last-autosync-failed-time',
  WondeReaderLastAutoSyncStart = 'sparx.sync.wonde/reader-last-autosync-start',
  WondeReaderLastAutoSyncSuccessTime = 'sparx.sync.wonde/reader-last-autosync-success-time',
  WondeReaderLastFailure = 'sparx.sync.wonde/reader-last-failure',
  WondeReaderLastSuccess = 'sparx.sync.wonde/reader-last-success',
  WondeReaderLastSyncStart = 'sparx.sync.wonde/reader-last-sync-start',
  WondeScienceLastAutoSyncFailedTime = 'sparx.sync.wonde/science-last-autosync-failed-time',
  WondeScienceLastAutoSyncStart = 'sparx.sync.wonde/science-last-autosync-start',
  WondeScienceLastAutoSyncSuccessTime = 'sparx.sync.wonde/science-last-autosync-success-time',
  WondeScienceLastFailure = 'sparx.sync.wonde/science-last-failure',
  WondeScienceLastSuccess = 'sparx.sync.wonde/science-last-success',
  WondeScienceLastSyncStart = 'sparx.sync.wonde/science-last-sync-start',
  WondeSummerAutosyncPausedTime = 'sparx.sync.wonde/summer-autosync-paused-time',
  WondeUseClassCode = 'sparx.sync.wonde/use-class-code',
  WondeUseLegalNames = 'sparx.sync.wonde/use-legal-names',
}
