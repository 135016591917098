import { bookSettingsToStyles } from 'components/book/book-settings';
import { useUserSettings } from 'queries/session';
import { useWindowSize } from 'utils/hooks';
import { FCWithChildren } from 'utils/props';

interface BookContentProps {
  className?: string;
  defaultFontSize?: number;
}

export const BookContent: FCWithChildren<BookContentProps> = ({
  children,
  className = '',
  defaultFontSize,
}) => {
  const { data: settings } = useUserSettings();
  const windowSize = useWindowSize();
  const smallWidth = Boolean(windowSize.width && windowSize.width < 500);
  const styles = bookSettingsToStyles(settings, { defaultFontSize, smallWidth });

  return (
    <div style={styles} className={'read-content ' + className}>
      {children}
    </div>
  );
};
