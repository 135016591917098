import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixSelect from '@radix-ui/react-select';
import classNames from 'classnames';

import { useTheme } from '../../context';
import styles from './Select.module.css';

type SelectItem = {
  value: string;
  text: string;
  className?: string;
};

type SelectGroup = {
  items: SelectItem[];
  label?: string;
};

type Props = {
  // The name/id of the select field used when submitting a form
  name?: string;
  // The value of the selected item.
  value?: string;
  // The function to call when the selected item changes.
  onChange: (value: string) => void;
  // The groups of items to display in the select.
  groups: SelectGroup[];
  // The Aria label for the select.
  label?: string;
  // The placeholder text to display when no item is selected.
  placeholder?: string;
  // The class name to apply to the trigger
  triggerClassName?: string;
  // The class name to apply to the portal (dropdown)
  portalClassName?: string;
};

/**
 * Select is a wrapper around the Radix Select component.
 * https://www.radix-ui.com/primitives/docs/components/select
 */
export const Select = ({
  value,
  onChange,
  label,
  placeholder,
  groups,
  name,
  triggerClassName,
  portalClassName,
}: Props) => {
  const { themeStyles } = useTheme();
  return (
    <RadixSelect.Root value={value} onValueChange={onChange} name={name}>
      <RadixSelect.Trigger
        aria-label={label}
        className={classNames(
          themeStyles['SelectShared'] || styles.SelectShared,
          styles.Trigger,
          triggerClassName,
        )}
      >
        <RadixSelect.Value placeholder={placeholder} />
        <RadixSelect.Icon>
          <FontAwesomeIcon icon={faChevronDown} />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Portal className={portalClassName}>
        <RadixSelect.Content
          position="popper"
          className={classNames(themeStyles['SelectShared'] || styles.SelectShared, styles.Content)}
        >
          <RadixSelect.ScrollUpButton className={styles.ScrollButton}>
            <FontAwesomeIcon icon={faChevronUp} />
          </RadixSelect.ScrollUpButton>
          <RadixSelect.Viewport className={styles.Viewport}>
            {groups.map((group, i) => (
              <RadixSelect.Group key={i}>
                {group.label && (
                  <RadixSelect.Label className={styles.GroupLabel}>{group.label}</RadixSelect.Label>
                )}
                {group.items.map(({ value: itemValue, text, className }) => (
                  <RadixSelect.Item
                    key={itemValue}
                    value={itemValue}
                    className={classNames(
                      styles.Item,
                      className,
                      itemValue === value && styles.ItemActive,
                    )}
                  >
                    <RadixSelect.ItemText>{text}</RadixSelect.ItemText>
                  </RadixSelect.Item>
                ))}
              </RadixSelect.Group>
            ))}
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton className={styles.ScrollButton}>
            <FontAwesomeIcon icon={faChevronDown} />
          </RadixSelect.ScrollDownButton>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  );
};
