// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/tasks/v1/assessment.proto" (package "sparx.reading.tasks.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentTask
 */
export interface AssessmentTask {}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentTask.Spec
 */
export interface AssessmentTask_Spec {
  /**
   * @generated from protobuf field: string assessment_id = 1;
   */
  assessmentId: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentTask.QuestionResult
 */
export interface AssessmentTask_QuestionResult {
  /**
   * @generated from protobuf field: string question_id = 1;
   */
  questionId: string;
  /**
   * @generated from protobuf field: bool correct = 2;
   */
  correct: boolean;
  /**
   * @generated from protobuf field: bool skipped = 3;
   */
  skipped: boolean;
  /**
   * The amount of time they spent answering the question in seconds.
   *
   * @generated from protobuf field: double time_taken = 4;
   */
  timeTaken: number;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentTask.State
 */
export interface AssessmentTask_State {
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.AssessmentTask.Question question = 1;
   */
  question?: AssessmentTask_Question;
  /**
   * @generated from protobuf field: repeated string seen_question_ids = 2;
   */
  seenQuestionIds: string[];
  /**
   * @generated from protobuf field: int32 current_question_index = 3;
   */
  currentQuestionIndex: number;
  /**
   * @generated from protobuf field: int32 questions_correct = 4;
   */
  questionsCorrect: number;
  /**
   * @generated from protobuf field: string quiz_title = 5;
   */
  quizTitle: string;
  /**
   * @generated from protobuf field: string quiz_description = 6;
   */
  quizDescription: string;
  /**
   * @generated from protobuf field: int32 start_index = 7;
   */
  startIndex: number;
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.AssessmentTask.QuestionResult results = 8;
   */
  results: AssessmentTask_QuestionResult[];
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentTask.Action
 */
export interface AssessmentTask_Action {
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'proceed';
        /**
         * @generated from protobuf field: bool proceed = 1;
         */
        proceed: boolean;
      }
    | {
        oneofKind: 'answer';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.AssessmentTask.Action.Answer answer = 2;
         */
        answer: AssessmentTask_Action_Answer;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentTask.Action.Answer
 */
export interface AssessmentTask_Action_Answer {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentTask.Question
 */
export interface AssessmentTask_Question {
  /**
   * @generated from protobuf field: int32 index = 1;
   */
  index: number;
  /**
   * @generated from protobuf field: string question_id = 2;
   */
  questionId: string;
  /**
   * @generated from protobuf field: string text = 3;
   */
  text: string;
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.AssessmentTask.Option options = 4;
   */
  options: AssessmentTask_Option[];
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.AssessmentTask.Question.Stage stage = 5;
   */
  stage: AssessmentTask_Question_Stage;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.AssessmentTask.Question.Result result = 6;
   */
  result: AssessmentTask_Question_Result;
  /**
   * @generated from protobuf field: map<string, sparx.reading.tasks.v1.AssessmentTask.Question.Result> part_results = 7;
   */
  partResults: {
    [key: string]: AssessmentTask_Question_Result;
  };
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.AssessmentTask.Question.Stage
 */
export enum AssessmentTask_Question_Stage {
  /**
   * @generated from protobuf enum value: STAGE_UNSPECIFIED = 0;
   */
  STAGE_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: QUESTION = 1;
   */
  QUESTION = 1,
  /**
   * @generated from protobuf enum value: RESULT = 2;
   */
  RESULT = 2,
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.AssessmentTask.Question.Result
 */
export enum AssessmentTask_Question_Result {
  /**
   * @generated from protobuf enum value: RESULT_UNSPECIFIED = 0;
   */
  RESULT_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: CORRECT = 1;
   */
  CORRECT = 1,
  /**
   * @generated from protobuf enum value: INCORRECT = 2;
   */
  INCORRECT = 2,
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentTask.Option
 */
export interface AssessmentTask_Option {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string text = 2;
   */
  text: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentQuiz
 */
export interface AssessmentQuiz {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string title = 2;
   */
  title: string;
  /**
   * @generated from protobuf field: string description = 3;
   */
  description: string;
  /**
   * When present this defines the adaptive parameters for quiz delivery. If not present
   * then all of the questions will be issued in order.
   *
   * @generated from protobuf field: sparx.reading.tasks.v1.AssessmentQuizAdaptive adaptive_delivery = 4;
   */
  adaptiveDelivery?: AssessmentQuizAdaptive;
  /**
   * @generated from protobuf field: repeated string questions = 5;
   */
  questions: string[];
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentQuizAdaptive
 */
export interface AssessmentQuizAdaptive {
  /**
   * Minimum number of questions that a student should be issued on this quiz, regardless
   * of the minutes_max value.
   *
   * @generated from protobuf field: int32 min_questions = 1;
   */
  minQuestions: number;
  /**
   * Maximum number of questions that a student should be issued on this quiz.
   *
   * @generated from protobuf field: int32 max_questions = 2;
   */
  maxQuestions: number;
  /**
   * Maximum number of minutes that a student should spend on this quiz. If the student
   * has answered min_questions but has not spent this amount of time on the quiz then
   * questions will continue to be asked until they have spent minutes_max or have been
   * issued max_questions.
   *
   * @generated from protobuf field: int32 minutes_max = 3;
   */
  minutesMax: number;
  /**
   * Question index of the first question the student is issued on the quiz.
   *
   * @generated from protobuf field: int32 start = 4;
   */
  start: number;
  /**
   * Number of questions to move when a student answers a question incorrectly.
   *
   * @generated from protobuf field: int32 move_on_wrong = 5;
   */
  moveOnWrong: number;
  /**
   * Number of questions to move when a student answers a question correctly.
   *
   * @generated from protobuf field: int32 move_on_correct = 6;
   */
  moveOnCorrect: number;
  /**
   * Random jitter +/- around the start index that should be applied when starting
   * the quiz. For example a start index of 5 and a jitter of 2 would allow students
   * to start on indexes 3,4,5,6,7.
   *
   * @generated from protobuf field: int32 start_jitter = 7;
   */
  startJitter: number;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentQuestionOption
 */
export interface AssessmentQuestionOption {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string text = 2;
   */
  text: string;
  /**
   * @generated from protobuf field: bool correct = 3;
   */
  correct: boolean;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentQuestion
 */
export interface AssessmentQuestion {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string text = 2;
   */
  text: string;
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.AssessmentQuestionOption options = 3;
   */
  options: AssessmentQuestionOption[];
  /**
   * When set the options will not be shuffled
   *
   * @generated from protobuf field: bool no_shuffle_options = 4;
   */
  noShuffleOptions: boolean;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.AssessmentDB
 */
export interface AssessmentDB {
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.AssessmentQuestion questions = 1;
   */
  questions: AssessmentQuestion[];
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.AssessmentQuiz assessments = 2;
   */
  assessments: AssessmentQuiz[];
}
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentTask$Type extends MessageType<AssessmentTask> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentTask', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentTask
 */
export const AssessmentTask = new AssessmentTask$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentTask_Spec$Type extends MessageType<AssessmentTask_Spec> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentTask.Spec', [
      {
        no: 1,
        name: 'assessment_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentTask.Spec
 */
export const AssessmentTask_Spec = new AssessmentTask_Spec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentTask_QuestionResult$Type extends MessageType<AssessmentTask_QuestionResult> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentTask.QuestionResult', [
      {
        no: 1,
        name: 'question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'skipped', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'time_taken', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentTask.QuestionResult
 */
export const AssessmentTask_QuestionResult =
  new AssessmentTask_QuestionResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentTask_State$Type extends MessageType<AssessmentTask_State> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentTask.State', [
      {
        no: 1,
        name: 'question',
        kind: 'message',
        T: () => AssessmentTask_Question,
      },
      {
        no: 2,
        name: 'seen_question_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'current_question_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'questions_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 5, name: 'quiz_title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'quiz_description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'start_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 8,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentTask_QuestionResult,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentTask.State
 */
export const AssessmentTask_State = new AssessmentTask_State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentTask_Action$Type extends MessageType<AssessmentTask_Action> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentTask.Action', [
      {
        no: 1,
        name: 'proceed',
        kind: 'scalar',
        oneof: 'action',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'answer',
        kind: 'message',
        oneof: 'action',
        T: () => AssessmentTask_Action_Answer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentTask.Action
 */
export const AssessmentTask_Action = new AssessmentTask_Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentTask_Action_Answer$Type extends MessageType<AssessmentTask_Action_Answer> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentTask.Action.Answer', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentTask.Action.Answer
 */
export const AssessmentTask_Action_Answer =
  new AssessmentTask_Action_Answer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentTask_Question$Type extends MessageType<AssessmentTask_Question> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentTask.Question', [
      { no: 1, name: 'index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'options',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentTask_Option,
      },
      {
        no: 5,
        name: 'stage',
        kind: 'enum',
        T: () => [
          'sparx.reading.tasks.v1.AssessmentTask.Question.Stage',
          AssessmentTask_Question_Stage,
        ],
      },
      {
        no: 6,
        name: 'result',
        kind: 'enum',
        T: () => [
          'sparx.reading.tasks.v1.AssessmentTask.Question.Result',
          AssessmentTask_Question_Result,
        ],
      },
      {
        no: 7,
        name: 'part_results',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: {
          kind: 'enum',
          T: () => [
            'sparx.reading.tasks.v1.AssessmentTask.Question.Result',
            AssessmentTask_Question_Result,
          ],
        },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentTask.Question
 */
export const AssessmentTask_Question = new AssessmentTask_Question$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentTask_Option$Type extends MessageType<AssessmentTask_Option> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentTask.Option', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentTask.Option
 */
export const AssessmentTask_Option = new AssessmentTask_Option$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentQuiz$Type extends MessageType<AssessmentQuiz> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentQuiz', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'description',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'adaptive_delivery',
        kind: 'message',
        T: () => AssessmentQuizAdaptive,
      },
      {
        no: 5,
        name: 'questions',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentQuiz
 */
export const AssessmentQuiz = new AssessmentQuiz$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentQuizAdaptive$Type extends MessageType<AssessmentQuizAdaptive> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentQuizAdaptive', [
      {
        no: 1,
        name: 'min_questions',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'max_questions',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'minutes_max', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'start', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 5,
        name: 'move_on_wrong',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'move_on_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'start_jitter',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentQuizAdaptive
 */
export const AssessmentQuizAdaptive = new AssessmentQuizAdaptive$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentQuestionOption$Type extends MessageType<AssessmentQuestionOption> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentQuestionOption', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentQuestionOption
 */
export const AssessmentQuestionOption = new AssessmentQuestionOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentQuestion$Type extends MessageType<AssessmentQuestion> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentQuestion', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'options',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentQuestionOption,
      },
      {
        no: 4,
        name: 'no_shuffle_options',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentQuestion
 */
export const AssessmentQuestion = new AssessmentQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentDB$Type extends MessageType<AssessmentDB> {
  constructor() {
    super('sparx.reading.tasks.v1.AssessmentDB', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentQuestion,
      },
      {
        no: 2,
        name: 'assessments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AssessmentQuiz,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.AssessmentDB
 */
export const AssessmentDB = new AssessmentDB$Type();
