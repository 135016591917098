import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Collapsible from '@radix-ui/react-collapsible';
import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { Fragment, useEffect, useRef, useState } from 'react';

import { NewBookItem } from './new-book-item';
import styles from './swapped-books.module.css';

type Props = {
  swappedBooks: LibraryBook[] | undefined;
  defaultOpen?: boolean | undefined;
};

export const SwappedBooks = ({ swappedBooks, defaultOpen }: Props) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(!!defaultOpen);

  useEffect(() => {
    if (open && panelRef.current) {
      panelRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [open]);

  if (!swappedBooks || swappedBooks.length === 0) return null;

  return (
    <div className={styles.SwappedBooks}>
      <Collapsible.Root open={open} onOpenChange={setOpen}>
        <Collapsible.Trigger className={styles.DisclosureButton}>
          <FontAwesomeIcon icon={faChevronDown} className={styles.Icon} data-open={open} />

          <span className={styles.DisclosureButtonText}>
            {`${open ? 'Hide' : 'Show'} swapped books`}{' '}
            <span className={styles.BookCount}>{`(${swappedBooks.length})`}</span>
          </span>
        </Collapsible.Trigger>

        <Collapsible.Content className={styles.DisclosurePanel} ref={panelRef}>
          {swappedBooks?.map(lb => (
            <Fragment key={lb.metadataAbridged?.name}>
              {lb.metadataAbridged !== undefined && (
                <NewBookItem
                  book={lb.metadataAbridged}
                  otherBooks={[]}
                  studentBook={lb.studentBook}
                  inline
                />
              )}
            </Fragment>
          ))}
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
};
