import { LoadingSpinner } from '../../components/loading-spinner/loading-spinner';
import { NotConnected } from './status/not-connected';
import { NotReady } from './status/not-ready';
import { Ready } from './status/ready';
import { ReadyToGetStarted } from './status/readyToGetStarted';
import { Reset } from './status/reset';

export enum MISStatus {
  LOADING,
  NOT_CONNECTED,
  NOT_READY,
  READY,
  RESET,
  READY_TO_GET_STARTED,
}

type MISStatusComponentProps = {
  abbreviated?: boolean;
};

export type MISStatusConfig = {
  subtitle: string;
  pageSubtitle: string;
  component: React.FC<MISStatusComponentProps>;
  hasWarning?: boolean;
  hasSuccess?: boolean;
};

export const misStatusConfig: Record<MISStatus, MISStatusConfig> = {
  [MISStatus.LOADING]: {
    subtitle: '',
    pageSubtitle: '',
    component: LoadingSpinner,
  },
  [MISStatus.NOT_CONNECTED]: {
    subtitle: 'Not connected',
    pageSubtitle: 'MIS not connected',
    component: NotConnected,
    hasWarning: true,
  },
  [MISStatus.NOT_READY]: {
    subtitle: 'Not ready to import',
    pageSubtitle: 'Connected, but not ready to import',
    component: NotReady,
    hasWarning: true,
  },
  [MISStatus.READY]: {
    subtitle: 'Ready to import classes',
    pageSubtitle: 'Ready to import classes',
    component: Ready,
    hasSuccess: true,
  },
  [MISStatus.RESET]: {
    subtitle: 'Ready to import classes',
    pageSubtitle: 'Ready to import classes',
    component: Reset,
    hasSuccess: true,
  },
  [MISStatus.READY_TO_GET_STARTED]: {
    subtitle: 'Ready to import classes',
    pageSubtitle: 'Ready to import classes',
    component: ReadyToGetStarted,
    hasSuccess: true,
  },
};
