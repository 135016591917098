import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationCustom_CustomModal } from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import { useClearAlert } from 'components/alert/alert';
import { Button } from 'components/buttons/button';
import styles from 'components/tasks/panel.module.css';

interface INotificationAlertProps {
  custom: NotificationCustom_CustomModal;
}

export const NotificationAlert: React.FC<INotificationAlertProps> = ({ custom }) => {
  const close = useClearAlert();

  return (
    <div className={styles.OnboardingModal}>
      <div className={styles.ExitButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <h2 className={styles.ModalTitle}>{custom.title}</h2>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: custom.content }} />
      <div className={styles.Buttons} style={{ marginTop: 25 }}>
        <Button variant="secondary" onClick={close} analyticsEvent={undefined}>
          Close
        </Button>
      </div>
    </div>
  );
};
