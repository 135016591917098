import {
  faChevronRight,
  faExclamationTriangle,
  faLock,
  faStar,
  faTimes,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { handleErr } from 'app/handle-err';
import classNames from 'classnames';
import { BankLink } from 'components/alert/banking-alert';
import { BookImage } from 'components/book/book-image';
import { BookSwapButton } from 'components/book-swap-button/book-swap-button';
import { Button } from 'components/buttons/button';
import { MatureThemesWarning } from 'components/mature-themes-warning/MatureThemesWarning';
import { HappinessRatingValue } from 'components/rating/happinessrating';
import { StarRating } from 'components/rating/starrating';
import goldIcon from 'components/sections/gold_icon_white.png';
import { ShowMoreLess } from 'components/show-more/show-more';
import panelStyles from 'components/tasks/panel.module.css';
import { useBookNotificationRead, useBookNotifications } from 'queries/notifications';
import { useUserCanReadBook } from 'queries/session';
import { useGetBookTask } from 'queries/tasks';
import { useMemo, useState } from 'react';
import {
  getBookCornerForNotification,
  LibraryBookProgress,
} from 'views/student/library-view/components/library-book';
import { CustomBookBlacklisted } from 'views/student/library-view/subviews/custom-book-page/custom-book-blacklisted';

import styles from './library-book-panel.module.css';

export const BookDescriptor: React.FC<{
  book: MetadataAbridged;
  noMeta?: boolean;
  small?: boolean;
}> = ({ book, noMeta, small = false }) => {
  const pageCount = book.pageCount?.studentPageCount || book.pageCount?.expectedPageCount;

  return (
    <div className={classNames(styles.BookDescriptor, small && styles.BookDescriptorSmall)}>
      <BookImage
        book={book}
        className={classNames(styles.BookDescriptorImage, { [styles.ImageSmall]: small })}
      />
      <div className={classNames(styles.BookDescriptorContent, small && styles.ContentSmall)}>
        {!book.ebookActive && (
          <div className={styles.GoldReader}>
            <h2 className={styles.GoldReaderTitle}>Gold Reader Book</h2>
            <img className={styles.GoldIcon} src={goldIcon} alt="" />
          </div>
        )}
        <h2 className={classNames(styles.BookTitle, small && styles.TitleSmall)}>{book.title}</h2>
        <div className={styles.BookAuthors}>{book.authors.join(', ')}</div>
        {!noMeta && (
          <div className={styles.BookInfo}>
            {Boolean(pageCount) && <div className={styles.BookPages}>{pageCount} pages</div>}
          </div>
        )}
      </div>
    </div>
  );
};

interface ILibraryBookPanel {
  book: MetadataAbridged;
  studentBook: StudentBook;
  close: () => void;
}

export const LibraryBookPanel: React.FC<ILibraryBookPanel> = ({ book, studentBook, close }) => {
  const bookID = book.name.replace('books/', '');
  const [bookLocked, setBookLocked] = useState<boolean>(false);
  const bookNotificationMap = useBookNotifications();
  const notifications = useMemo(() => {
    if (bookID) {
      return bookNotificationMap[bookID];
    }
    return undefined;
  }, [bookID, bookNotificationMap]);

  const readNotification = useBookNotificationRead();
  const openBook = useGetBookTask(bookID, {
    requestReread: true,
  });
  const doOpenBook = () => {
    openBook.mutate(undefined, {
      onSuccess: () => {
        // Mark the notification on this book as read
        if (notifications) {
          for (const n of notifications) {
            readNotification(n.notificationId);
          }
        }
      },
      onError: (err: Error) => {
        if (err.message === 'book not available') {
          setBookLocked(true);
        } else {
          handleErr(err);
        }
      },
    });
  };

  const { allowedToRead } = useUserCanReadBook(book);

  const corner = getBookCornerForNotification(notifications, studentBook);
  const pageCount = book.pageCount?.studentPageCount || book.pageCount?.expectedPageCount;

  if (!book) {
    return null;
  }

  const isGoldReaderBook = book && !book.ebookActive;

  const coverColour = book.coverImage?.prominentColours[0]
    ? '#' + book.coverImage?.prominentColours[0]
    : 'var(--sky-blue-80)';

  return (
    <div
      className={`${panelStyles.PanelWrapper} ${styles.PanelWrapper}`}
      style={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ['--cover-colour' as any]: coverColour,
      }}
    >
      <div className={`${panelStyles.Panel} ${panelStyles.PanelBottom} ${styles.Panel}`}>
        <div className={styles.BookContent}>
          <div className={styles.ExitButton} onClick={close}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div className={`${styles.CoverAndSwap} enter-down-1`}>
            <BookImage book={book} className={styles.BookCover}>
              {corner}
            </BookImage>
            {/* Only show the swap button for ebooks that the user is currently able to read. */}
            {studentBook?.isActive && <BookSwapButton studentBook={studentBook} />}
          </div>
          <div className={`${styles.BookMeta} enter-down-2`}>
            <h2
              className={classNames(styles.BookTitle, { [styles.Gold]: isGoldReaderBook })}
              data-test-id="book-panel-title"
            >
              {book.title}{' '}
              {isGoldReaderBook && (
                <div className={styles.GoldReaderIcon}>
                  <FontAwesomeIcon icon={faStar} />
                </div>
              )}
            </h2>

            <div className={styles.BookAuthors} data-test-id="book-panel-authors">
              <span>{book.authors.join(', ')}</span>

              {isGoldReaderBook && Boolean(pageCount) && (
                <span data-test-id="book-panel-pagecount">{pageCount} pages</span>
              )}
            </div>

            {book.genres.length > 0 && (
              <ul className={styles.GenreList}>
                {book.genres.map(genre => (
                  <li key={genre}>{genre}</li>
                ))}
              </ul>
            )}

            <MatureThemesWarning book={book} className={styles.BookTypeContainer} />

            {book.description && (
              <div className={styles.BookSnippet} data-test-id="book-panel-description">
                <ShowMoreLess
                  content={book.description}
                  maxLength={400}
                  linkColour={'var(--midnight-indigo-100)'}
                  isHTML
                />
              </div>
            )}

            {Boolean(studentBook.rating || studentBook.difficultyRating) && (
              <div className={styles.RatingPane}>
                {Boolean(studentBook.rating) && (
                  <div className={styles.Rating}>
                    <strong>Your Enjoyment Rating:</strong>
                    <StarRating rating={studentBook.rating} />
                  </div>
                )}
                {Boolean(studentBook.difficultyRating) && (
                  <div className={styles.Rating}>
                    <strong>Your Difficulty Rating:</strong>
                    <HappinessRatingValue value={studentBook.difficultyRating || 0} />
                  </div>
                )}
              </div>
            )}

            {!allowedToRead && (
              <div className={styles.BookAlert}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                You need to be a Gold Reader in order to read this book.{' '}
              </div>
            )}

            {book.blacklisted && <CustomBookBlacklisted />}

            <BankedExperience studentBook={studentBook} inReview={studentBook.isComplete} />

            <div className={styles.BookButtons}>
              {studentBook && <LibraryBookProgress progress={studentBook.progress} tall />}
              <div className={styles.BookButtonsButtons}>
                {studentBook.isComplete && (
                  <Button
                    variant="secondary"
                    disabled={true}
                    className={styles.BookComplete}
                    analyticsEvent={undefined}
                  >
                    <FontAwesomeIcon icon={faTrophy} style={{ marginRight: 10 }} />
                    Book complete
                  </Button>
                )}
                {!studentBook.isComplete && studentBook.atContentEnd && (
                  <Button
                    onClick={doOpenBook}
                    variant="secondary"
                    disabled={true}
                    className={styles.BookComplete}
                    analyticsEvent={undefined}
                  >
                    {studentBook.atContentEnd ? 'Content' : 'Book'} coming soon
                  </Button>
                )}
                {!studentBook.isComplete && allowedToRead && !book.blacklisted && (
                  <Button
                    onClick={doOpenBook}
                    disabled={bookLocked}
                    loading={openBook.isLoading}
                    rightIcon={
                      <FontAwesomeIcon
                        icon={bookLocked ? faLock : faChevronRight}
                        fixedWidth={true}
                      />
                    }
                    analyticsEvent={{
                      category: 'library',
                      action: studentBook.progress ? 'continue reading book' : 'start new book',
                      labels: { bookID },
                    }}
                  >
                    {studentBook.progress ? 'Continue Reading' : 'Start Reading'}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BankedExperience = ({
  studentBook,
  inReview,
}: {
  studentBook: StudentBook | undefined;
  inReview?: boolean;
}) => {
  if ((studentBook?.bankedExperience || 0) === 0) {
    return null;
  }
  return (
    <div className={styles.Banked}>
      <span className="info-text--success">
        +{(studentBook?.bankedExperience || 0).toLocaleString()} SRP
      </span>{' '}
      in your <BankLink /> for this book
      {inReview && (
        <div className={styles.BankedSmall}>
          Your banked points will be reviewed and awarded to you soon.
        </div>
      )}
    </div>
  );
};
