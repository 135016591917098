// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/tasks/v1/paperback.proto" (package "sparx.reading.tasks.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { InputQuestion } from '../../content/v1/books';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { BookRange } from '../../content/v1/books';
/**
 * @generated from protobuf message sparx.reading.tasks.v1.PaperbackReadingTask
 */
export interface PaperbackReadingTask {}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.Spec
 */
export interface PaperbackReadingTask_Spec {
  /**
   * @generated from protobuf field: sparx.reading.content.v1.BookRange book = 1;
   */
  book?: BookRange;
  /**
   * @generated from protobuf field: int32 words = 2;
   */
  words: number;
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.PaperbackReadingTask.DeliveryFlag flags = 3;
   */
  flags: PaperbackReadingTask_DeliveryFlag[];
  /**
   * Set to true if the book progress was rewound due to two skipped tasks in a row.
   *
   * @generated from protobuf field: bool rewind_task = 4;
   */
  rewindTask: boolean;
  /**
   * Set to true if the previous task was rewound. Because we rewind two
   * tasks, this content will already have been answered.
   *
   * @generated from protobuf field: bool previous_task_rewound = 5;
   */
  previousTaskRewound: boolean;
  /**
   * The 1-indexed position in the book of the breakpoint that ends the
   * section (`book.end_part_id`) at the time the task was generated.
   * This is 1-indexed so that we can show the first breakpoint in a book
   * as section 1 and to distinguish tasks with this set to the zero
   * value from before this field was added.
   *
   * @generated from protobuf field: int32 breakpoint_position_in_book = 6;
   */
  breakpointPositionInBook: number;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.TaskAttemptState
 */
export interface PaperbackReadingTask_TaskAttemptState {
  /**
   * The results (questions) that the student attempted.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.PaperbackReadingTask.QuestionResult results = 1;
   */
  results: PaperbackReadingTask_QuestionResult[];
  /**
   * The events that occurred during the task.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.PaperbackReadingTask.TimedEvent events = 2;
   */
  events: PaperbackReadingTask_TimedEvent[];
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.TimedEvent
 */
export interface PaperbackReadingTask_TimedEvent {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.PaperbackReadingTask.Event event = 2;
   */
  event: PaperbackReadingTask_Event;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.QuestionResult
 */
export interface PaperbackReadingTask_QuestionResult {
  /**
   * Whether the given answer was correct.
   *
   * @generated from protobuf field: bool correct = 1;
   */
  correct: boolean;
  /**
   * The ID of the question that was answered.
   *
   * @generated from protobuf field: string question_id = 2;
   */
  questionId: string;
  /**
   * The answer that was given.
   *
   * @generated from protobuf field: string answer = 3;
   */
  answer: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.State
 */
export interface PaperbackReadingTask_State {
  /**
   * @generated from protobuf field: sparx.reading.content.v1.BookRange book = 1;
   */
  book?: BookRange;
  /**
   * Contains the IDs of the questions that the student has seen so far in the task. It is reset when the student
   * has answered all of the questions in the breakpoint - if that happens at the end of a task attempt then it
   * becomes empty and if it happens part way through then it is reset to contain just the questions seen in the
   * current attempt.
   *
   * @generated from protobuf field: repeated string questions_seen = 2;
   */
  questionsSeen: string[];
  /**
   * The number of questions to give to the student for this task.
   *
   * @generated from protobuf field: int32 questions_to_issue = 3;
   */
  questionsToIssue: number;
  /**
   * The number of questions that have been issued to the student so far. It goes from 0 up to questions_to_issue
   * and resets to 0 again if the student starts another attempt at the task.
   *
   * @generated from protobuf field: int32 questions_issued = 4;
   */
  questionsIssued: number;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.InputQuestion current_question = 5;
   */
  currentQuestion?: InputQuestion;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.PaperbackReadingTask.State.Stage current_stage = 6;
   */
  currentStage: PaperbackReadingTask_State_Stage;
  /**
   * @generated from protobuf field: int32 questions_correct = 7;
   */
  questionsCorrect: number;
  /**
   * Whether they have answered all of the questions in the current
   * attempt at this task.
   *
   * @generated from protobuf field: bool finished = 8;
   */
  finished: boolean;
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.PaperbackReadingTask.TimedEvent events = 9;
   */
  events: PaperbackReadingTask_TimedEvent[];
  /**
   * @generated from protobuf field: google.protobuf.Timestamp can_start_questions = 10;
   */
  canStartQuestions?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp current_question_timeout = 11;
   */
  currentQuestionTimeout?: Timestamp;
  /**
   * @generated from protobuf field: int32 words = 12;
   */
  words: number;
  /**
   * Set to true if they read too fast
   *
   * @generated from protobuf field: bool skipped_ahead = 13;
   */
  skippedAhead: boolean;
  /**
   * Array of results which are appended to whenever a question
   * is answered. We can use the last item in the list to get the
   * result of the last answered question.
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.PaperbackReadingTask.QuestionResult results = 14;
   */
  results: PaperbackReadingTask_QuestionResult[];
  /**
   * Whether the text should be visible in the background while they are
   * answering questions.
   *
   * @generated from protobuf field: bool show_text_in_questions = 15;
   */
  showTextInQuestions: boolean;
  /**
   * Whether the students should be shown the result of answering each
   * question.
   *
   * @generated from protobuf field: bool show_question_results = 16;
   */
  showQuestionResults: boolean;
  /**
   * Whether to use 'select a sentence' mode for this round of questions.
   *
   * @generated from protobuf field: bool use_select_sentence = 17;
   */
  useSelectSentence: boolean;
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.PaperbackReadingTask.State.Stage
 */
export enum PaperbackReadingTask_State_Stage {
  /**
   * @generated from protobuf enum value: STAGE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STAGE_READING = 1;
   */
  READING = 1,
  /**
   * @generated from protobuf enum value: STAGE_WARNING = 2;
   */
  WARNING = 2,
  /**
   * @generated from protobuf enum value: STAGE_QUESTIONS = 3;
   */
  QUESTIONS = 3,
  /**
   * @generated from protobuf enum value: STAGE_TOOFAST = 4;
   */
  TOOFAST = 4,
  /**
   * @generated from protobuf enum value: STAGE_QUESTION_RESULT = 5;
   */
  QUESTION_RESULT = 5,
  /**
   * @generated from protobuf enum value: STAGE_REWOUND_BOOK = 6;
   */
  REWOUND_BOOK = 6,
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.Action
 */
export interface PaperbackReadingTask_Action {
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'proceed';
        /**
         * @generated from protobuf field: bool proceed = 1;
         */
        proceed: boolean;
      }
    | {
        oneofKind: 'answer';
        /**
         * @generated from protobuf field: string answer = 2;
         */
        answer: string;
      }
    | {
        oneofKind: undefined;
      };
  /**
   * Identifier is used to ensure that the action has been submitted
   * on the same state that the server thinks we are in. For example
   * for an answer action this will contain the question id.
   *
   * @generated from protobuf field: string identifier = 20;
   */
  identifier: string;
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.PaperbackReadingTask.DeliveryFlag
 */
export enum PaperbackReadingTask_DeliveryFlag {
  /**
   * @generated from protobuf enum value: DELIVERY_FLAG_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @deprecated
   * @generated from protobuf enum value: DELIVERY_FLAG_READING_TEST = 1 [deprecated = true];
   */
  READING_TEST = 1,
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.PaperbackReadingTask.Event
 */
export enum PaperbackReadingTask_Event {
  /**
   * @generated from protobuf enum value: EVENT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: EVENT_START_READING = 1;
   */
  START_READING = 1,
  /**
   * @generated from protobuf enum value: EVENT_END_READING = 2;
   */
  END_READING = 2,
  /**
   * @generated from protobuf enum value: EVENT_START_QUESTION = 3;
   */
  START_QUESTION = 3,
  /**
   * @generated from protobuf enum value: EVENT_END_QUESTION = 4;
   */
  END_QUESTION = 4,
  /**
   * @generated from protobuf enum value: EVENT_FORCE_READ = 5;
   */
  FORCE_READ = 5,
  /**
   * @generated from protobuf enum value: EVENT_RETURN_TO_BOOK = 6;
   */
  RETURN_TO_BOOK = 6,
  /**
   * @generated from protobuf enum value: EVENT_SHOW_QUESTION_RESULT = 7;
   */
  SHOW_QUESTION_RESULT = 7,
}
// @generated message type with reflection information, may provide speed optimized methods
class PaperbackReadingTask$Type extends MessageType<PaperbackReadingTask> {
  constructor() {
    super('sparx.reading.tasks.v1.PaperbackReadingTask', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.PaperbackReadingTask
 */
export const PaperbackReadingTask = new PaperbackReadingTask$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaperbackReadingTask_Spec$Type extends MessageType<PaperbackReadingTask_Spec> {
  constructor() {
    super('sparx.reading.tasks.v1.PaperbackReadingTask.Spec', [
      { no: 1, name: 'book', kind: 'message', T: () => BookRange },
      { no: 2, name: 'words', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'flags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.reading.tasks.v1.PaperbackReadingTask.DeliveryFlag',
          PaperbackReadingTask_DeliveryFlag,
          'DELIVERY_FLAG_',
        ],
      },
      { no: 4, name: 'rewind_task', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'previous_task_rewound',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 6,
        name: 'breakpoint_position_in_book',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.Spec
 */
export const PaperbackReadingTask_Spec = new PaperbackReadingTask_Spec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaperbackReadingTask_TaskAttemptState$Type extends MessageType<PaperbackReadingTask_TaskAttemptState> {
  constructor() {
    super('sparx.reading.tasks.v1.PaperbackReadingTask.TaskAttemptState', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PaperbackReadingTask_QuestionResult,
      },
      {
        no: 2,
        name: 'events',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PaperbackReadingTask_TimedEvent,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.TaskAttemptState
 */
export const PaperbackReadingTask_TaskAttemptState =
  new PaperbackReadingTask_TaskAttemptState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaperbackReadingTask_TimedEvent$Type extends MessageType<PaperbackReadingTask_TimedEvent> {
  constructor() {
    super('sparx.reading.tasks.v1.PaperbackReadingTask.TimedEvent', [
      { no: 1, name: 'timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 2,
        name: 'event',
        kind: 'enum',
        T: () => [
          'sparx.reading.tasks.v1.PaperbackReadingTask.Event',
          PaperbackReadingTask_Event,
          'EVENT_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.TimedEvent
 */
export const PaperbackReadingTask_TimedEvent =
  new PaperbackReadingTask_TimedEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaperbackReadingTask_QuestionResult$Type extends MessageType<PaperbackReadingTask_QuestionResult> {
  constructor() {
    super('sparx.reading.tasks.v1.PaperbackReadingTask.QuestionResult', [
      { no: 1, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 2,
        name: 'question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'answer', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.QuestionResult
 */
export const PaperbackReadingTask_QuestionResult =
  new PaperbackReadingTask_QuestionResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaperbackReadingTask_State$Type extends MessageType<PaperbackReadingTask_State> {
  constructor() {
    super('sparx.reading.tasks.v1.PaperbackReadingTask.State', [
      { no: 1, name: 'book', kind: 'message', T: () => BookRange },
      {
        no: 2,
        name: 'questions_seen',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'questions_to_issue',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'questions_issued',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'current_question',
        kind: 'message',
        T: () => InputQuestion,
      },
      {
        no: 6,
        name: 'current_stage',
        kind: 'enum',
        T: () => [
          'sparx.reading.tasks.v1.PaperbackReadingTask.State.Stage',
          PaperbackReadingTask_State_Stage,
          'STAGE_',
        ],
      },
      {
        no: 7,
        name: 'questions_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 8, name: 'finished', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 9,
        name: 'events',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PaperbackReadingTask_TimedEvent,
      },
      {
        no: 10,
        name: 'can_start_questions',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 11,
        name: 'current_question_timeout',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 12, name: 'words', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 13,
        name: 'skipped_ahead',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 14,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => PaperbackReadingTask_QuestionResult,
      },
      {
        no: 15,
        name: 'show_text_in_questions',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 16,
        name: 'show_question_results',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 17,
        name: 'use_select_sentence',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.State
 */
export const PaperbackReadingTask_State = new PaperbackReadingTask_State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PaperbackReadingTask_Action$Type extends MessageType<PaperbackReadingTask_Action> {
  constructor() {
    super('sparx.reading.tasks.v1.PaperbackReadingTask.Action', [
      {
        no: 1,
        name: 'proceed',
        kind: 'scalar',
        oneof: 'action',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'answer',
        kind: 'scalar',
        oneof: 'action',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 20,
        name: 'identifier',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.PaperbackReadingTask.Action
 */
export const PaperbackReadingTask_Action =
  new PaperbackReadingTask_Action$Type();
