import { useQueryClient } from '@tanstack/react-query';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { useEffect } from 'react';

/**
 * QueryAnalytics is a simple utility component that listens to react-query queries and mutations, sending an analytics
 * page event with details of any error (copied from swclient2)
 * @constructor
 */

export const QueryAnalytics = () => {
  const { sendEvent } = useClientEvent();
  const queryClient = useQueryClient();

  useEffect(() => {
    let unsubscribeQuery: () => void;
    let unsubscribeMutation: () => void;
    if (queryClient) {
      unsubscribeQuery = queryClient.getQueryCache().subscribe(event => {
        if (event.type === 'updated' && event.action.type === 'failed') {
          const { failureCount, error } = event.action;
          const { code, methodName, message } = error;
          const queryKey = event.query.queryKey;
          sendEvent({
            action: `${code ? code + ' ' : ''}error querying ${
              methodName
                ? methodName
                : 'unknown method' + (queryKey ? ' with query key: ' + queryKey : '')
            }`,
            category: 'query',
            labels: {
              code: code || 'unknown',
              methodName: methodName || 'unknown',
              message: message || 'unknown',
              failureCount: failureCount.toString(),
              type: 'query',
              // Send more details if the error is not of the expected format:
              details: methodName ? '' : JSON.stringify(error),
              queryKey: event.query.queryKey,
            },
          });
        }
      });
      unsubscribeMutation = queryClient.getMutationCache().subscribe(event => {
        if (event.type === 'updated' && event.action.type === 'error') {
          const { error } = event.action;
          const { code, methodName, message } = error;
          sendEvent({
            action: `${code ? code + ' ' : ''}error mutating ${
              methodName ? methodName : 'unknown method'
            }`,
            category: 'query',
            labels: {
              code: code || 'unknown',
              methodName: methodName || 'unknown',
              message: message || 'unknown',
              type: 'mutation',
              // Send more details if the error is not of the expected format:
              details: methodName ? '' : JSON.stringify(error),
            },
          });
        }
      });
    }
    return () => {
      unsubscribeQuery?.();
      unsubscribeMutation?.();
    };
  }, [queryClient, sendEvent]);

  return null;
};
