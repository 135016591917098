import { Book } from '@sparx/api/apis/sparx/reading/books/v1/book';

export const isISBN13 = (code: string) => {
  // ISBN13 barcodes start with 978 or 979
  if (!code.startsWith('978') && !code.startsWith('979')) {
    return false;
  }
  // ISBN13 has 13 characters
  if (code.length !== 13) {
    return false;
  }

  let checksum = 0;
  for (let i = 0; i < code.length; i++) {
    const char = code[i];
    const value = parseInt(char);
    if (isNaN(value)) {
      // Each character should be a digit
      return false;
    }
    checksum += value * (i % 2 === 1 ? 3 : 1);
  }
  return checksum % 10 === 0;
};

export const getBookId = (book: Book): string => book.name.split('books/')[1];
