/* eslint-disable*/
// because window and document are not defined

export const setupHotjar = () => {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        // @ts-ignore
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    // @ts-ignore
    h._hjSettings = { hjid: 1742301, hjsv: 6 };
    // @ts-ignore
    a = o.getElementsByTagName('head')[0];
    // @ts-ignore
    r = o.createElement('script');
    // @ts-ignore
    r.async = 1;
    // @ts-ignore
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    // @ts-ignore
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

let recording = false;

export const checkHotjarRecording = (user: any) => {
  if (window.hj && user && user.getFeaturesList && !user.getFeaturesList().includes('nohotjar')) {
    recording = true;
    window.hj('trigger', 'user_auth_trigger');
    tagHotjarRecording([`user:${user.getUserId()}`]);
  }
};

export const tagHotjarRecording = (tags: any = []) => {
  if (window.hj && recording) {
    window.hj('tagRecording', tags);
  }
};
