import { appHistory } from 'app/app-history';
import * as firebaseAnalytics from 'firebase/analytics';
import * as firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyANqxoFkfn2BBWs-vS8c3ONIySo0v4xeIk',
  authDomain: 'sparx-discovery-prototypes.firebaseapp.com',
  databaseURL: 'https://sparx-discovery-prototypes.firebaseio.com',
  projectId: 'sparx-discovery-prototypes',
  storageBucket: 'sparx-discovery-prototypes.appspot.com',
  messagingSenderId: '355613815268',
  appId: '1:355613815268:web:7dfeb9868f251e2e7857f1',
  measurementId: window.settings?.gaPropertyID,
};

// A promise that resolves to the analytics object if analytics is supported
// Analytics is not supported for example when running tests.
const appAnalytics = new Promise<firebaseAnalytics.Analytics>((resolve, reject) => {
  if (window.CookieScript?.instance === undefined) {
    return;
  }
  const { action, categories } = window.CookieScript.instance.currentState();
  if (action === 'accept' || categories.includes('performance')) {
    firebaseAnalytics
      .isSupported()
      .then(supported => {
        if (supported && firebaseConfig.measurementId) {
          const app = firebase.initializeApp(firebaseConfig);
          resolve(firebaseAnalytics.getAnalytics(app));
        } else {
          reject();
        }
      })
      .catch(err => console.info('appAnalytics: analytics not supported', err));
  }
});

// Listen to app history changes and log page views
appAnalytics
  .then(analytics => {
    appHistory.listen(({ location }) => {
      firebaseAnalytics.logEvent(analytics, 'page_view', {
        page_location: location.pathname,
        page_path: location.pathname + location.search,
      });
    });
  })
  .catch(err => console.info('appHistory: analytics not supported', err));

// A method for setting user properties once analytics are intitialised.
export const setAnalyticsUserProperties = (properties: firebaseAnalytics.CustomParams) => {
  appAnalytics
    .then(async analytics => {
      // Set the user ID and properties
      firebaseAnalytics.setUserId(analytics, properties.user_id as string);
      firebaseAnalytics.setUserProperties(analytics, properties);
    })
    .catch(err => console.info('userProps: analytics not supported', err));
};

export const getAppAnalytics = () => appAnalytics;
