import { Link } from 'components/link';
import { useSchoolMISStatus } from 'queries/school-status';
import { View } from 'views';
import { plural } from 'views/teacher/readingtest-view/utils';
import { pathForView } from 'views/views';

import { clickedResetLink } from '../events';
import styles from './shared.module.css';

export const Ready = () => {
  const { data, isLoading } = useSchoolMISStatus();

  if (isLoading || !data) return;

  return (
    <>
      <div className={styles.Box}>
        <p>
          We&apos;ve done some checking and it looks like the data in your MIS{' '}
          <strong>is ready</strong> for the new academic year.
        </p>

        <p className={styles.Subtle}>Here&apos;s how we know:</p>

        <p className={styles.Subtle}>
          <em>
            Of the {data.sparxStudents} {plural(data.sparxStudents, 'student', 'students')} who were
            using Sparx Reader last year, {data.movedStudents}{' '}
            {plural(data.sparxStudents, 'has', 'have')} been moved into their new classes in your
            MIS. This is a high percentage of students which suggests your new classes have been set
            up.
          </em>
        </p>
      </div>

      <p className={styles.BottomLine}>
        Once you&apos;ve{' '}
        <Link to={pathForView(View.NewYearSetup) + '/reset'} analyticsEvent={clickedResetLink()}>
          reset your Sparx Reader site
        </Link>{' '}
        we recommend you import your classes and students as soon as possible.
      </p>
    </>
  );
};
