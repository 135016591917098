import { Timestamp } from '@sparx/api/google/protobuf/timestamp';

import { CivilDate } from '../types/civilDate';

export const timestampToDate = (timestamp: Timestamp): Date => {
  return new Date(timestamp.seconds * 1000);
};

export const formatDate = (date?: CivilDate): string => {
  if (!date) {
    return '-';
  }
  return `${('' + date.day).padStart(2, '0')}/${('' + date.month).padStart(
    2,
    '0'
  )}/${date.year}`;
};
