import { LibraryBookPanel } from 'components/alert/library-book-panel';
import { Container } from 'components/container/container';
import { Content } from 'components/content/content';
import { Loading } from 'components/loading/loading';
import { useStartBookDialog } from 'hooks/start-book-dialog';
import { useListLibraryBooks } from 'queries/library-books';
import { useIsUserOnboarded } from 'queries/session';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import styles from './student-container.module.css';
import { StudentSidebar } from './student-sidebar';

type Props = {
  children: React.ReactNode;
  libraryView?: boolean;
};

export const StudentContainer = ({ children, libraryView }: Props) => {
  const onboarded = useIsUserOnboarded();
  const [startBookId, setStartBookId] = useStartBookDialog();
  const { data: libraryBooks, isLoading: libraryBooksLoading } = useListLibraryBooks();

  if (libraryBooksLoading) {
    return <Loading text="Loading library..." />;
  }

  const startingBook = (libraryBooks?.libraryBooks || []).find(
    book => book.metadataAbridged?.name === `books/${startBookId}`,
  );

  return (
    <>
      {startingBook && (
        <div className={styles.DismissArea} onClick={() => setStartBookId(undefined)} />
      )}

      {libraryView ? (
        <Container
          className={`${styles.Content} ${startingBook ? styles.ContentFaded : undefined}`}
        >
          <StudentSidebar />

          <Content className={styles.LibraryContent}>{children}</Content>
        </Container>
      ) : (
        children
      )}

      <TransitionGroup component="div">
        {onboarded &&
          startingBook &&
          startingBook.metadataAbridged !== undefined &&
          startingBook.studentBook !== undefined && (
            <CSSTransition classNames="slideup" timeout={600}>
              <LibraryBookPanel
                key={startingBook.metadataAbridged?.name}
                book={startingBook.metadataAbridged}
                studentBook={startingBook.studentBook}
                close={() => setStartBookId(undefined)}
              />
            </CSSTransition>
          )}
      </TransitionGroup>
    </>
  );
};
