import { Location, useLocation, useOutlet } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface IViewTransitionRoutesProps {
  reverse?: boolean;
}

export const ViewTransitionRoutes = ({ reverse }: IViewTransitionRoutesProps) => {
  const location = useLocation();
  const currentOutlet = useOutlet();

  return (
    <TransitionGroup className={'view-container ' + (reverse ? 'left' : 'right')} component="div">
      <CSSTransition key={locationKey(location)} timeout={500} classNames={'viewslide'}>
        {() => <div className="view">{currentOutlet}</div>}
      </CSSTransition>
    </TransitionGroup>
  );
};

const locationKey = (location: Location): string | undefined => {
  if (location.pathname.startsWith('/library')) {
    return 'library';
  }
  if (location.pathname.startsWith('/teacher')) {
    return 'teacher';
  }
  if (location.pathname.startsWith('/onboarding')) {
    return 'onboarding';
  }
  if (location.pathname.startsWith('/baseline-assessment')) {
    return 'baseline-assessment';
  }
  if (location.pathname.startsWith('/admin')) {
    return 'admin';
  }
  return location.key;
};
