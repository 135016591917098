import { TextRun } from '@sparx/api/apis/sparx/reading/books/v2/book_v2';

export interface ISplitWord {
  value: string;
  isSelectable: boolean;
}

export const splitStringIntoSelectableWords = (run: TextRun): ISplitWord[] => {
  const out: ISplitWord[] = [];
  let working = '';
  let isWord = false;
  const push = () => {
    if (working.length > 0) {
      // Check that the working string has letters in it
      // Regex accepts characters including accents https://stackoverflow.com/a/26900132
      const workingIsWord = working.match(/[A-Za-zÀ-ÖØ-öø-ÿ]/);
      if (isWord && workingIsWord && !run.style?.visibilityHidden)
        out.push({
          value: working,
          isSelectable: true,
        });
      else out.push({ value: working, isSelectable: false });
      working = '';
    }
  };
  Array.from(run.content).forEach((char, ix) => {
    if (char.match(/[A-Za-zÀ-ÖØ-öø-ÿ\-’']/)) {
      if (!isWord) push();
      isWord = true;
    } else if (char === '.') {
      // A fullstop can either end a word or be a part of it in the case of an abbreviation.
      // We check the next two characters to determine this. If the next character is a letter
      // and the following one is a fullstop, we assume it's an abbreviation. This only applies
      // when in a word as a fullstop cannot start a word.
      const nextChar = run.content[ix + 1];
      const nextNextChar = run.content[ix + 2];
      const isAbbreviation =
        nextChar && nextChar.match(/[A-Za-zÀ-ÖØ-öø-ÿ]/) && nextNextChar === '.' && isWord;
      if (!isAbbreviation) {
        if (isWord) push();
        isWord = false;
      }
    } else {
      if (isWord) push();
      isWord = false;
    }
    working += char;
  });
  push();

  return out;
};
