import {
  faBars,
  faCaretDown,
  faChevronLeft,
  faInfoCircle,
  faQuestionCircle,
  faUser,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExperienceReward } from '@sparx/api/apis/sparx/reading/users/v1/experience';
import { User, UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { useIntercom } from '@sparx/intercom';
import { urlKnowledgeBase } from 'app/intercom/knowledge-base-url';
import { Button } from 'components/buttons/button';
import book from 'components/centeredview/book.png';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { Link } from 'components/link';
import bankHeader from 'components/sections/bank_header.svg';
import goldIcon from 'components/sections/gold_icon.png';
import goldLocked from 'components/sections/gold_locked.png';
import { DropdownMenu, MenuDivider } from 'components/sections/header-menu';
import logo from 'components/sections/logo.svg';
import { NotificationContainer } from 'components/sections/notifications';
import styles from 'components/sections/sections.module.css';
import { StudentSearch } from 'components/student-search/student-search';
import { useIsGoldReader } from 'queries/gold';
import { setRewards, useExperience, useLatestReward } from 'queries/rewards';
import { useIsUserOnboarded, useUser } from 'queries/session';
import { useCurrentStaffMember } from 'queries/staff';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactMarkdown from 'react-markdown';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDocument360 } from 'utils/document360';
import {
  Flags,
  IFlaggedNotification,
  useBannerNotification,
  useBooleanFlag,
} from 'utils/featureflags';
import { FCWithChildren } from 'utils/props';
import { useIsStudentDemoMode } from 'utils/student-demo';
import { View } from 'views';
import { TrainingProgressWheel } from 'views/teacher/teacher-training-view/components/training-progress-wheel/training-progress-wheel';
import { TrainingContext } from 'views/teacher/teacher-training-view/training-context';
import {
  ICurrentView,
  pathForView,
  useCurrentView,
  useIsInTeacherMode,
  useNavigateHomeForUser,
} from 'views/views';

import { GettingStartedProgress } from './getting-started-progress';

/**
 * Header displays the header status bar with some user information and provides
 * the ability to logout or change settings.
 */
export const Header = () => {
  const user = useUser();
  const currentView = useCurrentView();
  const location = useLocation();
  const isTeacherMode = useIsInTeacherMode();
  const headerProps = { user, currentView, location, isTeacherMode };

  const showTeacherControls = isTeacherMode && user?.type === UserType.TEACHER;

  return (
    <HeaderContent
      {...headerProps}
      controls={() => (showTeacherControls ? <TeacherHeaderControls /> : <StudentHeaderControls />)}
    />
  );
};

interface IHeaderContentProps {
  user?: User;
  simple?: boolean;
  currentView?: ICurrentView;
  controls: () => JSX.Element;
}

export const HeaderContent = ({ user, simple, currentView, controls }: IHeaderContentProps) => {
  const { sendEvent } = useClientEvent();
  const bannerNotification = useBannerNotification();
  const navigateToHome = useNavigateHomeForUser();
  const isStudentDemoMode = useIsStudentDemoMode();

  const warning = isStudentDemoMode && (
    <div className={styles.HeaderWarning}>
      You are demoing <strong>Sparx Reader</strong> as a student.{' '}
      <Link
        to="/teacher"
        analyticsEvent={{ category: 'navigation', action: 'clicked return to teacher view' }}
      >
        Click here
      </Link>{' '}
      to return to the teacher interface.
    </div>
  );

  const navigateHome = () => {
    if (user) {
      sendEvent({ category: 'navigation', action: 'click logo' });
      navigateToHome(true);
    }
  };

  return (
    <>
      {bannerNotification && <Banner notification={bannerNotification} />}
      {!simple && warning}
      <div className={styles.Header} id="header-portal">
        <div
          className={`${styles.Left} ${
            currentView?.options?.backView ? styles.LeftActive : styles.LeftInactive
          }`}
        >
          <BackButton view={currentView?.options?.backView} />
        </div>

        <img src={book} onClick={navigateHome} className={styles.TitleSmall} alt="Sparx Reader" />
        <img src={logo} alt="Sparx Reader" className={styles.Title} onClick={navigateHome} />
        <div className={styles.Padding} />
        {controls()}
      </div>
    </>
  );
};

const Banner = ({ notification }: { notification: IFlaggedNotification }) => (
  <div className={styles.HeaderCustom}>
    <div className={styles.HeaderCustomContent}>
      <div className={styles.HeaderCustomContentIcon}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </div>
      <div className={styles.HeaderCustomContentText}>
        <ReactMarkdown linkTarget={notification.type === 'external-link' ? '_blank' : ''}>
          {notification.message}
        </ReactMarkdown>
      </div>
    </div>
  </div>
);

const StudentHeaderControls = () => {
  const user = useUser();
  const navigate = useNavigate();
  const { data: experience } = useExperience();
  const onboarded = useIsUserOnboarded();
  const goldReader = useIsGoldReader();

  if (!user) {
    return null;
  }

  const username = (
    <div className={styles.Username}>
      {onboarded && (
        <img
          alt=""
          src={goldReader && !user.statistics?.goldReaderDisabled ? goldIcon : goldLocked}
          onClick={e => {
            e.stopPropagation();
            navigate(pathForView(View.GoldReader));
          }}
        />
      )}
      <span>
        {user.firstName} <span className={styles.Surname}>{user.lastName}</span>
      </span>
    </div>
  );

  const menuAddition = (
    <div className={styles.AdditionalContainer}>
      <li className={styles.Additional}>{username}</li>
      <MenuDivider />
    </div>
  );

  return (
    <div className={styles.UserHolder}>
      {experience && <XPBubble />}
      {username}
      <NotificationContainer />
      <DropdownMenu additional={menuAddition}>
        {onClick => (
          <div className={styles.MenuButton} onClick={onClick}>
            <span className={styles.MenuButtonBarsLabel}>Menu</span>
            <FontAwesomeIcon icon={faBars} className={styles.MenuButtonBars} />
          </div>
        )}
      </DropdownMenu>
    </div>
  );
};

const TeacherHeaderControls = () => {
  const user = useUser();
  const { data: staffMember } = useCurrentStaffMember();
  const useIntercom = useBooleanFlag(Flags.UseIntercom);

  if (!user) {
    return null;
  }

  const displayName = staffMember?.displayName || `${user.firstName} ${user.lastName}`;

  const username = (
    <span className={styles.Username}>
      <span className={styles.UsernameLarge}>{displayName}</span>
      <span className={styles.UsernameSmall}>
        <FontAwesomeIcon icon={faUser} />
      </span>
    </span>
  );

  const menuAddition = (
    <div className={styles.AdditionalContainer}>
      <li className={styles.Additional}>{username}</li>
    </div>
  );

  return (
    <div className={styles.UserHolder}>
      <GettingStartedProgress />
      <StudentSearch />
      {useIntercom ? <IntercomHelp /> : <Doc360Help />}
      <NotificationContainer />
      <DropdownMenu additional={menuAddition}>
        {onClick => (
          <div className={styles.MenuButton} onClick={onClick}>
            {username}
            <div className={styles.Wheel}>
              <TrainingContext>
                <TrainingProgressWheel size={'s'} background="var(--midnight-indigo-80)" />
              </TrainingContext>
            </div>
            <FontAwesomeIcon icon={faCaretDown} className={styles.MenuButtonCaret} />
          </div>
        )}
      </DropdownMenu>
    </div>
  );
};

const Doc360Help = () => {
  const isTeacherMode = useIsInTeacherMode();
  const { isLoaded, openKnowledgeBase } = useDocument360();
  const showHelp = isTeacherMode && isLoaded;

  const onClick = () => openKnowledgeBase();

  return showHelp ? (
    <Button
      onClick={onClick}
      variant="secondary"
      className={styles.NeedHelp}
      leftIcon={<FontAwesomeIcon size="lg" icon={faQuestionCircle} />}
      analyticsEvent={{ category: 'support', action: 'clicked help & support' }}
    >
      <span className={styles.NeedHelpText}>Help & Support</span>
    </Button>
  ) : null;
};

const IntercomHelp = () => {
  const isTeacherMode = useIsInTeacherMode();

  const { intercomEnabled, show, showArticle, showSpace, isOpenRef, hide } = useIntercom();

  const onClick = () => {
    if (isOpenRef.current) {
      // If already open, close the widget
      hide();
      return;
    }

    // Show the home tab
    showSpace('home');
    // There seems to be a bug where sometimes the intercom widget won't
    // show, this timeout works around that. It seems to happen when an
    // article link is opened, closed and then the help button clicked.
    // Events show the widget open and instantly close.
    setTimeout(() => {
      if (!isOpenRef.current) {
        show();
      }
    }, 100);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  // automatically open Intercom when we have the ?support query param
  useEffect(() => {
    // Only trigger if we have intercom and are not on the landing page
    if (intercomEnabled && location.pathname !== '/') {
      if (searchParams.has('support')) {
        const article = searchParams.get('support');

        // remove the support param now that we have read it.
        setSearchParams(prev => {
          prev.delete('support');
          return prev;
        });

        if (!article) {
          // just open intercom
          showSpace('home');
        } else {
          const num = Number(article);
          if (!isNaN(num)) {
            // Open the relevant article
            // https://developers.intercom.com/installing-intercom/web/methods/#intercomshowarticle-articleid
            showArticle(num);
          } else {
            // Assume it's the name of a 'space' and open that.
            // https://developers.intercom.com/installing-intercom/web/methods/#intercomshowspace-spacename
            showSpace(article);
          }
        }
      }
    }
  }, [intercomEnabled, location, searchParams, setSearchParams, show, showArticle, showSpace]);

  return isTeacherMode && intercomEnabled ? (
    <Button
      onClick={onClick}
      variant="secondary"
      className={styles.NeedHelp}
      analyticsEvent={{
        category: 'support',
        action: 'click intercom button',
        labels: { widgetOpen: isOpenRef.current ? 'true' : 'false' },
      }}
      leftIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
    >
      Help &amp; Support
    </Button>
  ) : (
    <Link
      to={urlKnowledgeBase}
      analyticsEvent={{ category: 'support', action: 'click help & support link' }}
      target="_blank"
      rel="noreferrer"
    >
      <Button
        variant="secondary"
        className={styles.NeedHelp}
        analyticsEvent={undefined}
        leftIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
      >
        Help &amp; Support
      </Button>
    </Link>
  );
};

const XPBubble = () => {
  const { data: experience } = useExperience();
  const timeout = useRef<NodeJS.Timeout>();
  const [visibleXP, setVisibleXP] = useState<number>(experience?.experience || 0);
  const [currentReward, setCurrentReward] = useState<ExperienceReward[]>();
  const { data: rewards } = useLatestReward();
  if (rewards !== currentReward) {
    setCurrentReward(rewards);
  }
  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (rewards) {
      timeout.current = setTimeout(() => {
        setVisibleXP(experience?.experience || 0);
        setRewards([]);
      }, 3000 * rewards.length);
    }
  }, [rewards, experience, currentReward, setVisibleXP, timeout]);

  return (
    <>
      {experience && (
        <div className={styles.Experience}>
          <div className={styles.ExperienceBounce} key={visibleXP}>
            {visibleXP.toLocaleString()} <span className={styles.SRPLabel}>SRP</span>
          </div>
          {(rewards || []).map((reward, i) => (
            <div
              key={i}
              className={styles.ExperienceAddition}
              style={{ animationDelay: `${i * 3}s` }}
            >
              {reward.bankedExperience > 0 ? (
                <>
                  +{(reward?.bankedExperience || 0).toLocaleString()}{' '}
                  <img
                    src={bankHeader}
                    alt="BANKED"
                    style={{
                      width: 15,
                      marginLeft: 10,
                    }}
                  />
                </>
              ) : (
                <>+{(reward?.experience || 0).toLocaleString()} SRP</>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export const BackButton: React.FC<{ view?: View }> = ({ view }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => (view ? navigate(pathForView(view)) : {})}
      className={styles.BackButton}
      data-test-id="header-back-button"
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
};

interface IHeaderButton {
  icon: IconDefinition;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const HeaderButton = ({ icon, onClick }: IHeaderButton) => (
  <div onClick={onClick} className={styles.BackButton}>
    <FontAwesomeIcon icon={icon} />
  </div>
);

const getContainer = () => {
  const container = document.createElement('div');
  container.setAttribute('class', `${styles.Left} ${styles.LeftActive}`);
  return container;
};

export const HeaderPortal: FCWithChildren = ({ children }) => {
  const [container] = useState(getContainer());
  useEffect(() => {
    // Find the root element in your DOM
    const modalRoot = document.getElementById('header-portal');
    if (!modalRoot) {
      return; // can't do anything, header is not mounted
    }
    // Append container to root
    modalRoot.prepend(container);
    return () => {
      // On cleanup remove the container
      if (modalRoot) {
        modalRoot.removeChild(container);
      }
    };
  }, [container]);
  return ReactDOM.createPortal(children, container);
};
