import { Question } from 'components/baseline-assessment/question';
import {
  BaselineAssessmentQuestion,
  BaselineAssessmentQuestions,
} from 'components/baseline-assessment/questions';
import { Button } from 'components/buttons';
import { useSaveBaselineAssessmentAnswers } from 'queries/baseline-assessment';
import { useRef, useState } from 'react';

import { TaskComponentProps } from '../../tasks';
import styles from './baseline-assessment.module.css';

export const BaselineAssessmentOnboardingStep = ({ onNextStep }: TaskComponentProps) => {
  const questionRefs = useRef<HTMLDivElement[]>([]);
  const nextButtonRef = useRef<HTMLDivElement>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [answers, setAnswers] = useState(BaselineAssessmentQuestions);
  const { mutate: submitAnswers } = useSaveBaselineAssessmentAnswers();

  const isComplete = answers.every(answer => answer.selectedOption);

  const submit = () => {
    setIsSaving(true);

    submitAnswers(answers, {
      onSuccess: () => onNextStep(),
      onError: () => setIsSaving(false),
    });
  };

  const onSelectOption = (option: BaselineAssessmentQuestion, index: number) => {
    const newAnswers = [...answers];
    const nextQuestionRef = questionRefs.current[index + 1];

    newAnswers[index] = option;
    setAnswers(newAnswers);

    if (nextQuestionRef) {
      nextQuestionRef.scrollIntoView({ behavior: 'smooth' });
    } else if (nextButtonRef.current) {
      nextButtonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <h3 className={styles.StepTitle}>A few quick questions</h3>
      <div className={styles.StepDescription}>
        <p>
          <strong>Please be honest</strong> with your answers. This is not a test.
        </p>
      </div>

      <div>
        {BaselineAssessmentQuestions.map((question, index) => (
          <div
            ref={el => {
              if (el) {
                questionRefs.current[index] = el;
              }
            }}
            key={question.question}
            className={styles.QuestionContainer}
          >
            <hr className={styles.Hr} />
            <Question
              question={question}
              options={question.allOptions}
              onSelectOption={option => onSelectOption(option, index)}
            />
          </div>
        ))}
      </div>
      <div ref={nextButtonRef} className={styles.SubmitButton}>
        <Button
          analyticsEvent={{
            category: 'baseline question',
            action: 'click submit',
          }}
          onClick={submit}
          variant="primary"
          loading={isSaving}
          disabled={!isComplete || isSaving}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
