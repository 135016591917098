import { faCheck, faHandPointer, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubmitAnswerResponse } from '@sparx/api/apis/sparx/reading/vocab/v1/vocab';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from 'views/student/vocab-view/word-wheel.module.css';

interface IWordWheelProps {
  options: string;
  onChange: (value: string) => void;
  active: boolean;
  fixed: boolean;
  result?: SubmitAnswerResponse;
  startingLetter: string;
}

export const WordWheel: React.FC<IWordWheelProps> = ({
  options,
  onChange,
  active,
  fixed,
  result,
  startingLetter,
}) => {
  const [currentTarget, setCurrentTarget] = useState<number | null>(null);
  const [collecting, setCollecting] = useState<boolean>(false);
  const [showHint, setShowHint] = useState<boolean>(false);
  const letters = options.split('');

  const [collection, setCollection] = useState<Array<number>>([]);

  const addToCollection = (index: number) => {
    if (!collection.includes(index)) {
      const newValue = [...collection, index];
      setCollection(newValue);
      onChange(newValue.map(i => letters[i]).join(''));
      if (window?.navigator?.vibrate) {
        window.navigator.vibrate(10);
      }
    }
  };

  const startCollecting = (e: React.PointerEvent<HTMLDivElement>) => {
    setShowHint(false);
    setCollecting(true);
    if (currentTarget != null) {
      addToCollection(currentTarget);
    }
    if (e.target instanceof Element && e.target.releasePointerCapture) {
      e.target.releasePointerCapture(e.pointerId);
    }
  };

  const endCollecting = useCallback(
    (force = false) => {
      if (!fixed && (result?.correct !== true || force)) {
        if (collection.length <= 1) {
          setShowHint(true);
        }
        setCollecting(false);
        setCollection([]);
        onChange('');
      }
    },
    [setCollecting, setCollection, onChange, fixed, result, setShowHint, collection],
  );

  useEffect(() => {
    if (result?.correct === false && collection.length > 0) {
      setTimeout(() => {
        if (!result) {
          endCollecting(true);
        }
      }, 1000);
    }
  }, [result, endCollecting, collection]);

  useEffect(() => {
    const listen = () => {
      endCollecting();
    };
    document.addEventListener('pointerup', listen);
    return () => document.removeEventListener('pointerup', listen);
  }, [endCollecting]);

  const hoverLetter = (e: React.PointerEvent<HTMLDivElement>, i: number, start = false) => {
    if (active) {
      setCurrentTarget(i);
      if (!collecting && start) {
        startCollecting(e);
        addToCollection(i);
      } else if (collecting) {
        addToCollection(i);
      }
    }
    if (e.target instanceof HTMLElement && e.target.releasePointerCapture) {
      e.target.releasePointerCapture(e.pointerId);
    }
  };

  const leaveLetter = () => setCurrentTarget(null);
  const hintLetterSelected = collection.length > 0;
  const hintVisible = showHint && !fixed && active;

  return (
    <div className={classNames(styles.Container, { [styles.ContainerFixed]: fixed })}>
      <VapourTrails collection={collection} active={active} />
      {letters.map((letter, i) => (
        <div
          key={i}
          className={classNames(styles.Letter, {
            [styles.LetterSelected]: collection.includes(i),
            [styles.LetterStarting]:
              startingLetter === letter && !collection.includes(i) && !hintLetterSelected,
          })}
          onPointerDown={e => hoverLetter(e, i, true)}
          onPointerEnter={e => hoverLetter(e, i)}
          onPointerLeave={leaveLetter}
        >
          {letter}
        </div>
      ))}
      <div className={`${styles.ResultCorrect} ${result?.correct === true && styles.ResultShow}`}>
        <FontAwesomeIcon icon={faCheck} />
      </div>
      <div className={`${styles.ResultWrong} ${result?.correct === false && styles.ResultShow}`}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className={`${styles.Hint} ${!hintVisible && styles.HintHide}`}>
        <div className={styles.HintPointer}>
          <FontAwesomeIcon icon={faHandPointer} />
        </div>
        <span className={styles.HintText}>
          Drag between
          <br />
          letters
        </span>
      </div>
    </div>
  );
};

interface IVapourTrailsProps {
  collection: number[];
  active: boolean;
}

const VapourTrails: React.FC<IVapourTrailsProps> = ({ collection, active }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>({
    x: 0,
    y: 0,
  });

  const setMouseLocation = (e: React.PointerEvent<SVGElement>) => {
    if (svgRef.current) {
      // Calculate the mouse XY position in % based on the svg bounding rect
      const boxSize = svgRef.current.getBoundingClientRect();
      const left = (e.clientX - boxSize.left) / boxSize.height;
      const top = (e.clientY - boxSize.top) / boxSize.width;
      setMousePosition({ x: left * 100, y: top * 100 });
    }
  };

  // Clear the mouse position whenever the collection changes
  useEffect(() => setMousePosition(null), [collection, setMousePosition]);

  return (
    <svg className={styles.BackgroundSVG} onPointerMove={setMouseLocation} ref={svgRef}>
      {collection.slice(1).map((letter, i) => {
        const preLocation = indexLocation[collection[i]];
        const curLocation = indexLocation[letter];
        return (
          <line
            key={i}
            x1={`${curLocation.x}%`}
            y1={`${curLocation.y}%`}
            x2={`${preLocation.x}%`}
            y2={`${preLocation.y}%`}
            stroke="var(--white)"
            strokeWidth={5}
          />
        );
      })}
      {collection.length > 0 && mousePosition && active && (
        <>
          <line
            x1={`${mousePosition.x}%`}
            y1={`${mousePosition.y}%`}
            x2={`${indexLocation[collection[collection.length - 1]].x}%`}
            y2={`${indexLocation[collection[collection.length - 1]].y}%`}
            stroke="var(--white)"
            strokeWidth={5}
          />
          <circle fill="var(--white)" r={4} cx={`${mousePosition.x}%`} cy={`${mousePosition.y}%`} />
        </>
      )}
    </svg>
  );
};

const indexLocation: Record<number, { x: number; y: number }> = {
  0: {
    y: 50,
    x: 100,
  },
  1: {
    y: 85.35533905932738,
    x: 85.35533905932738,
  },
  2: {
    y: 100,
    x: 50,
  },
  3: {
    y: 85.35533905932738,
    x: 14.64466094067263,
  },
  4: {
    y: 50,
    x: 0,
  },
  5: {
    y: 14.64466094067263,
    x: 14.644660940672615,
  },
  6: {
    y: 0,
    x: 50,
  },
  7: {
    y: 14.644660940672615,
    x: 85.35533905932738,
  },
};
