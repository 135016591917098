import { faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './open-button.module.css';

type Props = {
  onOpen: () => void;
};

export const OpenButton = ({ onOpen }: Props) => {
  return (
    <div className={styles.OpenContainer}>
      <button onClick={onOpen} className={styles.OpenButton}>
        <FontAwesomeIcon icon={faCode} />
      </button>
    </div>
  );
};
