// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/content/v1/definitions.proto" (package "sparx.reading.feedback.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * UserDefinitionState is a users definition state containing
 * definitions they have requested and the number that they are
 * able to request.
 *
 * @generated from protobuf message sparx.reading.feedback.v1.UserDefinitionState
 */
export interface UserDefinitionState {
  /**
   * Number of words that the user is able to request definitions
   * for in the current period.
   *
   * @generated from protobuf field: int32 words_remaining = 1;
   */
  wordsRemaining: number;
  /**
   * Number of words that the user has requested definitions for
   * in the current period.
   *
   * @generated from protobuf field: int32 words_requested = 2;
   */
  wordsRequested: number;
  /**
   * List of definitions that the student has requested in the
   * past.
   *
   * @generated from protobuf field: repeated sparx.reading.feedback.v1.WordDefinition definitions = 3;
   */
  definitions: WordDefinition[];
}
/**
 * WordDefinition is a definition for a word.
 *
 * @generated from protobuf message sparx.reading.feedback.v1.WordDefinition
 */
export interface WordDefinition {
  /**
   * Unique idenfifier for the definition.
   *
   * @generated from protobuf field: string definition_id = 1;
   */
  definitionId: string;
  /**
   * ID of the book part that the definition was generated for.
   *
   * @generated from protobuf field: string part_id = 2;
   */
  partId: string;
  /**
   * ID of the bok that the definition was created for.
   *
   * @generated from protobuf field: string book_id = 3;
   */
  bookId: string;
  /**
   * Word that the definition was for.
   *
   * @generated from protobuf field: string word = 4;
   */
  word: string;
  /**
   * Context around the word that the definition was generated for.
   * The context may be multiple sentences and will include the
   * word that was requested.
   *
   * @generated from protobuf field: string word_context = 5;
   */
  wordContext: string;
  /**
   * The definition of the word.
   *
   * @generated from protobuf field: string definition = 6;
   */
  definition: string;
  /**
   * Feedback (if any) that was given for the definition.
   *
   * @generated from protobuf field: sparx.reading.feedback.v1.WordDefinitionFeedback feedback = 7;
   */
  feedback?: WordDefinitionFeedback;
}
/**
 * WordDefinitionFeedback is feedback for a word definition.
 *
 * @generated from protobuf message sparx.reading.feedback.v1.WordDefinitionFeedback
 */
export interface WordDefinitionFeedback {
  /**
   * Rating that the user gave the definition.
   *
   * @generated from protobuf field: sparx.reading.feedback.v1.WordDefinitionRating rating = 1;
   */
  rating: WordDefinitionRating;
  /**
   * Feedback that was provided for the definition by the user.
   *
   * @generated from protobuf field: string feedback = 2;
   */
  feedback: string;
}
/**
 * GetUserDefinitionStateRequest is a request to get the current
 * users definition state.
 *
 * @generated from protobuf message sparx.reading.feedback.v1.GetUserDefinitionStateRequest
 */
export interface GetUserDefinitionStateRequest {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
}
/**
 * GetUserDefinitionStateResponse returns the current users definition
 * state.
 *
 * @generated from protobuf message sparx.reading.feedback.v1.GetUserDefinitionStateResponse
 */
export interface GetUserDefinitionStateResponse {
  /**
   * @generated from protobuf field: sparx.reading.feedback.v1.UserDefinitionState state = 1;
   */
  state?: UserDefinitionState;
}
/**
 * SubmitWordDefinitionFeedbackRequest submits feedback for a word definition.
 *
 * @generated from protobuf message sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackRequest
 */
export interface SubmitWordDefinitionFeedbackRequest {
  /**
   * Identifier of the definition the feedback is for.
   *
   * @generated from protobuf field: string definition_id = 1;
   */
  definitionId: string;
  /**
   * Feedback for the definition.
   *
   * @generated from protobuf field: sparx.reading.feedback.v1.WordDefinitionFeedback feedback = 2;
   */
  feedback?: WordDefinitionFeedback;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackResponse
 */
export interface SubmitWordDefinitionFeedbackResponse {}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.GetWordDefinitionRequest
 */
export interface GetWordDefinitionRequest {
  /**
   * Book that the word was in.
   *
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * Book part that the word was in.
   *
   * @generated from protobuf field: string part_id = 2;
   */
  partId: string;
  /**
   * Word that the definition was requested for.
   *
   * @generated from protobuf field: string word = 3;
   */
  word: string;
  /**
   * Context around the word in the book.
   *
   * @generated from protobuf field: string word_context = 4;
   */
  wordContext: string;
}
/**
 * @generated from protobuf message sparx.reading.feedback.v1.GetWordDefinitionResponse
 */
export interface GetWordDefinitionResponse {
  /**
   * Definition for the requested word.
   *
   * @generated from protobuf field: sparx.reading.feedback.v1.WordDefinition definition = 1;
   */
  definition?: WordDefinition;
}
/**
 * @generated from protobuf enum sparx.reading.feedback.v1.WordDefinitionRating
 */
export enum WordDefinitionRating {
  /**
   * @generated from protobuf enum value: WORD_DEFINITION_RATING_UNSPECIFIED = 0;
   */
  WORD_DEFINITION_RATING_UNSPECIFIED = 0,
  /**
   * The definition was helpful.
   *
   * @generated from protobuf enum value: HELPFUL = 1;
   */
  HELPFUL = 1,
  /**
   * The definition was not helpful.
   *
   * @generated from protobuf enum value: UNHELPFUL = 2;
   */
  UNHELPFUL = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class UserDefinitionState$Type extends MessageType<UserDefinitionState> {
  constructor() {
    super('sparx.reading.feedback.v1.UserDefinitionState', [
      {
        no: 1,
        name: 'words_remaining',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'words_requested',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'definitions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => WordDefinition,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.UserDefinitionState
 */
export const UserDefinitionState = new UserDefinitionState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WordDefinition$Type extends MessageType<WordDefinition> {
  constructor() {
    super('sparx.reading.feedback.v1.WordDefinition', [
      {
        no: 1,
        name: 'definition_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'part_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'word', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'word_context',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'definition', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'feedback',
        kind: 'message',
        T: () => WordDefinitionFeedback,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.WordDefinition
 */
export const WordDefinition = new WordDefinition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WordDefinitionFeedback$Type extends MessageType<WordDefinitionFeedback> {
  constructor() {
    super('sparx.reading.feedback.v1.WordDefinitionFeedback', [
      {
        no: 1,
        name: 'rating',
        kind: 'enum',
        T: () => [
          'sparx.reading.feedback.v1.WordDefinitionRating',
          WordDefinitionRating,
        ],
      },
      { no: 2, name: 'feedback', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.WordDefinitionFeedback
 */
export const WordDefinitionFeedback = new WordDefinitionFeedback$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserDefinitionStateRequest$Type extends MessageType<GetUserDefinitionStateRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.GetUserDefinitionStateRequest', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.GetUserDefinitionStateRequest
 */
export const GetUserDefinitionStateRequest =
  new GetUserDefinitionStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserDefinitionStateResponse$Type extends MessageType<GetUserDefinitionStateResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.GetUserDefinitionStateResponse', [
      { no: 1, name: 'state', kind: 'message', T: () => UserDefinitionState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.GetUserDefinitionStateResponse
 */
export const GetUserDefinitionStateResponse =
  new GetUserDefinitionStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitWordDefinitionFeedbackRequest$Type extends MessageType<SubmitWordDefinitionFeedbackRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackRequest', [
      {
        no: 1,
        name: 'definition_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'feedback',
        kind: 'message',
        T: () => WordDefinitionFeedback,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackRequest
 */
export const SubmitWordDefinitionFeedbackRequest =
  new SubmitWordDefinitionFeedbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitWordDefinitionFeedbackResponse$Type extends MessageType<SubmitWordDefinitionFeedbackResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackResponse
 */
export const SubmitWordDefinitionFeedbackResponse =
  new SubmitWordDefinitionFeedbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWordDefinitionRequest$Type extends MessageType<GetWordDefinitionRequest> {
  constructor() {
    super('sparx.reading.feedback.v1.GetWordDefinitionRequest', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'part_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'word', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'word_context',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.GetWordDefinitionRequest
 */
export const GetWordDefinitionRequest = new GetWordDefinitionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWordDefinitionResponse$Type extends MessageType<GetWordDefinitionResponse> {
  constructor() {
    super('sparx.reading.feedback.v1.GetWordDefinitionResponse', [
      { no: 1, name: 'definition', kind: 'message', T: () => WordDefinition },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.feedback.v1.GetWordDefinitionResponse
 */
export const GetWordDefinitionResponse = new GetWordDefinitionResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.feedback.v1.Definitions
 */
export const Definitions = new ServiceType(
  'sparx.reading.feedback.v1.Definitions',
  [
    {
      name: 'GetUserDefinitionState',
      options: {},
      I: GetUserDefinitionStateRequest,
      O: UserDefinitionState,
    },
    {
      name: 'GetWordDefinition',
      options: {},
      I: GetWordDefinitionRequest,
      O: GetWordDefinitionResponse,
    },
    {
      name: 'SubmitWordDefinitionFeedback',
      options: {},
      I: SubmitWordDefinitionFeedbackRequest,
      O: SubmitWordDefinitionFeedbackResponse,
    },
  ],
);
