import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { useAllStudentGroups } from 'queries/management';
import { useSearchParams } from 'react-router-dom';
import { getStudentGroupId } from 'utils/groups';

type Setter = (groups: string[]) => void;

export const useSelectedGroups = (): [Group[], Setter] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: studentGroups } = useAllStudentGroups();

  const setter: Setter = groups => {
    if (groups.length) {
      searchParams.set('group', groups.join(','));
    } else {
      searchParams.delete('group');
    }
    setSearchParams(searchParams);
  };

  const groups: Group[] =
    studentGroups?.filter(a =>
      searchParams.getAll('group').find(g => g === getStudentGroupId(a)),
    ) || [];

  return [groups, setter];
};
