import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { useAtom } from 'jotai';
import { AnimatePresence, motion, MotionConfig } from 'motion/react';

import { selectedDifficultyAtom, selectedRatingAtom } from '../atoms';
import { BookDifficulty } from './book-difficulty/book-difficulty';
import { BookRating } from './book-rating/book-rating';
import { Buttons } from './buttons/buttons';
import styles from './interactive.module.css';

type Props = {
  studentBook: StudentBook;
  task: Task;
};

export const Interactive = ({ studentBook, task }: Props) => {
  const [selectedRating] = useAtom(selectedRatingAtom);
  const [selectedDifficulty] = useAtom(selectedDifficultyAtom);

  return (
    <div className={styles.Container}>
      <AnimatePresence mode="wait">
        {(selectedRating === undefined || selectedDifficulty === undefined) && (
          <motion.div
            className={styles.Box}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { opacity: { duration: 1, delay: 2 } } }}
            exit={{ opacity: 0, transition: { opacity: { duration: 0.2 } } }}
          >
            <div className={styles.InnerBox}>
              <MotionConfig
                transition={{
                  x: { type: 'spring', bounce: 0.3, duration: 0.5 },
                  opacity: { duration: 0.5 },
                }}
              >
                <AnimatePresence mode="wait">
                  {selectedRating === undefined && (
                    <motion.div
                      key="book-rating"
                      initial={{ x: 0, opacity: 1 }}
                      exit={{ x: -50, opacity: 0 }}
                      className={styles.Slide}
                    >
                      <BookRating studentBook={studentBook} task={task} />
                    </motion.div>
                  )}
                  {selectedRating !== undefined && selectedDifficulty === undefined && (
                    <motion.div
                      key="book-difficulty"
                      initial={{ x: 50, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      className={styles.Slide}
                    >
                      <BookDifficulty studentBook={studentBook} task={task} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </MotionConfig>
            </div>
          </motion.div>
        )}

        {selectedRating !== undefined && selectedDifficulty !== undefined && (
          <motion.div
            key="buttons"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ opacity: { duration: 0.35 } }}
          >
            <Buttons />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
