// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/content/v1/definitions.proto" (package "sparx.reading.feedback.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Definitions } from './definitions';
import type { SubmitWordDefinitionFeedbackResponse } from './definitions';
import type { SubmitWordDefinitionFeedbackRequest } from './definitions';
import type { GetWordDefinitionResponse } from './definitions';
import type { GetWordDefinitionRequest } from './definitions';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { UserDefinitionState } from './definitions';
import type { GetUserDefinitionStateRequest } from './definitions';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * Definitions is a service that provides definitions for words.
 *
 * @generated from protobuf service sparx.reading.feedback.v1.Definitions
 */
export interface IDefinitionsClient {
  /**
   * GetUserDefinitionState returns the current user's definition state.
   *
   * @generated from protobuf rpc: GetUserDefinitionState(sparx.reading.feedback.v1.GetUserDefinitionStateRequest) returns (sparx.reading.feedback.v1.UserDefinitionState);
   */
  getUserDefinitionState(
    input: GetUserDefinitionStateRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUserDefinitionStateRequest, UserDefinitionState>;
  /**
   * GetWordDefinition returns a definition for a word. The user must
   * be able to request word definitions.
   *
   * @generated from protobuf rpc: GetWordDefinition(sparx.reading.feedback.v1.GetWordDefinitionRequest) returns (sparx.reading.feedback.v1.GetWordDefinitionResponse);
   */
  getWordDefinition(
    input: GetWordDefinitionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetWordDefinitionRequest, GetWordDefinitionResponse>;
  /**
   * SubmitWordDefinitionFeedback submits feedback for a word definition.
   *
   * @generated from protobuf rpc: SubmitWordDefinitionFeedback(sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackRequest) returns (sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackResponse);
   */
  submitWordDefinitionFeedback(
    input: SubmitWordDefinitionFeedbackRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitWordDefinitionFeedbackRequest,
    SubmitWordDefinitionFeedbackResponse
  >;
}
/**
 * Definitions is a service that provides definitions for words.
 *
 * @generated from protobuf service sparx.reading.feedback.v1.Definitions
 */
export class DefinitionsClient implements IDefinitionsClient, ServiceInfo {
  typeName = Definitions.typeName;
  methods = Definitions.methods;
  options = Definitions.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetUserDefinitionState returns the current user's definition state.
   *
   * @generated from protobuf rpc: GetUserDefinitionState(sparx.reading.feedback.v1.GetUserDefinitionStateRequest) returns (sparx.reading.feedback.v1.UserDefinitionState);
   */
  getUserDefinitionState(
    input: GetUserDefinitionStateRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUserDefinitionStateRequest, UserDefinitionState> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetUserDefinitionStateRequest, UserDefinitionState>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetWordDefinition returns a definition for a word. The user must
   * be able to request word definitions.
   *
   * @generated from protobuf rpc: GetWordDefinition(sparx.reading.feedback.v1.GetWordDefinitionRequest) returns (sparx.reading.feedback.v1.GetWordDefinitionResponse);
   */
  getWordDefinition(
    input: GetWordDefinitionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetWordDefinitionRequest, GetWordDefinitionResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetWordDefinitionRequest, GetWordDefinitionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * SubmitWordDefinitionFeedback submits feedback for a word definition.
   *
   * @generated from protobuf rpc: SubmitWordDefinitionFeedback(sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackRequest) returns (sparx.reading.feedback.v1.SubmitWordDefinitionFeedbackResponse);
   */
  submitWordDefinitionFeedback(
    input: SubmitWordDefinitionFeedbackRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitWordDefinitionFeedbackRequest,
    SubmitWordDefinitionFeedbackResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitWordDefinitionFeedbackRequest,
      SubmitWordDefinitionFeedbackResponse
    >('unary', this._transport, method, opt, input);
  }
}
