export const positionToAddon: Record<number, React.ReactNode> = {
  1: (
    <svg width="40" height="34" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.743 8.545h-1.89v6.79h1.89v-6.79Z" fill="#FFD12F" />
      <path opacity=".1" d="M10.746 15.335H9.8v-3.762l.946-.095v3.857Z" fill="#A85B00" />
      <path
        d="M11.846 15.153v.785H7.754v-.785c0-.656.531-1.188 1.188-1.188h1.713c.657 0 1.189.532 1.189 1.188h.002Z"
        fill="#FFD12F"
      />
      <path
        opacity=".1"
        d="M11.846 15.153v.182H9.8v-1.37h.857c.657 0 1.189.532 1.189 1.188Z"
        fill="#A85B00"
      />
      <path
        d="M15.101 3.365h2.89a.05.05 0 0 1 .05.05v.594c0 1.725-1.136 4.5-5.143 4.5"
        stroke="#EFC42A"
      />
      <path
        d="M13.194 16.524v.242c0 .088-.07.16-.158.16H6.56a.158.158 0 0 1-.159-.16v-.242c0-.656.532-1.188 1.188-1.188h4.412c.656 0 1.188.532 1.188 1.188h.004Z"
        fill="#EFC42A"
      />
      <path
        d="M4.618 3.365h-2.89a.05.05 0 0 0-.05.05v.594c0 1.725 1.136 4.5 5.143 4.5"
        stroke="#EFC42A"
      />
      <path
        d="M15.931.56c.243 0 .44.198.436.44-.138 5.887-3.027 10.593-6.568 10.593C6.26 11.593 3.372 6.89 3.235 1A.433.433 0 0 1 3.67.56H15.93Z"
        fill="#FFD12F"
      />
      <path
        opacity=".1"
        d="M16.365 1C16.228 6.885 13.34 11.59 9.8 11.593c0 0 4.332-5.107 3.395-11.032h2.734c.243 0 .442.197.436.44Z"
        fill="#A85B00"
      />
      <path
        d="M13.534 16.524v.242c0 .086-.071.157-.159.157H9.8v-1.587h2.543c.657 0 1.19.532 1.19 1.188Z"
        fill="#E5B828"
      />
      <path d="M9.503 9.092V4.17l-1.022.24v-.87l1.502-.667h.701v6.219H9.503Z" fill="#8B4B00" />
    </svg>
  ),
  2: (
    <svg width="40" height="34" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.743 8.195h-1.89v6.79h1.89v-6.79Z" fill="#D0D0D0" />
      <path d="M10.746 14.985H9.8v-3.762l.946-.094v3.856Z" fill="#C7C7C7" />
      <path
        d="M11.846 14.803v.785H7.754v-.785c0-.656.531-1.188 1.188-1.188h1.713c.657 0 1.189.532 1.189 1.188h.002Z"
        fill="#D0D0D0"
      />
      <path d="M11.846 14.803v.183H9.8v-1.37h.857c.657 0 1.189.531 1.189 1.187Z" fill="#C7C7C7" />
      <path
        d="M15.237 2.564h3.287c.033 0 .058.026.058.058V3.3c0 1.973-1.293 5.145-5.853 5.145"
        stroke="#BCBCBC"
      />
      <path
        d="M13.194 16.172v.243c0 .088-.07.159-.158.159H6.56a.158.158 0 0 1-.159-.16v-.242c0-.656.532-1.188 1.188-1.188h4.412c.656 0 1.188.532 1.188 1.188h.004Z"
        fill="#C9C9C9"
      />
      <path
        d="M4.616 2.564H1.328a.058.058 0 0 0-.057.058V3.3c0 1.973 1.293 5.145 5.852 5.145"
        stroke="#BCBCBC"
      />
      <path
        d="M15.931.21c.243 0 .44.198.436.44-.138 5.888-3.027 10.593-6.568 10.593-3.54 0-6.427-4.703-6.564-10.592A.433.433 0 0 1 3.67.21H15.93Z"
        fill="#D0D0D0"
      />
      <path
        opacity=".1"
        d="M16.365.65C16.228 6.537 13.34 11.242 9.8 11.244c0 0 4.332-5.106 3.395-11.032h2.734c.243 0 .442.197.436.44Z"
        fill="#797979"
      />
      <path
        d="M13.534 16.172v.243c0 .086-.071.157-.159.157H9.8v-1.588h2.543c.657 0 1.19.532 1.19 1.188Z"
        fill="#BCBCBC"
      />
      <path
        d="M7.741 8.275V7.45c.38-.314.746-.625 1.102-.933.36-.307.68-.612.96-.915.283-.302.508-.595.674-.879.172-.29.258-.571.258-.844 0-.255-.071-.48-.213-.675-.137-.195-.365-.293-.684-.293-.326 0-.572.106-.738.32a1.22 1.22 0 0 0-.248.772H7.75c.012-.456.113-.835.302-1.137a1.75 1.75 0 0 1 .755-.684 2.368 2.368 0 0 1 1.057-.23c.634 0 1.125.174 1.475.524.355.343.533.79.533 1.34 0 .344-.08.679-.24 1.005a4.34 4.34 0 0 1-.613.941c-.255.302-.53.587-.826.853-.296.26-.583.504-.862.729h2.692v.932H7.74Z"
        fill="#5F5F5F"
      />
    </svg>
  ),
  3: (
    <svg width="40" height="34" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.743 8.846h-1.89v6.79h1.89v-6.79Z" fill="#EAB069" />
      <path opacity=".1" d="M10.746 15.636H9.8v-3.762l.946-.095v3.857Z" fill="#A85B00" />
      <path
        d="M11.846 15.454v.785H7.754v-.785c0-.657.531-1.188 1.188-1.188h1.713c.657 0 1.189.531 1.189 1.188h.002Z"
        fill="#EAB069"
      />
      <path
        opacity=".1"
        d="M11.846 15.454v.182H9.8v-1.37h.857c.657 0 1.189.531 1.189 1.188Z"
        fill="#A85B00"
      />
      <path
        d="M15.237 3.215h3.287c.033 0 .058.026.058.058v.677c0 1.974-1.293 5.146-5.853 5.146"
        stroke="#D89C52"
      />
      <path
        d="M13.194 16.823v.242c0 .088-.07.159-.158.159H6.56a.158.158 0 0 1-.159-.159v-.242c0-.656.532-1.188 1.188-1.188h4.412c.656 0 1.188.532 1.188 1.188h.004Z"
        fill="#D89C52"
      />
      <path
        d="M4.616 3.215H1.328a.058.058 0 0 0-.057.058v.677c0 1.974 1.293 5.146 5.852 5.146"
        stroke="#D89C52"
      />
      <path
        d="M15.931.86c.243 0 .44.197.436.439-.138 5.887-3.027 10.593-6.568 10.593-3.54 0-6.427-4.704-6.564-10.593a.433.433 0 0 1 .435-.44H15.93Z"
        fill="#EAB069"
      />
      <path
        opacity=".2"
        d="M16.365 1.299c-.137 5.885-3.024 10.59-6.565 10.593 0 0 4.332-5.107 3.395-11.033h2.734c.243 0 .442.198.436.44Z"
        fill="#7F4500"
      />
      <path
        d="M13.534 16.823v.242c0 .086-.071.157-.159.157H9.8v-1.587h2.543c.657 0 1.19.532 1.19 1.188Z"
        fill="#C98C41"
      />
      <path
        d="M9.926 9.5c-.408 0-.778-.072-1.11-.214a1.847 1.847 0 0 1-.8-.675c-.2-.302-.307-.681-.32-1.137h1.12c.006.302.104.556.293.764.196.201.468.302.817.302.332 0 .587-.092.764-.276a.956.956 0 0 0 .267-.693c0-.325-.119-.571-.355-.737-.231-.172-.53-.257-.898-.257h-.462v-.933h.471c.302 0 .554-.071.755-.213.202-.143.302-.353.302-.631a.698.698 0 0 0-.23-.55c-.149-.143-.356-.214-.622-.214-.29 0-.519.086-.684.258-.16.171-.25.382-.267.63h-1.11c.023-.574.222-1.027.595-1.359.379-.332.867-.497 1.466-.497.426 0 .784.077 1.075.23a1.562 1.562 0 0 1 .897 1.422c0 .35-.098.645-.293.888a1.39 1.39 0 0 1-.711.48c.35.071.634.243.853.515.219.267.328.604.328 1.013 0 .343-.083.657-.248.942a1.81 1.81 0 0 1-.729.684c-.314.171-.702.257-1.164.257Z"
        fill="#7A4200"
      />
    </svg>
  ),
};
