// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/vocab/v1/vocab.proto" (package "sparx.reading.content.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Vocab } from './vocab';
import type { SubmitAnswerResponse } from './vocab';
import type { SubmitAnswerRequest } from './vocab';
import type { GetQuestionResponse } from './vocab';
import type { GetQuestionRequest } from './vocab';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetStateResponse } from './vocab';
import type { GetStateRequest } from './vocab';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.content.v1.Vocab
 */
export interface IVocabClient {
  /**
   * @generated from protobuf rpc: GetState(sparx.reading.content.v1.GetStateRequest) returns (sparx.reading.content.v1.GetStateResponse);
   */
  getState(
    input: GetStateRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStateRequest, GetStateResponse>;
  /**
   * @generated from protobuf rpc: GetQuestion(sparx.reading.content.v1.GetQuestionRequest) returns (sparx.reading.content.v1.GetQuestionResponse);
   */
  getQuestion(
    input: GetQuestionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetQuestionRequest, GetQuestionResponse>;
  /**
   * @generated from protobuf rpc: SubmitAnswer(sparx.reading.content.v1.SubmitAnswerRequest) returns (sparx.reading.content.v1.SubmitAnswerResponse);
   */
  submitAnswer(
    input: SubmitAnswerRequest,
    options?: RpcOptions,
  ): UnaryCall<SubmitAnswerRequest, SubmitAnswerResponse>;
}
/**
 * @generated from protobuf service sparx.reading.content.v1.Vocab
 */
export class VocabClient implements IVocabClient, ServiceInfo {
  typeName = Vocab.typeName;
  methods = Vocab.methods;
  options = Vocab.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetState(sparx.reading.content.v1.GetStateRequest) returns (sparx.reading.content.v1.GetStateResponse);
   */
  getState(
    input: GetStateRequest,
    options?: RpcOptions,
  ): UnaryCall<GetStateRequest, GetStateResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetStateRequest, GetStateResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetQuestion(sparx.reading.content.v1.GetQuestionRequest) returns (sparx.reading.content.v1.GetQuestionResponse);
   */
  getQuestion(
    input: GetQuestionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetQuestionRequest, GetQuestionResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetQuestionRequest, GetQuestionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: SubmitAnswer(sparx.reading.content.v1.SubmitAnswerRequest) returns (sparx.reading.content.v1.SubmitAnswerResponse);
   */
  submitAnswer(
    input: SubmitAnswerRequest,
    options?: RpcOptions,
  ): UnaryCall<SubmitAnswerRequest, SubmitAnswerResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SubmitAnswerRequest, SubmitAnswerResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
