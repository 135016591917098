// Minimum number of words to include in the context.
const minWords = 15;
// Maximum number of words to include in the context.
const maxWords = 50;
// Maximum string length of the context.
const maxLength = 250;

export const gatherContextAroundElement = (
  element: HTMLSpanElement,
  container: HTMLDivElement | null,
) => {
  const validElement = (e: HTMLElement) => container && container !== e && container.contains(e);

  if (!element.parentElement || !validElement(element.parentElement)) return '';
  let elements: ChildNode[] = [element.parentElement];

  // Returns the text value of an array of elements.
  const getValue = (els: ChildNode[]) =>
    els
      .map(e => e.textContent)
      .join(' ')
      .replace(/\s+/g, ' ')
      .trim();

  // Returns true if the elements provide enough context.
  const haveEnough = (els: ChildNode[]) => {
    const value = getValue(els);
    return value.split(/\s+/g).length >= minWords;
  };

  // Returns if the elements have provide too much context.
  const haveTooMuch = (els: ChildNode[]) => {
    const value = getValue(els);
    return value.length > maxLength || value.split(/\s+/g).length >= maxWords;
  };

  // Sets elements to a new value if the new value is valid. Will return
  // an array if we should exit the loop.
  const trySetElements = (newElements: ChildNode[]) => {
    if (haveTooMuch(newElements)) return elements;
    if (haveEnough(newElements)) return newElements;
    elements = newElements;
  };

  const gatherElements = () => {
    // Until we have enough context we keep adding elements to the context
    // in this priority order:
    //  1. Previous siblings
    //  2. Next siblings
    //  3. Parent
    // When we reach the parent we restart the loop starting with that element.
    //
    // We do this as we believe the context before the element is more useful
    // for creating a definition.
    while (!haveEnough(elements)) {
      // Iterate previous siblings of first element in list
      while (elements[0].previousSibling) {
        const result = trySetElements([elements[0].previousSibling, ...elements]);
        if (result) return result;
      }

      // Iterate next siblings of last element in list
      while (elements[elements.length - 1].nextSibling !== null) {
        const next = elements[elements.length - 1].nextSibling;
        if (!next) break;
        const result = trySetElements([...elements, next]);
        if (result) return result;
      }

      // Pop out if we have a parent, and it's not the container
      // (don't want the element to escape the book content).
      const parent = elements[0]?.parentElement;
      if (parent && validElement(parent)) {
        // If we have a parent, we want to start the loop again with the parent.
        // At this point all the values of elements are children of parent.
        const result = trySetElements([parent]);
        if (result) return result;
      } else {
        return elements;
      }
    }
    return elements;
  };
  return getValue(gatherElements());
};
