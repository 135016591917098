import { CenteredEmpty } from 'components/centeredview/centered-view';

import styles from './server-unavailable-view.module.css';

export const ServerUnavailableView = ({ error }: { error: Error | null }) => {
  if (error) {
    console.error(`An error occurred: ${error.message}`);
  }

  return (
    <CenteredEmpty>
      <div className={styles.ServerUnavailable}>
        There has been a problem connecting to Sparx Reader. Please check your network connection or
        try again later.
      </div>
    </CenteredEmpty>
  );
};
