import { StudentContainer } from 'app/containers/student-container/student-container';
import { Outlet, RouteObject } from 'react-router-dom';
import { View } from 'views';
import { LandingView } from 'views/common/landing-view/landing-view';
import { ChooseNewBookView } from 'views/student/choose-new-book-view/choose-new-book-view';
import { GoldReaderView } from 'views/student/gold-reader-view/gold-reader-view';
import { LeagueView } from 'views/student/league-view/league-view';
import { LockedIntoTaskWrapper } from 'views/student/library-view/components/locked-into-task';
import { AddGoldReaderBookView } from 'views/student/library-view/subviews/add-gold-reader-book-view';
import { BookListView } from 'views/student/library-view/subviews/book-list-view';
import { HomeView } from 'views/student/library-view/subviews/home-view';
import { OnboardingView } from 'views/student/onboarding-view/onboarding-view';
import { TaskView } from 'views/student/task-view/task-view';
import { VocabLandingView } from 'views/student/vocab-view/vocab-landing-view';
import { VocabView } from 'views/student/vocab-view/vocab-view';
import { TrainingContext } from 'views/teacher/teacher-training-view/training-context';
import { pathForView } from 'views/views';

import { WithStudentRedirects } from './with-student-redirects';

export const studentRoutes: RouteObject[] = [
  // Sidebar hidden routes
  {
    path: '/',
    element: (
      <StudentContainer>
        <TrainingContext>
          <Outlet />
        </TrainingContext>
      </StudentContainer>
    ),
    children: [
      {
        path: pathForView(View.Landing),
        element: <LandingView />,
      },
      {
        path: pathForView(View.Onboarding),
        element: <OnboardingView />,
      },
      {
        path: pathForView(View.Task),
        element: <TaskView />,
      },
      {
        path: pathForView(View.Vocab),
        element: <VocabView />,
      },
    ],
  },
  // Library routes
  {
    path: pathForView(View.Library),
    element: (
      <StudentContainer libraryView>
        <WithStudentRedirects>
          <Outlet />
        </WithStudentRedirects>
      </StudentContainer>
    ),
    children: [
      {
        path: '',
        element: <HomeView />,
      },
      {
        path: 'explore',
        element: (
          <LockedIntoTaskWrapper>
            <BookListView />
          </LockedIntoTaskWrapper>
        ),
      },
      {
        path: 'explore/new',
        element: (
          <LockedIntoTaskWrapper>
            <ChooseNewBookView />
          </LockedIntoTaskWrapper>
        ),
      },
      {
        path: 'add',
        element: (
          <LockedIntoTaskWrapper>
            <AddGoldReaderBookView />
          </LockedIntoTaskWrapper>
        ),
      },
      {
        path: 'goldreader',
        element: (
          <LockedIntoTaskWrapper>
            <GoldReaderView />
          </LockedIntoTaskWrapper>
        ),
      },
      {
        path: 'league/:tab?',
        element: (
          <LockedIntoTaskWrapper>
            <LeagueView />
          </LockedIntoTaskWrapper>
        ),
      },
      {
        path: 'vocab',
        element: (
          <LockedIntoTaskWrapper>
            <VocabLandingView />
          </LockedIntoTaskWrapper>
        ),
      },
    ],
  },
];
