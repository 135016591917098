import { faLock, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/sidebar/sidebar.module.css';

// SidebarButton accepts either a font awesome icon - icon - or string
// references to a selected and deselected image - selectedImg and deselectedImg.
interface ISidebarButtonProps {
  onClick: () => void;
  selected: boolean;
  content: string | JSX.Element;
  icon?: IconDefinition;
  selectedImg?: string;
  deselectedImg?: string;
  locked?: boolean;
}

export const SidebarButton: React.FC<ISidebarButtonProps> = ({
  onClick,
  selected,
  icon,
  selectedImg,
  deselectedImg,
  content,
  locked,
}) => (
  <div
    onClick={onClick}
    className={classNames(styles.SidebarButton, {
      [styles.SidebarSelected]: selected,
      [styles.SidebarSelectedLocked]: locked,
    })}
    role="button"
    tabIndex={1}
  >
    {locked && (
      <div className={styles.LockIcon}>
        <FontAwesomeIcon icon={faLock} />
      </div>
    )}
    {icon !== undefined ? (
      <FontAwesomeIcon icon={icon} />
    ) : selected ? (
      <img src={selectedImg} className={styles.Img} />
    ) : (
      <img src={deselectedImg} className={styles.Img} />
    )}
    <span>{content}</span>
  </div>
);
