import { Button } from 'components/buttons/button';
import { useState } from 'react';

import { clickedBack, clickedSubmit, typedComment } from '../events';
import styles from '../popover.module.css';

interface ICommentProps {
  onBack: () => void;
  onCommentChange: (comment: string) => void;
  onSubmit: () => void;
  isDisabled: boolean;
}

export const Comment = ({ onBack, onCommentChange, onSubmit, isDisabled }: ICommentProps) => {
  const [comment, setComment] = useState('');

  const onChange = (value: string) => {
    onCommentChange(value);
    setComment(value);
  };

  return (
    <div>
      <p className={styles.SectionText}>Could you tell us more about your rating?</p>
      <p className={styles.SectionText}>
        The team personally reads every comment (1000+ and counting). Your feedback is essential to
        help us improve Sparx Reader.
      </p>

      <textarea
        rows={2}
        className={styles.TextInput}
        onChange={e => onChange(e.target.value)}
        onBlur={e => typedComment(e.target.value)}
      />

      <div className={styles.Buttons}>
        <Button
          onClick={() => onBack()}
          variant="secondary"
          disabled={isDisabled}
          analyticsEvent={clickedBack(comment)}
        >
          Back
        </Button>
        <Button
          onClick={() => onSubmit()}
          disabled={isDisabled}
          analyticsEvent={clickedSubmit(comment)}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
