import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { Button } from 'components/buttons/button';
import { useStartBookDialog } from 'hooks/start-book-dialog';
import { useState } from 'react';
import { LibraryBookItem } from 'views/student/library-view/components/library-book';

import styles from './books-section.module.css';
import { LibraryContainer } from './library-container';

export interface BooksSectionProps {
  title: string;
  books: LibraryBook[] | undefined;
}

export const visibleBooksLimit = 4;

export const BooksSection = ({ title, books = [] }: BooksSectionProps) => {
  const [, openStartBookDialog] = useStartBookDialog();

  const [showAll, setShowAll] = useState(false);
  if (books.length === 0) {
    return null;
  }
  const limit = showAll ? 0 : visibleBooksLimit;
  const visibleBooks = limit ? books.slice(0, limit) : books;

  return (
    <>
      <h2 className={styles.Title}>{title}</h2>
      <LibraryContainer>
        <div className={styles.BookShelf}>
          {visibleBooks.map(book => (
            <LibraryBookItem
              key={book.metadataAbridged?.name}
              book={book}
              onClick={() => openStartBookDialog(book.metadataAbridged)}
            />
          ))}
          {/* Padding to ensure that there are no full width tasks*/}
          <div className={styles.PaddingBox} />
          <div className={styles.PaddingBox} />
          <div className={styles.PaddingBox} />
        </div>
        {books.length > visibleBooksLimit && (
          <Button
            onClick={() => setShowAll(!showAll)}
            className={styles.ShowAllButton}
            analyticsEvent={{
              category: 'library',
              action: showAll ? 'clicked show all' : 'clicked hide all',
            }}
          >
            {!showAll ? 'Show' : 'Hide'} all
            <FontAwesomeIcon icon={showAll ? faChevronUp : faChevronDown} />
          </Button>
        )}
      </LibraryContainer>
    </>
  );
};
