import {
  faBackwardFast,
  faChevronLeft,
  faChevronRight,
  faForwardFast,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/buttons';
import { useEffect, useMemo } from 'react';

export const usePaginator = <T,>(
  paginated: boolean,
  items: T[],
  page: number,
  setPage: (val: number) => void,
  perPage: number,
) => {
  const pages = useMemo(() => Math.ceil(items.length / perPage), [items, perPage]);

  // Reset page count when items list changes
  useEffect(() => {
    if (items.length < page * perPage) {
      setPage(Math.floor(items.length / perPage));
    }
  }, [items.length, perPage, page, setPage]);

  if (!paginated) {
    return { visibleItems: items, navigator: null, offset: 0 };
  }

  const min = page * perPage;
  const max = Math.min(min + perPage, items.length);
  const visibleItems = items.slice(min, max);

  const minPage = page < 3 ? 0 : page - 2;
  const maxPage = Math.min(minPage + 5, pages);

  const navigator = pages > 1 && (
    <div style={{ display: 'flex', gap: 5 }}>
      <Button
        size="small"
        variant="secondary"
        onClick={() => setPage(0)}
        disabled={page - 1 < 0}
        analyticsEvent={undefined}
      >
        <FontAwesomeIcon icon={faBackwardFast} />
      </Button>
      <Button
        size="small"
        variant="primary"
        leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        onClick={() => setPage(page - 1)}
        disabled={page - 1 < 0}
        analyticsEvent={undefined}
      >
        Previous
      </Button>
      {Array.from({ length: maxPage - minPage }).map((_, index) => (
        <Button
          size="small"
          variant={minPage + index === page ? 'primary' : 'secondary'}
          key={index}
          onClick={() => setPage(minPage + index)}
          analyticsEvent={undefined}
        >
          {minPage + index + 1}
        </Button>
      ))}
      <Button
        size="small"
        rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
        variant="primary"
        onClick={() => setPage(page + 1)}
        disabled={page + 1 >= pages}
        analyticsEvent={undefined}
      >
        Next
      </Button>
      <Button
        size="small"
        variant="secondary"
        onClick={() => setPage(pages)}
        disabled={page + 1 >= pages}
        analyticsEvent={undefined}
      >
        <FontAwesomeIcon icon={faForwardFast} />
      </Button>
    </div>
  );

  return { navigator, visibleItems, offset: min };
};
