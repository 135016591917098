import { StudentState } from '@sparx/api/apis/sparx/reading/monitoring/v1/monitoring';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { timestampToMoment } from 'utils/time';

import { IStudentStateSummary } from './live-view';

export const useStudentStateMap = (states: StudentState[] | undefined) =>
  useMemo(
    () =>
      states?.reduce<Record<string, StudentState>>((p, s) => {
        p[s.studentId] = s;
        return p;
      }, {}) || {},
    [states],
  );

const getLastActiveSeconds = (date: Moment | undefined) =>
  !date ? undefined : moment.duration(moment().diff(date)).asSeconds();

export const getStudentStateSummary = (state: StudentState | undefined): IStudentStateSummary => {
  const lastActive = getLastActiveSeconds(timestampToMoment(state?.lastActive));
  return {
    state,
    lastActive,
    online: lastActive !== undefined && lastActive < 60,
    lastReadingCheckPassed: timestampToMoment(state?.lastReadingCheckPassed)?.isSame(
      moment(),
      'day',
    )
      ? timestampToMoment(state?.lastReadingCheckPassed)
      : undefined,
    lastLogin: timestampToMoment(state?.lastLogin)?.isSame(moment(), 'day')
      ? timestampToMoment(state?.lastLogin)
      : undefined,
  };
};
