import { AssessmentTask_Action } from '@sparx/api/apis/sparx/reading/tasks/v1/assessment';
import {
  BooklogQuestion,
  BooklogTask_Action,
  SubmitBooklogAnswer,
  SubmitBooklogRange,
} from '@sparx/api/apis/sparx/reading/tasks/v1/booklog';
import { PaperbackReadingTask_Action } from '@sparx/api/apis/sparx/reading/tasks/v1/paperback';
import { TaskState } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { useTaskActions } from 'queries/tasks';

interface ICommonActions {
  retry: () => void;
  load: () => void;
  isLoading?: boolean;
}

export const useCommonActions = (taskID: string): ICommonActions => {
  const { mutate, isLoading } = useTaskActions(taskID);
  return {
    retry: () => !isLoading && mutate({ action: { oneofKind: 'retry', retry: true } }),
    load: () => !isLoading && mutate({ action: { oneofKind: 'load', load: true } }),
    isLoading,
  };
};

interface IPaperbackReadingActions extends ICommonActions {
  tooFastSkipAhead: () => void;
  tooFastReturnToBook: () => void;
  finishReading: () => void;
  finishWarning: () => void;
  answerQuestion: (answer: string, questionID?: string) => void;
  finishViewingResult: () => void;
}

export const usePaperbackReadingActions = (taskID: string): IPaperbackReadingActions => {
  const { mutate, isLoading } = useTaskActions(taskID);
  const paperbackAction = (action: PaperbackReadingTask_Action) =>
    !isLoading &&
    mutate({
      action: { oneofKind: 'paperback', paperback: action },
    });

  return {
    retry: () => !isLoading && mutate({ action: { oneofKind: 'retry', retry: true } }),
    load: () => !isLoading && mutate({ action: { oneofKind: 'load', load: true } }),
    tooFastSkipAhead: () =>
      paperbackAction({ action: { oneofKind: 'proceed', proceed: true }, identifier: '' }),
    tooFastReturnToBook: () =>
      paperbackAction({ action: { oneofKind: 'answer', answer: 'return' }, identifier: '' }),
    finishReading: () =>
      paperbackAction({ action: { oneofKind: 'proceed', proceed: true }, identifier: '' }),
    finishWarning: () =>
      paperbackAction({ action: { oneofKind: 'proceed', proceed: true }, identifier: '' }),
    answerQuestion: (answer: string, questionID?: string) =>
      paperbackAction({
        action: { oneofKind: 'answer', answer: answer },
        identifier: questionID || '',
      }),
    finishViewingResult: () =>
      paperbackAction({ action: { oneofKind: 'proceed', proceed: true }, identifier: '' }),
    isLoading,
  };
};

export interface IBooklogActions extends ICommonActions {
  sendRangeAction: (range: SubmitBooklogRange) => void;
  sendQuestionInput: (question: BooklogQuestion) => void;
  sendQuestionAnswer: (answer: SubmitBooklogAnswer) => void;
  sendBackAction: () => void;
  sendPauseAction: () => void;
  sendResumeAction: () => void;
}

export const useBooklogActions = (taskID: string): IBooklogActions => {
  const { mutate, isLoading } = useTaskActions(taskID);
  const booklogAction = (action: BooklogTask_Action) =>
    !isLoading && mutate({ action: { oneofKind: 'booklog', booklog: action } });

  return {
    retry: () => !isLoading && mutate({ action: { oneofKind: 'retry', retry: true } }),
    load: () => !isLoading && mutate({ action: { oneofKind: 'load', load: true } }),
    sendRangeAction: (range: SubmitBooklogRange) =>
      booklogAction({ action: { oneofKind: 'range', range } }),
    sendQuestionInput: (question: BooklogQuestion) =>
      booklogAction({ action: { oneofKind: 'question', question: { question } } }),
    sendQuestionAnswer: (answer: SubmitBooklogAnswer) =>
      booklogAction({ action: { oneofKind: 'answer', answer } }),
    sendBackAction: () => booklogAction({ action: { oneofKind: 'back', back: true } }),
    sendPauseAction: () => booklogAction({ action: { oneofKind: 'pause', pause: true } }),
    sendResumeAction: () => booklogAction({ action: { oneofKind: 'resume', resume: true } }),
    isLoading,
  };
};

interface IAssessmentActions extends ICommonActions {
  sendAnswer: (answer: string) => void;
  progress: () => void;
}

export const useAssessmentActions = (taskID: string): IAssessmentActions => {
  const { mutate, isLoading } = useTaskActions(taskID);
  const assessmentAction = (action: AssessmentTask_Action) =>
    !isLoading && mutate({ action: { oneofKind: 'assessment', assessment: action } });

  return {
    retry: () => !isLoading && mutate({ action: { oneofKind: 'retry', retry: true } }),
    load: () => !isLoading && mutate({ action: { oneofKind: 'load', load: true } }),
    sendAnswer: (id: string) =>
      assessmentAction({ action: { oneofKind: 'answer', answer: { id } } }),
    progress: () => assessmentAction({ action: { oneofKind: 'proceed', proceed: true } }),
    isLoading,
  };
};

export const bookIDForReadingTask = (taskState?: TaskState): string | undefined => {
  if (!taskState) {
    return undefined;
  } else if (taskState.state.oneofKind === 'paperback') {
    return taskState.state.paperback.book?.bookId;
  } else if (taskState.state.oneofKind === 'booklog') {
    return taskState.state.booklog.bookId;
  }
  return undefined;
};

export const getTaskProgress = (exp: number, target: number): number =>
  Math.min(
    1,
    // Floor * 100 then / 100 so that it doesn't round 199/200 to 100%
    Math.floor((exp / target) * 100) / 100,
  );
