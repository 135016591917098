// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/monitoring/v1/monitoring.proto" (package "sparx.reading.monitoring.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { TaskState } from '../../tasks/v1/tasks';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.reading.monitoring.v1.WatchStudentsRequest
 */
export interface WatchStudentsRequest {
  /**
   * @generated from protobuf field: repeated string student_group_id = 1;
   */
  studentGroupId: string[];
}
/**
 * @generated from protobuf message sparx.reading.monitoring.v1.WatchStudentsResponse
 */
export interface WatchStudentsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.monitoring.v1.StudentState states = 1;
   */
  states: StudentState[];
}
/**
 * @generated from protobuf message sparx.reading.monitoring.v1.StudentState
 */
export interface StudentState {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_active = 2;
   */
  lastActive?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_reading_check_passed = 3;
   */
  lastReadingCheckPassed?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_login = 8;
   */
  lastLogin?: Timestamp;
  /**
   * @generated from protobuf field: sparx.reading.monitoring.v1.StudentTaskState current_task = 6;
   */
  currentTask?: StudentTaskState;
  /**
   * @generated from protobuf field: repeated sparx.reading.monitoring.v1.ProgressBucket buckets = 5;
   */
  buckets: ProgressBucket[];
  /**
   * @generated from protobuf field: string latest_path = 7;
   */
  latestPath: string;
}
/**
 * @generated from protobuf message sparx.reading.monitoring.v1.StudentTaskState
 */
export interface StudentTaskState {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.TaskState state = 2;
   */
  state?: TaskState;
  /**
   * @generated from protobuf field: string package_id = 3;
   */
  packageId: string;
}
/**
 * @generated from protobuf message sparx.reading.monitoring.v1.ProgressBucket
 */
export interface ProgressBucket {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_time = 1;
   */
  startTime?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp;
  /**
   * @generated from protobuf field: int32 srp_awarded = 3;
   */
  srpAwarded: number;
  /**
   * @generated from protobuf field: int32 words_read = 4;
   */
  wordsRead: number;
  /**
   * @generated from protobuf field: int32 task_count = 5;
   */
  taskCount: number;
  /**
   * @generated from protobuf field: int32 correct_count = 6;
   */
  correctCount: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class WatchStudentsRequest$Type extends MessageType<WatchStudentsRequest> {
  constructor() {
    super('sparx.reading.monitoring.v1.WatchStudentsRequest', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.monitoring.v1.WatchStudentsRequest
 */
export const WatchStudentsRequest = new WatchStudentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WatchStudentsResponse$Type extends MessageType<WatchStudentsResponse> {
  constructor() {
    super('sparx.reading.monitoring.v1.WatchStudentsResponse', [
      {
        no: 1,
        name: 'states',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.monitoring.v1.WatchStudentsResponse
 */
export const WatchStudentsResponse = new WatchStudentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentState$Type extends MessageType<StudentState> {
  constructor() {
    super('sparx.reading.monitoring.v1.StudentState', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'last_active', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'last_reading_check_passed',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 8, name: 'last_login', kind: 'message', T: () => Timestamp },
      {
        no: 6,
        name: 'current_task',
        kind: 'message',
        T: () => StudentTaskState,
      },
      {
        no: 5,
        name: 'buckets',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ProgressBucket,
      },
      {
        no: 7,
        name: 'latest_path',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.monitoring.v1.StudentState
 */
export const StudentState = new StudentState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentTaskState$Type extends MessageType<StudentTaskState> {
  constructor() {
    super('sparx.reading.monitoring.v1.StudentTaskState', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'state', kind: 'message', T: () => TaskState },
      { no: 3, name: 'package_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.monitoring.v1.StudentTaskState
 */
export const StudentTaskState = new StudentTaskState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProgressBucket$Type extends MessageType<ProgressBucket> {
  constructor() {
    super('sparx.reading.monitoring.v1.ProgressBucket', [
      { no: 1, name: 'start_time', kind: 'message', T: () => Timestamp },
      { no: 2, name: 'end_time', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'srp_awarded', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'words_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'task_count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 6,
        name: 'correct_count',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.monitoring.v1.ProgressBucket
 */
export const ProgressBucket = new ProgressBucket$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.monitoring.v1.Monitoring
 */
export const Monitoring = new ServiceType(
  'sparx.reading.monitoring.v1.Monitoring',
  [
    {
      name: 'WatchStudents',
      options: {},
      I: WatchStudentsRequest,
      O: WatchStudentsResponse,
    },
  ],
);
