import { Container } from 'components/container/container';
import { Content } from 'components/content/content';
import { NotFoundErrorMessage } from 'components/errors/error-message';

export const NotFoundView = () => (
  <Container>
    <Content>
      <NotFoundErrorMessage />
    </Content>
  </Container>
);
