import { SentimentRating } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useUser } from 'queries/session';
import { useListStaff } from 'queries/staff';

const BOOK_A_CALL_MAX_SENTIMENT_RATING = SentimentRating.DISLIKE;
const BOOK_A_CALL_USER_ROLES = [
  StaffRole.SPARX_LEADER,
  StaffRole.HEAD_OF_DEPARTMENT,
  StaffRole.HEAD_OF_SCHOOL,
  StaffRole.SENIOR_LEADER,
  StaffRole.SENIOR_LEADER_OTHER,
  StaffRole.DEPUTY_HEAD_OF_DEPARTMENT,
  StaffRole.DEPARTMENT_COORDINATOR,
];

export const useShouldShowBookACall = (sentimentRating: SentimentRating) => {
  const user = useUser();
  const { data: staff } = useListStaff(user?.schoolId || '');

  if (sentimentRating > BOOK_A_CALL_MAX_SENTIMENT_RATING) return false;

  const staffUser = staff?.staffMembers.find(
    staff => staff.name.split('staff/')[1] === user?.sparxUserId,
  );

  if (!staffUser) return false;

  const roles = staffUser.roles.filter(
    role => role.product === Product.SPARX_READER || role.product === Product.PRODUCT_UNKNOWN,
  );

  return roles.some(role => BOOK_A_CALL_USER_ROLES.includes(role.role));
};
