import { StaffRoleAssignment } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { hasRole, roleAssignmentsEqual } from '@sparx/staff-manager';

export const toggleRole = (
  currentRoles: StaffRoleAssignment[],
  { role, product }: StaffRoleAssignment,
) => {
  const roleHeld = hasRole(currentRoles, role, product);

  const newRoles = roleHeld
    ? currentRoles.filter(r => !roleAssignmentsEqual(r, { role, product }))
    : [...currentRoles, { role, product }];

  return newRoles;
};
