// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/canny/v1/sso.proto" (package "sparx.canny.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.canny.v1.GetSingleSignOnTokenRequest
 */
export interface GetSingleSignOnTokenRequest {
  /**
   * The user's name as will appear in Canny
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The user's email address
   *
   * @generated from protobuf field: string email_address = 2;
   */
  emailAddress: string;
}
/**
 * @generated from protobuf message sparx.canny.v1.GetSingleSignOnTokenResponse
 */
export interface GetSingleSignOnTokenResponse {
  /**
   * The token to authenticate the current user with Canny
   *
   * @generated from protobuf field: string token = 1;
   */
  token: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class GetSingleSignOnTokenRequest$Type extends MessageType<GetSingleSignOnTokenRequest> {
  constructor() {
    super('sparx.canny.v1.GetSingleSignOnTokenRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.canny.v1.GetSingleSignOnTokenRequest
 */
export const GetSingleSignOnTokenRequest =
  new GetSingleSignOnTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSingleSignOnTokenResponse$Type extends MessageType<GetSingleSignOnTokenResponse> {
  constructor() {
    super('sparx.canny.v1.GetSingleSignOnTokenResponse', [
      { no: 1, name: 'token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.canny.v1.GetSingleSignOnTokenResponse
 */
export const GetSingleSignOnTokenResponse =
  new GetSingleSignOnTokenResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.canny.v1.CannySingleSignOn
 */
export const CannySingleSignOn = new ServiceType(
  'sparx.canny.v1.CannySingleSignOn',
  [
    {
      name: 'GetSingleSignOnToken',
      options: {
        'sparx.api.auth': {
          action: 'get',
          resource: 'authtoken',
          domain: 'canny',
        },
      },
      I: GetSingleSignOnTokenRequest,
      O: GetSingleSignOnTokenResponse,
    },
  ],
);
