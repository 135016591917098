import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';

import styles from './show-more.module.css';

// The number of characters beyond max length where it isn't
// worth showing the "show more" link so just show the full string.
// This avoids the case where clicking "show more" only shows a few
// extra characters.
const MAX_LENGTH_EXTRA = 30;

type Props = {
  content: string;
  maxLength: number;
  linkColour?: string;
  isHTML?: boolean;
};

export const ShowMoreLess = ({ content, maxLength, linkColour = '#ED706C', isHTML }: Props) => {
  const requireHide = useMemo(
    () => content.length > maxLength + MAX_LENGTH_EXTRA,
    [content, maxLength],
  );
  const [showingMore, setShowingMore] = useState(false);
  if (!requireHide) {
    return isHTML ? <span dangerouslySetInnerHTML={{ __html: content }} /> : content;
  }
  if (showingMore) {
    return (
      <>
        {' '}
        {isHTML ? <span dangerouslySetInnerHTML={{ __html: content }} /> : content}{' '}
        <span
          style={{ color: linkColour }}
          className={styles.ShowMoreLink}
          onClick={() => setShowingMore(false)}
        >
          <u>Show less</u>{' '}
          <FontAwesomeIcon icon={faChevronUp} className={styles.ShowMoreLinkChevron} />
        </span>
      </>
    );
  }

  const shortContent = content.substring(0, maxLength);

  return (
    <>
      {isHTML ? <span dangerouslySetInnerHTML={{ __html: shortContent }} /> : shortContent} {'... '}
      <span
        style={{ color: linkColour }}
        className={styles.ShowMoreLink}
        onClick={() => setShowingMore(true)}
      >
        <u>Show more</u>{' '}
        <FontAwesomeIcon icon={faChevronDown} className={styles.ShowMoreLinkChevron} />
      </span>
    </>
  );
};
