import { LeaderboardDateRange } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { Select } from '@sparx/design/components';
import { useAlert } from 'components/alert/alert';
import { Button } from 'components/buttons/button';
import { ErrorMessage } from 'components/errors/error-message';
import { Link } from 'components/link';
import { Loading } from 'components/loading/loading';
import { Info } from 'components/panel/info';
import { Tabs } from 'components/tabs/tabs';
import { Title } from 'components/title/title';
import {
  useGetUserDisplayDataForCurrentUser,
  useGetUserOptedInToLeagueTable,
} from 'queries/leagueTables';
import { useEnglishStudentGroups } from 'queries/management';
import { useUser } from 'queries/session';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FCWithChildren } from 'utils/props';
import { useIsStudentDemoMode } from 'utils/student-demo';
import { View } from 'views';
import { NotFoundView } from 'views/common/not-found-view/not-found-view';
import styles from 'views/student/league-view/league-view.module.css';
import { NameChooser } from 'views/student/league-view/name-chooser';
import { pathForView } from 'views/views';

import { LibraryContent, LibraryTitle } from '../library-view/components/library-content';
import { LeagueViewIntro } from './components/league-view-intro';
import { useLeaderboards } from './leaderboards/leaderboards';
import { LeaveLeagueTables } from './leave-league-table';
import { createDisplayName } from './utils';

export const LeagueView = () => {
  const user = useUser();
  const setAlert = useAlert();
  const navigate = useNavigate();
  const { tab } = useParams();
  const leagueTables = useLeaderboards();
  const isTeacher = user?.type === UserType.TEACHER;
  const { data: groups, fetchStatus } = useEnglishStudentGroups();

  const currentTab = leagueTables.find(leagueTable => leagueTable.id === tab) || leagueTables[0];
  const currentTabIndex = leagueTables.indexOf(currentTab);

  // dateRange is the time period to view the leaderboard for, e.g. this week, this month.
  const [dateRange, setDateRange] = useState(LeaderboardDateRange.THIS_WEEK);
  const [yearGroupId, setYearGroupId] = useState('');

  const userDisplayData = useGetUserDisplayDataForCurrentUser();
  const optedIn = useGetUserOptedInToLeagueTable();
  const leagueName = useMemo(
    () => createDisplayName(user, userDisplayData.data?.positiveNoun),
    [userDisplayData?.data?.positiveNoun, user],
  );

  const isStudentDemoMode = useIsStudentDemoMode();

  const Wrapper: FCWithChildren = ({ children }) => (
    <LibraryContent>
      <Title>Leaderboard</Title>
      <LibraryTitle>Leaderboard</LibraryTitle>
      {children}
    </LibraryContent>
  );

  if (isStudentDemoMode) {
    return (
      <Wrapper>
        <div style={{ textAlign: 'center' }}>
          <p>
            We&apos;re busy working on some improvements to the student leaderboard, so for now
            it&apos;s unavailable in the student demo mode.
          </p>
          <p>We&apos;ll be bringing this back soon!</p>
          <Link
            to={pathForView(View.TeacherLeaderboard)}
            analyticsEvent={{
              category: 'student-leaderboard',
              action: 'Clicked go to teacher leaderboard',
            }}
          >
            Click here
          </Link>{' '}
          to visit the teacher leaderboard.
        </div>
      </Wrapper>
    );
  }

  if (
    !user ||
    userDisplayData.isLoading ||
    optedIn.isLoading ||
    (isTeacher && fetchStatus === 'fetching')
  ) {
    return (
      <Wrapper>
        <div style={{ textAlign: 'center' }}>
          <Loading text="Loading..." />
        </div>
      </Wrapper>
    );
  }

  if (userDisplayData.isError || optedIn.isError) {
    return (
      <Wrapper>
        <div style={{ textAlign: 'center' }}>
          <ErrorMessage message="Error loading data" />
        </div>
      </Wrapper>
    );
  }

  if (isTeacher && (!groups || groups.length === 0)) {
    return (
      <Wrapper>
        <Info showIcon>Import your classes to see the leaderboard</Info>
      </Wrapper>
    );
  }

  if (!currentTab) {
    return <NotFoundView />;
  }

  const openNameModal = () => setAlert(<NameChooser />);
  if (!isTeacher && !optedIn.data) {
    // If you are not opted in, all you can do is set your name.
    return (
      <Wrapper>
        <LeagueViewIntro onClick={openNameModal} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {!isTeacher && (
        <div className={styles.NameChooser}>
          <div className={styles.NameChooserName}>
            <strong>Your name:</strong>
            <span className={styles.NameChooserName}>{leagueName}</span>
          </div>
          <Button
            onClick={openNameModal}
            variant="secondary"
            size="small"
            analyticsEvent={undefined}
            className={styles.NameChooserButton}
          >
            Change name
          </Button>
          <LeaveLeagueTables />
        </div>
      )}
      <Tabs
        className={styles.LibraryTabs}
        tabs={leagueTables.map(leagueTable => ({ name: leagueTable.name }))}
        currentTab={currentTabIndex}
        setCurrentTab={index =>
          isTeacher
            ? navigate(`/teacher/leaderboard/${leagueTables[index].id}`)
            : navigate(`/library/league/${leagueTables[index].id}`)
        }
        right={
          <div className={styles.TimeSelectField}>
            <label className={styles.Label} htmlFor="dateRange">
              Show:
            </label>
            <Select
              label="date range"
              value={dateRange.toString()}
              triggerClassName={styles.TimeSelect}
              portalClassName={styles.TimeSelectPortal}
              name={'dateRange'}
              onChange={value => {
                setDateRange(parseInt(value) as LeaderboardDateRange);
              }}
              groups={[
                {
                  items: [
                    {
                      value: LeaderboardDateRange.THIS_WEEK.toString(),
                      text: 'This week',
                    },
                    {
                      value: LeaderboardDateRange.LAST_WEEK.toString(),
                      text: 'Last week',
                    },
                    {
                      value: LeaderboardDateRange.THIS_MONTH.toString(),
                      text: 'This month',
                    },
                    {
                      value: LeaderboardDateRange.LAST_MONTH.toString(),
                      text: 'Last month',
                    },
                    {
                      value: LeaderboardDateRange.THIS_YEAR.toString(),
                      text: 'This year',
                    },
                  ],
                },
              ]}
            />
          </div>
        }
      />
      <div className={styles.LeaderboardContainer}>
        <currentTab.component
          dateRange={dateRange}
          yearGroupId={yearGroupId}
          setYearGroupId={setYearGroupId}
        />
      </div>
    </Wrapper>
  );
};

export const Component = LeagueView;
