import { BooklogReference } from '@sparx/api/apis/sparx/reading/tasks/v1/booklog';
import { useState } from 'react';
import { FCWithOptionalChildren } from 'utils/props';

import { BooklogInput } from './booklog-input';
import { validThreeWords } from './utils';

interface IBooklogRangeInputProps {
  side: 'start' | 'finish';
  onChange: (val: BooklogReference) => void;
  defaultValue?: BooklogReference;
  hideLastThreeWords?: boolean;
}

export const BooklogRangeInput: FCWithOptionalChildren<IBooklogRangeInputProps> = ({
  children,
  side,
  onChange,
  defaultValue,
  hideLastThreeWords,
}) => {
  const [req, setRequest] = useState<BooklogReference>(
    defaultValue || {
      page: 0,
      chapter: '',
      firstWord: '',
      lastWord: '',
    },
  );
  const patch = (patch: Partial<BooklogReference>) => {
    const newValue = { ...req, ...patch };
    setRequest(newValue);
    onChange(newValue);
  };

  return (
    <div>
      <BooklogInput
        label={side === 'start' ? "I'm starting on page number:" : 'What page are you on now?'}
        value={req.page ? req.page.toString() : ''}
        setValue={value => patch({ page: parseInt(value) || 0 })}
      />
      {children}
      {!hideLastThreeWords && (
        <BooklogInput
          label={
            <>
              The last <strong>three</strong> words on this page are:
            </>
          }
          value={req.lastWord}
          setValue={val => patch({ lastWord: val })}
          invalid={!!req.lastWord.trim() && !validThreeWords(req.lastWord)}
        />
      )}
    </div>
  );
};
