import { HomeBox } from 'components/home-box/home-box';

import lightbulb from '../img/tips-lightbulb.png';
import styles from './gold-reader-tips.module.css';

export const GoldReaderTips = () => {
  return (
    <HomeBox variant="normal" lgw={100}>
      <div className={styles.Main}>
        <div>
          <h2 className={styles.Title}>Gold Reader Tips</h2>
          <strong>Follow these tips to stay on track:</strong>
          {/* The tips are also stored on the server for the gold reading warning.
               If you change them here you probably also want to change them on the server.
            */}
          <ul className={styles.Tips}>
            <li className={styles.Tip}>Enter page numbers and last 3 words carefully</li>
            <li className={styles.Tip}>
              Write sensible questions based on the section you&apos;ve just read
            </li>
            <li className={styles.Tip}>Use the timer to log your reading</li>
            <li className={styles.Tip}>Maximum reading between logs should be about 20 minutes</li>
            <li className={styles.Tip}>You should not have read the book before</li>
          </ul>
        </div>
        <div className={styles.Image}>
          <img src={lightbulb} alt="" />
        </div>
      </div>
    </HomeBox>
  );
};
