// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/tasks/v1/tasks.proto" (package "sparx.reading.tasks.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Tasks } from './tasks';
import type { GetBookTaskResponse } from './tasks';
import type { GetBookTaskRequest } from './tasks';
import type { SendTaskActionResponse } from './tasks';
import type { SendTaskActionRequest } from './tasks';
import type { GetHomeworksResponse } from './tasks';
import type { GetHomeworksRequest } from './tasks';
import type { ListTasksResponse } from './tasks';
import type { ListTasksRequest } from './tasks';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetTaskResponse } from './tasks';
import type { GetTaskRequest } from './tasks';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.tasks.v1.Tasks
 */
export interface ITasksClient {
  /**
   * GetTask loads a single task.
   *
   * @generated from protobuf rpc: GetTask(sparx.reading.tasks.v1.GetTaskRequest) returns (sparx.reading.tasks.v1.GetTaskResponse);
   */
  getTask(
    input: GetTaskRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTaskRequest, GetTaskResponse>;
  /**
   * ListTasksRequest loads a list of tasks.
   *
   * @generated from protobuf rpc: ListTasks(sparx.reading.tasks.v1.ListTasksRequest) returns (sparx.reading.tasks.v1.ListTasksResponse);
   */
  listTasks(
    input: ListTasksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListTasksRequest, ListTasksResponse>;
  /**
   * GetHomeworks queries for homeworks for a student
   *
   * @generated from protobuf rpc: GetHomeworks(sparx.reading.tasks.v1.GetHomeworksRequest) returns (sparx.reading.tasks.v1.GetHomeworksResponse);
   */
  getHomeworks(
    input: GetHomeworksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetHomeworksRequest, GetHomeworksResponse>;
  /**
   * SendTaskAction sends a task action for a task.
   *
   * @generated from protobuf rpc: SendTaskAction(sparx.reading.tasks.v1.SendTaskActionRequest) returns (sparx.reading.tasks.v1.SendTaskActionResponse);
   */
  sendTaskAction(
    input: SendTaskActionRequest,
    options?: RpcOptions,
  ): UnaryCall<SendTaskActionRequest, SendTaskActionResponse>;
  /**
   * @generated from protobuf rpc: GetBookTask(sparx.reading.tasks.v1.GetBookTaskRequest) returns (sparx.reading.tasks.v1.GetBookTaskResponse);
   */
  getBookTask(
    input: GetBookTaskRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBookTaskRequest, GetBookTaskResponse>;
}
/**
 * @generated from protobuf service sparx.reading.tasks.v1.Tasks
 */
export class TasksClient implements ITasksClient, ServiceInfo {
  typeName = Tasks.typeName;
  methods = Tasks.methods;
  options = Tasks.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetTask loads a single task.
   *
   * @generated from protobuf rpc: GetTask(sparx.reading.tasks.v1.GetTaskRequest) returns (sparx.reading.tasks.v1.GetTaskResponse);
   */
  getTask(
    input: GetTaskRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTaskRequest, GetTaskResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetTaskRequest, GetTaskResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * ListTasksRequest loads a list of tasks.
   *
   * @generated from protobuf rpc: ListTasks(sparx.reading.tasks.v1.ListTasksRequest) returns (sparx.reading.tasks.v1.ListTasksResponse);
   */
  listTasks(
    input: ListTasksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListTasksRequest, ListTasksResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListTasksRequest, ListTasksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetHomeworks queries for homeworks for a student
   *
   * @generated from protobuf rpc: GetHomeworks(sparx.reading.tasks.v1.GetHomeworksRequest) returns (sparx.reading.tasks.v1.GetHomeworksResponse);
   */
  getHomeworks(
    input: GetHomeworksRequest,
    options?: RpcOptions,
  ): UnaryCall<GetHomeworksRequest, GetHomeworksResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetHomeworksRequest, GetHomeworksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * SendTaskAction sends a task action for a task.
   *
   * @generated from protobuf rpc: SendTaskAction(sparx.reading.tasks.v1.SendTaskActionRequest) returns (sparx.reading.tasks.v1.SendTaskActionResponse);
   */
  sendTaskAction(
    input: SendTaskActionRequest,
    options?: RpcOptions,
  ): UnaryCall<SendTaskActionRequest, SendTaskActionResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SendTaskActionRequest, SendTaskActionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetBookTask(sparx.reading.tasks.v1.GetBookTaskRequest) returns (sparx.reading.tasks.v1.GetBookTaskResponse);
   */
  getBookTask(
    input: GetBookTaskRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBookTaskRequest, GetBookTaskResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetBookTaskRequest, GetBookTaskResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
