import { useQuery } from '@tanstack/react-query';
import { sessionsClient } from 'api';

export const useAdminUserActive = (path: string) => {
  return useQuery(
    ['admin-user', 'active'],
    () =>
      sessionsClient.adminUserActive({
        currentPath: path,
      }).response,
    {
      staleTime: Infinity,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );
};
