import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import classNames from 'classnames';
import { ShowMoreLess } from 'components/show-more/show-more';
import React from 'react';
import { timestampToMoment } from 'utils/time';

import styles from './booklog-task-view.module.css';

const maxContentLength = 150;

export const BooklogTaskQuestions = ({ task }: { task: Task }) => {
  if (task.state?.state.oneofKind !== 'booklog') {
    return null;
  }
  return (
    <div className={styles.Log}>
      <div className={classNames(styles.LogLabel, styles.LogPageStart, styles.LogNoWrap)}>
        <span className={styles.LogPageStartLabel}>
          Page {task.state.state.booklog.start?.page}
        </span>
        <FontAwesomeIcon icon={faArrowRight} className={styles.LogPageCountArrow} />
      </div>
      <div className={classNames(styles.LogLabel, styles.LogPageEnd, styles.LogNoWrap)}>
        Page {task.state.state.booklog.end?.page}
      </div>
      <div className={classNames(styles.LogTime, styles.LogNoWrap)}>
        {task.state.state.booklog.timeSpentMinutes} min
        {task.state.state.booklog.timeSpentMinutes === 1 ? '' : 's'}
      </div>
      <div className={classNames(styles.LogNoWrap, styles.LogPageCountDate)}>
        {task.state.completionDate && timestampToMoment(task.state.completionDate).format('LLL')}
      </div>
      <>
        {task.state.state.booklog.questions.map((question, ix) => {
          return (
            <React.Fragment key={ix}>
              <div className={styles.LogQuestionLabel}>Question:</div>
              <div className={styles.LogQuestionValue}>
                <ShowMoreLess content={question.questionText} maxLength={maxContentLength} />
              </div>
              <div className={styles.LogQuestionLabel}>Answer:</div>
              <div className={styles.LogQuestionValue}>
                <ShowMoreLess content={question.answerText} maxLength={maxContentLength} />
              </div>
            </React.Fragment>
          );
        })}
      </>
    </div>
  );
};
