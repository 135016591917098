import {
  faCaretDown,
  faCaretUp,
  faCertificate,
  faQuestionCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import {
  NotificationBankedReward,
  NotificationWithContext,
} from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import classNames from 'classnames';
import { useAlert, useClearAlert } from 'components/alert/alert';
import bank from 'components/alert/images/bank.svg';
import { Button } from 'components/buttons/button';
import styles from 'components/tasks/panel.module.css';
import { LargeSRPReward } from 'components/tasks/task-complete-modal';
import { useStudentBooksMap } from 'queries/library-books';
import { useBookNotificationRead } from 'queries/notifications';
import { useUser } from 'queries/session';
import { useMemo, useState } from 'react';
import Confetti from 'react-dom-confetti';

export const BankingAlert = () => {
  const close = useClearAlert();

  const { data: books } = useStudentBooksMap();
  const totalBanked = useMemo(() => {
    let total = 0;
    for (const bookAttempts of Object.values(books || {})) {
      for (const book of bookAttempts) {
        total += book.studentBook?.bankedExperience || 0;
      }
    }
    return total;
  }, [books]);

  return (
    <div className={classNames(styles.OnboardingModal, styles.GoldReader)}>
      <div className={styles.ExitButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className={styles.GoldReaderContent}>
        <h2 className={`${styles.ModalTitle} enter-down-0`}>Banking SRP</h2>
        <div className={`${styles.BankedCount} enter-down-1`}>
          You currently have{' '}
          <span className="info-text--success">+{totalBanked.toLocaleString()} SRP</span> in your
          Points Bank.
        </div>
        <p className="enter-down-2">
          <strong>Reading more than your current task will add SRP to your bank.</strong>
        </p>
        <p className="enter-down-2">
          You&apos;ll get your banked SRP once you&apos;ve finished the book you are reading.
        </p>
        <p className="enter-down-2">
          To get the full amount of SRP you have banked, you need to have read the book carefully
          and logged your reading regularly.
        </p>
        <img src={bank} className={'enter-down-3 ' + styles.BankImage} alt="" />
        <div className={`${styles.Buttons} enter-down-3`}>
          <Button onClick={close} analyticsEvent={undefined}>
            Got it!
          </Button>
        </div>
      </div>
    </div>
  );
};

export const BankLink = () => {
  const setAlert = useAlert();
  const show = () => setAlert(<BankingAlert />);
  return (
    <span onClick={show} className={styles.PointsBankLink}>
      Points Bank
      <FontAwesomeIcon icon={faQuestionCircle} />
    </span>
  );
};

const confettiConfig = {
  angle: 0,
  spread: 360,
  startVelocity: 30,
  elementCount: 50,
  dragFriction: 0.15,
  duration: 2000,
  stagger: 0,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#A4C451', '#3D5A80', '#EE6C4D'],
  // colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

interface IBankRewardAlertProps {
  notification: NotificationWithContext;
  reward: NotificationBankedReward;
  book: MetadataAbridged | undefined;
}

export const BankRewardAlert = ({ notification, reward, book }: IBankRewardAlertProps) => {
  const close = useClearAlert();
  const user = useUser();

  const [bankOpen, setBankOpen] = useState(notification.dismissed);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);

  const readNotification = useBookNotificationRead();
  const markNotificationAsSeen = () => readNotification(notification.notificationId);

  const SRP_THRESHOLD = 0.98;
  // Catch Banked SRP === 0 for older notifications
  const wasSRPThresholdMet =
    reward.bankedSrp === 0 ? true : reward.rewardedSrp / reward.bankedSrp >= SRP_THRESHOLD;

  return (
    <div className={classNames(styles.OnboardingModal, styles.BankReward)}>
      <div className={styles.ExitButton} onClick={close}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className={styles.GoldReaderContent}>
        <h2 className={`${styles.ModalTitle} enter-down-0`}>You&apos;ve been awarded SRP!</h2>
        <p className="enter-down-1" style={{ margin: 0 }}>
          Well done for completing <strong>{book?.title}</strong>.
          {!notification.dismissed && ' Click the bank below to collect your SRP.'}
        </p>
        <div className="enter-down-2">
          <div
            className={classNames(styles.BankRevealButton, bankOpen && styles.BankRevealOpen)}
            onClick={() => setBankOpen(true)}
          >
            <div className={styles.BankRevealConfetti}>
              <Confetti active={hasAnimated && wasSRPThresholdMet} config={confettiConfig} />
            </div>
            {bankOpen && (
              <div className={styles.BankRevealSRPContainer}>
                <LargeSRPReward
                  experience={reward.rewardedSrp}
                  className={styles.BankRevealSRP}
                  onStart={() => setHasAnimated(false)}
                  onEnd={() => {
                    markNotificationAsSeen();
                    setHasAnimated(true);
                  }}
                />

                {wasSRPThresholdMet ? (
                  <p style={{ margin: 0 }}>
                    <strong>Well done, {user?.firstName}!</strong> You earned maximum SRP by keeping
                    careful reading logs. Keep it up!
                  </p>
                ) : (
                  <div className="enter-down-3">
                    <div className={styles.SRPError}>
                      <p>
                        You claimed <strong>{reward.bankedSrp}SRP</strong> for this book, but
                        you&apos;ve only been awarded <strong>{reward.rewardedSrp}SRP</strong>.
                      </p>

                      <p>
                        To receive all of your banked SRP, make sure you keep careful reading logs.
                      </p>

                      {isListOpen && (
                        <ul style={{ textAlign: 'left' }}>
                          <li>Enter page numbers and last 3 words carefully</li>
                          <li>
                            Write sensible questions based on the section you&apos;ve just read
                          </li>
                          <li>Use the timer to log your reading</li>
                          <li>Maximum reading between logs should be about 20 minutes</li>
                          <li>You should not have read the book before</li>
                        </ul>
                      )}

                      <a
                        href="#"
                        className={styles.SRPErrorLink}
                        onClick={e => {
                          e.preventDefault();
                          setIsListOpen(!isListOpen);
                        }}
                      >
                        {isListOpen ? (
                          <>
                            See less <FontAwesomeIcon icon={faCaretUp} />
                          </>
                        ) : (
                          <>
                            See more <FontAwesomeIcon icon={faCaretDown} />
                          </>
                        )}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={styles.BankRevealBackground}>
              <FontAwesomeIcon icon={faCertificate} />
            </div>
            <img src={bank} className={styles.BankReveal} alt="Bank" />
          </div>
        </div>
        <div className={`${styles.Buttons} enter-down-3`} style={{ marginBottom: '15px' }}>
          {bankOpen ? (
            <Button onClick={close} disabled={!hasAnimated} analyticsEvent={undefined}>
              {wasSRPThresholdMet ? 'Continue' : 'Got it!'}
            </Button>
          ) : (
            <Button
              onClick={() => setBankOpen(true)}
              disabled={bankOpen}
              analyticsEvent={undefined}
            >
              Collect SRP
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
