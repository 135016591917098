// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/bonusquestions/v1/bonusquestions.proto" (package "sparx.reading.bonusquestions.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { ExperienceUpdate } from '../../users/v1/experience';
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.BonusQuestion
 */
export interface BonusQuestion {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: repeated sparx.reading.bonusquestions.v1.BonusQuestionStep steps = 2;
   */
  steps: BonusQuestionStep[];
}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.BonusQuestionStep
 */
export interface BonusQuestionStep {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf oneof: content
   */
  content:
    | {
        oneofKind: 'question';
        /**
         * @generated from protobuf field: sparx.reading.bonusquestions.v1.BonusQuestionStep.Question question = 2;
         */
        question: BonusQuestionStep_Question;
      }
    | {
        oneofKind: 'text';
        /**
         * @generated from protobuf field: sparx.reading.bonusquestions.v1.BonusQuestionStep.Text text = 3;
         */
        text: BonusQuestionStep_Text;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.BonusQuestionStep.Question
 */
export interface BonusQuestionStep_Question {
  /**
   * @generated from protobuf field: string text = 1;
   */
  text: string;
  /**
   * @generated from protobuf field: repeated string options = 2;
   */
  options: string[];
}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.BonusQuestionStep.Text
 */
export interface BonusQuestionStep_Text {
  /**
   * @generated from protobuf field: string text = 1;
   */
  text: string;
}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.StudentBonusQuestion
 */
export interface StudentBonusQuestion {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: sparx.reading.bonusquestions.v1.BonusQuestion question = 2;
   */
  question?: BonusQuestion;
}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.GetBonusQuestionsRequest
 */
export interface GetBonusQuestionsRequest {}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.GetBonusQuestionsResponse
 */
export interface GetBonusQuestionsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bonusquestions.v1.StudentBonusQuestion questions = 1;
   */
  questions: StudentBonusQuestion[];
}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.SubmitBonusQuestionRequest
 */
export interface SubmitBonusQuestionRequest {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: repeated string answer = 2;
   */
  answer: string[];
}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.SubmitBonusQuestionResponse
 */
export interface SubmitBonusQuestionResponse {
  /**
   * New list of bonus questions to display.
   *
   * @generated from protobuf field: repeated sparx.reading.bonusquestions.v1.StudentBonusQuestion questions = 1;
   */
  questions: StudentBonusQuestion[];
  /**
   * New experience update to apply.
   *
   * @generated from protobuf field: sparx.reading.users.v1.ExperienceUpdate experience_update = 2;
   */
  experienceUpdate?: ExperienceUpdate;
}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventRequest
 */
export interface SubmitBonusQuestionEventRequest {
  /**
   * @generated from protobuf field: string student_question_id = 1;
   */
  studentQuestionId: string;
  /**
   * @generated from protobuf field: string question_id = 2;
   */
  questionId: string;
  /**
   * @generated from protobuf field: string step_id = 3;
   */
  stepId: string;
  /**
   * @generated from protobuf field: string event = 4;
   */
  event: string;
}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventResponse
 */
export interface SubmitBonusQuestionEventResponse {}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.DismissBonusQuestionsRequest
 */
export interface DismissBonusQuestionsRequest {}
/**
 * @generated from protobuf message sparx.reading.bonusquestions.v1.DismissBonusQuestionsResponse
 */
export interface DismissBonusQuestionsResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class BonusQuestion$Type extends MessageType<BonusQuestion> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.BonusQuestion', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'steps',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BonusQuestionStep,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.BonusQuestion
 */
export const BonusQuestion = new BonusQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusQuestionStep$Type extends MessageType<BonusQuestionStep> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.BonusQuestionStep', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'question',
        kind: 'message',
        oneof: 'content',
        T: () => BonusQuestionStep_Question,
      },
      {
        no: 3,
        name: 'text',
        kind: 'message',
        oneof: 'content',
        T: () => BonusQuestionStep_Text,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.BonusQuestionStep
 */
export const BonusQuestionStep = new BonusQuestionStep$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusQuestionStep_Question$Type extends MessageType<BonusQuestionStep_Question> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.BonusQuestionStep.Question', [
      { no: 1, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'options',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.BonusQuestionStep.Question
 */
export const BonusQuestionStep_Question = new BonusQuestionStep_Question$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusQuestionStep_Text$Type extends MessageType<BonusQuestionStep_Text> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.BonusQuestionStep.Text', [
      { no: 1, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.BonusQuestionStep.Text
 */
export const BonusQuestionStep_Text = new BonusQuestionStep_Text$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentBonusQuestion$Type extends MessageType<StudentBonusQuestion> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.StudentBonusQuestion', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'question', kind: 'message', T: () => BonusQuestion },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.StudentBonusQuestion
 */
export const StudentBonusQuestion = new StudentBonusQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBonusQuestionsRequest$Type extends MessageType<GetBonusQuestionsRequest> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.GetBonusQuestionsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.GetBonusQuestionsRequest
 */
export const GetBonusQuestionsRequest = new GetBonusQuestionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBonusQuestionsResponse$Type extends MessageType<GetBonusQuestionsResponse> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.GetBonusQuestionsResponse', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentBonusQuestion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.GetBonusQuestionsResponse
 */
export const GetBonusQuestionsResponse = new GetBonusQuestionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitBonusQuestionRequest$Type extends MessageType<SubmitBonusQuestionRequest> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.SubmitBonusQuestionRequest', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'answer',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.SubmitBonusQuestionRequest
 */
export const SubmitBonusQuestionRequest = new SubmitBonusQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitBonusQuestionResponse$Type extends MessageType<SubmitBonusQuestionResponse> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.SubmitBonusQuestionResponse', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentBonusQuestion,
      },
      {
        no: 2,
        name: 'experience_update',
        kind: 'message',
        T: () => ExperienceUpdate,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.SubmitBonusQuestionResponse
 */
export const SubmitBonusQuestionResponse =
  new SubmitBonusQuestionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitBonusQuestionEventRequest$Type extends MessageType<SubmitBonusQuestionEventRequest> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventRequest', [
      {
        no: 1,
        name: 'student_question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'step_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'event', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventRequest
 */
export const SubmitBonusQuestionEventRequest =
  new SubmitBonusQuestionEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitBonusQuestionEventResponse$Type extends MessageType<SubmitBonusQuestionEventResponse> {
  constructor() {
    super(
      'sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventResponse',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventResponse
 */
export const SubmitBonusQuestionEventResponse =
  new SubmitBonusQuestionEventResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DismissBonusQuestionsRequest$Type extends MessageType<DismissBonusQuestionsRequest> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.DismissBonusQuestionsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.DismissBonusQuestionsRequest
 */
export const DismissBonusQuestionsRequest =
  new DismissBonusQuestionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DismissBonusQuestionsResponse$Type extends MessageType<DismissBonusQuestionsResponse> {
  constructor() {
    super('sparx.reading.bonusquestions.v1.DismissBonusQuestionsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bonusquestions.v1.DismissBonusQuestionsResponse
 */
export const DismissBonusQuestionsResponse =
  new DismissBonusQuestionsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.bonusquestions.v1.BonusQuestions
 */
export const BonusQuestions = new ServiceType(
  'sparx.reading.bonusquestions.v1.BonusQuestions',
  [
    {
      name: 'GetBonusQuestions',
      options: {},
      I: GetBonusQuestionsRequest,
      O: GetBonusQuestionsResponse,
    },
    {
      name: 'SubmitBonusQuestion',
      options: {},
      I: SubmitBonusQuestionRequest,
      O: SubmitBonusQuestionResponse,
    },
    {
      name: 'SubmitBonusQuestionEvent',
      options: {},
      I: SubmitBonusQuestionEventRequest,
      O: SubmitBonusQuestionEventResponse,
    },
    {
      name: 'DismissBonusQuestions',
      options: {},
      I: DismissBonusQuestionsRequest,
      O: DismissBonusQuestionsResponse,
    },
  ],
);
