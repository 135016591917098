import { faExclamationTriangle, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from 'components/sections/status-notification.module.css';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useStatusNotification } from 'utils/featureflags';

export const StatusNotification = () => {
  const status = useStatusNotification();
  const [dismissed, setDismissed] = useState(false);
  if (!status || dismissed) {
    return null;
  }

  return (
    <div className={styles.StatusNotification}>
      <div className={styles.StatusNotificationContent}>
        {status.type === 'warning' && (
          <FontAwesomeIcon icon={faExclamationTriangle} className={styles.StatusAlert} />
        )}
        {status.type === 'info' && (
          <FontAwesomeIcon icon={faInfoCircle} className={styles.StatusInfo} />
        )}
        <ReactMarkdown>{status.message}</ReactMarkdown>
        <button onClick={() => setDismissed(true)} className={styles.Dismiss}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};
