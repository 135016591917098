import { ReadingGuide } from '@sparx/api/apis/sparx/reading/users/v1/settings';
import classNames from 'classnames';
import { useUserSettings } from 'queries/session';
import { CSSProperties, useEffect, useState } from 'react';

import styles from './cursor-ruler.module.css';

export const CursorRuler = () => {
  const { data: settings } = useUserSettings();

  // By default set the guide to 1/3 of the screen height, or 1/2 on screens
  // shorter than 800px. This height is used on touch devices where the guide
  // position cannot be changed.
  const getDefaultPosition = () =>
    document.body.offsetHeight >= 800
      ? document.body.offsetHeight / 3
      : document.body.offsetHeight / 2;

  // The vertical position of the guide on the screen.
  const [position, setPosition] = useState(getDefaultPosition);
  useEffect(() => {
    if (settings?.guide !== ReadingGuide.UNSPECIFIED) {
      // Callback to set the position to the mouse position if available.
      // Using 'pointermove' rather than 'mousemove' allows for checking
      // whether the pointer is a mouse so we don't move the guide when the
      // screen is tapped on a touch device.
      const mouseCallback = (e: PointerEvent) =>
        e.pointerType === 'mouse' ? setPosition(e.y) : undefined;

      // Update the guide position when the screen is resized. This prevents
      // the guide from moving off screen if the window starts large and is
      // made smaller.
      const resizeCallback = () => setPosition(getDefaultPosition());

      document.addEventListener('pointermove', mouseCallback);
      window.addEventListener('resize', resizeCallback);
      return () => {
        document.removeEventListener('pointermove', mouseCallback);
        window.removeEventListener('resize', resizeCallback);
      };
    }
  }, [setPosition, settings?.guide]);

  if (!settings?.guide) {
    return null;
  }

  const positioning: CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    top: position,
    left: 0,
    zIndex: 2,
  };

  if (settings.guide === ReadingGuide.LINE) {
    return <div className={classNames(styles.Line)} style={positioning} />;
  } else if (settings.guide === ReadingGuide.MASK) {
    return (
      <div className={classNames(styles.MaskLine)} style={positioning}>
        <div className={styles.MaskBefore} />
        <div className={styles.MaskAfter} />
      </div>
    );
  }
  return null;
};
