// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/content/v1/books.proto" (package "sparx.reading.content.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.reading.content.v1.Book
 */
export interface Book {
  /**
   * @deprecated
   * @generated from protobuf field: sparx.reading.content.v1.BookMeta meta = 1 [deprecated = true];
   */
  meta?: BookMeta;
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.Page pages = 6;
   */
  pages: Page[];
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.InputQuestion input_questions = 7;
   */
  inputQuestions: InputQuestion[];
}
/**
 * Deprecated
 *
 * @generated from protobuf message sparx.reading.content.v1.BookMeta
 */
export interface BookMeta {
  /**
   * ID of the book that this edition is for
   *
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.BookRange
 */
export interface BookRange {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @generated from protobuf field: string start_part_id = 3;
   */
  startPartId: string;
  /**
   * @generated from protobuf field: string end_part_id = 4;
   */
  endPartId: string;
  /**
   * @generated from protobuf field: int32 start_page_index = 5;
   */
  startPageIndex: number; // inclusive
  /**
   * @generated from protobuf field: int32 end_page_index = 6;
   */
  endPageIndex: number; // inclusive
  /**
   * When set, there will be a related audio clip for this book range.
   *
   * @generated from protobuf field: string audio_clip_filename = 7;
   */
  audioClipFilename: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.Page
 */
export interface Page {
  /**
   * @generated from protobuf field: int32 index = 1;
   */
  index: number;
  /**
   * Such as 1, 2, 3 (should probably be unique)
   *
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * List of InputQuestion ID's that can be issued for this page
   *
   * @generated from protobuf field: repeated string questions = 3;
   */
  questions: string[];
  /**
   * If the page is a breakpoint for a task
   *
   * @generated from protobuf field: bool breakpoint = 4;
   */
  breakpoint: boolean;
  /**
   * Name of the last reading section if it is a breakpoint
   *
   * @generated from protobuf field: string breakpoint_section_name = 5;
   */
  breakpointSectionName: string;
  /**
   * @generated from protobuf field: string part_index = 6;
   */
  partIndex: string;
  /**
   * @generated from protobuf field: string audio_clip_filename = 7;
   */
  audioClipFilename: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.InputQuestion
 */
export interface InputQuestion {
  /**
   * @generated from protobuf field: string question_id = 1;
   */
  questionId: string;
  /**
   * @generated from protobuf field: string question_text = 2;
   */
  questionText: string;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.InputQuestion.Type type = 3;
   */
  type: InputQuestion_Type;
  /**
   * @generated from protobuf field: repeated string answers = 4;
   */
  answers: string[];
  /**
   * Optional list of options to make this multiple choice
   *
   * @generated from protobuf field: repeated string options = 5;
   */
  options: string[];
  /**
   * Order the question should be delivered in a task. If zero, then this
   * will be delivered in a random order along with other zero values - otherwise
   * the order will be based on the index and will be delivered incrementally.
   *
   * @generated from protobuf field: int32 priority = 6;
   */
  priority: number;
}
/**
 * @generated from protobuf enum sparx.reading.content.v1.InputQuestion.Type
 */
export enum InputQuestion_Type {
  /**
   * @generated from protobuf enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: TYPE_LAST_WORD = 1;
   */
  LAST_WORD = 1,
  /**
   * @generated from protobuf enum value: TYPE_QUESTION = 2;
   */
  QUESTION = 2,
  /**
   * @generated from protobuf enum value: TYPE_SELECT_SENTENCE = 3;
   */
  SELECT_SENTENCE = 3,
}
/**
 * @generated from protobuf message sparx.reading.content.v1.OnboardingBook
 */
export interface OnboardingBook {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * The page a student has to reach in an onboarding book for it to be
   * considered complete.
   *
   * @generated from protobuf field: int32 page = 2;
   */
  page: number;
  /**
   * Whether students have to reach the end of the book before they are
   * offered a different book or asked if they want to swap it.
   *
   * @generated from protobuf field: bool unskippable = 3;
   */
  unskippable: boolean;
  /**
   * The number of questions across all of the tasks in the book.
   *
   * @generated from protobuf field: int32 expected_number_of_questions = 4;
   */
  expectedNumberOfQuestions: number;
  /**
   * The final breakpoint ID of the onboarding book. Might not be at the end
   * of the book if there isn't content for the whole book.
   *
   * @generated from protobuf field: string end_part = 5;
   */
  endPart: string;
  /**
   * List of breakpoint ids we expect to see during onboarding
   *
   * @generated from protobuf field: repeated string expected_breakpoint_ids = 6;
   */
  expectedBreakpointIds: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Book$Type extends MessageType<Book> {
  constructor() {
    super('sparx.reading.content.v1.Book', [
      { no: 1, name: 'meta', kind: 'message', T: () => BookMeta },
      {
        no: 6,
        name: 'pages',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Page,
      },
      {
        no: 7,
        name: 'input_questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => InputQuestion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.Book
 */
export const Book = new Book$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookMeta$Type extends MessageType<BookMeta> {
  constructor() {
    super('sparx.reading.content.v1.BookMeta', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.BookMeta
 */
export const BookMeta = new BookMeta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookRange$Type extends MessageType<BookRange> {
  constructor() {
    super('sparx.reading.content.v1.BookRange', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'start_part_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'end_part_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'start_page_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'end_page_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'audio_clip_filename',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.BookRange
 */
export const BookRange = new BookRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Page$Type extends MessageType<Page> {
  constructor() {
    super('sparx.reading.content.v1.Page', [
      { no: 1, name: 'index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'questions',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'breakpoint', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'breakpoint_section_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'part_index', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 7,
        name: 'audio_clip_filename',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.Page
 */
export const Page = new Page$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InputQuestion$Type extends MessageType<InputQuestion> {
  constructor() {
    super('sparx.reading.content.v1.InputQuestion', [
      {
        no: 1,
        name: 'question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'question_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.reading.content.v1.InputQuestion.Type',
          InputQuestion_Type,
          'TYPE_',
        ],
      },
      {
        no: 4,
        name: 'answers',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'options',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'priority', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.InputQuestion
 */
export const InputQuestion = new InputQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnboardingBook$Type extends MessageType<OnboardingBook> {
  constructor() {
    super('sparx.reading.content.v1.OnboardingBook', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'unskippable', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 4,
        name: 'expected_number_of_questions',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 5, name: 'end_part', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 6,
        name: 'expected_breakpoint_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.OnboardingBook
 */
export const OnboardingBook = new OnboardingBook$Type();
