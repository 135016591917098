import { faChevronRight, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Button } from 'components/buttons';
import { HomeworkProgressBar } from 'components/homework-progress-bar/homework-progress-bar';
import {
  IStudentWithExperience,
  useEnglishStudentGroups,
  useTeacherStudentsWithExperience,
} from 'queries/management';
import { useAllRecentHomeworks } from 'queries/tasks';
import { useMemo, useState } from 'react';

import { openedNotStartedStudentsModal } from './events';
import { HandInSummaryModal } from './hand-in-summary-modal';
import styles from './hand-in-summary-progress.module.css';

export type StudentHomework = {
  student: IStudentWithExperience;
  studentGroup: Group;
  homework: Homework;
};

export const HandInSummaryProgress = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: students, isLoading: isLoadingStudents } = useTeacherStudentsWithExperience();
  const { data: studentGroups, isLoading: isLoadingStudentGroups } = useEnglishStudentGroups();
  const { data: homeworks, isLoading: homeworksLoading } = useAllRecentHomeworks();

  const { completedCount, startedCount, notStartedStudentHomeworks, notStartedStudentGroupIds } =
    useMemo(() => {
      let completedCount = 0;
      let startedCount = 0;
      const notStartedStudentHomeworks: StudentHomework[] = [];

      (homeworks || [])
        .filter(homework => homework.targetExperience > 0)
        .forEach(homework => {
          homework.students.forEach(studentHomework => {
            const studentExperience = students?.[studentHomework.studentId];
            const studentGroup = studentGroups?.find(
              group => group.name.split('/')[3] === studentExperience?.studentGroupId,
            );

            if (!studentExperience || !studentGroup || studentHomework.optional) {
              return;
            }

            if (
              studentHomework.targetExperienceOverride > 0 &&
              studentHomework.experience >= studentHomework.targetExperienceOverride
            ) {
              completedCount++;
            } else if (studentHomework.experience >= homework.targetExperience) {
              completedCount++;
            } else if (studentHomework.experience > 0) {
              startedCount++;
            } else {
              notStartedStudentHomeworks.push({
                student: studentExperience,
                studentGroup,
                homework,
              });
            }
          });
        });

      const notStartedStudentGroupIds = new Set(
        notStartedStudentHomeworks.map(student => student.student.studentGroupId),
      );

      return {
        completedCount,
        startedCount,
        notStartedStudentHomeworks,
        notStartedStudentGroupIds,
      };
    }, [homeworks, studentGroups, students]);

  if (
    homeworksLoading ||
    isLoadingStudents ||
    isLoadingStudentGroups ||
    notStartedStudentHomeworks.length === 0
  ) {
    return;
  }

  return (
    <>
      <HandInSummaryModal
        studentHomeworks={notStartedStudentHomeworks}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />

      <div className={styles.Container}>
        <div className={styles.Text}>
          <>
            <strong>
              <FontAwesomeIcon icon={faWarning} style={{ marginRight: 'var(--spx-unit-2)' }} />
              {notStartedStudentHomeworks.length} students in {notStartedStudentGroupIds.size}{' '}
              {notStartedStudentGroupIds.size === 1 ? 'class' : 'classes'}
            </strong>{' '}
            haven&apos;t started their homework this week
          </>
        </div>

        <div className={styles.ProgressBar}>
          <HomeworkProgressBar
            completedCount={completedCount}
            startedCount={startedCount}
            notStartedCount={notStartedStudentHomeworks.length}
          />
        </div>

        <div className={styles.ButtonContainer}>
          <Button
            size="small"
            analyticsEvent={openedNotStartedStudentsModal()}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
            className={styles.Button}
            onClick={() => setIsModalOpen(true)}
          >
            View students
          </Button>
        </div>
      </div>
    </>
  );
};
