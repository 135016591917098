import { faListAlt } from '@fortawesome/free-solid-svg-icons';

import { Tab } from '../../types';

export const useOverviewTab = (): Tab => {
  return {
    id: 'overview',
    title: 'Overview',
    subtitle: 'Prepare to reset',
    pageSubtitle: 'Prepare to reset',
    icon: faListAlt,
    responsibility: 'Sparx Leader & Head of Department',
  };
};
