// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/subscription/v1/subscriptionactions.proto" (package "sparx.school.subscription.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { SchoolSubscriptionDataActionsService } from './subscriptionactions';
import type { SubmitAgreementInPrincipleResponse } from './subscriptionactions';
import type { SubmitAgreementInPrincipleRequest } from './subscriptionactions';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { SubscriptionDetails } from './subscriptionactions';
import type { GetSubscriptionDetailsRequest } from './subscriptionactions';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * SchoolSubscriptionDataActionsService allows less authenticated users to
 * perform necessary actions against subscription data
 *
 * @generated from protobuf service sparx.school.subscription.v1.SchoolSubscriptionDataActionsService
 */
export interface ISchoolSubscriptionDataActionsServiceClient {
  /**
   * @generated from protobuf rpc: GetSubscriptionDetails(sparx.school.subscription.v1.GetSubscriptionDetailsRequest) returns (sparx.school.subscription.v1.SubscriptionDetails);
   */
  getSubscriptionDetails(
    input: GetSubscriptionDetailsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSubscriptionDetailsRequest, SubscriptionDetails>;
  /**
   * Endpoint to allow a teacher to submit an agreement in principle.
   *
   * @generated from protobuf rpc: SubmitAgreementInPrinciple(sparx.school.subscription.v1.SubmitAgreementInPrincipleRequest) returns (sparx.school.subscription.v1.SubmitAgreementInPrincipleResponse);
   */
  submitAgreementInPrinciple(
    input: SubmitAgreementInPrincipleRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitAgreementInPrincipleRequest,
    SubmitAgreementInPrincipleResponse
  >;
}
/**
 * SchoolSubscriptionDataActionsService allows less authenticated users to
 * perform necessary actions against subscription data
 *
 * @generated from protobuf service sparx.school.subscription.v1.SchoolSubscriptionDataActionsService
 */
export class SchoolSubscriptionDataActionsServiceClient
  implements ISchoolSubscriptionDataActionsServiceClient, ServiceInfo
{
  typeName = SchoolSubscriptionDataActionsService.typeName;
  methods = SchoolSubscriptionDataActionsService.methods;
  options = SchoolSubscriptionDataActionsService.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetSubscriptionDetails(sparx.school.subscription.v1.GetSubscriptionDetailsRequest) returns (sparx.school.subscription.v1.SubscriptionDetails);
   */
  getSubscriptionDetails(
    input: GetSubscriptionDetailsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSubscriptionDetailsRequest, SubscriptionDetails> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSubscriptionDetailsRequest, SubscriptionDetails>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * Endpoint to allow a teacher to submit an agreement in principle.
   *
   * @generated from protobuf rpc: SubmitAgreementInPrinciple(sparx.school.subscription.v1.SubmitAgreementInPrincipleRequest) returns (sparx.school.subscription.v1.SubmitAgreementInPrincipleResponse);
   */
  submitAgreementInPrinciple(
    input: SubmitAgreementInPrincipleRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitAgreementInPrincipleRequest,
    SubmitAgreementInPrincipleResponse
  > {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitAgreementInPrincipleRequest,
      SubmitAgreementInPrincipleResponse
    >('unary', this._transport, method, opt, input);
  }
}
