// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/tasks/v1/booklog.proto" (package "sparx.reading.tasks.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { InputQuestion } from '../../content/v1/books';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { SilverReader } from '../../books/v1/book';
/**
 * @generated from protobuf message sparx.reading.tasks.v1.BooklogTask
 */
export interface BooklogTask {}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.BooklogTask.Spec
 */
export interface BooklogTask_Spec {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.BooklogReference last_end = 2;
   */
  lastEnd?: BooklogReference;
  /**
   * @generated from protobuf field: int32 page_count = 3;
   */
  pageCount: number; // this is end page
  /**
   * @generated from protobuf field: int32 start_page = 4;
   */
  startPage: number;
  /**
   * @generated from protobuf field: string previous_answer_history = 5;
   */
  previousAnswerHistory: string;
  /**
   * When silver reader config is present we deliver the book in silver reader mode.
   *
   * @generated from protobuf field: sparx.reading.books.v1.SilverReader silver_reader_config = 6;
   */
  silverReaderConfig?: SilverReader;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.BooklogTask.State
 */
export interface BooklogTask_State {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.BooklogTask.State.Stage stage = 2;
   */
  stage: BooklogTask_State_Stage;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.BooklogReference start = 3;
   */
  start?: BooklogReference;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.BooklogReference end = 4;
   */
  end?: BooklogReference;
  /**
   * @generated from protobuf field: int32 time_spent_minutes = 5;
   */
  timeSpentMinutes: number;
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.BooklogQuestion questions = 6;
   */
  questions: BooklogQuestion[];
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.BooklogQuestionResult last_question_result = 14;
   */
  lastQuestionResult?: BooklogQuestionResult;
  /**
   * @generated from protobuf field: bool finished = 7;
   */
  finished: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_reading = 8;
   */
  startReading?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp finish_reading = 9;
   */
  finishReading?: Timestamp;
  /**
   * @generated from protobuf field: float accumulated_reading_time = 19;
   */
  accumulatedReadingTime: number;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.InputQuestion current_question = 10;
   */
  currentQuestion?: InputQuestion;
  /**
   * @generated from protobuf field: int32 current_question_index = 11;
   */
  currentQuestionIndex: number;
  /**
   * @generated from protobuf field: float current_question_prop = 16;
   */
  currentQuestionProp: number;
  /**
   * @generated from protobuf field: repeated string seen_questions = 12;
   */
  seenQuestions: string[];
  /**
   * @generated from protobuf field: int32 questions_correct = 13;
   */
  questionsCorrect: number;
  /**
   * @generated from protobuf field: string answer_history = 15;
   */
  answerHistory: string;
  /**
   * @generated from protobuf field: bool ended_prematurely = 17;
   */
  endedPrematurely: boolean;
  /**
   * @generated from protobuf field: int32 banked_srp = 18;
   */
  bankedSrp: number;
  /**
   * @generated from protobuf field: optional int32 silver_reader_proposed_end_page = 20;
   */
  silverReaderProposedEndPage?: number;
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.BooklogTask.State.Stage
 */
export enum BooklogTask_State_Stage {
  /**
   * @generated from protobuf enum value: STAGE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STAGE_LOGGING = 1;
   */
  LOGGING = 1,
  /**
   * @generated from protobuf enum value: STAGE_TIMING = 3;
   */
  TIMING = 3,
  /**
   * @generated from protobuf enum value: STAGE_LOGGING_END = 4;
   */
  LOGGING_END = 4,
  /**
   * @generated from protobuf enum value: STAGE_WRITING = 2;
   */
  WRITING = 2,
  /**
   * @generated from protobuf enum value: STAGE_QUESTIONS = 5;
   */
  QUESTIONS = 5,
  /**
   * @generated from protobuf enum value: STAGE_QUESTIONS_RESULT = 7;
   */
  QUESTIONS_RESULT = 7,
  /**
   * @generated from protobuf enum value: STAGE_LOG_WARNING = 6;
   */
  LOG_WARNING = 6,
  /**
   * @generated from protobuf enum value: STAGE_QUESTIONS_WARNING = 8;
   */
  QUESTIONS_WARNING = 8,
  /**
   * @generated from protobuf enum value: STAGE_PAUSED = 9;
   */
  PAUSED = 9,
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.BooklogTask.Action
 */
export interface BooklogTask_Action {
  /**
   * @generated from protobuf oneof: action
   */
  action:
    | {
        oneofKind: 'range';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.SubmitBooklogRange range = 1;
         */
        range: SubmitBooklogRange;
      }
    | {
        oneofKind: 'question';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.SubmitBooklogQuestion question = 2;
         */
        question: SubmitBooklogQuestion;
      }
    | {
        oneofKind: 'back';
        /**
         * @generated from protobuf field: bool back = 3;
         */
        back: boolean;
      }
    | {
        oneofKind: 'answer';
        /**
         * @generated from protobuf field: sparx.reading.tasks.v1.SubmitBooklogAnswer answer = 4;
         */
        answer: SubmitBooklogAnswer;
      }
    | {
        oneofKind: 'pause';
        /**
         * @generated from protobuf field: bool pause = 5;
         */
        pause: boolean;
      }
    | {
        oneofKind: 'resume';
        /**
         * @generated from protobuf field: bool resume = 6;
         */
        resume: boolean;
      }
    | {
        oneofKind: 'abortSilverReader';
        /**
         * @generated from protobuf field: bool abort_silver_reader = 7;
         */
        abortSilverReader: boolean;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.SubmitBooklogRange
 */
export interface SubmitBooklogRange {
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.BooklogReference start = 1;
   */
  start?: BooklogReference;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.BooklogReference end = 2;
   */
  end?: BooklogReference;
  /**
   * @generated from protobuf field: int32 time_spent_minutes = 3;
   */
  timeSpentMinutes: number;
  /**
   * @generated from protobuf field: bool mark_finished = 4;
   */
  markFinished: boolean;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.SubmitBooklogQuestion
 */
export interface SubmitBooklogQuestion {
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.BooklogQuestion question = 1;
   */
  question?: BooklogQuestion;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.SubmitBooklogAnswer
 */
export interface SubmitBooklogAnswer {
  /**
   * @generated from protobuf field: string question_id = 1;
   */
  questionId: string;
  /**
   * @generated from protobuf field: string answer = 2;
   */
  answer: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.BooklogReference
 */
export interface BooklogReference {
  /**
   * @generated from protobuf field: int32 page = 1;
   */
  page: number;
  /**
   * @generated from protobuf field: string chapter = 2;
   */
  chapter: string;
  /**
   * @generated from protobuf field: string first_word = 3;
   */
  firstWord: string;
  /**
   * @generated from protobuf field: string last_word = 4;
   */
  lastWord: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.BooklogQuestion
 */
export interface BooklogQuestion {
  /**
   * @generated from protobuf field: string question_text = 1;
   */
  questionText: string;
  /**
   * @generated from protobuf field: string answer_text = 2;
   */
  answerText: string;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.BooklogQuestionResult
 */
export interface BooklogQuestionResult {
  /**
   * @generated from protobuf field: bool correct = 1;
   */
  correct: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class BooklogTask$Type extends MessageType<BooklogTask> {
  constructor() {
    super('sparx.reading.tasks.v1.BooklogTask', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.BooklogTask
 */
export const BooklogTask = new BooklogTask$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BooklogTask_Spec$Type extends MessageType<BooklogTask_Spec> {
  constructor() {
    super('sparx.reading.tasks.v1.BooklogTask.Spec', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'last_end', kind: 'message', T: () => BooklogReference },
      { no: 3, name: 'page_count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'start_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 5,
        name: 'previous_answer_history',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'silver_reader_config',
        kind: 'message',
        T: () => SilverReader,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.BooklogTask.Spec
 */
export const BooklogTask_Spec = new BooklogTask_Spec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BooklogTask_State$Type extends MessageType<BooklogTask_State> {
  constructor() {
    super('sparx.reading.tasks.v1.BooklogTask.State', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'stage',
        kind: 'enum',
        T: () => [
          'sparx.reading.tasks.v1.BooklogTask.State.Stage',
          BooklogTask_State_Stage,
          'STAGE_',
        ],
      },
      { no: 3, name: 'start', kind: 'message', T: () => BooklogReference },
      { no: 4, name: 'end', kind: 'message', T: () => BooklogReference },
      {
        no: 5,
        name: 'time_spent_minutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BooklogQuestion,
      },
      {
        no: 14,
        name: 'last_question_result',
        kind: 'message',
        T: () => BooklogQuestionResult,
      },
      { no: 7, name: 'finished', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: 'start_reading', kind: 'message', T: () => Timestamp },
      { no: 9, name: 'finish_reading', kind: 'message', T: () => Timestamp },
      {
        no: 19,
        name: 'accumulated_reading_time',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 10,
        name: 'current_question',
        kind: 'message',
        T: () => InputQuestion,
      },
      {
        no: 11,
        name: 'current_question_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 16,
        name: 'current_question_prop',
        kind: 'scalar',
        T: 2 /*ScalarType.FLOAT*/,
      },
      {
        no: 12,
        name: 'seen_questions',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 13,
        name: 'questions_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 15,
        name: 'answer_history',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 17,
        name: 'ended_prematurely',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 18, name: 'banked_srp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 20,
        name: 'silver_reader_proposed_end_page',
        kind: 'scalar',
        opt: true,
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.BooklogTask.State
 */
export const BooklogTask_State = new BooklogTask_State$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BooklogTask_Action$Type extends MessageType<BooklogTask_Action> {
  constructor() {
    super('sparx.reading.tasks.v1.BooklogTask.Action', [
      {
        no: 1,
        name: 'range',
        kind: 'message',
        oneof: 'action',
        T: () => SubmitBooklogRange,
      },
      {
        no: 2,
        name: 'question',
        kind: 'message',
        oneof: 'action',
        T: () => SubmitBooklogQuestion,
      },
      {
        no: 3,
        name: 'back',
        kind: 'scalar',
        oneof: 'action',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 4,
        name: 'answer',
        kind: 'message',
        oneof: 'action',
        T: () => SubmitBooklogAnswer,
      },
      {
        no: 5,
        name: 'pause',
        kind: 'scalar',
        oneof: 'action',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 6,
        name: 'resume',
        kind: 'scalar',
        oneof: 'action',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 7,
        name: 'abort_silver_reader',
        kind: 'scalar',
        oneof: 'action',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.BooklogTask.Action
 */
export const BooklogTask_Action = new BooklogTask_Action$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitBooklogRange$Type extends MessageType<SubmitBooklogRange> {
  constructor() {
    super('sparx.reading.tasks.v1.SubmitBooklogRange', [
      { no: 1, name: 'start', kind: 'message', T: () => BooklogReference },
      { no: 2, name: 'end', kind: 'message', T: () => BooklogReference },
      {
        no: 3,
        name: 'time_spent_minutes',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'mark_finished',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.SubmitBooklogRange
 */
export const SubmitBooklogRange = new SubmitBooklogRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitBooklogQuestion$Type extends MessageType<SubmitBooklogQuestion> {
  constructor() {
    super('sparx.reading.tasks.v1.SubmitBooklogQuestion', [
      { no: 1, name: 'question', kind: 'message', T: () => BooklogQuestion },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.SubmitBooklogQuestion
 */
export const SubmitBooklogQuestion = new SubmitBooklogQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitBooklogAnswer$Type extends MessageType<SubmitBooklogAnswer> {
  constructor() {
    super('sparx.reading.tasks.v1.SubmitBooklogAnswer', [
      {
        no: 1,
        name: 'question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'answer', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.SubmitBooklogAnswer
 */
export const SubmitBooklogAnswer = new SubmitBooklogAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BooklogReference$Type extends MessageType<BooklogReference> {
  constructor() {
    super('sparx.reading.tasks.v1.BooklogReference', [
      { no: 1, name: 'page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'chapter', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'first_word', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'last_word', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.BooklogReference
 */
export const BooklogReference = new BooklogReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BooklogQuestion$Type extends MessageType<BooklogQuestion> {
  constructor() {
    super('sparx.reading.tasks.v1.BooklogQuestion', [
      {
        no: 1,
        name: 'question_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'answer_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.BooklogQuestion
 */
export const BooklogQuestion = new BooklogQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BooklogQuestionResult$Type extends MessageType<BooklogQuestionResult> {
  constructor() {
    super('sparx.reading.tasks.v1.BooklogQuestionResult', [
      { no: 1, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.BooklogQuestionResult
 */
export const BooklogQuestionResult = new BooklogQuestionResult$Type();
