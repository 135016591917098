// Makes a comma separated list but uses 'and' for the last two items
// It would be nice to use Intl.ListFormat but it's not supported on iOS 12.
export const makeItemList = (names: string[]): string => {
  const l = names.length;
  switch (l) {
    case 0:
      return '';
    case 1:
      return names[0];
    case 2:
      return `${names[0]} and ${names[1]}`;
    default:
      names = [...names.slice(0, l - 2), `${names[l - 2]} and ${names[l - 1]}`];
      return names.join(', ');
  }
};
