import { MatureTheme } from '@sparx/api/apis/sparx/reading/books/v1/book';

export const matureThemeMap: Record<string, MatureTheme> = {
  'Bereavement or loss': MatureTheme.BEREAVEMENT_AND_LOSS,
  Discrimination: MatureTheme.DISCRIMINATION,
  'Substance abuse': MatureTheme.SUBSTANCE_ABUSE,
  'Strong language': MatureTheme.STRONG_LANGUAGE,
  Sex: MatureTheme.SEX,
  'Sexual violence and sexual threat': MatureTheme.SEXUAL_VIOLENCE_AND_SEXUAL_THREAT,
  'Violence and threat': MatureTheme.VIOLENCE_AND_THREAT,
  'Historic perspective': MatureTheme.HISTORIC_PERSPECTIVE,
  'Mental health': MatureTheme.MENTAL_HEALTH,
  'Serious illness': MatureTheme.SERIOUS_ILLNESS,
};

const getThemeListFromMap = (map: Record<string, MatureTheme>) =>
  Object.entries(map).map(([label]) => ({
    value: label,
    label,
  }));

export const getReadableStrFromTheme = (theme: MatureTheme) =>
  Object.entries(matureThemeMap).find(([_, v]) => v === theme)?.[0] || 'Unspecified';

export const getThemeListFromThemes = (themes: MatureTheme[]) =>
  themes.map(t => ({
    value: getReadableStrFromTheme(t),
    label: getReadableStrFromTheme(t),
  }));

export const matureThemeList = getThemeListFromMap(matureThemeMap);
