import { HomeBox } from 'components/home-box/home-box';
import goldLocked from 'components/sections/gold_locked_white.png';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import { pathForView } from 'views/views';

import {
  GoldReaderProgressBar,
  GoldReaderStars,
} from '../../library-view/components/homework-components';
import styles from './gold-reader-progress.module.css';

interface IGoldReaderProgressProps {
  disabledOverride: boolean;
  isFetched: boolean;
  progress: number | undefined;
  stars: number | undefined;
  showWhatIsLink?: boolean;
  breakWide?: boolean;
}

export const GoldReaderProgressNotGr = ({
  disabledOverride,
  isFetched,
  progress,
  stars,
  showWhatIsLink = false,
  breakWide = false,
}: IGoldReaderProgressProps) => {
  const navigate = useNavigate();

  if (!isFetched) {
    return null;
  }

  if (disabledOverride) {
    return (
      <HomeBox
        title="Gold Reader is not available"
        variant="faded"
        centered={true}
        lgw={33}
        breakWide={breakWide}
      >
        <div className={styles.GoldDisabled}>
          <img alt="" src={goldLocked} />
          <p className={styles.DisabledPara}>Your teacher has disabled Gold Reader.</p>
        </div>
      </HomeBox>
    );
  }

  return (
    <HomeBox title={'Unlock Gold Reader'} variant={'normal'} centered={true} lgw={33} breakWide>
      <div className={styles.Icon}>
        <img alt="" src={goldLocked} />
      </div>
      <div className={styles.BodyText}>Reach 5 stars to unlock a 5-week Gold Reader pass!</div>
      <GoldReaderProgressBar progress={progress || 0} className={styles.Bar} width="100%" />
      <div className={styles.Stars}>
        <GoldReaderStars count={stars || 0} startCount={stars || 0} />
      </div>
      {showWhatIsLink && (
        <span
          className={styles.BookButtonsLink}
          onClick={() => navigate(pathForView(View.GoldReader))}
        >
          Go to Gold Reader
        </span>
      )}
    </HomeBox>
  );
};
