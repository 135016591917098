// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/subscription/v1/subscriptionactions.proto" (package "sparx.school.subscription.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { KeyContact } from '../../keycontacts/v1/keycontacts';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { SubscriptionType } from './types';
import { Date } from '../../../../../google/type/date';
import { Product } from '../../../types/product';
/**
 * A virtual resource translating SchoolSubscriptionData for a school into a
 * more digestable format for teacher portal.
 *
 * @generated from protobuf message sparx.school.subscription.v1.SubscriptionDetails
 */
export interface SubscriptionDetails {
  /**
   * Name of the school the details apply to.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Deals currently being offered to the school.
   *
   * @generated from protobuf field: repeated sparx.school.subscription.v1.Deal deals = 2;
   */
  deals: Deal[];
  /**
   * Previous subscriptions the school has had.
   *
   * @generated from protobuf field: repeated sparx.school.subscription.v1.SubscriptionSummary previous_subscriptions = 3;
   */
  previousSubscriptions: SubscriptionSummary[];
  /**
   * The current subscription for the school.
   *
   * @generated from protobuf field: sparx.school.subscription.v1.SubscriptionSummary current_subscription = 4;
   */
  currentSubscription?: SubscriptionSummary;
  /**
   * Data captured for the pending subscription.
   *
   * @generated from protobuf field: sparx.school.subscription.v1.SubscriptionSummary pending_subscription = 5;
   */
  pendingSubscription?: SubscriptionSummary;
  /**
   * @generated from protobuf field: sparx.types.Product product = 6;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.school.subscription.v1.Deal
 */
export interface Deal {
  /**
   * The start date of the deal
   *
   * @generated from protobuf field: google.type.Date start_date = 1;
   */
  startDate?: Date;
  /**
   * The end date of the deal
   *
   * @generated from protobuf field: google.type.Date end_date = 2;
   */
  endDate?: Date;
  /**
   * The price of the deal in gbp
   *
   * @generated from protobuf field: string price_gbp = 3;
   */
  priceGbp: string;
  /**
   * The number of months in the deal
   *
   * @generated from protobuf field: int32 duration_months = 4;
   */
  durationMonths: number;
  /**
   * The name of the pricing option this deal is sourced from.
   *
   * @generated from protobuf field: string option_name = 5;
   */
  optionName: string;
}
/**
 * @generated from protobuf message sparx.school.subscription.v1.SubscriptionSummary
 */
export interface SubscriptionSummary {
  /**
   * The date the subscription starts
   *
   * @generated from protobuf field: google.type.Date start_date = 1;
   */
  startDate?: Date;
  /**
   * The date the subscription ends
   *
   * @generated from protobuf field: google.type.Date end_date = 2;
   */
  endDate?: Date;
  /**
   * The price (with units) of the subscription
   *
   * @generated from protobuf field: string price = 3;
   */
  price: string;
  /**
   * The duration in months of the selected subscription
   *
   * @generated from protobuf field: int32 duration_months = 8;
   */
  durationMonths: number;
  /**
   * The name of the option that was selected
   *
   * @generated from protobuf field: string selected_option = 9;
   */
  selectedOption: string;
  /**
   * The type of the subscription
   *
   * @generated from protobuf field: sparx.school.subscription.v1.SubscriptionType type = 4;
   */
  type: SubscriptionType;
  /**
   * The current status of the subscription in the pipeline
   *
   * @generated from protobuf field: sparx.school.subscription.v1.IndividualSubscriptionStatus status = 5;
   */
  status: IndividualSubscriptionStatus;
  /**
   * The time the subscription transitioned to the status
   *
   * @generated from protobuf field: google.protobuf.Timestamp status_last_transition_time = 6;
   */
  statusLastTransitionTime?: Timestamp;
  /**
   * The id of the user who AIPed
   *
   * @generated from protobuf field: string aip_user_id = 7;
   */
  aipUserId: string;
  /**
   * The date a school intends to start HW (only applicable to trials)
   *
   * @generated from protobuf field: google.type.Date planned_homework_start_date = 10;
   */
  plannedHomeworkStartDate?: Date;
}
/**
 * @generated from protobuf message sparx.school.subscription.v1.GetSubscriptionDetailsRequest
 */
export interface GetSubscriptionDetailsRequest {
  /**
   * The school to get the subscription details for.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The product to request the subscription details for.
   *
   * @generated from protobuf field: sparx.types.Product product = 2;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.school.subscription.v1.SubmitAgreementInPrincipleRequest
 */
export interface SubmitAgreementInPrincipleRequest {
  /**
   * The school the user is submitting an AIP for. The AIP is always set
   * against the pending subscription.
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Updated information for the finance contact entered by the user.
   *
   * @generated from protobuf field: sparx.school.keycontacts.v1.KeyContact finance_contact = 2;
   */
  financeContact?: KeyContact;
  /**
   * Updatemask for the finance contact. If empty, finance contact will not be
   * updated.
   *
   * @generated from protobuf field: google.protobuf.FieldMask finance_contact_update_mask = 3;
   */
  financeContactUpdateMask?: FieldMask;
  /**
   * The product this AIP is being submitted for.
   *
   * @generated from protobuf field: sparx.types.Product product = 10;
   */
  product: Product;
  /**
   * Optional. The name of the option that was selected.
   *
   * @generated from protobuf field: string option_name = 9;
   */
  optionName: string;
  /**
   * Required. The start date for the pending subscription.
   *
   * @generated from protobuf field: google.type.Date start_date = 4;
   */
  startDate?: Date;
  /**
   * The end date for the pending subscription. Required if duration_months is
   * 0. Ignored if duration_months is non-zero.
   *
   * @generated from protobuf field: google.type.Date end_date = 5;
   */
  endDate?: Date;
  /**
   * The duration in months for the pending subscription.
   *
   * @generated from protobuf field: int32 duration_months = 6;
   */
  durationMonths: number;
  /**
   * The price in GBP of the subscription. Must be parsable as a valid decimal
   * price.
   *
   * @generated from protobuf field: string price_gbp = 7;
   */
  priceGbp: string;
  /**
   * When true, no purchase order request will be sent relating to this AIP.
   *
   * @generated from protobuf field: bool skip_purchase_order_request = 8;
   */
  skipPurchaseOrderRequest: boolean;
}
/**
 * @generated from protobuf message sparx.school.subscription.v1.SubmitAgreementInPrincipleResponse
 */
export interface SubmitAgreementInPrincipleResponse {}
/**
 * @generated from protobuf enum sparx.school.subscription.v1.IndividualSubscriptionStatus
 */
export enum IndividualSubscriptionStatus {
  /**
   * @generated from protobuf enum value: INDIVIDUAL_SUBSCRIPTION_STATUS_UNKNOWN = 0;
   */
  INDIVIDUAL_SUBSCRIPTION_STATUS_UNKNOWN = 0,
  /**
   * The school has not yet agreed to subscribe
   *
   * @generated from protobuf enum value: NOT_YET_AGREED = 1;
   */
  NOT_YET_AGREED = 1,
  /**
   * The school has agreed to subscribe
   *
   * @generated from protobuf enum value: AGREED_IN_PRINCIPLE = 2;
   */
  AGREED_IN_PRINCIPLE = 2,
  /**
   * A purchase order has been received
   *
   * @generated from protobuf enum value: PURCHASE_ORDER_RECEIVED = 3;
   */
  PURCHASE_ORDER_RECEIVED = 3,
  /**
   * Payment has been received
   *
   * @generated from protobuf enum value: PAYMENT_RECEIVED = 4;
   */
  PAYMENT_RECEIVED = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class SubscriptionDetails$Type extends MessageType<SubscriptionDetails> {
  constructor() {
    super('sparx.school.subscription.v1.SubscriptionDetails', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'deals',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Deal,
      },
      {
        no: 3,
        name: 'previous_subscriptions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => SubscriptionSummary,
      },
      {
        no: 4,
        name: 'current_subscription',
        kind: 'message',
        T: () => SubscriptionSummary,
      },
      {
        no: 5,
        name: 'pending_subscription',
        kind: 'message',
        T: () => SubscriptionSummary,
      },
      {
        no: 6,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.subscription.v1.SubscriptionDetails
 */
export const SubscriptionDetails = new SubscriptionDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Deal$Type extends MessageType<Deal> {
  constructor() {
    super('sparx.school.subscription.v1.Deal', [
      { no: 1, name: 'start_date', kind: 'message', T: () => Date },
      { no: 2, name: 'end_date', kind: 'message', T: () => Date },
      { no: 3, name: 'price_gbp', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'duration_months',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'option_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.subscription.v1.Deal
 */
export const Deal = new Deal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubscriptionSummary$Type extends MessageType<SubscriptionSummary> {
  constructor() {
    super('sparx.school.subscription.v1.SubscriptionSummary', [
      { no: 1, name: 'start_date', kind: 'message', T: () => Date },
      { no: 2, name: 'end_date', kind: 'message', T: () => Date },
      { no: 3, name: 'price', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'duration_months',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 9,
        name: 'selected_option',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.school.subscription.v1.SubscriptionType',
          SubscriptionType,
        ],
      },
      {
        no: 5,
        name: 'status',
        kind: 'enum',
        T: () => [
          'sparx.school.subscription.v1.IndividualSubscriptionStatus',
          IndividualSubscriptionStatus,
        ],
      },
      {
        no: 6,
        name: 'status_last_transition_time',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 7,
        name: 'aip_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 10,
        name: 'planned_homework_start_date',
        kind: 'message',
        T: () => Date,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.subscription.v1.SubscriptionSummary
 */
export const SubscriptionSummary = new SubscriptionSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSubscriptionDetailsRequest$Type extends MessageType<GetSubscriptionDetailsRequest> {
  constructor() {
    super('sparx.school.subscription.v1.GetSubscriptionDetailsRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.subscription.v1.GetSubscriptionDetailsRequest
 */
export const GetSubscriptionDetailsRequest =
  new GetSubscriptionDetailsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitAgreementInPrincipleRequest$Type extends MessageType<SubmitAgreementInPrincipleRequest> {
  constructor() {
    super('sparx.school.subscription.v1.SubmitAgreementInPrincipleRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'finance_contact', kind: 'message', T: () => KeyContact },
      {
        no: 3,
        name: 'finance_contact_update_mask',
        kind: 'message',
        T: () => FieldMask,
      },
      {
        no: 10,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
      {
        no: 9,
        name: 'option_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'start_date', kind: 'message', T: () => Date },
      { no: 5, name: 'end_date', kind: 'message', T: () => Date },
      {
        no: 6,
        name: 'duration_months',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 7, name: 'price_gbp', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'skip_purchase_order_request',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.subscription.v1.SubmitAgreementInPrincipleRequest
 */
export const SubmitAgreementInPrincipleRequest =
  new SubmitAgreementInPrincipleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitAgreementInPrincipleResponse$Type extends MessageType<SubmitAgreementInPrincipleResponse> {
  constructor() {
    super(
      'sparx.school.subscription.v1.SubmitAgreementInPrincipleResponse',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.school.subscription.v1.SubmitAgreementInPrincipleResponse
 */
export const SubmitAgreementInPrincipleResponse =
  new SubmitAgreementInPrincipleResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.school.subscription.v1.SchoolSubscriptionDataActionsService
 */
export const SchoolSubscriptionDataActionsService = new ServiceType(
  'sparx.school.subscription.v1.SchoolSubscriptionDataActionsService',
  [
    {
      name: 'GetSubscriptionDetails',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'schoolSubscription',
          domain: '{school_name}',
        },
      },
      I: GetSubscriptionDetailsRequest,
      O: SubscriptionDetails,
    },
    {
      name: 'SubmitAgreementInPrinciple',
      options: {
        'sparx.api.auth': {
          action: 'submitAIP',
          resource: 'schoolSubscription',
          domain: '{school_name}',
        },
      },
      I: SubmitAgreementInPrincipleRequest,
      O: SubmitAgreementInPrincipleResponse,
    },
  ],
);
