import classNames from 'classnames';
import { FCWithChildren } from 'utils/props';

import styles from './home-box.module.css';

type BoxWidths = 33 | 66 | 50 | 100;

interface IHomeBoxProps {
  title?: string;
  variant?: 'normal' | 'dark' | 'gold' | 'faded';
  centered?: boolean;
  lgw?: BoxWidths;
  className?: string;
  onClick?: () => void;
  breakWide?: boolean;
}

const lgBoxWidthClasses: Record<BoxWidths, string> = {
  33: styles.BoxWide33,
  50: styles.BoxWide50,
  66: styles.BoxWide66,
  100: styles.BoxWide100,
};

export const HomeBox: FCWithChildren<IHomeBoxProps> = ({
  children,
  variant,
  title,
  centered,
  lgw,
  className,
  onClick,
  breakWide = false,
}) => {
  return (
    <HomeBoxWrapper lgw={lgw} breakWide={breakWide}>
      <div
        className={classNames(
          styles.Box,
          centered && styles.BoxCentered,
          variant === 'dark' && styles.BoxDark,
          variant === 'gold' && styles.BoxGold,
          variant === 'faded' && styles.BoxFaded,
          className,
        )}
        onClick={onClick}
      >
        {title && <h3>{title}</h3>}
        {children}
      </div>
    </HomeBoxWrapper>
  );
};

interface IHomeBoxWrapperProps {
  lgw?: BoxWidths;
  breakWide?: boolean;
}

export const HomeBoxWrapper: FCWithChildren<IHomeBoxWrapperProps> = ({
  children,
  lgw,
  breakWide,
}) => (
  <div
    className={classNames(styles.BoxWrapper, lgBoxWidthClasses[lgw || 50], {
      [styles.BreakWide]: breakWide,
    })}
  >
    {children}
  </div>
);
