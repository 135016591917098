import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { useSchoolInInterimState } from 'hooks/school-calendar';
import { useSchoolMISStatus } from 'queries/school-status';

import { Tab } from '../../types';
import { MISStatus, MISStatusConfig, misStatusConfig } from './types';

export const useMISTab = (): Tab & { status: MISStatus; config: MISStatusConfig } => {
  const { data, isLoading, isError } = useSchoolMISStatus();
  const isInterim = useSchoolInInterimState();

  const status = (): MISStatus => {
    if (!isInterim) return MISStatus.RESET;
    if (isLoading) return MISStatus.LOADING;
    if (!data || isError) return MISStatus.NOT_CONNECTED;
    return data.hasRolledOver ? MISStatus.READY : MISStatus.NOT_READY;
  };

  const config = misStatusConfig[status()];

  return {
    id: 'manage-mis',
    title: 'Manage your MIS',
    subtitle: config.subtitle,
    pageSubtitle: config.pageSubtitle,
    icon: faUsersCog,
    responsibility: 'Sparx Leader & Head of Department',
    hasWarning: isLoading ? false : config.hasWarning,
    hasSuccess: isLoading ? false : config.hasSuccess,
    isLoading,
    status: status(),
    config,
  };
};

/**
 * Alternative implementation of useMISTab for the Getting Started page, which
 * shows whether the MIS is connected or not, but does not show anything about
 * rollover
 */
export const useGettingStartedMISTab = (): Tab & { status: MISStatus; config: MISStatusConfig } => {
  const { data, isLoading, isError } = useSchoolMISStatus();

  const status = (): MISStatus => {
    if (isLoading) return MISStatus.LOADING;
    if (!data || isError) return MISStatus.NOT_CONNECTED;
    return MISStatus.READY_TO_GET_STARTED;
  };

  const config = misStatusConfig[status()];

  return {
    id: 'manage-mis',
    title: 'Manage your MIS',
    subtitle: config.subtitle,
    pageSubtitle: config.pageSubtitle,
    icon: faUsersCog,
    responsibility: 'Sparx Leader & Head of Department',
    hasWarning: isLoading ? false : config.hasWarning,
    hasSuccess: isLoading ? false : config.hasSuccess,
    isLoading,
    status: status(),
    config,
  };
};
