import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import goldPreview from 'components/alert/images/gold_reader_preview.png';
import { Button } from 'components/buttons/button';
import { HomeBox } from 'components/home-box/home-box';
import { useStartBookDialog } from 'hooks/start-book-dialog';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import { pathForView } from 'views/views';

import styles from './gold-reader-summary.module.css';

interface IGoldReaderSummaryProps {
  isFetched: boolean;
  isGoldReader: boolean;
  latestGoldReaderBook: LibraryBook | undefined;
  latestGoldReaderBookComplete: boolean;
  latestEbook: LibraryBook | undefined;
}

export const GoldReaderSummary = (props: IGoldReaderSummaryProps) => {
  if (!props.isFetched) {
    return null;
  }
  return (
    <HomeBox variant="normal" lgw={100}>
      <div className={styles.Main}>
        <div className={styles.Image}>
          <img src={goldPreview} alt="" />
        </div>
        <div>
          <GoldReaderSummaryContent {...props} />
        </div>
      </div>
    </HomeBox>
  );
};

const GoldReaderSummaryContent = ({
  isGoldReader,
  latestGoldReaderBook,
  latestGoldReaderBookComplete,
  latestEbook,
}: IGoldReaderSummaryProps) => {
  const navigate = useNavigate();
  const [, openStartBookDialog] = useStartBookDialog();

  if (isGoldReader && latestGoldReaderBook && !latestGoldReaderBookComplete) {
    return (
      <>
        <p className={styles.Text}>
          <strong>
            You&apos;re a Gold Reader! You can add your own books and read them to earn SRP.
          </strong>
        </p>
        <p className={styles.Text}>
          You can keep reading your last Gold Reader book or head over to the Library and choose
          another.
        </p>
        <div className={styles.Buttons}>
          <Button
            analyticsEvent={undefined}
            size="small"
            color="danger"
            className={styles.FirstButton}
            onClick={() =>
              latestGoldReaderBook && openStartBookDialog(latestGoldReaderBook.metadataAbridged)
            }
          >
            Continue last Gold Reader book
          </Button>
          <Button
            analyticsEvent={undefined}
            size="small"
            color="danger"
            variant="secondary"
            onClick={() => navigate(pathForView(View.AddGoldReaderBook))}
          >
            Add a book
          </Button>
        </div>
      </>
    );
  }

  if (isGoldReader && latestGoldReaderBook && latestGoldReaderBookComplete) {
    return (
      <>
        <p>
          <strong>
            You&apos;re a Gold Reader! You can add your own books and read them to earn SRP.
          </strong>
        </p>
        <p>Head over to the Library and choose a new book.</p>
        <Button
          analyticsEvent={undefined}
          size="small"
          color="danger"
          onClick={() => navigate(pathForView(View.AddGoldReaderBook))}
        >
          Add a book
        </Button>
      </>
    );
  }

  if (isGoldReader && !latestGoldReaderBook) {
    return (
      <>
        <p>
          <strong>
            You&apos;re a Gold Reader! You can now add your own books and read them to earn SRP.
          </strong>
        </p>
        <p>Head over to the Library and choose a book to get going.</p>
        <Button
          analyticsEvent={undefined}
          size="small"
          color="danger"
          onClick={() => navigate(pathForView(View.AddGoldReaderBook))}
        >
          Add a book
        </Button>
      </>
    );
  }

  return (
    <>
      <p className={styles.Text}>
        Gold Readers can add their own books to Sparx Reader and read them to earn SRP.
      </p>
      <p className={styles.Text}>
        Reading more than your current task will earn you stars to unlock your Gold Reader pass.
      </p>
      <Button
        analyticsEvent={undefined}
        className={styles.FirstButton}
        onClick={() =>
          // If they have not got an active ebook, navigate to the library.
          latestEbook
            ? openStartBookDialog(latestEbook.metadataAbridged)
            : navigate(pathForView(View.Explore))
        }
      >
        Keep reading to earn your pass
      </Button>
    </>
  );
};
