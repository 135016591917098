// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/flags/v1/flags.proto" (package "sparx.reading.users.flags.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.reading.users.flags.v1.StudentFlag
 */
export interface StudentFlag {
  /**
   * @generated from protobuf field: string id = 1;
   */
  id: string;
  /**
   * @generated from protobuf field: string student_id = 2;
   */
  studentId: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_timestamp = 3;
   */
  createdTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.users.flags.v1.CreateStudentFlagRequest
 */
export interface CreateStudentFlagRequest {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
}
/**
 * @generated from protobuf message sparx.reading.users.flags.v1.CreateStudentFlagResponse
 */
export interface CreateStudentFlagResponse {
  /**
   * @generated from protobuf field: sparx.reading.users.flags.v1.StudentFlag student_flag = 1;
   */
  studentFlag?: StudentFlag;
}
// @generated message type with reflection information, may provide speed optimized methods
class StudentFlag$Type extends MessageType<StudentFlag> {
  constructor() {
    super('sparx.reading.users.flags.v1.StudentFlag', [
      { no: 1, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'created_timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.flags.v1.StudentFlag
 */
export const StudentFlag = new StudentFlag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateStudentFlagRequest$Type extends MessageType<CreateStudentFlagRequest> {
  constructor() {
    super('sparx.reading.users.flags.v1.CreateStudentFlagRequest', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.flags.v1.CreateStudentFlagRequest
 */
export const CreateStudentFlagRequest = new CreateStudentFlagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateStudentFlagResponse$Type extends MessageType<CreateStudentFlagResponse> {
  constructor() {
    super('sparx.reading.users.flags.v1.CreateStudentFlagResponse', [
      { no: 1, name: 'student_flag', kind: 'message', T: () => StudentFlag },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.flags.v1.CreateStudentFlagResponse
 */
export const CreateStudentFlagResponse = new CreateStudentFlagResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.users.flags.v1.FlagsService
 */
export const FlagsService = new ServiceType(
  'sparx.reading.users.flags.v1.FlagsService',
  [
    {
      name: 'CreateStudentFlag',
      options: {},
      I: CreateStudentFlagRequest,
      O: CreateStudentFlagResponse,
    },
  ],
);
