import classNames from 'classnames';
import {
  AnalyticEventFields,
  useClientEvent,
} from 'components/client-events/client-event-provider';
import styles from 'components/tabs/tabs.module.css';

interface ITab {
  name: string | React.ReactElement;
  id?: string;
  selected?: boolean;
  onClick?: () => void;
}

interface ITabsProps {
  tabs: ITab[];
  prefix?: string;
  currentTab?: number;
  setCurrentTab?: (index: number) => void;
  right?: React.ReactElement;
  className?: string;
  analyticsEvent?: AnalyticEventFields;
}

export const Tabs: React.FC<ITabsProps> = ({
  tabs,
  prefix,
  currentTab,
  setCurrentTab,
  right,
  className,
  analyticsEvent,
}) => {
  const { sendEvent } = useClientEvent();
  return (
    <div className={classNames(styles.Tabs, className)}>
      {prefix && <div className={styles.Prefix}>{prefix}</div>}
      {tabs.map((tab, i) => (
        <Tab
          key={i}
          onClick={() => {
            tab.onClick ? tab.onClick() : setCurrentTab ? setCurrentTab(i) : undefined;
            if (analyticsEvent) {
              sendEvent({
                ...analyticsEvent,
                labels: { tab: tab.id, ...(analyticsEvent.labels || {}) },
              });
            }
          }}
          selected={i === currentTab || tab.selected}
          text={tab.name}
        />
      ))}
      {right && <div className={styles.Right}>{right}</div>}
    </div>
  );
};

interface ITabProps {
  selected?: boolean;
  text: string | React.ReactElement;
  onClick?: () => void;
}

export const Tab: React.FC<ITabProps> = ({ selected, text, onClick }) => (
  <div onClick={onClick} className={`${styles.Tab} ${selected && styles.TabSelected}`}>
    {text}
  </div>
);
