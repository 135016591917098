import { Button } from 'components/buttons';
import { Link } from 'components/link';
import { View } from 'views';
import { pathForView } from 'views/views';

import { clickedChooseBookButton, clickedDashboardButton } from '../../events';
import styles from './buttons.module.css';

export const Buttons = () => {
  return (
    <div className={styles.Container}>
      <Link to={pathForView(View.Landing)} analyticsEvent={clickedDashboardButton()}>
        <Button analyticsEvent={undefined} className={styles.DashboardButton}>
          Go to your Dashboard
        </Button>
      </Link>

      <Link to={pathForView(View.ChooseNewBook)} analyticsEvent={clickedChooseBookButton()}>
        <Button analyticsEvent={undefined} className={styles.ChooseBookButton}>
          Choose another book
        </Button>
      </Link>
    </div>
  );
};
