import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BackButton } from 'components/backbutton/backbutton';
import { AnalyticEventFields } from 'components/client-events/client-event-provider';
import { Link } from 'components/link';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './library-content.module.css';

export const LibraryContent = ({ children }: PropsWithChildren) => {
  return <div className={styles.Container}>{children}</div>;
};

interface LibraryTitleProps {
  crumb?: {
    title: string;
    to: string;
    analyticsEvent?: AnalyticEventFields;
  };
}

export const LibraryTitle = ({ children, crumb }: PropsWithChildren<LibraryTitleProps>) => {
  const navigate = useNavigate();
  return (
    <div className={styles.Title}>
      {crumb && (
        <BackButton onClick={() => navigate(crumb.to)} analyticsEvent={crumb.analyticsEvent} />
      )}
      <h2>
        {crumb && (
          <span className={styles.Crumb}>
            <Link className={styles.Link} to={crumb.to} analyticsEvent={crumb.analyticsEvent}>
              {crumb.title}
            </Link>
            <FontAwesomeIcon icon={faCaretRight} />
          </span>
        )}
        {children}
      </h2>
    </div>
  );
};
