// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/feedback/v1/feedback.proto" (package "sparx.reading.feedback.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Feedback } from './feedback';
import type { BatchGetVideoWatchesResponse } from './feedback';
import type { BatchGetVideoWatchesRequest } from './feedback';
import type { RecordVideoWatchResponse } from './feedback';
import type { RecordVideoWatchRequest } from './feedback';
import type { ResetUserTeacherSurveyResponse } from './feedback';
import type { ResetUserTeacherSurveyRequest } from './feedback';
import type { SubmitTeacherSurveySectionResponsesResponse } from './feedback';
import type { SubmitTeacherSurveySectionResponsesRequest } from './feedback';
import type { StartTeacherSurveyResponse } from './feedback';
import type { StartTeacherSurveyRequest } from './feedback';
import type { TeacherSurvey } from './feedback';
import type { GetTeacherSurveyRequest } from './feedback';
import type { DismissUserSentimentFeedbackResponse } from './feedback';
import type { DismissUserSentimentFeedbackRequest } from './feedback';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { SubmitUserSentimentFeedbackResponse } from './feedback';
import type { SubmitUserSentimentFeedbackRequest } from './feedback';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.feedback.v1.Feedback
 */
export interface IFeedbackClient {
  /**
   * SubmitUserSentimentFeedback handles the "How are you finding Sparx Reader"
   * pop-up with smiley faces.
   *
   * @generated from protobuf rpc: SubmitUserSentimentFeedback(sparx.reading.feedback.v1.SubmitUserSentimentFeedbackRequest) returns (sparx.reading.feedback.v1.SubmitUserSentimentFeedbackResponse);
   */
  submitUserSentimentFeedback(
    input: SubmitUserSentimentFeedbackRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitUserSentimentFeedbackRequest,
    SubmitUserSentimentFeedbackResponse
  >;
  /**
   * Deprecated.
   * DismissUserSentimentFeedback handles the user dismissing the feedback
   * pop-up. It isn't possible to do this anymore.
   *
   * @generated from protobuf rpc: DismissUserSentimentFeedback(sparx.reading.feedback.v1.DismissUserSentimentFeedbackRequest) returns (sparx.reading.feedback.v1.DismissUserSentimentFeedbackResponse);
   */
  dismissUserSentimentFeedback(
    input: DismissUserSentimentFeedbackRequest,
    options?: RpcOptions,
  ): UnaryCall<
    DismissUserSentimentFeedbackRequest,
    DismissUserSentimentFeedbackResponse
  >;
  /**
   * GetTeacherSurvey returns the requesting user's answers to a specified survey. (Get)
   *
   * @generated from protobuf rpc: GetTeacherSurvey(sparx.reading.feedback.v1.GetTeacherSurveyRequest) returns (sparx.reading.feedback.v1.TeacherSurvey);
   */
  getTeacherSurvey(
    input: GetTeacherSurveyRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTeacherSurveyRequest, TeacherSurvey>;
  /**
   * StartTeacherSurvey is called when the teacher starts doing a survey, we
   * record the time that they started. (Create)
   *
   * @generated from protobuf rpc: StartTeacherSurvey(sparx.reading.feedback.v1.StartTeacherSurveyRequest) returns (sparx.reading.feedback.v1.StartTeacherSurveyResponse);
   */
  startTeacherSurvey(
    input: StartTeacherSurveyRequest,
    options?: RpcOptions,
  ): UnaryCall<StartTeacherSurveyRequest, StartTeacherSurveyResponse>;
  /**
   * SubmitTeacherSurveySectionResponses is called when the teacher finishes
   * filling out a section of the survey. (Update)
   *
   * @generated from protobuf rpc: SubmitTeacherSurveySectionResponses(sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesRequest) returns (sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesResponse);
   */
  submitTeacherSurveySectionResponses(
    input: SubmitTeacherSurveySectionResponsesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitTeacherSurveySectionResponsesRequest,
    SubmitTeacherSurveySectionResponsesResponse
  >;
  /**
   * ResetUserTeacherSurvey (dev/test ONLY) clears all the survey data for a teacher.
   *
   * @generated from protobuf rpc: ResetUserTeacherSurvey(sparx.reading.feedback.v1.ResetUserTeacherSurveyRequest) returns (sparx.reading.feedback.v1.ResetUserTeacherSurveyResponse);
   */
  resetUserTeacherSurvey(
    input: ResetUserTeacherSurveyRequest,
    options?: RpcOptions,
  ): UnaryCall<ResetUserTeacherSurveyRequest, ResetUserTeacherSurveyResponse>;
  /**
   * RecordVideoWatch is called when the teacher starts or finishes a video.
   *
   * @generated from protobuf rpc: RecordVideoWatch(sparx.reading.feedback.v1.RecordVideoWatchRequest) returns (sparx.reading.feedback.v1.RecordVideoWatchResponse);
   */
  recordVideoWatch(
    input: RecordVideoWatchRequest,
    options?: RpcOptions,
  ): UnaryCall<RecordVideoWatchRequest, RecordVideoWatchResponse>;
  /**
   * BatchGetVideoWatches returns the watch states for the requested video resources.
   *
   * @generated from protobuf rpc: BatchGetVideoWatches(sparx.reading.feedback.v1.BatchGetVideoWatchesRequest) returns (sparx.reading.feedback.v1.BatchGetVideoWatchesResponse);
   */
  batchGetVideoWatches(
    input: BatchGetVideoWatchesRequest,
    options?: RpcOptions,
  ): UnaryCall<BatchGetVideoWatchesRequest, BatchGetVideoWatchesResponse>;
}
/**
 * @generated from protobuf service sparx.reading.feedback.v1.Feedback
 */
export class FeedbackClient implements IFeedbackClient, ServiceInfo {
  typeName = Feedback.typeName;
  methods = Feedback.methods;
  options = Feedback.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * SubmitUserSentimentFeedback handles the "How are you finding Sparx Reader"
   * pop-up with smiley faces.
   *
   * @generated from protobuf rpc: SubmitUserSentimentFeedback(sparx.reading.feedback.v1.SubmitUserSentimentFeedbackRequest) returns (sparx.reading.feedback.v1.SubmitUserSentimentFeedbackResponse);
   */
  submitUserSentimentFeedback(
    input: SubmitUserSentimentFeedbackRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitUserSentimentFeedbackRequest,
    SubmitUserSentimentFeedbackResponse
  > {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitUserSentimentFeedbackRequest,
      SubmitUserSentimentFeedbackResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Deprecated.
   * DismissUserSentimentFeedback handles the user dismissing the feedback
   * pop-up. It isn't possible to do this anymore.
   *
   * @generated from protobuf rpc: DismissUserSentimentFeedback(sparx.reading.feedback.v1.DismissUserSentimentFeedbackRequest) returns (sparx.reading.feedback.v1.DismissUserSentimentFeedbackResponse);
   */
  dismissUserSentimentFeedback(
    input: DismissUserSentimentFeedbackRequest,
    options?: RpcOptions,
  ): UnaryCall<
    DismissUserSentimentFeedbackRequest,
    DismissUserSentimentFeedbackResponse
  > {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      DismissUserSentimentFeedbackRequest,
      DismissUserSentimentFeedbackResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * GetTeacherSurvey returns the requesting user's answers to a specified survey. (Get)
   *
   * @generated from protobuf rpc: GetTeacherSurvey(sparx.reading.feedback.v1.GetTeacherSurveyRequest) returns (sparx.reading.feedback.v1.TeacherSurvey);
   */
  getTeacherSurvey(
    input: GetTeacherSurveyRequest,
    options?: RpcOptions,
  ): UnaryCall<GetTeacherSurveyRequest, TeacherSurvey> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetTeacherSurveyRequest, TeacherSurvey>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * StartTeacherSurvey is called when the teacher starts doing a survey, we
   * record the time that they started. (Create)
   *
   * @generated from protobuf rpc: StartTeacherSurvey(sparx.reading.feedback.v1.StartTeacherSurveyRequest) returns (sparx.reading.feedback.v1.StartTeacherSurveyResponse);
   */
  startTeacherSurvey(
    input: StartTeacherSurveyRequest,
    options?: RpcOptions,
  ): UnaryCall<StartTeacherSurveyRequest, StartTeacherSurveyResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      StartTeacherSurveyRequest,
      StartTeacherSurveyResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * SubmitTeacherSurveySectionResponses is called when the teacher finishes
   * filling out a section of the survey. (Update)
   *
   * @generated from protobuf rpc: SubmitTeacherSurveySectionResponses(sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesRequest) returns (sparx.reading.feedback.v1.SubmitTeacherSurveySectionResponsesResponse);
   */
  submitTeacherSurveySectionResponses(
    input: SubmitTeacherSurveySectionResponsesRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitTeacherSurveySectionResponsesRequest,
    SubmitTeacherSurveySectionResponsesResponse
  > {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitTeacherSurveySectionResponsesRequest,
      SubmitTeacherSurveySectionResponsesResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * ResetUserTeacherSurvey (dev/test ONLY) clears all the survey data for a teacher.
   *
   * @generated from protobuf rpc: ResetUserTeacherSurvey(sparx.reading.feedback.v1.ResetUserTeacherSurveyRequest) returns (sparx.reading.feedback.v1.ResetUserTeacherSurveyResponse);
   */
  resetUserTeacherSurvey(
    input: ResetUserTeacherSurveyRequest,
    options?: RpcOptions,
  ): UnaryCall<ResetUserTeacherSurveyRequest, ResetUserTeacherSurveyResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ResetUserTeacherSurveyRequest,
      ResetUserTeacherSurveyResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * RecordVideoWatch is called when the teacher starts or finishes a video.
   *
   * @generated from protobuf rpc: RecordVideoWatch(sparx.reading.feedback.v1.RecordVideoWatchRequest) returns (sparx.reading.feedback.v1.RecordVideoWatchResponse);
   */
  recordVideoWatch(
    input: RecordVideoWatchRequest,
    options?: RpcOptions,
  ): UnaryCall<RecordVideoWatchRequest, RecordVideoWatchResponse> {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<RecordVideoWatchRequest, RecordVideoWatchResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * BatchGetVideoWatches returns the watch states for the requested video resources.
   *
   * @generated from protobuf rpc: BatchGetVideoWatches(sparx.reading.feedback.v1.BatchGetVideoWatchesRequest) returns (sparx.reading.feedback.v1.BatchGetVideoWatchesResponse);
   */
  batchGetVideoWatches(
    input: BatchGetVideoWatchesRequest,
    options?: RpcOptions,
  ): UnaryCall<BatchGetVideoWatchesRequest, BatchGetVideoWatchesResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      BatchGetVideoWatchesRequest,
      BatchGetVideoWatchesResponse
    >('unary', this._transport, method, opt, input);
  }
}
