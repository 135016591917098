import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from 'components/rating/stars-rating.module.css';
import { useState } from 'react';

interface IStarsRatingEditorProps {
  rating: number;
  onChange: (rating: number) => void;
}

export const StarsRatingEditor: React.FC<IStarsRatingEditorProps> = ({ rating, onChange }) => {
  const [targetStars, setTargetStars] = useState<number>(rating);
  const hovering = targetStars !== rating;
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (targetStars > i * 2 + 1) {
      stars.push(1);
    } else if (targetStars > i * 2) {
      stars.push(0.5);
    } else {
      stars.push(0);
    }
  }
  return (
    <div className={styles.Stars}>
      {stars.map((star, i) => {
        let icon = faStarSolid;
        let active = true;
        if (star === 0.5) {
          icon = faStarHalf;
        } else if (star === 0) {
          active = false;
        }
        return (
          <div
            className={`${styles.StarContainer} enter-down-2`}
            onMouseLeave={() => setTargetStars(rating)}
            key={i}
          >
            <div
              className={styles.StarAreaLeft}
              onClick={() => onChange(i * 2 + 1)}
              onMouseOver={() => setTargetStars(i * 2 + 1)}
              data-test-id={`star-${i * 2 + 1}`}
            />
            <div
              className={styles.StarAreaRight}
              onClick={() => onChange(i * 2 + 2)}
              onMouseOver={() => setTargetStars(i * 2 + 2)}
              data-test-id={`star-${i * 2 + 2}`}
            />
            <div className={`${styles.Star} ${styles.StarBG}`}>
              <FontAwesomeIcon icon={faStarOutline} />
            </div>
            {active && (
              <div
                className={`${styles.Star} ${hovering ? styles.StarActive : styles.StarCurrent}`}
              >
                <FontAwesomeIcon icon={icon} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
