// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/books/v2/book_legacy.proto" (package "sparx.reading.books.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Struct } from '../../../../../google/protobuf/struct';
/**
 * The old format of book content, deprecated in favour of reflowable content.
 *
 * @deprecated
 * @generated from protobuf message sparx.reading.books.v2.BookLegacy
 */
export interface BookLegacy {
  /**
   * Parellas in this book content.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v2.BookLegacyParella parellas = 1;
   */
  parellas: BookLegacyParella[];
  /**
   * Styles that should be applied to this book.
   *
   * @generated from protobuf field: sparx.reading.books.v2.BookLegacyStyles styles = 2;
   */
  styles?: BookLegacyStyles;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.books.v2.BookLegacyStyles
 */
export interface BookLegacyStyles {
  /**
   * @generated from protobuf field: repeated sparx.reading.books.v2.BookLegacyCSS styles = 1;
   */
  styles: BookLegacyCSS[];
  /**
   * @generated from protobuf field: map<int32, string> paragraph_classes = 2;
   */
  paragraphClasses: {
    [key: number]: string;
  };
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.books.v2.BookLegacyCSS
 */
export interface BookLegacyCSS {
  /**
   * @generated from protobuf field: string tag = 1;
   */
  tag: string;
  /**
   * @generated from protobuf field: map<string, string> styles = 2;
   */
  styles: {
    [key: string]: string;
  };
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.books.v2.BookLegacyParella
 */
export interface BookLegacyParella {
  /**
   * @generated from protobuf field: string parella_id = 1;
   */
  parellaId: string;
  /**
   * @generated from protobuf field: int32 parella_index = 2;
   */
  parellaIndex: number;
  /**
   * @generated from protobuf field: repeated sparx.reading.books.v2.BookLegacyPart parts = 3;
   */
  parts: BookLegacyPart[];
  /**
   * @generated from protobuf field: int32 words = 4;
   */
  words: number;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.books.v2.BookLegacyPart
 */
export interface BookLegacyPart {
  /**
   * @generated from protobuf field: string part_id = 1;
   */
  partId: string;
  /**
   * @generated from protobuf field: int32 paragraph_index = 2;
   */
  paragraphIndex: number;
  /**
   * @generated from protobuf oneof: contents
   */
  contents:
    | {
        oneofKind: 'title';
        /**
         * @generated from protobuf field: sparx.reading.books.v2.BookLegacyPart.Title title = 4;
         */
        title: BookLegacyPart_Title;
      }
    | {
        oneofKind: 'text';
        /**
         * @generated from protobuf field: sparx.reading.books.v2.BookLegacyPart.Text text = 5;
         */
        text: BookLegacyPart_Text;
      }
    | {
        oneofKind: 'image';
        /**
         * @generated from protobuf field: sparx.reading.books.v2.BookLegacyPart.Image image = 6;
         */
        image: BookLegacyPart_Image;
      }
    | {
        oneofKind: 'page';
        /**
         * @generated from protobuf field: sparx.reading.books.v2.BookLegacyPart.PageBreakpoint page = 7;
         */
        page: BookLegacyPart_PageBreakpoint;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.books.v2.BookLegacyPart.Title
 */
export interface BookLegacyPart_Title {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * @generated from protobuf field: string css_class = 2;
   */
  cssClass: string;
}
/**
 * @generated from protobuf message sparx.reading.books.v2.BookLegacyPart.Image
 */
export interface BookLegacyPart_Image {
  /**
   * @generated from protobuf field: string source = 1;
   */
  source: string;
}
/**
 * @generated from protobuf message sparx.reading.books.v2.BookLegacyPart.Text
 */
export interface BookLegacyPart_Text {
  /**
   * @generated from protobuf field: string text = 1;
   */
  text: string;
  /**
   * @generated from protobuf field: string css_class = 2;
   */
  cssClass: string;
}
/**
 * @generated from protobuf message sparx.reading.books.v2.BookLegacyPart.PageBreakpoint
 */
export interface BookLegacyPart_PageBreakpoint {
  /**
   * @generated from protobuf field: int32 page_number = 1;
   */
  pageNumber: number;
}
/**
 * BookLegacyImport is a utility message used exclusively by the
 * bookv1tov2 script to load existing content so it can be converted
 * into a BookLegacy message.
 *
 * @deprecated
 * @generated from protobuf message sparx.reading.books.v2.BookLegacyImport
 */
export interface BookLegacyImport {
  /**
   * @generated from protobuf field: repeated sparx.reading.books.v2.BookLegacyParella parellas = 1;
   */
  parellas: BookLegacyParella[];
  /**
   * @generated from protobuf field: sparx.reading.books.v2.BookLegacyStyles styles = 2;
   */
  styles?: BookLegacyStyles;
  /**
   * Deprecated meta field (required for loading)
   *
   * @generated from protobuf field: google.protobuf.Struct meta = 3;
   */
  meta?: Struct;
  /**
   * Deprecated bookTypes field (required for loading)
   *
   * @generated from protobuf field: repeated string bookTypes = 4;
   */
  bookTypes: string[];
}
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacy$Type extends MessageType<BookLegacy> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacy', [
      {
        no: 1,
        name: 'parellas',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookLegacyParella,
      },
      { no: 2, name: 'styles', kind: 'message', T: () => BookLegacyStyles },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacy
 */
export const BookLegacy = new BookLegacy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacyStyles$Type extends MessageType<BookLegacyStyles> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacyStyles', [
      {
        no: 1,
        name: 'styles',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookLegacyCSS,
      },
      {
        no: 2,
        name: 'paragraph_classes',
        kind: 'map',
        K: 5 /*ScalarType.INT32*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacyStyles
 */
export const BookLegacyStyles = new BookLegacyStyles$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacyCSS$Type extends MessageType<BookLegacyCSS> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacyCSS', [
      { no: 1, name: 'tag', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'styles',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacyCSS
 */
export const BookLegacyCSS = new BookLegacyCSS$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacyParella$Type extends MessageType<BookLegacyParella> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacyParella', [
      { no: 1, name: 'parella_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'parella_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'parts',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookLegacyPart,
      },
      { no: 4, name: 'words', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacyParella
 */
export const BookLegacyParella = new BookLegacyParella$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacyPart$Type extends MessageType<BookLegacyPart> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacyPart', [
      { no: 1, name: 'part_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'paragraph_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'title',
        kind: 'message',
        oneof: 'contents',
        T: () => BookLegacyPart_Title,
      },
      {
        no: 5,
        name: 'text',
        kind: 'message',
        oneof: 'contents',
        T: () => BookLegacyPart_Text,
      },
      {
        no: 6,
        name: 'image',
        kind: 'message',
        oneof: 'contents',
        T: () => BookLegacyPart_Image,
      },
      {
        no: 7,
        name: 'page',
        kind: 'message',
        oneof: 'contents',
        T: () => BookLegacyPart_PageBreakpoint,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacyPart
 */
export const BookLegacyPart = new BookLegacyPart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacyPart_Title$Type extends MessageType<BookLegacyPart_Title> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacyPart.Title', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'css_class', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacyPart.Title
 */
export const BookLegacyPart_Title = new BookLegacyPart_Title$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacyPart_Image$Type extends MessageType<BookLegacyPart_Image> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacyPart.Image', [
      { no: 1, name: 'source', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacyPart.Image
 */
export const BookLegacyPart_Image = new BookLegacyPart_Image$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacyPart_Text$Type extends MessageType<BookLegacyPart_Text> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacyPart.Text', [
      { no: 1, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'css_class', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacyPart.Text
 */
export const BookLegacyPart_Text = new BookLegacyPart_Text$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacyPart_PageBreakpoint$Type extends MessageType<BookLegacyPart_PageBreakpoint> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacyPart.PageBreakpoint', [
      { no: 1, name: 'page_number', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacyPart.PageBreakpoint
 */
export const BookLegacyPart_PageBreakpoint =
  new BookLegacyPart_PageBreakpoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLegacyImport$Type extends MessageType<BookLegacyImport> {
  constructor() {
    super('sparx.reading.books.v2.BookLegacyImport', [
      {
        no: 1,
        name: 'parellas',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookLegacyParella,
      },
      { no: 2, name: 'styles', kind: 'message', T: () => BookLegacyStyles },
      { no: 3, name: 'meta', kind: 'message', T: () => Struct },
      {
        no: 4,
        name: 'bookTypes',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookLegacyImport
 */
export const BookLegacyImport = new BookLegacyImport$Type();
