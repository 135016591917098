import { clientEventsAtom } from 'components/client-events/atoms';
import { useAtom } from 'jotai';

import styles from './client-events-table.module.css';

export const ClientEventsTable = () => {
  const [events] = useAtom(clientEventsAtom);

  return (
    <table className={styles.Table}>
      <thead>
        <tr>
          <th style={{ width: '25%' }}>Page</th>
          <th style={{ width: '20%' }}>Category</th>
          <th style={{ width: '20%' }}>Action</th>
          <th>Labels</th>
        </tr>
      </thead>

      <tbody>
        {events.map(event => (
          <tr key={event.eventIndex}>
            <td>{event.page}</td>
            <td>{event.category}</td>
            <td>{event.action}</td>
            <td>
              <table className={styles.LabelsTable}>
                <tbody>
                  {Object.entries(event.labels || []).map(([key, value]) => (
                    <tr key={key}>
                      <td style={{ width: '30%' }}>{key}</td>
                      <td>{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
