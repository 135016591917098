import { faIdCard } from '@fortawesome/free-solid-svg-icons';
import { useStaffKeyContacts } from '@sparx/staff-manager/src/queries';

import { Tab } from '../../types';

export const useStaffTab = (): Tab => {
  const { data, isLoading } = useStaffKeyContacts({ suspense: true });

  const isOk = data?.status.status === 'ok';

  return {
    id: 'manage-staff',
    title: 'Manage Staff & Key Contacts',
    subtitle: isOk ? 'Check all contact details are correct' : 'Action required',
    pageSubtitle: isOk ? 'Check all contact details are correct' : 'Action required',
    icon: faIdCard,
    responsibility: 'Sparx Leader & Head of Department',
    hasWarning: !isOk,
    hasSuccess: isOk,
    isLoading,
  };
};
