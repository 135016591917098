// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/tasks/v1/homework.proto" (package "sparx.reading.tasks.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.reading.tasks.v1.StudentHomework
 */
export interface StudentHomework {
  /**
   * @generated from protobuf field: string student_id = 2;
   */
  studentId: string;
  /**
   * @generated from protobuf field: int32 experience = 3;
   */
  experience: number;
  /**
   * @generated from protobuf field: int32 target_experience_override = 4;
   */
  targetExperienceOverride: number;
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.StudentHomeworkStatistics statistics = 5;
   */
  statistics?: StudentHomeworkStatistics;
  /**
   * @generated from protobuf field: bool optional = 6;
   */
  optional: boolean;
  /**
   * @generated from protobuf field: int32 complete_experience = 7;
   */
  completeExperience: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp complete_time = 8;
   */
  completeTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.StudentHomeworkTaskAttempt
 */
export interface StudentHomeworkTaskAttempt {
  /**
   * The ID of the student who attempted this task.
   *
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * The ID of the homework that this task is part of.
   *
   * @generated from protobuf field: string homework_id = 2;
   */
  homeworkId: string;
  /**
   * The ID of the task attempt.
   *
   * @generated from protobuf field: string task_attempt_id = 3;
   */
  taskAttemptId: string;
  /**
   * The amount of experience assigned to the homework from this task attempt.
   *
   * @generated from protobuf field: int32 normal_experience = 4;
   */
  normalExperience: number;
  /**
   * The amount of experience assigned to this homework after it was due.
   *
   * @generated from protobuf field: int32 overdue_experience = 5;
   */
  overdueExperience: number;
  /**
   * The amount of experience assigned to this homework after it was complete.
   *
   * @generated from protobuf field: int32 extra_experience = 6;
   */
  extraExperience: number;
  /**
   * Whether the SRP was shared with another homework.
   *
   * @generated from protobuf field: bool is_shared = 7;
   */
  isShared: boolean;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.Homework
 */
export interface Homework {
  /**
   * @generated from protobuf field: string homework_id = 1;
   */
  homeworkId: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp end_date = 3;
   */
  endDate?: Timestamp;
  /**
   * @generated from protobuf field: int32 target_experience = 4;
   */
  targetExperience: number;
  /**
   * Used if being returned for a student
   *
   * @generated from protobuf field: int32 experience = 5;
   */
  experience: number;
  /**
   * @generated from protobuf field: string student_group_id = 6;
   */
  studentGroupId: string;
  /**
   * Used if being returned for a teacher
   *
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.StudentHomework students = 7;
   */
  students: StudentHomework[];
  /**
   * ID of the homework group that this homework belongs to. If the string
   * is empty then this homework is standalone.
   *
   * @generated from protobuf field: string homework_group_id = 8;
   */
  homeworkGroupId: string;
  /**
   * Type of work this 'homework' is in. For example homework / classwork.
   *
   * @generated from protobuf field: sparx.reading.tasks.v1.WorkType work_type = 9;
   */
  workType: WorkType;
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.StudentHomeworkStatistics
 */
export interface StudentHomeworkStatistics {
  /**
   * @generated from protobuf field: sparx.reading.tasks.v1.StudentStats total = 1;
   */
  total?: StudentStats;
  /**
   * A map from book_id to reading done on that book in this homework.
   *
   * @generated from protobuf field: map<string, sparx.reading.tasks.v1.StudentStats> books = 2;
   */
  books: {
    [key: string]: StudentStats;
  };
}
/**
 * @generated from protobuf message sparx.reading.tasks.v1.StudentStats
 */
export interface StudentStats {
  /**
   * @generated from protobuf field: int32 tasks_attempted = 1;
   */
  tasksAttempted: number;
  /**
   * @generated from protobuf field: int32 tasks_passed_first_time = 2;
   */
  tasksPassedFirstTime: number;
  /**
   * @generated from protobuf field: int32 words_read = 3;
   */
  wordsRead: number;
  /**
   * @generated from protobuf field: int32 pages_read = 4;
   */
  pagesRead: number;
  /**
   * @generated from protobuf field: int32 srp_awarded = 5;
   */
  srpAwarded: number;
}
/**
 * @generated from protobuf enum sparx.reading.tasks.v1.WorkType
 */
export enum WorkType {
  /**
   * @generated from protobuf enum value: WORK_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: WORK_TYPE_HOMEWORK = 1;
   */
  HOMEWORK = 1,
  /**
   * @generated from protobuf enum value: WORK_TYPE_CLASSWORK = 2;
   */
  CLASSWORK = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class StudentHomework$Type extends MessageType<StudentHomework> {
  constructor() {
    super('sparx.reading.tasks.v1.StudentHomework', [
      { no: 2, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'experience', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'target_experience_override',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'statistics',
        kind: 'message',
        T: () => StudentHomeworkStatistics,
      },
      { no: 6, name: 'optional', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 7,
        name: 'complete_experience',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 8, name: 'complete_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.StudentHomework
 */
export const StudentHomework = new StudentHomework$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentHomeworkTaskAttempt$Type extends MessageType<StudentHomeworkTaskAttempt> {
  constructor() {
    super('sparx.reading.tasks.v1.StudentHomeworkTaskAttempt', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'task_attempt_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'normal_experience',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'overdue_experience',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'extra_experience',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 7, name: 'is_shared', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.StudentHomeworkTaskAttempt
 */
export const StudentHomeworkTaskAttempt = new StudentHomeworkTaskAttempt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Homework$Type extends MessageType<Homework> {
  constructor() {
    super('sparx.reading.tasks.v1.Homework', [
      {
        no: 1,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'start_date', kind: 'message', T: () => Timestamp },
      { no: 3, name: 'end_date', kind: 'message', T: () => Timestamp },
      {
        no: 4,
        name: 'target_experience',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 5, name: 'experience', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 6,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 7,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentHomework,
      },
      {
        no: 8,
        name: 'homework_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 9,
        name: 'work_type',
        kind: 'enum',
        T: () => ['sparx.reading.tasks.v1.WorkType', WorkType, 'WORK_TYPE_'],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.Homework
 */
export const Homework = new Homework$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentHomeworkStatistics$Type extends MessageType<StudentHomeworkStatistics> {
  constructor() {
    super('sparx.reading.tasks.v1.StudentHomeworkStatistics', [
      { no: 1, name: 'total', kind: 'message', T: () => StudentStats },
      {
        no: 2,
        name: 'books',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => StudentStats },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.StudentHomeworkStatistics
 */
export const StudentHomeworkStatistics = new StudentHomeworkStatistics$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentStats$Type extends MessageType<StudentStats> {
  constructor() {
    super('sparx.reading.tasks.v1.StudentStats', [
      {
        no: 1,
        name: 'tasks_attempted',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'tasks_passed_first_time',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'words_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'pages_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'srp_awarded', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.tasks.v1.StudentStats
 */
export const StudentStats = new StudentStats$Type();
