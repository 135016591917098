import { Provider } from 'inversify-react';
import { PropsWithChildren } from 'react';

import { CommonParams, CommonParamsContext } from '../context';
import { container } from './container';

export const SparxQueryProvider = ({ children, ...value }: PropsWithChildren<CommonParams>) => (
  <CommonParamsContext.Provider value={value}>
    <Provider container={container}>{children}</Provider>
  </CommonParamsContext.Provider>
);
