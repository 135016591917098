import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from 'components/errors/error-message';
import { useIsUserOnboarded } from 'queries/session';
import { useLockedInPackage } from 'queries/tasks';
import { FCWithChildren } from 'utils/props';
import { View } from 'views';
import { pathForView } from 'views/views';

export const LockedIntoTaskWrapper: FCWithChildren = ({ children }) => {
  const onboarded = useIsUserOnboarded();
  const { data: lockedInPackage } = useLockedInPackage();

  if (!onboarded || lockedInPackage) {
    return <LockedIntoTask />;
  }

  return children;
};

export const LockedIntoTask = () => (
  <ErrorMessage
    message="You cannot access this page at the moment as you are locked into a task"
    title={<FontAwesomeIcon icon={faLock} />}
    backButtonLink={pathForView(View.Library)}
    backButtonText="Back to home"
  >
    Please go back to the home page and complete the task to unlock this view.
  </ErrorMessage>
);
