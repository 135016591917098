import {
  GetUserOnboardingProgressRequest,
  GetUserOnboardingProgressResponse,
} from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { sessionsClient } from 'api';

export const USER_ONBOARDING_PROGRESS_QUERY_KEY = 'user-onboarding-progress';
export const useUserOnboardingProgress = <T = GetUserOnboardingProgressResponse>(
  options?: UseQueryOptions<GetUserOnboardingProgressResponse, Error, T, string[]>,
) => {
  return useQuery(
    [USER_ONBOARDING_PROGRESS_QUERY_KEY],
    () =>
      sessionsClient.getUserOnboardingProgress(GetUserOnboardingProgressRequest.create()).response,
    {
      ...options,
    },
  );
};
