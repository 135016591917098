// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/leaderboards/userdisplay/v1/userdisplay.proto" (package "sparx.leaderboards.userdisplay.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { FieldMask } from '../../../../../google/protobuf/field_mask';
import { Product } from '../../../types/product';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.UserDisplayData
 */
export interface UserDisplayData {
  /**
   * Format: `users/{user_id}/userdisplaydata`
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The time at which the object was last updated (read only)
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 2;
   */
  updateTime?: Timestamp;
  /**
   * The positive noun of the user
   *
   * @generated from protobuf field: string positive_noun = 3;
   */
  positiveNoun: string;
  /**
   * The products which the user has opted out of the leaderboard in
   *
   * @generated from protobuf field: repeated sparx.types.Product opted_out_products = 4;
   */
  optedOutProducts: Product[];
}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.GetUserDisplayDataRequest
 */
export interface GetUserDisplayDataRequest {
  /**
   * users/{usersid}/userdisplaydata
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.GetUserDisplayDataForCurrentUserRequest
 */
export interface GetUserDisplayDataForCurrentUserRequest {}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataRequest
 */
export interface UpdateUserDisplayDataRequest {
  /**
   * The user display data to update
   *
   * @generated from protobuf field: sparx.leaderboards.userdisplay.v1.UserDisplayData user_display_data = 1;
   */
  userDisplayData?: UserDisplayData;
  /**
   * The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataForCurrentUserRequest
 */
export interface UpdateUserDisplayDataForCurrentUserRequest {
  /**
   * The user display data to update. The name field is ignored.
   *
   * @generated from protobuf field: sparx.leaderboards.userdisplay.v1.UserDisplayData user_display_data = 1;
   */
  userDisplayData?: UserDisplayData;
  /**
   * The list of fields to update.
   *
   * @generated from protobuf field: google.protobuf.FieldMask update_mask = 2;
   */
  updateMask?: FieldMask;
}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataRequest
 */
export interface BatchGetUserDisplayDataRequest {
  /**
   * The resource names to get
   *
   * @generated from protobuf field: repeated string names = 1;
   */
  names: string[];
  /**
   * Optional. If set, will only return user display data for users who have
   * not opted out of the leaderboard for this product.
   *
   * @generated from protobuf field: sparx.types.Product product = 2;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataResponse
 */
export interface BatchGetUserDisplayDataResponse {
  /**
   * The user display data
   *
   * @generated from protobuf field: repeated sparx.leaderboards.userdisplay.v1.UserDisplayData user_display_data = 1;
   */
  userDisplayData: UserDisplayData[];
}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.GeneratePositiveNounRequest
 */
export interface GeneratePositiveNounRequest {}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.GeneratePositiveNounResponse
 */
export interface GeneratePositiveNounResponse {
  /**
   * @generated from protobuf field: string positive_noun = 1;
   */
  positiveNoun: string;
}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardRequest
 */
export interface OptOutOfProductLeaderboardRequest {
  /**
   * users/{usersid}/userdisplaydata
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The product to opt out of
   *
   * @generated from protobuf field: sparx.types.Product product = 2;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardResponse
 */
export interface OptOutOfProductLeaderboardResponse {}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardRequest
 */
export interface OptInToProductLeaderboardRequest {
  /**
   * users/{usersid}/userdisplaydata
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The product to opt in to
   *
   * @generated from protobuf field: sparx.types.Product product = 2;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardResponse
 */
export interface OptInToProductLeaderboardResponse {}
/**
 * UserDisplayDataUpdateMessage is used to send update messages to BQ.
 *
 * @generated from protobuf message sparx.leaderboards.userdisplay.v1.UserDisplayDataUpdateMessage
 */
export interface UserDisplayDataUpdateMessage {
  /**
   * @generated from protobuf field: sparx.leaderboards.userdisplay.v1.UserDisplayData user_display_data = 1;
   */
  userDisplayData?: UserDisplayData;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class UserDisplayData$Type extends MessageType<UserDisplayData> {
  constructor() {
    super('sparx.leaderboards.userdisplay.v1.UserDisplayData', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'update_time', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'positive_noun',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'opted_out_products',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.UserDisplayData
 */
export const UserDisplayData = new UserDisplayData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserDisplayDataRequest$Type extends MessageType<GetUserDisplayDataRequest> {
  constructor() {
    super('sparx.leaderboards.userdisplay.v1.GetUserDisplayDataRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.GetUserDisplayDataRequest
 */
export const GetUserDisplayDataRequest = new GetUserDisplayDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserDisplayDataForCurrentUserRequest$Type extends MessageType<GetUserDisplayDataForCurrentUserRequest> {
  constructor() {
    super(
      'sparx.leaderboards.userdisplay.v1.GetUserDisplayDataForCurrentUserRequest',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.GetUserDisplayDataForCurrentUserRequest
 */
export const GetUserDisplayDataForCurrentUserRequest =
  new GetUserDisplayDataForCurrentUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserDisplayDataRequest$Type extends MessageType<UpdateUserDisplayDataRequest> {
  constructor() {
    super('sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataRequest', [
      {
        no: 1,
        name: 'user_display_data',
        kind: 'message',
        T: () => UserDisplayData,
      },
      { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataRequest
 */
export const UpdateUserDisplayDataRequest =
  new UpdateUserDisplayDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserDisplayDataForCurrentUserRequest$Type extends MessageType<UpdateUserDisplayDataForCurrentUserRequest> {
  constructor() {
    super(
      'sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataForCurrentUserRequest',
      [
        {
          no: 1,
          name: 'user_display_data',
          kind: 'message',
          T: () => UserDisplayData,
        },
        { no: 2, name: 'update_mask', kind: 'message', T: () => FieldMask },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataForCurrentUserRequest
 */
export const UpdateUserDisplayDataForCurrentUserRequest =
  new UpdateUserDisplayDataForCurrentUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserDisplayDataRequest$Type extends MessageType<BatchGetUserDisplayDataRequest> {
  constructor() {
    super('sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataRequest', [
      {
        no: 1,
        name: 'names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataRequest
 */
export const BatchGetUserDisplayDataRequest =
  new BatchGetUserDisplayDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserDisplayDataResponse$Type extends MessageType<BatchGetUserDisplayDataResponse> {
  constructor() {
    super('sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataResponse', [
      {
        no: 1,
        name: 'user_display_data',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UserDisplayData,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataResponse
 */
export const BatchGetUserDisplayDataResponse =
  new BatchGetUserDisplayDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneratePositiveNounRequest$Type extends MessageType<GeneratePositiveNounRequest> {
  constructor() {
    super('sparx.leaderboards.userdisplay.v1.GeneratePositiveNounRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.GeneratePositiveNounRequest
 */
export const GeneratePositiveNounRequest =
  new GeneratePositiveNounRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneratePositiveNounResponse$Type extends MessageType<GeneratePositiveNounResponse> {
  constructor() {
    super('sparx.leaderboards.userdisplay.v1.GeneratePositiveNounResponse', [
      {
        no: 1,
        name: 'positive_noun',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.GeneratePositiveNounResponse
 */
export const GeneratePositiveNounResponse =
  new GeneratePositiveNounResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OptOutOfProductLeaderboardRequest$Type extends MessageType<OptOutOfProductLeaderboardRequest> {
  constructor() {
    super(
      'sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardRequest',
      [
        { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: 'product',
          kind: 'enum',
          T: () => ['sparx.types.Product', Product],
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardRequest
 */
export const OptOutOfProductLeaderboardRequest =
  new OptOutOfProductLeaderboardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OptOutOfProductLeaderboardResponse$Type extends MessageType<OptOutOfProductLeaderboardResponse> {
  constructor() {
    super(
      'sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardResponse',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardResponse
 */
export const OptOutOfProductLeaderboardResponse =
  new OptOutOfProductLeaderboardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OptInToProductLeaderboardRequest$Type extends MessageType<OptInToProductLeaderboardRequest> {
  constructor() {
    super(
      'sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardRequest',
      [
        { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: 'product',
          kind: 'enum',
          T: () => ['sparx.types.Product', Product],
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardRequest
 */
export const OptInToProductLeaderboardRequest =
  new OptInToProductLeaderboardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OptInToProductLeaderboardResponse$Type extends MessageType<OptInToProductLeaderboardResponse> {
  constructor() {
    super(
      'sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardResponse',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardResponse
 */
export const OptInToProductLeaderboardResponse =
  new OptInToProductLeaderboardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserDisplayDataUpdateMessage$Type extends MessageType<UserDisplayDataUpdateMessage> {
  constructor() {
    super(
      'sparx.leaderboards.userdisplay.v1.UserDisplayDataUpdateMessage',
      [
        {
          no: 1,
          name: 'user_display_data',
          kind: 'message',
          T: () => UserDisplayData,
        },
        { no: 2, name: 'timestamp', kind: 'message', T: () => Timestamp },
      ],
      {
        'sparx.api.bigquery.v1.table': {
          name: 'user_display_data',
          partitionColumn: 'timestamp',
        },
      },
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.userdisplay.v1.UserDisplayDataUpdateMessage
 */
export const UserDisplayDataUpdateMessage =
  new UserDisplayDataUpdateMessage$Type();
/**
 * @generated ServiceType for protobuf service sparx.leaderboards.userdisplay.v1.UserDisplay
 */
export const UserDisplay = new ServiceType(
  'sparx.leaderboards.userdisplay.v1.UserDisplay',
  [
    {
      name: 'GetUserDisplayData',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'userdisplaydata',
          domain: 'userdisplaydata',
        },
      },
      I: GetUserDisplayDataRequest,
      O: UserDisplayData,
    },
    {
      name: 'GetUserDisplayDataForCurrentUser',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'userdisplaydata',
          domain: 'userdisplaydata/own',
        },
      },
      I: GetUserDisplayDataForCurrentUserRequest,
      O: UserDisplayData,
    },
    {
      name: 'UpdateUserDisplayData',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'userdisplaydata',
          domain: 'userdisplaydata',
        },
      },
      I: UpdateUserDisplayDataRequest,
      O: UserDisplayData,
    },
    {
      name: 'UpdateUserDisplayDataForCurrentUser',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'userdisplaydata',
          domain: 'userdisplaydata/own',
        },
      },
      I: UpdateUserDisplayDataForCurrentUserRequest,
      O: UserDisplayData,
    },
    {
      name: 'BatchGetUserDisplayData',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'userdisplaydata',
          domain: 'userdisplaydata',
        },
      },
      I: BatchGetUserDisplayDataRequest,
      O: BatchGetUserDisplayDataResponse,
    },
    {
      name: 'GeneratePositiveNoun',
      options: {
        'sparx.api.auth': { action: 'read', resource: 'noun', domain: 'noun' },
      },
      I: GeneratePositiveNounRequest,
      O: GeneratePositiveNounResponse,
    },
    {
      name: 'OptOutOfProductLeaderboard',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'userdisplaydata',
          domain: 'userdisplaydata',
        },
      },
      I: OptOutOfProductLeaderboardRequest,
      O: OptOutOfProductLeaderboardResponse,
    },
    {
      name: 'OptInToProductLeaderboard',
      options: {
        'sparx.api.auth': {
          action: 'write',
          resource: 'userdisplaydata',
          domain: 'userdisplaydata',
        },
      },
      I: OptInToProductLeaderboardRequest,
      O: OptInToProductLeaderboardResponse,
    },
  ],
);
