// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/v1/experience.proto" (package "sparx.reading.users.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.reading.users.v1.Experience
 */
export interface Experience {
  /**
   * @generated from protobuf field: int32 experience = 1;
   */
  experience: number;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.ExperienceReward
 */
export interface ExperienceReward {
  /**
   * @generated from protobuf field: int32 experience = 1;
   */
  experience: number;
  /**
   * Currently not stored, only used for client side information. Should equal
   * the total experience rewarded.
   *
   * @generated from protobuf field: repeated sparx.reading.users.v1.ExperienceRewardDetail detail = 2;
   */
  detail: ExperienceRewardDetail[];
  /**
   * Multiplier applied to the awarded experience
   *
   * @generated from protobuf field: double experience_multiplier = 3;
   */
  experienceMultiplier: number;
  /**
   * Experience added to current homework
   *
   * @generated from protobuf field: int32 current_homework_experience = 4;
   */
  currentHomeworkExperience: number;
  /**
   * Experience added to previous homework
   *
   * @generated from protobuf field: int32 previous_homework_experience = 5;
   */
  previousHomeworkExperience: number;
  /**
   * Amount of experience added to homeworks
   *
   * @generated from protobuf field: map<string, int32> homework_rewards = 6;
   */
  homeworkRewards: {
    [key: string]: number;
  };
  /**
   * Banked experience for this tasks book
   *
   * @generated from protobuf field: int32 banked_experience = 7;
   */
  bankedExperience: number;
  /**
   * Whether this reward achieved gold reader
   *
   * @generated from protobuf field: bool gold_reader_achieved = 8;
   */
  goldReaderAchieved: boolean;
  /**
   * The amount and type of experience added to each homework
   *
   * @generated from protobuf field: repeated sparx.reading.users.v1.HomeworkExperience homework_experience = 9;
   */
  homeworkExperience: HomeworkExperience[];
}
/**
 * @generated from protobuf message sparx.reading.users.v1.HomeworkExperience
 */
export interface HomeworkExperience {
  /**
   * @generated from protobuf field: string homework_id = 1;
   */
  homeworkId: string;
  /**
   * @generated from protobuf field: int32 experience_added = 2;
   */
  experienceAdded: number;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.ExperienceType experience_type = 3;
   */
  experienceType: ExperienceType;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.ExperienceRewardDetail
 */
export interface ExperienceRewardDetail {
  /**
   * @generated from protobuf field: int32 experience = 1;
   */
  experience: number;
  /**
   * @generated from protobuf field: string reason = 2;
   */
  reason: string;
  /**
   * @generated from protobuf field: bool focus = 3;
   */
  focus: boolean; // if it is important
  /**
   * @generated from protobuf field: bool skipped = 4;
   */
  skipped: boolean; // it is a tease but they were not rewarded the value
}
/**
 * @generated from protobuf message sparx.reading.users.v1.ExperienceUpdate
 */
export interface ExperienceUpdate {
  /**
   * The new state of the student's experience
   *
   * @generated from protobuf field: sparx.reading.users.v1.Experience new_experience = 1;
   */
  newExperience?: Experience;
  /**
   * Recent experience rewards
   *
   * @generated from protobuf field: repeated sparx.reading.users.v1.ExperienceReward reward = 2;
   */
  reward: ExperienceReward[];
  /**
   * The source of this experience update
   *
   * @generated from protobuf field: sparx.reading.users.v1.ExperienceUpdate.Source source = 3;
   */
  source?: ExperienceUpdate_Source;
  /**
   * @generated from protobuf field: bool gold_reader_state_updated = 9;
   */
  goldReaderStateUpdated: boolean;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.ExperienceUpdate.Source
 */
export interface ExperienceUpdate_Source {
  /**
   * Source type, this can be something like 'task' or 'vocab'
   *
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * Optional context which can be used alongside the source type. For
   * example for a task source this can contain the task id.
   *
   * @generated from protobuf field: string context = 2;
   */
  context: string;
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.ExperienceType
 */
export enum ExperienceType {
  /**
   * @generated from protobuf enum value: NORMAL = 0;
   */
  NORMAL = 0,
  /**
   * @generated from protobuf enum value: EXTRA = 1;
   */
  EXTRA = 1,
  /**
   * @generated from protobuf enum value: OVERDUE = 2;
   */
  OVERDUE = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class Experience$Type extends MessageType<Experience> {
  constructor() {
    super('sparx.reading.users.v1.Experience', [
      { no: 1, name: 'experience', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.Experience
 */
export const Experience = new Experience$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExperienceReward$Type extends MessageType<ExperienceReward> {
  constructor() {
    super('sparx.reading.users.v1.ExperienceReward', [
      { no: 1, name: 'experience', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'detail',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ExperienceRewardDetail,
      },
      {
        no: 3,
        name: 'experience_multiplier',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 4,
        name: 'current_homework_experience',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'previous_homework_experience',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'homework_rewards',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
      {
        no: 7,
        name: 'banked_experience',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 8,
        name: 'gold_reader_achieved',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 9,
        name: 'homework_experience',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => HomeworkExperience,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ExperienceReward
 */
export const ExperienceReward = new ExperienceReward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeworkExperience$Type extends MessageType<HomeworkExperience> {
  constructor() {
    super('sparx.reading.users.v1.HomeworkExperience', [
      {
        no: 1,
        name: 'homework_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'experience_added',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'experience_type',
        kind: 'enum',
        T: () => ['sparx.reading.users.v1.ExperienceType', ExperienceType],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.HomeworkExperience
 */
export const HomeworkExperience = new HomeworkExperience$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExperienceRewardDetail$Type extends MessageType<ExperienceRewardDetail> {
  constructor() {
    super('sparx.reading.users.v1.ExperienceRewardDetail', [
      { no: 1, name: 'experience', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'reason', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'focus', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 4, name: 'skipped', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ExperienceRewardDetail
 */
export const ExperienceRewardDetail = new ExperienceRewardDetail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExperienceUpdate$Type extends MessageType<ExperienceUpdate> {
  constructor() {
    super('sparx.reading.users.v1.ExperienceUpdate', [
      { no: 1, name: 'new_experience', kind: 'message', T: () => Experience },
      {
        no: 2,
        name: 'reward',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => ExperienceReward,
      },
      {
        no: 3,
        name: 'source',
        kind: 'message',
        T: () => ExperienceUpdate_Source,
      },
      {
        no: 9,
        name: 'gold_reader_state_updated',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ExperienceUpdate
 */
export const ExperienceUpdate = new ExperienceUpdate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExperienceUpdate_Source$Type extends MessageType<ExperienceUpdate_Source> {
  constructor() {
    super('sparx.reading.users.v1.ExperienceUpdate.Source', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'context', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ExperienceUpdate.Source
 */
export const ExperienceUpdate_Source = new ExperienceUpdate_Source$Type();
