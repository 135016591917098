import { LeaderboardUser } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import classNames from 'classnames';
import { Link } from 'components/link';
import { IStudentWithExperience } from 'queries/management';

import { positionToAddon } from '../position-to-addon';
import styles from './league-table-row.module.css';

interface ILeagueTableRowProps {
  leaderboardUser: LeaderboardUser;

  // Only set for teacher views
  student?: IStudentWithExperience;

  isLoading?: boolean;
}

export const LeagueTableRow = ({ leaderboardUser, student, isLoading }: ILeagueTableRowProps) => {
  return (
    <div
      className={classNames(styles.Row, {
        [styles.RowYou]: leaderboardUser.isCurrentUser,
        [styles.Row0Experience]: leaderboardUser.xp === 0,
        [styles.RowLoading]: isLoading,
      })}
    >
      <div className={styles.PositionContainer}>
        {leaderboardUser.xp > 0 && positionToAddon[leaderboardUser.rank] ? (
          positionToAddon[leaderboardUser.rank]
        ) : (
          <div className={styles.Position}>
            {leaderboardUser.xp > 0 ? leaderboardUser.rank : '-'}
          </div>
        )}
      </div>
      <div className={styles.RowInformation}>
        {student ? (
          <Link
            to={`/teacher/student/${student.userId}/summary`}
            className={styles.Name}
            analyticsEvent={undefined}
          >
            {student.firstName} {student.lastName}
            <span>
              ({student.firstName} the {leaderboardUser.userDisplayData?.positiveNoun})
            </span>
          </Link>
        ) : (
          <div className={styles.Name}>
            {!isLoading && (
              <>
                {leaderboardUser.firstName} the {leaderboardUser.userDisplayData?.positiveNoun}
              </>
            )}
          </div>
        )}
        <div className={styles.Experience}>
          {leaderboardUser.xp} <span className={styles.SRP}>SRP</span>
        </div>
      </div>
    </div>
  );
};
