import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationWithContext } from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import classNames from 'classnames';
import { useBookNotificationRead } from 'queries/notifications';
import { FCWithOptionalChildren } from 'utils/props';
import { timestampToMoment } from 'utils/time';

import styles from './notification.module.css';

interface Props {
  title: string | React.ReactNode;
  clickMessage?: string;
  hideClickMessage?: boolean;
  onClick?: () => void;
  notification: NotificationWithContext;
}
export interface INotificationWrapperProps {
  notification: NotificationWithContext;
}

export const Notification: FCWithOptionalChildren<Props> = ({
  title,
  clickMessage,
  hideClickMessage = false,
  notification,
  children,
  onClick,
}) => {
  const dateString = timestampToMoment(notification.timestamp)?.fromNow() || 'Unknown';
  const readNotification = useBookNotificationRead();

  return (
    <div
      className={classNames(styles.NotificationPane, {
        [styles.NotificationPaneRead]: notification.dismissed,
      })}
      onClick={() => {
        onClick && onClick();
        if (notification.notification?.notification.oneofKind === 'bankedReward') {
          return; // don't read if banked reward
        }
        readNotification(notification.notificationId);
      }}
    >
      <div className={styles.NotificationPaneNew}>
        {!notification.dismissed && <div className={styles.NotificationNew} />}
      </div>
      <div className={styles.NotificationPaneContent}>
        {dateString && <span className={styles.NotificationTime}>{dateString}</span>}
        <h2>{title}</h2>
        {children && <p>{children}</p>}
        {!hideClickMessage && (
          <div className={styles.ClickMessage}>{clickMessage || 'Take a look'}</div>
        )}
      </div>
      <div className={styles.NotificationPaneCTA}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  );
};
