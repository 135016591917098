// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/books/v2/book_v2.proto" (package "sparx.reading.books.v2", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { BookLegacy } from './book_legacy';
/**
 * Book contains the contents of a book.
 *
 * @generated from protobuf message sparx.reading.books.v2.Book
 */
export interface Book {
  /**
   * Unique ID for the book.
   *
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @generated from protobuf oneof: content
   */
  content:
    | {
        oneofKind: 'reflowable';
        /**
         * Reflowable book content.
         *
         * @generated from protobuf field: sparx.reading.books.v2.BookReflowable reflowable = 2;
         */
        reflowable: BookReflowable;
      }
    | {
        oneofKind: 'legacy';
        /**
         * Legacy book content.
         *
         * @generated from protobuf field: sparx.reading.books.v2.BookLegacy legacy = 3;
         */
        legacy: BookLegacy;
      }
    | {
        oneofKind: undefined;
      };
  /**
   * List of assets that are associated with this book
   *
   * @generated from protobuf field: repeated string assets = 10;
   */
  assets: string[];
}
/**
 * BookReflowable contains a reflowable book. The book is split into three parts:
 * front matter, body and back matter.
 *
 * Each part contains a list of sections which can contain text and images. The
 * text in the book is plain text without html, and is styled through a TextStyle.
 *
 * Each paragraph is split into spans which are selectable blocks of text. Each
 * span can contain a list of text runs which are fragments of styled text.
 *
 * @generated from protobuf message sparx.reading.books.v2.BookReflowable
 */
export interface BookReflowable {
  /**
   * The front matter of a book consists of its very first pages: the title page,
   * copyright page, table of contents, etc. There may also be a preface by the
   * author, or a foreword by someone familiar with their work.
   * This is not usually shown to the student when using reader.
   *
   * @generated from protobuf field: sparx.reading.books.v2.ReflowableMatter front_matter = 1;
   */
  frontMatter?: ReflowableMatter;
  /**
   * The body is the main text that goes between the front matter and back matter.
   * This is the main text that will be shown to the user.
   * It should include any prologues or epilogues that form part of the story.
   *
   * @generated from protobuf field: sparx.reading.books.v2.ReflowableMatter body = 2;
   */
  body?: ReflowableMatter;
  /**
   * The back matter of a book is the final pages that aren't key to the story.
   * Authors use their back matter to offer readers further context or information
   * about the story.
   * This is not usually shown to the student when using reader.
   *
   * @generated from protobuf field: sparx.reading.books.v2.ReflowableMatter back_matter = 3;
   */
  backMatter?: ReflowableMatter;
}
/**
 * ReflowableMatter contains the content for a part of a book.
 *
 * @generated from protobuf message sparx.reading.books.v2.ReflowableMatter
 */
export interface ReflowableMatter {
  /**
   * List of sections in this part of the book.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v2.Section sections = 1;
   */
  sections: Section[];
}
/**
 * @generated from protobuf message sparx.reading.books.v2.Section
 */
export interface Section {
  /**
   * Unique identifier for this section. This should be globally unique in the book.
   *
   * @generated from protobuf field: string section_id = 1;
   */
  sectionId: string;
  /**
   * @generated from protobuf field: sparx.reading.books.v2.SectionType type = 2;
   */
  type: SectionType;
  /**
   * @generated from protobuf oneof: content
   */
  content:
    | {
        oneofKind: 'paragraph';
        /**
         * @generated from protobuf field: sparx.reading.books.v2.Paragraph paragraph = 3;
         */
        paragraph: Paragraph;
      }
    | {
        oneofKind: 'image';
        /**
         * @generated from protobuf field: sparx.reading.books.v2.Image image = 4;
         */
        image: Image;
      }
    | {
        oneofKind: 'separator';
        /**
         * @generated from protobuf field: sparx.reading.books.v2.Separator separator = 5;
         */
        separator: Separator;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * Margins is margin settings for the four directions. They are defined in unit
 * values rather than absolute values so their actual values will depend on the
 * renderer.
 *
 * @generated from protobuf message sparx.reading.books.v2.Margins
 */
export interface Margins {
  /**
   * Margin above the element.
   *
   * @generated from protobuf field: float top = 1;
   */
  top: number;
  /**
   * Margins to the right of the element
   *
   * @generated from protobuf field: float right = 2;
   */
  right: number;
  /**
   * Margins beneath the element.
   *
   * @generated from protobuf field: float bottom = 3;
   */
  bottom: number;
  /**
   * Margins to the left of the element.
   *
   * @generated from protobuf field: float left = 4;
   */
  left: number;
}
/**
 * ParagraphStyle is a style for a paragraph.
 *
 * @generated from protobuf message sparx.reading.books.v2.ParagraphStyle
 */
export interface ParagraphStyle {
  /**
   * @generated from protobuf field: sparx.reading.books.v2.Alignment alignment = 1;
   */
  alignment: Alignment;
  /**
   * @generated from protobuf field: bool drop_cap = 2;
   */
  dropCap: boolean;
  /**
   * Margins defines any additional margins to be applied to this paragraph. They
   * are defined as units rather than absolute values.
   *
   * @generated from protobuf field: sparx.reading.books.v2.Margins margins = 3;
   */
  margins?: Margins;
}
/**
 * Paragraph is a set of spans with a style. They will be grouped together as
 * as a element when rendered. The actual type of the element depends on the
 * parent and is not necessarily a paragraph.
 *
 * @generated from protobuf message sparx.reading.books.v2.Paragraph
 */
export interface Paragraph {
  /**
   * The style to apply to the container of the spans, such as its margins
   * and text alignment. This does not include text styling, such as bold
   * or italics.
   *
   * @generated from protobuf field: sparx.reading.books.v2.ParagraphStyle style = 1;
   */
  style?: ParagraphStyle;
  /**
   * Array of spans which make up the text content.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v2.Span spans = 2;
   */
  spans: Span[];
}
/**
 * Span represents a single block of text in a book. This is most likely
 * a sentence, however it may also contain further delimiters in certain
 * cases, such as if it is contained in speech:
 *
 *   "Quack? I haven't heard it quack before" thought Ryan.
 *
 * The way that the spans are split is the judgment of the user when the
 * book is converted so may not be consistent between books.
 *
 * @generated from protobuf message sparx.reading.books.v2.Span
 */
export interface Span {
  /**
   * Unique identifier for the span. This should be globally unique in the book.
   *
   * @generated from protobuf field: string span_id = 1;
   */
  spanId: string;
  /**
   * Array of text runs for this span.
   *
   * @generated from protobuf field: repeated sparx.reading.books.v2.TextRun runs = 2;
   */
  runs: TextRun[];
  /**
   * Number of words in this span.
   *
   * @generated from protobuf field: int32 word_count = 3;
   */
  wordCount: number;
  /**
   * Used when converting legacy books. This span is a marker that will
   * likely have no content but is used to identify a position in the book.
   *
   * @generated from protobuf field: bool is_anchor = 4;
   */
  isAnchor: boolean;
}
/**
 * TextRun is a single fragment of text within a span alongside a style. The
 * span may be a section of text within a sentence, or a whole sentence.
 *
 * @generated from protobuf message sparx.reading.books.v2.TextRun
 */
export interface TextRun {
  /**
   * Text in this run.
   *
   * @generated from protobuf field: string content = 1;
   */
  content: string;
  /**
   * Style to apply to the text when displaying it.
   *
   * @generated from protobuf field: sparx.reading.books.v2.TextStyle style = 2;
   */
  style?: TextStyle;
}
/**
 * @generated from protobuf message sparx.reading.books.v2.TextStyle
 */
export interface TextStyle {
  /**
   * Display the text in bold font.
   *
   * @generated from protobuf field: bool bold = 1;
   */
  bold: boolean;
  /**
   * Display the text in italics.
   *
   * @generated from protobuf field: bool italic = 2;
   */
  italic: boolean;
  /**
   * Display the text with an underline.
   *
   * @generated from protobuf field: bool underline = 3;
   */
  underline: boolean;
  /**
   * Override the font face.
   *
   * @generated from protobuf field: sparx.reading.books.v2.FontFace font_face = 4;
   */
  fontFace: FontFace;
  /**
   * Display the text with a strikethrough
   *
   * @generated from protobuf field: bool strikethrough = 5;
   */
  strikethrough: boolean;
  /**
   * Font variant
   *
   * @generated from protobuf field: sparx.reading.books.v2.FontVariant font_variant = 6;
   */
  fontVariant: FontVariant;
  /**
   * Hide the text from view.
   *
   * @generated from protobuf field: bool visibility_hidden = 7;
   */
  visibilityHidden: boolean;
}
/**
 * @generated from protobuf message sparx.reading.books.v2.ImageStyle
 */
export interface ImageStyle {
  /**
   * What size to display the image a book.
   *
   * @generated from protobuf field: sparx.reading.books.v2.ImageSize size = 1;
   */
  size: ImageSize;
  /**
   * Alignment of the image in the book.
   *
   * @generated from protobuf field: sparx.reading.books.v2.Alignment alignment = 2;
   */
  alignment: Alignment;
}
/**
 * Image contains information about an image in a book and how to display it.
 *
 * @generated from protobuf message sparx.reading.books.v2.Image
 */
export interface Image {
  /**
   * Alternative text description of the image for visually impaired readers.
   *
   * @generated from protobuf field: string alt_text = 1;
   */
  altText: string;
  /**
   * Information about where the image can be found.
   *
   * @generated from protobuf field: sparx.reading.books.v2.ImageSource source = 2;
   */
  source?: ImageSource;
  /**
   * Styling information on how to display the image.
   *
   * @generated from protobuf field: sparx.reading.books.v2.ImageStyle style = 3;
   */
  style?: ImageStyle;
}
/**
 * ImageSource is information about where an image can be sourced.
 *
 * @generated from protobuf message sparx.reading.books.v2.ImageSource
 */
export interface ImageSource {
  /**
   * @generated from protobuf oneof: source
   */
  source:
    | {
        oneofKind: 'imageId';
        /**
         * Unique ID of the image.
         *
         * @generated from protobuf field: string image_id = 1;
         */
        imageId: string;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * Separator denotes a separator in a book.
 *
 * @generated from protobuf message sparx.reading.books.v2.Separator
 */
export interface Separator {}
/**
 * @generated from protobuf enum sparx.reading.books.v2.SectionType
 */
export enum SectionType {
  /**
   * @generated from protobuf enum value: SECTION_TYPE_UNSPECIFIED = 0;
   */
  SECTION_TYPE_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: CHAPTER_TITLE = 1;
   */
  CHAPTER_TITLE = 1,
  /**
   * @generated from protobuf enum value: CHAPTER_SUBTITLE = 2;
   */
  CHAPTER_SUBTITLE = 2,
  /**
   * @generated from protobuf enum value: NORMAL = 3;
   */
  NORMAL = 3,
  /**
   * @generated from protobuf enum value: SUBHEADING = 4;
   */
  SUBHEADING = 4,
  /**
   * @generated from protobuf enum value: BLOCK_QUOTE = 5;
   */
  BLOCK_QUOTE = 5,
  /**
   * @generated from protobuf enum value: POEM = 6;
   */
  POEM = 6,
  /**
   * A section break is a break in the text. It can have a separator but
   * can also have no content.
   *
   *  OPENING_QUOTE = 9;
   *  OPENING_QUOTE_CREDIT = 10;
   * TODO: lists?
   *
   * @generated from protobuf enum value: BREAK = 7;
   */
  BREAK = 7,
}
/**
 * FontFace is a choice of font face for some text. The actual fonts used
 * depends on the viewer and their settings.
 *
 * @generated from protobuf enum sparx.reading.books.v2.FontFace
 */
export enum FontFace {
  /**
   * Default font face.
   *
   * @generated from protobuf enum value: FONT_FACE_UNSPECIFIED = 0;
   */
  FONT_FACE_UNSPECIFIED = 0,
  /**
   * Text will be displayed in a serif font.
   *
   * @generated from protobuf enum value: SERIF = 1;
   */
  SERIF = 1,
  /**
   * Text will be displayed in a sans-serif font.
   *
   * @generated from protobuf enum value: SANS_SERIF = 2;
   */
  SANS_SERIF = 2,
  /**
   * Text will be displayed in a monospace font.
   *
   * @generated from protobuf enum value: MONOSPACE = 3;
   */
  MONOSPACE = 3,
  /**
   * Wingdings for special characters
   *
   * @generated from protobuf enum value: WINGDINGS = 4;
   */
  WINGDINGS = 4,
}
/**
 * @generated from protobuf enum sparx.reading.books.v2.FontVariant
 */
export enum FontVariant {
  /**
   * Default font variant.
   *
   * @generated from protobuf enum value: FONT_VARIANT_UNSPECIFIED = 0;
   */
  FONT_VARIANT_UNSPECIFIED = 0,
  /**
   * Small caps variant.
   *
   * @generated from protobuf enum value: SMALL_CAPS = 1;
   */
  SMALL_CAPS = 1,
}
/**
 * @generated from protobuf enum sparx.reading.books.v2.ImageSize
 */
export enum ImageSize {
  /**
   * @generated from protobuf enum value: IMAGE_SIZE_UNSPECIFIED = 0;
   */
  IMAGE_SIZE_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: SMALL = 1;
   */
  SMALL = 1,
  /**
   * @generated from protobuf enum value: MEDIUM = 2;
   */
  MEDIUM = 2,
  /**
   * @generated from protobuf enum value: LARGE = 3;
   */
  LARGE = 3,
}
/**
 * @generated from protobuf enum sparx.reading.books.v2.Alignment
 */
export enum Alignment {
  /**
   * @generated from protobuf enum value: ALIGNMENT_UNSPECIFIED = 0;
   */
  ALIGNMENT_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: LEFT = 1;
   */
  LEFT = 1,
  /**
   * @generated from protobuf enum value: CENTER = 2;
   */
  CENTER = 2,
  /**
   * @generated from protobuf enum value: RIGHT = 3;
   */
  RIGHT = 3,
  /**
   * @generated from protobuf enum value: FULL = 4;
   */
  FULL = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class Book$Type extends MessageType<Book> {
  constructor() {
    super('sparx.reading.books.v2.Book', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'reflowable',
        kind: 'message',
        oneof: 'content',
        T: () => BookReflowable,
      },
      {
        no: 3,
        name: 'legacy',
        kind: 'message',
        oneof: 'content',
        T: () => BookLegacy,
      },
      {
        no: 10,
        name: 'assets',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.Book
 */
export const Book = new Book$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookReflowable$Type extends MessageType<BookReflowable> {
  constructor() {
    super('sparx.reading.books.v2.BookReflowable', [
      {
        no: 1,
        name: 'front_matter',
        kind: 'message',
        T: () => ReflowableMatter,
      },
      { no: 2, name: 'body', kind: 'message', T: () => ReflowableMatter },
      {
        no: 3,
        name: 'back_matter',
        kind: 'message',
        T: () => ReflowableMatter,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.BookReflowable
 */
export const BookReflowable = new BookReflowable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReflowableMatter$Type extends MessageType<ReflowableMatter> {
  constructor() {
    super('sparx.reading.books.v2.ReflowableMatter', [
      {
        no: 1,
        name: 'sections',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Section,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.ReflowableMatter
 */
export const ReflowableMatter = new ReflowableMatter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Section$Type extends MessageType<Section> {
  constructor() {
    super('sparx.reading.books.v2.Section', [
      { no: 1, name: 'section_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.reading.books.v2.SectionType', SectionType],
      },
      {
        no: 3,
        name: 'paragraph',
        kind: 'message',
        oneof: 'content',
        T: () => Paragraph,
      },
      {
        no: 4,
        name: 'image',
        kind: 'message',
        oneof: 'content',
        T: () => Image,
      },
      {
        no: 5,
        name: 'separator',
        kind: 'message',
        oneof: 'content',
        T: () => Separator,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.Section
 */
export const Section = new Section$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Margins$Type extends MessageType<Margins> {
  constructor() {
    super('sparx.reading.books.v2.Margins', [
      { no: 1, name: 'top', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 2, name: 'right', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 3, name: 'bottom', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 4, name: 'left', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.Margins
 */
export const Margins = new Margins$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParagraphStyle$Type extends MessageType<ParagraphStyle> {
  constructor() {
    super('sparx.reading.books.v2.ParagraphStyle', [
      {
        no: 1,
        name: 'alignment',
        kind: 'enum',
        T: () => ['sparx.reading.books.v2.Alignment', Alignment],
      },
      { no: 2, name: 'drop_cap', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'margins', kind: 'message', T: () => Margins },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.ParagraphStyle
 */
export const ParagraphStyle = new ParagraphStyle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Paragraph$Type extends MessageType<Paragraph> {
  constructor() {
    super('sparx.reading.books.v2.Paragraph', [
      { no: 1, name: 'style', kind: 'message', T: () => ParagraphStyle },
      {
        no: 2,
        name: 'spans',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Span,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.Paragraph
 */
export const Paragraph = new Paragraph$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Span$Type extends MessageType<Span> {
  constructor() {
    super('sparx.reading.books.v2.Span', [
      { no: 1, name: 'span_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'runs',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => TextRun,
      },
      { no: 3, name: 'word_count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'is_anchor', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.Span
 */
export const Span = new Span$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextRun$Type extends MessageType<TextRun> {
  constructor() {
    super('sparx.reading.books.v2.TextRun', [
      { no: 1, name: 'content', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'style', kind: 'message', T: () => TextStyle },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.TextRun
 */
export const TextRun = new TextRun$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextStyle$Type extends MessageType<TextStyle> {
  constructor() {
    super('sparx.reading.books.v2.TextStyle', [
      { no: 1, name: 'bold', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'italic', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'underline', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 4,
        name: 'font_face',
        kind: 'enum',
        T: () => ['sparx.reading.books.v2.FontFace', FontFace],
      },
      {
        no: 5,
        name: 'strikethrough',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 6,
        name: 'font_variant',
        kind: 'enum',
        T: () => ['sparx.reading.books.v2.FontVariant', FontVariant],
      },
      {
        no: 7,
        name: 'visibility_hidden',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.TextStyle
 */
export const TextStyle = new TextStyle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageStyle$Type extends MessageType<ImageStyle> {
  constructor() {
    super('sparx.reading.books.v2.ImageStyle', [
      {
        no: 1,
        name: 'size',
        kind: 'enum',
        T: () => ['sparx.reading.books.v2.ImageSize', ImageSize],
      },
      {
        no: 2,
        name: 'alignment',
        kind: 'enum',
        T: () => ['sparx.reading.books.v2.Alignment', Alignment],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.ImageStyle
 */
export const ImageStyle = new ImageStyle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Image$Type extends MessageType<Image> {
  constructor() {
    super('sparx.reading.books.v2.Image', [
      { no: 1, name: 'alt_text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'source', kind: 'message', T: () => ImageSource },
      { no: 3, name: 'style', kind: 'message', T: () => ImageStyle },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.Image
 */
export const Image = new Image$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageSource$Type extends MessageType<ImageSource> {
  constructor() {
    super('sparx.reading.books.v2.ImageSource', [
      {
        no: 1,
        name: 'image_id',
        kind: 'scalar',
        oneof: 'source',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.ImageSource
 */
export const ImageSource = new ImageSource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Separator$Type extends MessageType<Separator> {
  constructor() {
    super('sparx.reading.books.v2.Separator', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.books.v2.Separator
 */
export const Separator = new Separator$Type();
