import { AnalyticEventFields } from 'components/client-events/client-event-provider';

const CATEGORY = 'book-complete-view';

export const clickedDashboardButton = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked dashboard button',
});

export const clickedChooseBookButton = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked choose book button',
});
