import { useSearchParams } from 'react-router-dom';

type Setter = (book?: { name: string }) => void;

export const useStartBookDialog = (): [string | null, Setter] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const bookId = searchParams.get('book');

  const setter: Setter = book => {
    if (book && book.name) {
      searchParams.set('book', book.name.replace('books/', ''));
    } else {
      searchParams.delete('book');
    }
    setSearchParams(searchParams);
  };

  return [bookId, setter];
};
