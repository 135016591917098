import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { useUser } from 'queries/session';
import { useEffect } from 'react';

const teacherComponentIDs: string[] = ['01HY0PXS8YJM4231G2264DCBAM'];
const studentComponentIDs: string[] = ['01HY0PXS8YF5BQ4MTHXAD4EZFM'];

declare global {
  interface Window {
    // Status Embed variables
    // IDs of components to show alerts for
    spxStatusEmbedComponentIDs?: string[];
    // Function to update the list of components to show alerts for, and
    // trigger the alert to update if needed
    spxStatusEmbedUpdateComponents?: (componentIDs: string[]) => void;
  }
}

const updateComponents = (newIDs: string[]) => {
  if (window.spxStatusEmbedUpdateComponents) {
    window.spxStatusEmbedUpdateComponents(newIDs);
  } else {
    // Embed hasn't started yet
    window.spxStatusEmbedComponentIDs = newIDs;
  }
};

// Hook to configure the components to display based on the user type
export const useConfigureStatusEmbed = () => {
  const user = useUser();

  useEffect(() => {
    if (user?.type === UserType.STUDENT) {
      updateComponents(studentComponentIDs);
    } else if (user?.type === UserType.TEACHER) {
      updateComponents(teacherComponentIDs);
    }
  }, [user?.type]);
};
