import {
  NotificationWhatsNew,
  NotificationWithContext,
} from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import { pathForView } from 'views/views';

import { Notification } from './notification';

type Props = {
  notification: NotificationWithContext;
  whatsNew: NotificationWhatsNew;
};

export const WhatsNewNotification = ({ notification, whatsNew }: Props) => {
  const navigate = useNavigate();

  return (
    <Notification
      title={whatsNew.title}
      notification={notification}
      onClick={() =>
        navigate({
          pathname: pathForView(View.WhatsNew),
          search: `?highlight=${whatsNew.postId}`,
        })
      }
    >
      {whatsNew.text}
    </Notification>
  );
};
