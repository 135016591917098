// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/content/v1/service.proto" (package "sparx.reading.content.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Book as Book$ } from '../../books/v1/book';
import { Breakpoint } from '../../bookkeeper/v1/bookquestion';
import { Question } from '../../bookkeeper/v1/bookquestion';
import { Book } from '../../books/v2/book_v2';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.reading.content.v1.StudentBook
 */
export interface StudentBook {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string book_id = 2;
   */
  bookId: string;
  /**
   * @generated from protobuf field: int32 attempt_index = 16;
   */
  attemptIndex: number;
  /**
   * @generated from protobuf field: string package_id = 17;
   */
  packageId: string;
  /**
   * @generated from protobuf field: float progress = 3;
   */
  progress: number;
  /**
   * @generated from protobuf field: bool recommendation = 4;
   */
  recommendation: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp started_timestamp = 5;
   */
  startedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp finished_timestamp = 6;
   */
  finishedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_read_timestamp = 7;
   */
  lastReadTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: int32 experience = 8;
   */
  experience: number;
  /**
   * @generated from protobuf field: int32 banked_experience = 14;
   */
  bankedExperience: number;
  /**
   * @generated from protobuf field: int32 rating = 9;
   */
  rating: number;
  /**
   * @generated from protobuf field: int32 difficulty_rating = 10;
   */
  difficultyRating: number;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.StudentBook.StudentBookExtra extra = 11;
   */
  extra?: StudentBook_StudentBookExtra;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.StudentBook.SwapState swapped = 12;
   */
  swapped: StudentBook_SwapState;
  /**
   * Set to true if there is no more content available for the student to read
   *
   * @generated from protobuf field: bool at_content_end = 13;
   */
  atContentEnd: boolean;
  /**
   * Set to true if the student can re-read the book
   *
   * @deprecated
   * @generated from protobuf field: bool can_restart = 15 [deprecated = true];
   */
  canRestart: boolean;
  /**
   * Book title
   *
   * @generated from protobuf field: string title = 18;
   */
  title: string;
  /**
   * Is the student currently reading the book
   *
   * @generated from protobuf field: bool is_active = 19;
   */
  isActive: boolean;
  /**
   * Whether this is an ebook
   *
   * @generated from protobuf field: bool is_ebook = 20;
   */
  isEbook: boolean;
  /**
   * Whether the book is complete
   *
   * @generated from protobuf field: bool is_complete = 21;
   */
  isComplete: boolean;
  /**
   * Whether the book is either high or low onboarding
   *
   * @deprecated
   * @generated from protobuf field: bool is_onboarding = 22 [deprecated = true];
   */
  isOnboarding: boolean;
  /**
   * Whether the book is a training book
   *
   * @generated from protobuf field: bool is_training = 23;
   */
  isTraining: boolean;
  /**
   * Whether the student's chronological age is below the minimum age for the book
   *
   * @generated from protobuf field: bool has_min_age_above_student_age = 24;
   */
  hasMinAgeAboveStudentAge: boolean;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.StudentBook.TeacherSwapState teacher_swapped = 25;
   */
  teacherSwapped: StudentBook_TeacherSwapState;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.StudentBook.StudentBookExtra
 */
export interface StudentBook_StudentBookExtra {
  /**
   * @generated from protobuf field: int32 incorrect_streak = 1;
   */
  incorrectStreak: number;
  /**
   * @generated from protobuf field: int32 sections_skipped = 2;
   */
  sectionsSkipped: number;
  /**
   * @generated from protobuf field: int32 tasks_attempted = 3;
   */
  tasksAttempted: number;
  /**
   * @generated from protobuf field: int32 tasks_attempted_passed_first_time = 4;
   */
  tasksAttemptedPassedFirstTime: number;
  /**
   * Last part id that the student has read up to
   *
   * @generated from protobuf field: string last_part_id = 5;
   */
  lastPartId: string;
  /**
   * Last page index that the student has read up to
   *
   * @generated from protobuf field: int32 last_page_index = 6;
   */
  lastPageIndex: number;
}
/**
 * @generated from protobuf enum sparx.reading.content.v1.StudentBook.SwapState
 */
export enum StudentBook_SwapState {
  /**
   * @generated from protobuf enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STATE_KEPT = 1;
   */
  STATE_KEPT = 1,
  /**
   * @generated from protobuf enum value: STATE_SWAPPED = 2;
   */
  STATE_SWAPPED = 2,
}
/**
 * If the book has been swapped by a teacher, the kind of swap
 *
 * @generated from protobuf enum sparx.reading.content.v1.StudentBook.TeacherSwapState
 */
export enum StudentBook_TeacherSwapState {
  /**
   * @generated from protobuf enum value: TEACHER_SWAP_UNSPECIFIED = 0;
   */
  TEACHER_SWAP_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: TEACHER_SWAP_LEVEL_SWAP = 1;
   */
  TEACHER_SWAP_LEVEL_SWAP = 1,
  /**
   * @generated from protobuf enum value: TEACHER_SWAP_MANUAL_SWAP = 2;
   */
  TEACHER_SWAP_MANUAL_SWAP = 2,
  /**
   * @generated from protobuf enum value: TEACHER_SWAP_PERMANENT_REMOVAL = 3;
   */
  TEACHER_SWAP_PERMANENT_REMOVAL = 3,
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetBookRequest
 */
export interface GetBookRequest {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @generated from protobuf field: string task_id = 2;
   */
  taskId: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetBookResponse
 */
export interface GetBookResponse {
  /**
   * @generated from protobuf field: sparx.reading.books.v2.Book book_v2 = 2;
   */
  bookV2?: Book;
  /**
   * List of questions for the book. Only populated for admins.
   *
   * @generated from protobuf field: repeated sparx.reading.bookkeeper.v1.Question questions = 3;
   */
  questions: Question[];
  /**
   * List of breakpoints for the book. Only populated for admins.
   *
   * @generated from protobuf field: repeated sparx.reading.bookkeeper.v1.Breakpoint breakpoints = 4;
   */
  breakpoints: Breakpoint[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetAvailableBooksRequest
 */
export interface GetAvailableBooksRequest {
  /**
   * If provided, will select the available books for the given user.
   * This field will default to the current authenticated user so should
   * not be provided unless the user is an admin.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetAvailableBooksResponse
 */
export interface GetAvailableBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.books.v1.Book books = 1;
   */
  books: Book$[];
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.StudentBook student_books = 2;
   */
  studentBooks: StudentBook[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.CheckBookLockRequest
 */
export interface CheckBookLockRequest {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.CheckBookLockResponse
 */
export interface CheckBookLockResponse {}
/**
 * @generated from protobuf message sparx.reading.content.v1.EditableQuestionOption
 */
export interface EditableQuestionOption {
  /**
   * @generated from protobuf field: string option_id = 1;
   */
  optionId: string;
  /**
   * @generated from protobuf field: string text = 2;
   */
  text: string;
  /**
   * @generated from protobuf field: bool correct = 3;
   */
  correct: boolean;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.EditableQuestion
 */
export interface EditableQuestion {
  /**
   * @generated from protobuf field: string question_id = 1;
   */
  questionId: string;
  /**
   * @generated from protobuf field: string question_text = 2;
   */
  questionText: string;
  /**
   * @generated from protobuf field: int32 priority = 3;
   */
  priority: number;
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.EditableQuestionOption options = 4;
   */
  options: EditableQuestionOption[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.BookAnnotationQuestion
 */
export interface BookAnnotationQuestion {
  /**
   * @generated from protobuf field: sparx.reading.content.v1.EditableQuestion question = 1;
   */
  question?: EditableQuestion;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.EditableQuestion shadow_question = 2;
   */
  shadowQuestion?: EditableQuestion;
  /**
   * @generated from protobuf field: string admin_notes = 3;
   */
  adminNotes: string;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.EditableQuestion proofread_question = 4;
   */
  proofreadQuestion?: EditableQuestion;
  /**
   * @generated from protobuf field: string proofread_notes = 5;
   */
  proofreadNotes: string;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.ProofreadState proofread_state = 6;
   */
  proofreadState: ProofreadState;
  /**
   * @generated from protobuf field: repeated string proofread_categories = 7;
   */
  proofreadCategories: string[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.BookAnnotationContent
 */
export interface BookAnnotationContent {
  /**
   * @generated from protobuf oneof: content
   */
  content:
    | {
        oneofKind: 'question';
        /**
         * @generated from protobuf field: sparx.reading.content.v1.BookAnnotationQuestion question = 3;
         */
        question: BookAnnotationQuestion;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.content.v1.BookAnnotation
 */
export interface BookAnnotation {
  /**
   * @generated from protobuf field: string book_annotation_id = 1;
   */
  bookAnnotationId: string;
  /**
   * @generated from protobuf field: string part_id = 2;
   */
  partId: string;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.BookAnnotationContent content = 3;
   */
  content?: BookAnnotationContent;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.CustomBookQuestion
 */
export interface CustomBookQuestion {
  /**
   * @generated from protobuf field: string question_id = 1;
   */
  questionId: string;
  /**
   * @generated from protobuf field: string book_id = 2;
   */
  bookId: string;
  /**
   * @generated from protobuf field: double proportion = 3;
   */
  proportion: number;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.EditableQuestion question = 4;
   */
  question?: EditableQuestion;
  /**
   * @generated from protobuf field: bool ready = 5;
   */
  ready: boolean;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetBookAnnotationsRequest
 */
export interface GetBookAnnotationsRequest {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetBookAnnotationsResponse
 */
export interface GetBookAnnotationsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.BookAnnotation annotations = 1;
   */
  annotations: BookAnnotation[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.CommitBookAnnotationRequest
 */
export interface CommitBookAnnotationRequest {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.BookAnnotation annotation = 2;
   */
  annotation?: BookAnnotation;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.CommitBookAnnotationRequest.Action action = 3;
   */
  action: CommitBookAnnotationRequest_Action;
  /**
   * @generated from protobuf field: optional int32 word_count = 4;
   */
  wordCount?: number;
}
/**
 * @generated from protobuf enum sparx.reading.content.v1.CommitBookAnnotationRequest.Action
 */
export enum CommitBookAnnotationRequest_Action {
  /**
   * @generated from protobuf enum value: ACTION_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ACTION_CREATE = 1;
   */
  CREATE = 1,
  /**
   * @generated from protobuf enum value: ACTION_DELETE = 2;
   */
  DELETE = 2,
  /**
   * @generated from protobuf enum value: ACTION_UPDATE = 3;
   */
  UPDATE = 3,
}
/**
 * @generated from protobuf message sparx.reading.content.v1.CommitBookAnnotationResponse
 */
export interface CommitBookAnnotationResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.BookAnnotation annotations = 1;
   */
  annotations: BookAnnotation[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetCustomBookRequest
 */
export interface GetCustomBookRequest {
  /**
   * @generated from protobuf field: string isbn = 1;
   */
  isbn: string;
  /**
   * @generated from protobuf field: string input_method = 2;
   */
  inputMethod: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetCustomBookResponse
 */
export interface GetCustomBookResponse {
  /**
   * @generated from protobuf field: sparx.reading.books.v1.Book book = 1;
   */
  book?: Book$;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.AssignCustomBookRequest
 */
export interface AssignCustomBookRequest {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @generated from protobuf field: int32 page_count = 2;
   */
  pageCount: number; // this is end_page
  /**
   * @generated from protobuf field: int32 start_page = 3;
   */
  startPage: number;
  /**
   * ISBN used to request this book.
   *
   * @generated from protobuf field: string isbn = 4;
   */
  isbn: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.AssignCustomBookResponse
 */
export interface AssignCustomBookResponse {}
/**
 * @generated from protobuf message sparx.reading.content.v1.ListCustomBooksRequest
 */
export interface ListCustomBooksRequest {}
/**
 * @generated from protobuf message sparx.reading.content.v1.ListCustomBooksResponse
 */
export interface ListCustomBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.CustomBookQuestion questions = 3;
   */
  questions: CustomBookQuestion[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.UpsertCustomBookQuestionsRequest
 */
export interface UpsertCustomBookQuestionsRequest {
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.CustomBookQuestion questions = 1;
   */
  questions: CustomBookQuestion[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.UpsertCustomBookQuestionsResponse
 */
export interface UpsertCustomBookQuestionsResponse {}
/**
 * @generated from protobuf message sparx.reading.content.v1.AdminStudentBook
 */
export interface AdminStudentBook {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string book_id = 2;
   */
  bookId: string;
  /**
   * @generated from protobuf field: int32 attempt_index = 3;
   */
  attemptIndex: number;
  /**
   * @generated from protobuf field: int32 banked_srp = 4;
   */
  bankedSrp: number;
  /**
   * @generated from protobuf field: float progress = 5;
   */
  progress: number;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.ListAdminStudentBooksRequest
 */
export interface ListAdminStudentBooksRequest {}
/**
 * @generated from protobuf message sparx.reading.content.v1.ListAdminStudentBooksResponse
 */
export interface ListAdminStudentBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.AdminStudentBook admin_student_books = 1;
   */
  adminStudentBooks: AdminStudentBook[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.SetAdminStudentBookRequest
 */
export interface SetAdminStudentBookRequest {
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.AdminStudentBookResponse choices = 1;
   */
  choices: AdminStudentBookResponse[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.AdminStudentBookResponse
 */
export interface AdminStudentBookResponse {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string book_id = 2;
   */
  bookId: string;
  /**
   * @generated from protobuf field: int32 attempt_index = 3;
   */
  attemptIndex: number;
  /**
   * @generated from protobuf field: int32 srp = 4;
   */
  srp: number;
  /**
   * @generated from protobuf field: string decision = 5;
   */
  decision: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.SetAdminStudentBookResponse
 */
export interface SetAdminStudentBookResponse {}
/**
 * @generated from protobuf message sparx.reading.content.v1.CreateAdminStudentBookRequest
 */
export interface CreateAdminStudentBookRequest {
  /**
   * @generated from protobuf field: repeated string user_ids = 1;
   */
  userIds: string[];
  /**
   * @generated from protobuf field: string book_id = 2;
   */
  bookId: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.CreateAdminStudentBookResponse
 */
export interface CreateAdminStudentBookResponse {}
/**
 * @generated from protobuf message sparx.reading.content.v1.UpdateBookkeeperQuestionsRequest
 */
export interface UpdateBookkeeperQuestionsRequest {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookkeeper.v1.Breakpoint breakpoints = 1;
   */
  breakpoints: Breakpoint[];
  /**
   * @generated from protobuf field: repeated sparx.reading.bookkeeper.v1.Question questions = 2;
   */
  questions: Question[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.UpdateBookkeeperQuestionsResponse
 */
export interface UpdateBookkeeperQuestionsResponse {}
/**
 * @generated from protobuf message sparx.reading.content.v1.ApproveAllAnnotationsRequest
 */
export interface ApproveAllAnnotationsRequest {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.ApproveAllAnnotationsResponse
 */
export interface ApproveAllAnnotationsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.BookAnnotation annotations = 1;
   */
  annotations: BookAnnotation[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.DeleteQuestionRequest
 */
export interface DeleteQuestionRequest {
  /**
   * @generated from protobuf field: string question_name = 1;
   */
  questionName: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.DeleteQuestionResponse
 */
export interface DeleteQuestionResponse {}
/**
 * @generated from protobuf message sparx.reading.content.v1.SoftDeleteBreakpointRequest
 */
export interface SoftDeleteBreakpointRequest {
  /**
   * @generated from protobuf field: string breakpoint_name = 1;
   */
  breakpointName: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.SoftDeleteBreakpointResponse
 */
export interface SoftDeleteBreakpointResponse {}
/**
 * @generated from protobuf enum sparx.reading.content.v1.ProofreadState
 */
export enum ProofreadState {
  /**
   * @generated from protobuf enum value: PROOFREAD_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: PROOFREAD_STATE_APPROVED = 1;
   */
  APPROVED = 1,
  /**
   * @generated from protobuf enum value: PROOFREAD_STATE_REJECTED = 2;
   */
  REJECTED = 2,
  /**
   * @generated from protobuf enum value: PROOFREAD_STATE_APPROVED_WITH_EDITS = 3;
   */
  APPROVED_WITH_EDITS = 3,
  /**
   * @generated from protobuf enum value: PROOFREAD_STATE_REJECTED_WITH_REPLACEMENT = 4;
   */
  REJECTED_WITH_REPLACEMENT = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class StudentBook$Type extends MessageType<StudentBook> {
  constructor() {
    super('sparx.reading.content.v1.StudentBook', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 16,
        name: 'attempt_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 17,
        name: 'package_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'progress', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      {
        no: 4,
        name: 'recommendation',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 5, name: 'started_timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 6,
        name: 'finished_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 7,
        name: 'last_read_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 8, name: 'experience', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 14,
        name: 'banked_experience',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 9, name: 'rating', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 10,
        name: 'difficulty_rating',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 11,
        name: 'extra',
        kind: 'message',
        T: () => StudentBook_StudentBookExtra,
      },
      {
        no: 12,
        name: 'swapped',
        kind: 'enum',
        T: () => [
          'sparx.reading.content.v1.StudentBook.SwapState',
          StudentBook_SwapState,
        ],
      },
      {
        no: 13,
        name: 'at_content_end',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 15, name: 'can_restart', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 18, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 19, name: 'is_active', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 20, name: 'is_ebook', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 21, name: 'is_complete', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 22,
        name: 'is_onboarding',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 23, name: 'is_training', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 24,
        name: 'has_min_age_above_student_age',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 25,
        name: 'teacher_swapped',
        kind: 'enum',
        T: () => [
          'sparx.reading.content.v1.StudentBook.TeacherSwapState',
          StudentBook_TeacherSwapState,
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.StudentBook
 */
export const StudentBook = new StudentBook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StudentBook_StudentBookExtra$Type extends MessageType<StudentBook_StudentBookExtra> {
  constructor() {
    super('sparx.reading.content.v1.StudentBook.StudentBookExtra', [
      {
        no: 1,
        name: 'incorrect_streak',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'sections_skipped',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'tasks_attempted',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'tasks_attempted_passed_first_time',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'last_part_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'last_page_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.StudentBook.StudentBookExtra
 */
export const StudentBook_StudentBookExtra =
  new StudentBook_StudentBookExtra$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookRequest$Type extends MessageType<GetBookRequest> {
  constructor() {
    super('sparx.reading.content.v1.GetBookRequest', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'task_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetBookRequest
 */
export const GetBookRequest = new GetBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookResponse$Type extends MessageType<GetBookResponse> {
  constructor() {
    super('sparx.reading.content.v1.GetBookResponse', [
      { no: 2, name: 'book_v2', kind: 'message', T: () => Book },
      {
        no: 3,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Question,
      },
      {
        no: 4,
        name: 'breakpoints',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Breakpoint,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetBookResponse
 */
export const GetBookResponse = new GetBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvailableBooksRequest$Type extends MessageType<GetAvailableBooksRequest> {
  constructor() {
    super('sparx.reading.content.v1.GetAvailableBooksRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetAvailableBooksRequest
 */
export const GetAvailableBooksRequest = new GetAvailableBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvailableBooksResponse$Type extends MessageType<GetAvailableBooksResponse> {
  constructor() {
    super('sparx.reading.content.v1.GetAvailableBooksResponse', [
      {
        no: 1,
        name: 'books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Book$,
      },
      {
        no: 2,
        name: 'student_books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => StudentBook,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetAvailableBooksResponse
 */
export const GetAvailableBooksResponse = new GetAvailableBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckBookLockRequest$Type extends MessageType<CheckBookLockRequest> {
  constructor() {
    super('sparx.reading.content.v1.CheckBookLockRequest', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.CheckBookLockRequest
 */
export const CheckBookLockRequest = new CheckBookLockRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckBookLockResponse$Type extends MessageType<CheckBookLockResponse> {
  constructor() {
    super('sparx.reading.content.v1.CheckBookLockResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.CheckBookLockResponse
 */
export const CheckBookLockResponse = new CheckBookLockResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditableQuestionOption$Type extends MessageType<EditableQuestionOption> {
  constructor() {
    super('sparx.reading.content.v1.EditableQuestionOption', [
      { no: 1, name: 'option_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.EditableQuestionOption
 */
export const EditableQuestionOption = new EditableQuestionOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditableQuestion$Type extends MessageType<EditableQuestion> {
  constructor() {
    super('sparx.reading.content.v1.EditableQuestion', [
      {
        no: 1,
        name: 'question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'question_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'priority', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'options',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => EditableQuestionOption,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.EditableQuestion
 */
export const EditableQuestion = new EditableQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookAnnotationQuestion$Type extends MessageType<BookAnnotationQuestion> {
  constructor() {
    super('sparx.reading.content.v1.BookAnnotationQuestion', [
      { no: 1, name: 'question', kind: 'message', T: () => EditableQuestion },
      {
        no: 2,
        name: 'shadow_question',
        kind: 'message',
        T: () => EditableQuestion,
      },
      {
        no: 3,
        name: 'admin_notes',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'proofread_question',
        kind: 'message',
        T: () => EditableQuestion,
      },
      {
        no: 5,
        name: 'proofread_notes',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'proofread_state',
        kind: 'enum',
        T: () => [
          'sparx.reading.content.v1.ProofreadState',
          ProofreadState,
          'PROOFREAD_STATE_',
        ],
      },
      {
        no: 7,
        name: 'proofread_categories',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.BookAnnotationQuestion
 */
export const BookAnnotationQuestion = new BookAnnotationQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookAnnotationContent$Type extends MessageType<BookAnnotationContent> {
  constructor() {
    super('sparx.reading.content.v1.BookAnnotationContent', [
      {
        no: 3,
        name: 'question',
        kind: 'message',
        oneof: 'content',
        T: () => BookAnnotationQuestion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.BookAnnotationContent
 */
export const BookAnnotationContent = new BookAnnotationContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookAnnotation$Type extends MessageType<BookAnnotation> {
  constructor() {
    super('sparx.reading.content.v1.BookAnnotation', [
      {
        no: 1,
        name: 'book_annotation_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'part_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'content',
        kind: 'message',
        T: () => BookAnnotationContent,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.BookAnnotation
 */
export const BookAnnotation = new BookAnnotation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomBookQuestion$Type extends MessageType<CustomBookQuestion> {
  constructor() {
    super('sparx.reading.content.v1.CustomBookQuestion', [
      {
        no: 1,
        name: 'question_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'proportion', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 4, name: 'question', kind: 'message', T: () => EditableQuestion },
      { no: 5, name: 'ready', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.CustomBookQuestion
 */
export const CustomBookQuestion = new CustomBookQuestion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookAnnotationsRequest$Type extends MessageType<GetBookAnnotationsRequest> {
  constructor() {
    super('sparx.reading.content.v1.GetBookAnnotationsRequest', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetBookAnnotationsRequest
 */
export const GetBookAnnotationsRequest = new GetBookAnnotationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookAnnotationsResponse$Type extends MessageType<GetBookAnnotationsResponse> {
  constructor() {
    super('sparx.reading.content.v1.GetBookAnnotationsResponse', [
      {
        no: 1,
        name: 'annotations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookAnnotation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetBookAnnotationsResponse
 */
export const GetBookAnnotationsResponse = new GetBookAnnotationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommitBookAnnotationRequest$Type extends MessageType<CommitBookAnnotationRequest> {
  constructor() {
    super('sparx.reading.content.v1.CommitBookAnnotationRequest', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'annotation', kind: 'message', T: () => BookAnnotation },
      {
        no: 3,
        name: 'action',
        kind: 'enum',
        T: () => [
          'sparx.reading.content.v1.CommitBookAnnotationRequest.Action',
          CommitBookAnnotationRequest_Action,
          'ACTION_',
        ],
      },
      {
        no: 4,
        name: 'word_count',
        kind: 'scalar',
        opt: true,
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.CommitBookAnnotationRequest
 */
export const CommitBookAnnotationRequest =
  new CommitBookAnnotationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CommitBookAnnotationResponse$Type extends MessageType<CommitBookAnnotationResponse> {
  constructor() {
    super('sparx.reading.content.v1.CommitBookAnnotationResponse', [
      {
        no: 1,
        name: 'annotations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookAnnotation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.CommitBookAnnotationResponse
 */
export const CommitBookAnnotationResponse =
  new CommitBookAnnotationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomBookRequest$Type extends MessageType<GetCustomBookRequest> {
  constructor() {
    super('sparx.reading.content.v1.GetCustomBookRequest', [
      { no: 1, name: 'isbn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'input_method',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetCustomBookRequest
 */
export const GetCustomBookRequest = new GetCustomBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomBookResponse$Type extends MessageType<GetCustomBookResponse> {
  constructor() {
    super('sparx.reading.content.v1.GetCustomBookResponse', [
      { no: 1, name: 'book', kind: 'message', T: () => Book$ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetCustomBookResponse
 */
export const GetCustomBookResponse = new GetCustomBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignCustomBookRequest$Type extends MessageType<AssignCustomBookRequest> {
  constructor() {
    super('sparx.reading.content.v1.AssignCustomBookRequest', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_count', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'start_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'isbn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.AssignCustomBookRequest
 */
export const AssignCustomBookRequest = new AssignCustomBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignCustomBookResponse$Type extends MessageType<AssignCustomBookResponse> {
  constructor() {
    super('sparx.reading.content.v1.AssignCustomBookResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.AssignCustomBookResponse
 */
export const AssignCustomBookResponse = new AssignCustomBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomBooksRequest$Type extends MessageType<ListCustomBooksRequest> {
  constructor() {
    super('sparx.reading.content.v1.ListCustomBooksRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.ListCustomBooksRequest
 */
export const ListCustomBooksRequest = new ListCustomBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCustomBooksResponse$Type extends MessageType<ListCustomBooksResponse> {
  constructor() {
    super('sparx.reading.content.v1.ListCustomBooksResponse', [
      {
        no: 3,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CustomBookQuestion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.ListCustomBooksResponse
 */
export const ListCustomBooksResponse = new ListCustomBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertCustomBookQuestionsRequest$Type extends MessageType<UpsertCustomBookQuestionsRequest> {
  constructor() {
    super('sparx.reading.content.v1.UpsertCustomBookQuestionsRequest', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => CustomBookQuestion,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.UpsertCustomBookQuestionsRequest
 */
export const UpsertCustomBookQuestionsRequest =
  new UpsertCustomBookQuestionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpsertCustomBookQuestionsResponse$Type extends MessageType<UpsertCustomBookQuestionsResponse> {
  constructor() {
    super('sparx.reading.content.v1.UpsertCustomBookQuestionsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.UpsertCustomBookQuestionsResponse
 */
export const UpsertCustomBookQuestionsResponse =
  new UpsertCustomBookQuestionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminStudentBook$Type extends MessageType<AdminStudentBook> {
  constructor() {
    super('sparx.reading.content.v1.AdminStudentBook', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'attempt_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 4, name: 'banked_srp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'progress', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.AdminStudentBook
 */
export const AdminStudentBook = new AdminStudentBook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAdminStudentBooksRequest$Type extends MessageType<ListAdminStudentBooksRequest> {
  constructor() {
    super('sparx.reading.content.v1.ListAdminStudentBooksRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.ListAdminStudentBooksRequest
 */
export const ListAdminStudentBooksRequest =
  new ListAdminStudentBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAdminStudentBooksResponse$Type extends MessageType<ListAdminStudentBooksResponse> {
  constructor() {
    super('sparx.reading.content.v1.ListAdminStudentBooksResponse', [
      {
        no: 1,
        name: 'admin_student_books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AdminStudentBook,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.ListAdminStudentBooksResponse
 */
export const ListAdminStudentBooksResponse =
  new ListAdminStudentBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAdminStudentBookRequest$Type extends MessageType<SetAdminStudentBookRequest> {
  constructor() {
    super('sparx.reading.content.v1.SetAdminStudentBookRequest', [
      {
        no: 1,
        name: 'choices',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AdminStudentBookResponse,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.SetAdminStudentBookRequest
 */
export const SetAdminStudentBookRequest = new SetAdminStudentBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminStudentBookResponse$Type extends MessageType<AdminStudentBookResponse> {
  constructor() {
    super('sparx.reading.content.v1.AdminStudentBookResponse', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'attempt_index',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 4, name: 'srp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 5, name: 'decision', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.AdminStudentBookResponse
 */
export const AdminStudentBookResponse = new AdminStudentBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAdminStudentBookResponse$Type extends MessageType<SetAdminStudentBookResponse> {
  constructor() {
    super('sparx.reading.content.v1.SetAdminStudentBookResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.SetAdminStudentBookResponse
 */
export const SetAdminStudentBookResponse =
  new SetAdminStudentBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAdminStudentBookRequest$Type extends MessageType<CreateAdminStudentBookRequest> {
  constructor() {
    super('sparx.reading.content.v1.CreateAdminStudentBookRequest', [
      {
        no: 1,
        name: 'user_ids',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.CreateAdminStudentBookRequest
 */
export const CreateAdminStudentBookRequest =
  new CreateAdminStudentBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAdminStudentBookResponse$Type extends MessageType<CreateAdminStudentBookResponse> {
  constructor() {
    super('sparx.reading.content.v1.CreateAdminStudentBookResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.CreateAdminStudentBookResponse
 */
export const CreateAdminStudentBookResponse =
  new CreateAdminStudentBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBookkeeperQuestionsRequest$Type extends MessageType<UpdateBookkeeperQuestionsRequest> {
  constructor() {
    super('sparx.reading.content.v1.UpdateBookkeeperQuestionsRequest', [
      {
        no: 1,
        name: 'breakpoints',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Breakpoint,
      },
      {
        no: 2,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Question,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.UpdateBookkeeperQuestionsRequest
 */
export const UpdateBookkeeperQuestionsRequest =
  new UpdateBookkeeperQuestionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBookkeeperQuestionsResponse$Type extends MessageType<UpdateBookkeeperQuestionsResponse> {
  constructor() {
    super('sparx.reading.content.v1.UpdateBookkeeperQuestionsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.UpdateBookkeeperQuestionsResponse
 */
export const UpdateBookkeeperQuestionsResponse =
  new UpdateBookkeeperQuestionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApproveAllAnnotationsRequest$Type extends MessageType<ApproveAllAnnotationsRequest> {
  constructor() {
    super('sparx.reading.content.v1.ApproveAllAnnotationsRequest', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.ApproveAllAnnotationsRequest
 */
export const ApproveAllAnnotationsRequest =
  new ApproveAllAnnotationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApproveAllAnnotationsResponse$Type extends MessageType<ApproveAllAnnotationsResponse> {
  constructor() {
    super('sparx.reading.content.v1.ApproveAllAnnotationsResponse', [
      {
        no: 1,
        name: 'annotations',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BookAnnotation,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.ApproveAllAnnotationsResponse
 */
export const ApproveAllAnnotationsResponse =
  new ApproveAllAnnotationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteQuestionRequest$Type extends MessageType<DeleteQuestionRequest> {
  constructor() {
    super('sparx.reading.content.v1.DeleteQuestionRequest', [
      {
        no: 1,
        name: 'question_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.DeleteQuestionRequest
 */
export const DeleteQuestionRequest = new DeleteQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteQuestionResponse$Type extends MessageType<DeleteQuestionResponse> {
  constructor() {
    super('sparx.reading.content.v1.DeleteQuestionResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.DeleteQuestionResponse
 */
export const DeleteQuestionResponse = new DeleteQuestionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SoftDeleteBreakpointRequest$Type extends MessageType<SoftDeleteBreakpointRequest> {
  constructor() {
    super('sparx.reading.content.v1.SoftDeleteBreakpointRequest', [
      {
        no: 1,
        name: 'breakpoint_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.SoftDeleteBreakpointRequest
 */
export const SoftDeleteBreakpointRequest =
  new SoftDeleteBreakpointRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SoftDeleteBreakpointResponse$Type extends MessageType<SoftDeleteBreakpointResponse> {
  constructor() {
    super('sparx.reading.content.v1.SoftDeleteBreakpointResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.SoftDeleteBreakpointResponse
 */
export const SoftDeleteBreakpointResponse =
  new SoftDeleteBreakpointResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.content.v1.Books
 */
export const Books = new ServiceType('sparx.reading.content.v1.Books', [
  { name: 'GetBook', options: {}, I: GetBookRequest, O: GetBookResponse },
  {
    name: 'GetAvailableBooks',
    options: {},
    I: GetAvailableBooksRequest,
    O: GetAvailableBooksResponse,
  },
  {
    name: 'CheckBookLock',
    options: {},
    I: CheckBookLockRequest,
    O: CheckBookLockResponse,
  },
  {
    name: 'GetBookAnnotations',
    options: {},
    I: GetBookAnnotationsRequest,
    O: GetBookAnnotationsResponse,
  },
  {
    name: 'CommitBookAnnotation',
    options: {},
    I: CommitBookAnnotationRequest,
    O: CommitBookAnnotationResponse,
  },
  {
    name: 'ApproveAllAnnotations',
    options: {},
    I: ApproveAllAnnotationsRequest,
    O: ApproveAllAnnotationsResponse,
  },
  {
    name: 'GetCustomBook',
    options: {},
    I: GetCustomBookRequest,
    O: GetCustomBookResponse,
  },
  {
    name: 'AssignCustomBook',
    options: {},
    I: AssignCustomBookRequest,
    O: AssignCustomBookResponse,
  },
  {
    name: 'ListCustomBooks',
    options: {},
    I: ListCustomBooksRequest,
    O: ListCustomBooksResponse,
  },
  {
    name: 'UpsertCustomBookQuestions',
    options: {},
    I: UpsertCustomBookQuestionsRequest,
    O: UpsertCustomBookQuestionsResponse,
  },
  {
    name: 'ListAdminStudentBooks',
    options: {},
    I: ListAdminStudentBooksRequest,
    O: ListAdminStudentBooksResponse,
  },
  {
    name: 'SetAdminStudentBook',
    options: {},
    I: SetAdminStudentBookRequest,
    O: SetAdminStudentBookResponse,
  },
  {
    name: 'CreateAdminStudentBook',
    options: {},
    I: CreateAdminStudentBookRequest,
    O: CreateAdminStudentBookResponse,
  },
  {
    name: 'UpdateBookkeeperQuestions',
    options: {},
    I: UpdateBookkeeperQuestionsRequest,
    O: UpdateBookkeeperQuestionsResponse,
  },
  {
    name: 'DeleteQuestion',
    options: {},
    I: DeleteQuestionRequest,
    O: DeleteQuestionResponse,
  },
  {
    name: 'SoftDeleteBreakpoint',
    options: {},
    I: SoftDeleteBreakpointRequest,
    O: SoftDeleteBreakpointResponse,
  },
]);
