// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/content/v1/assignment.proto" (package "sparx.reading.content.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.reading.content.v1.Assignment
 */
export interface Assignment {
  /**
   * A resource for the assignement in the form users/{user_id}/assignments/{assignment_id}
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The book to be worked on
   *
   * @generated from protobuf field: string book_id = 2;
   */
  bookId: string;
  /**
   * A uuid for the user who created the assignment
   *
   * @generated from protobuf field: string creator_id = 3;
   */
  creatorId: string;
  /**
   * When the assignment was created
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;
  /**
   * When the assignment was last updated
   *
   * @generated from protobuf field: google.protobuf.Timestamp update_time = 5;
   */
  updateTime?: Timestamp;
  /**
   * The status of the assignment
   *
   * @generated from protobuf field: sparx.reading.content.v1.Assignment.Status status = 6;
   */
  status: Assignment_Status;
  /**
   * The details of the assignment;
   *
   * @generated from protobuf field: string details = 7;
   */
  details: string;
  /**
   * The type of the assignment
   *
   * @generated from protobuf field: sparx.reading.content.v1.Assignment.Type type = 8;
   */
  type: Assignment_Type;
}
/**
 * @generated from protobuf enum sparx.reading.content.v1.Assignment.Status
 */
export enum Assignment_Status {
  /**
   * @generated from protobuf enum value: STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * If the assignment is inactive the user cannot work on that book unless
   * they have at least one other active assignment for the same book.
   *
   * @generated from protobuf enum value: STATUS_INACTIVE = 1;
   */
  INACTIVE = 1,
}
/**
 * @generated from protobuf enum sparx.reading.content.v1.Assignment.Type
 */
export enum Assignment_Type {
  /**
   * @generated from protobuf enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: TYPE_QUESTION_WRITING = 1;
   */
  QUESTION_WRITING = 1,
  /**
   * @generated from protobuf enum value: TYPE_REVIEWING = 2;
   */
  REVIEWING = 2,
}
/**
 * @generated from protobuf message sparx.reading.content.v1.CreateAssignmentRequest
 */
export interface CreateAssignmentRequest {
  /**
   * The resource name of the parent in the form users/{user_id}
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.Assignment assignment = 2;
   */
  assignment?: Assignment;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.UpdateAssignmentRequest
 */
export interface UpdateAssignmentRequest {
  /**
   * The resource name of the parent in the form users/{user_id}
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.Assignment assignment = 2;
   */
  assignment?: Assignment;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.ListAssignmentsRequest
 */
export interface ListAssignmentsRequest {
  /**
   * The resource name of the parent in the form users/{user_id}.
   * A name of users/- returns all assignments for all users.
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * Pagination
   *
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.ListAssignmentsResponse
 */
export interface ListAssignmentsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.content.v1.Assignment assignments = 1;
   */
  assignments: Assignment[];
  /**
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class Assignment$Type extends MessageType<Assignment> {
  constructor() {
    super('sparx.reading.content.v1.Assignment', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'creator_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'create_time', kind: 'message', T: () => Timestamp },
      { no: 5, name: 'update_time', kind: 'message', T: () => Timestamp },
      {
        no: 6,
        name: 'status',
        kind: 'enum',
        T: () => [
          'sparx.reading.content.v1.Assignment.Status',
          Assignment_Status,
          'STATUS_',
        ],
      },
      { no: 7, name: 'details', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 8,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.reading.content.v1.Assignment.Type',
          Assignment_Type,
          'TYPE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.Assignment
 */
export const Assignment = new Assignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAssignmentRequest$Type extends MessageType<CreateAssignmentRequest> {
  constructor() {
    super('sparx.reading.content.v1.CreateAssignmentRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'assignment', kind: 'message', T: () => Assignment },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.CreateAssignmentRequest
 */
export const CreateAssignmentRequest = new CreateAssignmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateAssignmentRequest$Type extends MessageType<UpdateAssignmentRequest> {
  constructor() {
    super('sparx.reading.content.v1.UpdateAssignmentRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'assignment', kind: 'message', T: () => Assignment },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.UpdateAssignmentRequest
 */
export const UpdateAssignmentRequest = new UpdateAssignmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssignmentsRequest$Type extends MessageType<ListAssignmentsRequest> {
  constructor() {
    super('sparx.reading.content.v1.ListAssignmentsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.ListAssignmentsRequest
 */
export const ListAssignmentsRequest = new ListAssignmentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAssignmentsResponse$Type extends MessageType<ListAssignmentsResponse> {
  constructor() {
    super('sparx.reading.content.v1.ListAssignmentsResponse', [
      {
        no: 1,
        name: 'assignments',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Assignment,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.ListAssignmentsResponse
 */
export const ListAssignmentsResponse = new ListAssignmentsResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.content.v1.AssignmentService
 */
export const AssignmentService = new ServiceType(
  'sparx.reading.content.v1.AssignmentService',
  [
    {
      name: 'CreateAssignment',
      options: {},
      I: CreateAssignmentRequest,
      O: Assignment,
    },
    {
      name: 'UpdateAssignment',
      options: {},
      I: UpdateAssignmentRequest,
      O: Assignment,
    },
    {
      name: 'ListAssignments',
      options: {},
      I: ListAssignmentsRequest,
      O: ListAssignmentsResponse,
    },
  ],
);
