import { LeaderboardUser } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import { positionToAddon } from 'views/student/league-view/components/position-to-addon';
import styles from 'views/student/library-view/subviews/home-view.module.css';
import { pathForView } from 'views/views';

export const CurrentPosition = ({ leaderboardUser }: { leaderboardUser: LeaderboardUser }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.StatName}>Current position in your class this year</div>
      <div className={styles.StatPosition}>
        {positionToAddon[leaderboardUser.rank]}
        {leaderboardUser.rank}
        {nth(leaderboardUser.rank)}
      </div>
      <span
        onClick={() => navigate({ pathname: pathForView(View.ClassLeague) })}
        className={styles.BookButtonsLink}
      >
        View full table
      </span>
    </>
  );
};

const nth = function (d: number) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
