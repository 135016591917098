import { LeaderboardUser } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { IStudentWithExperience } from 'queries/management';
import { useEffect } from 'react';
import { scrollAfterTimeout } from 'utils/scroll';
import styles from 'views/student/league-view/league-view.module.css';

export const useScrollToUser = (isLoading: boolean) =>
  useEffect(() => {
    if (!isLoading) {
      scrollAfterTimeout(styles.RowYou, 500);
    }
  }, [isLoading]);

export const findStudent = (
  students: Record<string, IStudentWithExperience>,
  user: LeaderboardUser,
): IStudentWithExperience | undefined => {
  const userId = user.userDisplayData?.name.split('/')[1] || '';

  return students?.[userId];
};
