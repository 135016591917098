import { SubmitBooklogRange } from '@sparx/api/apis/sparx/reading/tasks/v1/booklog';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { Button } from 'components/buttons';
import { getBooklogTaskState, useTaskActionsLoading } from 'queries/tasks';

import styles from '../booklog-task-view.module.css';

interface IStartInputSectionSilverReaderProps {
  task: Task;
  onSubmit: (req: SubmitBooklogRange) => void;
  onBack?: () => void;
}

export const StartInputSectionSilverReader = ({
  task,
  onSubmit,
}: IStartInputSectionSilverReaderProps) => {
  const booklogState = getBooklogTaskState(task);

  const submit = () =>
    onSubmit({
      timeSpentMinutes: 0,
      markFinished: false,
    });
  const loading = useTaskActionsLoading();
  return (
    <>
      <div className={styles.SilverStartPage}>
        Start on <strong>page {booklogState?.start?.page || 0}</strong>
      </div>
      <div className={styles.FakeTime}>0:00 mins</div>
      <div className={styles.ActionButtons}>
        <Button analyticsEvent={undefined} onClick={submit} loading={loading}>
          Start timer
        </Button>
      </div>
    </>
  );
};
