import { SentimentRating } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import classNames from 'classnames';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { useSubmitSentimentFeedback } from 'queries/sentiment-feedback';
import { useState } from 'react';

import { maximised, minimised } from './events';
import { useCurrentSection } from './hooks/current-section';
import { useIsPathBlacklisted } from './hooks/is-path-blacklisted';
import styles from './popover.module.css';
import { BookACall } from './popover-sections/book-a-call';
import { Comment } from './popover-sections/comment';
import { Sentiment } from './popover-sections/sentiment';
import { Submitted } from './popover-sections/submitted';
import { SectionTransition } from './section-transition';
import { Section } from './sections';

interface IFeedbackPopoverProps {
  isVisible: boolean;
}

export const FeedbackPopover = ({ isVisible }: IFeedbackPopoverProps) => {
  const { sendEvent } = useClientEvent();
  const [isMinimised, setIsMinimised] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [sentimentRating, setSentimentRating] = useState<SentimentRating>(SentimentRating.UNKNOWN);
  const [comment, setComment] = useState('');
  const { currentSection, setCurrentSection, onNextSection } = useCurrentSection(sentimentRating);
  const submitFeedback = useSubmitSentimentFeedback();
  const isPathBlacklisted = useIsPathBlacklisted();

  if (!isVisible || isPathBlacklisted) return;

  const onSubmit = () => {
    onNextSection();
    submitFeedback.mutate({ sentiment: sentimentRating, comment, labels: {} });
  };

  const toggleCollapsed = () => {
    sendEvent(isMinimised ? maximised() : minimised());
    setIsMinimised(!isMinimised);
  };

  if (currentSection === Section.Submitted && !isComplete) {
    setIsComplete(true);
  }

  return (
    <div className={classNames(styles.PanelContainer, isComplete && styles.Disappear)}>
      <div className={styles.Panel}>
        <div
          className={styles.Title}
          onClick={toggleCollapsed}
          data-hide={currentSection === Section.Submitted || currentSection === Section.BookACall}
        >
          {isMinimised ? 'How are you finding Sparx Reader?' : 'Ask me later'}
        </div>

        <div
          className={classNames(
            styles.SectionContainer,
            isMinimised ? styles.Minimise : styles.Maximise,
          )}
        >
          <SectionTransition show={currentSection === Section.Sentiment}>
            <Sentiment
              onSelect={number => {
                setSentimentRating(number);
                onNextSection();
              }}
            />
          </SectionTransition>
          <SectionTransition show={currentSection === Section.Comment}>
            <Comment
              onBack={() => setCurrentSection(Section.Sentiment)}
              onCommentChange={comment => setComment(comment)}
              onSubmit={onSubmit}
              isDisabled={isComplete}
            />
          </SectionTransition>
          <SectionTransition show={currentSection === Section.BookACall}>
            <BookACall onSubmit={() => onNextSection()} />
          </SectionTransition>
          <SectionTransition show={currentSection === Section.Submitted}>
            <Submitted />
          </SectionTransition>
        </div>
      </div>
    </div>
  );
};
