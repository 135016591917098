type ServiceName = string;
type MethodName = string;

export type Key<TParams> = [ServiceName, MethodName, TParams];

export type MakeKeyFunc<TParams> = (params: TParams) => Key<TParams>;

export function createKeyMaker<TParams>(
  serviceName: ServiceName,
  methodName: MethodName,
): MakeKeyFunc<TParams> {
  return (params: TParams) => [serviceName, methodName, params];
}
