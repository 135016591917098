import classNames from 'classnames';
import styles from 'components/loading/loading.module.css';

interface ILoadingProps {
  text?: string;
  white?: boolean;
  className?: string;
}

/**
 * Loading displays a loading indicator with some progress text.
 * @param text
 * @constructor
 */
export const Loading: React.FC<ILoadingProps> = ({ text, white, className }) => (
  <div>
    <div className={classNames(styles.DotLoader, white && styles.DotLoaderWhite, className)}>
      <div />
      <div />
      <div />
      <div />
    </div>
    <br />
    <h2>{text}</h2>
  </div>
);

export const CenterLoading: React.FC<ILoadingProps> = props => (
  <div className={styles.Centered}>
    <Loading {...props} />
  </div>
);
