// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/v1/sessions.proto" (package "sparx.reading.users.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Sessions } from './sessions';
import type { UpdateTeacherRolesUpdateTimeResponse } from './sessions';
import type { UpdateTeacherRolesUpdateTimeRequest } from './sessions';
import type { GetUserOnboardingProgressResponse } from './sessions';
import type { GetUserOnboardingProgressRequest } from './sessions';
import type { ShouldUserAnswerBaselineAssessmentResponse } from './sessions';
import type { ShouldUserAnswerBaselineAssessmentRequest } from './sessions';
import type { SaveBaselineAssessmentAnswersResponse } from './sessions';
import type { SaveBaselineAssessmentAnswersRequest } from './sessions';
import type { ListBaselineAssessmentAnswersResponse } from './sessions';
import type { ListBaselineAssessmentAnswersRequest } from './sessions';
import type { UpdateUserDateOfBirthResponse } from './sessions';
import type { UpdateUserDateOfBirthRequest } from './sessions';
import type { UpdateStudentBookProgressResponse } from './sessions';
import type { UpdateStudentBookProgressRequest } from './sessions';
import type { UpdateUserReadingAgeResponse } from './sessions';
import type { UpdateUserReadingAgeRequest } from './sessions';
import type { UpdateOnboardingStatusResponse } from './sessions';
import type { UpdateOnboardingStatusRequest } from './sessions';
import type { GetServerTimeResponse } from './sessions';
import type { GetServerTimeRequest } from './sessions';
import type { GetGoldReaderStateResponse } from './sessions';
import type { GetGoldReaderStateRequest } from './sessions';
import type { UserEvent } from './sessions';
import type { StreamUserEventsRequest } from './sessions';
import type { ServerStreamingCall } from '@protobuf-ts/runtime-rpc';
import type { SetUserOnboardedResponse } from './sessions';
import type { SetUserOnboardedRequest } from './sessions';
import type { DoAdminGoldReaderActionResponse } from './sessions';
import type { DoAdminGoldReaderActionRequest } from './sessions';
import type { UpdateBookSettingsResponse } from './sessions';
import type { UpdateBookSettingsRequest } from './sessions';
import type { AdminUserActiveResponse } from './sessions';
import type { AdminUserActiveRequest } from './sessions';
import type { UserActiveResponse } from './sessions';
import type { UserActiveRequest } from './sessions';
import type { LogEventResponse } from './sessions';
import type { LogEventRequest } from './sessions';
import type { ClearSessionResponse } from './sessions';
import type { ClearSessionRequest } from './sessions';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetSessionResponse } from './sessions';
import type { GetSessionRequest } from './sessions';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.users.v1.Sessions
 */
export interface ISessionsClient {
  /**
   * GetSession returns the current session for the user
   *
   * @generated from protobuf rpc: GetSession(sparx.reading.users.v1.GetSessionRequest) returns (sparx.reading.users.v1.GetSessionResponse);
   */
  getSession(
    input: GetSessionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSessionRequest, GetSessionResponse>;
  /**
   * ClearSessions removes the current active session for the user
   *
   * @generated from protobuf rpc: ClearSession(sparx.reading.users.v1.ClearSessionRequest) returns (sparx.reading.users.v1.ClearSessionResponse);
   */
  clearSession(
    input: ClearSessionRequest,
    options?: RpcOptions,
  ): UnaryCall<ClearSessionRequest, ClearSessionResponse>;
  /**
   * LogEvent logs an event.
   *
   * @generated from protobuf rpc: LogEvent(sparx.reading.users.v1.LogEventRequest) returns (sparx.reading.users.v1.LogEventResponse);
   */
  logEvent(
    input: LogEventRequest,
    options?: RpcOptions,
  ): UnaryCall<LogEventRequest, LogEventResponse>;
  /**
   * UserActive pings the server that the user is active.
   *
   * @generated from protobuf rpc: UserActive(sparx.reading.users.v1.UserActiveRequest) returns (sparx.reading.users.v1.UserActiveResponse);
   */
  userActive(
    input: UserActiveRequest,
    options?: RpcOptions,
  ): UnaryCall<UserActiveRequest, UserActiveResponse>;
  /**
   * AdminUserActive pings the server that the admin user is active.
   *
   * @generated from protobuf rpc: AdminUserActive(sparx.reading.users.v1.AdminUserActiveRequest) returns (sparx.reading.users.v1.AdminUserActiveResponse);
   */
  adminUserActive(
    input: AdminUserActiveRequest,
    options?: RpcOptions,
  ): UnaryCall<AdminUserActiveRequest, AdminUserActiveResponse>;
  /**
   * @generated from protobuf rpc: UpdateBookSettings(sparx.reading.users.v1.UpdateBookSettingsRequest) returns (sparx.reading.users.v1.UpdateBookSettingsResponse);
   */
  updateBookSettings(
    input: UpdateBookSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateBookSettingsRequest, UpdateBookSettingsResponse>;
  /**
   * DoAdminGoldReaderAction updates gold reader states or sends warning for batches of students.
   * To be called by admin.
   *
   * @generated from protobuf rpc: DoAdminGoldReaderAction(sparx.reading.users.v1.DoAdminGoldReaderActionRequest) returns (sparx.reading.users.v1.DoAdminGoldReaderActionResponse);
   */
  doAdminGoldReaderAction(
    input: DoAdminGoldReaderActionRequest,
    options?: RpcOptions,
  ): UnaryCall<DoAdminGoldReaderActionRequest, DoAdminGoldReaderActionResponse>;
  /**
   * SetUserOnboarded is an admin endpoint for manually setting a user's
   * reading age and marking them as onboarded. It will do nothing if the user
   * has already finished onboarding.
   *
   * @generated from protobuf rpc: SetUserOnboarded(sparx.reading.users.v1.SetUserOnboardedRequest) returns (sparx.reading.users.v1.SetUserOnboardedResponse);
   */
  setUserOnboarded(
    input: SetUserOnboardedRequest,
    options?: RpcOptions,
  ): UnaryCall<SetUserOnboardedRequest, SetUserOnboardedResponse>;
  /**
   * StreamUserEvents streams basic user events to the client. There is no queue on the
   * event stream so the client must ensure that a connection to this endpoint is open
   * for as long as it is interested in the stream.
   *
   * @generated from protobuf rpc: StreamUserEvents(sparx.reading.users.v1.StreamUserEventsRequest) returns (stream sparx.reading.users.v1.UserEvent);
   */
  streamUserEvents(
    input: StreamUserEventsRequest,
    options?: RpcOptions,
  ): ServerStreamingCall<StreamUserEventsRequest, UserEvent>;
  /**
   * @generated from protobuf rpc: GetGoldReaderState(sparx.reading.users.v1.GetGoldReaderStateRequest) returns (sparx.reading.users.v1.GetGoldReaderStateResponse);
   */
  getGoldReaderState(
    input: GetGoldReaderStateRequest,
    options?: RpcOptions,
  ): UnaryCall<GetGoldReaderStateRequest, GetGoldReaderStateResponse>;
  /**
   * @generated from protobuf rpc: GetServerTime(sparx.reading.users.v1.GetServerTimeRequest) returns (sparx.reading.users.v1.GetServerTimeResponse);
   */
  getServerTime(
    input: GetServerTimeRequest,
    options?: RpcOptions,
  ): UnaryCall<GetServerTimeRequest, GetServerTimeResponse>;
  /**
   * @generated from protobuf rpc: UpdateOnboardingStatus(sparx.reading.users.v1.UpdateOnboardingStatusRequest) returns (sparx.reading.users.v1.UpdateOnboardingStatusResponse);
   */
  updateOnboardingStatus(
    input: UpdateOnboardingStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateOnboardingStatusRequest, UpdateOnboardingStatusResponse>;
  /**
   * @generated from protobuf rpc: UpdateUserReadingAge(sparx.reading.users.v1.UpdateUserReadingAgeRequest) returns (sparx.reading.users.v1.UpdateUserReadingAgeResponse);
   */
  updateUserReadingAge(
    input: UpdateUserReadingAgeRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUserReadingAgeRequest, UpdateUserReadingAgeResponse>;
  /**
   * @generated from protobuf rpc: UpdateStudentBookProgress(sparx.reading.users.v1.UpdateStudentBookProgressRequest) returns (sparx.reading.users.v1.UpdateStudentBookProgressResponse);
   */
  updateStudentBookProgress(
    input: UpdateStudentBookProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateStudentBookProgressRequest,
    UpdateStudentBookProgressResponse
  >;
  /**
   * @generated from protobuf rpc: UpdateUserDateOfBirth(sparx.reading.users.v1.UpdateUserDateOfBirthRequest) returns (sparx.reading.users.v1.UpdateUserDateOfBirthResponse);
   */
  updateUserDateOfBirth(
    input: UpdateUserDateOfBirthRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUserDateOfBirthRequest, UpdateUserDateOfBirthResponse>;
  /**
   * List answers to previous baseline assessments.
   *
   * @generated from protobuf rpc: ListBaselineAssessmentAnswers(sparx.reading.users.v1.ListBaselineAssessmentAnswersRequest) returns (sparx.reading.users.v1.ListBaselineAssessmentAnswersResponse);
   */
  listBaselineAssessmentAnswers(
    input: ListBaselineAssessmentAnswersRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListBaselineAssessmentAnswersRequest,
    ListBaselineAssessmentAnswersResponse
  >;
  /**
   * Create answers to baseline survey questions. The create_time in the
   * request will be ignored and server time used instead.
   *
   * @generated from protobuf rpc: SaveBaselineAssessmentAnswers(sparx.reading.users.v1.SaveBaselineAssessmentAnswersRequest) returns (sparx.reading.users.v1.SaveBaselineAssessmentAnswersResponse);
   */
  saveBaselineAssessmentAnswers(
    input: SaveBaselineAssessmentAnswersRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SaveBaselineAssessmentAnswersRequest,
    SaveBaselineAssessmentAnswersResponse
  >;
  /**
   * @deprecated
   * @generated from protobuf rpc: ShouldUserAnswerBaselineAssessment(sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentRequest) returns (sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentResponse);
   */
  shouldUserAnswerBaselineAssessment(
    input: ShouldUserAnswerBaselineAssessmentRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ShouldUserAnswerBaselineAssessmentRequest,
    ShouldUserAnswerBaselineAssessmentResponse
  >;
  /**
   * The onboaring status & list of onboarding tasks for the user.
   *
   * @generated from protobuf rpc: GetUserOnboardingProgress(sparx.reading.users.v1.GetUserOnboardingProgressRequest) returns (sparx.reading.users.v1.GetUserOnboardingProgressResponse);
   */
  getUserOnboardingProgress(
    input: GetUserOnboardingProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetUserOnboardingProgressRequest,
    GetUserOnboardingProgressResponse
  >;
  /**
   * Update the timestamp of the last time the teacher roles update prompt was shown and responded to.
   *
   * @generated from protobuf rpc: UpdateTeacherRolesUpdateTime(sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeRequest) returns (sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeResponse);
   */
  updateTeacherRolesUpdateTime(
    input: UpdateTeacherRolesUpdateTimeRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateTeacherRolesUpdateTimeRequest,
    UpdateTeacherRolesUpdateTimeResponse
  >;
}
/**
 * @generated from protobuf service sparx.reading.users.v1.Sessions
 */
export class SessionsClient implements ISessionsClient, ServiceInfo {
  typeName = Sessions.typeName;
  methods = Sessions.methods;
  options = Sessions.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetSession returns the current session for the user
   *
   * @generated from protobuf rpc: GetSession(sparx.reading.users.v1.GetSessionRequest) returns (sparx.reading.users.v1.GetSessionResponse);
   */
  getSession(
    input: GetSessionRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSessionRequest, GetSessionResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSessionRequest, GetSessionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * ClearSessions removes the current active session for the user
   *
   * @generated from protobuf rpc: ClearSession(sparx.reading.users.v1.ClearSessionRequest) returns (sparx.reading.users.v1.ClearSessionResponse);
   */
  clearSession(
    input: ClearSessionRequest,
    options?: RpcOptions,
  ): UnaryCall<ClearSessionRequest, ClearSessionResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ClearSessionRequest, ClearSessionResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * LogEvent logs an event.
   *
   * @generated from protobuf rpc: LogEvent(sparx.reading.users.v1.LogEventRequest) returns (sparx.reading.users.v1.LogEventResponse);
   */
  logEvent(
    input: LogEventRequest,
    options?: RpcOptions,
  ): UnaryCall<LogEventRequest, LogEventResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<LogEventRequest, LogEventResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * UserActive pings the server that the user is active.
   *
   * @generated from protobuf rpc: UserActive(sparx.reading.users.v1.UserActiveRequest) returns (sparx.reading.users.v1.UserActiveResponse);
   */
  userActive(
    input: UserActiveRequest,
    options?: RpcOptions,
  ): UnaryCall<UserActiveRequest, UserActiveResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UserActiveRequest, UserActiveResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * AdminUserActive pings the server that the admin user is active.
   *
   * @generated from protobuf rpc: AdminUserActive(sparx.reading.users.v1.AdminUserActiveRequest) returns (sparx.reading.users.v1.AdminUserActiveResponse);
   */
  adminUserActive(
    input: AdminUserActiveRequest,
    options?: RpcOptions,
  ): UnaryCall<AdminUserActiveRequest, AdminUserActiveResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<AdminUserActiveRequest, AdminUserActiveResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateBookSettings(sparx.reading.users.v1.UpdateBookSettingsRequest) returns (sparx.reading.users.v1.UpdateBookSettingsResponse);
   */
  updateBookSettings(
    input: UpdateBookSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateBookSettingsRequest, UpdateBookSettingsResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateBookSettingsRequest,
      UpdateBookSettingsResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * DoAdminGoldReaderAction updates gold reader states or sends warning for batches of students.
   * To be called by admin.
   *
   * @generated from protobuf rpc: DoAdminGoldReaderAction(sparx.reading.users.v1.DoAdminGoldReaderActionRequest) returns (sparx.reading.users.v1.DoAdminGoldReaderActionResponse);
   */
  doAdminGoldReaderAction(
    input: DoAdminGoldReaderActionRequest,
    options?: RpcOptions,
  ): UnaryCall<
    DoAdminGoldReaderActionRequest,
    DoAdminGoldReaderActionResponse
  > {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      DoAdminGoldReaderActionRequest,
      DoAdminGoldReaderActionResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * SetUserOnboarded is an admin endpoint for manually setting a user's
   * reading age and marking them as onboarded. It will do nothing if the user
   * has already finished onboarding.
   *
   * @generated from protobuf rpc: SetUserOnboarded(sparx.reading.users.v1.SetUserOnboardedRequest) returns (sparx.reading.users.v1.SetUserOnboardedResponse);
   */
  setUserOnboarded(
    input: SetUserOnboardedRequest,
    options?: RpcOptions,
  ): UnaryCall<SetUserOnboardedRequest, SetUserOnboardedResponse> {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<SetUserOnboardedRequest, SetUserOnboardedResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * StreamUserEvents streams basic user events to the client. There is no queue on the
   * event stream so the client must ensure that a connection to this endpoint is open
   * for as long as it is interested in the stream.
   *
   * @generated from protobuf rpc: StreamUserEvents(sparx.reading.users.v1.StreamUserEventsRequest) returns (stream sparx.reading.users.v1.UserEvent);
   */
  streamUserEvents(
    input: StreamUserEventsRequest,
    options?: RpcOptions,
  ): ServerStreamingCall<StreamUserEventsRequest, UserEvent> {
    const method = this.methods[8],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<StreamUserEventsRequest, UserEvent>(
      'serverStreaming',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetGoldReaderState(sparx.reading.users.v1.GetGoldReaderStateRequest) returns (sparx.reading.users.v1.GetGoldReaderStateResponse);
   */
  getGoldReaderState(
    input: GetGoldReaderStateRequest,
    options?: RpcOptions,
  ): UnaryCall<GetGoldReaderStateRequest, GetGoldReaderStateResponse> {
    const method = this.methods[9],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetGoldReaderStateRequest,
      GetGoldReaderStateResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: GetServerTime(sparx.reading.users.v1.GetServerTimeRequest) returns (sparx.reading.users.v1.GetServerTimeResponse);
   */
  getServerTime(
    input: GetServerTimeRequest,
    options?: RpcOptions,
  ): UnaryCall<GetServerTimeRequest, GetServerTimeResponse> {
    const method = this.methods[10],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetServerTimeRequest, GetServerTimeResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: UpdateOnboardingStatus(sparx.reading.users.v1.UpdateOnboardingStatusRequest) returns (sparx.reading.users.v1.UpdateOnboardingStatusResponse);
   */
  updateOnboardingStatus(
    input: UpdateOnboardingStatusRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateOnboardingStatusRequest, UpdateOnboardingStatusResponse> {
    const method = this.methods[11],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateOnboardingStatusRequest,
      UpdateOnboardingStatusResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateUserReadingAge(sparx.reading.users.v1.UpdateUserReadingAgeRequest) returns (sparx.reading.users.v1.UpdateUserReadingAgeResponse);
   */
  updateUserReadingAge(
    input: UpdateUserReadingAgeRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUserReadingAgeRequest, UpdateUserReadingAgeResponse> {
    const method = this.methods[12],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateUserReadingAgeRequest,
      UpdateUserReadingAgeResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateStudentBookProgress(sparx.reading.users.v1.UpdateStudentBookProgressRequest) returns (sparx.reading.users.v1.UpdateStudentBookProgressResponse);
   */
  updateStudentBookProgress(
    input: UpdateStudentBookProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateStudentBookProgressRequest,
    UpdateStudentBookProgressResponse
  > {
    const method = this.methods[13],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateStudentBookProgressRequest,
      UpdateStudentBookProgressResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: UpdateUserDateOfBirth(sparx.reading.users.v1.UpdateUserDateOfBirthRequest) returns (sparx.reading.users.v1.UpdateUserDateOfBirthResponse);
   */
  updateUserDateOfBirth(
    input: UpdateUserDateOfBirthRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUserDateOfBirthRequest, UpdateUserDateOfBirthResponse> {
    const method = this.methods[14],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateUserDateOfBirthRequest,
      UpdateUserDateOfBirthResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * List answers to previous baseline assessments.
   *
   * @generated from protobuf rpc: ListBaselineAssessmentAnswers(sparx.reading.users.v1.ListBaselineAssessmentAnswersRequest) returns (sparx.reading.users.v1.ListBaselineAssessmentAnswersResponse);
   */
  listBaselineAssessmentAnswers(
    input: ListBaselineAssessmentAnswersRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ListBaselineAssessmentAnswersRequest,
    ListBaselineAssessmentAnswersResponse
  > {
    const method = this.methods[15],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListBaselineAssessmentAnswersRequest,
      ListBaselineAssessmentAnswersResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Create answers to baseline survey questions. The create_time in the
   * request will be ignored and server time used instead.
   *
   * @generated from protobuf rpc: SaveBaselineAssessmentAnswers(sparx.reading.users.v1.SaveBaselineAssessmentAnswersRequest) returns (sparx.reading.users.v1.SaveBaselineAssessmentAnswersResponse);
   */
  saveBaselineAssessmentAnswers(
    input: SaveBaselineAssessmentAnswersRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SaveBaselineAssessmentAnswersRequest,
    SaveBaselineAssessmentAnswersResponse
  > {
    const method = this.methods[16],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SaveBaselineAssessmentAnswersRequest,
      SaveBaselineAssessmentAnswersResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @deprecated
   * @generated from protobuf rpc: ShouldUserAnswerBaselineAssessment(sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentRequest) returns (sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentResponse);
   */
  shouldUserAnswerBaselineAssessment(
    input: ShouldUserAnswerBaselineAssessmentRequest,
    options?: RpcOptions,
  ): UnaryCall<
    ShouldUserAnswerBaselineAssessmentRequest,
    ShouldUserAnswerBaselineAssessmentResponse
  > {
    const method = this.methods[17],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ShouldUserAnswerBaselineAssessmentRequest,
      ShouldUserAnswerBaselineAssessmentResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * The onboaring status & list of onboarding tasks for the user.
   *
   * @generated from protobuf rpc: GetUserOnboardingProgress(sparx.reading.users.v1.GetUserOnboardingProgressRequest) returns (sparx.reading.users.v1.GetUserOnboardingProgressResponse);
   */
  getUserOnboardingProgress(
    input: GetUserOnboardingProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<
    GetUserOnboardingProgressRequest,
    GetUserOnboardingProgressResponse
  > {
    const method = this.methods[18],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetUserOnboardingProgressRequest,
      GetUserOnboardingProgressResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * Update the timestamp of the last time the teacher roles update prompt was shown and responded to.
   *
   * @generated from protobuf rpc: UpdateTeacherRolesUpdateTime(sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeRequest) returns (sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeResponse);
   */
  updateTeacherRolesUpdateTime(
    input: UpdateTeacherRolesUpdateTimeRequest,
    options?: RpcOptions,
  ): UnaryCall<
    UpdateTeacherRolesUpdateTimeRequest,
    UpdateTeacherRolesUpdateTimeResponse
  > {
    const method = this.methods[19],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateTeacherRolesUpdateTimeRequest,
      UpdateTeacherRolesUpdateTimeResponse
    >('unary', this._transport, method, opt, input);
  }
}
