import { Content } from 'components/content/content';
import { Loading } from 'components/loading/loading';
import { RolePrompt } from 'components/role-prompt/role-prompt';
import { TeacherSentimentFeedback } from 'components/teacher-sentiment-feedback/teacher-sentiment-feedback';
import { useAllStudentGroups } from 'queries/management';
import { useSubscriptionLockout } from 'queries/subscriptions';
import { SchoolSubscriptionBanner } from 'views/teacher/components/school-subscription-banner/school-subscription-banner';
import { SubscriptionLocked } from 'views/teacher/components/subscription-locked';
import { TeacherAlertProvider } from 'views/teacher/components/teacher-alert';
import { TeacherSidebar } from 'views/teacher/components/teacher-sidebar';

import styles from './teacher-container.module.css';

type Props = {
  children: React.ReactNode;
};

export const TeacherContainer = ({ children }: Props) => {
  const { isLoading: isLoadingStudentGroups } = useAllStudentGroups();
  const subscriptionLockout = useSubscriptionLockout();
  if (isLoadingStudentGroups) {
    return <Loading />;
  }

  const sidebar = !subscriptionLockout ? <TeacherSidebar /> : null;

  return (
    <TeacherAlertProvider>
      <SubscriptionLocked>
        <div className={styles.Container}>
          {sidebar}
          <TeacherSentimentFeedback />
          <RolePrompt />

          <Content>
            <SchoolSubscriptionBanner />
            <div className={styles.Content}>{children}</div>
          </Content>
        </div>
      </SubscriptionLocked>
    </TeacherAlertProvider>
  );
};
