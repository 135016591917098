import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { CSSProperties } from 'react';

import { useTheme } from '../../context';
import { SparxUnit, sparxUnitToValue } from '../../utils/units';
import styles from './InfoTooltip.module.css';
import { Tooltip, TooltipProps } from './Tooltip';

interface InfoTooltipProps extends Omit<TooltipProps, 'children'> {
  // Additional class name to apply to the info icon.
  iconClassName?: string;
  // Additional styles to apply to the info icon.
  iconStyle?: CSSProperties;

  // Margin to the left of the icon.
  ml?: SparxUnit;
  // Margin to the right of the icon.
  mr?: SparxUnit;
}

/**
 * InfoTooltip is an Info (i) icon wrapped with a tooltip.
 */
export const InfoTooltip = ({ iconClassName, iconStyle, ml, mr, ...props }: InfoTooltipProps) => {
  const { themeStyles } = useTheme();

  return (
    <Tooltip {...props}>
      <FontAwesomeIcon
        icon={faInfoCircle}
        className={classNames(themeStyles['InfoTooltip'] || styles.InfoTooltip, iconClassName)}
        style={{
          marginLeft: ml ? sparxUnitToValue(ml) : undefined,
          marginRight: mr ? sparxUnitToValue(mr) : undefined,
          ...iconStyle,
        }}
      />
    </Tooltip>
  );
};
