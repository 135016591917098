import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { Button } from 'components/buttons';
import { TableContainer } from 'components/table/table-container';
import { TableCell, TableRow } from 'components/table/table-row';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { timestampToMoment } from 'utils/time';

import { closedNotStartedStudentsModal, copiedNotStartedStudentsTable } from './events';
import styles from './hand-in-summary-modal.module.css';
import { StudentHomework } from './hand-in-summary-progress';

type Props = {
  studentHomeworks: StudentHomework[];
  isOpen: boolean;
  onClose: () => void;
};

export const HandInSummaryModal = ({ studentHomeworks, isOpen, onClose }: Props) => {
  const navigate = useNavigate();
  const [hasCopied, setHasCopied] = useState(false);

  const sorted = useMemo(
    () =>
      studentHomeworks.sort((a, b) => {
        const homeworkAEndDate = a.homework.endDate?.seconds || 0;
        const homeworkBEndDate = b.homework.endDate?.seconds || 0;

        if (homeworkAEndDate === homeworkBEndDate) {
          if (a.studentGroup.displayName === b.studentGroup.displayName) {
            return a.student.lastName.localeCompare(b.student.lastName);
          }
          return a.studentGroup.displayName.localeCompare(b.studentGroup.displayName, undefined, {
            numeric: true,
          });
        }
        return homeworkAEndDate - homeworkBEndDate;
      }),
    [studentHomeworks],
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      [
        ['Class', 'Student', 'Due date'].join('\t'),
        ...sorted.map(({ student, studentGroup, homework }) =>
          [
            studentGroup.displayName,
            `${student.firstName} ${student.lastName}`,
            timestampToMoment(homework.endDate).fromNow(),
          ].join('\t'),
        ),
      ].join('\n'),
    );
    setHasCopied(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content width="3xl">
        <Modal.Title align="center">
          Students who haven&apos;t started their homework this week
        </Modal.Title>

        <Modal.Body>
          <TableContainer
            maxHeight={400}
            columns={[
              {
                name: 'Class',
                width: 'minmax(min-content, 150px)',
              },
              {
                name: 'Student',
                width: '1fr',
              },
              {
                name: 'Due date',
                width: 'minmax(min-content, 150px)',
              },
            ]}
          >
            {sorted.map(({ student, studentGroup, homework }) => (
              <TableRow
                key={student.userId + homework.homeworkId}
                className={styles.TableRow}
                onClick={() =>
                  navigate(
                    `/teacher/classes/homework?group=${studentGroup.name.split('/')[3]}&homework=${homework.homeworkId}`,
                  )
                }
              >
                <TableCell>{studentGroup.displayName}</TableCell>
                <TableCell className={styles.StudentName}>
                  {student.firstName} {student.lastName}
                </TableCell>
                <TableCell>{timestampToMoment(homework.endDate).fromNow()}</TableCell>
              </TableRow>
            ))}
          </TableContainer>
        </Modal.Body>

        <Modal.Footer align="center">
          <Button
            leftIcon={<FontAwesomeIcon icon={faCopy} />}
            onClick={() => copyToClipboard()}
            analyticsEvent={copiedNotStartedStudentsTable()}
          >
            {hasCopied ? 'Copied!' : 'Copy to clipboard'}
          </Button>

          <Button
            variant="secondary"
            onClick={() => onClose()}
            analyticsEvent={closedNotStartedStudentsModal()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
