interface IScrollAfterTimeoutOptions {
  onScroll?: () => void;
  block?: ScrollLogicalPosition;
}

export const scrollAfterTimeout = (
  className: string,
  timeout: number,
  options?: IScrollAfterTimeoutOptions,
) => {
  const focus = document.getElementsByClassName(className);
  if (focus && focus[0]) {
    const target = focus[0];
    window.setTimeout(() => {
      target.scrollIntoView({
        behavior: 'smooth',
        block: options?.block || 'center',
      });
    }, timeout);
    if (options?.onScroll) {
      options.onScroll();
    }
  }
};

export const scrollAfterTimeoutRef = (
  ref: React.RefObject<HTMLElement>,
  timeout: number,
  options?: IScrollAfterTimeoutOptions,
) => {
  const focus = ref.current;
  if (focus) {
    const target = focus;
    window.setTimeout(() => {
      target.scrollIntoView({
        behavior: 'smooth',
        block: options?.block || 'center',
      });
    }, timeout);
    if (options?.onScroll) {
      options.onScroll();
    }
  }
};
