import {
  faBackward,
  faHeadphones,
  faPause,
  faPlay,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'components/buttons/button';
import { EventLabels, useClientEvent } from 'components/client-events/client-event-provider';
import { useEffect, useRef, useState } from 'react';
import { LibraryBookProgress } from 'views/student/library-view/components/library-book';
import styles from 'views/student/task-view/components/paperback-audio-control.module.css';

interface PaperbackAudioControlProps {
  bookID: string;
  taskID: string;
  audioClip: string;
  audioURL: (bookID: string, clip: string) => string;
  onFinish: () => void;
  active: boolean;
}

export const PaperbackAudioControl: React.FC<PaperbackAudioControlProps> = ({
  bookID,
  taskID,
  audioClip,
  audioURL,
  onFinish,
  active,
}) => {
  const { sendEvent: _sendEvent } = useClientEvent();
  const audio = useRef<HTMLAudioElement>(null);
  const [finished, setFinished] = useState(false);
  const [progress, setProgress] = useState(0);

  const sendEvent = (action: string, data: EventLabels = {}) =>
    _sendEvent({
      category: 'audio playback',
      action,
      labels: {
        taskID,
        bookID,
        audioClip,
        currentTime: audio.current?.currentTime,
        paused: audio.current?.paused,
        ended: audio.current?.ended,
        ...data,
      },
    });

  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    setIsPlaying(
      Boolean(
        audio.current &&
          audio.current.currentTime > 0 &&
          !audio.current.paused &&
          !audio.current.ended &&
          audio.current.readyState > 2,
      ),
    );
  }, [audio]);

  // Effect to pause the audio if we're not active
  useEffect(() => {
    if (isPlaying && !active) {
      audio.current?.pause();
    }
  }, [active, isPlaying]);

  const updateProgress = () => {
    setProgress(audio.current?.currentTime || 0);
  };

  const onClickPlayButton = () => {
    if (isPlaying) {
      audio.current?.pause();
      sendEvent('pause');
    } else {
      audio.current?.play();
      sendEvent('play');
    }
  };

  const onBackwardsButton = () => {
    if (audio.current) {
      const initialTime = audio.current.currentTime;
      audio.current.currentTime -= 10;
      sendEvent('reverse', { initialTime });
    }
  };

  const onEnd = () => {
    if (!finished) {
      setFinished(true);
      sendEvent('end');
    }
  };

  const onFinishButton = () => {
    onFinish();
    sendEvent('continue');
  };

  const [duration, setDuration] = useState(1);
  useEffect(() => {
    if (audio.current) {
      setDuration(audio.current.duration);
    }
  }, [audio]);

  return (
    <div
      className={classNames(styles.Container, {
        [styles.ContainerFixed]: isPlaying || progress > 0,
        [styles.ContainerInactive]: !active,
      })}
    >
      <h2>
        <FontAwesomeIcon icon={faHeadphones} />
        Listen to this segment
      </h2>
      <div className={styles.AudioControlContainer}>
        <audio
          ref={audio}
          onEnded={onEnd}
          onTimeUpdate={updateProgress}
          src={audioURL(bookID, audioClip)}
        />
        <Button
          className={styles.PlayButton}
          onClick={onClickPlayButton}
          analyticsEvent={undefined}
        >
          <FontAwesomeIcon
            icon={isPlaying ? faPause : progress >= duration ? faRedo : faPlay}
            fixedWidth={true}
            flip={!isPlaying && progress >= duration ? 'horizontal' : undefined}
          />
        </Button>
        <Button
          className={styles.PlayButton}
          disabled={progress <= 0}
          onClick={onBackwardsButton}
          analyticsEvent={undefined}
        >
          <FontAwesomeIcon icon={faBackward} />
        </Button>
        <LibraryBookProgress progress={progress / duration} tall={true} fullWidth={true} />
      </div>
      {finished && (
        <Button
          className={styles.FinishButton}
          onClick={onFinishButton}
          disabled={!finished}
          analyticsEvent={undefined}
        >
          I am ready to continue
        </Button>
      )}
    </div>
  );
};
