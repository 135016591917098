import styles from './backdrop.module.css';

export const Backdrop = () => (
  <svg
    width="650"
    height="647"
    viewBox="0 0 650 647"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.Backdrop}
  >
    <g opacity="0.02">
      <path
        d="M350.974 84.8553C348.907 84.6522 347.437 82.817 347.639 80.7597C349.973 57.9949 352.259 35.2254 354.493 12.499L355.212 5.1787C355.414 3.12136 357.261 1.6121 359.328 1.81521C361.395 2.01831 362.913 3.8582 362.71 5.91554L361.991 13.2358C359.753 36.0101 357.467 58.7796 355.133 81.5444C354.931 83.6017 353.089 85.0631 351.022 84.86L350.974 84.8553Z"
        fill="white"
      />
      <path
        d="M339.585 186.919C339.585 186.919 339.537 186.915 339.489 186.91C337.422 186.707 335.962 184.776 336.207 182.771C338.384 165.063 340.482 146.671 342.707 126.504C342.909 124.447 344.751 122.985 346.818 123.189C348.885 123.392 350.35 125.275 350.148 127.332C347.923 147.499 345.82 165.939 343.643 183.647C343.446 185.656 341.556 187.113 339.537 186.915L339.585 186.919Z"
        fill="white"
      />
      <path
        d="M302.573 538.871C300.507 538.668 298.989 536.828 299.239 534.775C301.747 510.24 304.154 485.744 306.614 461.204L306.863 458.668C307.066 456.611 308.912 455.101 310.979 455.305C313.046 455.508 314.564 457.348 314.362 459.405L314.113 461.941C311.701 486.485 309.289 511.03 306.733 535.56C306.531 537.617 304.688 539.079 302.622 538.876L302.573 538.871Z"
        fill="white"
      />
      <path
        d="M290.719 645.189C290.719 645.189 290.671 645.184 290.623 645.179C288.561 644.928 287.096 643.045 287.346 640.993C289.603 622.471 291.82 603.366 294.101 582.625C294.303 580.568 296.001 579.092 298.26 579.314C300.327 579.517 301.792 581.4 301.59 583.458C299.304 604.247 297.087 623.352 294.825 641.921C294.58 643.926 292.738 645.387 290.719 645.189Z"
        fill="white"
      />
      <path
        d="M408.618 34.0969C408.33 34.0685 408.09 34.0449 407.811 33.9209C405.834 33.2919 404.782 31.1596 405.412 29.1926C406.997 24.4211 408.626 19.7021 410.302 14.9878C411.028 13.0303 413.165 12.0325 415.084 12.7525C417.051 13.4772 418.055 15.6047 417.333 17.5144C415.662 22.1808 414.038 26.852 412.51 31.5326C411.956 33.2172 410.301 34.2622 408.57 34.0921L408.618 34.0969Z"
        fill="white"
      />
      <path
        d="M391.507 94.1522C391.507 94.1522 391.122 94.1145 390.93 94.0956C388.944 93.5623 387.734 91.5593 388.224 89.5303C389.702 83.8787 391.184 78.1792 392.709 72.5322C393.243 70.5558 395.254 69.3525 397.288 69.8905C399.274 70.4238 400.48 72.4746 399.946 74.451C398.42 80.098 396.943 85.7496 395.514 91.406C395.047 93.1958 393.329 94.3796 391.502 94.2001L391.507 94.1522Z"
        fill="white"
      />
      <path
        d="M364.174 190.544C363.886 190.516 363.597 190.488 363.318 190.364C361.347 189.687 360.342 187.559 361.021 185.597C366.088 171.071 370.95 155.173 376.275 135.552C376.809 133.576 378.868 132.377 380.854 132.911C382.84 133.444 384.046 135.495 383.512 137.471C378.115 157.326 373.239 173.368 368.109 188.033C367.508 189.712 365.909 190.666 364.227 190.501L364.174 190.544Z"
        fill="white"
      />
      <path
        d="M266.353 525.699C266.353 525.699 265.921 525.656 265.733 525.589C263.747 525.056 262.594 522.962 263.128 520.986C270.548 494.373 276.655 474.201 282.905 455.541C283.583 453.579 285.676 452.528 287.648 453.205C289.62 453.882 290.677 455.966 289.999 457.929C283.763 476.445 277.713 496.526 270.307 522.995C269.792 524.78 268.132 525.873 266.353 525.699Z"
        fill="white"
      />
      <path
        d="M235.772 634.428C235.532 634.405 235.291 634.381 235.06 634.262C233.079 633.681 231.974 631.591 232.556 629.62C239.051 608.471 244.906 587.405 250.284 567.741C250.818 565.765 252.877 564.566 254.911 565.104C256.897 565.638 258.103 567.689 257.564 569.713C252.181 589.425 246.268 610.582 239.765 631.826C239.254 633.563 237.55 634.603 235.82 634.433L235.772 634.428Z"
        fill="white"
      />
      <path
        d="M460.51 51.2739C460.077 51.2314 459.654 51.0932 459.283 50.9119C457.431 50.0053 456.629 47.8009 457.49 45.9532C459.26 42.2626 461.035 38.5241 462.713 34.7762C463.579 32.8807 465.793 32.0838 467.697 32.9473C469.602 33.8108 470.405 36.0151 469.539 37.9106C467.808 41.7017 466.033 45.4402 464.258 49.1786C463.579 50.6577 462.048 51.4251 460.51 51.2739Z"
        fill="white"
      />
      <path
        d="M424.273 121.719C423.793 121.672 423.37 121.533 422.908 121.295C421.06 120.34 420.359 118.098 421.316 116.259C423.89 111.295 426.508 106.384 429.126 101.472C432.759 94.6312 436.344 87.7857 439.928 80.9402C440.885 79.1019 443.138 78.4054 444.985 79.3599C446.832 80.3144 447.534 82.5571 446.577 84.3953C442.988 91.2887 439.403 98.1342 435.771 104.975C433.153 109.887 430.578 114.851 427.96 119.762C427.242 121.141 425.759 121.913 424.269 121.767L424.273 121.719Z"
        fill="white"
      />
      <path
        d="M387.955 198.437C387.619 198.404 387.282 198.371 386.96 198.194C385.046 197.426 384.094 195.255 384.864 193.35C388.936 183.026 393.512 172.51 398.855 161.104C399.716 159.256 401.982 158.416 403.834 159.323C405.691 160.181 406.537 162.438 405.628 164.281C400.347 175.549 395.829 185.974 391.824 196.112C391.183 197.691 389.59 198.597 387.955 198.437Z"
        fill="white"
      />
      <path
        d="M229.189 524.124C228.757 524.082 228.377 523.996 227.963 523.762C226.106 522.903 225.26 520.646 226.17 518.804C229.422 511.877 232.675 504.951 235.928 498.024C244.036 480.706 252.397 462.784 260.956 445.317C261.865 443.474 264.127 442.682 265.979 443.588C267.832 444.495 268.629 446.747 267.72 448.59C259.166 466.01 250.809 483.883 242.754 501.159C239.501 508.085 236.248 515.012 232.996 521.938C232.316 523.417 230.78 524.232 229.242 524.081L229.189 524.124Z"
        fill="white"
      />
      <path
        d="M183.486 614.364C183.005 614.316 182.486 614.169 182.028 613.882C180.234 612.885 179.585 610.599 180.585 608.813C189.7 592.608 199.075 574.738 209.151 554.183C210.06 552.34 212.322 551.548 214.174 552.455C216.027 553.361 216.824 555.613 215.915 557.456C205.729 578.145 196.344 596.111 187.167 612.455C186.406 613.781 184.976 614.51 183.534 614.368L183.486 614.364Z"
        fill="white"
      />
      <path
        d="M463.004 137.988C462.379 137.927 461.72 137.717 461.181 137.278C459.506 136.05 459.102 133.74 460.334 132.074C477.395 108.582 494.112 85.6369 511.099 62.911C512.331 61.2447 514.694 60.8972 516.369 62.1246C518.044 63.352 518.395 65.7052 517.164 67.3716C500.23 90.0543 483.513 113 466.456 136.443C465.665 137.573 464.355 138.073 463.057 137.945L463.004 137.988Z"
        fill="white"
      />
      <path
        d="M408.772 212.318C408.147 212.256 407.488 212.047 406.901 211.602C405.226 210.375 404.875 208.022 406.102 206.403C415.282 193.973 424.367 181.533 433.504 169.049C434.735 167.383 437.051 167.031 438.731 168.21C440.406 169.438 440.762 171.743 439.578 173.414C430.489 185.902 421.352 198.385 412.171 210.816C411.337 211.893 410.07 212.445 408.772 212.318Z"
        fill="white"
      />
      <path
        d="M181.625 523.17C180.904 523.099 180.202 522.837 179.576 522.292C178.006 520.979 177.804 518.592 179.122 517.03C188.354 506.054 197.823 493.652 208.876 477.975C210.059 476.304 212.379 475.904 214.107 477.089C215.787 478.268 216.239 480.583 215.003 482.297C203.835 498.156 194.251 510.74 184.861 521.845C184.037 522.827 182.827 523.288 181.625 523.17Z"
        fill="white"
      />
      <path
        d="M135.507 585.543C134.93 585.487 134.319 585.282 133.819 584.943C132.086 583.806 131.634 581.492 132.774 579.768C139.093 570.293 144.41 562.603 149.421 555.559C150.605 553.888 152.973 553.493 154.653 554.673C156.333 555.852 156.732 558.21 155.548 559.881C150.59 566.882 145.325 574.528 139.012 583.956C138.217 585.133 136.848 585.724 135.502 585.591L135.507 585.543Z"
        fill="white"
      />
      <path
        d="M427.553 228.606C426.688 228.521 425.851 228.149 425.239 227.461C423.785 225.965 423.823 223.602 425.319 222.203L521.544 129.972C523.045 128.526 525.419 128.566 526.826 130.057C528.28 131.552 528.242 133.915 526.746 135.314L430.521 227.545C429.715 228.335 428.616 228.662 427.558 228.558L427.553 228.606Z"
        fill="white"
      />
      <path
        d="M178.16 467.566C177.295 467.481 176.458 467.109 175.846 466.421C174.392 464.925 174.43 462.562 175.926 461.163L222.056 416.955C223.557 415.508 225.931 415.548 227.337 417.039C228.792 418.534 228.754 420.898 227.257 422.296L181.128 466.505C180.322 467.296 179.223 467.622 178.165 467.518L178.16 467.566Z"
        fill="white"
      />
      <path
        d="M93.2962 548.885C92.431 548.8 91.594 548.427 90.9822 547.739C89.5277 546.244 89.5658 543.88 91.0621 542.482L144.371 491.394C145.872 489.947 148.246 489.988 149.652 491.478C151.107 492.974 151.069 495.337 149.572 496.736L96.2638 547.824C95.4582 548.614 94.3584 548.941 93.3009 548.837L93.2962 548.885Z"
        fill="white"
      />
      <path
        d="M557.534 172.154C556.477 172.05 555.461 171.516 554.83 170.536C553.639 168.825 554.062 166.499 555.775 165.363C567.013 157.676 578.208 149.936 589.407 142.148C591.125 140.964 593.461 141.387 594.652 143.098C595.843 144.809 595.42 147.134 593.702 148.318C582.503 156.106 571.308 163.846 560.022 171.529C559.245 172.032 558.399 172.239 557.534 172.154Z"
        fill="white"
      />
      <path
        d="M489.927 217.634C488.821 217.525 487.811 216.943 487.179 215.963C486.037 214.257 486.507 211.936 488.221 210.8C496.834 205.125 505.453 199.402 514.028 193.627C515.741 192.491 518.078 192.913 519.22 194.62C520.363 196.326 519.94 198.652 518.227 199.788C509.604 205.558 500.985 211.281 492.367 217.004C491.638 217.512 490.749 217.666 489.932 217.586L489.927 217.634Z"
        fill="white"
      />
      <path
        d="M442.192 248.546C441.086 248.437 440.028 247.85 439.401 246.822C438.263 245.068 438.782 242.752 440.538 241.668C444.886 238.907 449.239 236.099 453.587 233.338C455.3 232.202 457.627 232.72 458.77 234.427C459.908 236.181 459.393 238.449 457.68 239.585C453.328 242.394 448.98 245.155 444.579 247.959C443.854 248.419 443.009 248.626 442.192 248.546Z"
        fill="white"
      />
      <path
        d="M113.114 468.709C112.056 468.605 110.998 468.018 110.361 467.086C109.219 465.379 109.641 463.054 111.403 461.923L207.347 397.731C209.06 396.595 211.397 397.018 212.535 398.772C213.673 400.526 213.255 402.804 211.494 403.935L115.549 468.127C114.82 468.635 113.926 468.837 113.109 468.757L113.114 468.709Z"
        fill="white"
      />
      <path
        d="M58.1012 505.524C57.0437 505.42 55.9852 504.833 55.3488 503.901C54.2062 502.195 54.6288 499.869 56.39 498.738L71.0357 488.97C72.7488 487.834 75.0853 488.257 76.2232 490.011C77.3659 491.717 76.9432 494.043 75.182 495.174L60.5364 504.942C59.807 505.45 58.9136 505.652 58.0965 505.572L58.1012 505.524Z"
        fill="white"
      />
      <path
        d="M601.767 204.52C600.469 204.393 599.242 203.548 598.687 202.285C597.856 200.368 598.75 198.185 600.67 197.408C606.139 195.095 611.656 192.787 617.211 190.58C619.132 189.802 621.32 190.742 622.103 192.654C622.886 194.567 621.944 196.745 620.023 197.522C614.516 199.735 609.052 202 603.631 204.317C603.026 204.547 602.383 204.677 601.806 204.621L601.767 204.52Z"
        fill="white"
      />
      <path
        d="M497.32 251.549C496.022 251.421 494.838 250.629 494.283 249.367C493.447 247.497 494.298 245.262 496.175 244.432C508.715 238.805 521.217 233.077 533.718 227.349C542.816 223.171 551.913 218.993 561.002 214.91C562.879 214.08 565.13 214.881 565.965 216.75C566.8 218.62 565.998 220.86 564.121 221.69C555.028 225.82 545.935 229.951 536.885 234.134C524.384 239.861 511.83 245.632 499.241 251.255C498.632 251.533 497.993 251.615 497.32 251.549Z"
        fill="white"
      />
      <path
        d="M454.146 270.107C452.801 269.975 451.573 269.129 451.023 267.819C450.24 265.907 451.134 263.724 453.054 262.947C460.232 260.029 467.626 256.891 475.635 253.427C477.56 252.602 479.753 253.493 480.589 255.363C481.42 257.28 480.526 259.463 478.648 260.293C470.587 263.8 463.15 266.885 455.919 269.846C455.314 270.077 454.723 270.163 454.146 270.107Z"
        fill="white"
      />
      <path
        d="M164.038 399.902C162.837 399.784 161.696 399.044 161.079 397.92C160.142 396.089 160.849 393.84 162.687 392.91C165.956 391.25 169.191 389.443 172.378 387.63C179.976 383.401 187.834 379.004 196.411 376.176C198.365 375.547 200.496 376.577 201.178 378.528C201.812 380.475 200.778 382.595 198.819 383.272C190.891 385.923 183.705 389.903 176.054 394.175C172.767 396.025 169.483 397.828 166.109 399.574C165.446 399.895 164.707 400.016 164.034 399.95L164.038 399.902Z"
        fill="white"
      />
      <path
        d="M31.0155 456.682C29.7657 456.559 28.5771 455.814 27.9736 454.548C27.0901 452.673 27.8879 450.481 29.7745 449.556C58.1709 436.163 87.4841 422.329 117.743 410.231C119.664 409.454 121.852 410.393 122.635 412.306C123.418 414.218 122.476 416.396 120.555 417.174C90.5459 429.2 61.2854 442.991 33.0426 456.302C32.3801 456.623 31.7412 456.705 31.0682 456.639L31.0155 456.682Z"
        fill="white"
      />
      <path
        d="M582.344 264.396C580.806 264.245 579.458 263.146 579.08 261.563C578.595 259.583 579.816 257.529 581.804 257.048C601.651 252.139 619.33 247.548 635.78 243.126C637.773 242.598 639.842 243.767 640.375 245.752C640.908 247.736 639.735 249.795 637.742 250.324C621.192 254.784 603.517 259.327 583.617 264.279C583.171 264.38 582.777 264.438 582.344 264.396Z"
        fill="white"
      />
      <path
        d="M461.007 293.63C459.469 293.479 458.121 292.381 457.743 290.798C457.258 288.818 458.479 286.764 460.515 286.288C477.322 282.191 494.214 278.199 511.064 274.154L537.601 267.825C539.638 267.349 541.654 268.561 542.134 270.589C542.619 272.569 541.398 274.623 539.362 275.099L512.824 281.428C495.979 285.425 479.13 289.469 462.324 293.567C461.925 293.672 461.488 293.678 461.055 293.635L461.007 293.63Z"
        fill="white"
      />
      <path
        d="M93.9748 383.935C92.3885 383.779 90.9972 382.628 90.6717 381.002C90.2396 378.979 91.552 376.982 93.5792 376.602L96.0577 376.072C126.685 369.614 158.397 362.972 188.613 353.285C190.568 352.656 192.699 353.686 193.328 355.68C193.962 357.626 192.928 359.747 190.926 360.372C160.311 370.164 128.445 376.888 97.621 383.375L95.1425 383.905C94.7485 383.963 94.3546 384.02 94.0181 383.987L93.9748 383.935Z"
        fill="white"
      />
      <path
        d="M12.8495 403.982C11.4075 403.84 10.0982 402.842 9.60969 401.393C8.97591 399.446 10.1055 397.335 12.06 396.706C23.6242 393.108 35.8953 389.725 49.4208 386.417C51.4093 385.936 53.4736 387.153 53.9585 389.133C54.4434 391.113 53.2224 393.167 51.2339 393.648C37.8092 396.918 25.7398 400.224 14.3197 403.836C13.8203 403.98 13.3302 404.029 12.8495 403.982Z"
        fill="white"
      />
      <path
        d="M627.187 306.386C625.36 306.207 623.906 304.711 623.796 302.865C623.705 300.827 625.287 299.05 627.334 298.961C633.336 298.633 639.333 298.353 645.325 298.121C647.42 298.037 649.158 299.608 649.244 301.694C649.331 303.78 647.705 305.504 645.658 305.593C639.666 305.825 633.717 306.11 627.764 306.443C627.572 306.424 627.379 306.405 627.187 306.386Z"
        fill="white"
      />
      <path
        d="M463.631 317.8C461.853 317.625 460.393 316.178 460.279 314.379C460.14 312.337 461.674 310.555 463.73 310.371C478.299 309.29 492.915 308.215 507.488 307.086L526.165 305.685C528.221 305.501 530.008 307.077 530.195 309.124C530.334 311.167 528.795 312.996 526.743 313.133L508.067 314.534C493.494 315.662 478.877 316.738 464.309 317.818C464.068 317.795 463.871 317.824 463.679 317.805L463.631 317.8Z"
        fill="white"
      />
      <path
        d="M143.723 339.454C141.945 339.279 140.485 337.831 140.328 335.98C140.189 333.938 141.771 332.161 143.822 332.024C158.958 331.096 172.297 330.185 184.472 329.256C186.529 329.071 188.363 330.653 188.502 332.695C188.641 334.738 187.102 336.567 185.051 336.704C172.779 337.623 159.44 338.535 144.305 339.463C144.112 339.444 143.915 339.473 143.675 339.449L143.723 339.454Z"
        fill="white"
      />
      <path
        d="M4.14318 348.877C2.3647 348.702 0.9488 347.307 0.786289 345.504C0.599219 343.457 2.08979 341.623 4.19428 341.443C33.3781 338.804 62.2992 336.863 96.7129 334.835C98.7646 334.698 100.551 336.275 100.69 338.317C100.829 340.36 99.247 342.136 97.1952 342.273C62.8776 344.311 34.0574 346.213 4.92165 348.857C4.68131 348.834 4.43628 348.858 4.19595 348.834L4.14318 348.877Z"
        fill="white"
      />
      <path
        d="M628.961 359.938C608.725 357.95 588.472 355.138 568.744 352.427C534.844 347.743 499.796 342.898 464.64 342.1C462.559 342.041 460.931 340.335 460.989 338.263C461.047 336.192 462.759 334.573 464.84 334.632C500.385 335.42 535.674 340.289 569.814 344.996C594.378 348.376 619.755 351.884 644.753 353.809C644.753 353.809 644.801 353.814 644.849 353.819C646.868 354.017 648.391 355.809 648.241 357.823C648.039 359.881 646.288 361.399 644.217 361.244C639.16 360.844 634.109 360.396 629.014 359.895L628.961 359.938Z"
        fill="white"
      />
      <path
        d="M170.196 311.042C149.624 309.02 129.131 306.185 109.114 303.445C99.2019 302.085 89.3377 300.729 79.4161 299.464C77.3539 299.213 75.8889 297.33 76.1872 295.282C76.4374 293.23 78.3277 291.773 80.3851 292.072C90.3068 293.337 100.219 294.697 110.131 296.058C135.224 299.489 161.125 303.097 186.758 304.988C188.786 305.091 190.352 306.936 190.198 308.998C190.044 311.06 188.245 312.574 186.173 312.418C180.824 312.038 175.532 311.566 170.196 311.042Z"
        fill="white"
      />
      <path
        d="M5.20323 293.137C3.28055 292.948 1.79202 291.305 1.84059 289.329C1.89855 287.257 3.61065 285.638 5.69163 285.698C14.794 285.916 24.4017 286.425 34.2789 287.154C36.3646 287.166 37.8729 289.102 37.7188 291.164C37.5167 293.221 35.7659 294.74 33.6943 294.585C23.9179 293.817 14.4498 293.37 5.49633 293.118C5.4002 293.108 5.30406 293.099 5.20793 293.09L5.20323 293.137Z"
        fill="white"
      />
      <path
        d="M633.354 415.972C633.066 415.944 632.825 415.92 632.546 415.796C608.01 407.685 583.265 400.713 562.334 394.936C560.348 394.403 559.146 392.304 559.728 390.333C560.262 388.356 562.365 387.21 564.351 387.743C585.378 393.529 610.215 400.559 634.943 408.689C636.92 409.318 637.972 411.451 637.342 413.418C636.788 415.102 635.133 416.147 633.402 415.977L633.354 415.972Z"
        fill="white"
      />
      <path
        d="M518.662 383.012C518.662 383.012 518.229 382.969 518.042 382.903C500.51 378.088 479.818 372.335 458.905 365.884C456.928 365.255 455.823 363.166 456.453 361.199C457.083 359.232 459.181 358.134 461.157 358.763C481.927 365.2 502.57 370.948 520.059 375.71C522.045 376.243 523.251 378.294 522.664 380.313C522.149 382.098 520.484 383.239 518.705 383.064L518.662 383.012Z"
        fill="white"
      />
      <path
        d="M144.077 275.578C144.077 275.578 143.645 275.536 143.457 275.469L125.449 270.559C89.1015 260.659 51.523 250.445 15.1034 238.316C13.1316 237.639 12.0746 235.555 12.7527 233.593C13.4309 231.63 15.5239 230.58 17.4957 231.257C53.6797 243.315 91.162 253.519 127.413 263.41L145.421 268.319C147.407 268.853 148.613 270.903 148.026 272.923C147.559 274.713 145.846 275.849 144.068 275.674L144.077 275.578Z"
        fill="white"
      />
      <path
        d="M613.204 467.951C612.723 467.904 612.252 467.761 611.838 467.527C605.237 464.077 598.583 460.669 591.968 457.362C590.121 456.408 589.371 454.161 590.324 452.37C591.281 450.532 593.538 449.788 595.337 450.737C602.044 454.102 608.697 457.509 615.298 460.959C617.146 461.914 617.847 464.157 616.89 465.995C616.172 467.373 614.689 468.145 613.199 467.999L613.204 467.951Z"
        fill="white"
      />
      <path
        d="M550.648 436.829C550.215 436.787 549.792 436.649 549.373 436.462C539.088 431.491 528.745 426.61 518.455 421.686C496.153 411.089 473.115 400.081 450.629 388.886C448.777 387.98 448.027 385.732 448.936 383.889C449.845 382.046 452.102 381.302 453.954 382.209C476.397 393.351 499.392 404.306 521.641 414.946C531.979 419.875 542.317 424.803 552.607 429.727C554.46 430.634 555.262 432.838 554.348 434.729C553.626 436.156 552.138 436.976 550.6 436.825L550.648 436.829Z"
        fill="white"
      />
      <path
        d="M196.259 264.62C195.826 264.577 195.355 264.434 194.937 264.248C190.448 262.02 185.911 259.787 181.423 257.558C179.571 256.652 178.821 254.404 179.73 252.561C180.639 250.718 182.896 249.974 184.748 250.881C189.285 253.114 193.773 255.342 198.31 257.575C200.162 258.482 200.912 260.729 200.003 262.572C199.28 263.999 197.797 264.771 196.259 264.62Z"
        fill="white"
      />
      <path
        d="M178.207 255.697C177.775 255.654 177.351 255.516 176.933 255.33C170.818 252.362 164.704 249.394 158.585 246.474C156.733 245.568 155.93 243.363 156.844 241.473C157.753 239.63 159.967 238.833 161.867 239.744C167.981 242.712 174.148 245.637 180.258 248.652C182.11 249.559 182.908 251.811 181.999 253.654C181.277 255.081 179.789 255.901 178.25 255.75L178.207 255.697Z"
        fill="white"
      />
      <path
        d="M118.416 227.069C117.984 227.026 117.561 226.888 117.142 226.702C110.234 223.414 103.326 220.127 96.4226 216.792C94.5705 215.885 93.7679 213.681 94.6817 211.79C95.5908 209.947 97.8045 209.15 99.7047 210.061C106.608 213.397 113.516 216.684 120.376 219.967C122.228 220.873 123.031 223.078 122.122 224.921C121.443 226.4 119.911 227.167 118.373 227.016L118.416 227.069Z"
        fill="white"
      />
      <path
        d="M54.2263 195.786C53.7937 195.744 53.3224 195.601 52.9039 195.415C46.7989 192.351 40.7466 189.244 34.699 186.09C32.8516 185.135 32.1499 182.893 33.107 181.054C34.0641 179.216 36.3165 178.52 38.1639 179.474C44.1634 182.624 50.2204 185.683 56.2774 188.742C58.1249 189.696 58.8746 191.944 57.9175 193.782C57.1949 195.208 55.7117 195.98 54.2216 195.834L54.2263 195.786Z"
        fill="white"
      />
      <path
        d="M546.698 490.352C545.881 490.272 545.087 489.952 544.466 489.36C535.634 480.956 525.351 472.506 512.938 463.509C511.263 462.281 510.907 459.976 512.138 458.31C513.37 456.643 515.685 456.291 517.36 457.519C529.995 466.731 540.548 475.4 549.654 483.976C551.166 485.38 551.224 487.753 549.767 489.252C548.952 490.138 547.799 490.508 546.694 490.4L546.698 490.352Z"
        fill="white"
      />
      <path
        d="M473.215 435.597C472.59 435.535 471.979 435.33 471.483 434.943C460.842 427.666 448.362 418.856 436.196 408.82C434.627 407.506 434.372 405.163 435.694 403.553C437.013 401.992 439.415 401.745 440.984 403.059C452.876 412.923 465.226 421.576 475.728 428.791C477.455 429.975 477.859 432.285 476.676 433.956C475.881 435.134 474.517 435.676 473.219 435.549L473.215 435.597Z"
        fill="white"
      />
      <path
        d="M208.172 242.651C207.548 242.589 206.893 242.332 206.302 241.935C194.393 233.229 182.481 224.571 170.568 215.913C168.893 214.686 168.537 212.38 169.72 210.709C170.952 209.043 173.267 208.691 174.947 209.87C186.908 218.533 198.821 227.191 210.729 235.897C212.404 237.125 212.755 239.478 211.528 241.096C210.694 242.174 209.427 242.726 208.129 242.598L208.172 242.651Z"
        fill="white"
      />
      <path
        d="M132.281 187.707C131.657 187.646 130.998 187.436 130.459 186.997C123.436 181.911 116.365 176.82 109.338 171.782C107.663 170.555 107.307 168.249 108.491 166.578C109.722 164.912 112.038 164.56 113.717 165.739C120.74 170.825 127.762 175.911 134.838 180.954C136.513 182.182 136.917 184.492 135.685 186.158C134.895 187.288 133.584 187.787 132.286 187.66L132.281 187.707Z"
        fill="white"
      />
      <path
        d="M547.554 557.679C546.593 557.584 545.669 557.107 545.019 556.319C533.575 542.393 520.79 528.287 504.828 511.936C503.374 510.441 503.412 508.078 504.908 506.679C506.409 505.232 508.783 505.272 510.19 506.763C526.33 523.276 539.245 537.54 550.814 551.671C552.115 553.248 551.88 555.64 550.297 556.934C549.505 557.581 548.506 557.869 547.545 557.775L547.554 557.679Z"
        fill="white"
      />
      <path
        d="M471.414 477.64C470.597 477.56 469.808 477.192 469.139 476.595C453.684 461.502 436.145 444.417 419.768 426.866C418.361 425.376 418.447 423.017 419.948 421.57C421.444 420.172 423.814 420.26 425.268 421.755C441.564 439.153 459.007 456.229 474.423 471.221C475.882 472.669 475.94 475.041 474.487 476.493C473.677 477.331 472.524 477.701 471.419 477.592L471.414 477.64Z"
        fill="white"
      />
      <path
        d="M224.628 223.834C223.715 223.745 222.883 223.325 222.223 222.632C204.577 203.169 186.006 184.244 170.146 168.338C168.687 166.89 168.677 164.522 170.178 163.076C171.631 161.624 174.005 161.664 175.464 163.112C191.362 179.118 210.026 198.101 227.758 217.669C229.16 219.207 229.025 221.561 227.481 222.955C226.68 223.698 225.633 223.981 224.624 223.882L224.628 223.834Z"
        fill="white"
      />
      <path
        d="M137.749 134.528C136.884 134.443 136.095 134.075 135.431 133.43C121.309 119.096 109.826 107.05 99.3059 95.5823C97.9041 94.0437 98.0383 91.6898 99.5346 90.291C101.079 88.897 103.444 89.0327 104.85 90.5235C115.327 101.939 126.767 113.932 140.797 128.209C142.251 129.704 142.213 132.068 140.765 133.471C139.959 134.262 138.855 134.636 137.749 134.528Z"
        fill="white"
      />
      <path
        d="M503.98 592.672C502.971 592.572 502.004 592.043 501.315 591.154C486.701 571.458 473.558 551.616 462.593 534.791C461.455 533.036 461.969 530.768 463.682 529.632C465.444 528.501 467.723 529.015 468.865 530.721C479.791 547.446 492.848 567.183 507.331 586.721C508.575 588.389 508.2 590.719 506.526 591.956C505.744 592.507 504.846 592.757 503.932 592.667L503.98 592.672Z"
        fill="white"
      />
      <path
        d="M446.846 507.881C445.74 507.773 444.682 507.186 444.05 506.206C431.118 486.192 415.929 462.961 399.758 440.842C398.515 439.174 398.889 436.843 400.564 435.607C402.238 434.371 404.58 434.746 405.823 436.414C422.124 458.691 437.351 482.022 450.37 502.141C451.508 503.895 450.994 506.164 449.233 507.295C448.508 507.755 447.663 507.962 446.846 507.881Z"
        fill="white"
      />
      <path
        d="M213.064 161.252C212.007 161.148 210.948 160.561 210.312 159.628C201.296 146.183 192.184 132.727 183.067 119.32C181.925 117.614 182.347 115.288 184.06 114.152C185.774 113.016 188.11 113.439 189.253 115.146C198.369 128.553 207.481 142.008 216.541 155.506C217.683 157.213 217.213 159.534 215.499 160.67C214.77 161.178 213.877 161.38 213.059 161.299L213.064 161.252Z"
        fill="white"
      />
      <path
        d="M158.369 80.7595C157.311 80.6556 156.296 80.1211 155.616 79.1364L142.537 60.1225C141.346 58.4114 141.817 56.0906 143.482 54.9499C145.195 53.814 147.532 54.2368 148.679 55.8954L161.758 74.9093C162.949 76.6204 162.478 78.9412 160.765 80.0772C159.988 80.5804 159.142 80.7872 158.277 80.7022L158.369 80.7595Z"
        fill="white"
      />
      <path
        d="M454.988 619.594C453.69 619.467 452.507 618.674 451.946 617.46C439.842 591.489 428.968 567.812 418.692 544.968C417.857 543.098 418.707 540.863 420.584 540.033C422.462 539.203 424.708 540.052 425.543 541.921C435.776 564.713 446.65 588.39 458.759 614.313C459.642 616.187 458.792 618.422 456.963 619.257C456.353 619.535 455.661 619.66 455.036 619.599L454.988 619.594Z"
        fill="white"
      />
      <path
        d="M406.421 512.797C405.123 512.67 403.891 511.872 403.341 510.562C394.454 490.366 385.92 470.543 378.06 451.752C377.277 449.839 378.171 447.657 380.092 446.879C382.012 446.102 384.206 446.994 384.988 448.906C392.848 467.697 401.344 487.42 410.231 507.616C411.066 509.486 410.215 511.721 408.29 512.546C407.68 512.824 407.041 512.906 406.417 512.845L406.421 512.797Z"
        fill="white"
      />
      <path
        d="M266.658 197.144C265.456 197.026 264.311 196.334 263.698 195.163C259.848 187.925 256.068 180.452 252.259 172.3C251.376 170.426 252.178 168.186 254.055 167.356C255.937 166.478 258.188 167.279 259.023 169.149C262.794 177.2 266.486 184.568 270.303 191.657C271.288 193.493 270.581 195.742 268.786 196.725C268.119 197.095 267.379 197.215 266.658 197.144Z"
        fill="white"
      />
      <path
        d="M247.604 157.447C246.306 157.32 245.074 156.522 244.523 155.212C238.428 141.184 232.562 126.792 226.938 112.906L224.948 107.977C224.165 106.064 225.107 103.886 227.028 103.109C228.948 102.331 231.137 103.271 231.92 105.184L233.91 110.113C239.534 123.999 245.356 138.338 251.413 152.266C252.248 154.136 251.35 156.366 249.472 157.196C248.863 157.474 248.224 157.556 247.599 157.495L247.604 157.447Z"
        fill="white"
      />
      <path
        d="M209.131 65.458C207.833 65.3305 206.649 64.5378 206.046 63.2709C201.028 52.102 196.23 42.1622 191.322 32.8398C190.386 31.0087 191.092 28.7594 192.883 27.8243C194.721 26.8939 196.981 27.5991 197.922 29.3823C202.912 38.8577 207.792 48.9504 212.844 60.2676C213.679 62.137 212.829 64.3722 210.952 65.2022C210.342 65.4804 209.703 65.5625 209.078 65.5011L209.131 65.458Z"
        fill="white"
      />
      <path
        d="M402.014 637.672C400.428 637.516 399.036 636.365 398.711 634.739C395.89 621.467 392.505 607.512 387.985 590.788C387.452 588.804 388.625 586.745 390.618 586.217C392.611 585.688 394.68 586.857 395.213 588.842C399.767 605.714 403.191 619.769 406.046 633.189C406.478 635.213 405.165 637.209 403.138 637.59C402.744 637.647 402.35 637.705 402.014 637.672Z"
        fill="white"
      />
      <path
        d="M378.505 547.975C377.015 547.828 375.71 546.782 375.279 545.243C367.588 517.821 359.801 488.41 354.902 458.751C354.566 456.738 355.97 454.798 357.993 454.466C360.015 454.133 361.964 455.532 362.3 457.546C367.136 486.86 374.846 516.071 382.512 543.248C383.093 545.237 381.92 547.296 379.927 547.825C379.476 547.974 378.99 547.974 378.558 547.932L378.505 547.975Z"
        fill="white"
      />
      <path
        d="M289.933 187.983C288.346 187.827 286.96 186.628 286.634 185.002C281.062 157.257 273.36 129.449 265.954 102.587C262.912 91.5637 259.827 80.488 256.934 69.4313C256.401 67.4466 257.622 65.3928 259.615 64.864C261.608 64.3353 263.672 65.5526 264.205 67.5372C267.146 78.5987 270.193 89.574 273.235 100.597C280.723 127.612 288.464 155.521 294.056 183.557C294.44 185.576 293.132 187.524 291.1 187.953C290.706 188.011 290.365 188.025 289.981 187.988L289.933 187.983Z"
        fill="white"
      />
      <path
        d="M249.67 28.7674C248.084 28.6115 246.693 27.4603 246.367 25.8342C245.498 21.8359 244.73 17.7993 243.962 13.7626C243.578 11.7443 244.934 9.80032 246.961 9.41984C248.988 9.03935 250.942 10.3907 251.326 12.4091C252.099 16.3979 252.877 20.3388 253.702 24.2845C254.134 26.3076 252.822 28.3041 250.795 28.6846C250.401 28.7425 250.007 28.8004 249.67 28.7674Z"
        fill="white"
      />
      <path
        d="M344.384 607.76C342.606 607.585 341.147 606.137 340.989 604.286C339.388 579.589 337.483 554.523 335.695 530.243C333.997 507.516 332.231 484.01 330.715 460.916C330.576 458.873 332.158 457.096 334.209 456.96C336.261 456.823 338.047 458.4 338.186 460.442C339.702 483.537 341.474 506.995 343.172 529.721C345.007 554.007 346.859 579.115 348.461 603.813C348.6 605.855 347.018 607.632 344.966 607.769C344.774 607.75 344.577 607.779 344.336 607.755L344.384 607.76Z"
        fill="white"
      />
      <path
        d="M314.8 185.306C313.022 185.131 311.606 183.736 311.448 181.885C309.011 152.855 307.189 123.981 305.376 94.5289C305.237 92.4863 306.819 90.7095 308.871 90.5729C310.922 90.4364 312.709 92.0129 312.848 94.0554C314.666 123.46 316.444 152.281 318.886 181.263C319.073 183.311 317.534 185.14 315.483 185.277C315.242 185.253 315.045 185.282 314.805 185.258L314.8 185.306Z"
        fill="white"
      />
      <path
        d="M305.873 51.9223C304.095 51.7475 302.636 50.2998 302.478 48.4487C301.4 32.2565 300.331 17.9491 299.201 4.74696C299.014 2.69968 300.553 0.870287 302.605 0.733752C304.661 0.549372 306.5 2.08269 306.639 4.12525C307.764 17.3753 308.828 31.7304 309.95 47.9752C310.089 50.0178 308.507 51.7946 306.455 51.9311C306.263 51.9122 306.018 51.9365 305.825 51.9176L305.873 51.9223Z"
        fill="white"
      />
      <path
        d="M290.719 645.189C290.719 645.189 290.671 645.184 290.623 645.179C288.561 644.928 287.096 643.045 287.346 640.993C289.603 622.471 291.82 603.366 294.101 582.625C294.303 580.568 296.001 579.092 298.26 579.314C300.327 579.517 301.792 581.4 301.59 583.458C299.304 604.247 297.087 623.352 294.825 641.921C294.58 643.926 292.738 645.387 290.719 645.189Z"
        fill="white"
      />
      <path
        d="M302.573 538.871C300.507 538.668 298.989 536.828 299.239 534.775C301.747 510.24 304.154 485.744 306.614 461.204L306.863 458.668C307.066 456.611 308.912 455.101 310.979 455.305C313.046 455.508 314.564 457.348 314.362 459.405L314.113 461.941C311.701 486.485 309.289 511.03 306.733 535.56C306.531 537.617 304.688 539.079 302.622 538.876L302.573 538.871Z"
        fill="white"
      />
      <path
        d="M339.585 186.919C339.585 186.919 339.537 186.915 339.489 186.91C337.422 186.707 335.962 184.776 336.207 182.771C338.384 165.063 340.482 146.671 342.707 126.504C342.909 124.447 344.751 122.985 346.818 123.189C348.885 123.392 350.35 125.275 350.148 127.332C347.923 147.499 345.82 165.939 343.643 183.647C343.446 185.656 341.556 187.113 339.537 186.915L339.585 186.919Z"
        fill="white"
      />
      <path
        d="M350.974 84.8553C348.907 84.6522 347.437 82.817 347.639 80.7597C349.973 57.9949 352.259 35.2254 354.493 12.499L355.212 5.1787C355.414 3.12136 357.261 1.6121 359.328 1.81521C361.395 2.01831 362.913 3.8582 362.71 5.91554L361.991 13.2358C359.753 36.0101 357.467 58.7796 355.133 81.5444C354.931 83.6017 353.089 85.0631 351.022 84.86L350.974 84.8553Z"
        fill="white"
      />
    </g>
  </svg>
);
