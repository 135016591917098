// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/v1/sessions.proto" (package "sparx.reading.users.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { OnboardingTask } from './onboarding';
import { OnboardingStatus } from './onboarding';
import { GoldReaderState } from './statistics';
import { Struct } from '../../../../../google/protobuf/struct';
import { Experience } from './experience';
import { Settings } from './settings';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { Statistics } from './statistics';
/**
 * @generated from protobuf message sparx.reading.users.v1.User
 */
export interface User {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * Student ID or staff ID. For v2 staff manager teachers, this will be shared
   * across all schools they can log in to. Not set for sparx accounts.
   *
   * @generated from protobuf field: string sparx_user_id = 20;
   */
  sparxUserId: string;
  /**
   * @deprecated
   * @generated from protobuf field: string username = 2 [deprecated = true];
   */
  username: string;
  /**
   * @generated from protobuf field: string first_name = 3;
   */
  firstName: string;
  /**
   * @generated from protobuf field: string last_name = 4;
   */
  lastName: string;
  /**
   * @deprecated
   * @generated from protobuf field: bool admin = 5 [deprecated = true];
   */
  admin: boolean;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.UserType type = 6;
   */
  type: UserType;
  /**
   * @generated from protobuf field: repeated string features = 7;
   */
  features: string[];
  /**
   * @generated from protobuf field: string school_id = 8;
   */
  schoolId: string;
  /**
   * @generated from protobuf field: string student_group_id = 9;
   */
  studentGroupId: string;
  /**
   * Only populated for MIS schools if they have a tutor group.
   * Only returned to the teacher.
   *
   * @generated from protobuf field: string tutor_group_id = 15;
   */
  tutorGroupId: string;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.Statistics statistics = 10;
   */
  statistics?: Statistics;
  /**
   * Date that the user last logged in
   *
   * @generated from protobuf field: google.protobuf.Timestamp last_login = 11;
   */
  lastLogin?: Timestamp;
  /**
   * Whether the user is a gold reader
   *
   * @generated from protobuf field: bool gold_reader = 12;
   */
  goldReader: boolean;
  /**
   * The users' book settings
   *
   * @generated from protobuf field: sparx.reading.users.v1.Settings settings = 13;
   */
  settings?: Settings;
  /**
   * Relative length of the homework the student should be set. Only set
   * for api calls for teachers.
   *
   * @generated from protobuf field: sparx.reading.users.v1.HomeworkLength homework_length = 14;
   */
  homeworkLength: HomeworkLength;
  /**
   * Timestamp the next sentiment feedback survey should be sent to the user.
   *
   * @generated from protobuf field: google.protobuf.Timestamp ask_sentiment_feedback_at = 16;
   */
  askSentimentFeedbackAt?: Timestamp;
  /**
   * User roles
   *
   * @generated from protobuf field: repeated string roles = 17;
   */
  roles: string[];
  /**
   * This field is no longer used. To find out about a user's league
   * status call userdisplay.
   *
   * @deprecated
   * @generated from protobuf field: string league_name = 18 [deprecated = true];
   */
  leagueName: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp date_of_birth = 19;
   */
  dateOfBirth?: Timestamp;
  /**
   * Date that the user first logged in
   *
   * @generated from protobuf field: google.protobuf.Timestamp first_login = 21;
   */
  firstLogin?: Timestamp;
  /**
   * The user's email address. Currently only set for Sparx Staff since we have
   * no other way to fetch it in-product.
   *
   * @generated from protobuf field: string email_address = 22;
   */
  emailAddress: string;
  /**
   * The last time, if any, the user responded to the teacher roles update prompt.
   *
   * @generated from protobuf field: google.protobuf.Timestamp teacher_roles_update_time = 23;
   */
  teacherRolesUpdateTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.OAuthProvider
 */
export interface OAuthProvider {
  /**
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * @generated from protobuf field: string hosted_domain = 2;
   */
  hostedDomain: string;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.GetSessionRequest
 */
export interface GetSessionRequest {}
/**
 * @generated from protobuf message sparx.reading.users.v1.GetSessionResponse
 */
export interface GetSessionResponse {
  /**
   * @generated from protobuf field: sparx.reading.users.v1.User user = 2;
   */
  user?: User;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.Experience experience = 4;
   */
  experience?: Experience;
  /**
   * @generated from protobuf field: bool first_login_today = 6;
   */
  firstLoginToday: boolean;
  /**
   * @generated from protobuf field: google.protobuf.Struct feature_flags = 9;
   */
  featureFlags?: Struct;
  /**
   * The start of the current academic year, which is the start of the previous
   * year during interim.
   *
   * @generated from protobuf field: google.protobuf.Timestamp school_year_start = 10;
   */
  schoolYearStart?: Timestamp;
  /**
   * Hash for authenticating with Intercom. Only returned for staff members.
   *
   * @generated from protobuf field: string intercom_hash = 11;
   */
  intercomHash: string;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.ClearSessionRequest
 */
export interface ClearSessionRequest {}
/**
 * @generated from protobuf message sparx.reading.users.v1.ClearSessionResponse
 */
export interface ClearSessionResponse {}
/**
 * @generated from protobuf message sparx.reading.users.v1.LogEventRequest
 */
export interface LogEventRequest {
  /**
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * @generated from protobuf field: string contextJSON = 2;
   */
  contextJSON: string;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.LogEventResponse
 */
export interface LogEventResponse {}
/**
 * @generated from protobuf message sparx.reading.users.v1.UserActiveRequest
 */
export interface UserActiveRequest {
  /**
   * @generated from protobuf field: string current_path = 1;
   */
  currentPath: string;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.AdminUserActiveRequest
 */
export interface AdminUserActiveRequest {
  /**
   * @generated from protobuf field: string current_path = 1;
   */
  currentPath: string;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.UserActiveResponse
 */
export interface UserActiveResponse {}
/**
 * @generated from protobuf message sparx.reading.users.v1.AdminUserActiveResponse
 */
export interface AdminUserActiveResponse {}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateBookSettingsRequest
 */
export interface UpdateBookSettingsRequest {
  /**
   * @generated from protobuf field: sparx.reading.users.v1.Settings settings = 1;
   */
  settings?: Settings;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateBookSettingsResponse
 */
export interface UpdateBookSettingsResponse {
  /**
   * @generated from protobuf field: sparx.reading.users.v1.Settings settings = 1;
   */
  settings?: Settings;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.DoAdminGoldReaderActionRequest
 */
export interface DoAdminGoldReaderActionRequest {
  /**
   * @generated from protobuf field: repeated sparx.reading.users.v1.DoAdminGoldReaderActionRequest.StudentAction actions = 1;
   */
  actions: DoAdminGoldReaderActionRequest_StudentAction[];
}
/**
 * @generated from protobuf message sparx.reading.users.v1.DoAdminGoldReaderActionRequest.StudentAction
 */
export interface DoAdminGoldReaderActionRequest_StudentAction {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.DoAdminGoldReaderActionRequest.GoldReaderAction action = 2;
   */
  action: DoAdminGoldReaderActionRequest_GoldReaderAction;
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.DoAdminGoldReaderActionRequest.GoldReaderAction
 */
export enum DoAdminGoldReaderActionRequest_GoldReaderAction {
  /**
   * @generated from protobuf enum value: ACTION_UNSPECIFIED = 0;
   */
  ACTION_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ACTION_WARNING = 1;
   */
  ACTION_WARNING = 1,
  /**
   * @generated from protobuf enum value: ACTION_REVOKE = 2;
   */
  ACTION_REVOKE = 2,
}
/**
 * @generated from protobuf message sparx.reading.users.v1.DoAdminGoldReaderActionResponse
 */
export interface DoAdminGoldReaderActionResponse {}
/**
 * @generated from protobuf message sparx.reading.users.v1.SetUserOnboardedRequest
 */
export interface SetUserOnboardedRequest {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: int32 reading_age_months = 2;
   */
  readingAgeMonths: number;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.SetUserOnboardedResponse
 */
export interface SetUserOnboardedResponse {
  /**
   * Whether the user was updated. The user is not updated if they had already
   * finished onboarding when this endpoint was called.
   *
   * @generated from protobuf field: bool updated = 1;
   */
  updated: boolean;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.StreamUserEventsRequest
 */
export interface StreamUserEventsRequest {}
/**
 * @generated from protobuf message sparx.reading.users.v1.UserEvent
 */
export interface UserEvent {
  /**
   * Type of the event. Can be an arbitrary value.
   *
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * Arbitrary event data related to the type of event.
   *
   * @generated from protobuf field: string data = 2;
   */
  data: string;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.GetGoldReaderStateRequest
 */
export interface GetGoldReaderStateRequest {}
/**
 * @generated from protobuf message sparx.reading.users.v1.GetGoldReaderStateResponse
 */
export interface GetGoldReaderStateResponse {
  /**
   * @generated from protobuf field: sparx.reading.users.v1.GoldReaderState gold_reader_state = 1;
   */
  goldReaderState?: GoldReaderState;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.GetServerTimeRequest
 */
export interface GetServerTimeRequest {}
/**
 * @generated from protobuf message sparx.reading.users.v1.GetServerTimeResponse
 */
export interface GetServerTimeResponse {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp server_time = 1;
   */
  serverTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateOnboardingStatusRequest
 */
export interface UpdateOnboardingStatusRequest {}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateOnboardingStatusResponse
 */
export interface UpdateOnboardingStatusResponse {}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateUserReadingAgeRequest
 */
export interface UpdateUserReadingAgeRequest {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: optional int32 reading_age_months = 2;
   */
  readingAgeMonths?: number;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateUserReadingAgeResponse
 */
export interface UpdateUserReadingAgeResponse {}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateUserDateOfBirthRequest
 */
export interface UpdateUserDateOfBirthRequest {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp date_of_birth = 2;
   */
  dateOfBirth?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateUserDateOfBirthResponse
 */
export interface UpdateUserDateOfBirthResponse {}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateStudentBookProgressRequest
 */
export interface UpdateStudentBookProgressRequest {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: string book_id = 2;
   */
  bookId: string;
  /**
   * @generated from protobuf field: float progress = 3;
   */
  progress: number;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateStudentBookProgressResponse
 */
export interface UpdateStudentBookProgressResponse {}
/**
 * @generated from protobuf message sparx.reading.users.v1.BaselineAssessmentAnswer
 */
export interface BaselineAssessmentAnswer {
  /**
   * The question they were asked as part of this baseline assessment
   *
   * @generated from protobuf field: string question = 1;
   */
  question: string;
  /**
   * The option they selected in response.
   *
   * @generated from protobuf field: string selected_option = 2;
   */
  selectedOption: string;
  /**
   * All of the options in the order they were shown.
   *
   * @generated from protobuf field: repeated string all_options = 3;
   */
  allOptions: string[];
  /**
   * The time the answer was given.
   *
   * @generated from protobuf field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.ListBaselineAssessmentAnswersRequest
 */
export interface ListBaselineAssessmentAnswersRequest {}
/**
 * @generated from protobuf message sparx.reading.users.v1.ListBaselineAssessmentAnswersResponse
 */
export interface ListBaselineAssessmentAnswersResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.users.v1.BaselineAssessmentAnswer answers = 1;
   */
  answers: BaselineAssessmentAnswer[];
}
/**
 * @generated from protobuf message sparx.reading.users.v1.SaveBaselineAssessmentAnswersRequest
 */
export interface SaveBaselineAssessmentAnswersRequest {
  /**
   * @generated from protobuf field: repeated sparx.reading.users.v1.BaselineAssessmentAnswer answers = 1;
   */
  answers: BaselineAssessmentAnswer[];
}
/**
 * @generated from protobuf message sparx.reading.users.v1.SaveBaselineAssessmentAnswersResponse
 */
export interface SaveBaselineAssessmentAnswersResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.users.v1.BaselineAssessmentAnswer answers = 1;
   */
  answers: BaselineAssessmentAnswer[];
}
/**
 * @generated from protobuf message sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentRequest
 */
export interface ShouldUserAnswerBaselineAssessmentRequest {}
/**
 * @generated from protobuf message sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentResponse
 */
export interface ShouldUserAnswerBaselineAssessmentResponse {
  /**
   * @generated from protobuf field: bool decision = 1;
   */
  decision: boolean;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.GetUserOnboardingProgressRequest
 */
export interface GetUserOnboardingProgressRequest {}
/**
 * @generated from protobuf message sparx.reading.users.v1.GetUserOnboardingProgressResponse
 */
export interface GetUserOnboardingProgressResponse {
  /**
   * @generated from protobuf field: sparx.reading.users.v1.OnboardingStatus status = 1;
   */
  status: OnboardingStatus;
  /**
   * An ordered list of onboarding tasks for the user.
   *
   * @generated from protobuf field: repeated sparx.reading.users.v1.OnboardingTask tasks = 2;
   */
  tasks: OnboardingTask[];
}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeRequest
 */
export interface UpdateTeacherRolesUpdateTimeRequest {
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeResponse
 */
export interface UpdateTeacherRolesUpdateTimeResponse {}
/**
 * @generated from protobuf enum sparx.reading.users.v1.UserType
 */
export enum UserType {
  /**
   * @generated from protobuf enum value: UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: STUDENT = 1;
   */
  STUDENT = 1,
  /**
   * @generated from protobuf enum value: TEACHER = 2;
   */
  TEACHER = 2,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.HomeworkLength
 */
export enum HomeworkLength {
  /**
   * @generated from protobuf enum value: HOMEWORK_LENGTH_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: HOMEWORK_LENGTH_COMPULSORY = 1;
   */
  COMPULSORY = 1,
  /**
   * @generated from protobuf enum value: HOMEWORK_LENGTH_HALF = 2;
   */
  HALF = 2,
  /**
   * @generated from protobuf enum value: HOMEWORK_LENGTH_OPTIONAL = 3;
   */
  OPTIONAL = 3,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.OnboardedReason
 */
export enum OnboardedReason {
  /**
   * @generated from protobuf enum value: INCOMPLETE = 0;
   */
  INCOMPLETE = 0,
  /**
   * @generated from protobuf enum value: ALREADY_ONBOARDED = 1;
   */
  ALREADY_ONBOARDED = 1,
  /**
   * @generated from protobuf enum value: MET_SKIP_THRESHOLD = 2;
   */
  MET_SKIP_THRESHOLD = 2,
  /**
   * @generated from protobuf enum value: COMPLETED_ALL_BOOKS = 3;
   */
  COMPLETED_ALL_BOOKS = 3,
  /**
   * @generated from protobuf enum value: COMPLETED_BOOKS_AND_SRT = 4;
   */
  COMPLETED_BOOKS_AND_SRT = 4,
  /**
   * @generated from protobuf enum value: COMPLETED_SRT_BASED_ONBOARDING = 5;
   */
  COMPLETED_SRT_BASED_ONBOARDING = 5,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.AccountStatus
 */
export enum AccountStatus {
  /**
   * @generated from protobuf enum value: ACCOUNT_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ACCOUNT_STATUS_PENDING_SETUP = 1;
   */
  PENDING_SETUP = 1,
  /**
   * @generated from protobuf enum value: ACCOUNT_STATUS_NEVER_LOGGED_IN = 2;
   */
  NEVER_LOGGED_IN = 2,
  /**
   * @generated from protobuf enum value: ACCOUNT_STATUS_FORGOTTEN_PASSWORD = 3;
   */
  FORGOTTEN_PASSWORD = 3,
  /**
   * @generated from protobuf enum value: ACCOUNT_STATUS_RESET_PASSWORD_TRIGGERED = 4;
   */
  RESET_PASSWORD_TRIGGERED = 4,
  /**
   * @generated from protobuf enum value: ACCOUNT_STATUS_READING_TEST_INCOMPLETE = 5;
   */
  READING_TEST_INCOMPLETE = 5,
  /**
   * @generated from protobuf enum value: ACCOUNT_STATUS_SETUP = 6;
   */
  SETUP = 6,
}
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
  constructor() {
    super('sparx.reading.users.v1.User', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 20,
        name: 'sparx_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'username', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'last_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 5, name: 'admin', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 6,
        name: 'type',
        kind: 'enum',
        T: () => ['sparx.reading.users.v1.UserType', UserType],
      },
      {
        no: 7,
        name: 'features',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 8, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 9,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 15,
        name: 'tutor_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 10, name: 'statistics', kind: 'message', T: () => Statistics },
      { no: 11, name: 'last_login', kind: 'message', T: () => Timestamp },
      { no: 12, name: 'gold_reader', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 13, name: 'settings', kind: 'message', T: () => Settings },
      {
        no: 14,
        name: 'homework_length',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.v1.HomeworkLength',
          HomeworkLength,
          'HOMEWORK_LENGTH_',
        ],
      },
      {
        no: 16,
        name: 'ask_sentiment_feedback_at',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 17,
        name: 'roles',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 18,
        name: 'league_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 19, name: 'date_of_birth', kind: 'message', T: () => Timestamp },
      { no: 21, name: 'first_login', kind: 'message', T: () => Timestamp },
      {
        no: 22,
        name: 'email_address',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 23,
        name: 'teacher_roles_update_time',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OAuthProvider$Type extends MessageType<OAuthProvider> {
  constructor() {
    super('sparx.reading.users.v1.OAuthProvider', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'hosted_domain',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.OAuthProvider
 */
export const OAuthProvider = new OAuthProvider$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSessionRequest$Type extends MessageType<GetSessionRequest> {
  constructor() {
    super('sparx.reading.users.v1.GetSessionRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.GetSessionRequest
 */
export const GetSessionRequest = new GetSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSessionResponse$Type extends MessageType<GetSessionResponse> {
  constructor() {
    super('sparx.reading.users.v1.GetSessionResponse', [
      { no: 2, name: 'user', kind: 'message', T: () => User },
      { no: 4, name: 'experience', kind: 'message', T: () => Experience },
      {
        no: 6,
        name: 'first_login_today',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 9, name: 'feature_flags', kind: 'message', T: () => Struct },
      {
        no: 10,
        name: 'school_year_start',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 11,
        name: 'intercom_hash',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.GetSessionResponse
 */
export const GetSessionResponse = new GetSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClearSessionRequest$Type extends MessageType<ClearSessionRequest> {
  constructor() {
    super('sparx.reading.users.v1.ClearSessionRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ClearSessionRequest
 */
export const ClearSessionRequest = new ClearSessionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClearSessionResponse$Type extends MessageType<ClearSessionResponse> {
  constructor() {
    super('sparx.reading.users.v1.ClearSessionResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ClearSessionResponse
 */
export const ClearSessionResponse = new ClearSessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogEventRequest$Type extends MessageType<LogEventRequest> {
  constructor() {
    super('sparx.reading.users.v1.LogEventRequest', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'contextJSON',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.LogEventRequest
 */
export const LogEventRequest = new LogEventRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogEventResponse$Type extends MessageType<LogEventResponse> {
  constructor() {
    super('sparx.reading.users.v1.LogEventResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.LogEventResponse
 */
export const LogEventResponse = new LogEventResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserActiveRequest$Type extends MessageType<UserActiveRequest> {
  constructor() {
    super('sparx.reading.users.v1.UserActiveRequest', [
      {
        no: 1,
        name: 'current_path',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UserActiveRequest
 */
export const UserActiveRequest = new UserActiveRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminUserActiveRequest$Type extends MessageType<AdminUserActiveRequest> {
  constructor() {
    super('sparx.reading.users.v1.AdminUserActiveRequest', [
      {
        no: 1,
        name: 'current_path',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.AdminUserActiveRequest
 */
export const AdminUserActiveRequest = new AdminUserActiveRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserActiveResponse$Type extends MessageType<UserActiveResponse> {
  constructor() {
    super('sparx.reading.users.v1.UserActiveResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UserActiveResponse
 */
export const UserActiveResponse = new UserActiveResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminUserActiveResponse$Type extends MessageType<AdminUserActiveResponse> {
  constructor() {
    super('sparx.reading.users.v1.AdminUserActiveResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.AdminUserActiveResponse
 */
export const AdminUserActiveResponse = new AdminUserActiveResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBookSettingsRequest$Type extends MessageType<UpdateBookSettingsRequest> {
  constructor() {
    super('sparx.reading.users.v1.UpdateBookSettingsRequest', [
      { no: 1, name: 'settings', kind: 'message', T: () => Settings },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateBookSettingsRequest
 */
export const UpdateBookSettingsRequest = new UpdateBookSettingsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBookSettingsResponse$Type extends MessageType<UpdateBookSettingsResponse> {
  constructor() {
    super('sparx.reading.users.v1.UpdateBookSettingsResponse', [
      { no: 1, name: 'settings', kind: 'message', T: () => Settings },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateBookSettingsResponse
 */
export const UpdateBookSettingsResponse = new UpdateBookSettingsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DoAdminGoldReaderActionRequest$Type extends MessageType<DoAdminGoldReaderActionRequest> {
  constructor() {
    super('sparx.reading.users.v1.DoAdminGoldReaderActionRequest', [
      {
        no: 1,
        name: 'actions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => DoAdminGoldReaderActionRequest_StudentAction,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.DoAdminGoldReaderActionRequest
 */
export const DoAdminGoldReaderActionRequest =
  new DoAdminGoldReaderActionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DoAdminGoldReaderActionRequest_StudentAction$Type extends MessageType<DoAdminGoldReaderActionRequest_StudentAction> {
  constructor() {
    super(
      'sparx.reading.users.v1.DoAdminGoldReaderActionRequest.StudentAction',
      [
        { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        {
          no: 2,
          name: 'action',
          kind: 'enum',
          T: () => [
            'sparx.reading.users.v1.DoAdminGoldReaderActionRequest.GoldReaderAction',
            DoAdminGoldReaderActionRequest_GoldReaderAction,
          ],
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.DoAdminGoldReaderActionRequest.StudentAction
 */
export const DoAdminGoldReaderActionRequest_StudentAction =
  new DoAdminGoldReaderActionRequest_StudentAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DoAdminGoldReaderActionResponse$Type extends MessageType<DoAdminGoldReaderActionResponse> {
  constructor() {
    super('sparx.reading.users.v1.DoAdminGoldReaderActionResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.DoAdminGoldReaderActionResponse
 */
export const DoAdminGoldReaderActionResponse =
  new DoAdminGoldReaderActionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetUserOnboardedRequest$Type extends MessageType<SetUserOnboardedRequest> {
  constructor() {
    super('sparx.reading.users.v1.SetUserOnboardedRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'reading_age_months',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.SetUserOnboardedRequest
 */
export const SetUserOnboardedRequest = new SetUserOnboardedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetUserOnboardedResponse$Type extends MessageType<SetUserOnboardedResponse> {
  constructor() {
    super('sparx.reading.users.v1.SetUserOnboardedResponse', [
      { no: 1, name: 'updated', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.SetUserOnboardedResponse
 */
export const SetUserOnboardedResponse = new SetUserOnboardedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamUserEventsRequest$Type extends MessageType<StreamUserEventsRequest> {
  constructor() {
    super('sparx.reading.users.v1.StreamUserEventsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.StreamUserEventsRequest
 */
export const StreamUserEventsRequest = new StreamUserEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserEvent$Type extends MessageType<UserEvent> {
  constructor() {
    super('sparx.reading.users.v1.UserEvent', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'data', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UserEvent
 */
export const UserEvent = new UserEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGoldReaderStateRequest$Type extends MessageType<GetGoldReaderStateRequest> {
  constructor() {
    super('sparx.reading.users.v1.GetGoldReaderStateRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.GetGoldReaderStateRequest
 */
export const GetGoldReaderStateRequest = new GetGoldReaderStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGoldReaderStateResponse$Type extends MessageType<GetGoldReaderStateResponse> {
  constructor() {
    super('sparx.reading.users.v1.GetGoldReaderStateResponse', [
      {
        no: 1,
        name: 'gold_reader_state',
        kind: 'message',
        T: () => GoldReaderState,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.GetGoldReaderStateResponse
 */
export const GetGoldReaderStateResponse = new GetGoldReaderStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetServerTimeRequest$Type extends MessageType<GetServerTimeRequest> {
  constructor() {
    super('sparx.reading.users.v1.GetServerTimeRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.GetServerTimeRequest
 */
export const GetServerTimeRequest = new GetServerTimeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetServerTimeResponse$Type extends MessageType<GetServerTimeResponse> {
  constructor() {
    super('sparx.reading.users.v1.GetServerTimeResponse', [
      { no: 1, name: 'server_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.GetServerTimeResponse
 */
export const GetServerTimeResponse = new GetServerTimeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOnboardingStatusRequest$Type extends MessageType<UpdateOnboardingStatusRequest> {
  constructor() {
    super('sparx.reading.users.v1.UpdateOnboardingStatusRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateOnboardingStatusRequest
 */
export const UpdateOnboardingStatusRequest =
  new UpdateOnboardingStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOnboardingStatusResponse$Type extends MessageType<UpdateOnboardingStatusResponse> {
  constructor() {
    super('sparx.reading.users.v1.UpdateOnboardingStatusResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateOnboardingStatusResponse
 */
export const UpdateOnboardingStatusResponse =
  new UpdateOnboardingStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserReadingAgeRequest$Type extends MessageType<UpdateUserReadingAgeRequest> {
  constructor() {
    super('sparx.reading.users.v1.UpdateUserReadingAgeRequest', [
      {
        no: 1,
        name: 'user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
        options: { 'sparx.api.validation.v1.field': { uuid: true } },
      },
      {
        no: 2,
        name: 'reading_age_months',
        kind: 'scalar',
        opt: true,
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateUserReadingAgeRequest
 */
export const UpdateUserReadingAgeRequest =
  new UpdateUserReadingAgeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserReadingAgeResponse$Type extends MessageType<UpdateUserReadingAgeResponse> {
  constructor() {
    super('sparx.reading.users.v1.UpdateUserReadingAgeResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateUserReadingAgeResponse
 */
export const UpdateUserReadingAgeResponse =
  new UpdateUserReadingAgeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserDateOfBirthRequest$Type extends MessageType<UpdateUserDateOfBirthRequest> {
  constructor() {
    super('sparx.reading.users.v1.UpdateUserDateOfBirthRequest', [
      {
        no: 1,
        name: 'user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
        options: { 'sparx.api.validation.v1.field': { uuid: true } },
      },
      { no: 2, name: 'date_of_birth', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateUserDateOfBirthRequest
 */
export const UpdateUserDateOfBirthRequest =
  new UpdateUserDateOfBirthRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserDateOfBirthResponse$Type extends MessageType<UpdateUserDateOfBirthResponse> {
  constructor() {
    super('sparx.reading.users.v1.UpdateUserDateOfBirthResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateUserDateOfBirthResponse
 */
export const UpdateUserDateOfBirthResponse =
  new UpdateUserDateOfBirthResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentBookProgressRequest$Type extends MessageType<UpdateStudentBookProgressRequest> {
  constructor() {
    super('sparx.reading.users.v1.UpdateStudentBookProgressRequest', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'progress', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateStudentBookProgressRequest
 */
export const UpdateStudentBookProgressRequest =
  new UpdateStudentBookProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateStudentBookProgressResponse$Type extends MessageType<UpdateStudentBookProgressResponse> {
  constructor() {
    super('sparx.reading.users.v1.UpdateStudentBookProgressResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateStudentBookProgressResponse
 */
export const UpdateStudentBookProgressResponse =
  new UpdateStudentBookProgressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BaselineAssessmentAnswer$Type extends MessageType<BaselineAssessmentAnswer> {
  constructor() {
    super('sparx.reading.users.v1.BaselineAssessmentAnswer', [
      { no: 1, name: 'question', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'selected_option',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'all_options',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 4, name: 'create_time', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.BaselineAssessmentAnswer
 */
export const BaselineAssessmentAnswer = new BaselineAssessmentAnswer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBaselineAssessmentAnswersRequest$Type extends MessageType<ListBaselineAssessmentAnswersRequest> {
  constructor() {
    super('sparx.reading.users.v1.ListBaselineAssessmentAnswersRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ListBaselineAssessmentAnswersRequest
 */
export const ListBaselineAssessmentAnswersRequest =
  new ListBaselineAssessmentAnswersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBaselineAssessmentAnswersResponse$Type extends MessageType<ListBaselineAssessmentAnswersResponse> {
  constructor() {
    super('sparx.reading.users.v1.ListBaselineAssessmentAnswersResponse', [
      {
        no: 1,
        name: 'answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BaselineAssessmentAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ListBaselineAssessmentAnswersResponse
 */
export const ListBaselineAssessmentAnswersResponse =
  new ListBaselineAssessmentAnswersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveBaselineAssessmentAnswersRequest$Type extends MessageType<SaveBaselineAssessmentAnswersRequest> {
  constructor() {
    super('sparx.reading.users.v1.SaveBaselineAssessmentAnswersRequest', [
      {
        no: 1,
        name: 'answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BaselineAssessmentAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.SaveBaselineAssessmentAnswersRequest
 */
export const SaveBaselineAssessmentAnswersRequest =
  new SaveBaselineAssessmentAnswersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveBaselineAssessmentAnswersResponse$Type extends MessageType<SaveBaselineAssessmentAnswersResponse> {
  constructor() {
    super('sparx.reading.users.v1.SaveBaselineAssessmentAnswersResponse', [
      {
        no: 1,
        name: 'answers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => BaselineAssessmentAnswer,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.SaveBaselineAssessmentAnswersResponse
 */
export const SaveBaselineAssessmentAnswersResponse =
  new SaveBaselineAssessmentAnswersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShouldUserAnswerBaselineAssessmentRequest$Type extends MessageType<ShouldUserAnswerBaselineAssessmentRequest> {
  constructor() {
    super(
      'sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentRequest',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentRequest
 */
export const ShouldUserAnswerBaselineAssessmentRequest =
  new ShouldUserAnswerBaselineAssessmentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShouldUserAnswerBaselineAssessmentResponse$Type extends MessageType<ShouldUserAnswerBaselineAssessmentResponse> {
  constructor() {
    super('sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentResponse', [
      { no: 1, name: 'decision', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.ShouldUserAnswerBaselineAssessmentResponse
 */
export const ShouldUserAnswerBaselineAssessmentResponse =
  new ShouldUserAnswerBaselineAssessmentResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserOnboardingProgressRequest$Type extends MessageType<GetUserOnboardingProgressRequest> {
  constructor() {
    super('sparx.reading.users.v1.GetUserOnboardingProgressRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.GetUserOnboardingProgressRequest
 */
export const GetUserOnboardingProgressRequest =
  new GetUserOnboardingProgressRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserOnboardingProgressResponse$Type extends MessageType<GetUserOnboardingProgressResponse> {
  constructor() {
    super('sparx.reading.users.v1.GetUserOnboardingProgressResponse', [
      {
        no: 1,
        name: 'status',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.v1.OnboardingStatus',
          OnboardingStatus,
          'ONBOARDING_STATUS_',
        ],
      },
      {
        no: 2,
        name: 'tasks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => OnboardingTask,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.GetUserOnboardingProgressResponse
 */
export const GetUserOnboardingProgressResponse =
  new GetUserOnboardingProgressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTeacherRolesUpdateTimeRequest$Type extends MessageType<UpdateTeacherRolesUpdateTimeRequest> {
  constructor() {
    super('sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeRequest', [
      { no: 1, name: 'timestamp', kind: 'message', T: () => Timestamp },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeRequest
 */
export const UpdateTeacherRolesUpdateTimeRequest =
  new UpdateTeacherRolesUpdateTimeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTeacherRolesUpdateTimeResponse$Type extends MessageType<UpdateTeacherRolesUpdateTimeResponse> {
  constructor() {
    super('sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.UpdateTeacherRolesUpdateTimeResponse
 */
export const UpdateTeacherRolesUpdateTimeResponse =
  new UpdateTeacherRolesUpdateTimeResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.users.v1.Sessions
 */
export const Sessions = new ServiceType('sparx.reading.users.v1.Sessions', [
  {
    name: 'GetSession',
    options: {},
    I: GetSessionRequest,
    O: GetSessionResponse,
  },
  {
    name: 'ClearSession',
    options: {},
    I: ClearSessionRequest,
    O: ClearSessionResponse,
  },
  { name: 'LogEvent', options: {}, I: LogEventRequest, O: LogEventResponse },
  {
    name: 'UserActive',
    options: {},
    I: UserActiveRequest,
    O: UserActiveResponse,
  },
  {
    name: 'AdminUserActive',
    options: {},
    I: AdminUserActiveRequest,
    O: AdminUserActiveResponse,
  },
  {
    name: 'UpdateBookSettings',
    options: {},
    I: UpdateBookSettingsRequest,
    O: UpdateBookSettingsResponse,
  },
  {
    name: 'DoAdminGoldReaderAction',
    options: {},
    I: DoAdminGoldReaderActionRequest,
    O: DoAdminGoldReaderActionResponse,
  },
  {
    name: 'SetUserOnboarded',
    options: {},
    I: SetUserOnboardedRequest,
    O: SetUserOnboardedResponse,
  },
  {
    name: 'StreamUserEvents',
    serverStreaming: true,
    options: {},
    I: StreamUserEventsRequest,
    O: UserEvent,
  },
  {
    name: 'GetGoldReaderState',
    options: {},
    I: GetGoldReaderStateRequest,
    O: GetGoldReaderStateResponse,
  },
  {
    name: 'GetServerTime',
    options: {},
    I: GetServerTimeRequest,
    O: GetServerTimeResponse,
  },
  {
    name: 'UpdateOnboardingStatus',
    options: {},
    I: UpdateOnboardingStatusRequest,
    O: UpdateOnboardingStatusResponse,
  },
  {
    name: 'UpdateUserReadingAge',
    options: {},
    I: UpdateUserReadingAgeRequest,
    O: UpdateUserReadingAgeResponse,
  },
  {
    name: 'UpdateStudentBookProgress',
    options: {},
    I: UpdateStudentBookProgressRequest,
    O: UpdateStudentBookProgressResponse,
  },
  {
    name: 'UpdateUserDateOfBirth',
    options: {},
    I: UpdateUserDateOfBirthRequest,
    O: UpdateUserDateOfBirthResponse,
  },
  {
    name: 'ListBaselineAssessmentAnswers',
    options: {},
    I: ListBaselineAssessmentAnswersRequest,
    O: ListBaselineAssessmentAnswersResponse,
  },
  {
    name: 'SaveBaselineAssessmentAnswers',
    options: {},
    I: SaveBaselineAssessmentAnswersRequest,
    O: SaveBaselineAssessmentAnswersResponse,
  },
  {
    name: 'ShouldUserAnswerBaselineAssessment',
    options: {},
    I: ShouldUserAnswerBaselineAssessmentRequest,
    O: ShouldUserAnswerBaselineAssessmentResponse,
  },
  {
    name: 'GetUserOnboardingProgress',
    options: {},
    I: GetUserOnboardingProgressRequest,
    O: GetUserOnboardingProgressResponse,
  },
  {
    name: 'UpdateTeacherRolesUpdateTime',
    options: {},
    I: UpdateTeacherRolesUpdateTimeRequest,
    O: UpdateTeacherRolesUpdateTimeResponse,
  },
]);
