import {
  BooklogReference,
  SubmitBooklogRange,
} from '@sparx/api/apis/sparx/reading/tasks/v1/booklog';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import classNames from 'classnames';
import { Button } from 'components/buttons';
import { getBooklogTaskSpec, getBooklogTaskState, useTaskActionsLoading } from 'queries/tasks';
import { useState } from 'react';

import { BooklogRangeInput } from '../booklog-range-input';
import styles from '../booklog-task-view.module.css';
import { validRange } from '../utils';

interface IStartSessionProps {
  task: Task;
  onSubmit: (req: SubmitBooklogRange) => void;
  onBack?: () => void;
}

export const StartSession = ({ task, onSubmit }: IStartSessionProps) => {
  const booklogState = getBooklogTaskState(task);
  const booklogSpec = getBooklogTaskSpec(task);

  const initial: SubmitBooklogRange = {
    timeSpentMinutes: 0,
    markFinished: false,
  };
  const start: BooklogReference = booklogState?.start || {
    page: 0,
    chapter: '',
    firstWord: '',
    lastWord: '',
  };
  const [req, setRequest] = useState({
    ...initial,
    start,
  });
  const valid = validRange(req.start);
  const submit = () => onSubmit(req);
  const loading = useTaskActionsLoading();

  return (
    <>
      <h2 className={classNames(styles.StageTitle, styles.StartSessionTitle)}>
        Start your next reading session
      </h2>
      <p className={styles.StageSubtitle}>
        This will <strong>show your teacher</strong> how much reading you have been doing.
      </p>
      <BooklogRangeInput
        side="start"
        onChange={start => setRequest({ ...req, start })}
        defaultValue={booklogState?.start || booklogSpec?.lastEnd}
      />
      <div className={classNames(styles.ActionButtons, styles.SingleActionButton)}>
        <Button analyticsEvent={undefined} disabled={!valid} onClick={submit} loading={loading}>
          Next
        </Button>
      </div>
    </>
  );
};
