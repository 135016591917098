import {
  faBookReader,
  faCheck,
  faSadTear,
  faTimes,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './panel-icons.module.css';

export const PanelIconTick = () => {
  return (
    <div className={`${styles.PanelIcon} ${styles.PanelIconGreen}`}>
      <FontAwesomeIcon icon={faCheck} />
    </div>
  );
};

export const PanelIconCross = () => {
  return (
    <div className={`${styles.PanelIcon} ${styles.PanelIconCross}`}>
      <FontAwesomeIcon icon={faTimes} className={styles.PanelIconCrossText} />
    </div>
  );
};

export const PanelIconTrophy = () => {
  return (
    <div className={`${styles.PanelIcon} ${styles.PanelIconGreen}`}>
      <FontAwesomeIcon icon={faTrophy} />
    </div>
  );
};

export const PanelIconTear = () => {
  return (
    <div className={`${styles.PanelIcon} ${styles.PanelIconOrange}`}>
      <FontAwesomeIcon icon={faSadTear} />
    </div>
  );
};

export const PanelIconBook = () => {
  return (
    <div className={`${styles.PanelIcon} ${styles.PanelIconGrey}`}>
      <FontAwesomeIcon icon={faBookReader} />
    </div>
  );
};
