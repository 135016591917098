import {
  BatchPreviewHomeworkGroupDatesResponse,
  BatchUpdateHomeworkGroupsResponse,
  DeleteHomeworkResponse,
  EndHomeworkGroupRequest,
  HomeworkGroup,
  PreviewHomeworkGroupDatesResponse,
  UpdateHomeworkGroupResponse,
  UpdateHomeworkResponse,
} from '@sparx/api/apis/sparx/reading/management/v1/management';
import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { GetHomeworksResponse } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { managementClient } from 'api';
import moment from 'moment';
import { timestampToMoment } from 'utils/time';

import { queryClient } from './client';

export const useUpdateHomeworkGroup = (
  options?: Omit<
    UseMutationOptions<UpdateHomeworkGroupResponse, Error, HomeworkGroup>,
    'mutationFn'
  >,
) =>
  useMutation({
    mutationFn: (homeworkGroup: HomeworkGroup) =>
      managementClient.updateHomeworkGroup({ homeworkGroup }).response,
    ...options,
  });

export const useBatchUpdateHomeworkGroups = (
  options?: Omit<
    UseMutationOptions<BatchUpdateHomeworkGroupsResponse, Error, HomeworkGroup[]>,
    'mutationFn'
  >,
) =>
  useMutation({
    mutationFn: (homeworkGroups: HomeworkGroup[]) =>
      managementClient.batchUpdateHomeworkGroups({ homeworkGroups }).response,
    ...options,
  });

export const useEndHomeworkGroup = () =>
  useMutation((req: EndHomeworkGroupRequest) => managementClient.endHomeworkGroup(req).response, {
    onSuccess: () => {
      setHomeworkGroupsStale();
      setAllHomeworksStale();
    },
  });

export const setHomeworkGroupsStale = () =>
  queryClient.invalidateQueries(['teacher', 'homeworkgroups']);
export const setAllHomeworksStale = () => queryClient.invalidateQueries(['homeworks', 'all']);

export const useHomeworkGroupPreview = (
  homeworkGroup: HomeworkGroup,
  options?: Partial<
    UseQueryOptions<
      PreviewHomeworkGroupDatesResponse,
      Error,
      PreviewHomeworkGroupDatesResponse,
      [string, HomeworkGroup]
    >
  >,
) =>
  useQuery(
    ['homeworkgrouppreview', homeworkGroup],
    () => managementClient.previewHomeworkGroupDates({ homeworkGroup }).response,
    {
      retry: false,
      keepPreviousData: true,
      ...options,
    },
  );

export const useBatchHomeworkGroupPreview = (
  homeworkGroups: HomeworkGroup[],
  options?: Partial<
    UseQueryOptions<
      BatchPreviewHomeworkGroupDatesResponse,
      Error,
      BatchPreviewHomeworkGroupDatesResponse,
      [string, HomeworkGroup[]]
    >
  >,
) =>
  useQuery(
    ['batchhomeworkgrouppreview', homeworkGroups],
    () => managementClient.batchPreviewHomeworkGroupDates({ homeworkGroups }).response,
    {
      retry: false,
      keepPreviousData: true,
      ...options,
    },
  );

export const useHomeworkGroups = () =>
  useQuery(['teacher', 'homeworkgroups'], () => managementClient.listHomeworkGroups({}).response, {
    select: d => d.groups,
  });

export const useCreateClasswork = (
  options: UseMutationOptions<UpdateHomeworkResponse, Error, Homework> = {},
) =>
  useMutation(homework => managementClient.updateHomework({ homework }).response, {
    ...options,
    onSuccess: (resp, request, ctx) => {
      // Ensure we update the homeworks state after this, then call
      // `options.onSuccess` if provided for any additional handling.
      queryClient.setQueryData(
        ['homeworks', 'all'],
        (previous: GetHomeworksResponse | undefined) => ({
          extraPackages: previous?.extraPackages ?? [],
          homeworks: resp.homework
            ? (previous?.homeworks || []).concat(resp.homework)
            : previous?.homeworks || [],
        }),
      );
      return options.onSuccess && options.onSuccess(resp, request, ctx);
    },
  });

export const useEditHomework = (
  options: UseMutationOptions<UpdateHomeworkResponse, Error, Homework> = {},
) =>
  useMutation(homework => managementClient.updateHomework({ homework }).response, {
    ...options,
    onSuccess: (resp, request, ctx) => {
      // Ensure we update the homeworks state after this, then call
      // `options.onSuccess` if provided for any additional handling.
      queryClient.setQueryData(
        ['homeworks', 'all'],
        (previous: GetHomeworksResponse | undefined) => ({
          extraPackages: previous?.extraPackages ?? [],
          homeworks:
            previous?.homeworks?.map(hw =>
              hw.homeworkId === resp.homework?.homeworkId ? resp.homework : hw,
            ) || [],
        }),
      );
      return options.onSuccess && options.onSuccess(resp, request, ctx);
    },
  });

export const useDeleteHomework = (
  options: UseMutationOptions<DeleteHomeworkResponse, Error, string> = {},
) =>
  useMutation(homeworkId => managementClient.deleteHomework({ homeworkId }).response, {
    ...options,
    onSuccess: (resp, homeworkId, ctx) => {
      // Ensure we update the homeworks state after this, then call
      // `options.onSuccess` if provided for any additional handling.
      queryClient.setQueryData(
        ['homeworks', 'all'],
        (previous: GetHomeworksResponse | undefined) => ({
          extraPackages: previous?.extraPackages ?? [],
          homeworks: previous?.homeworks?.filter(hw => hw.homeworkId !== homeworkId) || [],
        }),
      );
      return options.onSuccess && options.onSuccess(resp, homeworkId, ctx);
    },
  });

export const futureHomeworks = (homeworks: Homework[]) =>
  homeworks.filter(hw => timestampToMoment(hw.startDate).isAfter(moment()));

export const useListHomeworkViews = (homeworkIds: string[]) =>
  useQuery(
    ['homeworkViews', ...homeworkIds],
    () => managementClient.listHomeworkViews({ homeworkIds }).response,
  );

export const useCreateHomeworkView = () =>
  useMutation((homeworkId: string) => managementClient.createHomeworkView({ homeworkId }).response);
