import { ModuleSpec } from '@sparx/teacher-training';
import { Flags, useBooleanFlag } from 'utils/featureflags';

import {
  getModuleUsingSparxReader,
  moduleEssential,
  moduleTrinityLaunchingReader,
  moduleTrinitySuccessWithReader,
} from './module-specs';

export const useTrainingModules = (): ModuleSpec[] => {
  const skipSrtOnboarding = useBooleanFlag(Flags.SkipSrtOnboarding);
  return [
    moduleEssential,
    getModuleUsingSparxReader(skipSrtOnboarding),
    moduleTrinityLaunchingReader,
    moduleTrinitySuccessWithReader,
  ];
};
