import { IdentifierType } from '@sparx/api/apis/sparx/reading/books/v1/book';
import {
  StudentBook,
  StudentBook_SwapState,
} from '@sparx/api/apis/sparx/reading/content/v1/service';
import {
  LibraryBook,
  ListBookChoiceRequest,
  ListBookChoiceResponse,
  ListLibraryBooksRequest,
  ListLibraryBooksResponse,
  ListNewBooksRequest,
  ListNewBooksResponse,
  ListOnboardingLibraryBooksRequest,
  ListOnboardingLibraryBooksResponse,
  UpdateLibraryBookRequest,
  UpdateLibraryBookResponse,
} from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { libraryBooksClient } from 'api';
import { useNavigate } from 'react-router-dom';
import { timestampToMoment } from 'utils/time';
import { View } from 'views';
import { pathForView } from 'views/views';

import { setBooksStale } from './books';
import { queryClient } from './client';

export const LIBRARY_BOOKS_QUERY_KEY = 'library-books';
export const LIBRARY_BOOKS_ADMIN_QUERY_KEY = 'library-books-admin';
export const ONBOARDING_LIBRARY_BOOKS_QUERY_KEY = 'onboarding-library-books';
export const NEW_BOOKS_QUERY_KEY = 'new-books';

const MAX_ACTIVE_BOOKS = 2;

export const useListLibraryBooks = <T = ListLibraryBooksResponse>(
  options?: UseQueryOptions<ListLibraryBooksResponse, Error, T, string[]>,
) =>
  useQuery(
    [LIBRARY_BOOKS_QUERY_KEY],
    () => libraryBooksClient.listLibraryBooks(ListLibraryBooksRequest.create()).response,
    {
      staleTime: 1000 * 15,
      ...options,
    },
  );

export const useBookChoice = <T = ListBookChoiceResponse>(
  req: ListBookChoiceRequest,
  options?: UseQueryOptions<ListBookChoiceResponse, Error, T, string[]>,
) =>
  useQuery(
    [
      'book_choice',
      req.userId,
      req.userReadingAge.toString(),
      req.version.toString(),
      JSON.stringify(req.config),
      JSON.stringify(req.v3Config),
    ],
    () => libraryBooksClient.listBookChoice(req).response,
    {
      staleTime: 1000 * 15,
      enabled: Boolean(req.userId) && (req.userReadingAge === 0 || !isNaN(req.userReadingAge)),
      ...options,
    },
  );

export const useStudentBooksMap = () =>
  useListLibraryBooks({
    select: data => libraryBooksToMap(data.libraryBooks),
  });

export const libraryBooksToMap = (libraryBooks: LibraryBook[]) =>
  libraryBooks.reduce<Record<string, LibraryBook[]>>((p, v) => {
    if (v.studentBook) {
      p[v.studentBook.bookId] = (p[v.studentBook.bookId] || []).concat(v);
    }
    return p;
  }, {});

const MINIMUM_MINS_BETWEEN_GOLD_SCANS = 10;
export const selectNextScanTime = (data: ListLibraryBooksResponse): moment.Moment | undefined => {
  const goldBookIDs = new Set(
    data.libraryBooks
      .filter(b => b.metadataAbridged !== undefined && !b.metadataAbridged?.ebookActive)
      .map(b => b.metadataAbridged?.name.split('books/')[1]),
  );

  if (goldBookIDs.size === 0) {
    return undefined;
  }
  const mostRecentGoldBook = data.libraryBooks
    .filter(
      lb =>
        goldBookIDs.has(lb.studentBook?.bookId) && lb?.studentBook?.startedTimestamp !== undefined,
    )
    .sort((a, b) =>
      timestampToMoment(a?.studentBook?.startedTimestamp).isAfter(
        timestampToMoment(b?.studentBook?.startedTimestamp),
      )
        ? -1
        : 1,
    )[0];

  const nextScanTime = timestampToMoment(mostRecentGoldBook?.studentBook?.startedTimestamp)
    .clone()
    .add(MINIMUM_MINS_BETWEEN_GOLD_SCANS, 'minutes');

  return nextScanTime;
};

export const useNextScanTime = () =>
  useListLibraryBooks({
    select: selectNextScanTime,
  });

export const useLibraryBookByPackage = (packageID: string) =>
  useListLibraryBooks({
    select: data => data.libraryBooks?.find(lb => lb.studentBook?.packageId === packageID),
  });

// Swapped books are shown on the new book choice page.
// A swapped book is available to be restarted when:
// - it is an ebook
// - AND it is not complete
// - AND it has been swapped by either the student or the teacher
// - AND it is not at the end of the content
// - AND it is not a training book
// - AND it does not have a minimum age above the student's age
export const useListSwappedLibraryBooks = () =>
  useListLibraryBooks({
    select: data =>
      data.libraryBooks.filter(
        lb =>
          lb.studentBook?.isEbook &&
          lb.studentBook?.swapped === StudentBook_SwapState.STATE_SWAPPED &&
          !lb.studentBook.isComplete &&
          !lb.studentBook.atContentEnd &&
          !lb.studentBook.isTraining &&
          !lb.studentBook.hasMinAgeAboveStudentAge,
      ),
  });

// Current books are books which can be shown on the home view widget.
// They are ebooks or Gold reader books which are not swapped or complete.
export const useListCurrentLibraryBooks = () =>
  useListLibraryBooks({
    select: data =>
      data.libraryBooks.filter(
        lb =>
          lb.studentBook !== undefined &&
          lb.studentBook?.swapped !== StudentBook_SwapState.STATE_SWAPPED &&
          !lb.studentBook?.isComplete &&
          !lb.studentBook?.atContentEnd,
      ),
  });

// Books which appear in 'My Books'. The books are filtered to include not complete, not swapped
// ebooks, and sorted.
export const useListMyLibraryBooks = () =>
  useListLibraryBooks({
    select: data => {
      return data.libraryBooks.filter(
        lb =>
          lb.studentBook?.isEbook &&
          lb.studentBook?.swapped !== StudentBook_SwapState.STATE_SWAPPED &&
          !lb.studentBook.isComplete &&
          !lb.studentBook.atContentEnd,
      );
    },
  });

// Books which appear in 'Completed' are ebooks which are complete.
export const useListCompletedLibraryBooks = () =>
  useListLibraryBooks({
    select: data =>
      data.libraryBooks.filter(lb => lb.studentBook?.isComplete || lb.studentBook?.atContentEnd),
  });

// TODO on this PR - consider using this for the scan time
// Books which appear in 'Gold Reader books' are non-ebooks which are not complete.
export const useListGoldReaderBooks = () =>
  useListLibraryBooks({
    select: data => {
      return data.libraryBooks.filter(
        lb =>
          lb.studentBook !== undefined && !lb.studentBook?.isEbook && !lb.studentBook?.isComplete,
      );
    },
  });

export const useListNewBooks = <T = ListNewBooksResponse>(
  options?: UseQueryOptions<ListNewBooksResponse, Error, T, string[]>,
) =>
  useQuery(
    [NEW_BOOKS_QUERY_KEY],
    () => libraryBooksClient.listNewBooks(ListNewBooksRequest.create()).response,
    {
      staleTime: 1000 * 15,
      ...options,
    },
  );

// useUpdateLibraryBook updates a library book on the server and then uses the response
// to update the library-books query cache.
export const useUpdateLibraryBook = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (req: UpdateLibraryBookRequest) => libraryBooksClient.updateLibraryBook(req).response,
    {
      onSuccess: updatedLibraryBook => {
        queryClient.setQueryData(
          [LIBRARY_BOOKS_QUERY_KEY],
          (oldState: ListLibraryBooksResponse | undefined) =>
            createNewLibraryBookState(oldState, updatedLibraryBook),
        );
        setBooksStale();
      },
    },
  );
};

export const createNewLibraryBookState = (
  oldState: ListLibraryBooksResponse | undefined,
  updatedLibraryBook: UpdateLibraryBookResponse,
) => {
  if (updatedLibraryBook.libraryBook === undefined)
    return oldState || ListLibraryBooksResponse.create();
  const newState = {
    libraryBooks:
      oldState?.libraryBooks.map(lb =>
        studentBooksMatch(lb.studentBook, updatedLibraryBook.libraryBook?.studentBook)
          ? updatedLibraryBook.libraryBook || lb
          : lb,
      ) || [],
  };
  return newState;
};

// Update a library book's student book without changing its metadata. This is
// useful for keeping the active book state refreshed from task responses.
export const updateLibraryStudentBook = (studentBook: StudentBook) => {
  if (queryClient.getQueryState([LIBRARY_BOOKS_QUERY_KEY]) === undefined) {
    return;
  }

  queryClient.setQueryData(
    [LIBRARY_BOOKS_QUERY_KEY],
    (oldState: ListLibraryBooksResponse | undefined) => {
      return oldState
        ? {
            libraryBooks: oldState.libraryBooks.map(lb =>
              studentBooksMatch(lb.studentBook, studentBook) ? { ...lb, studentBook } : lb,
            ),
          }
        : ListLibraryBooksResponse.create();
    },
  );
};

const studentBooksMatch = (a?: StudentBook, b?: StudentBook) => {
  if (a === undefined || b === undefined) return false;
  return a?.bookId === b?.bookId && a?.attemptIndex === b?.attemptIndex;
};

export const useListOnboardingLibraryBooks = <T = ListOnboardingLibraryBooksResponse>(
  options?: UseQueryOptions<ListOnboardingLibraryBooksResponse, Error, T, string[]>,
) => {
  return useQuery(
    [ONBOARDING_LIBRARY_BOOKS_QUERY_KEY],
    () =>
      libraryBooksClient.listOnboardingLibraryBooks(ListOnboardingLibraryBooksRequest.create())
        .response,
    {
      ...options,
    },
  );
};

/**
 * useTryAnotherBook marks the given book as swapped and navigates to the book selection page.
 * The book selection page varies depending on whether the user is in onboarding or not.
 */
export const useTryAnotherBook = () => {
  const navigate = useNavigate();
  const updateLibraryBook = useUpdateLibraryBook();
  const { data: listLibraryBooksResponse } = useListLibraryBooks();

  // Can only start a new book if they have fewer than MAX_ACTIVE_BOOKS (not
  // including this one as it will be swapped).
  const libraryBooks = listLibraryBooksResponse?.libraryBooks || [];
  const canStartNew = libraryBooks.filter(b => b.studentBook?.isActive).length <= MAX_ACTIVE_BOOKS;

  return (studentBook?: StudentBook, chooseNewBook?: boolean, swappedReason?: string) => {
    // If a book is provided, mark it as swapped out.
    if (studentBook) {
      updateLibraryBook.mutate(
        {
          studentBook: {
            ...studentBook,
            swapped: StudentBook_SwapState.STATE_SWAPPED,
          },
          swappedReason,
        },
        {
          onSettled: () => {
            // Change the view.
            const view = canStartNew && chooseNewBook ? View.ChooseNewBook : View.Explore;
            navigate(pathForView(view), {
              state: { popped: true },
            });
          },
        },
      );
    }
  };
};

export const useLibraryBook = (bookID: string) =>
  useListLibraryBooks({
    select: data =>
      data.libraryBooks.find(b =>
        b.metadataAbridged?.identifiers?.find(
          bid => bid.type === IdentifierType.SPARX_READER_UUID && bid.value === bookID,
        ),
      ),
    enabled: Boolean(bookID),
  });

export const useCanStartNewBook = () => {
  const { data: listLibraryBooksResponse } = useListLibraryBooks();
  const libraryBooks = listLibraryBooksResponse?.libraryBooks || [];
  return libraryBooks.filter(b => b.studentBook?.isActive).length < MAX_ACTIVE_BOOKS;
};
