// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/leaderboards/userdisplay/v1/userdisplay.proto" (package "sparx.leaderboards.userdisplay.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { UserDisplay } from './userdisplay';
import type { OptInToProductLeaderboardResponse } from './userdisplay';
import type { OptInToProductLeaderboardRequest } from './userdisplay';
import type { OptOutOfProductLeaderboardResponse } from './userdisplay';
import type { OptOutOfProductLeaderboardRequest } from './userdisplay';
import type { GeneratePositiveNounResponse } from './userdisplay';
import type { GeneratePositiveNounRequest } from './userdisplay';
import type { BatchGetUserDisplayDataResponse } from './userdisplay';
import type { BatchGetUserDisplayDataRequest } from './userdisplay';
import type { UpdateUserDisplayDataForCurrentUserRequest } from './userdisplay';
import type { UpdateUserDisplayDataRequest } from './userdisplay';
import type { GetUserDisplayDataForCurrentUserRequest } from './userdisplay';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { UserDisplayData } from './userdisplay';
import type { GetUserDisplayDataRequest } from './userdisplay';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.leaderboards.userdisplay.v1.UserDisplay
 */
export interface IUserDisplayClient {
  /**
   * GetUserDisplayData returns the display data for the requested user.
   *
   * @generated from protobuf rpc: GetUserDisplayData(sparx.leaderboards.userdisplay.v1.GetUserDisplayDataRequest) returns (sparx.leaderboards.userdisplay.v1.UserDisplayData);
   */
  getUserDisplayData(
    input: GetUserDisplayDataRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUserDisplayDataRequest, UserDisplayData>;
  /**
   * GetUserDisplayDataForCurrentUser returns the display data for the requesting user.
   *
   * @generated from protobuf rpc: GetUserDisplayDataForCurrentUser(sparx.leaderboards.userdisplay.v1.GetUserDisplayDataForCurrentUserRequest) returns (sparx.leaderboards.userdisplay.v1.UserDisplayData);
   */
  getUserDisplayDataForCurrentUser(
    input: GetUserDisplayDataForCurrentUserRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUserDisplayDataForCurrentUserRequest, UserDisplayData>;
  /**
   * UpdateUserDisplayData upserts the user display data for the requested user.
   *
   * @generated from protobuf rpc: UpdateUserDisplayData(sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataRequest) returns (sparx.leaderboards.userdisplay.v1.UserDisplayData);
   */
  updateUserDisplayData(
    input: UpdateUserDisplayDataRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUserDisplayDataRequest, UserDisplayData>;
  /**
   * UpdateUserDisplayData upserts the requesting user's display data.
   *
   * @generated from protobuf rpc: UpdateUserDisplayDataForCurrentUser(sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataForCurrentUserRequest) returns (sparx.leaderboards.userdisplay.v1.UserDisplayData);
   */
  updateUserDisplayDataForCurrentUser(
    input: UpdateUserDisplayDataForCurrentUserRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUserDisplayDataForCurrentUserRequest, UserDisplayData>;
  /**
   * BatchGetUserDisplayData gets the userdisplaydata for the given resource names.
   * This creates display data for any users which do not exist, assigning them
   * a random display name.
   * Users who are opted out of the given product will not be returned.
   *
   * @generated from protobuf rpc: BatchGetUserDisplayData(sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataRequest) returns (sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataResponse);
   */
  batchGetUserDisplayData(
    input: BatchGetUserDisplayDataRequest,
    options?: RpcOptions,
  ): UnaryCall<BatchGetUserDisplayDataRequest, BatchGetUserDisplayDataResponse>;
  /**
   * GeneratePositiveNoun returns a random positive noun which may be used in display data.
   *
   * @generated from protobuf rpc: GeneratePositiveNoun(sparx.leaderboards.userdisplay.v1.GeneratePositiveNounRequest) returns (sparx.leaderboards.userdisplay.v1.GeneratePositiveNounResponse);
   */
  generatePositiveNoun(
    input: GeneratePositiveNounRequest,
    options?: RpcOptions,
  ): UnaryCall<GeneratePositiveNounRequest, GeneratePositiveNounResponse>;
  /**
   * OptOutOfProductLeaderboard adds the requested product to the list of
   * opted-out products for the requested user.
   *
   * @generated from protobuf rpc: OptOutOfProductLeaderboard(sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardRequest) returns (sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardResponse);
   */
  optOutOfProductLeaderboard(
    input: OptOutOfProductLeaderboardRequest,
    options?: RpcOptions,
  ): UnaryCall<
    OptOutOfProductLeaderboardRequest,
    OptOutOfProductLeaderboardResponse
  >;
  /**
   * OptInToProductLeaderboard removes the requested product from the list of
   * opted-out products for the requested user. Does nothing if user has not
   * opted out.
   *
   * @generated from protobuf rpc: OptInToProductLeaderboard(sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardRequest) returns (sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardResponse);
   */
  optInToProductLeaderboard(
    input: OptInToProductLeaderboardRequest,
    options?: RpcOptions,
  ): UnaryCall<
    OptInToProductLeaderboardRequest,
    OptInToProductLeaderboardResponse
  >;
}
/**
 * @generated from protobuf service sparx.leaderboards.userdisplay.v1.UserDisplay
 */
export class UserDisplayClient implements IUserDisplayClient, ServiceInfo {
  typeName = UserDisplay.typeName;
  methods = UserDisplay.methods;
  options = UserDisplay.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetUserDisplayData returns the display data for the requested user.
   *
   * @generated from protobuf rpc: GetUserDisplayData(sparx.leaderboards.userdisplay.v1.GetUserDisplayDataRequest) returns (sparx.leaderboards.userdisplay.v1.UserDisplayData);
   */
  getUserDisplayData(
    input: GetUserDisplayDataRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUserDisplayDataRequest, UserDisplayData> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetUserDisplayDataRequest, UserDisplayData>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * GetUserDisplayDataForCurrentUser returns the display data for the requesting user.
   *
   * @generated from protobuf rpc: GetUserDisplayDataForCurrentUser(sparx.leaderboards.userdisplay.v1.GetUserDisplayDataForCurrentUserRequest) returns (sparx.leaderboards.userdisplay.v1.UserDisplayData);
   */
  getUserDisplayDataForCurrentUser(
    input: GetUserDisplayDataForCurrentUserRequest,
    options?: RpcOptions,
  ): UnaryCall<GetUserDisplayDataForCurrentUserRequest, UserDisplayData> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetUserDisplayDataForCurrentUserRequest,
      UserDisplayData
    >('unary', this._transport, method, opt, input);
  }
  /**
   * UpdateUserDisplayData upserts the user display data for the requested user.
   *
   * @generated from protobuf rpc: UpdateUserDisplayData(sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataRequest) returns (sparx.leaderboards.userdisplay.v1.UserDisplayData);
   */
  updateUserDisplayData(
    input: UpdateUserDisplayDataRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUserDisplayDataRequest, UserDisplayData> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<UpdateUserDisplayDataRequest, UserDisplayData>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * UpdateUserDisplayData upserts the requesting user's display data.
   *
   * @generated from protobuf rpc: UpdateUserDisplayDataForCurrentUser(sparx.leaderboards.userdisplay.v1.UpdateUserDisplayDataForCurrentUserRequest) returns (sparx.leaderboards.userdisplay.v1.UserDisplayData);
   */
  updateUserDisplayDataForCurrentUser(
    input: UpdateUserDisplayDataForCurrentUserRequest,
    options?: RpcOptions,
  ): UnaryCall<UpdateUserDisplayDataForCurrentUserRequest, UserDisplayData> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      UpdateUserDisplayDataForCurrentUserRequest,
      UserDisplayData
    >('unary', this._transport, method, opt, input);
  }
  /**
   * BatchGetUserDisplayData gets the userdisplaydata for the given resource names.
   * This creates display data for any users which do not exist, assigning them
   * a random display name.
   * Users who are opted out of the given product will not be returned.
   *
   * @generated from protobuf rpc: BatchGetUserDisplayData(sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataRequest) returns (sparx.leaderboards.userdisplay.v1.BatchGetUserDisplayDataResponse);
   */
  batchGetUserDisplayData(
    input: BatchGetUserDisplayDataRequest,
    options?: RpcOptions,
  ): UnaryCall<
    BatchGetUserDisplayDataRequest,
    BatchGetUserDisplayDataResponse
  > {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      BatchGetUserDisplayDataRequest,
      BatchGetUserDisplayDataResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * GeneratePositiveNoun returns a random positive noun which may be used in display data.
   *
   * @generated from protobuf rpc: GeneratePositiveNoun(sparx.leaderboards.userdisplay.v1.GeneratePositiveNounRequest) returns (sparx.leaderboards.userdisplay.v1.GeneratePositiveNounResponse);
   */
  generatePositiveNoun(
    input: GeneratePositiveNounRequest,
    options?: RpcOptions,
  ): UnaryCall<GeneratePositiveNounRequest, GeneratePositiveNounResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GeneratePositiveNounRequest,
      GeneratePositiveNounResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * OptOutOfProductLeaderboard adds the requested product to the list of
   * opted-out products for the requested user.
   *
   * @generated from protobuf rpc: OptOutOfProductLeaderboard(sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardRequest) returns (sparx.leaderboards.userdisplay.v1.OptOutOfProductLeaderboardResponse);
   */
  optOutOfProductLeaderboard(
    input: OptOutOfProductLeaderboardRequest,
    options?: RpcOptions,
  ): UnaryCall<
    OptOutOfProductLeaderboardRequest,
    OptOutOfProductLeaderboardResponse
  > {
    const method = this.methods[6],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      OptOutOfProductLeaderboardRequest,
      OptOutOfProductLeaderboardResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * OptInToProductLeaderboard removes the requested product from the list of
   * opted-out products for the requested user. Does nothing if user has not
   * opted out.
   *
   * @generated from protobuf rpc: OptInToProductLeaderboard(sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardRequest) returns (sparx.leaderboards.userdisplay.v1.OptInToProductLeaderboardResponse);
   */
  optInToProductLeaderboard(
    input: OptInToProductLeaderboardRequest,
    options?: RpcOptions,
  ): UnaryCall<
    OptInToProductLeaderboardRequest,
    OptInToProductLeaderboardResponse
  > {
    const method = this.methods[7],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      OptInToProductLeaderboardRequest,
      OptInToProductLeaderboardResponse
    >('unary', this._transport, method, opt, input);
  }
}
