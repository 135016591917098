import {
  faAirFreshener,
  faBook,
  faCrown,
  faHome,
  faSpellCheck,
} from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from 'components/sidebar/sidebar';
import { SidebarButton } from 'components/sidebar/sidebar-button';
import { useCanVocab, useIsUserOnboarded, userHasRole, UserRole, useUser } from 'queries/session';
import { useLockedInPackage } from 'queries/tasks';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import goldDeselected from 'views/student/gold-reader-view/img/GR_deselected.svg';
import goldSelected from 'views/student/gold-reader-view/img/GR_selected.svg';
import { pathForView, useCurrentView } from 'views/views';

export const StudentSidebar = () => {
  const user = useUser();
  const view = useCurrentView();
  const canUseVocab = useCanVocab();
  const onboarded = useIsUserOnboarded();

  const navigate = useNavigate();

  const { data: lockedInPackage } = useLockedInPackage();

  const canExplore = onboarded && !lockedInPackage;

  return (
    <Sidebar>
      <SidebarButton
        onClick={() => navigate(pathForView(View.Library))}
        selected={view?.view === View.Library}
        content="Home"
        icon={faHome}
      />
      <SidebarButton
        onClick={() => navigate(pathForView(View.Explore))}
        selected={
          view?.view === View.Explore ||
          view?.view === View.AddGoldReaderBook ||
          view?.view === View.ChooseNewBook
        }
        content="Library"
        icon={faBook}
        locked={!canExplore}
      />
      <SidebarButton
        onClick={() => navigate(pathForView(View.ClassLeague))}
        selected={location.pathname.startsWith('/library/league')}
        content="Leaderboard"
        icon={faCrown}
        locked={!canExplore}
      />
      {canUseVocab && (
        <SidebarButton
          onClick={() => navigate(pathForView(View.VocabLanding))}
          selected={view?.view === View.VocabLanding}
          content="Vocab"
          icon={faSpellCheck}
          locked={!canExplore}
        />
      )}
      <SidebarButton
        onClick={() => navigate(pathForView(View.GoldReader))}
        selected={view?.view === View.GoldReader && onboarded}
        content={
          <>
            Gold
            <br />
            Reader
          </>
        }
        deselectedImg={goldDeselected}
        selectedImg={goldSelected}
        locked={!canExplore}
      />
      {userHasRole(user, UserRole.EQMRead) && (
        <SidebarButton
          onClick={() => navigate(pathForView(View.EQM))}
          selected={false}
          content="EQM"
          icon={faAirFreshener}
        />
      )}
    </Sidebar>
  );
};
