import { NotificationCustom } from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import { useAlert } from 'components/alert/alert';
import { NotificationAlert } from 'components/alert/notification-alert';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { View } from 'views';
import { pathForView } from 'views/views';

import { INotificationWrapperProps, Notification } from './notification';

interface Props extends INotificationWrapperProps {
  custom: NotificationCustom;
}

export const CustomNotification: React.FC<Props> = ({ notification, custom }) => {
  const setAlert = useAlert();
  const navigate = useNavigate();

  return (
    <Notification
      title={custom.title}
      notification={notification}
      onClick={() => {
        if (custom.modal) {
          setAlert(<NotificationAlert custom={custom.modal} />);
        } else if (custom.navigate.oneofKind === 'view') {
          navigate(pathForView(custom.navigate.view as View));
        } else if (custom.navigate.oneofKind === 'viewParams') {
          navigate({
            pathname: pathForView(custom.navigate.viewParams.view as View),
            search: createSearchParams(custom.navigate.viewParams.params).toString(),
          });
        } else if (custom.navigate.oneofKind === 'externalUrl') {
          window.open(custom.navigate.externalUrl, '_blank');
        }
      }}
      clickMessage={custom.cta}
      hideClickMessage={custom.cta === ''}
    >
      {custom.text}
    </Notification>
  );
};
