import { Button } from 'components/buttons/button';

import { clickedBookCall, clickedNoCall } from '../events';
import styles from '../popover.module.css';

const BOOK_A_CALL_URL = 'https://meetings-eu1.hubspot.com/alice-stirmey/allreadercoaches';

interface IBookACallProps {
  onSubmit: () => void;
}

export const BookACall = ({ onSubmit }: IBookACallProps) => {
  return (
    <div>
      <p className={styles.SectionText}>
        We&apos;re sorry to hear you&apos;re not liking Sparx Reader. We&apos;d love to hear more
        about your feedback, so if you&apos;d like to book a call to discuss this further with us,
        click the button below.
      </p>

      <div className={styles.Buttons}>
        <Button onClick={() => onSubmit()} variant="secondary" analyticsEvent={clickedNoCall()}>
          No, thank you
        </Button>
        <Button
          onClick={() => {
            onSubmit();
            window.open(BOOK_A_CALL_URL, '_blank')?.focus();
          }}
          analyticsEvent={clickedBookCall()}
        >
          Book a call
        </Button>
      </div>
    </div>
  );
};
