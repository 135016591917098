// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/subscription/v1/types.proto" (package "sparx.school.subscription.v1", syntax proto3),// tslint:disable
// @ts-nocheck
// Shared types

/**
 * Possible types of subscriptions.
 *
 * @generated from protobuf enum sparx.school.subscription.v1.SubscriptionType
 */
export enum SubscriptionType {
  /**
   * Default enum value
   *
   * @generated from protobuf enum value: SUBSCRIPTION_TYPE_UNKNOWN = 0;
   */
  SUBSCRIPTION_TYPE_UNKNOWN = 0,
  /**
   * Individual subscription
   *
   * @generated from protobuf enum value: INDIVIDUAL = 1;
   */
  INDIVIDUAL = 1,
  /**
   * School is part of a group/MAT subscription
   *
   * @generated from protobuf enum value: SCHOOL_GROUP = 2;
   */
  SCHOOL_GROUP = 2,
  /**
   * School has other subscription arrangements
   *
   * @generated from protobuf enum value: OTHER = 3;
   */
  OTHER = 3,
  /**
   * School is currently on a trial subscription
   *
   * @generated from protobuf enum value: TRIAL = 4;
   */
  TRIAL = 4,
}
/**
 * Possible types of pricings.
 *
 * @generated from protobuf enum sparx.school.subscription.v1.PricingType
 */
export enum PricingType {
  /**
   * Default enum value
   *
   * @generated from protobuf enum value: PRICING_TYPE_UNKNOWN = 0;
   */
  PRICING_TYPE_UNKNOWN = 0,
  /**
   * Pricing is for whole school
   *
   * @generated from protobuf enum value: WHOLE_SCHOOL = 1;
   */
  WHOLE_SCHOOL = 1,
  /**
   * Pricing is per cohort
   *
   * @generated from protobuf enum value: PER_COHORT = 2;
   */
  PER_COHORT = 2,
  /**
   * Pricing is per learner
   *
   * @generated from protobuf enum value: PER_LEARNER = 3;
   */
  PER_LEARNER = 3,
}
