export const useDocument360 = () => {
  const widgetIframe = document.getElementById(
    'document360-widget-iframe',
  ) as HTMLIFrameElement | null;

  const isLoaded = widgetIframe !== null && widgetIframe.contentDocument !== null;

  const openKnowledgeBase = () => {
    if (isLoaded) {
      widgetIframe.contentDocument.getElementById('doc360-button')?.click();
    }
  };

  return { isLoaded, openKnowledgeBase };
};
