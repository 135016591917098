import { ExperienceReward } from '@sparx/api/apis/sparx/reading/users/v1/experience';
import classNames from 'classnames';
import styles from 'components/tasks/panel.module.css';

import { PanelIconCross, PanelIconTick } from '../panel-icons';
import { failText, LargeSRPReward, successText } from '../task-complete-modal';

interface IRewardPageProps {
  hideTaskCheck: boolean;
  passed: boolean;
  children: React.ReactNode;
  reward?: ExperienceReward;
}

export const RewardPage: React.FC<IRewardPageProps> = ({
  reward,
  hideTaskCheck,
  passed,
  children,
}) => (
  <div className={classNames(styles.Page, styles.RewardPage)}>
    {!hideTaskCheck && (
      <div className={styles.RewardPageSection}>
        {passed ? <PanelIconTick /> : <PanelIconCross />}
        <h2 className={`${styles.ModalTitle} enter-down-1`}>{passed ? successText : failText}</h2>
        {children && (
          <div className={`${styles.PanelResult} enter-down-2`} style={{ margin: 0 }}>
            {children}
          </div>
        )}
      </div>
    )}

    {!hideTaskCheck && reward && reward.experience > 0 && (
      <div className={styles.RewardPageSectionDivider} />
    )}

    {reward && reward.experience > 0 && (
      <div className={styles.RewardPageSection}>
        <LargeSRPReward experience={reward.experience} />
        {reward.detail.map((detail, i) => (
          <div
            key={i}
            className={classNames(
              styles.RewardReason,
              `enter-down-${i + 1}`,
              detail.focus && styles.RewardReasonFocus,
              detail.skipped && styles.RewardReasonSkipped,
            )}
          >
            <strong>+{detail.experience}</strong> {detail.reason}
          </div>
        ))}
      </div>
    )}
  </div>
);
