import {
  LibraryBook,
  MetadataAbridged,
} from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { Button } from 'components/buttons/button';
import { ProgressBar } from 'components/progress/progress-bar';
import { useStartBookDialog } from 'hooks/start-book-dialog';
import { useNavigate } from 'react-router-dom';
import { makeItemList } from 'utils/names';
import { View } from 'views';
import styles from 'views/student/library-view/components/my-book-shelf.module.css';
import { pathForView } from 'views/views';

import { LibraryBookItem } from './library-book';
import { LibraryContainer } from './library-container';
import { LinkToBookChoice } from './link-to-book-choice';

export const MyBookShelf = ({
  books,
  canStartNewBook,
}: {
  books: LibraryBook[];
  canStartNewBook: boolean;
  verboseDisplay?: boolean;
}) => {
  const navigate = useNavigate();
  const [, openStartBookDialog] = useStartBookDialog();

  // Get the first book of books
  const toBookChoice = () => navigate(pathForView(View.ChooseNewBook));

  return (
    <LibraryContainer>
      <div className={styles.Shelf}>
        {books.map((bk, i) => (
          <DisplayBook
            book={bk}
            key={bk.metadataAbridged?.name || i}
            displayFull={i === 0}
            openStartBookDialog={openStartBookDialog}
          />
        ))}

        <LinkToBookChoice
          disabled={!canStartNewBook}
          handleLinkClick={toBookChoice}
          analyticsAction="my shelf new book link"
        />

        {/* Padding to ensure that there are no full width tasks*/}
        <div className={styles.PaddingBox} />
        <div className={styles.PaddingBox} />
        <div className={styles.PaddingBox} />
      </div>
    </LibraryContainer>
  );
};

const DisplayBook = ({
  book,
  displayFull,
  openStartBookDialog,
}: {
  book: LibraryBook;
  // Whether to show the expanded view of the book with progress and title.
  displayFull: boolean;
  openStartBookDialog: (book: MetadataAbridged) => void;
}) => {
  if (book.metadataAbridged === undefined) {
    return null;
  }

  const onClick = () => book.metadataAbridged && openStartBookDialog(book.metadataAbridged);

  if (displayFull) {
    return (
      <div className={styles.FirstBook}>
        <div className={styles.FirstBookCover}>
          <LibraryBookItem onClick={onClick} book={book} size="large" />
        </div>
        <div className={styles.FirstBookMeta}>
          <h2 className={styles.Title}>{book.metadataAbridged.title}</h2>
          <div className={styles.Authors}>by {makeItemList(book.metadataAbridged.authors)}</div>
          <div className={styles.Progress}>
            <ProgressBar
              percent={(book.studentBook?.progress || 0) * 100}
              className={styles.BookProgressBar}
              width="80%"
            />
          </div>
          <Button
            onClick={onClick}
            analyticsEvent={{
              category: 'library',
              action: 'continue latest book',
              labels: { bookName: book.metadataAbridged?.name },
            }}
          >
            {book.studentBook?.progress ? 'Continue reading' : 'Start reading'}
          </Button>
        </div>
      </div>
    );
  }

  return <LibraryBookItem book={book} onClick={onClick} />;
};
