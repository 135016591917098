import { GetSchoolRequest, School, SchoolsService } from '@sparx/api/apis/sparx/school/v2/schools';
import { ISchoolsServiceClient as Client } from '@sparx/api/apis/sparx/school/v2/schools.client';
import { useContext } from 'react';

import { CommonParams, CommonParamsContext } from '../context';
import { Key as QueryKey } from '../key';
import { buildQueriesWithoutInput, buildQueryOptions, Procedure } from '../procedure';
import { QueryOpts } from '../query-opts';
import { useSchoolsClient } from './client';

type Params = Pick<CommonParams, 'schoolName'>;
type Key = QueryKey<Params>;
type QueryReturn = School;
type Opts<TData = QueryReturn> = QueryOpts<QueryReturn, TData, Key>;

const { keyPrefix, makeKey, getOpts } = buildQueryOptions<Client, Params, QueryReturn>(
  SchoolsService,
  GetSchoolRequest,
  (client, { schoolName: name }) => client.getSchool({ name }).response,
);

const useOpts = (): (() => Opts) => {
  const client = useSchoolsClient();
  const { schoolName } = useContext(CommonParamsContext);
  return () => getOpts(client, { schoolName });
};

const { useQuery, useSuspenseQuery } = buildQueriesWithoutInput(useOpts);

export const getSchool: Procedure<Client, Params, QueryReturn> = {
  keyPrefix,
  makeKey,
  getOpts,
  useOpts,
  useQuery,
  useSuspenseQuery,
};
