// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/books/v1/book.proto" (package "sparx.reading.books.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Books } from './book';
import type { StoreBookResponse } from './book';
import type { StoreBookRequest } from './book';
import type { ListBooksResponse } from './book';
import type { ListBooksRequest } from './book';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { QueryBooksResponse } from './book';
import type { QueryBooksRequest } from './book';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.books.v1.Books
 */
export interface IBooksClient {
  /**
   * QueryBooks queries books from the book database.
   *
   * @generated from protobuf rpc: QueryBooks(sparx.reading.books.v1.QueryBooksRequest) returns (sparx.reading.books.v1.QueryBooksResponse);
   */
  queryBooks(
    input: QueryBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<QueryBooksRequest, QueryBooksResponse>;
  /**
   * ListBooks returns a list of all books available in the book database.
   *
   * @generated from protobuf rpc: ListBooks(sparx.reading.books.v1.ListBooksRequest) returns (sparx.reading.books.v1.ListBooksResponse);
   */
  listBooks(
    input: ListBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListBooksRequest, ListBooksResponse>;
  /**
   * StoreBook stores a book in the book database.
   *
   * The endpoint acts as an upsert. If the book with the same name already
   * exists in the database then it will be updated. This endpoint can only
   * be called by a user with admin privileges.
   *
   * @generated from protobuf rpc: StoreBook(sparx.reading.books.v1.StoreBookRequest) returns (sparx.reading.books.v1.StoreBookResponse);
   */
  storeBook(
    input: StoreBookRequest,
    options?: RpcOptions,
  ): UnaryCall<StoreBookRequest, StoreBookResponse>;
}
/**
 * @generated from protobuf service sparx.reading.books.v1.Books
 */
export class BooksClient implements IBooksClient, ServiceInfo {
  typeName = Books.typeName;
  methods = Books.methods;
  options = Books.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * QueryBooks queries books from the book database.
   *
   * @generated from protobuf rpc: QueryBooks(sparx.reading.books.v1.QueryBooksRequest) returns (sparx.reading.books.v1.QueryBooksResponse);
   */
  queryBooks(
    input: QueryBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<QueryBooksRequest, QueryBooksResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<QueryBooksRequest, QueryBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * ListBooks returns a list of all books available in the book database.
   *
   * @generated from protobuf rpc: ListBooks(sparx.reading.books.v1.ListBooksRequest) returns (sparx.reading.books.v1.ListBooksResponse);
   */
  listBooks(
    input: ListBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListBooksRequest, ListBooksResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListBooksRequest, ListBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * StoreBook stores a book in the book database.
   *
   * The endpoint acts as an upsert. If the book with the same name already
   * exists in the database then it will be updated. This endpoint can only
   * be called by a user with admin privileges.
   *
   * @generated from protobuf rpc: StoreBook(sparx.reading.books.v1.StoreBookRequest) returns (sparx.reading.books.v1.StoreBookResponse);
   */
  storeBook(
    input: StoreBookRequest,
    options?: RpcOptions,
  ): UnaryCall<StoreBookRequest, StoreBookResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<StoreBookRequest, StoreBookResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
