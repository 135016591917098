import { MessageInfo } from '@protobuf-ts/runtime';
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { CommonParamKeys } from './context';
import { createKeyMaker, Key, MakeKeyFunc } from './key';
import { QueryOpts } from './query-opts';

/*
 * A note on the conditional type predicate used in this file:
 * The type `keyof Omit<Params, CommonParamKeys>` is used to determine whether or not
 * the procedure requires parameters to be passed in at the point of calling. If the type
 * is `never`, then the procedure does not require parameters and we can fall back to a
 * simpler signature for the `useOpts`, `useQuery` and `useSuspenseQuery` hooks.
 */

type CustomOpts<TQueryReturn, TData> = Omit<
  UseQueryOptions<TQueryReturn, Error, TData>,
  'suspense'
>;

type KeyExports<Params> = {
  /** A common key prefix for this procedure */
  keyPrefix: readonly [string, string];
  /** A function for producing a full key for this procecure, given parameters */
  makeKey: MakeKeyFunc<Params>;
};

type OptionExports<Client, Params, Return> = {
  /** A function which returns arguments for `useQuery` / `useSuspenseQuery` */
  getOpts: (client: Client, params: Params) => QueryOpts<Return, Return, Key<Params>>;
  /** A hook which returns arguments for `useQuery` / `useSuspenseQuery` */
  useOpts: () => [keyof Omit<Params, CommonParamKeys>] extends [never]
    ? () => QueryOpts<Return, Return, Key<Params>>
    : (params: Omit<Params, CommonParamKeys>) => QueryOpts<Return, Return, Key<Params>>;
};

type QueryExports<Params, Return> = {
  useQuery: [keyof Omit<Params, CommonParamKeys>] extends [never]
    ? <TData = Return>(options?: CustomOpts<Return, TData>) => UseQueryResult<TData, Error>
    : <TData = Return>(
        params: Omit<Params, CommonParamKeys>,
        options?: CustomOpts<Return, TData>,
      ) => UseQueryResult<TData, Error>;

  useSuspenseQuery: [keyof Omit<Params, CommonParamKeys>] extends [never]
    ? <TData = Return>(options?: CustomOpts<Return, TData>) => UseQueryResult<TData, Error>
    : <TData = Return>(
        params: Omit<Params, CommonParamKeys>,
        options?: CustomOpts<Return, TData>,
      ) => UseQueryResult<TData, Error>;
};

/** Encapsulates functions / hooks related to a GRPC procedure call */
export type Procedure<Client, Params, Return> = KeyExports<Params> &
  OptionExports<Client, Params, Return> &
  QueryExports<Params, Return>;

/** Build options (i.e. queryFn and queryKey) for a procedure */
export const buildQueryOptions = <Client, Params, Return>(
  service: ServiceType,
  request: MessageInfo,
  call: (client: Client, params: Params) => Promise<Return>,
) => {
  const keyPrefix = [service.typeName, request.typeName] as const;
  const makeKey = createKeyMaker<Params>(...keyPrefix);
  const getOpts = (client: Client, params: Params) => ({
    queryKey: makeKey(params),
    queryFn: async () => call(client, params),
  });

  return { keyPrefix, makeKey, getOpts };
};

/** Build hooks for a procedure that doesn't require input parameters */
export const buildQueriesWithoutInput = <Return, Params>(
  useOpts: () => () => QueryOpts<Return, Return, Key<Params>>,
) => {
  const makeUseQuery =
    (suspense: boolean) =>
    <TData = Return>(options?: CustomOpts<Return, TData>) => {
      const { queryKey, queryFn } = useOpts()();
      return useQuery({ queryKey, queryFn, ...options, suspense });
    };

  return {
    useQuery: makeUseQuery(false),
    useSuspenseQuery: makeUseQuery(true),
  };
};

/** Build hooks for a procedure that does require input parameters */
export const buildQueriesWithInput = <Return, Params, CallParams>(
  useOpts: () => (params: CallParams) => QueryOpts<Return, Return, Key<Params>>,
) => {
  const makeUseQuery =
    (suspense: boolean) =>
    <TData = Return>(params: CallParams, options?: CustomOpts<Return, TData>) => {
      const { queryKey, queryFn } = useOpts()(params);
      return useQuery({ queryKey, queryFn, ...options, suspense });
    };

  return {
    useQuery: makeUseQuery(false),
    useSuspenseQuery: makeUseQuery(true),
  };
};
