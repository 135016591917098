import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { HomeworkLength } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Link } from 'components/link';
import { SortableTable } from 'components/table/sortable-table';
import { TableCell, TableRow, TableRowFull } from 'components/table/table-row';
import { useEnglishStudentGroups, useTeacherStudentsWithExperience } from 'queries/management';
import { useAllRecentHomeworks } from 'queries/tasks';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getStudentGroupId } from 'utils/groups';
import { timestampToMoment } from 'utils/time';

import styles from './hand-in-summary.module.css';
import { HandInSummaryCompletion } from './hand-in-summary-completion';
import { HandInSummaryLegend } from './hand-in-summary-legend';
import { HandInSummaryProgress } from './hand-in-summary-progress';

export interface Bar {
  label: string;
  type: barType;
  className: string;
}

export type barType = 'completed' | 'started' | 'notstarted';

const bars: Array<Bar> = [
  { type: 'completed', label: 'Completed', className: styles.Completed },
  { type: 'started', label: 'Started', className: styles.Started },
  { type: 'notstarted', label: 'Not started', className: styles.NotStarted },
];

type Completion = Record<barType, number>;

// Row can either be a full row with all data loaded, or a partial row with
// only class information loaded. This partial type is used for the loading
// state where we've loaded classes but not homeworks yet, so can display the
// class names.
export type IRow =
  | {
      type: 'full';
      class: Group;
      homework: Homework | undefined;
      completion: Completion;
      numStudents: number;
    }
  | {
      type: 'partial';
      class: Group;
    };

type classSort = 'class' | 'date' | 'completion';
const classesSorts: Record<classSort, (a: IRow, b: IRow) => number> = {
  class: (a, b) => a.class.name.localeCompare(b.class.name, undefined, { numeric: true }),
  date: (a, b) => {
    if (a.type === 'partial' || b.type === 'partial') return 0;
    // If both classes have a homework, sort by homework end date
    if (a.homework?.endDate && b.homework?.endDate) {
      if (a.homework?.endDate.seconds === b.homework?.endDate.seconds) {
        return classesSorts.class(a, b);
      }
      return a.homework?.endDate.seconds - b.homework?.endDate.seconds;
    }
    if (!a.homework && !b.homework) {
      return 0;
    }
    // Classes without homework should be sorted to the bottom
    if (!a.homework) {
      return 1;
    }
    if (!b.homework) {
      return -1;
    }
    return 0;
  },
  completion: (a, b) => {
    if (a.type === 'partial' || b.type === 'partial') return 0;
    if (!a.homework && !b.homework) {
      return 0;
    }
    if (!a.homework || !a.numStudents) {
      return 1;
    }
    if (!b.homework || !b.numStudents) {
      return -1;
    }
    return (
      b.completion.completed / b.numStudents - a.completion.completed / a.numStudents ||
      b.completion.started / b.numStudents - a.completion.started / a.numStudents ||
      b.numStudents - a.numStudents ||
      0
    );
  },
};

const makeHandInLink = (row: IRow) => {
  const link = `/teacher/classes/homework?group=${getStudentGroupId(row.class)}`;
  if (row.type === 'full' && row.homework) {
    return `${link}&homework=${row.homework.homeworkId}`;
  }
  return link;
};

export const HandInSummary = () => {
  const { data: classes, isLoading: classesLoading } = useEnglishStudentGroups();
  const { data: homeworks, isLoading: homeworksLoading } = useAllRecentHomeworks();
  const { data: students, isLoading: studentsLoading } = useTeacherStudentsWithExperience();
  const isNarrowScreen = useMediaQuery({ query: '(max-width: 800px)' });

  const rows: IRow[] = useMemo(() => {
    // If we've loaded classes but not homeworks, return a partial row for each
    // class so we can show class names while the homeworks are loading.
    if (classes === undefined || homeworks === undefined || students === undefined) {
      if (classes !== undefined) {
        return classes.map(c => ({ type: 'partial', class: c }));
      }
      return [];
    }
    return classes.map(c => {
      const homework = homeworks?.find(h => h.studentGroupId === getStudentGroupId(c));
      let numStudents = 0;
      const completion: Completion = {
        completed: 0,
        started: 0,
        notstarted: 0,
      };
      if (homework !== undefined) {
        homework.students.forEach(student => {
          const studentExperience = students?.[student.studentId];
          // Do not count optional homework students
          if (!studentExperience || studentExperience.homeworkLength === HomeworkLength.OPTIONAL) {
            return;
          }
          numStudents++;
          if (
            student.targetExperienceOverride > 0 &&
            student.experience >= student.targetExperienceOverride
          ) {
            completion.completed++;
          } else if (student.experience >= homework.targetExperience) {
            completion.completed++;
          } else if (student.experience > 0) {
            completion.started++;
          } else {
            completion.notstarted++;
          }
        });
      }
      return { type: 'full', class: c, homework, completion, numStudents };
    });
  }, [classes, homeworks, students]);

  const loading = classesLoading || homeworksLoading || studentsLoading;

  // Show placeholder 'loading' rows while loading, or 'no classes' row when loaded with no data.
  const emptyRows = loading ? (
    Array(10)
      .fill(null)
      .map((_, i) => <TableRowFull key={i}>Loading...</TableRowFull>)
  ) : (
    <TableRowFull>There are no classes.</TableRowFull>
  );

  return (
    <div className={styles.Summary}>
      <div className={styles.TitleRow}>
        <h3 className={styles.Title}>Hand-in summary</h3>
        <HandInSummaryLegend bars={bars} />
      </div>

      <HandInSummaryProgress />

      <SortableTable
        className={styles.Table}
        fixedRowHeight={49}
        maxHeight={320}
        sorts={classesSorts}
        defaultSort="date"
        data={rows}
        noRows={emptyRows}
        analyticsCategory="dashboard"
        analyticsLabels={{ table: 'handin summary' }}
        loading={loading}
        columns={[
          {
            name: 'Class',
            width: 'minmax(100px, 2fr)',
            sort: 'class',
          },
          {
            name: 'Homework due',
            width: '2fr',
            sort: 'date',
          },
          {
            name: isNarrowScreen ? 'Completion' : 'Homework completion',
            width: 'minmax(300px, 5fr)',
            sort: 'completion',
          },
          {
            name: '',
            width: isNarrowScreen ? '120px' : '200px',
          },
        ]}
      >
        {(row, i) => {
          return (
            <TableRow key={row.class.name} className={styles.TableRow}>
              <TableCell className={styles.Cell}>{row.class.displayName}</TableCell>
              {row.type === 'full' ? (
                <>
                  <TableCell className={styles.Cell}>
                    {row.homework?.endDate && timestampToMoment(row.homework?.endDate).fromNow()}
                  </TableCell>
                  <HandInSummaryCompletion row={row} bars={bars} />
                  <TableCell className={styles.LinkCell}>
                    <Link
                      className={styles.Link}
                      to={makeHandInLink(row)}
                      analyticsEvent={{
                        category: 'dashboard',
                        action: 'view hand in',
                        labels: {
                          classId: getStudentGroupId(row.class),
                          homeworkId: row.homework?.homeworkId,
                          listIndex: i,
                        },
                      }}
                    >
                      {isNarrowScreen && <>Hand-in &gt;</>}
                      {!isNarrowScreen && <>View Hand-in &gt;</>}
                    </Link>
                  </TableCell>
                </>
              ) : (
                <>
                  {/* While we are loading homeworks just show class names and 'loading'. */}
                  <TableCell />
                  <TableCell>Loading...</TableCell>
                  <TableCell />
                </>
              )}
            </TableRow>
          );
        }}
      </SortableTable>
    </div>
  );
};
