import styles from 'components/progress/timer.module.css';

interface ITimerProps {
  percent: number;
  className?: string;
  warning?: boolean;
  danger?: boolean;
}

export const Timer: React.FC<ITimerProps> = ({ percent, className, warning, danger }) => (
  <div className={`${styles.Timer} ${className}`}>
    <div
      className={styles.TimerScroll}
      style={{
        width: `${Math.max(0, Math.min(percent, 100)) * 100}%`,
        background: warning
          ? danger
            ? 'var(--ruby-red-100)'
            : 'var(--jaffa-orange-100)'
          : 'var(--olive-green-100)',
      }}
    />
  </div>
);
