import { Transition } from '@headlessui/react';
import classNames from 'classnames';

import styles from './popover.module.css';

export const SectionTransition = ({
  show,
  children,
}: {
  show: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Transition
      className={styles.Section}
      show={show}
      enter={classNames(styles.EaseOut, styles.Delay300)}
      enterFrom={classNames(styles.Opacity0, styles.Scale95)}
      enterTo={classNames(styles.Opacity100, styles.Scale100)}
      leave={classNames(styles.EaseIn)}
      leaveFrom={classNames(styles.Opacity100, styles.Scale100)}
      leaveTo={classNames(styles.Opacity0, styles.Scale95)}
    >
      {children}
    </Transition>
  );
};
