import { Button } from 'components/buttons/button';
import styles from 'components/tasks/panel.module.css';
import { useTaskActionsLoading } from 'queries/tasks';
import { FCWithOptionalChildren } from 'utils/props';

interface IPaperbackReadingPanelProps {
  taskID: string;
  finishReading: () => void;
  inline?: boolean;
}

export const PaperbackReadingPanel: FCWithOptionalChildren<IPaperbackReadingPanelProps> = ({
  children,
  finishReading,
  inline,
}) => {
  const loading = useTaskActionsLoading();

  return (
    <div className={inline ? styles.InlineWrapper : styles.PanelWrapper}>
      <div className={inline ? undefined : `${styles.Panel} ${styles.PanelBottom}`}>
        {children}
        <div className={styles.Buttons}>
          <div className={styles.ContinueButton}>
            <Button
              onClick={finishReading}
              loading={loading}
              data-test-id="read-button"
              analyticsEvent={undefined}
            >
              I have read {inline ? 'up to here' : 'this'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
