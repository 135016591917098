import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { useUser } from 'queries/session';
import { PropsWithChildren } from 'react';
import { NotFoundView } from 'views/common/not-found-view/not-found-view';

export const RequiresTeacher = ({ children }: PropsWithChildren) => {
  const user = useUser();

  if (user?.type === UserType.TEACHER) return <>{children}</>;

  return <NotFoundView />;
};
