import { format } from 'date-fns';
import * as htmlToImage from 'html-to-image';
import { useEnglishStudentGroups, useTeacherStudentsWithExperience } from 'queries/management';
import { useMemo } from 'react';

import { WidgetConfig } from './types';

export const fileName = (schoolName: string, type: string) =>
  `${schoolName} - ${type} (${format(new Date(), 'yyyy-MM-dd')})`;

export const downloadWidgetAsImage = async (
  schoolName: string,
  config: WidgetConfig,
  element: HTMLDivElement,
) => {
  // account for weird Safari bug
  // https://github.com/bubkoo/html-to-image/issues/361#issuecomment-1402537176
  await htmlToImage.toPng(element);
  await htmlToImage.toPng(element);
  const dataUrl = await htmlToImage.toPng(element);
  const link = document.createElement('a');

  link.download = `${fileName(schoolName, config.id)}.png`;
  link.href = dataUrl;
  link.click();
};

export const useSchoolReadingHoursThisYear = () => {
  const { data, isLoading } = useTeacherStudentsWithExperience();
  const { data: groups } = useEnglishStudentGroups();
  const readingHoursThisYear = useMemo(() => {
    const englishGroupIds = groups?.map(g => g.name.split('/')[3]) || [];
    const srpThisYear = Object.values(data || {})
      .filter(s => englishGroupIds.includes(s.studentGroupId))
      .reduce((acc, student) => acc + student.experienceThisYear, 0);
    return srpThisYear / 10 / 60;
  }, [data, groups]);

  return { readingHoursThisYear, isLoading };
};

export const shouldShowPraiseStats = (
  forceEnable: boolean,
  completedBookCount: number,
  readingHoursThisYear: number,
) => {
  if (forceEnable) return true;

  return completedBookCount > 5 && readingHoursThisYear > 5;
};
