import { useIsSchoolPostReset } from 'hooks/school-calendar';
import { atom, useAtom } from 'jotai';
import { useUser } from 'queries/session';
import { useEffect } from 'react';

const dismissedPostReset = atom(false);

/** useShowPostReset is a hook for determining and managing whether the post
 * reset widget should show. */
export const useShowPostReset = () => {
  const isSchoolPostReset = useIsSchoolPostReset();

  const user = useUser();
  const localStorageKey = `dismiss-post-reset-widget-${user?.userId}`;
  const [isDismissed, setIsDismissed] = useAtom(dismissedPostReset);

  useEffect(() => {
    if (localStorage.getItem(localStorageKey) === 'true') {
      setIsDismissed(true);
    }
  }, [localStorageKey, setIsDismissed]);

  const dismiss = () => {
    localStorage.setItem(localStorageKey, 'true');
    setIsDismissed(true);
  };

  return {
    showPostReset: !(isDismissed || !user) && isSchoolPostReset,
    dismiss,
  };
};
