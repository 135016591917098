import { faExclamationTriangle, faSpinner, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Book } from '@sparx/api/apis/sparx/reading/books/v1/book';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { Tooltip } from '@sparx/design/components';
import { getImageURL } from 'api';
import classNames from 'classnames';
import { CSSProperties, useState } from 'react';
import { makeItemList } from 'utils/names';
import { FCWithOptionalChildren } from 'utils/props';

import styles from './book-image.module.css';

interface IBookImageProps {
  book: MetadataAbridged | Book | undefined;
  className?: string;
  style?: CSSProperties;
  showGoldReaderBanner?: boolean;
  titleTooltip?: boolean;
}

export const BookImage: FCWithOptionalChildren<IBookImageProps> = ({
  book,
  className,
  style,
  children,
  showGoldReaderBanner = false,
  titleTooltip = false,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const content = (
    <div className={classNames('book-image-container', className)}>
      {error ? (
        <div className="fake-book" style={style}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
      ) : book?.coverImage?.source.oneofKind === 'name' ? (
        <>
          {showGoldReaderBanner && (
            <div className={styles.GoldReaderBanner}>
              <span>Gold Reader</span>

              <FontAwesomeIcon icon={faStar} />
            </div>
          )}
          <div className={classNames('book-image-loading', loaded && 'book-image-loading-done')}>
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          </div>
          <img
            src={getImageURL(book.name, book.coverImage.source.name)}
            alt={''}
            style={style}
            onLoad={() => setLoaded(true)}
            onError={() => {
              setLoaded(true);
              setError(true);
            }}
          />
        </>
      ) : (
        <div className="fake-book" style={style}>
          <span className="fake-book-title">{book?.title}</span>
          {book && <span className="fake-book-author">{makeItemList(book.authors)}</span>}
        </div>
      )}
      {children}
    </div>
  );

  return titleTooltip && book !== undefined ? (
    <Tooltip
      content={
        <>
          <div>{book.title}</div>
          <div>by {makeItemList(book.authors)}</div>
        </>
      }
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};
