import { faAngleRight, faCalendarDays, faSync, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@sparx/design/components';
import { Button } from 'components/buttons';
import { Link } from 'components/link';

import { clickedButton, clickedDismiss } from './events';
import { useShowPostReset } from './hooks';
import styles from './post-reset-widget.module.css';

export const PostResetWidget = () => {
  const { dismiss } = useShowPostReset();

  return (
    <div className={styles.Root}>
      <h3 className={styles.Label}>
        <FontAwesomeIcon icon={faCalendarDays} /> New academic year
      </h3>
      <div className={styles.Panel}>
        <div className={styles.Header}>
          ACTION REQUIRED
          <Tooltip content="Dismiss this widget">
            <div className={styles.DismissButtonContainer}>
              <Button
                className={styles.DismissButton}
                analyticsEvent={clickedDismiss()}
                onClick={dismiss}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          </Tooltip>
        </div>

        <div className={styles.Body}>
          <p>
            <strong>Your Sparx Reader site has been reset for this academic year.</strong>
          </p>

          <p>
            You now need to import and set up your classes. Don&apos;t worry, Sparx Reader will
            remember difficulty levels and SRP totals for all students who have used Sparx Reader at
            your school before.
          </p>

          <Link
            to="/teacher/new-year-setup/import-classes-and-students"
            analyticsEvent={clickedButton()}
          >
            <Button analyticsEvent={undefined} className={styles.Button}>
              Set up your Sparx Reader site
              <FontAwesomeIcon icon={faAngleRight} />
            </Button>
          </Link>
        </div>

        <FontAwesomeIcon icon={faSync} className={styles.Icon} />
      </div>
    </div>
  );
};
