import { faArrowLeft, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title } from 'components/title/title';
import teacherStyles from 'views/teacher/components/teacher-view.module.css';

import styles from './choose-group-view.module.css';

type CustomIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
type ChooseGroupViewProp = {
  title: string;
  icon?: IconDefinition;
  customIcon?: CustomIcon;
};

export const ChooseGroupView = ({ title, icon, customIcon: CustomIcon }: ChooseGroupViewProp) => {
  return (
    <>
      <Title>{title}</Title>
      <div className={teacherStyles.NoContentWrapper}>
        <div className={teacherStyles.NoContent}>
          <div className={styles.IconContainer}>
            {CustomIcon && <CustomIcon className={styles.Icon} />}
            {icon && <FontAwesomeIcon icon={icon} className={styles.Icon} fixedWidth={true} />}
          </div>
          <h3>{title}</h3>
          <p>
            <FontAwesomeIcon icon={faArrowLeft} />
            Select a class from the left to view this page.
          </p>
        </div>
      </div>
    </>
  );
};
