import { Link } from 'components/link';

import { clickedGetInTouchLink } from '../events';
import styles from './shared.module.css';

export const NotConnected = () => {
  return (
    <>
      <div className={styles.Box}>
        <p>
          We weren&apos;t able to connect to your MIS, please try again or{' '}
          <Link
            to="https://support.sparxreader.com/docs/ticket-deflector/how-can-we-help-you?p=r0"
            analyticsEvent={clickedGetInTouchLink()}
            className={styles.Link}
            target="_blank"
            referrerPolicy="no-referrer"
          >
            get in touch
          </Link>
          .
        </p>
      </div>
    </>
  );
};
