import { AnalyticEventFields } from 'components/client-events/client-event-provider';

const CATEGORY = 'post-reset-dashboard-widget';

export const clickedButton = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked button',
});

export const clickedDismiss = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked dismiss button',
});
