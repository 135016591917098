import {
  BooklogReference,
  BooklogTask_State_Stage,
} from '@sparx/api/apis/sparx/reading/tasks/v1/booklog';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { timestampToDate } from '@sparx/subscription-manager/src/utils/dates';
import { add, differenceInMilliseconds } from 'date-fns';
import { getBooklogTaskSpec, getBooklogTaskState } from 'queries/tasks';

export type Minutes = {
  suggested: number;
  min: number;
};

export const getSuggestedMinutes = (silverReader: boolean): Minutes => {
  if (silverReader) {
    return { suggested: 10, min: 5 };
  }
  return { suggested: 20, min: 15 };
};

export const getTimerStats = (task: Task) => {
  const state = getBooklogTaskState(task);
  const spec = getBooklogTaskSpec(task);
  const isSilverReader = Boolean(spec?.silverReaderConfig);
  const stage = state?.stage;
  const paused = stage === BooklogTask_State_Stage.PAUSED;
  const suggested = getSuggestedMinutes(isSilverReader);
  const accumulatedTimeMs = (state?.accumulatedReadingTime || 0) * 60 * 1000;
  let runningTimeMs = 0;
  if (!paused && state?.startReading !== undefined) {
    runningTimeMs = differenceInMilliseconds(new Date(), timestampToDate(state.startReading));
  }
  const totalTimeMs = accumulatedTimeMs + runningTimeMs;
  const exceededSuggested = totalTimeMs > suggested.suggested * 60 * 1000;
  const frac = totalTimeMs / (suggested.suggested * 60 * 1000);
  const exceededTime = exceededSuggested
    ? undefined
    : add(new Date(), { seconds: suggested.suggested * 60 - totalTimeMs / 1000 });
  return {
    state,
    paused,
    suggested,
    totalTimeMs,
    exceededTime,
    exceededSuggested,
    frac,
    isSilverReader,
  };
};

export const validThreeWords = (value: string) =>
  value.trim().replace(/\s+/g, ' ').split(' ').length === 3;

export const validRange = (
  range: BooklogReference | undefined,
  isSilverReader?: boolean,
): boolean =>
  !!range &&
  (isSilverReader || validThreeWords(range.firstWord) || validThreeWords(range.lastWord)) &&
  range.page > 0;
