import { useLocation } from 'react-router-dom';

// Don't show the dialog on these paths.
const blacklistedPaths = [
  '/teacher/live',
  '/teacher/classes/readingtest',
  '/teacher/reading-matters/survey',
  '/teacher/mis-sync-import',
];

export const useIsPathBlacklisted = () => {
  const location = useLocation();

  return blacklistedPaths.includes(location.pathname);
};
