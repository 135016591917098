import { SentimentRating } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { useState } from 'react';

import { Section } from '../sections';
import { useShouldShowBookACall } from './show-book-a-call';

export const useCurrentSection = (sentimentRating: SentimentRating) => {
  const shouldShowBookACall = useShouldShowBookACall(sentimentRating);
  const [currentSection, setCurrentSection] = useState<Section>(Section.Sentiment);

  const onNextSection = () => {
    switch (currentSection) {
      case Section.Sentiment:
        setCurrentSection(Section.Comment);
        break;
      case Section.Comment:
        setCurrentSection(shouldShowBookACall ? Section.BookACall : Section.Submitted);
        break;
      case Section.BookACall:
        setCurrentSection(Section.Submitted);
    }
  };

  return { currentSection, setCurrentSection, onNextSection };
};
