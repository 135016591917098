import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './custom-book-page.module.css';

export const CustomBookBlacklisted = () => (
  <div className={styles.Blacklisted}>
    <FontAwesomeIcon icon={faExclamationTriangle} />
    <div className={styles.BlacklistedText}>
      <p>This book isn&apos;t available to read with Sparx Reader. Please choose another book.</p>
      <p>If you would like to read this book in future, please speak to your Teacher.</p>
    </div>
  </div>
);
