type ProductKey =
  | 'SPARX_MATHS'
  | 'SPARX_READER'
  | 'SPARX_SCIENCE'
  | 'SPARX_TEACHING';

interface ProductURLs {
  products: {
    [key: string]: string;
  };
}

interface Product {
  key: ProductKey;
  name: string;
  niceUrl: string;
}

export interface ProductWithUrl extends Product {
  url: string;
}

const productList: Product[] = [
  {
    key: 'SPARX_MATHS',
    name: 'Sparx Maths',
    niceUrl: 'https://sparxmaths.uk',
  },
  {
    key: 'SPARX_READER',
    name: 'Sparx Reader',
    niceUrl: 'https://app.sparxreader.com',
  },
  {
    key: 'SPARX_SCIENCE',
    name: 'Sparx Science',
    niceUrl: 'https://app.sparxscience.com',
  },
  // TODO: maybe re-enable this - we probably don't want to link students to it at the moment
  // {
  //   key: 'SPARX_TEACHING',
  //   name: 'Sparx Teaching',
  //   niceUrl: 'https://app.sparxteaching.com',
  // },
];

export const loadProductUrls = (config: {
  indexweaverApiURL: string;
  schoolID: string;
  authorizationToken: string;
  isTeacher?: boolean;
  product?: ProductKey;
}) =>
  fetch(`${config.indexweaverApiURL}/urls`, {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Authorization: config.authorizationToken,
    },
    body: JSON.stringify({
      s: config.schoolID,
      u: config.isTeacher ? 'teacher' : 'student',
    }),
  })
    .then((res) => res.json() as Promise<ProductURLs>)
    .then(({ products }) =>
      productList
        .filter((p) => products[p.key] && p.key !== config.product)
        .map(
          (p) =>
            ({ ...p, url: products[p.key] || '' }) satisfies ProductWithUrl,
        ),
    );
