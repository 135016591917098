import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { FCWithChildren } from 'utils/props';

export const AdminMenuPortal: FCWithChildren = ({ children }) => {
  const getContainer = () => document.createElement('div');
  const [container] = useState(getContainer());
  useEffect(() => {
    // Find the root element in your DOM
    const root = document.getElementById('sidebar-portal');
    if (!root) {
      return; // can't do anything, header is not mounted
    }
    // Append container to root
    root.prepend(container);
    return () => {
      // On cleanup remove the container
      if (root) {
        root.removeChild(container);
      }
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
};
