import { User } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { useUser } from 'queries/session';
import { useState } from 'react';
import { timestampToMoment } from 'utils/time';

import { FeedbackPopover } from './popover';

const isFeedbackDue = (teacher: User) => {
  if (!teacher.askSentimentFeedbackAt) {
    return false;
  }

  return timestampToMoment(teacher.askSentimentFeedbackAt).isBefore();
};

export const TeacherSentimentFeedback = () => {
  const user = useUser();
  const [hasDialogBeenDisplayed, setHasDialogBeenDisplayed] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  if (user && !isFeedbackDue(user)) return;

  if (!hasDialogBeenDisplayed && user && isFeedbackDue(user)) {
    setHasDialogBeenDisplayed(true);
    setTimeout(() => setIsVisible(true), 50);
  }

  return <FeedbackPopover isVisible={isVisible} />;
};
