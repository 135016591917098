import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AssessmentTask_Option,
  AssessmentTask_Question,
  AssessmentTask_Question_Stage,
  AssessmentTask_State,
} from '@sparx/api/apis/sparx/reading/tasks/v1/assessment';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { Background } from '@sparx/api/apis/sparx/reading/users/v1/settings';
import classNames from 'classnames';
import { BookContent } from 'components/book/book-content';
import { bookSettingsToStyles } from 'components/book/book-settings';
import { Button } from 'components/buttons/button';
import { ScoredTaskCompleteModal } from 'components/tasks/task-complete-modal';
import { useUserSettings } from 'queries/session';
import { useTaskActionsLoading } from 'queries/tasks';
import { CSSProperties, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useWindowSize } from 'utils/hooks';
import { useAssessmentActions } from 'utils/tasks';
import { validate as validateUUID } from 'uuid';

import styles from './assessment-task-view.module.css';

interface IAssessmentTaskViewProps {
  task: Task;
  state: AssessmentTask_State;
}

export const AssessmentTaskView: React.FC<IAssessmentTaskViewProps> = ({ task, state }) => {
  const actions = useAssessmentActions(task.taskId);

  let questionElement;
  if (state.question) {
    questionElement = <TaskQuestion question={state.question} taskID={task.taskId} />;
  } else {
    questionElement = <Intro state={state} onStart={actions.progress} />;
  }

  const windowSize = useWindowSize();
  const smallWidth = Boolean(windowSize.width && windowSize.width < 500);

  return (
    <BookContent className={styles.Assessment} defaultFontSize={smallWidth ? 16 : 20}>
      <TransitionGroup
        component="div"
        className={classNames(styles.Wrapper, 'PanelPaperbackQuestionContainer')}
      >
        <CSSTransition
          key={state.question?.index}
          timeout={500}
          classNames={'PanelPaperbackQuestion'}
        >
          {questionElement}
        </CSSTransition>
      </TransitionGroup>

      <ScoredTaskCompleteModal
        state={task.state}
        retry={actions.retry}
        isOpen={task.state?.completed}
        task={task}
        hideScoreMessage={true}
      />
    </BookContent>
  );
};

interface IIntroProps {
  state: AssessmentTask_State;
  onStart: () => void;
}

const Intro = ({ state, onStart }: IIntroProps) => {
  const loading = useTaskActionsLoading();
  const { data: settings } = useUserSettings();

  const showBackground =
    settings?.background === Background.UNSPECIFIED || settings?.background === Background.WHITE;
  return (
    <div className={styles.Container}>
      <div className={styles.IntroScreen}>
        <div className={styles.IntoScreenContent}>
          <h2>{state.quizTitle}</h2>
          <div
            className={classNames(styles.IntroDescription, {
              [styles.IntroDescriptionBackground]: showBackground,
            })}
          >
            <ReactMarkdown>{state.quizDescription}</ReactMarkdown>
          </div>
        </div>
        <div className={styles.IntroScreenButtons}>
          <Button
            analyticsEvent={undefined}
            loading={loading}
            onClick={onStart}
            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            Start
          </Button>
        </div>
      </div>
    </div>
  );
};

interface ITaskQuestionProps {
  taskID: string;
  question: AssessmentTask_Question;
}

const TaskQuestion = ({ taskID, question }: ITaskQuestionProps) => {
  const actions = useAssessmentActions(taskID);
  const loading = useTaskActionsLoading();
  return (
    <div className={styles.Container}>
      <div className={styles.InnerContainer}>
        <div className={styles.QuestionNumber}>Q{(question.index || 0) + 1}.</div>
        <Question question={question} isLoading={loading} onSubmit={actions.sendAnswer} />
      </div>
    </div>
  );
};

interface IQuestionProps {
  question: AssessmentTask_Question;
  isLoading?: boolean;
  onSubmit: (answer: string) => void;
}

export const Question = ({ question, isLoading, onSubmit }: IQuestionProps) => {
  const [selectedOption, setSelectedOption] = useState('');

  const toggleAnswer = (option: string) => {
    if (option === selectedOption) {
      setSelectedOption('');
    } else {
      setSelectedOption(option);
    }
  };
  const submit = () => {
    if (selectedOption) {
      onSubmit(selectedOption);
    }
  };

  return (
    <div className={styles.Question}>
      <ReactMarkdown
        className={styles.QuestionText}
        components={{ img: args => <QuestionImage src={args.src} alt={args.alt} /> }}
      >
        {question.text || ''}
      </ReactMarkdown>
      <div className={styles.Options}>
        {question.options.map(opt => (
          <Option
            opt={opt}
            question={question}
            loading={isLoading}
            selected={opt.id === selectedOption}
            onClick={() => toggleAnswer(opt.id)}
            key={opt.id}
          />
        ))}
      </div>
      <div className={styles.Continue}>
        <Button
          analyticsEvent={undefined}
          onClick={submit}
          disabled={!selectedOption}
          loading={isLoading}
          color={'default'}
          // We don't have a debounce here as this button is pressed twice in quick succession
          // (for submitting and then moving on) and we already have a loading state.
          debounceMs={0}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

interface IOptionProps {
  opt: AssessmentTask_Option;
  question: AssessmentTask_Question;
  loading?: boolean;
  selected?: boolean;
  onClick: () => void;
}

const Option = ({ opt, question, loading, selected, onClick }: IOptionProps) => {
  // Apply the styles directly to the button as it does not inherit settings
  // from the BookContent wrapper.
  const { data: settings } = useUserSettings();
  const windowSize = useWindowSize();
  const smallWidth = Boolean(windowSize.width && windowSize.width < 500);
  const settingsStyles = bookSettingsToStyles(settings, { defaultFontSize: smallWidth ? 16 : 20 });
  const buttonStyles = {
    fontFamily: settingsStyles.fontFamily,
    fontSize: settingsStyles.fontSize,
    letterSpacing: settingsStyles.letterSpacing,
  } as CSSProperties;

  // If the button is none of selected, correct or incorrect use the settings colour
  if (!selected) {
    buttonStyles.background = settingsStyles.background;
  }
  return (
    <Button
      analyticsEvent={undefined}
      variant="srt"
      disabled={question.stage !== AssessmentTask_Question_Stage.QUESTION}
      className={classNames(styles.Option, {
        [styles.OptionDisabled]: question.stage !== AssessmentTask_Question_Stage.QUESTION,
        [styles.OptionLoading]: loading,
        [styles.OptionSelected]: selected && !question.partResults?.[opt.id],
      })}
      onClick={onClick}
      style={buttonStyles}
    >
      <span className={styles.OptionText}>{opt.text}</span>
    </Button>
  );
};

const sparxAssetURL = 'https://assets.sparxhomework.uk';

// If the image is an asset, then we load it from the sparx asset url
const QuestionImage = ({ src, alt }: { src?: string; alt?: string }) => (
  <div className={styles.Image}>
    <img src={validateUUID(src || '') ? `${sparxAssetURL}/${src}` : src} alt={alt} />
  </div>
);
