import { FCWithChildren } from 'utils/props';

export const Content: FCWithChildren<{ className?: string; style?: React.CSSProperties }> = ({
  children,
  style,
  className,
}) => (
  <div className={`scrollable ` + (className || '')} style={style}>
    {children}
  </div>
);
