import { LeaderboardDateRange } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { subMonths } from 'date-fns';

import styles from './banner.module.css';

type DateRangeSetting = {
  title: string;
  subtitle: string;
};

const dateRangeSettings: Record<LeaderboardDateRange, DateRangeSetting> = {
  [LeaderboardDateRange.THIS_WEEK]: {
    title: 'Weekly leaderboard',
    subtitle: 'Monday to Sunday',
  },
  [LeaderboardDateRange.LAST_WEEK]: {
    title: 'Weekly leaderboard',
    subtitle: 'Monday to Sunday',
  },
  [LeaderboardDateRange.THIS_MONTH]: {
    title: 'Monthly leaderboard',
    subtitle: new Date().toLocaleString('default', { month: 'long' }),
  },
  [LeaderboardDateRange.LAST_MONTH]: {
    title: 'Monthly leaderboard',
    subtitle: subMonths(new Date(), 1).toLocaleString('default', { month: 'long' }),
  },
  [LeaderboardDateRange.THIS_YEAR]: {
    title: 'Yearly leaderboard',
    subtitle: 'This academic year',
  },
};

export type Props = {
  dateRange: LeaderboardDateRange;
};

export const Banner = ({ dateRange }: Props) => {
  const { title, subtitle } = dateRangeSettings[dateRange];

  return (
    <div className={styles.Container}>
      <div className={styles.Ribbon}>
        <p className={styles.Title}>{title}</p>
        <p className={styles.Subtitle}>{subtitle}</p>
      </div>

      <Crown className={styles.Crown} />
    </div>
  );
};

const Crown = ({ className }: { className: string }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 119 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_d_4147_2749)">
      <path
        d="M106.51 31.7695C106.51 26.7995 102.48 22.7695 97.5103 22.7695C92.5403 22.7695 88.5103 26.7995 88.5103 31.7695C88.5103 33.1095 88.8103 34.3695 89.3303 35.5095L76.7003 39.7095L65.5503 22.9195C67.7103 21.2795 69.1003 18.6895 69.1003 15.7695C69.1003 10.7995 65.0703 6.76953 60.1003 6.76953C55.1403 6.77953 51.1203 10.8095 51.1203 15.7695C51.1203 18.6995 52.5303 21.2995 54.7003 22.9395L43.5203 39.7695L29.8403 35.2295C30.2803 34.1695 30.5303 32.9995 30.5303 31.7695C30.5303 26.7995 26.5003 22.7695 21.5303 22.7695C16.5603 22.7695 12.5303 26.7995 12.5303 31.7695C12.5303 36.7395 16.5603 40.7695 21.5303 40.7695C21.8003 40.7695 22.0603 40.7495 22.3203 40.7295L33.0503 68.5695V79.4595C33.0503 81.2895 34.5303 82.7695 36.3603 82.7695H83.8603C85.6903 82.7695 87.1703 81.2895 87.1703 79.4595V68.5095L97.8703 40.7495C102.68 40.5595 106.52 36.6195 106.52 31.7695H106.51Z"
        fill="#EE9003"
      />
    </g>
    <path
      d="M35.79 65.7207H84.33C85.87 65.7207 87.12 66.9707 87.12 68.5107V79.5107C87.12 81.3407 85.64 82.8207 83.81 82.8207H36.31C34.48 82.8207 33 81.3407 33 79.5107V68.5107C33 66.9707 34.25 65.7207 35.79 65.7207Z"
      fill="#FDA713"
    />
    <path
      d="M87.0396 68.7695L98.2197 39.7695L92.6296 34.4595L76.6597 39.7695L65.3596 22.7695H59.6396V68.7695H87.0396Z"
      fill="#EED603"
    />
    <path
      opacity="0.5"
      d="M87.0396 68.7695L98.2197 39.7695L92.6296 34.4595L76.6597 39.7695L65.3596 22.7695H59.6396V68.7695H87.0396Z"
      fill="#FDA713"
    />
    <path
      d="M33.0696 68.8301L21.8896 39.8301L27.4796 34.5201L43.4596 39.8301L54.7496 22.8301H60.0396V68.8301H33.0696Z"
      fill="#EED603"
    />
    <path
      d="M97.4697 40.8301C102.44 40.8301 106.47 36.8006 106.47 31.8301C106.47 26.8595 102.44 22.8301 97.4697 22.8301C92.4992 22.8301 88.4697 26.8595 88.4697 31.8301C88.4697 36.8006 92.4992 40.8301 97.4697 40.8301Z"
      fill="#EE9003"
    />
    <path
      d="M21.4697 40.8301C26.4403 40.8301 30.4697 36.8006 30.4697 31.8301C30.4697 26.8595 26.4403 22.8301 21.4697 22.8301C16.4992 22.8301 12.4697 26.8595 12.4697 31.8301C12.4697 36.8006 16.4992 40.8301 21.4697 40.8301Z"
      fill="#FDA713"
    />
    <path
      d="M60.0596 24.8301C65.0301 24.8301 69.0596 20.8006 69.0596 15.8301C69.0596 10.8595 65.0301 6.83008 60.0596 6.83008C55.089 6.83008 51.0596 10.8595 51.0596 15.8301C51.0596 20.8006 55.089 24.8301 60.0596 24.8301Z"
      fill="#FDA713"
    />
    <path
      d="M61.1201 37.8504L64.6001 44.1804L71.6901 45.5404C72.6501 45.7204 73.0301 46.8904 72.3601 47.6004L67.4201 52.8704L68.3201 60.0304C68.4401 61.0004 67.4501 61.7204 66.5701 61.3004L60.0301 58.2304L53.4901 61.3004C52.6101 61.7104 51.6101 60.9904 51.7401 60.0304L52.6401 52.8704L47.7001 47.6004C47.0301 46.8904 47.4101 45.7204 48.3701 45.5404L55.4601 44.1804L58.9401 37.8504C59.4101 37.0004 60.6401 37.0004 61.1101 37.8504H61.1201Z"
      fill="#FDA713"
    />
    <path
      d="M72.15 47.8209L67.42 52.8609L68.29 59.7109C68.43 60.8109 67.29 61.6309 66.29 61.1609L60.04 58.2209L60.17 37.2109C60.79 37.2109 61.06 37.7309 61.27 38.1209L64.61 44.1709L71.4 45.4709C72.49 45.6709 72.93 47.0109 72.16 47.8209H72.15Z"
      fill="#EE9003"
    />
    <path
      opacity="0.6"
      d="M60.0596 68.8301H87.1096V79.5201C87.1096 81.3501 85.6296 82.8301 83.7996 82.8301H60.0696V68.8301H60.0596Z"
      fill="#EE9003"
    />
    <path
      d="M60.04 6.83008C65.01 6.83008 69.04 10.8601 69.04 15.8301C69.04 20.8001 65.01 24.8301 60.04 24.8301V6.83008Z"
      fill="#EE9003"
    />
    <defs>
      <filter
        id="filter0_d_4147_2749"
        x="4.53027"
        y="3.76953"
        width="109.99"
        height="92"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0 0.0156863 0 0 0 0.4 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4147_2749" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4147_2749"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
