import { LeagueTableRow } from './league-table-row';

export const LoadingRow = () => {
  return (
    <LeagueTableRow
      leaderboardUser={{ isCurrentUser: false, rank: 0, xp: 0, firstName: '' }}
      isLoading
    />
  );
};
