import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import styles from './checkbox.module.css';

interface ICheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inverted?: boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = ({ checked, disabled, onChange, inverted }) => (
  <div
    className={classNames(styles.Container, {
      [styles.ContainerDisabled]: disabled,
      [styles.ContainerSelected]: checked,
      [styles.ContainerInverted]: inverted,
    })}
  >
    <input
      type="checkbox"
      className={styles.Checkbox}
      checked={checked || false}
      disabled={disabled}
      onChange={onChange}
    />
    <FontAwesomeIcon icon={faCheck} className={styles.Check} />
  </div>
);
