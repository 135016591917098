import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { formatRelative } from 'date-fns';
import { enGB } from 'date-fns/locale';
import moment from 'moment';

export const timestampToMoment = (ts?: Timestamp): moment.Moment => moment(timestampToDate(ts));

export const timestampToDate = (ts?: Timestamp): Date => (ts ? Timestamp.toDate(ts) : new Date(0));

/**
 * momentToTimestamp Converts a moment.Moment to a Timestamp.
 */
export const momentToTimestamp = (m: moment.Moment): Timestamp => Timestamp.fromDate(m.toDate());

/**
 * millisToISOString Converts millis since epoch to an ISO string
 */
export const millisToISOString = (s: number): string => {
  const d = new Date(s);
  return d.toISOString();
};

export const formatRelativeTimestamp = (ts: Timestamp): string => {
  const formatRelativeLocale = {
    lastWeek: "'last' cccc 'at' K:mm a",
    yesterday: "'yesterday at' K:mm a",
    today: "'today at' K:mm a",
    tomorrow: "'tomorrow at' K:mm a",
    nextWeek: "'next' cccc 'at' K:mm a",
    other: "dd/MM/yyyy 'at' K:mm a",
  };

  return formatRelative(Timestamp.toDate(ts), new Date(), {
    locale: {
      ...enGB,
      formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
    },
  });
};
