// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/school/keycontacts/v1/keycontacts.proto" (package "sparx.school.keycontacts.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * The details for a single contact.
 *
 * @deprecated
 * @generated from protobuf message sparx.school.keycontacts.v1.KeyContact
 */
export interface KeyContact {
  /**
   * The given (i.e. first) name of the contact.
   *
   * @generated from protobuf field: string given_name = 1;
   */
  givenName: string;
  /**
   * The family (i.e. last) name of the contact.
   *
   * @generated from protobuf field: string family_name = 2;
   */
  familyName: string;
  /**
   * The email address of the contact.
   *
   * @generated from protobuf field: string email = 3;
   */
  email: string;
  /**
   * The timestamp of the last time a welcome email was sent to this contact.
   * Will be set to null if contact email changed.
   *
   * @generated from protobuf field: google.protobuf.Timestamp email_send_time = 4;
   */
  emailSendTime?: Timestamp;
  /**
   * Annotations for services to store arbitrary data against the key contact.
   * Write behaviour is merge.
   *
   * @generated from protobuf field: map<string, string> annotations = 5;
   */
  annotations: {
    [key: string]: string;
  };
}
// @generated message type with reflection information, may provide speed optimized methods
class KeyContact$Type extends MessageType<KeyContact> {
  constructor() {
    super('sparx.school.keycontacts.v1.KeyContact', [
      { no: 1, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'family_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'email', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'email_send_time', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'annotations',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.school.keycontacts.v1.KeyContact
 */
export const KeyContact = new KeyContact$Type();
