import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './loading-spinner.module.css';

export const LoadingSpinner = () => {
  return (
    <div className={styles.LoadingSpinner}>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  );
};
