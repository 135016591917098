import {
  faBook,
  faCodeCompare,
  faComments,
  faEdit,
  faFileImport,
  faMedal,
  faSearch,
  faThumbsUp,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';
import { UserRole, useUserHasRole } from 'queries/session';
import { Outlet, useParams } from 'react-router-dom';
import { AdminMenuPortal } from 'views/admin/components/admin-menu-portal';
import { SidebarPrimaryLink } from 'views/teacher/components/collapsible-sidebar';

import styles from './book-view.module.css';

export const BookView = ({ allBooks = false }: { allBooks?: boolean }) => {
  const bookId = useParams().bookId;
  const isContentAdmin = useUserHasRole(UserRole.EQMAdmin);

  return (
    <div className={styles.Page}>
      <AdminMenuPortal>
        <h4>Meta</h4>
        {allBooks ? (
          <>
            <SidebarPrimaryLink to={`/admin/super/allbooks/${bookId}`} name="Book" icon={faBook} />
            {isContentAdmin && (
              <>
                <SidebarPrimaryLink
                  to={`/admin/super/allbooks/${bookId}/edit`}
                  name="Edit"
                  icon={faEdit}
                />
                <h4>Silver Reader</h4>
                <SidebarPrimaryLink
                  to={`/admin/super/allbooks/${bookId}/questions`}
                  name="Questions"
                  icon={faMedal}
                />
              </>
            )}
          </>
        ) : (
          <>
            <SidebarPrimaryLink to={`/admin/books/${bookId}`} name="Book" icon={faBook} />
            {isContentAdmin && (
              <SidebarPrimaryLink to={`/admin/books/${bookId}/edit`} name="Edit" icon={faEdit} />
            )}
          </>
        )}

        {!allBooks && (
          <>
            <h4>Ebook</h4>
            <SidebarPrimaryLink
              to={`/admin/books/${bookId}/preview`}
              name="Preview"
              icon={faSearch}
            />
            <SidebarPrimaryLink
              to={`/admin/books/${bookId}/write`}
              name="Questions"
              icon={faUserEdit}
            />
            <SidebarPrimaryLink
              to={`/admin/books/${bookId}/proofread`}
              name="Proofread"
              icon={faThumbsUp}
            />
            <SidebarPrimaryLink
              to={`/admin/books/${bookId}/feedback`}
              name="Feedback"
              icon={faComments}
            />
            {isContentAdmin && (
              <>
                <SidebarPrimaryLink
                  to={`/admin/books/${bookId}/compare`}
                  name="Release"
                  icon={faCodeCompare}
                />
                <SidebarPrimaryLink
                  to={`/admin/books/${bookId}/editor`}
                  name="Edit ebook"
                  icon={faEdit}
                />
                <SidebarPrimaryLink
                  to={`/admin/books/${bookId}/importQuestions`}
                  name="Import questions"
                  icon={faFileImport}
                />
              </>
            )}
          </>
        )}
      </AdminMenuPortal>
      <Outlet />
    </div>
  );
};
