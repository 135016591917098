import { errorIsUnauthenticated } from 'app/containers/app-container/app-container';
import { CenteredEmpty } from 'components/centeredview/centered-view';
import { Loading } from 'components/loading/loading';
import { useSession } from 'queries/session';
import { useEffect } from 'react';
import { useNavigateHomeForUser } from 'views/views';

import { ServerUnavailableView } from '../server-unavailable-view/server-unavailable-view';

export const LandingView = () => {
  const navigateHome = useNavigateHomeForUser();
  const { isError, error: sessionError } = useSession();

  useEffect(() => {
    navigateHome(false);
  }, [navigateHome]);

  if (isError && !errorIsUnauthenticated(sessionError)) {
    return <ServerUnavailableView error={sessionError} />;
  }

  return (
    <CenteredEmpty>
      <Loading white />
    </CenteredEmpty>
  );
};
