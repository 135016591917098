import {
  SchoolCompletedBookCountRequest,
  SchoolCompletedBookCountResponse,
} from '@sparx/api/apis/sparx/reading/management/v1/management';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { managementClient } from 'api';

export const useSchoolCompletedBookCount = <T = SchoolCompletedBookCountResponse>(
  options?: UseQueryOptions<SchoolCompletedBookCountResponse, Error, T, string[]>,
) => {
  return useQuery(
    ['school-completed-book-count'],
    () =>
      managementClient.schoolCompletedBookCount(SchoolCompletedBookCountRequest.create()).response,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    },
  );
};
