import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect, useState } from 'react';

const heightAtom = atomWithStorage('reader-dev-tools-height', 300);
const minHeight = 100;
const maxHeightBoundary = 300;
const headerHeight = 49;

export const useResize = () => {
  const [height, setHeight] = useAtom(heightAtom);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [mouseYPosition, setMouseYPosition] = useState(0);

  useEffect(() => {
    const height = document.documentElement.clientHeight - mouseYPosition - headerHeight;
    const maxHeight = document.documentElement.clientHeight - maxHeightBoundary;

    if (isMouseDown && height > minHeight && height < maxHeight) {
      setHeight(height);
    }
  }, [isMouseDown, mouseYPosition, setHeight]);

  const handleResize = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsMouseDown(true);
    setMouseYPosition(e.clientY);

    const handleMouseMove = (e: MouseEvent) => {
      setMouseYPosition(e.clientY);
    };
    const handleMouseUp = () => {
      setIsMouseDown(false);
      document.removeEventListener('mousemove', handleMouseMove);
    };

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
  };

  return { height, handleResize };
};
