import styles from './unknown.module.css';

export const Unknown = () => {
  return (
    <>
      <h3 className={styles.StepTitle}>Sorry, something has gone wrong</h3>
      <div className={styles.StepDescription}>
        <p style={{ margin: 0 }}>Please try refreshing the page.</p>
      </div>
    </>
  );
};
