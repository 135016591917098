import { ModuleSpec } from '@sparx/teacher-training';

import { useTrainingModules } from './hooks';
import {
  moduleTrinityLaunchingReader,
  moduleTrinitySuccessWithReader,
  TRAINING_MODULE_ESSENTIAL,
  TRAINING_MODULE_USING_SPARX_READER,
} from './module-specs';

export type TrainingTab = {
  id: string;
  title: string;
  modules: ModuleSpec[];
};

export const MaximisingImpactTab: TrainingTab = {
  id: 'maximising-impact',
  title: 'Maximising Impact',
  modules: [moduleTrinityLaunchingReader, moduleTrinitySuccessWithReader],
};

export const useTrainingTabs = (): TrainingTab[] => {
  const trainingModules = useTrainingModules();
  const essential = trainingModules.find(s => s.name === TRAINING_MODULE_ESSENTIAL);
  const using = trainingModules.find(s => s.name === TRAINING_MODULE_USING_SPARX_READER);
  if (!essential || !using) {
    throw new Error('Essential and Using Sparx Reader modules must be present');
  }
  return [
    {
      id: 'essential-training',
      title: 'Essential Training',
      modules: [essential],
    },
    {
      id: 'using-sparx-reader',
      title: 'Using Sparx Reader',
      modules: [using],
    },
    MaximisingImpactTab,
  ];
};
