import { faListAlt } from '@fortawesome/free-solid-svg-icons';

import { Tab } from '../../types';

export const useGettingStartedOverviewTab = (): Tab => {
  return {
    id: 'overview',
    title: 'Overview',
    subtitle: '',
    pageSubtitle: '',
    icon: faListAlt,
    responsibility: '',
  };
};
