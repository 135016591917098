import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/panel/panel.module.css';
import { FCWithChildren } from 'utils/props';

interface IPanelProps {
  icon?: IconProp;
  iconClass?: string;
  title: string;
  buttons?: React.ReactNode;
  className?: string;
}

export const Panel: FCWithChildren<IPanelProps> = ({
  children,
  icon,
  iconClass,
  title,
  buttons,
  className,
}) => {
  return (
    <div className={classNames(styles.Panel, className)}>
      {icon && (
        <div className={`${styles.PanelLeft} ${styles.PanelIcon}`}>
          <FontAwesomeIcon icon={icon} className={iconClass} />
        </div>
      )}
      <div className={styles.PanelRight}>
        <h2>{title}</h2>
        {children}
        {buttons && <div className={styles.PanelButtons}>{buttons}</div>}
      </div>
    </div>
  );
};
