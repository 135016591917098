import { UploadedAssessmentResult } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import {
  AssessmentForm,
  AssessmentPackageState,
} from '@sparx/api/apis/sparx/reading/management/v1/management';
import { StudentState } from '@sparx/api/apis/sparx/reading/monitoring/v1/monitoring';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { add, format } from 'date-fns';
import moment, { Moment } from 'moment';
import { IStudentWithExperience } from 'queries/management';
import { timestampToMoment } from 'utils/time';

import { getStudentStateSummary } from '../live-view/utils';
import { AssessmentPackageSummaryState } from './readingtest-view';
import { AssessmentPackagesSummary, IStudentRow, PackageSummary } from './types';

export const DEFAULT_DURATION_MINUTES = 120;
export const MAX_DURATION_HOURS = 9;

export const makeStudentRow = (
  student: IStudentWithExperience,
  packages: AssessmentPackageState[],
  state: StudentState | undefined,
  assessments: AssessmentForm[],
  additionalPackages: PackageSummary[],
): IStudentRow => {
  const summary = getStudentStateSummary(state);
  return {
    userId: student.userId,
    student,
    ...getAssessmentPackageSummary(packages, assessments, additionalPackages),
    ...summary,
  };
};

export const expiresOutsideSchoolHours = (startTime: Date, durationMinutes: number): boolean => {
  const expiresAt = add(startTime, { minutes: durationMinutes });
  return expiresAt.getHours() > 15 || (expiresAt.getHours() === 15 && expiresAt.getMinutes() > 30);
};

const getAssessmentPackageSummary = (
  packages: AssessmentPackageState[],
  assessments: AssessmentForm[],
  additionalPackages: PackageSummary[],
): AssessmentPackagesSummary => {
  const status = {
    hasActivePackage: false,
    activeRecently: false,
    hasScheduledPackage: false,
    isResumable: false,
  };

  let latest = packageSummaryForStudent(packages[0], assessments);
  for (const pkg of additionalPackages) {
    if (!latest || (pkg.startDate && pkg.startDate.isAfter(latest.startDate))) {
      latest = pkg;
    }
  }

  if (latest?.startDate && latest?.endDate && latest?.packageId) {
    status.hasActivePackage = latest.startDate.isSameOrBefore() && latest.endDate.isAfter();
    status.activeRecently = latest.endDate.clone().isAfter();
    status.hasScheduledPackage = latest.startDate.isAfter();

    if (
      latest.state === 'started' &&
      latest.startDate.isBefore() &&
      latest.endDate.isSameOrAfter(moment().subtract(21, 'days')) &&
      latest.endDate.isBefore()
    ) {
      status.isResumable = true;
    }
  }

  const completedAdditionalPackages = additionalPackages.filter(p => p.completed).length;
  const completedPackageCount =
    packages.filter(pkg => pkg.package && pkg.package?.tasksComplete >= pkg.package?.tasksTotal)
      .length + completedAdditionalPackages;

  return {
    latestPackage: latest,
    ...status,
    packageCount: packages.length,
    completedPackageCount,
  };
};

const packageSummaryForStudent = (
  pkg: AssessmentPackageState | undefined,
  assessments: AssessmentForm[],
): PackageSummary | undefined => {
  if (!pkg?.package) return undefined;

  const type = pkg.package.type;
  const assessment = (assessments || []).find(assessment => assessment.type === type);
  const timeSpent = Math.floor(pkg.timeSpentSeconds / 60);

  let state: AssessmentPackageSummaryState = 'notstarted';
  let completed = false;
  if (pkg.package && pkg.started) {
    state = 'started';
  }
  if (pkg.package && pkg.package.tasksComplete >= pkg.package.tasksTotal) {
    completed = true;
    state = 'finished';
  }

  return {
    packageId: pkg.package.packageId,
    title: assessment ? trimPackageName(assessment.title) : '-',
    endDateString: timestampToMoment(pkg.package.endDate).format('DD/MM/YY'),
    state,
    timeSpentSeconds: pkg.timeSpentSeconds,
    timeSpentString: `${timeSpent} ${timeSpent === 1 ? 'minute' : 'minutes'}`,
    tasksTotal: pkg.package.tasksTotal,
    tasksComplete: pkg.package.tasksComplete,
    completed,
    startDate: optionalTimestampToMoment(pkg.package.startDate),
    endDate: optionalTimestampToMoment(pkg.package.endDate),
  };
};

export const packageSummaryForAssessmentPackage = (
  result: UploadedAssessmentResult,
): PackageSummary | undefined => {
  if (!result.package) return undefined;

  let state: AssessmentPackageSummaryState = 'started';
  if (result.package.state?.completionTimestamp) {
    state = 'finished';
  }
  const timeSpentSeconds = result.package.state?.questionTimeSeconds || 0;
  const timeSpentMinutes = Math.floor(timeSpentSeconds / 60);

  return {
    title: 'SRA 2024', // TODO: not hardcoded
    endDateString: timestampToMoment(result.package.endTimestamp).format('DD/MM/YY'),
    state,
    timeSpentSeconds,
    timeSpentString: `${timeSpentMinutes} ${timeSpentMinutes === 1 ? 'minute' : 'minutes'}`,
    tasksTotal: 1,
    tasksComplete: state === 'finished' ? 1 : 0,
    completed: state === 'finished',
    startDate: optionalTimestampToMoment(result.package.startTimestamp),
    endDate: optionalTimestampToMoment(result.package.endTimestamp),
  };
};

const optionalTimestampToMoment = (timestamp?: Timestamp): Moment | undefined =>
  timestamp ? moment(Timestamp.toDate(timestamp)) : undefined;

export const trimPackageName = (name: string) => {
  return name.replace('Sparx Reading Test - Form ', '');
};

export const plural = (count: number, singular: string, plural: string) =>
  count === 1 ? singular : plural;
export const pluralStudent = (count: number) => plural(count, 'student', 'students');
export const pluralTest = (count: number) => plural(count, 'test', 'tests');

export const incompleteStudents = (students: IStudentRow[]) =>
  students.filter(student => student.latestPackage?.state === 'started');

export const humanReadableDate = (d: Date) => format(d, "HH:mm 'on' EEEE do MMMM yyyy");
