import classNames from 'classnames';

import { Bar } from './hand-in-summary';
import styles from './hand-in-summary.module.css';

export const HandInSummaryLegend = ({ bars }: { bars: Array<Bar> }) => {
  return (
    <div className={styles.Legend}>
      {bars.map(bar => (
        <div className={styles.LegendItem} key={bar.type}>
          <div className={classNames(styles.LegendColour, bar.className)} />
          <div className={styles.LegendLabel}>{bar.label}</div>
        </div>
      ))}
    </div>
  );
};
