import {
  IndividualSubscriptionStatus,
  SubscriptionDetails,
} from '@sparx/api/apis/sparx/school/subscription/v1/subscriptionactions';
import { SubscriptionType } from '@sparx/api/apis/sparx/school/subscription/v1/types';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { subscriptionActionsClient } from 'api';
import { endOfDay, fromUnixTime, isBefore } from 'date-fns';
import { useSparxStaffFeatures } from 'views/teacher/components/sparx-staff-features-context';

import { useSchoolID } from './schools';

type CivilDate = {
  day: number;
  month: number;
  year: number;
};

const fromCivilDate = ({ day, month, year }: CivilDate | Readonly<CivilDate>) =>
  new Date(year, month - 1, day);

export const useGetSubscriptionDetails = <TData = SubscriptionDetails>(
  options?: UseQueryOptions<SubscriptionDetails, Error, TData, string[]>,
) => {
  const schoolID = useSchoolID();
  return useQuery(
    ['subscription'],
    () =>
      subscriptionActionsClient.getSubscriptionDetails({
        schoolName: `schools/${schoolID}`,
        product: Product.SPARX_READER,
      }).response,
    { ...options, enabled: Boolean(options?.enabled !== false && schoolID) },
  );
};

// A school is in the SubscriptionTrial mode if its current subscription
// is Trial and its pending subscription is Individual
const SubscriptionTrial = 'TRIAL';
type SubscriptionTrial = typeof SubscriptionTrial;

// SubscriptionMode mirrors the SubscriptionType from the API, but
// narrows down the cases to be more relevant to client flows
type SubscriptionMode = SubscriptionTrial | 'INDIVIDUAL' | 'OTHER';

type SubscriptionStatus = {
  mode: SubscriptionMode;
  currentEndDate?: Date;
  isInSubscription: boolean;
  isAgreed: boolean;
};

export const useSubscriptionStatus = (
  options?: UseQueryOptions<SubscriptionDetails, Error, SubscriptionDetails, string[]>,
): SubscriptionStatus | undefined => {
  const { data, dataUpdatedAt } = useGetSubscriptionDetails({ staleTime: Infinity, ...options });

  if (!data) {
    return undefined;
  }

  let mode: SubscriptionMode = 'OTHER';
  if (
    data.currentSubscription?.type === SubscriptionType.TRIAL &&
    data.pendingSubscription?.type === SubscriptionType.INDIVIDUAL
  ) {
    mode = SubscriptionTrial;
  } else if (data.pendingSubscription?.type === SubscriptionType.INDIVIDUAL) {
    mode = 'INDIVIDUAL';
  }

  // convert the dataUpdatedAt from millis to seconds.
  const now = fromUnixTime(dataUpdatedAt / 1000);
  const end = data.currentSubscription?.endDate
    ? fromCivilDate(data.currentSubscription.endDate)
    : undefined;

  // We are in the bounds of the current subscription if the end date
  // is undefined (cannot be for trial or individual), or it has not passed.
  const isInSubscription = !end || isBefore(now, endOfDay(end));

  const isAgreed = data.pendingSubscription?.status !== IndividualSubscriptionStatus.NOT_YET_AGREED;

  return {
    mode,
    currentEndDate: end,
    isInSubscription,
    isAgreed,
  };
};

export const useSubscriptionLockout = (
  options?: UseQueryOptions<SubscriptionDetails, Error, SubscriptionDetails, string[]>,
) => {
  const status = useSubscriptionStatus(options);
  const { sparxStaffFeaturesEnabled } = useSparxStaffFeatures();
  const isLocked =
    (status?.mode === SubscriptionTrial || status?.mode === 'INDIVIDUAL') &&
    !status?.isInSubscription &&
    !status?.isAgreed;

  if (sparxStaffFeaturesEnabled) {
    return false;
  }
  if (status === undefined) {
    return undefined;
  }
  return isLocked;
};
