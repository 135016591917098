import * as Sentry from '@sentry/react';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { ErrorPage } from 'components/errors/error-message';
import { Header } from 'components/sections/header';
import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { AppContainer } from './containers/app-container/app-container';

const FallbackContent = () => (
  <ErrorPage message="An error has occurred">
    <p>We&apos;re really sorry but an unexpected error has occurred.</p>
    <p>
      Please <strong>refresh the page</strong> and try again.
    </p>
  </ErrorPage>
);

const makeEvent = (action: string, msg: string, stack: string | null | undefined) => {
  const event = {
    category: 'error fallback',
    action,
    labels: { msg, stack },
  };
  return event;
};

// A fallback component to use as the error element for Sentry.wrapCreateBrowserRouter.
// Attempt to send an event to page events and to sentry.
export function SentryRouteErrorFallback() {
  const routeError = useRouteError() as Error;
  const { sendEvent } = useClientEvent();

  useEffect(() => {
    if (!routeError) {
      return;
    }
    sendEvent(makeEvent('show router error', routeError.message, routeError.stack));
    Sentry.captureException(routeError, { level: 'fatal' });
  }, []);

  return (
    <AppContainer>
      <Header />
      <FallbackContent />
    </AppContainer>
  );
}

export const SentryRootFallback = ({
  error,
  componentStack,
}: {
  error: Error;
  componentStack: React.ErrorInfo['componentStack'];
}) => {
  const { sendEvent } = useClientEvent();
  useEffect(() => {
    sendEvent(makeEvent('show root error', error.message, componentStack));
  }, []);
  return <FallbackContent />;
};
