import { School } from '@sparx/api/apis/sparx/school/v2/schools';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { getSchool } from '@sparx/query/schools-service';
import { addDays, isAfter, isBefore, max } from 'date-fns';
import { useSession, useUser } from 'queries/session';
import { timestampToDate } from 'utils/time';

export const useSchoolID = () => {
  const user = useUser();

  // Fallback to the value set on the window if user is undefined.
  return user?.schoolId || window.READER_SCHOOL_ID;
};

// Recreated from teacher portal lib/api/reactQuery/schools.ts
const getSchoolCreateDate = (school?: School) => {
  if (!school || !school.createTime) {
    return undefined;
  }

  const schoolCreateTime = Timestamp.toDate(school.createTime);

  // Look for the most recent instance of the Reader product being added
  if (school.productChangeTimes.length) {
    const readerLastAdded = school.productChangeTimes.reduce((acc, curr) => {
      return curr.product === Product.SPARX_READER &&
        curr.enabledTime &&
        isAfter(Timestamp.toDate(curr.enabledTime), acc)
        ? Timestamp.toDate(curr.enabledTime)
        : acc;
    }, schoolCreateTime);

    if (isAfter(readerLastAdded, schoolCreateTime)) {
      return readerLastAdded;
    }
  }

  // Next, look for the school unarchive time
  if (school.undeleteTime) {
    const undeleteTime = Timestamp.toDate(school.undeleteTime);
    if (isAfter(undeleteTime, schoolCreateTime)) {
      return undeleteTime;
    }
  }

  // Just use the create time
  return schoolCreateTime;
};

/**
 * Gets the school creation date. This is the date the school was created _or_ the
 * date the school was undeleted if it was previously deleted or the most recent date Reader product
 * was enabled if that exists.
 */
export const useSchoolCreateDate = () => getSchool.useQuery({ select: getSchoolCreateDate });

/**
 *
 * A hook to determine if the user has recently started using Reader.
 * This is used as part of the decision on whether to show getting started content.
 * A user is considered to have recently started using Reader if they started
 * using it within the last `numDays` days.
 * The date is they started is defined as the most recent of the school year start date, the date
 * the school was created and the date the Reader product was enabled.
 */
export const useRecentlyStartedUsingReader = (numDays: number) => {
  const { data: session, ...sessionQueryState } = useSession();
  const { data: createDate, ...createDateQueryState } = useSchoolCreateDate();
  const schoolYearStartDate = timestampToDate(session?.schoolYearStart);
  const now = new Date();
  return {
    isLoading: sessionQueryState.isLoading || createDateQueryState.isLoading,
    isError: sessionQueryState.isError || createDateQueryState.isError,
    data:
      !session || !createDate
        ? undefined
        : isBefore(now, addDays(max([schoolYearStartDate, createDate]), numDays)),
  };
};

export const useAcademicYearStartDate = () => {
  const session = useSession();
  if (!session.data) {
    return session;
  }
  return {
    ...session,
    data: session.data.schoolYearStart && Timestamp.toDate(session.data.schoolYearStart),
  };
};
