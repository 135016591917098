import { faSpellCheck } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components/buttons/button';
import { Panel } from 'components/panel/panel';
import { Title } from 'components/title/title';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import {
  LibraryContent,
  LibraryTitle,
} from 'views/student/library-view/components/library-content';
import styles from 'views/student/vocab-view/vocab-landing-view.module.css';
import { pathForView } from 'views/views';

export const VocabLandingView = () => {
  const navigate = useNavigate();
  const onClick = () => navigate(pathForView(View.Vocab));

  return (
    <LibraryContent>
      <Title>Vocab</Title>
      <LibraryTitle>Vocabulary Check</LibraryTitle>

      <Panel
        icon={faSpellCheck}
        title="Vocabulary Check"
        buttons={
          <Button onClick={onClick} analyticsEvent={undefined}>
            Start
          </Button>
        }
        className={styles.VocabLandingPanel}
      >
        <p>
          Welcome to the Vocabulary check. Each day you will be challenged with some words. You will
          be rewarded SRP for each word you get correct!
        </p>
      </Panel>
    </LibraryContent>
  );
};
