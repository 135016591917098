import {
  ListSchoolStaffMembersResponse,
  SchoolStaffMember,
} from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { filterUserRolesForProduct } from '@sparx/staff-manager/src/utils';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { schoolStaffClient } from 'api';
import { useUser } from 'queries/session';

import { useSchoolID } from './schools';

export const useListStaff = <T = ListSchoolStaffMembersResponse>(
  schoolID: string,
  options?: UseQueryOptions<ListSchoolStaffMembersResponse, Error, T, string[]>,
) =>
  useQuery(
    ['staffv2.ListSchoolStaffMembers', schoolID],
    () =>
      schoolStaffClient.listSchoolStaffMembers({
        school: `schools/${schoolID}`,
      }).response,
    options,
  );

export const useGetStaffMember = <T = SchoolStaffMember>(
  staffID: string,
  schoolID: string,
  options?: UseQueryOptions<SchoolStaffMember, Error, T, string[]>,
) =>
  useQuery(
    ['staffv2.SchoolStaffMember', schoolID, staffID],
    () =>
      schoolStaffClient.getSchoolStaffMember({
        name: `staff/${staffID}`,
        school: `schools/${schoolID}`,
      }).response,
    options,
  );

export const useCurrentStaffMember = <T = SchoolStaffMember>(
  options?: UseQueryOptions<SchoolStaffMember, Error, T, string[]>,
) => {
  const user = useUser();
  return useGetStaffMember(user?.sparxUserId || '', user?.schoolId || '', {
    ...options,
    enabled:
      options?.enabled === false
        ? false
        : user !== undefined && Boolean(user?.sparxUserId) && Boolean(user?.schoolId),
  });
};

export const useListReaderStaff = () => {
  const schoolID = useSchoolID();
  const { data: allStaffMembers, ...listStaffQueryState } = useListStaff(schoolID || '', {
    enabled: !!schoolID,
  });
  const staffMembers = allStaffMembers?.staffMembers.filter(
    sm => sm.productAccess && filterUserRolesForProduct(sm.roles, Product.SPARX_READER).length > 0,
  );
  return { data: staffMembers, ...listStaffQueryState };
};
