import { GrpcWebFetchTransport, GrpcWebOptions } from '@protobuf-ts/grpcweb-transport';
import { RpcMetadata, RpcTransport } from '@protobuf-ts/runtime-rpc';
import { SittingsClient } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting.client';
import { CannySingleSignOnClient } from '@sparx/api/apis/sparx/canny/v1/sso.client';
import { LeaderboardsClient } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards.client';
import { UserDisplayClient } from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay.client';
import { WondeSyncClient } from '@sparx/api/apis/sparx/misintegration/wondesync/v1/wondesync.client';
import { BonusQuestionsClient } from '@sparx/api/apis/sparx/reading/bonusquestions/v1/bonusquestions.client';
import { BooksClient } from '@sparx/api/apis/sparx/reading/books/v1/book.client';
import { AssignmentServiceClient } from '@sparx/api/apis/sparx/reading/content/v1/assignment.client';
import { DefinitionsClient } from '@sparx/api/apis/sparx/reading/content/v1/definitions.client';
import { BooksClient as ContentClient } from '@sparx/api/apis/sparx/reading/content/v1/service.client';
import { FeedbackClient } from '@sparx/api/apis/sparx/reading/feedback/v1/feedback.client';
import { LeagueClient } from '@sparx/api/apis/sparx/reading/league/v1/league.client';
import { ManagementClient } from '@sparx/api/apis/sparx/reading/management/v1/management.client';
import { MonitoringClient } from '@sparx/api/apis/sparx/reading/monitoring/v1/monitoring.client';
import { ReportingClient } from '@sparx/api/apis/sparx/reading/reports/v2/reporting.client';
import { BookmarksClient } from '@sparx/api/apis/sparx/reading/silver/v1/bookmarks.client';
import { TasksClient } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks.client';
import { LibraryBooksClient } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks.client';
import { NotificationsClient } from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications.client';
import { SessionsClient } from '@sparx/api/apis/sparx/reading/users/v1/sessions.client';
import { VocabClient } from '@sparx/api/apis/sparx/reading/vocab/v1/vocab.client';
import { ReaderReportGenClient } from '@sparx/api/apis/sparx/reports/reportgen/v1/reader.client';
import { SchoolCalendarServiceClient } from '@sparx/api/apis/sparx/school/calendar/v4/calendar.client';
import { SchoolStaffServiceClient } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff.client';
import { SchoolSubscriptionDataActionsServiceClient } from '@sparx/api/apis/sparx/school/subscription/v1/subscriptionactions.client';
import { SchoolActionsServiceClient } from '@sparx/api/apis/sparx/school/v2/schoolactions.client';
import { SchoolsServiceClient } from '@sparx/api/apis/sparx/school/v2/schools.client';
import { GroupsAPIClient } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi.client';
import { SchoolStatusServiceClient } from '@sparx/api/apis/sparx/teacherportal/schoolstatus/v1/schoolstatus.client';
import { StudentAPIClient } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi.client';
import { TrainingProgressServiceClient } from '@sparx/api/apis/sparx/training/progress/v1/trainingprogress.client';
import { AccessTokenProvider, authInterceptor, extensionInterceptor } from '@sparx/grpcweb';
import { setSchoolsClient } from '@sparx/query/schools-service';

const viteRemoteDevAPIUrl = import.meta.env.VITE_DEV_REMOTE_API;
const viteLocalDevAPIUrl = import.meta.env.VITE_API_URL;
export const getAPIURL = (): string =>
  viteRemoteDevAPIUrl || viteLocalDevAPIUrl || window.settings?.apiURL;

const apiURL = getAPIURL();

const tokenFetcher = () =>
  fetch(apiURL + '/accesstoken', {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(async resp => {
    if (!resp.ok && resp.status === 401) {
      accessTokenProvider.stop();
    }
    return resp;
  });

// Configure the access token provider and set it to load periodically
const accessTokenProvider: AccessTokenProvider = new AccessTokenProvider(tokenFetcher);
accessTokenProvider.start().then(() => console.log('Access Token Provider initialised'));

export const requestAccessToken = () => accessTokenProvider.requestAccessToken();

const makeRpcMetadata: () => RpcMetadata = () => {
  const meta: RpcMetadata = {};

  const params = new URLSearchParams(window?.location.search);
  const variant = params.get('spx.variant');
  const variantHeader = 'SPX-Variant-Selector';
  if (variant) {
    meta[variantHeader] = variant;
  }

  return meta;
};

export const getAccessTokenTransport = (url: string): RpcTransport =>
  new GrpcWebFetchTransport({
    baseUrl: url,
    format: 'binary',
    fetchInit: {
      credentials: 'include',
      keepalive: true,
    },
    meta: makeRpcMetadata(),
    interceptors: [authInterceptor(accessTokenProvider), extensionInterceptor],
  });

// Transport for any of the per-RPC authenticated token-based endpoints on readingserver.
const readerAccessTokenTransport = getAccessTokenTransport(apiURL + '/rpcauth');
export const readerReportGenClient = new ReaderReportGenClient(readerAccessTokenTransport);
export const reportingClient = new ReportingClient(readerAccessTokenTransport);

const options: GrpcWebOptions = {
  baseUrl: apiURL,
  format: 'binary',
  fetchInit: {
    credentials: 'include',
    keepalive: false,
  },
  meta: makeRpcMetadata(),
  interceptors: [
    {
      interceptUnary(next, method, input, options) {
        const call = next(method, input, options);
        const methodFullPath = `${options.baseUrl}/${method.service.typeName}/${method.name}`;
        const methodType = 'unary';

        call.then(
          finishedUnaryCall => {
            window.postMessage(
              {
                type: '__GRPCWEB_DEVTOOLS__',
                method: methodFullPath,
                methodType,
                request: finishedUnaryCall.request,
                response: finishedUnaryCall.response,
              },
              '*',
            );

            return finishedUnaryCall;
          },
          error => {
            window.postMessage(
              {
                type: '__GRPCWEB_DEVTOOLS__',
                method: methodFullPath,
                methodType,
                request: call.request,
                error: {
                  ...error,
                  message: error.message,
                },
              },
              '*',
            );
          },
        );

        return call;
      },
      interceptServerStreaming(next, method, input, options) {
        const call = next(method, input, options);
        const methodFullPath = `${options.baseUrl}/${method.service.typeName}/${method.name}`;
        const methodType = 'server_streaming';

        window.postMessage({
          type: '__GRPCWEB_DEVTOOLS__',
          method: methodFullPath,
          methodType,
          request: call.request,
        });

        call.responses.onMessage(message => {
          window.postMessage(
            {
              type: '__GRPCWEB_DEVTOOLS__',
              method: methodFullPath,
              methodType,
              response: message,
            },
            '*',
          );
        });

        call.responses.onError(error => {
          window.postMessage(
            {
              type: '__GRPCWEB_DEVTOOLS__',
              method: methodFullPath,
              methodType,
              error: {
                ...error,
                message: error.message,
              },
            },
            '*',
          );
        });

        call.responses.onComplete(() => {
          window.postMessage(
            {
              type: '__GRPCWEB_DEVTOOLS__',
              method: methodFullPath,
              methodType,
              response: 'EOF',
            },
            '*',
          );
        });

        return call;
      },
    },
  ],
};

const transport = new GrpcWebFetchTransport(options);

// We use KeepAlive to ensure requests added to logout clean-up aren't
// cancelled but it causes issues in Chrome if request sizes are greater than
// ~40kb so shouldn't be used everywhere.
const keepaliveTransport = new GrpcWebFetchTransport({
  ...options,
  fetchInit: { ...options.fetchInit, keepalive: true },
});

export const booksClient = new BooksClient(transport);
export const managementClient = new ManagementClient(transport);
// We send a task action to pause GR timer on logout which needs keep-alive so
// it's not cancelled when the page changes to the login page.
export const tasksClient = new TasksClient(keepaliveTransport);
export const sessionsClient = new SessionsClient(transport);
export const leagueClient = new LeagueClient(transport);
export const monitoringClient = new MonitoringClient(transport);
export const vocabClient = new VocabClient(transport);
export const notificationClient = new NotificationsClient(transport);
export const contentClient = new ContentClient(transport);
export const definitionsClient = new DefinitionsClient(transport);
export const feedbackClient = new FeedbackClient(transport);
export const libraryBooksClient = new LibraryBooksClient(transport);
export const assignmentsClient = new AssignmentServiceClient(transport);
export const bonusQuestionsClient = new BonusQuestionsClient(transport);

export const schoolStaffClient = new SchoolStaffServiceClient(
  getAccessTokenTransport(window.settings?.teacherApiURL || '/schoolstaff'),
);

export const trainingProgressClient = new TrainingProgressServiceClient(
  getAccessTokenTransport(window.settings?.trainingProgressApiURL ?? '/trainingprogress'),
);

export const schoolStatusClient = new SchoolStatusServiceClient(
  getAccessTokenTransport(window.settings?.schoolStatusApiURL ?? '/schoolstatus'),
);

export const groupsClient = new GroupsAPIClient(
  getAccessTokenTransport(window.settings?.teacherApiURL ?? '/groupsapi'),
);

export const wondeSyncClient = new WondeSyncClient(
  getAccessTokenTransport(window.settings?.teacherApiURL ?? '/wondesyncapi'),
);

export const studentsClient = new StudentAPIClient(
  getAccessTokenTransport(window.settings?.tpStudentApiURL ?? '/tpstudentapi'),
);

const schoolsClient = new SchoolsServiceClient(
  getAccessTokenTransport(window.settings?.schoolsApiURL ?? '/schools'),
);
setSchoolsClient(schoolsClient);

export const schoolActionsClient = new SchoolActionsServiceClient(
  getAccessTokenTransport(window.settings?.schoolsApiURL ?? '/schools'),
);

export const silverBookmarkClient = new BookmarksClient(
  getAccessTokenTransport(apiURL + '/rpcauth'),
);

export const subscriptionActionsClient = new SchoolSubscriptionDataActionsServiceClient(
  getAccessTokenTransport(window.settings?.schoolsApiURL ?? '/schools'),
);

export const schoolCalendarClient = new SchoolCalendarServiceClient(
  getAccessTokenTransport(window.settings?.teacherApiURL || '/schoolcalendar'),
);

export const userDisplayClient = new UserDisplayClient(
  getAccessTokenTransport(window.settings?.studentApiURL || '/userdisplay'),
);

export const leaderboardsClient = new LeaderboardsClient(
  getAccessTokenTransport(window.settings?.studentApiURL || '/leaderboards'),
);

export const cannySSOClient = new CannySingleSignOnClient(
  getAccessTokenTransport(window.settings?.teacherApiURL || '/cannyserver'),
);

export const assessmentsSittingClient = new SittingsClient(
  getAccessTokenTransport(window.settings?.assessmentsApiURL || '/assessments'),
);

export const getImageURL = (bookName: string, image: string): string =>
  `${apiURL}/images/v2/${tidyBookName(bookName)}/${tidyAssetName(image)}`;

export const getAudioURL = (bookID: string, audio: string): string =>
  `${apiURL}/audio/v2/${tidyBookName(bookID)}/${tidyAssetName(audio)}`;

const tidyBookName = (bookName: string): string => bookName.replace('books/', '');

const tidyAssetName = (assetName: string): string => {
  const split = assetName.split('/');
  // Expect "books/{book_id}/asset/{asset_id}"
  if (split.length !== 4) {
    console.warn('assetName incorrectly formatted', assetName);
    return assetName;
  }
  return split[split.length - 1]; // return final component (asset id)
};
