import * as Sentry from '@sentry/react';
import { getSchool } from '@sparx/query/schools-service';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { handleErr } from 'app/handle-err';

// Replace UUIDs and numbers in a fingerprint with placeholders so that we group similar errors
const normaliseFingerprintString = (fingerprint: string) =>
  fingerprint
    .replace(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi, '<UUID>')
    .replace(/[0-9]+/g, '<NUM>');
const normaliseFingerprint = (fingerprint: string[]) => fingerprint.map(normaliseFingerprintString);

// React Query client with Sentry error capture
// https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      Sentry.withScope(scope => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint(normaliseFingerprint([query.queryHash]));
        Sentry.captureException(error);
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (err, _vars, _ctx, mutation) => {
      Sentry.withScope(scope => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            normaliseFingerprint(
              mutation.options.mutationKey.filter(segment => typeof segment === 'string'),
            ),
          );
        }
        Sentry.captureException(err);
      });
    },
  }),
  defaultOptions: {
    queries: {
      onError: err => handleErr(err),
    },
    mutations: {
      onError: err => handleErr(err),
    },
  },
});

queryClient.setQueryDefaults(getSchool.keyPrefix, {
  cacheTime: 900000, // 15 minutes
  staleTime: 300000, // 5 minutes
});

export const combineQueryStates = (...queryStates: { isLoading: boolean; isError: boolean }[]) => ({
  isLoading: queryStates.some(s => s.isLoading),
  isError: queryStates.some(s => s.isError),
});
