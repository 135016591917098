import { StaffRole } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import classnames from 'classnames';
import { ComponentProps } from 'react';

import { useStaffContext } from '../Context';
import { getProductName } from '../utils';
import styles from './RoleSelector.module.css';
import { UserRoleRow } from './UserRoleRow';

/**
 * Exposes callbacks for determining if a row representing a role should be checked and for
 * setting its checked state (with a product or without - in which case, the 'default' subject is used)
 */
export interface RoleToggleProps {
  hasRole: (r: StaffRole, p: Product) => boolean;
  toggle: (r: StaffRole, p: Product) => void;
}

interface RoleSelectorProps extends RoleToggleProps, ComponentProps<'div'> {
  /** Reduces padding on the individual rows, so that the component can be contained in a single modal */
  compact?: boolean;
}

export const RoleSelector = (props: RoleSelectorProps) => {
  const { defaultProduct } = useStaffContext();
  const { hasRole, toggle, compact, className, ...divProps } = props;

  const productName = getProductName(defaultProduct);
  const rowProps = {
    hasRole,
    toggle,
    // Using a style tag trumps the className (I don't like this, revisit)
    ...(compact && { style: { padding: 'var(--spx-unit-2)' } }),
  };

  // Overrides for the new assessments app. This can and should be improved
  const isAssessments = defaultProduct === Product.SPARX_ASSESSMENTS;

  return (
    <div
      className={classnames(
        'SpxRoleSelector-root',
        styles.RoleSelector,
        isAssessments && styles.RoleSelectorAssessments,
        className,
      )}
      {...divProps}
    >
      <UserRoleRow
        staffRole={StaffRole.SPARX_LEADER}
        {...rowProps}
        tooltip={
          <>
            {productName} leaders are crucial to the success of {productName} at your school -
            acting as a key contact with Sparx Learning, ensuring that your department and students
            know how to use {productName}, and promoting best practice within your school.
            You&rsquo;ll need to have <strong>one leader</strong> for each Sparx Learning system in
            use at your school.
          </>
        }
      />
      <UserRoleRow staffRole={StaffRole.TEACHER} {...rowProps} />
      <UserRoleRow staffRole={StaffRole.SENIOR_LEADER} {...rowProps} />
      <UserRoleRow staffRole={StaffRole.SENIOR_LEADER_OTHER} {...rowProps} />
      <UserRoleRow staffRole={StaffRole.HEAD_OF_DEPARTMENT} {...rowProps} />
      <UserRoleRow staffRole={StaffRole.DEPUTY_HEAD_OF_DEPARTMENT} {...rowProps} />
      <UserRoleRow staffRole={StaffRole.TRUST_DIRECTOR} {...rowProps} />
      <UserRoleRow staffRole={StaffRole.KEY_STAGE_LEAD} {...rowProps} />
      <UserRoleRow staffRole={StaffRole.LEAD_PRACTITIONER} {...rowProps} />
      <UserRoleRow staffRole={StaffRole.DEPARTMENT_COORDINATOR} {...rowProps} />
      {defaultProduct === Product.SPARX_READER && (
        <UserRoleRow staffRole={StaffRole.LIBRARIAN} {...rowProps} />
      )}
      <UserRoleRow staffRole={StaffRole.OTHER} {...rowProps} />
    </div>
  );
};
