import { getAPIURL } from 'api';
import queryString from 'query-string';

export const selectSchoolURL =
  window?.settings?.selectSchoolURL || 'https://selectschool.test2.test.sparxmaths.uk';

const getSchoolId = (school: string | (string | null)[] | null | undefined) => {
  return (school ? school.toString() : '').replace('schools/', '');
};

export const getSchoolIDFromUrl = () => {
  const { school } = queryString.parse(window.location.search);
  return getSchoolId(school);
};
export const handleErr = (err: unknown) => {
  if (err && typeof err === 'object' && 'message' in err && err.message === 'not authenticated') {
    redirectToLogin();
  }
};

export const redirectToLogin = (schoolID?: string) => {
  const apiURL = getAPIURL();
  const { school, selectschool, is, fg } = queryString.parse(window.location.search);
  schoolID = schoolID || getSchoolId(school);
  const shouldSelectSchool = Boolean(selectschool?.toString());
  const route = window.location.toString();
  const isQuery = is?.toString() ? { is: 'true' } : {};
  const fgQuery = fg?.toString() ? { fg: '1' } : {};
  if (!schoolID || shouldSelectSchool) {
    const query = queryString.stringify({
      app: 'sparx_reader',
      route,
      ...(shouldSelectSchool ? { forget: 1 } : {}),
    });
    window.location.replace(selectSchoolURL + '?' + query);
  } else {
    const query = queryString.stringify({ school: schoolID, route, ...isQuery, ...fgQuery });
    window.location.replace(apiURL + '/oauth2/login?' + query);
  }

  // Intentionally leave promise body empty to prevent returning: we want to
  // wait for the redirection to happen.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return new Promise(() => {});
};
