import { ErrorMessage } from 'components/errors/error-message';
import { Loading } from 'components/loading/loading';
import { useListOnboardingLibraryBooks } from 'queries/library-books';

import { Book } from './book';
import styles from './training-book.module.css';

export const TrainingBook = () => {
  const { data, isLoading, isError } = useListOnboardingLibraryBooks();

  if (isError) {
    return <ErrorMessage message="oops" />;
  }
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.BooksContainer}>
      {data.onboardingLibraryBooks.map(book => (
        <Book key={book.metadataAbridged?.name} book={book} />
      ))}
    </div>
  );
};
