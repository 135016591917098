import { faQuestionCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoldReaderState } from '@sparx/api/apis/sparx/reading/users/v1/statistics';
import classNames from 'classnames';
import { ProgressBar } from 'components/progress/progress-bar';
import { useGoldReaderState, useIsGoldReader, useLastGoldReaderState } from 'queries/gold';
import { useUser } from 'queries/session';
import { useMyHomeworks } from 'queries/tasks';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { PieChart } from 'react-minimal-pie-chart';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import styles from 'views/student/library-view/components/homework-components.module.css';
import { pathForView } from 'views/views';

interface IGoldReaderProgressProps {
  title?: string;
  titleClassName?: string;
  inactive?: boolean;
  animateFromPrevious?: boolean;
  wrapper?: (child: React.ReactElement) => React.ReactElement;
  hideDisabledMessage?: boolean;
}

// If the standard for earning a Gold Reader star increases, the user's `progress` may
// decrease, which would cause the bar to animate backwards.
// To avoid this, if the lastState has a higher progress than the currentState,
// we adjust the previous progress to be some fixed amount less than the current,
// so that the bar will animate forwards.
export const adjustPreviousState = (
  currentState?: GoldReaderState,
  lastState?: GoldReaderState,
): GoldReaderState | undefined => {
  if (!lastState || !currentState) {
    return undefined;
  }

  // If the new State has more or equal progress, just return the lastState.
  //  (Equal is important, otherwise when students have made no progress, the bar will still animate)
  // If the new State has less progress, we adjust the lastState to be some fixed amount less than the current.
  return lastState.progress <= currentState.progress
    ? lastState
    : { ...lastState, progress: Math.max(0, currentState.progress - 0.1) };
};

export const GoldReaderProgress = ({
  titleClassName,
  inactive,
  animateFromPrevious,
  wrapper,
}: IGoldReaderProgressProps) => {
  const navigate = useNavigate();
  const { data: state } = useGoldReaderState();
  const { data: lastStateResp } = useLastGoldReaderState();
  const lastState = lastStateResp?.goldReaderState;

  const isGoldReader = useIsGoldReader();
  const nextPassEarnt = isGoldReader && (state?.stars || 0) >= 5;
  const previousState: GoldReaderState | undefined = animateFromPrevious
    ? adjustPreviousState(state, lastState)
    : undefined;
  const user = useUser();

  const startStar = previousState ? previousState.stars : state?.stars || 0;
  const startProg = previousState ? previousState.progress : state?.progress || 0;

  const [vStar, setVStar] = useState(startStar);
  const [vProg, setVProg] = useState(startStar < (state?.stars || 0) ? 0 : startProg);

  useEffect(() => {
    const func = () => {
      setVProg(state?.progress || 0);
      setVStar(state?.stars || 0);
    };
    const t = window.setTimeout(func, 1000);
    return () => clearTimeout(t);
  }, [setVProg, setVStar, state]);

  // Don't show the gold reader until they have passed at least one homework
  const { data: allHomeworks } = useMyHomeworks();
  const hasFinishedHwk = allHomeworks?.find(h => h.experience >= h.targetExperience);
  if (!hasFinishedHwk) {
    return null;
  }

  if (nextPassEarnt) {
    return null;
  }

  if (user?.statistics?.goldReaderDisabled) {
    return null;
  }

  const goldReaderText = (
    <span className={styles.AlertLink} onClick={() => navigate(pathForView(View.GoldReader))}>
      Gold Reader pass.
      <FontAwesomeIcon icon={faQuestionCircle} />
    </span>
  );

  const output = (
    <>
      <div className={classNames(styles.ProgressTitle, titleClassName)}>
        Continue reading to earn your{isGoldReader && ' next'} {goldReaderText}
      </div>
      <div
        className={classNames(
          styles.ProgressContainer,
          styles.ProgressContainerStars,
          inactive && styles.ProgressContainerStarsInactive,
        )}
      >
        <div className={styles.ProgressContainerBar}>
          <GoldReaderProgressBar progress={vProg} inactive={inactive} width="100%" />
        </div>
        <GoldReaderStars count={vStar} startCount={startStar} />
      </div>
    </>
  );
  return wrapper ? wrapper(output) : output;
};

interface IGoldReaderProgressBarProps {
  progress: number; //[0,1]
  inactive?: boolean;
  className?: string;
  width?: string;
}

export const GoldReaderProgressBar = ({
  progress,
  inactive,
  className,
  width,
}: IGoldReaderProgressBarProps) => (
  <ProgressBar
    percent={progress * 100}
    className={classNames(styles.ProgressBar, className)}
    barClassName={classNames(styles.ProgressStarBar, inactive && styles.ProgressStarBarInactive)}
    width={width}
    hideTick={true}
  />
);

interface IGoldReaderStarsProps {
  count: number;
  startCount: number;
  size?: 'small';
}

export const GoldReaderStars = ({ count, startCount, size }: IGoldReaderStarsProps) => (
  <>
    {[1, 2, 3, 4, 5].map(star => (
      <FontAwesomeIcon
        key={star}
        icon={faStar}
        className={classNames(
          styles.ProgressStar,
          star <= count && styles.ProgressStarActive,
          star <= startCount && styles.ProgressStarAlreadyActive,
          { [styles.ProgressStarSmall]: size === 'small' },
        )}
      />
    ))}
  </>
);

interface IHomeworkPieProps {
  progress: number;
  start?: number;
  countUp?: boolean;
  className?: string;
  background?: string;
  gradientVariant?: string;
  small?: boolean;
  labelText?: string | React.ReactElement;
  rounded?: boolean;
  lineWidth?: number;
}

export const HomeworkPie: React.FC<IHomeworkPieProps> = ({
  progress,
  countUp,
  start,
  className,
  background,
  gradientVariant,
  small,
  labelText,
  rounded = true,
  lineWidth = 20,
}) => {
  const [animateState, setAnimateState] = useState(false);
  const cappedProgress = Math.min(progress * 100, 100);
  const [reveal, setReveal] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setAnimateState(true);
      setReveal(cappedProgress);
    }, 50);
  }, [cappedProgress]);
  let label = <span className={styles.Label}>{labelText}</span>;
  if (!labelText && countUp) {
    label = (
      <CountUp
        start={Math.min(Math.round((start || 0) * 100), 100)}
        end={Math.min(Math.round(progress * 100), 100)}
        duration={1}
        suffix="%"
      />
    );
  } else if (!labelText) {
    label = <>{Math.round(progress * 100)}%</>;
  }
  return (
    <div className={classNames(styles.PieContainer, small && styles.PieContainerSmall, className)}>
      <div className={styles.PiePercent}>{label}</div>
      <PieChart
        className={styles.PieChart}
        animate={animateState}
        animationDuration={500}
        animationEasing="ease-out"
        center={[50, 50]}
        data={[
          {
            color: `url(#${gradientVariant || 'gradient1'})`,
            key: 1,
            value: 1,
          },
        ]}
        reveal={reveal}
        totalValue={1}
        lineWidth={lineWidth}
        background={background || 'var(--carbon-grey-10)'}
        startAngle={270}
        rounded={rounded}
      >
        <defs>
          <linearGradient id="gradient1">
            <stop offset="0%" stopColor="var(--leaf-green-100)" />
            <stop offset="100%" stopColor="var(--leaf-green-80)" />
          </linearGradient>
          <linearGradient id="orange">
            <stop offset="0%" stopColor="var(--marigold-yellow-100)" />
            <stop offset="100%" stopColor="var(--marigold-yellow-100)" />
          </linearGradient>
          <linearGradient id="green">
            <stop offset="0%" stopColor="var(--leaf-green-100)" />
            <stop offset="100%" stopColor="var(--leaf-green-100)" />
          </linearGradient>
          <linearGradient id="training">
            <stop offset="0%" stopColor="var(--spring-green-80)" />
            <stop offset="100%" stopColor="var(--spring-green-80)" />
          </linearGradient>
        </defs>
      </PieChart>
    </div>
  );
};

interface IHomeworkSRPCount {
  current: number;
  target: number;
  className?: string;
  hideColour?: boolean;
}

export const HomeworkSRPCount: React.FC<IHomeworkSRPCount> = ({
  current,
  target,
  className,
  hideColour,
}) => {
  const currentClass = current < target ? styles.SRPCountCurrentDanger : styles.SRPCountCurrent;

  return (
    <div className={`${styles.SRPCount} ${className}`} data-testid="homework-srp-count">
      <span className={hideColour ? '' : currentClass}>{current.toLocaleString()}</span> /{' '}
      <span className={styles.SRPCountTarget}>{target.toLocaleString()}</span>{' '}
      <span className={styles.SRPLabel} title="Sparx Reader Points">
        SRP
      </span>
    </div>
  );
};
