import { Link } from 'components/link';
import { useSchoolMISStatus } from 'queries/school-status';

import styles from './shared.module.css';

export const ReadyToGetStarted = () => {
  const { data, isLoading } = useSchoolMISStatus();

  if (isLoading || !data) return;

  return (
    <>
      <div className={styles.Box}>
        <p>
          We&apos;ve done some checking and it looks like the data in your MIS{' '}
          <strong>is ready</strong> to get started.
        </p>

        <p>
          We recommend that you proceed to{' '}
          <Link
            to="/teacher/getting-started/import-classes-and-students"
            analyticsEvent={undefined}
            className={styles.Link}
          >
            import your class and students
          </Link>{' '}
          if you haven&apos;t done so already.
        </p>
      </div>
    </>
  );
};
