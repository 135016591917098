// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/notifications/v1/notifications.proto" (package "sparx.reading.users.notifications.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { ExperienceUpdate } from '../../v1/experience';
import { Timestamp } from '../../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationWithContext
 */
export interface NotificationWithContext {
  /**
   * @generated from protobuf field: string notification_id = 1;
   */
  notificationId: string;
  /**
   * @generated from protobuf field: sparx.reading.users.notifications.v1.Notification notification = 2;
   */
  notification?: Notification;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp timestamp = 3;
   */
  timestamp?: Timestamp;
  /**
   * @generated from protobuf field: bool dismissed = 4;
   */
  dismissed: boolean;
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.Notification
 */
export interface Notification {
  /**
   * @generated from protobuf oneof: notification
   */
  notification:
    | {
        oneofKind: 'book';
        /**
         * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationBook book = 1;
         */
        book: NotificationBook;
      }
    | {
        oneofKind: 'custom';
        /**
         * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationCustom custom = 2;
         */
        custom: NotificationCustom;
      }
    | {
        oneofKind: 'star';
        /**
         * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationStar star = 3;
         */
        star: NotificationStar;
      }
    | {
        oneofKind: 'bankedReward';
        /**
         * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationBankedReward banked_reward = 4;
         */
        bankedReward: NotificationBankedReward;
      }
    | {
        oneofKind: 'warning';
        /**
         * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationWarning warning = 5;
         */
        warning: NotificationWarning;
      }
    | {
        oneofKind: 'disabled';
        /**
         * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationGoldReaderDisabled disabled = 6;
         */
        disabled: NotificationGoldReaderDisabled;
      }
    | {
        oneofKind: 'whatsNew';
        /**
         * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationWhatsNew whats_new = 7;
         */
        whatsNew: NotificationWhatsNew;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationBook
 */
export interface NotificationBook {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationBook.Type type = 2;
   */
  type: NotificationBook_Type;
}
/**
 * @generated from protobuf enum sparx.reading.users.notifications.v1.NotificationBook.Type
 */
export enum NotificationBook_Type {
  /**
   * @generated from protobuf enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: TYPE_NEW_BOOK = 1;
   */
  NEW_BOOK = 1,
  /**
   * @generated from protobuf enum value: TYPE_NEW_CHAPTERS = 2;
   */
  NEW_CHAPTERS = 2,
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationCustom
 */
export interface NotificationCustom {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * @generated from protobuf field: string text = 2;
   */
  text: string;
  /**
   * @generated from protobuf field: string cta = 3;
   */
  cta: string;
  /**
   * Optional custom modal
   *
   * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationCustom.CustomModal modal = 4;
   */
  modal?: NotificationCustom_CustomModal;
  /**
   * @generated from protobuf oneof: navigate
   */
  navigate:
    | {
        oneofKind: 'view';
        /**
         * @generated from protobuf field: string view = 5;
         */
        view: string;
      }
    | {
        oneofKind: 'viewParams';
        /**
         * @generated from protobuf field: sparx.reading.users.notifications.v1.ViewWithParams view_params = 6;
         */
        viewParams: ViewWithParams;
      }
    | {
        oneofKind: 'externalUrl';
        /**
         * @generated from protobuf field: string external_url = 7;
         */
        externalUrl: string;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationCustom.CustomModal
 */
export interface NotificationCustom_CustomModal {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * Supports html
   *
   * @generated from protobuf field: string content = 2;
   */
  content: string;
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationStar
 */
export interface NotificationStar {
  /**
   * @generated from protobuf field: int32 star_index = 1;
   */
  starIndex: number;
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationBankedReward
 */
export interface NotificationBankedReward {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * The amount of SRP actually awarded to the user. This could be lower than the banked SRP if we deem
   * the user has not earned the full amount.
   *
   * @generated from protobuf field: int32 rewarded_srp = 2;
   */
  rewardedSrp: number;
  /**
   * The amount of SRP the user has banked.
   *
   * @generated from protobuf field: int32 banked_srp = 3;
   */
  bankedSrp: number;
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationGoldReaderDisabled
 */
export interface NotificationGoldReaderDisabled {}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationWarning
 */
export interface NotificationWarning {
  /**
   * The title of the notification
   *
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * The text of the notification
   *
   * @generated from protobuf field: string text = 2;
   */
  text: string;
  /**
   * The cta for the notification
   *
   * @generated from protobuf field: string cta = 3;
   */
  cta: string;
  /**
   * The type of warning notification
   *
   * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationWarning.Type type = 5;
   */
  type: NotificationWarning_Type;
  /**
   * The modal
   *
   * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationWarning.WarningModal modal = 4;
   */
  modal?: NotificationWarning_WarningModal;
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationWarning.WarningModal
 */
export interface NotificationWarning_WarningModal {
  /**
   * The title of the modal. Required
   *
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * The subtitle of the modal. Optional.
   *
   * @generated from protobuf field: string subtitle = 2;
   */
  subtitle: string;
  /**
   * The content of the modal. Supports html.
   *
   * @generated from protobuf field: string content = 3;
   */
  content: string;
  /**
   * The text for the modal button.
   *
   * @generated from protobuf field: string cta = 4;
   */
  cta: string;
}
/**
 * @generated from protobuf enum sparx.reading.users.notifications.v1.NotificationWarning.Type
 */
export enum NotificationWarning_Type {
  /**
   * @generated from protobuf enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: TYPE_GOLD_READER = 1;
   */
  GOLD_READER = 1,
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.ViewWithParams
 */
export interface ViewWithParams {
  /**
   * @generated from protobuf field: string view = 1;
   */
  view: string;
  /**
   * @generated from protobuf field: map<string, string> params = 2;
   */
  params: {
    [key: string]: string;
  };
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationTrigger
 */
export interface NotificationTrigger {
  /**
   * @generated from protobuf oneof: trigger
   */
  trigger:
    | {
        oneofKind: 'bookWait';
        /**
         * @generated from protobuf field: sparx.reading.users.notifications.v1.NotificationTriggerBookWait book_wait = 1;
         */
        bookWait: NotificationTriggerBookWait;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationWhatsNew
 */
export interface NotificationWhatsNew {
  /**
   * @generated from protobuf field: string title = 1;
   */
  title: string;
  /**
   * @generated from protobuf field: string text = 2;
   */
  text: string;
  /**
   * @generated from protobuf field: string post_id = 3;
   */
  postId: string;
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.NotificationTriggerBookWait
 */
export interface NotificationTriggerBookWait {
  /**
   * @generated from protobuf field: string book_id = 1;
   */
  bookId: string;
  /**
   * @deprecated
   * @generated from protobuf field: int32 end_page = 2 [deprecated = true];
   */
  endPage: number;
  /**
   * @generated from protobuf field: string end_part_id = 3;
   */
  endPartId: string;
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.GetNotificationsRequest
 */
export interface GetNotificationsRequest {}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.GetNotificationsResponse
 */
export interface GetNotificationsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.users.notifications.v1.NotificationWithContext notifications = 1;
   */
  notifications: NotificationWithContext[];
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.SetNotificationStatusRequest
 */
export interface SetNotificationStatusRequest {
  /**
   * @generated from protobuf field: string notification_id = 1;
   */
  notificationId: string;
  /**
   * No longer used: this request only marks notifications as read.
   *
   * @deprecated
   * @generated from protobuf field: bool read = 2 [deprecated = true];
   */
  read: boolean;
}
/**
 * @generated from protobuf message sparx.reading.users.notifications.v1.SetNotificationStatusResponse
 */
export interface SetNotificationStatusResponse {
  /**
   * @generated from protobuf field: sparx.reading.users.v1.ExperienceUpdate experience_update = 1;
   */
  experienceUpdate?: ExperienceUpdate;
}
// @generated message type with reflection information, may provide speed optimized methods
class NotificationWithContext$Type extends MessageType<NotificationWithContext> {
  constructor() {
    super('sparx.reading.users.notifications.v1.NotificationWithContext', [
      {
        no: 1,
        name: 'notification_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'notification', kind: 'message', T: () => Notification },
      { no: 3, name: 'timestamp', kind: 'message', T: () => Timestamp },
      { no: 4, name: 'dismissed', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationWithContext
 */
export const NotificationWithContext = new NotificationWithContext$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Notification$Type extends MessageType<Notification> {
  constructor() {
    super('sparx.reading.users.notifications.v1.Notification', [
      {
        no: 1,
        name: 'book',
        kind: 'message',
        oneof: 'notification',
        T: () => NotificationBook,
      },
      {
        no: 2,
        name: 'custom',
        kind: 'message',
        oneof: 'notification',
        T: () => NotificationCustom,
      },
      {
        no: 3,
        name: 'star',
        kind: 'message',
        oneof: 'notification',
        T: () => NotificationStar,
      },
      {
        no: 4,
        name: 'banked_reward',
        kind: 'message',
        oneof: 'notification',
        T: () => NotificationBankedReward,
      },
      {
        no: 5,
        name: 'warning',
        kind: 'message',
        oneof: 'notification',
        T: () => NotificationWarning,
      },
      {
        no: 6,
        name: 'disabled',
        kind: 'message',
        oneof: 'notification',
        T: () => NotificationGoldReaderDisabled,
      },
      {
        no: 7,
        name: 'whats_new',
        kind: 'message',
        oneof: 'notification',
        T: () => NotificationWhatsNew,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.Notification
 */
export const Notification = new Notification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationBook$Type extends MessageType<NotificationBook> {
  constructor() {
    super('sparx.reading.users.notifications.v1.NotificationBook', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.notifications.v1.NotificationBook.Type',
          NotificationBook_Type,
          'TYPE_',
        ],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationBook
 */
export const NotificationBook = new NotificationBook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationCustom$Type extends MessageType<NotificationCustom> {
  constructor() {
    super('sparx.reading.users.notifications.v1.NotificationCustom', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'cta', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'modal',
        kind: 'message',
        T: () => NotificationCustom_CustomModal,
      },
      {
        no: 5,
        name: 'view',
        kind: 'scalar',
        oneof: 'navigate',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'view_params',
        kind: 'message',
        oneof: 'navigate',
        T: () => ViewWithParams,
      },
      {
        no: 7,
        name: 'external_url',
        kind: 'scalar',
        oneof: 'navigate',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationCustom
 */
export const NotificationCustom = new NotificationCustom$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationCustom_CustomModal$Type extends MessageType<NotificationCustom_CustomModal> {
  constructor() {
    super(
      'sparx.reading.users.notifications.v1.NotificationCustom.CustomModal',
      [
        { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: 'content', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationCustom.CustomModal
 */
export const NotificationCustom_CustomModal =
  new NotificationCustom_CustomModal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationStar$Type extends MessageType<NotificationStar> {
  constructor() {
    super('sparx.reading.users.notifications.v1.NotificationStar', [
      { no: 1, name: 'star_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationStar
 */
export const NotificationStar = new NotificationStar$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationBankedReward$Type extends MessageType<NotificationBankedReward> {
  constructor() {
    super('sparx.reading.users.notifications.v1.NotificationBankedReward', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'rewarded_srp',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 3, name: 'banked_srp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationBankedReward
 */
export const NotificationBankedReward = new NotificationBankedReward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationGoldReaderDisabled$Type extends MessageType<NotificationGoldReaderDisabled> {
  constructor() {
    super(
      'sparx.reading.users.notifications.v1.NotificationGoldReaderDisabled',
      [],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationGoldReaderDisabled
 */
export const NotificationGoldReaderDisabled =
  new NotificationGoldReaderDisabled$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationWarning$Type extends MessageType<NotificationWarning> {
  constructor() {
    super('sparx.reading.users.notifications.v1.NotificationWarning', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'cta', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 5,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.notifications.v1.NotificationWarning.Type',
          NotificationWarning_Type,
          'TYPE_',
        ],
      },
      {
        no: 4,
        name: 'modal',
        kind: 'message',
        T: () => NotificationWarning_WarningModal,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationWarning
 */
export const NotificationWarning = new NotificationWarning$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationWarning_WarningModal$Type extends MessageType<NotificationWarning_WarningModal> {
  constructor() {
    super(
      'sparx.reading.users.notifications.v1.NotificationWarning.WarningModal',
      [
        { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 2, name: 'subtitle', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 3, name: 'content', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
        { no: 4, name: 'cta', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationWarning.WarningModal
 */
export const NotificationWarning_WarningModal =
  new NotificationWarning_WarningModal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ViewWithParams$Type extends MessageType<ViewWithParams> {
  constructor() {
    super('sparx.reading.users.notifications.v1.ViewWithParams', [
      { no: 1, name: 'view', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'params',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.ViewWithParams
 */
export const ViewWithParams = new ViewWithParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationTrigger$Type extends MessageType<NotificationTrigger> {
  constructor() {
    super('sparx.reading.users.notifications.v1.NotificationTrigger', [
      {
        no: 1,
        name: 'book_wait',
        kind: 'message',
        oneof: 'trigger',
        T: () => NotificationTriggerBookWait,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationTrigger
 */
export const NotificationTrigger = new NotificationTrigger$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationWhatsNew$Type extends MessageType<NotificationWhatsNew> {
  constructor() {
    super('sparx.reading.users.notifications.v1.NotificationWhatsNew', [
      { no: 1, name: 'title', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'text', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'post_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationWhatsNew
 */
export const NotificationWhatsNew = new NotificationWhatsNew$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationTriggerBookWait$Type extends MessageType<NotificationTriggerBookWait> {
  constructor() {
    super('sparx.reading.users.notifications.v1.NotificationTriggerBookWait', [
      { no: 1, name: 'book_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'end_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'end_part_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.NotificationTriggerBookWait
 */
export const NotificationTriggerBookWait =
  new NotificationTriggerBookWait$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationsRequest$Type extends MessageType<GetNotificationsRequest> {
  constructor() {
    super('sparx.reading.users.notifications.v1.GetNotificationsRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.GetNotificationsRequest
 */
export const GetNotificationsRequest = new GetNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationsResponse$Type extends MessageType<GetNotificationsResponse> {
  constructor() {
    super('sparx.reading.users.notifications.v1.GetNotificationsResponse', [
      {
        no: 1,
        name: 'notifications',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => NotificationWithContext,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.GetNotificationsResponse
 */
export const GetNotificationsResponse = new GetNotificationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetNotificationStatusRequest$Type extends MessageType<SetNotificationStatusRequest> {
  constructor() {
    super('sparx.reading.users.notifications.v1.SetNotificationStatusRequest', [
      {
        no: 1,
        name: 'notification_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'read', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.SetNotificationStatusRequest
 */
export const SetNotificationStatusRequest =
  new SetNotificationStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetNotificationStatusResponse$Type extends MessageType<SetNotificationStatusResponse> {
  constructor() {
    super(
      'sparx.reading.users.notifications.v1.SetNotificationStatusResponse',
      [
        {
          no: 1,
          name: 'experience_update',
          kind: 'message',
          T: () => ExperienceUpdate,
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.notifications.v1.SetNotificationStatusResponse
 */
export const SetNotificationStatusResponse =
  new SetNotificationStatusResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.users.notifications.v1.Notifications
 */
export const Notifications = new ServiceType(
  'sparx.reading.users.notifications.v1.Notifications',
  [
    {
      name: 'GetNotifications',
      options: {},
      I: GetNotificationsRequest,
      O: GetNotificationsResponse,
    },
    {
      name: 'SetNotificationStatus',
      options: {},
      I: SetNotificationStatusRequest,
      O: SetNotificationStatusResponse,
    },
  ],
);
