import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import classNames from 'classnames';
import { useClientEvent } from 'components/client-events/client-event-provider';
import React from 'react';

import styles from './department-widgets.module.css';

interface IDepartmentWidgetProps {
  proportion: number;
  thresholds: [number, number];
  icon: IconDefinition;
  label: string | JSX.Element;
  title: string;
  subtitle: string;
  body: React.ReactNode;
  buttonText: string;
  buttonAction: () => void;
  loading?: boolean;
  className?: string;
}

enum WidgetState {
  GREEN = 'green',
  AMBER = 'amber',
  RED = 'red',
}

const getWidgetState = (value: number, thresholds: [number, number]): WidgetState => {
  if (value < thresholds[0]) {
    return WidgetState.RED;
  } else if (value < thresholds[1]) {
    return WidgetState.AMBER;
  } else {
    return WidgetState.GREEN;
  }
};

// A widget which shows on the department dashboard. Each one can be in one of three states: green, amber, or red.
export const DepartmentWidget = ({
  icon,
  proportion,
  thresholds,
  label,
  title,
  subtitle,
  body,
  buttonText,
  buttonAction,
  loading,
  className,
}: IDepartmentWidgetProps) => {
  const { sendEvent } = useClientEvent();
  const clickHandler = () => {
    sendEvent({
      category: 'dept dash widget',
      action: `clicked link for ${label}`,
    });
    buttonAction();
  };
  const widgetState = getWidgetState(proportion, thresholds);

  return (
    <div className={classNames(styles.Widget, className)}>
      <h3 className={styles.Label}>{label}</h3>
      <div className={styles.Box} data-widget-state={widgetState}>
        {loading ? (
          <div className={styles.Loading}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className={styles.TopContent}>
              <div className={styles.Icon}>
                <FontAwesomeIcon icon={icon} />
              </div>
              <h2 className={styles.Title}>{title}</h2>
              <h3 className={styles.Subtitle}>{subtitle}</h3>
              <div className={styles.Body}>{body}</div>
            </div>
            <button className={styles.Button} onClick={clickHandler}>
              <span className={styles.ButtonText}>
                {buttonText}
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};
