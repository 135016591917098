// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/canny/v1/sso.proto" (package "sparx.canny.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { CannySingleSignOn } from './sso';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetSingleSignOnTokenResponse } from './sso';
import type { GetSingleSignOnTokenRequest } from './sso';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.canny.v1.CannySingleSignOn
 */
export interface ICannySingleSignOnClient {
  /**
   * GetSingleSignOnToken returns a token that can be used to authenticate the
   * current user to Canny. The user's ID is taken from the authorization
   *
   * @generated from protobuf rpc: GetSingleSignOnToken(sparx.canny.v1.GetSingleSignOnTokenRequest) returns (sparx.canny.v1.GetSingleSignOnTokenResponse);
   */
  getSingleSignOnToken(
    input: GetSingleSignOnTokenRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSingleSignOnTokenRequest, GetSingleSignOnTokenResponse>;
}
/**
 * @generated from protobuf service sparx.canny.v1.CannySingleSignOn
 */
export class CannySingleSignOnClient
  implements ICannySingleSignOnClient, ServiceInfo
{
  typeName = CannySingleSignOn.typeName;
  methods = CannySingleSignOn.methods;
  options = CannySingleSignOn.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * GetSingleSignOnToken returns a token that can be used to authenticate the
   * current user to Canny. The user's ID is taken from the authorization
   *
   * @generated from protobuf rpc: GetSingleSignOnToken(sparx.canny.v1.GetSingleSignOnTokenRequest) returns (sparx.canny.v1.GetSingleSignOnTokenResponse);
   */
  getSingleSignOnToken(
    input: GetSingleSignOnTokenRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSingleSignOnTokenRequest, GetSingleSignOnTokenResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetSingleSignOnTokenRequest,
      GetSingleSignOnTokenResponse
    >('unary', this._transport, method, opt, input);
  }
}
