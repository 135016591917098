import { userHasRole, UserRole, useUser } from 'queries/session';
import { createContext, useContext } from 'react';

type Context = {
  // Whether Sparx staff features are enabled
  sparxStaffFeaturesEnabled: boolean;
  // Toggle the Sparx staff features on or off (inverts the current state)
  toggleSparxStaffFeaturesEnabled: () => void;
};

export const SparxStaffFeaturesContext = createContext<Context>({
  sparxStaffFeaturesEnabled: false,
  toggleSparxStaffFeaturesEnabled: () => undefined,
});

export const useSparxStaffFeatures = (): Context => {
  const ctx = useContext(SparxStaffFeaturesContext);
  const user = useUser();
  if (!ctx) {
    throw new Error('useSparxStaffFeatures can only be used inside a SparxStaffFeaturesProvider');
  }
  if (!userHasRole(user, UserRole.Admin)) {
    return {
      sparxStaffFeaturesEnabled: false,
      toggleSparxStaffFeaturesEnabled: () => undefined,
    };
  }
  return ctx;
};
