import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';

import { ClientEventsTable } from '../client-events-table/client-events-table';
import { FeatureFlagsTable } from '../feature-flags-table/feature-flags-table';
import styles from './panel.module.css';
import { useResize } from './resize-hook';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const Panel = ({ isOpen, onClose }: Props) => {
  const { height, handleResize } = useResize();

  return (
    <div className={classNames(styles.Panel, { [styles.Hidden]: !isOpen })}>
      <div className={styles.DragHandle} onMouseDown={handleResize} />

      <button onClick={onClose} className={styles.CloseButton}>
        <FontAwesomeIcon icon={faTimes} />
      </button>

      <Tabs.Root defaultValue="events" className={styles.TabsContainer}>
        <Tabs.List className={styles.TabsList}>
          <Tabs.Trigger value="events" className={styles.TabButton}>
            Events
          </Tabs.Trigger>
          <Tabs.Trigger value="feature-flags" className={styles.TabButton}>
            Feature flags
          </Tabs.Trigger>
        </Tabs.List>
        <div style={{ height: height + 'px' }} className={styles.TabContainer}>
          <Tabs.Content value="events">
            <ClientEventsTable />
          </Tabs.Content>
          <Tabs.Content value="feature-flags">
            <FeatureFlagsTable />
          </Tabs.Content>
        </div>
      </Tabs.Root>
    </div>
  );
};
