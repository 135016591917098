import styles from 'views/student/library-view/subviews/home-view.module.css';

export const LeagueNotEnabled = () => (
  <>
    <div className={styles.NotEnabled}>
      The leaderboard is currently unavailable in student demo mode. We&apos;ll be bringing this
      back soon!
    </div>
  </>
);
