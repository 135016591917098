// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/league/v1/league.proto" (package "sparx.reading.league.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.reading.league.v1.LeagueTableResult
 */
export interface LeagueTableResult {
  /**
   * ID of the user in the league table. For students this value
   * will only be set for the authenticated users row.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * Name of the user to display in the league table.
   *
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * Amount of experience this user has gained in the requested period.
   *
   * @generated from protobuf field: int32 experience = 3;
   */
  experience: number;
  /**
   * Additional tags on the user which can be used for filtering or annotations
   * on the league table row.
   *
   * @generated from protobuf field: repeated sparx.reading.league.v1.LeagueTableResultTag tags = 4;
   */
  tags: LeagueTableResultTag[];
  /**
   * The position/rank of the user in the league table.
   *
   * @generated from protobuf field: int32 position = 5;
   */
  position: number;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.league.v1.GetAllLeagueTableRequest
 */
export interface GetAllLeagueTableRequest {
  /**
   * When true will return the data for the current academic year, otherwise
   * the values across all years will be returned.
   *
   * @generated from protobuf field: bool this_year = 1;
   */
  thisYear: boolean;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.league.v1.GetAllLeagueTableResponse
 */
export interface GetAllLeagueTableResponse {
  /**
   * The users own name in the league table.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * List of requested league table results.
   *
   * @generated from protobuf field: repeated sparx.reading.league.v1.LeagueTableResult results = 2;
   */
  results: LeagueTableResult[];
  /**
   * The top users on Reader.
   *
   * @generated from protobuf field: repeated sparx.reading.league.v1.LeagueTableResult top_results = 3;
   */
  topResults: LeagueTableResult[];
  /**
   * The relative results around the authenticated user.
   * Includes users above and below the authenticated user.
   *
   * @generated from protobuf field: repeated sparx.reading.league.v1.LeagueTableResult relative_results = 4;
   */
  relativeResults: LeagueTableResult[];
}
/**
 * @generated from protobuf message sparx.reading.league.v1.GetStudentGroupLeagueTableRequest
 */
export interface GetStudentGroupLeagueTableRequest {
  /**
   * @generated from protobuf field: string student_group_id = 1;
   */
  studentGroupId: string;
  /**
   * When true will return the data for the current academic year, otherwise
   * the values across all years will be returned.
   *
   * @generated from protobuf field: bool this_year = 2;
   */
  thisYear: boolean;
}
/**
 * @generated from protobuf message sparx.reading.league.v1.GetStudentGroupLeagueTableResponse
 */
export interface GetStudentGroupLeagueTableResponse {
  /**
   * List of requested league table results.
   *
   * @generated from protobuf field: repeated sparx.reading.league.v1.LeagueTableResult results = 1;
   */
  results: LeagueTableResult[];
  /**
   * The position of the authenticated user in the league table.
   *
   * @generated from protobuf field: int32 user_position = 2;
   */
  userPosition: number;
}
/**
 * @generated from protobuf message sparx.reading.league.v1.GetSchoolLeagueTableRequest
 */
export interface GetSchoolLeagueTableRequest {
  /**
   * @generated from protobuf field: string school_id = 1;
   */
  schoolId: string;
  /**
   * When true will return the data for the current academic year, otherwise
   * the values across all years will be returned.
   *
   * @generated from protobuf field: bool this_year = 2;
   */
  thisYear: boolean;
}
/**
 * @generated from protobuf message sparx.reading.league.v1.GetSchoolLeagueTableResponse
 */
export interface GetSchoolLeagueTableResponse {
  /**
   * List of requested league table results.
   *
   * @generated from protobuf field: repeated sparx.reading.league.v1.LeagueTableResult results = 1;
   */
  results: LeagueTableResult[];
  /**
   * The position of the authenticated user in the league table.
   *
   * @generated from protobuf field: int32 user_position = 2;
   */
  userPosition: number;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.league.v1.SetLeagueTableNameRequest
 */
export interface SetLeagueTableNameRequest {
  /**
   * The users name to set.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.league.v1.SetLeagueTableNameResponse
 */
export interface SetLeagueTableNameResponse {}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.league.v1.LeaveLeagueTableRequest
 */
export interface LeaveLeagueTableRequest {}
/**
 * @deprecated
 * @generated from protobuf message sparx.reading.league.v1.LeaveLeagueTableResponse
 */
export interface LeaveLeagueTableResponse {}
/**
 * @generated from protobuf enum sparx.reading.league.v1.LeagueTableResultTag
 */
export enum LeagueTableResultTag {
  /**
   * @generated from protobuf enum value: TAG_UNSPECIFIED = 0;
   */
  TAG_UNSPECIFIED = 0,
  /**
   * User is a sparx staff member.
   *
   * @generated from protobuf enum value: TAG_SPARX_STAFF = 1;
   */
  TAG_SPARX_STAFF = 1,
  /**
   * User is in the same class as the authenticated user.
   *
   * @generated from protobuf enum value: TAG_YOUR_CLASS = 2;
   */
  TAG_YOUR_CLASS = 2,
  /**
   * User is in the same school as the authenticated user.
   *
   * @generated from protobuf enum value: TAG_YOUR_SCHOOL = 3;
   */
  TAG_YOUR_SCHOOL = 3,
}
// @generated message type with reflection information, may provide speed optimized methods
class LeagueTableResult$Type extends MessageType<LeagueTableResult> {
  constructor() {
    super('sparx.reading.league.v1.LeagueTableResult', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'experience', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'tags',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'sparx.reading.league.v1.LeagueTableResultTag',
          LeagueTableResultTag,
        ],
      },
      { no: 5, name: 'position', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.league.v1.LeagueTableResult
 */
export const LeagueTableResult = new LeagueTableResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllLeagueTableRequest$Type extends MessageType<GetAllLeagueTableRequest> {
  constructor() {
    super('sparx.reading.league.v1.GetAllLeagueTableRequest', [
      { no: 1, name: 'this_year', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.league.v1.GetAllLeagueTableRequest
 */
export const GetAllLeagueTableRequest = new GetAllLeagueTableRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllLeagueTableResponse$Type extends MessageType<GetAllLeagueTableResponse> {
  constructor() {
    super('sparx.reading.league.v1.GetAllLeagueTableResponse', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LeagueTableResult,
      },
      {
        no: 3,
        name: 'top_results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LeagueTableResult,
      },
      {
        no: 4,
        name: 'relative_results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LeagueTableResult,
      },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.league.v1.GetAllLeagueTableResponse
 */
export const GetAllLeagueTableResponse = new GetAllLeagueTableResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentGroupLeagueTableRequest$Type extends MessageType<GetStudentGroupLeagueTableRequest> {
  constructor() {
    super('sparx.reading.league.v1.GetStudentGroupLeagueTableRequest', [
      {
        no: 1,
        name: 'student_group_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'this_year', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.league.v1.GetStudentGroupLeagueTableRequest
 */
export const GetStudentGroupLeagueTableRequest =
  new GetStudentGroupLeagueTableRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStudentGroupLeagueTableResponse$Type extends MessageType<GetStudentGroupLeagueTableResponse> {
  constructor() {
    super('sparx.reading.league.v1.GetStudentGroupLeagueTableResponse', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LeagueTableResult,
      },
      {
        no: 2,
        name: 'user_position',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.league.v1.GetStudentGroupLeagueTableResponse
 */
export const GetStudentGroupLeagueTableResponse =
  new GetStudentGroupLeagueTableResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolLeagueTableRequest$Type extends MessageType<GetSchoolLeagueTableRequest> {
  constructor() {
    super('sparx.reading.league.v1.GetSchoolLeagueTableRequest', [
      { no: 1, name: 'school_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'this_year', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.league.v1.GetSchoolLeagueTableRequest
 */
export const GetSchoolLeagueTableRequest =
  new GetSchoolLeagueTableRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolLeagueTableResponse$Type extends MessageType<GetSchoolLeagueTableResponse> {
  constructor() {
    super('sparx.reading.league.v1.GetSchoolLeagueTableResponse', [
      {
        no: 1,
        name: 'results',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LeagueTableResult,
      },
      {
        no: 2,
        name: 'user_position',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.league.v1.GetSchoolLeagueTableResponse
 */
export const GetSchoolLeagueTableResponse =
  new GetSchoolLeagueTableResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetLeagueTableNameRequest$Type extends MessageType<SetLeagueTableNameRequest> {
  constructor() {
    super('sparx.reading.league.v1.SetLeagueTableNameRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.league.v1.SetLeagueTableNameRequest
 */
export const SetLeagueTableNameRequest = new SetLeagueTableNameRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetLeagueTableNameResponse$Type extends MessageType<SetLeagueTableNameResponse> {
  constructor() {
    super('sparx.reading.league.v1.SetLeagueTableNameResponse', []);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.league.v1.SetLeagueTableNameResponse
 */
export const SetLeagueTableNameResponse = new SetLeagueTableNameResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveLeagueTableRequest$Type extends MessageType<LeaveLeagueTableRequest> {
  constructor() {
    super('sparx.reading.league.v1.LeaveLeagueTableRequest', []);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.league.v1.LeaveLeagueTableRequest
 */
export const LeaveLeagueTableRequest = new LeaveLeagueTableRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveLeagueTableResponse$Type extends MessageType<LeaveLeagueTableResponse> {
  constructor() {
    super('sparx.reading.league.v1.LeaveLeagueTableResponse', []);
  }
}
/**
 * @deprecated
 * @generated MessageType for protobuf message sparx.reading.league.v1.LeaveLeagueTableResponse
 */
export const LeaveLeagueTableResponse = new LeaveLeagueTableResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.league.v1.League
 */
export const League = new ServiceType('sparx.reading.league.v1.League', [
  {
    name: 'GetStudentGroupLeagueTable',
    options: {},
    I: GetStudentGroupLeagueTableRequest,
    O: GetStudentGroupLeagueTableResponse,
  },
  {
    name: 'GetSchoolLeagueTable',
    options: {},
    I: GetSchoolLeagueTableRequest,
    O: GetSchoolLeagueTableResponse,
  },
  {
    name: 'GetAllLeagueTable',
    options: {},
    I: GetAllLeagueTableRequest,
    O: GetAllLeagueTableResponse,
  },
  {
    name: 'SetLeagueTableName',
    options: {},
    I: SetLeagueTableNameRequest,
    O: SetLeagueTableNameResponse,
  },
  {
    name: 'LeaveLeagueTable',
    options: {},
    I: LeaveLeagueTableRequest,
    O: LeaveLeagueTableResponse,
  },
]);
