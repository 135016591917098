import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'components/buttons';
import { Panel } from 'components/panel/panel';

export const LeagueViewIntro: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Panel
    icon={faCrown}
    title="Welcome to the Leaderboard"
    buttons={
      <Button onClick={onClick} analyticsEvent={undefined}>
        Join the leaderboard
      </Button>
    }
  >
    <p>
      To get started, we need to set up a name for you! Click the button below to choose a name to
      appear on the leaderboard.
    </p>
  </Panel>
);
