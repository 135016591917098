import { Experience, ExperienceReward } from '@sparx/api/apis/sparx/reading/users/v1/experience';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'queries/client';

type TaskRewardData = Record<string, ExperienceReward[]>;
type LatestRewardData = ExperienceReward[];

export const useTaskRewards = () =>
  useQuery<TaskRewardData>(['rewards', 'task'], () => ({}), {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

export const useLatestReward = () =>
  useQuery<LatestRewardData>(['rewards', 'latest'], () => [], {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

export const setRewards = (rewards: ExperienceReward[], taskID?: string) => {
  queryClient.setQueryData(['rewards', 'latest'], rewards);
  queryClient.invalidateQueries(['user', 'notifications']);
  if (taskID) {
    queryClient.setQueryData(['rewards', 'task'], (data: TaskRewardData | undefined) => ({
      ...(data || {}),
      [taskID]: rewards,
    }));
  }
};

export const useExperience = () =>
  useQuery<Experience | undefined>(['rewards', 'experience'], {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

export const setExperience = (experience: Experience) =>
  queryClient.setQueryData(['rewards', 'experience'], experience);
