import { ErrorMessage } from 'components/errors/error-message';
import { Link } from 'components/link';
import { Warning } from 'components/panel/info';
import { combineQueryStates } from 'queries/client';
import {
  useCanStartNewBook,
  useListNewBooks,
  useListSwappedLibraryBooks,
} from 'queries/library-books';
import { useEffect, useState } from 'react';
import { View } from 'views';
import loadingGif from 'views/student/choose-new-book-view/choosing_book.gif';
import {
  LibraryContent,
  LibraryTitle,
} from 'views/student/library-view/components/library-content';
import { pathForView } from 'views/views';

import styles from './choose-new-book-view.module.css';
import { NewBookItem } from './new-book-item';
import { SwappedBooks } from './swapped-books';

export const SpecialSpinner = () => (
  <div className={styles.SpecialSpinner}>
    <img alt="" className={styles.SpecialSpinnerGif} src={loadingGif} />
    <div className={styles.SpecialSpinnerText}>Choosing your books...</div>
  </div>
);

export const ChooseNewBookView = () => {
  const { data: newBooks, ...newBooksQueryState } = useListNewBooks();
  const { data: swappedBooks, ...swappedBooksQueryState } = useListSwappedLibraryBooks();
  const [showSpecialSpinner, setShowSpecialSpinner] = useState(true);
  const { isLoading, isError } = combineQueryStates(newBooksQueryState, swappedBooksQueryState);
  const numNewBooks = newBooks?.metadataAbridged?.length || 0;
  const canStartNewBook = useCanStartNewBook();

  useEffect(() => {
    setTimeout(() => {
      setShowSpecialSpinner(false);
    }, 2000);
  }, []);

  if (isError) {
    return (
      <ErrorMessage message="Sorry, there's been a problem. Please refresh or try again later." />
    );
  }
  if (isLoading || (numNewBooks > 0 && showSpecialSpinner)) {
    return <SpecialSpinner />;
  }

  let content = null;
  if (numNewBooks > 0) {
    content = (
      <>
        {(newBooks?.metadataAbridged?.length || 0) > 0 && (
          <h2>
            We&apos;ve chosen the following {newBooks?.metadataAbridged?.length} books based on your
            level. Which adventure will you choose?
          </h2>
        )}

        <div className={styles.Items}>
          {newBooks?.metadataAbridged?.map(book => (
            <NewBookItem
              key={book.name}
              book={book}
              otherBooks={newBooks.metadataAbridged.filter(newBook => newBook.name !== book.name)}
            />
          ))}
        </div>
        <SwappedBooks swappedBooks={swappedBooks} />
      </>
    );
  } else if (numNewBooks === 0 && canStartNewBook) {
    content = (
      <>
        <Warning className={styles.Warning} showIcon>
          You can&apos;t start a new ebook right now as there are none available. You can choose a
          book you have previously swapped out below.
        </Warning>
        <SwappedBooks swappedBooks={swappedBooks} defaultOpen />
      </>
    );
  } else {
    content = (
      <Warning className={styles.Warning} showIcon>
        You can&apos;t start a new ebook right now. New books will be available when you finish some
        of the books you&apos;ve already started.{' '}
        <Link
          to={pathForView(View.Explore)}
          className={styles.WarningLink}
          analyticsEvent={{ category: 'choose-new-book', action: 'warning library link' }}
        >
          Back to My Library.
        </Link>
      </Warning>
    );
  }

  return (
    <LibraryContent>
      <LibraryTitle
        crumb={{
          title: 'My Library',
          to: pathForView(View.Explore),
          analyticsEvent: {
            category: 'choose-new-book',
            action: 'back',
          },
        }}
      >
        Choose a new book
      </LibraryTitle>
      {content}
    </LibraryContent>
  );
};
