import { SchoolCalendar } from '@sparx/api/apis/sparx/school/calendar/v4/calendar';
import { useQuery } from '@tanstack/react-query';
import { schoolCalendarClient } from 'api';
import { useSchoolID } from 'queries/schools';

export const SchoolCalendarQueryKey = 'GetSchoolCalendar';

export const useSchoolCalendar = <TData = SchoolCalendar>(opts?: {
  suspense?: boolean;
  select?: (data: SchoolCalendar) => TData;
}) => {
  const schoolID = useSchoolID();

  return useQuery(
    [SchoolCalendarQueryKey],
    () => schoolCalendarClient.getSchoolCalendar({ name: `schools/${schoolID}/calendar` }).response,
    {
      enabled: schoolID !== undefined,
      cacheTime: 900000, // 15 minutes
      staleTime: 300000, // 5 minutes
      ...opts,
    },
  );
};
