// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/bookkeeper/v1/bookquestion.proto" (package "sparx.reading.bookkeeper.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.GetBreakpointRequest
 */
export interface GetBreakpointRequest {
  /**
   * The name of the breakpoint to get. Must have the format
   * "books/{book_id}/breakpoints/{breakpoint_id}".
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.GetBreakpointResponse
 */
export interface GetBreakpointResponse {
  /**
   * @generated from protobuf field: sparx.reading.bookkeeper.v1.Breakpoint breakpoint = 1;
   */
  breakpoint?: Breakpoint;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.ListBreakpointsRequest
 */
export interface ListBreakpointsRequest {
  /**
   * The name of the book. Must have the format "books/{book_id}".
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.ListBreakpointsResponse
 */
export interface ListBreakpointsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookkeeper.v1.Breakpoint breakpoints = 1;
   */
  breakpoints: Breakpoint[];
  /**
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.GetQuestionRequest
 */
export interface GetQuestionRequest {
  /**
   * The name of the question. Must have the format
   * "books/{book_id}/breakpoints/{breakpoint_id}/questions/{question_id}".
   * Wildcards are allowed in breakpoint_id.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.GetQuestionResponse
 */
export interface GetQuestionResponse {
  /**
   * @generated from protobuf field: sparx.reading.bookkeeper.v1.Question question = 1;
   */
  question?: Question;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.ListQuestionsRequest
 */
export interface ListQuestionsRequest {
  /**
   * The name of the breakpoint. Must have the format
   * "books/{book_id}/breakpoints/{breakpoint_id}".
   *
   * @generated from protobuf field: string parent = 1;
   */
  parent: string;
  /**
   * @generated from protobuf field: int32 page_size = 2;
   */
  pageSize: number;
  /**
   * @generated from protobuf field: string page_token = 3;
   */
  pageToken: string;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.ListQuestionsResponse
 */
export interface ListQuestionsResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookkeeper.v1.Question questions = 1;
   */
  questions: Question[];
  /**
   * @generated from protobuf field: string next_page_token = 2;
   */
  nextPageToken: string;
}
/**
 * Breakpoint represents a place in a book which forms
 * the start or end of a task.
 *
 * @generated from protobuf message sparx.reading.bookkeeper.v1.Breakpoint
 */
export interface Breakpoint {
  /**
   * The name of the breakpoint. Must have the format
   * "books/{book_id}/breakpoints/{breakpoint_id}". The
   * breakpoint_id corresponds to an id in the content hierarchy
   * e.g. a span_id.
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * The names of the questions that can be issued for this breakpoint
   *
   * @generated from protobuf field: repeated string question_names = 2;
   */
  questionNames: string[];
  /**
   * The (zero-based) index of this breakpoint in the book. This value is not
   * immutable and can change if a new breakpoint is added before it.
   *
   * @generated from protobuf field: int32 position_in_book = 3;
   */
  positionInBook: number;
  /**
   * Percentage position progress in the range [0,1] that this breakpoint
   * is at in the book. This value is not immutable and can change if a new
   * breakpoint is added before it.
   *
   * @generated from protobuf field: double progress_in_book = 4;
   */
  progressInBook: number;
  /**
   * The number of words since the previous breakpoint. This value is not
   * immutable and can charge if a new breakpoint is added before it.
   *
   * @generated from protobuf field: int32 words_since_last_breakpoint = 5;
   */
  wordsSinceLastBreakpoint: number;
  /**
   * Any audio clip that relates to this breakpoint.
   *
   * @generated from protobuf field: string audio_clip_filename = 6;
   */
  audioClipFilename: string;
  /**
   * Whether this breakpoint has been marked as deleted. We keep the breakpoint
   * around so students reading the book at the breakpoint won't have their
   * progress reset if there are any other changes.
   *
   * @generated from protobuf field: bool deleted = 7;
   */
  deleted: boolean;
}
/**
 * Question represents the question
 *
 * @generated from protobuf message sparx.reading.bookkeeper.v1.Question
 */
export interface Question {
  /**
   * The name of the question. Must have the format
   * "books/{book_id}/breakpoints/{breakpoint_id}/questions/{question_id}".
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string question_text = 2;
   */
  questionText: string;
  /**
   * @generated from protobuf field: sparx.reading.bookkeeper.v1.Question.Type type = 3;
   */
  type: Question_Type;
  /**
   * @generated from protobuf field: repeated string answers = 4;
   */
  answers: string[];
  /**
   * Optional list of options to make this multiple choice
   *
   * @generated from protobuf field: repeated string options = 5;
   */
  options: string[];
  /**
   * Order the question should be delivered in a task.
   * If zero, then this will be delivered in a random
   * order along with other zero values - otherwise
   * the order will be based on the index and will
   * be delivered incrementally.
   *
   * @generated from protobuf field: int32 priority = 6;
   */
  priority: number;
}
/**
 * @generated from protobuf enum sparx.reading.bookkeeper.v1.Question.Type
 */
export enum Question_Type {
  /**
   * @generated from protobuf enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: TYPE_LAST_WORD = 1;
   */
  LAST_WORD = 1,
  /**
   * @generated from protobuf enum value: TYPE_QUESTION = 2;
   */
  QUESTION = 2,
  /**
   * @generated from protobuf enum value: TYPE_SELECT_SENTENCE = 3;
   */
  SELECT_SENTENCE = 3,
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.UpdateQuestionsRequest
 */
export interface UpdateQuestionsRequest {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookkeeper.v1.Question questions = 1;
   */
  questions: Question[];
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.UpdateQuestionsResponse
 */
export interface UpdateQuestionsResponse {}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.UpdateBreakpointsRequest
 */
export interface UpdateBreakpointsRequest {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookkeeper.v1.Breakpoint breakpoints = 1;
   */
  breakpoints: Breakpoint[];
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.UpdateBreakpointsResponse
 */
export interface UpdateBreakpointsResponse {}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.DeleteBreakpointRequest
 */
export interface DeleteBreakpointRequest {
  /**
   * The name of the breakpoint. Must have the format
   * "books/{book_id}/breakpoints/{breakpoint_id}".
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * When set any question on the deleted breakpoint will
   * be moved to the next breakpoint in the book. If there
   * is not a breakpoint following this one then an error
   * will be returned.
   *
   * When this parameter is false, the requested breakpoint
   * must not have any questions else this endpoint will
   * return an error.
   *
   * @generated from protobuf field: bool move_questions_to_next_breakpoint = 2;
   */
  moveQuestionsToNextBreakpoint: boolean;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.DeleteBreakpointResponse
 */
export interface DeleteBreakpointResponse {}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.SoftDeleteBreakpointRequest
 */
export interface SoftDeleteBreakpointRequest {
  /**
   * The name of the breakpoint. Must have the format
   * "books/{book_id}/breakpoints/{breakpoint_id}".
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.SoftDeleteBreakpointResponse
 */
export interface SoftDeleteBreakpointResponse {}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.DeleteQuestionRequest
 */
export interface DeleteQuestionRequest {
  /**
   * The name of the question to delete. Must have the format
   * "books/{book_id}/breakpoints/{breakpoint_id}/questions/{question_id}".
   *
   * @generated from protobuf field: string name = 1;
   */
  name: string;
}
/**
 * @generated from protobuf message sparx.reading.bookkeeper.v1.DeleteQuestionResponse
 */
export interface DeleteQuestionResponse {}
// @generated message type with reflection information, may provide speed optimized methods
class GetBreakpointRequest$Type extends MessageType<GetBreakpointRequest> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.GetBreakpointRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.GetBreakpointRequest
 */
export const GetBreakpointRequest = new GetBreakpointRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBreakpointResponse$Type extends MessageType<GetBreakpointResponse> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.GetBreakpointResponse', [
      { no: 1, name: 'breakpoint', kind: 'message', T: () => Breakpoint },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.GetBreakpointResponse
 */
export const GetBreakpointResponse = new GetBreakpointResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBreakpointsRequest$Type extends MessageType<ListBreakpointsRequest> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.ListBreakpointsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.ListBreakpointsRequest
 */
export const ListBreakpointsRequest = new ListBreakpointsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBreakpointsResponse$Type extends MessageType<ListBreakpointsResponse> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.ListBreakpointsResponse', [
      {
        no: 1,
        name: 'breakpoints',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Breakpoint,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.ListBreakpointsResponse
 */
export const ListBreakpointsResponse = new ListBreakpointsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQuestionRequest$Type extends MessageType<GetQuestionRequest> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.GetQuestionRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.GetQuestionRequest
 */
export const GetQuestionRequest = new GetQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQuestionResponse$Type extends MessageType<GetQuestionResponse> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.GetQuestionResponse', [
      { no: 1, name: 'question', kind: 'message', T: () => Question },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.GetQuestionResponse
 */
export const GetQuestionResponse = new GetQuestionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListQuestionsRequest$Type extends MessageType<ListQuestionsRequest> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.ListQuestionsRequest', [
      { no: 1, name: 'parent', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'page_size', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'page_token', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.ListQuestionsRequest
 */
export const ListQuestionsRequest = new ListQuestionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListQuestionsResponse$Type extends MessageType<ListQuestionsResponse> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.ListQuestionsResponse', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Question,
      },
      {
        no: 2,
        name: 'next_page_token',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.ListQuestionsResponse
 */
export const ListQuestionsResponse = new ListQuestionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Breakpoint$Type extends MessageType<Breakpoint> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.Breakpoint', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'question_names',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'position_in_book',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'progress_in_book',
        kind: 'scalar',
        T: 1 /*ScalarType.DOUBLE*/,
      },
      {
        no: 5,
        name: 'words_since_last_breakpoint',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'audio_clip_filename',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 7, name: 'deleted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.Breakpoint
 */
export const Breakpoint = new Breakpoint$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Question$Type extends MessageType<Question> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.Question', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'question_text',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'type',
        kind: 'enum',
        T: () => [
          'sparx.reading.bookkeeper.v1.Question.Type',
          Question_Type,
          'TYPE_',
        ],
      },
      {
        no: 4,
        name: 'answers',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 5,
        name: 'options',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 6, name: 'priority', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.Question
 */
export const Question = new Question$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateQuestionsRequest$Type extends MessageType<UpdateQuestionsRequest> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.UpdateQuestionsRequest', [
      {
        no: 1,
        name: 'questions',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Question,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.UpdateQuestionsRequest
 */
export const UpdateQuestionsRequest = new UpdateQuestionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateQuestionsResponse$Type extends MessageType<UpdateQuestionsResponse> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.UpdateQuestionsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.UpdateQuestionsResponse
 */
export const UpdateQuestionsResponse = new UpdateQuestionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBreakpointsRequest$Type extends MessageType<UpdateBreakpointsRequest> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.UpdateBreakpointsRequest', [
      {
        no: 1,
        name: 'breakpoints',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Breakpoint,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.UpdateBreakpointsRequest
 */
export const UpdateBreakpointsRequest = new UpdateBreakpointsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBreakpointsResponse$Type extends MessageType<UpdateBreakpointsResponse> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.UpdateBreakpointsResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.UpdateBreakpointsResponse
 */
export const UpdateBreakpointsResponse = new UpdateBreakpointsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteBreakpointRequest$Type extends MessageType<DeleteBreakpointRequest> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.DeleteBreakpointRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'move_questions_to_next_breakpoint',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.DeleteBreakpointRequest
 */
export const DeleteBreakpointRequest = new DeleteBreakpointRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteBreakpointResponse$Type extends MessageType<DeleteBreakpointResponse> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.DeleteBreakpointResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.DeleteBreakpointResponse
 */
export const DeleteBreakpointResponse = new DeleteBreakpointResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SoftDeleteBreakpointRequest$Type extends MessageType<SoftDeleteBreakpointRequest> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.SoftDeleteBreakpointRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.SoftDeleteBreakpointRequest
 */
export const SoftDeleteBreakpointRequest =
  new SoftDeleteBreakpointRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SoftDeleteBreakpointResponse$Type extends MessageType<SoftDeleteBreakpointResponse> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.SoftDeleteBreakpointResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.SoftDeleteBreakpointResponse
 */
export const SoftDeleteBreakpointResponse =
  new SoftDeleteBreakpointResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteQuestionRequest$Type extends MessageType<DeleteQuestionRequest> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.DeleteQuestionRequest', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.DeleteQuestionRequest
 */
export const DeleteQuestionRequest = new DeleteQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteQuestionResponse$Type extends MessageType<DeleteQuestionResponse> {
  constructor() {
    super('sparx.reading.bookkeeper.v1.DeleteQuestionResponse', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookkeeper.v1.DeleteQuestionResponse
 */
export const DeleteQuestionResponse = new DeleteQuestionResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.bookkeeper.v1.BookQuestions
 */
export const BookQuestions = new ServiceType(
  'sparx.reading.bookkeeper.v1.BookQuestions',
  [
    {
      name: 'GetBreakpoint',
      options: {},
      I: GetBreakpointRequest,
      O: GetBreakpointResponse,
    },
    {
      name: 'ListBreakpoints',
      options: {},
      I: ListBreakpointsRequest,
      O: ListBreakpointsResponse,
    },
    {
      name: 'GetQuestion',
      options: {},
      I: GetQuestionRequest,
      O: GetQuestionResponse,
    },
    {
      name: 'ListQuestions',
      options: {},
      I: ListQuestionsRequest,
      O: ListQuestionsResponse,
    },
    {
      name: 'UpdateQuestions',
      options: {},
      I: UpdateQuestionsRequest,
      O: UpdateQuestionsResponse,
    },
    {
      name: 'DeleteQuestion',
      options: {},
      I: DeleteQuestionRequest,
      O: DeleteQuestionResponse,
    },
    {
      name: 'UpdateBreakpoints',
      options: {},
      I: UpdateBreakpointsRequest,
      O: UpdateBreakpointsResponse,
    },
    {
      name: 'DeleteBreakpoint',
      options: {},
      I: DeleteBreakpointRequest,
      O: DeleteBreakpointResponse,
    },
    {
      name: 'SoftDeleteBreakpoint',
      options: {},
      I: SoftDeleteBreakpointRequest,
      O: SoftDeleteBreakpointResponse,
    },
  ],
);
