import * as Sentry from '@sentry/react';
import { AppContainer } from 'app/containers/app-container/app-container';
import { ViewTransitionRoutes } from 'app/containers/app-container/view-transition-group';
import { IntercomContext } from 'app/intercom/intercom';
import { AlertProvider } from 'components/alert/alert';
import { AnalyticsShimProvider } from 'components/client-events/analytics-shim';
import { ClientEventProvider } from 'components/client-events/client-event-provider';
import { QueryAnalytics } from 'components/client-events/query-analytics';
import { Header } from 'components/sections/header';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import { View } from 'views';
import { LandingView } from 'views/common/landing-view/landing-view';
import { SparxStaffFeaturesProvider } from 'views/teacher/components/sparx-staff-features-provider';
import { pathForView } from 'views/views';

import { SentryRouteErrorFallback } from '../errors';
import { adminRoutes } from './admin-routes';
import { contentAdminRoutes } from './content-admin-routes';
import { studentRoutes } from './student-routes';
import { teacherRoutes } from './teacher-routes';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routes = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Outlet />,
    errorElement: <SentryRouteErrorFallback />,
    children: [
      {
        path: '/login',
        element: <Navigate to="/" replace={true} />,
      },
      {
        path: pathForView(View.Landing),
        element: <LandingView />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <ClientEventProvider pushURL={window?.settings?.interactionGatewayURL}>
        <AnalyticsShimProvider>
          <QueryAnalytics />
          <AlertProvider>
            <SparxStaffFeaturesProvider>
              <AppContainer>
                <IntercomContext>
                  <Header />
                  <ViewTransitionRoutes />
                </IntercomContext>
              </AppContainer>
            </SparxStaffFeaturesProvider>
          </AlertProvider>
        </AnalyticsShimProvider>
      </ClientEventProvider>
    ),
    errorElement: (
      <ClientEventProvider pushURL={window?.settings?.interactionGatewayURL}>
        <SentryRouteErrorFallback />
      </ClientEventProvider>
    ),
    children: [teacherRoutes, ...studentRoutes, contentAdminRoutes, adminRoutes],
  },
]);
