import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';

import styles from './stars.module.css';

type Props = {
  rating?: number;
  onSelectRating?: (rating: number) => void;
};

export const Stars = ({ rating = 0, onSelectRating }: Props) => {
  const [hoveredStar, setHoveredStar] = useState(0);

  const clipPathWidth = (star: number) => {
    if (star <= hoveredStar || star <= rating) return 0;
    if (star - 0.5 <= hoveredStar || star - 0.5 <= rating) return 50;
    return 100;
  };

  return (
    <div className={styles.Stars}>
      {[1, 2, 3, 4, 5].map(star => (
        <div
          key={star}
          className={classNames(styles.Star, { [styles.Selectable]: Boolean(onSelectRating) })}
        >
          {onSelectRating && (
            <div
              className={styles.HoverTarget}
              onClick={() => onSelectRating(star - 0.5)}
              onMouseOver={() => setHoveredStar(star - 0.5)}
              onMouseLeave={() => setHoveredStar(0)}
              data-testid={`star-${star - 0.5}`}
            />
          )}
          {onSelectRating && (
            <div
              className={classNames(styles.HoverTarget, styles.Right)}
              onClick={() => onSelectRating(star)}
              onMouseOver={() => setHoveredStar(star)}
              onMouseLeave={() => setHoveredStar(0)}
              data-testid={`star-${star}`}
            />
          )}
          <FontAwesomeIcon icon={faStar} className={styles.BaseStar} />
          <FontAwesomeIcon
            key={star}
            icon={faStar}
            className={styles.FilledStar}
            style={{
              clipPath: `inset(0 ${clipPathWidth(star)}% 0 0)`,
            }}
          />
        </div>
      ))}
    </div>
  );
};
