import { BooklogQuestion } from '@sparx/api/apis/sparx/reading/tasks/v1/booklog';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { Button } from 'components/buttons';
import { atom, useAtom } from 'jotai';
import { useTaskActionsLoading } from 'queries/tasks';

import styles from '../booklog-task-view.module.css';

interface IStageWritingProps {
  task: Task;
  onSubmit: (req: BooklogQuestion) => void;
  onBack: () => void;
}

// We use jotai to remember what question and answer the user has entered so
// that if they go back, they don't have to type it in again.
const booklogQuestionAtom = atom<BooklogQuestion>({
  questionText: '',
  answerText: '',
});

export const StageWriting = ({ onSubmit, onBack }: IStageWritingProps) => {
  const [req, setRequest] = useAtom(booklogQuestionAtom);
  const submit = () => {
    onSubmit(req);

    // Reset the jotai state when the question has been submitted.
    setRequest({ questionText: '', answerText: '' });
  };
  const valid = !!req.questionText.trim() && !!req.answerText.trim();
  const loading = useTaskActionsLoading();

  return (
    <div>
      <h2 className={styles.StageTitle}>Add a question to your reading session</h2>
      <p className={styles.StageSubtitle}>
        Write a question and an answer to that question for the reading you have just done. This
        will show your teacher that you have been reading carefully.
      </p>
      <p></p>
      <div className={styles.QuestionInputField}>
        <div className={styles.QuestionInputLabel}>Question</div>
        <textarea
          className={'text-input'}
          value={req.questionText}
          onChange={e => setRequest({ ...req, questionText: e.target.value })}
          maxLength={500}
        />
      </div>
      <div className={styles.QuestionInputField}>
        <div className={styles.QuestionInputLabel}>Answer</div>
        <textarea
          className={'text-input'}
          value={req.answerText}
          onChange={e => setRequest({ ...req, answerText: e.target.value })}
          maxLength={500}
        />
      </div>
      <div className={styles.ActionButtons}>
        <Button analyticsEvent={undefined} variant="secondary" onClick={onBack}>
          Back
        </Button>
        <Button analyticsEvent={undefined} loading={loading} disabled={!valid} onClick={submit}>
          Finish
        </Button>
      </div>
    </div>
  );
};
