import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from 'components/copyright/copyright.module.css';
import moment from 'moment';
import { userHasRole, UserRole, useUser } from 'queries/session';
import { isTestEnv } from 'utils/settings';

/**
 * Copyright displays a copyright notice populated with the current year.
 * @constructor
 */
export const Copyright = () => (
  <div className={styles.Copyright}>&copy; Copyright {moment().format('YYYY')} Sparx Ltd.</div>
);

export const Version = () => {
  const user = useUser();
  const isAdmin = userHasRole(user, UserRole.EQMRead);
  const showCommit = isTestEnv() || isAdmin;
  const commit = import.meta.env.VITE_RELEASE_COMMIT || '???';

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    // eslint-disable-next-line compat/compat -- admin only feature
    navigator.clipboard.writeText(commit);
  };

  if (!showCommit) {
    return null;
  }

  return (
    <button onClick={onClick} className={styles.Button}>
      <span className={styles.CopyIcon}>
        <FontAwesomeIcon icon={faCopy} />
      </span>
      <span className={styles.Commit}>{commit.slice(0, 8)}</span>
    </button>
  );
};
