import * as RadixTooltip from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode, useState } from 'react';

import { useTheme } from '../../context';
import styles from './Tooltip.module.css';

export interface TooltipProps {
  // The content to display in the tooltip.
  content?: ReactNode;
  // Side of the tooltip to display on.
  side?: RadixTooltip.TooltipContentProps['side'];
  // When true, click of the child will not be prevented.
  allowClickThrough?: boolean;
}

/**
 * Tooltip is a component that wraps children with a tooltip.
 * It is a styled wrapper around the Radix Tooltip component.
 *
 * Overrideable styles can be provided by providing a .Tooltip
 * class in the theme.
 */
export const Tooltip = ({
  children,
  content,
  side,
  allowClickThrough,
}: PropsWithChildren<TooltipProps>) => {
  const { themeStyles } = useTheme();
  const [open, setOpen] = useState(false);

  // If there is no tooltip content, then just return the children.
  if (!content) {
    return children;
  }

  return (
    <RadixTooltip.Root open={open} delayDuration={0} onOpenChange={setOpen}>
      <RadixTooltip.Trigger
        onClick={e => {
          setOpen(true);
          if (!allowClickThrough) {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        asChild
      >
        {children}
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className={classNames(themeStyles['Tooltip'] || styles.Tooltip, styles.TooltipContent)}
          side={side}
          sideOffset={5}
        >
          <div className={styles.Content}>{content}</div>
          <RadixTooltip.Arrow className={styles.TooltipArrow} width={16} height={8} />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};
