import { Button } from 'components/buttons';
import { Link } from 'components/link';
import { useFinishOnboarding } from 'queries/session';
import { useSearchParams } from 'react-router-dom';
import { View } from 'views';
import { pathForView } from 'views/views';

import { TaskComponentProps } from '../../tasks';
import styles from './awaiting-srt-result.module.css';
import WaitingIcon from './wizard_SRT_false.svg';
import CompleteIcon from './wizard_SRT_true.svg';

export const AwaitingSRTResult = ({ task }: TaskComponentProps) => {
  const finishOnboarding = useFinishOnboarding();
  const [searchParams] = useSearchParams();
  const trainingSrc = searchParams.get('src') === 'training';
  const message = task.isComplete
    ? trainingSrc
      ? 'Well done! You have completed the training book. You can return to your Sparx Reader training or start reading.'
      : "Well done! You are now ready to start reading. Let's head to the Library to choose a book."
    : 'Your teacher will set you a short reading test soon. Complete the test to start using Sparx Reader.';

  const icon = task.isComplete ? CompleteIcon : WaitingIcon;

  return (
    <div className={styles.SRTContainer}>
      <img src={icon} className={styles.Icon} />
      <div>
        <h3 className={styles.StepTitle}>Reading test</h3>
        <div className={styles.StepDescription}>
          <p>{message}</p>
          {trainingSrc && (
            <Link
              to={pathForView(View.TeacherTraining)}
              analyticsEvent={{
                category: 'awaiting-srt-result',
                action: 'return to training',
              }}
              className={styles.Link}
            >
              <Button analyticsEvent={undefined}>Return to training</Button>
            </Link>
          )}
          <Button
            analyticsEvent={undefined}
            disabled={!task.isComplete}
            onClick={() => finishOnboarding.mutate({})}
            variant={trainingSrc ? 'secondary' : 'primary'}
          >
            Start Sparx Reader
          </Button>
        </div>
      </div>
    </div>
  );
};
