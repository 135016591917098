import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { Loading } from 'components/loading/loading';
import goldIcon from 'components/sections/gold_icon_white.png';
import goldLocked from 'components/sections/gold_locked_white.png';
import { Title } from 'components/title/title';
import { useGoldReaderState } from 'queries/gold';
import { useListGoldReaderBooks, useListMyLibraryBooks } from 'queries/library-books';
import { useUser } from 'queries/session';

import { LibraryContent, LibraryTitle } from '../library-view/components/library-content';
import { useCurrentHomework, useHomeworks } from '../library-view/subviews/home-view';
import { GoldReaderProgress } from './gold-reader-progress/gold-reader-progress';
import { GoldReaderProgressNotGr } from './gold-reader-progress/gold-reader-progress-not-gr';
import { GoldReaderSummary } from './gold-reader-summary/gold-reader-summary';
import { GoldReaderTips } from './gold-reader-tips/gold-reader-tips';
import styles from './gold-reader-view.module.css';

interface IGoldReaderViewContentProps {
  disabledOverride: boolean;
  isLoading: boolean;
  isFetched: boolean;
  progress: number;
  stars: number;
  isGoldReader: boolean;
  passExpiry: Timestamp | undefined;
  latestEbook: LibraryBook | undefined;
  latestGoldReaderBook: LibraryBook | undefined;
  latestGoldReaderBookComplete: boolean;
  currentHomework: Homework | undefined;
}

export const GoldReaderView = () => {
  const user = useUser();
  const { data: myBooks } = useListMyLibraryBooks();
  const { data: myGoldReaderBooks } = useListGoldReaderBooks();
  const { data: state, isLoading, isFetched } = useGoldReaderState();
  const isGoldReader = Boolean(state?.passExpiry);

  const latestEbook = myBooks && myBooks[0];

  // Find the first gold reader book.
  const latestGoldReaderBook = myGoldReaderBooks && myGoldReaderBooks[0];
  const latestGoldReaderBookComplete =
    ((latestGoldReaderBook && latestGoldReaderBook.studentBook?.progress) || 0) >= 1;

  // Find current homework
  const { currentTasks } = useHomeworks();
  const currentHomework = useCurrentHomework(currentTasks);

  const disabledOverride = Boolean(user?.statistics?.goldReaderDisabled);
  const stars = state?.stars || 0;
  const progress = state?.progress || 0;
  const passExpiry = state?.passExpiry;
  const props = {
    isGoldReader,
    isLoading,
    isFetched,
    disabledOverride,
    stars,
    progress,
    latestEbook,
    latestGoldReaderBook,
    latestGoldReaderBookComplete,
    passExpiry,
    currentHomework,
  };

  return <GoldReaderViewContent {...props} />;
};

export const GoldReaderViewContent = (props: IGoldReaderViewContentProps) => {
  if (props.isLoading) {
    return (
      <LibraryContent>
        <Title>Gold Reader</Title>
        <LibraryTitle>Gold Reader</LibraryTitle>
        <Loading />
      </LibraryContent>
    );
  }
  return (
    <LibraryContent>
      <Title>Gold Reader</Title>
      <LibraryTitle>
        Gold Reader
        <img
          alt=""
          src={props.isGoldReader && !props.disabledOverride ? goldIcon : goldLocked}
          className={styles.GoldReaderIcon}
        />
      </LibraryTitle>
      <div className={styles.BoxContainer}>
        {!props.disabledOverride && <GoldReaderSummary {...props} />}
        {props.disabledOverride ? (
          <GoldReaderProgressNotGr
            disabledOverride={true}
            isFetched={true}
            progress={0}
            stars={0}
            breakWide
          />
        ) : props.isGoldReader ? (
          <GoldReaderProgress {...props} />
        ) : (
          <GoldReaderProgressNotGr {...props} />
        )}
        {props.isGoldReader && !props.disabledOverride && <GoldReaderTips />}
      </div>
    </LibraryContent>
  );
};
