import { faMortarBoard } from '@fortawesome/free-solid-svg-icons';
import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { TrainingSummary, useStaffTrainingSummaries } from '@sparx/teacher-training';
import { useListReaderStaff } from 'queries/staff';

import { Tab } from '../../types';

export const useTrainingTab = (): Tab & {
  staffMembers: SchoolStaffMember[] | undefined;
  trainingSummaries: Record<string, TrainingSummary | undefined> | undefined;
} => {
  const { data: staffMembers, isLoading: staffLoading } = useListReaderStaff();
  const {
    data: trainingSummaries,
    isLoading: trainingSummariesLoading,
    isFetching: trainingSummariesFetching,
  } = useStaffTrainingSummaries(
    staffMembers?.map(staffMember => staffMember.name.split('/')[1]) || [],
    {
      enabled: !!staffMembers?.length,
    },
  );

  // We check that all staff have completed the essential training (first
  // module) to count this step as complete.
  const missingEssentialTraining = staffMembers?.some(staffMember => {
    const trainingSummary = trainingSummaries?.[staffMember.name.split('/')[1]];
    return !trainingSummary || !trainingSummary.modules[0]?.complete;
  });

  const isLoading = staffLoading || (trainingSummariesLoading && trainingSummariesFetching);

  const hasStaffMembers = staffMembers && staffMembers.length > 0;

  return {
    id: 'staff-training',
    title: 'Complete Staff Training',
    subtitle: missingEssentialTraining
      ? 'Action required'
      : hasStaffMembers
        ? 'Training complete'
        : 'Please add staff members',
    pageSubtitle: missingEssentialTraining
      ? 'Action required'
      : hasStaffMembers
        ? 'Training complete'
        : 'Please add staff members',
    icon: faMortarBoard,
    responsibility: 'Sparx Leader & Head of Department',
    hasWarning: missingEssentialTraining,
    hasSuccess: !missingEssentialTraining && hasStaffMembers,
    isLoading,
    staffMembers: staffMembers,
    trainingSummaries,
  };
};
