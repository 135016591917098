import {
  BonusQuestionStep,
  BonusQuestionStep_Question,
  StudentBonusQuestion,
} from '@sparx/api/apis/sparx/reading/bonusquestions/v1/bonusquestions';
import {
  AssessmentTask_Question_Result,
  AssessmentTask_Question_Stage,
} from '@sparx/api/apis/sparx/reading/tasks/v1/assessment';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { bonusQuestionsClient } from 'api';
import classNames from 'classnames';
import { BookContent } from 'components/book/book-content';
import { bookSettingsToStylesOuter } from 'components/book/book-settings';
import { Button } from 'components/buttons/button';
import { Loading } from 'components/loading/loading';
import { LargeSRPReward } from 'components/tasks/task-complete-modal';
import { queryClient } from 'queries/client';
import { useUpdateExperience } from 'queries/notifications';
import { useUserSettings } from 'queries/session';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useWindowSize } from 'utils/hooks';
import { Question } from 'views/student/task-view/assessment-task-view';

import styles from './bonus-question-modal.module.css';

const useBonusQuestions = () =>
  useQuery({
    queryKey: ['bonus-questions'],
    queryFn: () => bonusQuestionsClient.getBonusQuestions({}).response,
    cacheTime: 1000 * 60 * 5, // 5 minutes
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    retry: false, // Don't retry if this fails for some reason
  });

const useSubmitBonusQuestion = (id: string) => {
  const updateExperience = useUpdateExperience();
  return useMutation({
    mutationKey: ['bonus-question', 'submit', id],
    mutationFn: (answer: string[]) =>
      bonusQuestionsClient.submitBonusQuestion({ id, answer }).response,
    onSuccess: resp => {
      // Update the cache
      if (resp.questions)
        queryClient.setQueryData(['bonus-questions'], { questions: resp.questions });

      // Update with experience update
      const xpUpdate = resp.experienceUpdate;
      if (xpUpdate) {
        updateExperience(xpUpdate);
        queryClient.refetchQueries(['homeworks', 'mine']); // ensure refetch
      }
    },
  });
};

const useDismissBonusQuestions = () =>
  useMutation({
    mutationKey: ['bonus-questions', 'dismiss'],
    mutationFn: () => bonusQuestionsClient.dismissBonusQuestions({}).response,
    onSuccess: () => queryClient.invalidateQueries(['bonus-questions']),
  });

const useSubmitBonusQuestionEvent = (studentQuestionId?: string, questionId?: string) =>
  useMutation({
    mutationKey: ['bonus-question', 'submit-event', questionId],
    mutationFn: (args: { stepId?: string; action: { type: string } & Record<string, string> }) =>
      bonusQuestionsClient.submitBonusQuestionEvent({
        studentQuestionId: studentQuestionId || '',
        questionId: questionId || '',
        stepId: args.stepId || '',
        event: JSON.stringify({
          timestamp: new Date(),
          ...args.action,
        }),
      }).response,
  });

const BonusQuestionDelivery = ({
  question,
  index,
  onNextQuestion,
}: {
  question: StudentBonusQuestion;
  index: number;
  onNextQuestion: () => void;
}) => {
  const { mutate: sendEvent } = useSubmitBonusQuestionEvent(question.id, question.question?.id);

  const [answers, setAnswers] = useState<string[]>([]);
  const step = question.question?.steps[answers.length];

  const windowSize = useWindowSize();
  const smallWidth = Boolean(windowSize.width && windowSize.width < 500);

  const submitAnswer = useSubmitBonusQuestion(question.id);

  // Send event when the question id or step id changes
  useEffect(() => {
    sendEvent({ action: { type: 'start' }, stepId: step?.id || '' });
  }, [sendEvent, step?.id, question.id]);

  const onNext = (answer: string) => {
    sendEvent({ action: { type: 'submit', answer }, stepId: step?.id || '' });
    const newAnswers = [...answers, answer];
    setAnswers(newAnswers);
    if (newAnswers.length === question.question?.steps.length) {
      submitAnswer.mutate(newAnswers);
    }
  };

  // Behaviour to scroll the dots into view when the step changes
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ block: 'center' });
  }, [step]);

  let content;
  if (submitAnswer.isLoading) {
    content = (
      <div className={styles.Experience}>
        <Loading />
      </div>
    );
  } else if (submitAnswer.data) {
    content = (
      <div className={styles.Experience}>
        {submitAnswer?.data?.experienceUpdate?.reward.map((r, i) => (
          <LargeSRPReward experience={r.experience} key={i} />
        ))}
        <Button onClick={onNextQuestion} variant="primary" analyticsEvent={undefined}>
          Continue
        </Button>
      </div>
    );
  } else {
    switch (step?.content.oneofKind) {
      case 'text':
        content = (
          <div className={styles.QuestionNumberContainer}>
            <BookContent defaultFontSize={smallWidth ? 16 : 20} className={styles.QuestionContent}>
              <ReactMarkdown>{step.content.text.text}</ReactMarkdown>
              <div className={styles.Continue}>
                <Button
                  onClick={() => onNext('<submit>')}
                  variant="primary"
                  analyticsEvent={undefined}
                >
                  Next
                </Button>
              </div>
            </BookContent>
          </div>
        );
        break;
      case 'question':
        content = (
          <div className={styles.QuestionNumberContainer}>
            <BookContent defaultFontSize={smallWidth ? 16 : 20} className={styles.QuestionContent}>
              <BonusQuestionContent
                onSubmit={onNext}
                step={step}
                question={step.content.question}
              />
            </BookContent>
          </div>
        );
        break;
      default:
        return null; // hide everything
    }
  }

  return (
    <>
      <Modal.Title className={styles.ModalTitle}>
        Question {index + 1}
        <div className={styles.ProgressDots} ref={ref}>
          {question.question?.steps.map((_, i) => (
            <div
              key={i}
              className={classNames(styles.ProgressDot, {
                [styles.ProgressDotActive]: i == answers.length,
                [styles.ProgressDotPrevious]: i < answers.length,
              })}
            />
          ))}
        </div>
      </Modal.Title>
      <TransitionGroup
        component="div"
        className={classNames(styles.TransitionWrapper, 'PanelPaperbackQuestionContainer')}
      >
        <CSSTransition
          key={step?.id || 'other'}
          timeout={500}
          classNames={classNames(styles.TransitionContentWrapper, 'PanelPaperbackQuestion')}
        >
          {content}
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

const shuffleStrings = (a: string[]) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const BonusQuestionContent = ({
  step,
  question,
  onSubmit,
}: {
  step: BonusQuestionStep;
  question: BonusQuestionStep_Question;
  onSubmit: (answer: string) => void;
}) => {
  // Ensure the options are shuffled
  const options = useMemo(() => {
    const opts = shuffleStrings([...question.options]);
    return opts.map(o => ({ text: o, id: o }));
  }, [question]);

  return (
    <Question
      key={step.id}
      question={{
        index: 0,
        questionId: '',
        text: question.text,
        options,
        stage: AssessmentTask_Question_Stage.QUESTION,
        result: AssessmentTask_Question_Result.RESULT_UNSPECIFIED,
        partResults: {},
      }}
      isLoading={false}
      onSubmit={onSubmit}
    />
  );
};

export const BonusQuestionModal = () => {
  const [isOpen, setOpen] = useState(false);
  const [stage, setStage] = useState<'intro' | 'questions'>('intro');

  const { data: questions } = useBonusQuestions();
  const [currentQuestion, setCurrentQuestion] = useState(questions?.questions[0]);

  const { data: settings } = useUserSettings();

  const { mutate: sendEvent } = useSubmitBonusQuestionEvent(
    currentQuestion?.id,
    currentQuestion?.question?.id,
  );

  const [doneOpenCheck, setDoneOpenCheck] = useState(false);
  useEffect(() => {
    if (questions?.questions?.length && !doneOpenCheck) {
      setOpen(true);
      setCurrentQuestion(questions.questions[0]);
      setDoneOpenCheck(true);
      sendEvent({ action: { type: 'open' } });
    }
  }, [sendEvent, setOpen, questions, doneOpenCheck, setDoneOpenCheck]);

  const [questionIndex, setQuestionIndex] = useState(0);
  const nextQuestion = () => {
    if (questions?.questions?.length) {
      setQuestionIndex(i => i + 1);
      setCurrentQuestion(questions.questions[0]);
    } else {
      setOpen(false);
      sendEvent({ action: { type: 'finish' } });
    }
  };

  const { mutate: dismiss } = useDismissBonusQuestions();
  const closeModal = () => {
    dismiss();
    setOpen(false);
    sendEvent({ action: { type: 'dismiss' } });
  };

  let content;
  if (stage === 'intro') {
    content = (
      <IntroContent onContinue={() => setStage('questions')} onExit={() => setOpen(false)} />
    );
  } else if (stage === 'questions' && currentQuestion) {
    content = (
      <Modal.Body className={styles.Container} key={currentQuestion.id}>
        <BonusQuestionDelivery
          question={currentQuestion}
          index={questionIndex}
          onNextQuestion={nextQuestion}
        />
      </Modal.Body>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      overlayDismiss={false}
      overlayClassName={classNames(styles.Overlay, {
        [styles.OverlayActive]: stage !== 'intro',
      })}
      onClose={closeModal}
    >
      <Modal.Content
        width={stage === 'intro' ? 'xl' : '3xl'}
        style={stage === 'questions' ? bookSettingsToStylesOuter(settings) : undefined}
        className={styles.Content}
      >
        <Modal.CloseButton />
        {content}
      </Modal.Content>
    </Modal>
  );
};

const IntroContent = ({ onContinue, onExit }: { onContinue: () => void; onExit: () => void }) => (
  <>
    <Modal.Title align="center">Bonus SRP boost</Modal.Title>
    <Modal.Body className={styles.IntroText}>
      <p>Answer some quick questions for bonus SRP.</p>
      <p>The SRP counts towards your homework.</p>
    </Modal.Body>
    <Modal.Footer align="center">
      <Button analyticsEvent={undefined} onClick={onExit} variant="secondary">
        Not now
      </Button>
      <Button analyticsEvent={undefined} onClick={onContinue}>
        Let&apos;s do it!
      </Button>
    </Modal.Footer>
  </>
);
