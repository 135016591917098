import { Fireworks } from '@fireworks-js/react';
import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import classNames from 'classnames';
import { useUser } from 'queries/session';
import { usePackageTasks } from 'queries/tasks';

import { Backdrop } from './backdrop/backdrop';
import styles from './book-complete-view.module.css';
import { BookCover } from './book-cover/book-cover';
import { Interactive } from './interactive/interactive';

interface Props {
  bookMetadata: MetadataAbridged;
  studentBook: StudentBook;
  task: Task;
}

export const BookCompleteView = ({ bookMetadata, studentBook, task }: Props) => {
  const user = useUser();

  const { data: tasks } = usePackageTasks(task.packageId);

  if (!user || !tasks) {
    return (
      <div className={styles.Container}>
        <Backdrop />
      </div>
    );
  }

  const readingSeconds =
    tasks.tasks.reduce((acc, t) => acc + (t.state?.readingTimeSeconds || 0), 0) || 0;
  const srpToMinutes = readingSeconds / 60;
  const hours = Math.floor(srpToMinutes / 60);
  const minutes = Math.floor(srpToMinutes % 60);

  const hoursText = hours === 1 ? '1 hour' : hours > 1 ? `${hours} hours` : '';
  const minutesText = minutes === 1 ? '1 minute' : minutes > 1 ? `${minutes} minutes` : '';

  const wordCount = bookMetadata?.wordCount?.count || 0;

  return (
    <div className={styles.Container}>
      <Backdrop />

      <div className={styles.Contents}>
        <BookCover bookMetadata={bookMetadata} />

        <div className={styles.BelowCover}>
          <p className={classNames(styles.Green, styles.Congratulations)}>Congratulations!</p>

          <p className={classNames(styles.White, styles.Finished)}>
            You finished <strong>&quot;{bookMetadata.title}&quot;</strong>
            <br /> by {bookMetadata.authors.join(', ')}
          </p>

          <p className={classNames(styles.White, styles.GreatReading)}>
            Great reading {user.firstName}! You spent{' '}
            <strong className={styles.Green}>
              {hoursText} {minutesText}
            </strong>{' '}
            reading
            {wordCount > 0 && (
              <strong className={styles.Green}> {wordCount.toLocaleString()} words</strong>
            )}{' '}
            and gained{' '}
            <strong className={styles.Green}>{studentBook.experience.toLocaleString()} SRP</strong>!
          </p>

          <Interactive studentBook={studentBook} task={task} />
        </div>
      </div>

      <Fireworks
        options={{
          opacity: 0.5,
          explosion: 10,
          acceleration: 1.01,
          intensity: 75,
          delay: { min: 200, max: 300 },
        }}
        autostart={true}
        className={styles.Fireworks}
      />
    </div>
  );
};
