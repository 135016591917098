import { isJsonObject, JsonValue } from '@protobuf-ts/runtime';
import { useFlags } from 'utils/featureflags';

import styles from './client-events-table.module.css';

export const FeatureFlagsTable = () => {
  const flags = useFlags();

  const flagValue = (value: JsonValue) => {
    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }
    if (value === true) {
      return 'true';
    }
    if (value === false) {
      return 'false';
    }
    if (isJsonObject(value)) {
      return JSON.stringify(value);
    }
    return '';
  };

  return (
    <table className={styles.Table}>
      <thead>
        <tr>
          <th style={{ width: '40%' }}>Flag key</th>
          <th>Flag value</th>
        </tr>
      </thead>

      <tbody>
        {Object.entries(flags || []).map(flag => (
          <tr key={flag[0]}>
            <td>{flag[0]}</td>
            <td>{flag[1] ? flagValue(flag[1]) : ''}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
