import { captureException } from '@sentry/react';
import {
  Alignment,
  BookReflowable,
  FontFace,
  FontVariant,
  ParagraphStyle,
  SectionType,
  Span,
  TextRun,
  TextStyle,
} from '@sparx/api/apis/sparx/reading/books/v2/book_v2';
import { getImageURL } from 'api';
import classNames from 'classnames';
import { BookContent } from 'components/book/book-content';
import styles from 'components/bookv2/bookv2.module.css';
import { buildReflowableParts, IBookSection } from 'components/bookv2/sections';
import { useCADContext } from 'components/contextual-definitions/definition-provider';
import { SplitIntoSelectableWords } from 'components/contextual-definitions/selectable-word';
import { useBookContentV2 } from 'queries/books';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { StringDiff } from 'react-string-diff';
import { useOnScreen } from 'utils/hooks';
import {
  PerformanceModeContext,
  PerformanceModeNext,
  PerformanceModePrevious,
  PerformanceModeState,
} from 'views/admin/views/ebooks/performance-mode';

export interface IBookV2Options {
  startID?: string;
  endID?: string;
  taskID?: string;

  beforeSections?: number;
  afterSections?: number;

  spanSelectable?: boolean;
  onSpanClick?: (span: Span) => void;

  onSectionClick?: (sectionID: string) => void;
  sectionClasses?: Record<string, string>;
  sectionStyles?: Record<string, ParagraphStyle>;
  sectionTypeOverrides?: Record<string, SectionType>;

  spanClasses?: Record<string, string>;
  runClasses?: Record<string, Record<string, string>>;
  runContentOverrides?: Record<string, Record<string, string>>;
  injectedElements?: Record<string, React.ReactNode>;

  showWordCounts?: boolean;
  pushV1Pages?: boolean;

  disableContextualDefinitions?: boolean;
}

interface IBookV2Props extends IBookV2Options {
  bookID: string;
}

interface IBookViewContext {
  bookID: string;
  spanClasses?: Record<string, string>;
  runClasses?: Record<string, Record<string, string>>;
  runContentOverrides?: Record<string, Record<string, string>>;
  spanSelectable?: boolean;
  onSpanClick?: (span: Span) => void;
  previewBeforeStart?: boolean;
  previewAfterEnd?: boolean;
  disableContextualDefinitions?: boolean;
}

const BookViewContext = React.createContext<IBookViewContext>({
  bookID: '',
  spanClasses: {},
  runClasses: {},
  runContentOverrides: {},
  spanSelectable: false,
  onSpanClick: () => undefined,
});

export const BookV2 = (props: IBookV2Props) => {
  const { data: book } = useBookContentV2({
    bookId: props.bookID,
    taskId: props.taskID,
  });
  switch (book?.bookV2?.content.oneofKind) {
    case 'reflowable':
      return (
        <ReflowableBook
          bookId={book.bookV2?.bookId}
          book={book?.bookV2?.content.reflowable}
          {...props}
        />
      );
    default:
      console.error('Unknown book content type', book);
      return <></>;
  }
};

interface IReflowableBookProps extends IBookV2Options {
  bookId: string;
  book: BookReflowable | undefined;
}

export const ReflowableBook = (props: IReflowableBookProps) => {
  const book = props.book;
  const bookPartResult = useMemo(() => buildReflowableParts(book, props), [book, props]);

  const { sections, sectionIDToChunkIndex } = useMemo(() => {
    const { parts: bookParts } = bookPartResult;
    // This map is used to tell performance mode where sections are in the huge
    // list of elements to render so that it can jump to them.
    const sectionIDToChunkIndex: Record<string, number | undefined> = {};
    const sections = bookParts.map((p, i) => {
      if (p.section) {
        sectionIDToChunkIndex[p.section.sectionId] = i;
        const overrideType = props.sectionTypeOverrides?.[p.section.sectionId];
        if (
          props.sectionStyles?.[p.section.sectionId] &&
          p.section.content.oneofKind === 'paragraph'
        ) {
          p.section.content.paragraph.style = {
            ...p.section.content.paragraph?.style,
            ...props.sectionStyles?.[p.section.sectionId],
          };
        }

        let content: React.ReactNode;
        switch (overrideType || p.section.type) {
          case SectionType.NORMAL:
            content = <SectionParagraph key={p.section.sectionId} section={p.section} />;
            break;
          case SectionType.CHAPTER_TITLE:
            content = <ChapterTitle key={p.section.sectionId} section={p.section} />;
            break;
          case SectionType.CHAPTER_SUBTITLE:
            content = <ChapterSubtitle key={p.section.sectionId} section={p.section} />;
            break;
          case SectionType.POEM:
            content = <SectionPoem key={p.section.sectionId} section={p.section} />;
            break;
          case SectionType.BREAK:
            content = <div key={p.section.sectionId} className={styles.Break} />;
            break;
          default:
            captureException(
              new Error(`Unknown section type: ${p.section.type} at ${p.section.sectionId}`),
            );
            return <div key={p.section.sectionId} />;
        }

        if (props.onSectionClick) {
          return (
            <div
              className={classNames(
                styles.SelectableSection,
                props.sectionClasses?.[p.section.sectionId],
              )}
              key={p.section.sectionId}
              onClick={() => props.onSectionClick?.(p.section?.sectionId || '')}
            >
              {content}
            </div>
          );
        }

        return content;
      } else if (p.node) {
        return <React.Fragment key={i}>{p.node}</React.Fragment>;
      }
      return <React.Fragment key={i}></React.Fragment>;
    });
    return { sections, sectionIDToChunkIndex };
  }, [props, bookPartResult]);

  // PERFORMANCE MODE
  const location = useLocation();
  const performanceModeState = useContext(PerformanceModeContext);
  const performanceModeEnabled = performanceModeState?.performanceModeEnabled;
  const chunkCount = performanceModeState?.chunkCount;
  const setChunkCount = performanceModeState?.setChunkCount;
  const setSectionIDToChunkIndex = performanceModeState?.setSectionIDToChunkIndex;
  const navigateToSection = performanceModeState?.navigateToSection;
  useEffect(() => {
    // Tell performance mode how many chunks (bits of text, breakpoints, word counts) there are to display.
    if (setChunkCount && chunkCount !== sections.length) {
      setChunkCount(sections.length);
    }
  }, [chunkCount, setChunkCount, sections.length]);
  useEffect(() => {
    // Tell performance mode where the sections (e.g. chapters) are so that it can jump to them.
    if (setSectionIDToChunkIndex) {
      setSectionIDToChunkIndex(sectionIDToChunkIndex);
    }
  }, [setSectionIDToChunkIndex, sectionIDToChunkIndex]);
  useEffect(() => {
    // If there is a hash in the URL, i.e. a section ID for the chapter, jump to it.
    if (navigateToSection && location.hash) {
      navigateToSection(location.hash.substring(1));
    }
  }, [navigateToSection, location.hash]);

  return (
    <BookViewContext.Provider
      value={{
        bookID: props.bookId,
        spanClasses: props.spanClasses,
        runClasses: props.runClasses,
        runContentOverrides: props.runContentOverrides,
        spanSelectable: props.spanSelectable,
        onSpanClick: props.onSpanClick,
        previewBeforeStart: (props.beforeSections || 0) > 0,
        previewAfterEnd: (props.afterSections || 0) > 0,
        disableContextualDefinitions: props.disableContextualDefinitions,
      }}
    >
      <BookContent
        className={classNames(styles.Book, {
          [styles.BookFadedBefore]: bookPartResult.addedBefore,
          [styles.BookFadedAfter]: bookPartResult.addedAfter,
        })}
      >
        {performanceModeEnabled ? (
          <PerformanceSections performanceModeState={performanceModeState} sections={sections} />
        ) : (
          sections
        )}
      </BookContent>
    </BookViewContext.Provider>
  );
};

/**
 * PerformanceSections is a wrapper around sections for when performance mode is
 * enabled. Rather than rendering all sections, it only renders a subset of them
 * and has buttons for navigating to the previous/next bit.
 */
const PerformanceSections = ({
  performanceModeState,
  sections,
}: {
  performanceModeState: PerformanceModeState;
  sections: JSX.Element[];
}) => {
  return (
    <>
      {performanceModeState.startChunk !== 0 && (
        <PerformanceModePrevious fullWidth> Previous</PerformanceModePrevious>
      )}
      {sections.slice(
        performanceModeState.startChunk,
        performanceModeState.startChunk + performanceModeState.chunksToDisplay,
      )}
      {performanceModeState.startChunk + performanceModeState.chunksToDisplay <
        performanceModeState.chunkCount && (
        <PerformanceModeNext fullWidth>Next </PerformanceModeNext>
      )}
    </>
  );
};

interface ISectionProps {
  section: IBookSection;
}

const ChapterTitle = ({ section }: ISectionProps) => {
  return (
    <SectionContent section={section} wrapImages={true}>
      {children => (
        <h1 id={section.sectionId} data-book-section="header">
          {children}
        </h1>
      )}
    </SectionContent>
  );
};

const ChapterSubtitle = ({ section }: ISectionProps) => {
  return (
    <SectionContent section={section} wrapImages={true}>
      {children => (
        <h2 id={section.sectionId} data-book-section="subheader">
          {children}
        </h2>
      )}
    </SectionContent>
  );
};

export const SectionParagraph = ({ section }: ISectionProps) => {
  return (
    <SectionContent section={section}>
      {children => (
        <p
          style={paragraphStyle(
            section.content.oneofKind === 'paragraph'
              ? section.content.paragraph?.style
              : undefined,
          )}
        >
          {children}
        </p>
      )}
    </SectionContent>
  );
};

const SectionPoem = ({ section }: ISectionProps) => {
  return (
    <SectionContent section={section} wrapImages={true}>
      {children => (
        <div
          style={paragraphStyle(
            section.content.oneofKind === 'paragraph'
              ? section.content.paragraph?.style
              : undefined,
          )}
          className={styles.Poem}
        >
          {children}
        </div>
      )}
    </SectionContent>
  );
};

interface ISectionContentProps extends ISectionProps {
  children: (children: React.ReactNode) => JSX.Element;
  wrapImages?: boolean;
}

const SectionContent = ({ section, children, wrapImages }: ISectionContentProps) => {
  const bookContext = useContext(BookViewContext);
  if (section.content.oneofKind === 'paragraph') {
    return children(spans(section, bookContext));
  } else if (section.content.oneofKind === 'image') {
    const imageElement = (
      <div className={styles.ImageSection}>
        <img
          alt=""
          src={getImageURL(
            bookContext.bookID,
            (section.content.image.source?.source.oneofKind === 'imageId' &&
              section.content.image.source?.source.imageId) ||
              '',
          )}
        />
      </div>
    );
    if (wrapImages) {
      return children(imageElement);
    }
    return imageElement;
  }
  captureException(new Error(`Unknown section content: ${section.sectionId}`));
  return <></>;
};

const spans = (section: IBookSection, ctx: IBookViewContext) => {
  // If running is true then we are appending in-focus spans to the output.
  // If it is false then we still append the spans but they are faded.
  let running = !section.startID;

  // Iterate through the spans and populate the output array.
  const output = [];
  for (const span of (section.content.oneofKind === 'paragraph' &&
    section.content.paragraph?.spans) ||
    []) {
    if (span.spanId === section.startID) {
      // This and future spans will be in-focus
      running = true;
    }
    if (span.spanId === section.endID) {
      // This and future spans will be faded
      running = false;
      if (!ctx.previewAfterEnd) {
        break; // end here
      }
    }
    if (running || ctx.previewBeforeStart) {
      output.push(
        <span
          key={span.spanId}
          className={classNames(
            styles.Span,
            !running && styles.SpanFaded,
            ctx.spanClasses?.[span.spanId],
            ctx.spanSelectable && styles.SpanSelectable,
          )}
          onClick={() => ctx.spanSelectable && ctx.onSpanClick?.(span)}
        >
          <TextRuns
            spanId={span.spanId}
            ctx={ctx}
            contextualDefinitionsEnabled={running && !ctx.disableContextualDefinitions}
          >
            {span.runs}
          </TextRuns>
        </span>,
      );
    }
  }
  return output;
};

const TextRuns = ({
  children,
  ctx,
  spanId,
  contextualDefinitionsEnabled,
}: {
  children: TextRun[];
  ctx: IBookViewContext;
  spanId: string;
  contextualDefinitionsEnabled?: boolean;
}) => {
  const cadContext = useCADContext();

  // Only show the split words if the element is on screen
  const ref = useRef<HTMLSpanElement | null>(null);
  const splitOutWords = useOnScreen(contextualDefinitionsEnabled ? ref : undefined, {
    // NOTE: This lowers the point at which we start splitting sentences so that it is slightly below the edge of the page to get around occasional scrolling jank in Chrome.
    // See PR for details: https://github.com/supersparks/CloudExperiments/pull/19392
    // This value is the height of the status bar plus half the height of a line of text.
    // Status bar height from: reader/client/src/components/sections/sections.module.css
    // Base line height from: reader/client/src/index.css
    rootMargin: '-72px 0px 0px 0px',
  });

  return (
    <span ref={ref}>
      {children.map((run, i) => {
        const splitWords = Boolean(splitOutWords && cadContext && contextualDefinitionsEnabled);
        const runContent = (
          <SplitIntoSelectableWords
            run={run}
            cadContext={cadContext}
            splitWords={splitWords}
            spanId={spanId}
            key={i}
          />
        );

        // TODO: could improve the validation on the editor. A delete class takes
        // precedence over an edit, but this isn't shown to the user or enforced
        // in the UI.
        const cls = ctx.runClasses?.[spanId]?.[String(i)];
        if (cls) {
          return (
            <span className={cls} style={run.style ? textRunStyle(run.style) : {}} key={i}></span>
          );
        }

        const contentOverride = ctx.runContentOverrides?.[spanId]?.[String(i)];
        if (contentOverride !== undefined) {
          return (
            <span style={run.style ? textRunStyle(run.style) : {}} key={i}>
              <StringDiff
                oldValue={run.content}
                newValue={contentOverride}
                className={styles.RunDiff}
              />
            </span>
          );
        }
        return run.style ? (
          <span style={textRunStyle(run.style)} key={i}>
            {runContent}
          </span>
        ) : (
          runContent
        );
      })}
    </span>
  );
};

const textRunStyle = (style: TextStyle): React.CSSProperties => {
  const properties: React.CSSProperties = {};
  if (style.bold) {
    properties.fontWeight = 'bold';
  }
  if (style.italic) {
    properties.fontStyle = 'italic';
  }
  if (style.underline) {
    properties.textDecoration = 'underline';
  }
  if (style.strikethrough) {
    properties.textDecoration = 'line-through';
  }
  switch (style.fontVariant) {
    case FontVariant.SMALL_CAPS: {
      properties.fontVariant = 'small-caps';
      break;
    }
  }
  switch (style.fontFace) {
    case FontFace.MONOSPACE:
      properties.fontFamily = '"Courier New", serif';
      break;
    case FontFace.WINGDINGS:
      properties.fontFamily = 'Wingdings';
      break;
  }
  if (style.visibilityHidden) {
    properties.backgroundColor = 'var(--slate-grey-100)';
    properties.color = 'transparent';
    properties.userSelect = 'none';
  }
  return properties;
};

const paragraphStyle = (style?: ParagraphStyle): React.CSSProperties => {
  const properties: React.CSSProperties = {};
  switch (style?.alignment) {
    case Alignment.CENTER:
      properties.textAlign = 'center';
      properties.textIndent = '0'; // Override any p style
      break;
    case Alignment.RIGHT:
      properties.textAlign = 'right';
      break;
  }

  // Margins
  if (style?.margins?.top) properties.marginTop = `${style.margins.top}em`;
  if (style?.margins?.right) properties.marginRight = `${style.margins.right}em`;
  if (style?.margins?.bottom) properties.marginBottom = `${style.margins.bottom}em`;
  if (style?.margins?.left) properties.marginLeft = `${style.margins.left}em`;

  return properties;
};
