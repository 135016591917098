import { NotificationWarning } from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import { useAlert } from 'components/alert/alert';
import { WarningAlert } from 'components/alert/warning-alert';

import { INotificationWrapperProps, Notification } from './notification';
import styles from './notification.module.css';

interface Props extends INotificationWrapperProps {
  warning: NotificationWarning;
}

export const WarningNotification: React.FC<Props> = ({ notification, warning }) => {
  const setAlert = useAlert();

  return (
    <Notification
      title={warning.title}
      notification={notification}
      onClick={() => {
        if (warning.modal) {
          setAlert(<WarningAlert notification={notification} warning={warning} />, {
            modalClass: styles.WarningModalOverride,
          });
        }
      }}
      clickMessage={warning.cta}
    >
      {warning.text}
    </Notification>
  );
};
