import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { User } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { useAllStudentGroups, useSearchStudents } from 'queries/management';
import { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router-dom';
import Select, { components, DropdownIndicatorProps, SelectInstance } from 'react-select';
import { getStudentGroupId } from 'utils/groups';
import { useClickAwayListener, useDebounce } from 'utils/hooks';

import styles from './student-search.module.css';

const MIN_QUERY_CHARS = 3;

type Option = {
  label: string;
  value: string;
  studentGroupID: string;
};

const DropdownIndicator = (props: DropdownIndicatorProps<Option, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faSearch} fixedWidth={true} className={styles.GreyText} />
    </components.DropdownIndicator>
  );
};

const formatOptionLabel = (query: string, option: Option, groups: Group[]) => {
  const group = groups?.find(g => getStudentGroupId(g) === option.studentGroupID);

  return (
    <div className={styles.OptionLabel}>
      <Highlighter
        searchWords={[query]}
        textToHighlight={option.label}
        className={styles.StudentName}
        highlightClassName={styles.Highlight}
      />
      {group && <div className={styles.GreyText}>{group.displayName}</div>}
    </div>
  );
};

const formatSearchResults = (users: User[]) => {
  return users.map(user => ({
    label: `${user.firstName} ${user.lastName}`,
    value: user.userId,
    studentGroupID: user.studentGroupId,
  }));
};

export const StudentSearch: React.FC = () => {
  const navigate = useNavigate();
  const [selectRef, setSelectRef] = useState<SelectInstance<Option> | null>();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const { data: groups } = useAllStudentGroups();
  const { data: searchResult, isLoading } = useSearchStudents(debouncedQuery);
  const options: Option[] = searchResult ? formatSearchResults(searchResult.students) : [];

  useClickAwayListener(
    setIsMobileMenuOpen,
    isMobileMenuOpen,
    (ev: MouseEvent) => !(ev.target instanceof Element && ev.target.closest('#mobile-search')),
  );

  useHotkeys(
    '/',
    e => {
      if (selectRef) {
        e.preventDefault();
        selectRef.focus();
      }
    },
    [selectRef],
  );

  const select = ({ autoFocus = false }: { autoFocus: boolean }) => (
    <Select
      ref={ref => setSelectRef(ref)}
      isLoading={query.length >= MIN_QUERY_CHARS && isLoading}
      value={null}
      onChange={value => {
        if (value) {
          setIsMobileMenuOpen(false);
          navigate(`/teacher/student/${value.value}/summary`);
        }
      }}
      onInputChange={newValue => setQuery(newValue)}
      className={styles.Select}
      classNamePrefix="react-select"
      options={options}
      blurInputOnSelect={true}
      placeholder="Search for a student"
      menuIsOpen={query.length >= MIN_QUERY_CHARS}
      noOptionsMessage={() => 'No students found'}
      formatOptionLabel={data => formatOptionLabel(query, data, groups || [])}
      components={{ DropdownIndicator, IndicatorSeparator: null }}
      autoFocus={autoFocus}
      tabSelectsValue={false}
    />
  );

  return (
    <>
      <div className={styles.MobileContainer} id="mobile-search">
        <button
          type="button"
          onClick={e => {
            e.stopPropagation();
            setIsMobileMenuOpen(!isMobileMenuOpen);
          }}
          className={styles.Button}
          data-is-menu-open={isMobileMenuOpen}
        >
          <FontAwesomeIcon icon={faSearch} fixedWidth={true} className={styles.GreyText} />
        </button>
        {isMobileMenuOpen && (
          <div className={styles.MobileMenu}>
            <div>{select({ autoFocus: true })}</div>
          </div>
        )}
      </div>
      <div className={styles.DesktopContainer}>{select({ autoFocus: false })}</div>
    </>
  );
};
