import { LeaderboardDateRange } from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { useUser } from 'queries/session';

import { StudentClassLeaderboard } from './student-class-leaderboard';
import { StudentSchoolLeaderboard } from './student-school-leaderboard';
import { StudentYearLeaderboard } from './student-year-leaderboard';
import { TeacherClassLeaderboard } from './teacher-class-leaderboard';
import { TeacherSchoolLeaderboard } from './teacher-school-leaderboard';
import { TeacherYearGroupLeaderboard } from './teacher-year-group-leaderboard';

export type LeaderboardProps = {
  dateRange: LeaderboardDateRange;
  yearGroupId: string;
  setYearGroupId: (yearGroupId: string) => void;
};

type Leaderboard = {
  id: string;
  name: string;
  component: React.FC<LeaderboardProps>;
};

const studentLeaderboards: Leaderboard[] = [
  { id: 'class', name: 'Your Class', component: StudentClassLeaderboard },
  { id: 'year', name: 'Your Year', component: StudentYearLeaderboard },
  { id: 'school', name: 'Your School', component: StudentSchoolLeaderboard },
];

const teacherLeaderboards: Leaderboard[] = [
  { id: 'class', name: 'Class', component: TeacherClassLeaderboard },
  { id: 'year', name: 'Year group', component: TeacherYearGroupLeaderboard },
  { id: 'school', name: 'School', component: TeacherSchoolLeaderboard },
];

export const useLeaderboards = () => {
  const user = useUser();

  return user && user.type === UserType.TEACHER ? teacherLeaderboards : studentLeaderboards;
};
