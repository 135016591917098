import {
  BookReflowable,
  Section,
  SectionType,
  Span,
} from '@sparx/api/apis/sparx/reading/books/v2/book_v2';
import { IBookV2Options } from 'components/bookv2/bookv2';
import styles from 'components/bookv2/bookv2.module.css';

export interface IBookSection extends Section {
  startID?: string;
  endID?: string;
}

export interface IBookPart {
  section?: IBookSection;
  node?: React.ReactNode;
}

export interface IBuildBookPartsResult {
  parts: IBookPart[];
  // Whether any parts before the main text were added
  addedBefore?: boolean;
  // Whether any parts after the main text were added
  addedAfter?: boolean;
}

/**
 * Builds parts from a book from some options.
 */
export const buildReflowableParts = (
  book: BookReflowable | undefined,
  options: IBookV2Options,
): IBuildBookPartsResult => {
  const output: IBookPart[] = [];

  const firstPartID = book && book.body?.sections[0]?.sectionId;
  const lastPartID = book && getReflowableLastPartID(book);

  let running = !options.startID;
  let ended = false;
  let before: IBookPart[] = [];
  let after = options.afterSections || 0;
  let wordCount = 0;

  let addedBefore = false;
  let addedAfter = false;

  const sections = book?.body?.sections || [];
  for (const section of sections) {
    let startSpan: Span | undefined, endSpan: Span | undefined;
    const _injectedElements: { spanID: string; node: React.ReactNode }[] = [];

    if (section.content.oneofKind === 'paragraph') {
      const spans = section.content.paragraph.spans;
      for (const span of spans) {
        // Check if this is the span we should start on
        if (span.spanId === options.startID) {
          startSpan = span;
        }
        // Check if this is the span we should end on
        if (span.spanId === options.endID) {
          endSpan = span;
        }
        // See if there is an injected element for this span
        const injected = options.injectedElements?.[span.spanId];
        if (injected) {
          _injectedElements.push({ spanID: span.spanId, node: injected });
        }
      }
    }

    // If this section is the startID or if it contains a span which is the
    // start span then we start running.
    if (section.sectionId === options.startID || startSpan) {
      running = true;
    }

    // If we have encountered the section that matches the endID, we can
    // stop adding sections now.
    if (section.sectionId === options.endID) {
      running = false;
      ended = true;
    }

    // If we have an injected element for this section we inject it before
    // all the sections content which may be split.
    let sectionInjected = options.injectedElements?.[section.sectionId];
    if (!sectionInjected && section.sectionId === firstPartID) {
      // If the first section is the startID, we see if there is an injected
      // for the start of the book (empty string).
      sectionInjected = options.injectedElements?.[''];
    }
    if (sectionInjected && section.sectionId !== lastPartID) {
      // Note, if this is the last section then we add injected elements
      // after the section instead (see below).
      output.push({ node: sectionInjected });
    }

    if (running) {
      if (before.length > 0) {
        // If there are sections before we started running then we add them.
        // The list is only added to if we are not running.
        output.unshift(...before);
        addedBefore = true;
        before = [];
      }

      // Splice the section with the injected elements and add the result
      // to the output.
      let parts: IBookPart[] = [
        {
          section: {
            ...section,
            startID: startSpan?.spanId,
            endID: endSpan?.spanId,
          },
        },
      ];

      for (const injected of _injectedElements) {
        const where = injected.spanID === lastPartID ? 'after' : 'before';
        parts = injectNodeIntoParts(parts, injected.spanID, injected.node, where);
      }
      output.push(...parts);

      // Check if we are showing word counts and then add the word counter
      // node to the output if so.
      if (
        options.showWordCounts &&
        // TODO: RDR-873
        // This implementation is problematic because the word count excludes valid SectionTypes
        // such as POEM or BLOCK_QUOTE.
        section.type === SectionType.NORMAL &&
        section.content.oneofKind === 'paragraph'
      ) {
        // Count the number of words in this paragraph by summing the word count
        // over all the paragraphs spans.
        const spans = section.content.paragraph.spans;
        const sectionWords = spans.reduce((p, s) => (p += s.wordCount), 0) || 0;

        // Only show the word count if we have increased the rolling word count.
        // This prevents it from showing on images.
        if (sectionWords > 0) {
          wordCount += sectionWords;
          output.push({
            node: <div className={styles.WordCount}>{wordCount.toLocaleString()}</div>,
          });
        }
      }
    } else if (!ended) {
      // We are here if we have not started running yet, so we need to cache
      // the sections that come before we start. We truncate the list whenever
      // we add a new section.
      if (options.beforeSections) {
        before = [
          ...before.splice(-options.beforeSections),
          {
            section: { ...section, startID: options.startID },
          },
        ];
      }
    } else if (ended && after > 0) {
      // We are here if we have finished running, but still have sections
      // to append after the in focus text. We add these sections until the
      // counter is 0.
      addedAfter = true;
      output.push({
        section: { ...section, startID: options.startID },
      });
      after--;
    } else {
      // We have finished outputting the in-focus text and the after sections,
      // so we can exit now
      break;
    }

    // Add injected elements after the section if it is the last section.
    if (sectionInjected && section.sectionId === lastPartID) {
      output.push({ node: sectionInjected });
    }

    // If we have encountered the section that matches the endID, we can stop
    // adding future sections.
    if (endSpan) {
      running = false;
      ended = true;
    }
  }
  return { parts: output, addedAfter, addedBefore };
};

/**
 * Splits a section with paragraph content by a span.
 *
 * If the span is found in the paragraph then two sections will be returned,
 * with the span in question being in the latter section. If the span is not
 * found then the single section is returned.
 */
export const splitSectionBySpan = (
  section: Section,
  spanID: string,
  where: 'before' | 'after' = 'before',
) => {
  // We cannot split a section that does not have a paragraph.
  if (section.content.oneofKind !== 'paragraph' || !section.content.paragraph) {
    return [section];
  }

  // Create two copies of the section but with no spans.
  const before: Section & { content: { oneofKind: 'paragraph' } } = {
    ...section,
    content: {
      oneofKind: 'paragraph',
      paragraph: { style: section.content.paragraph.style, spans: [] },
    },
  };
  const after: Section & { content: { oneofKind: 'paragraph' } } = {
    ...section,
    content: {
      oneofKind: 'paragraph',
      paragraph: { style: section.content.paragraph.style, spans: [] },
    },
  };

  // Iterate through the spans and re-add them to the before section
  // until we find the one we are looking for. We then start adding
  // to the after section instead.
  let found = false;
  for (const span of section.content.paragraph.spans) {
    if (span.spanId === spanID && where === 'before') {
      // We have encountered the span we are looking for
      found = true;
    }
    if (!found) {
      // Add to the section before the split
      before.content.paragraph.spans.push(span);
    } else {
      // Add to the section after the split
      after.content.paragraph.spans.push(span);
    }
    if (span.spanId === spanID && where === 'after') {
      // We have encountered the span we are looking for
      found = true;
    }
  }

  // If we did not find the span to split on, then just return the first
  // section as 'after' does not have any content in it.
  if (!found) {
    return [before];
  }

  // Ensure each split section has a unique id.
  before.sectionId += '/a';
  after.sectionId += '/b';
  return [before, after];
};

/**
 * Takes an array of IBookPart and splits one if it contains a span with
 * the spanID, and will add the node as a BookPart in between.
 */
export const injectNodeIntoParts = (
  parts: IBookPart[],
  spanID: string,
  node: React.ReactNode,
  where: 'before' | 'after' = 'before',
) => {
  const output: IBookPart[] = [];
  for (const part of parts) {
    // We can only split parts with sections. If we don't have a section
    // it is likely we have a node instead.
    if (part.section) {
      // Try to split the section by the span. If the span is not found
      // then the section will be returned.
      const split = splitSectionBySpan(part.section, spanID, where);

      // Iterate the split sections. The array should usually only contain
      // one or two sections.
      for (const i in split) {
        const spanSection = split[i];

        // If we are at index 1 then we have >1 sections as a result of
        // the splitting, meaning that the span was found. Here we inject
        // the node before this section.
        if (i === '1') {
          output.push({ node });
        }
        output.push({
          section: {
            ...part.section,
            ...spanSection,
          },
        });
      }
    } else {
      // Add the IBookPart to the output as is.
      output.push(part);
    }
  }
  return output;
};

/**
 * Returns the final part id for a book. This can be a spanID or a sectionID.
 */
export const getReflowableLastPartID = (book: BookReflowable) => {
  const sections = book.body?.sections || [];
  const lastSection = sections[sections.length - 1];

  return (
    (lastSection?.content.oneofKind === 'paragraph' &&
      lastSection.content.paragraph?.spans[lastSection?.content.paragraph?.spans.length - 1]
        ?.spanId) ||
    lastSection?.sectionId
  );
};
