import { faComments, faNewspaper, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FCWithOptionalChildren } from 'utils/props';

import styles from './wizard-steps.module.css';

export type Step = 'read' | 'record' | 'question';

const getIconAndText = (step: Step) => {
  switch (step) {
    case 'read':
      return { icon: faNewspaper, text: 'Read' };
    case 'record':
      return { icon: faPenToSquare, text: 'Record' };
    case 'question':
      return { icon: faComments, text: 'Question' };
  }
};

export const WizardSteps: FCWithOptionalChildren = ({ children }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Steps}>{children}</div>
    </div>
  );
};

export const WizardStep = ({
  active,
  done,
  includeLine,
  step,
}: {
  active: boolean;
  done: boolean;
  includeLine: boolean;
  step: Step;
}) => {
  const { icon, text } = getIconAndText(step);
  return (
    <>
      <div className={styles.IconAndText}>
        <div
          className={classNames(styles.Icon, {
            [styles.IconActive]: active,
            [styles.IconDone]: done,
          })}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className={classNames(styles.Text, { [styles.TextActive]: active })}>{text}</div>
      </div>
      {includeLine && <div className={classNames(styles.Line, { [styles.LineDone]: done })} />}
    </>
  );
};
