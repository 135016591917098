import { SentimentRating } from '@sparx/api/apis/sparx/interaction/feedback/v1/feedback';
import { AnalyticEventFields } from 'components/client-events/client-event-provider';

const CATEGORY = 'sentiment-feedback';

export const clickedSentimentRating = (sentimentRating: SentimentRating): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked sentiment rating',
  labels: { section: 'sentiment', sentimentRating },
});

export const clickedBack = (comment: string): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked back',
  labels: { section: 'comment', comment },
});
export const clickedSubmit = (comment: string): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked submit',
  labels: { section: 'comment', comment },
});
export const typedComment = (comment: string): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'typed comment',
  labels: { section: 'comment', comment },
});

export const clickedNoCall = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked no call',
  labels: { section: 'book a call' },
});
export const clickedBookCall = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked book call',
  labels: { section: 'book a call' },
});

export const maximised = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'maximised',
});
export const minimised = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'minimised',
});
