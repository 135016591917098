import { UserDisplayData } from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { Modal } from '@sparx/sparx-design/components/modal/Modal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { userDisplayClient } from 'api';
import { Button } from 'components/buttons';
import { LEAGUE_TABLE_QUERY_KEY, useGetUserDisplayDataForCurrentUser } from 'queries/leagueTables';
import { FC, useState } from 'react';

import styles from './league-view.module.css';

export const LeaveLeagueTables = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { data } = useGetUserDisplayDataForCurrentUser();

  const client = useQueryClient();
  const { mutate: confirmLeave } = useMutation(
    (data: UserDisplayData) => {
      const optedOutProducts = new Set(data.optedOutProducts || []);
      optedOutProducts.add(Product.SPARX_READER);
      return userDisplayClient.updateUserDisplayDataForCurrentUser({
        userDisplayData: {
          ...data,
          optedOutProducts: Array.from(optedOutProducts),
        },
        updateMask: { paths: ['opted_out_products'] },
      }).response;
    },
    {
      onSuccess: () => {
        setShowConfirm(false);
        client.resetQueries([LEAGUE_TABLE_QUERY_KEY]).catch(console.error);
      },
    },
  );

  return (
    <>
      <ConfirmLeaveModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => data && confirmLeave(data)}
      />
      <Button
        onClick={() => setShowConfirm(true)}
        analyticsEvent={{ category: 'league table', action: 'open leave confirmation modal' }}
        variant="secondary"
        color="danger"
        size="small"
      >
        Leave
      </Button>
    </>
  );
};

type ConfirmLeaveModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmLeaveModal: FC<ConfirmLeaveModalProps> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content width="xl">
        <Modal.Title align="center">Are you sure you want to leave the Leaderboard?</Modal.Title>
        <Modal.Body className={styles.AlignCentre}>
          <p>
            You will not be able to view the leaderboard after you leave, and you will no longer be
            visible to others on the leaderboard.
          </p>
        </Modal.Body>
        <Modal.Footer align="center">
          <Button
            variant="secondary"
            onClick={onClose}
            analyticsEvent={{ category: 'league table', action: 'cancel leave league table' }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            analyticsEvent={{ category: 'league table', action: 'confirm leave league table' }}
            color="danger"
          >
            I&apos;m sure I want to leave
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
