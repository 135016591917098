import { TextRun } from '@sparx/api/apis/sparx/reading/books/v2/book_v2';
import classNames from 'classnames';
import { isWordBlocked } from 'components/contextual-definitions/blocklist';
import { CADContext } from 'components/contextual-definitions/definition-provider';
import { useEffect, useMemo, useRef, useState } from 'react';

import styles from './selectable-word.module.css';
import { splitStringIntoSelectableWords } from './split-into-words';

interface ISelectableWordProps {
  children: string;
  partId: string;
  cadContext: CADContext;
}

export const SelectableWord = ({ children, partId, cadContext }: ISelectableWordProps) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(cadContext.selected === ref.current);
  }, [cadContext.selected]);

  const blocked = useMemo(() => isWordBlocked(children), [children]);

  return (
    <span
      ref={ref}
      className={classNames(styles.Word, {
        [styles.SelectableWord]: !blocked && !cadContext.disabled,
        [styles.SelectableWordSelected]: isSelected,
      })}
      onClick={e => {
        if (!cadContext.disabled) {
          e.stopPropagation();
          ref.current && cadContext.selectWord(ref.current, partId);
        }
      }}
    >
      {children}
    </span>
  );
};

export const SplitIntoSelectableWords = ({
  cadContext,
  run,
  spanId,
  splitWords,
}: {
  cadContext: CADContext | undefined;
  run: TextRun;
  spanId: string;
  splitWords: boolean;
}) => {
  if (splitWords && cadContext) {
    const splitWords = splitStringIntoSelectableWords(run);
    return splitWords.map((word, idx) =>
      word.isSelectable ? (
        <SelectableWord key={idx} partId={spanId} cadContext={cadContext}>
          {word.value}
        </SelectableWord>
      ) : (
        word.value
      ),
    );
  }
  return run.content;
};
