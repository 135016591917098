import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { Switch } from '@sparx/sparx-design/components/switch/Switch';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { endOfWeek, Interval, startOfWeek, subWeeks } from 'date-fns';
import { useListHomeworkViews } from 'queries/homework';
import { useEnglishStudentGroups } from 'queries/management';
import { useHomeworkDueBetween } from 'queries/tasks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DepartmentWidget } from '../department-widget';
import { openedModal } from './events';
import { UnviewedHomeworkModal } from './unviewed-homework-modal';
import styles from './unviewed-homework-widget.module.css';

enum Week {
  LAST_WEEK,
  THIS_WEEK,
}

const intervalForWeek = (week: Week): Interval =>
  week === Week.THIS_WEEK
    ? { start: startOfWeek(new Date()), end: endOfWeek(new Date()) }
    : { start: startOfWeek(subWeeks(new Date(), 1)), end: endOfWeek(subWeeks(new Date(), 1)) };

const useUniqueClassHomework = (week: Week) => {
  const {
    data: studentGroups,
    isLoading: isLoadingStudentGroups,
    isError: isErrorStudentGroups,
  } = useEnglishStudentGroups();
  const {
    data: homeworks,
    isLoading: isLoadingHomework,
    isError: isErrorHomework,
  } = useHomeworkDueBetween(intervalForWeek(week));

  const uniqueClassHomeworks: Record<string, Homework> = {};
  (homeworks || []).forEach(homework => {
    const studentGroup = studentGroups?.find(
      group => group.name.split('/')[3] === homework.studentGroupId,
    );

    if (!studentGroup) return;

    if (!uniqueClassHomeworks[homework.studentGroupId]) {
      uniqueClassHomeworks[homework.studentGroupId] = homework;
    }
  });

  const isLoading = isLoadingStudentGroups || isLoadingHomework;
  const isError = isErrorStudentGroups || isErrorHomework;

  return {
    data: Object.values(uniqueClassHomeworks),
    isLoading,
    isError,
  };
};

export const UnviewedHomeworkWidget = () => {
  const [week, setWeek] = useState(Week.THIS_WEEK);
  const navigate = useNavigate();
  const { sendEvent } = useClientEvent();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: homework,
    isLoading: isLoadingHomework,
    isError: isErrorHomework,
  } = useUniqueClassHomework(week);
  const {
    data: homeworkViews,
    isLoading: isLoadingHomeworkViews,
    isError: isErrorHomeworkViews,
  } = useListHomeworkViews(homework.map(h => h.homeworkId));

  const unviewedHomework = homework.filter(
    homework => homeworkViews?.homeworkViews[homework.homeworkId].teacherIds.length === 0,
  );

  const openModal = () => {
    sendEvent(openedModal());
    setIsModalOpen(true);
  };

  const isLoading = isLoadingHomework || isLoadingHomeworkViews;
  const isError = isErrorHomework || isErrorHomeworkViews;

  if (isError) return;

  const proportion =
    homework.length > 0 ? (homework.length - unviewedHomework.length) / homework.length : 0;

  return (
    <>
      <UnviewedHomeworkModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        homeworks={unviewedHomework}
      />

      <DepartmentWidget
        label={
          <div className={styles.LabelContainer}>
            <span>Hand-in page usage</span>
            <div className={styles.SwitchContainer}>
              <Switch
                id="unviewed-homework-week-switch"
                labelLeft="Last week"
                labelRight="This week"
                checked={week === Week.THIS_WEEK}
                onCheckedChange={() =>
                  setWeek(week => (week === Week.THIS_WEEK ? Week.LAST_WEEK : Week.THIS_WEEK))
                }
              />
            </div>
          </div>
        }
        title={`${homework.length - unviewedHomework.length}/${homework.length} classes`}
        proportion={proportion}
        thresholds={[0.5, 1]}
        icon={faSliders}
        subtitle={`had their Hand-in page viewed for ${week === Week.THIS_WEEK ? 'this' : 'last'} week's homework`}
        body="Our data shows that viewing the hand-in page regularly has a huge impact on completion rates!"
        buttonText={unviewedHomework.length > 0 ? 'Show classes' : 'View Hand-in'}
        buttonAction={
          unviewedHomework.length > 0 ? openModal : () => navigate('/teacher/classes/homework')
        }
        className={styles.Widget}
        loading={isLoading}
      />
    </>
  );
};
