// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/vocab/v1/vocab.proto" (package "sparx.reading.content.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Homework } from '../../tasks/v1/homework';
import { ExperienceUpdate } from '../../users/v1/experience';
/**
 * @generated from protobuf message sparx.reading.content.v1.WordDefinition
 */
export interface WordDefinition {
  /**
   * @generated from protobuf field: string definition = 1;
   */
  definition: string;
  /**
   * @generated from protobuf field: string usage = 2;
   */
  usage: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetStateRequest
 */
export interface GetStateRequest {}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetStateResponse
 */
export interface GetStateResponse {
  /**
   * @generated from protobuf field: sparx.reading.content.v1.VocabState state = 1;
   */
  state?: VocabState;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetQuestionRequest
 */
export interface GetQuestionRequest {}
/**
 * @generated from protobuf message sparx.reading.content.v1.GetQuestionResponse
 */
export interface GetQuestionResponse {
  /**
   * @generated from protobuf field: string word = 1;
   */
  word: string;
  /**
   * @generated from protobuf field: sparx.reading.content.v1.WordDefinition definition = 2;
   */
  definition?: WordDefinition;
  /**
   * @generated from protobuf field: string choices = 3;
   */
  choices: string; // letters to choose from
  /**
   * @generated from protobuf field: sparx.reading.content.v1.VocabState state = 4;
   */
  state?: VocabState;
  /**
   * @generated from protobuf field: int32 max_position = 5;
   */
  maxPosition: number;
  /**
   * TODO?
   *
   * @generated from protobuf field: string answer = 20;
   */
  answer: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.SubmitAnswerRequest
 */
export interface SubmitAnswerRequest {
  /**
   * @generated from protobuf field: string answer = 1;
   */
  answer: string;
  /**
   * @generated from protobuf field: bool skip = 2;
   */
  skip: boolean;
  /**
   * @generated from protobuf field: int32 position = 3;
   */
  position: number;
  /**
   * @generated from protobuf field: string input_type = 4;
   */
  inputType: string;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.SubmitAnswerResponse
 */
export interface SubmitAnswerResponse {
  /**
   * Whether they got the question correct or not
   *
   * @generated from protobuf field: bool correct = 1;
   */
  correct: boolean;
  /**
   * Continue flag is set if they should request a new question. Usually if they skip/get correct.
   *
   * @generated from protobuf field: bool continue = 2;
   */
  continue: boolean;
  /**
   * @generated from protobuf field: sparx.reading.users.v1.ExperienceUpdate experience_update = 3;
   */
  experienceUpdate?: ExperienceUpdate;
  /**
   * @generated from protobuf field: repeated sparx.reading.tasks.v1.Homework homeworks = 4;
   */
  homeworks: Homework[];
}
/**
 * @generated from protobuf message sparx.reading.content.v1.VocabState
 */
export interface VocabState {
  /**
   * @generated from protobuf oneof: algo
   */
  algo:
    | {
        oneofKind: 'simpleAlgo';
        /**
         * @generated from protobuf field: sparx.reading.content.v1.VocabStateSimpleAlgo simple_algo = 1;
         */
        simpleAlgo: VocabStateSimpleAlgo;
      }
    | {
        oneofKind: 'bucketAlgo';
        /**
         * @generated from protobuf field: sparx.reading.content.v1.VocabStateBucketAlgo bucket_algo = 2;
         */
        bucketAlgo: VocabStateBucketAlgo;
      }
    | {
        oneofKind: undefined;
      };
}
/**
 * @generated from protobuf message sparx.reading.content.v1.VocabStateSimpleAlgo
 */
export interface VocabStateSimpleAlgo {
  /**
   * @generated from protobuf field: int32 position = 1;
   */
  position: number;
  /**
   * @generated from protobuf field: int32 current_word_position = 2;
   */
  currentWordPosition: number;
  /**
   * @generated from protobuf field: repeated int64 seen = 3;
   */
  seen: number[];
  /**
   * @generated from protobuf field: bool attempted_current = 4;
   */
  attemptedCurrent: boolean;
  /**
   * @generated from protobuf field: repeated int64 seen_today = 10;
   */
  seenToday: number[];
  /**
   * Date in format YYYY-MM-DD
   *
   * @generated from protobuf field: string last_attempted = 11;
   */
  lastAttempted: string;
  /**
   * @generated from protobuf field: int32 attempted_today = 12;
   */
  attemptedToday: number;
}
/**
 * @generated from protobuf message sparx.reading.content.v1.VocabStateBucketAlgo
 */
export interface VocabStateBucketAlgo {
  /**
   * Current position they are out of all words.
   *
   * @generated from protobuf field: int32 current_word_position = 1;
   */
  currentWordPosition: number;
  /**
   * Current bucket the student is in.
   *
   * @generated from protobuf field: int32 bucket = 2;
   */
  bucket: number;
  /**
   * Map of bucket to position. Position will loop around if over the
   * maximum position in a bucket.
   *
   * @generated from protobuf field: map<int32, int32> bucket_position = 3;
   */
  bucketPosition: {
    [key: number]: number;
  };
  /**
   * @generated from protobuf field: bool attempted_current = 4;
   */
  attemptedCurrent: boolean;
  /**
   * Date in format YYYY-MM-DD
   *
   * @generated from protobuf field: string last_attempted = 10;
   */
  lastAttempted: string;
  /**
   * @generated from protobuf field: int32 attempted_today = 11;
   */
  attemptedToday: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class WordDefinition$Type extends MessageType<WordDefinition> {
  constructor() {
    super('sparx.reading.content.v1.WordDefinition', [
      { no: 1, name: 'definition', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'usage', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.WordDefinition
 */
export const WordDefinition = new WordDefinition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStateRequest$Type extends MessageType<GetStateRequest> {
  constructor() {
    super('sparx.reading.content.v1.GetStateRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetStateRequest
 */
export const GetStateRequest = new GetStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStateResponse$Type extends MessageType<GetStateResponse> {
  constructor() {
    super('sparx.reading.content.v1.GetStateResponse', [
      { no: 1, name: 'state', kind: 'message', T: () => VocabState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetStateResponse
 */
export const GetStateResponse = new GetStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQuestionRequest$Type extends MessageType<GetQuestionRequest> {
  constructor() {
    super('sparx.reading.content.v1.GetQuestionRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetQuestionRequest
 */
export const GetQuestionRequest = new GetQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQuestionResponse$Type extends MessageType<GetQuestionResponse> {
  constructor() {
    super('sparx.reading.content.v1.GetQuestionResponse', [
      { no: 1, name: 'word', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'definition', kind: 'message', T: () => WordDefinition },
      { no: 3, name: 'choices', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'state', kind: 'message', T: () => VocabState },
      {
        no: 5,
        name: 'max_position',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 20, name: 'answer', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.GetQuestionResponse
 */
export const GetQuestionResponse = new GetQuestionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitAnswerRequest$Type extends MessageType<SubmitAnswerRequest> {
  constructor() {
    super('sparx.reading.content.v1.SubmitAnswerRequest', [
      { no: 1, name: 'answer', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'skip', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'position', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'input_type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.SubmitAnswerRequest
 */
export const SubmitAnswerRequest = new SubmitAnswerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitAnswerResponse$Type extends MessageType<SubmitAnswerResponse> {
  constructor() {
    super('sparx.reading.content.v1.SubmitAnswerResponse', [
      { no: 1, name: 'correct', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 2, name: 'continue', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 3,
        name: 'experience_update',
        kind: 'message',
        T: () => ExperienceUpdate,
      },
      {
        no: 4,
        name: 'homeworks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Homework,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.SubmitAnswerResponse
 */
export const SubmitAnswerResponse = new SubmitAnswerResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VocabState$Type extends MessageType<VocabState> {
  constructor() {
    super('sparx.reading.content.v1.VocabState', [
      {
        no: 1,
        name: 'simple_algo',
        kind: 'message',
        oneof: 'algo',
        T: () => VocabStateSimpleAlgo,
      },
      {
        no: 2,
        name: 'bucket_algo',
        kind: 'message',
        oneof: 'algo',
        T: () => VocabStateBucketAlgo,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.VocabState
 */
export const VocabState = new VocabState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VocabStateSimpleAlgo$Type extends MessageType<VocabStateSimpleAlgo> {
  constructor() {
    super('sparx.reading.content.v1.VocabStateSimpleAlgo', [
      { no: 1, name: 'position', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 2,
        name: 'current_word_position',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'seen',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 4,
        name: 'attempted_current',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 10,
        name: 'seen_today',
        kind: 'scalar',
        repeat: 1 /*RepeatType.PACKED*/,
        T: 3 /*ScalarType.INT64*/,
        L: 2 /*LongType.NUMBER*/,
      },
      {
        no: 11,
        name: 'last_attempted',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 12,
        name: 'attempted_today',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.VocabStateSimpleAlgo
 */
export const VocabStateSimpleAlgo = new VocabStateSimpleAlgo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VocabStateBucketAlgo$Type extends MessageType<VocabStateBucketAlgo> {
  constructor() {
    super('sparx.reading.content.v1.VocabStateBucketAlgo', [
      {
        no: 1,
        name: 'current_word_position',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 2, name: 'bucket', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'bucket_position',
        kind: 'map',
        K: 5 /*ScalarType.INT32*/,
        V: { kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      },
      {
        no: 4,
        name: 'attempted_current',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 10,
        name: 'last_attempted',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 11,
        name: 'attempted_today',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.content.v1.VocabStateBucketAlgo
 */
export const VocabStateBucketAlgo = new VocabStateBucketAlgo$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.content.v1.Vocab
 */
export const Vocab = new ServiceType('sparx.reading.content.v1.Vocab', [
  { name: 'GetState', options: {}, I: GetStateRequest, O: GetStateResponse },
  {
    name: 'GetQuestion',
    options: {},
    I: GetQuestionRequest,
    O: GetQuestionResponse,
  },
  {
    name: 'SubmitAnswer',
    options: {},
    I: SubmitAnswerRequest,
    O: SubmitAnswerResponse,
  },
]);
