import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from 'components/contextual-definitions/definition-provider.module.css';
import { useCallback, useEffect, useState } from 'react';
import { Flags, useBooleanFlag } from 'utils/featureflags';

interface SpeakButtonProps {
  word: string;
  onPlay?: () => void;
}

export const SpeakButton = ({ word, onPlay }: SpeakButtonProps) => {
  const isEnabled = useBooleanFlag(Flags.ContextAwareDefinitionsAudio);

  const [voice, setVoice] = useState<SpeechSynthesisVoice>();
  const updateVoice = useCallback(() => {
    const filterPreferences: ((voice: SpeechSynthesisVoice) => boolean)[] = [
      // Prefer any Google voice (Chrome)
      voice => voice.lang === 'en-GB' && voice.name.includes('Google'),
      // Prefer these Apple voices (Safari/Firefox OSX)
      voice => voice.lang === 'en-GB' && voice.name.includes('com.apple.voice.compact.'),
      // Prefer GB voices
      voice => voice.lang === 'en-GB',
      // Choose any available voice
      () => true,
    ];

    const voices = speechSynthesis.getVoices();
    for (const filter of filterPreferences) {
      const filtered = voices.filter(filter);
      if (filterPreferences.length > 0) {
        // Choose a random filtered voice
        setVoice(filtered[Math.floor(Math.random() * filtered.length)]);
        return;
      }
    }
    return null;
  }, [setVoice]);

  useEffect(() => {
    updateVoice();
    speechSynthesis.onvoiceschanged = updateVoice;
  }, [updateVoice]);

  const [speaking, setSpeaking] = useState(false);
  if (!speechSynthesis || !voice || !word || !isEnabled) {
    return null; // not enabled
  }

  const speak = () => {
    if (!speechSynthesis.speaking) {
      const utterance = new SpeechSynthesisUtterance(word);
      utterance.voice = voice;

      setSpeaking(true);
      utterance.addEventListener('end', () => setSpeaking(false));

      console.log('Spoke word', word, 'with voice', voice);
      speechSynthesis.speak(utterance);
      onPlay?.();
    }
  };

  return (
    <div
      onClick={speak}
      className={classNames(styles.SpeakButton, speaking && styles.SpeakButtonActive)}
    >
      <FontAwesomeIcon icon={faVolumeHigh} />
    </div>
  );
};
