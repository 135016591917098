export type SingleSignOnConfig = {
  title: string;
  text: string;
};

export const SingleSignOnConfigs: Record<string, SingleSignOnConfig> = {
  AllEnabled: {
    title: 'enabled for teachers and students',
    text: 'edit this setting.',
  },
  AllDisabled: {
    title: 'disabled',
    text: 'enable this for teachers & students.',
  },
  TeacherEnabled: {
    title: 'enabled for teachers only',
    text: 'enable this for students.',
  },
  StudentEnabled: {
    title: 'enabled for students only',
    text: 'enable this for teachers.',
  },
};
