import { WordDefinitionRating } from '@sparx/api/apis/sparx/reading/content/v1/definitions';
import { ConfirmAlert } from 'components/alert/confirm-alert';
import { Checkbox } from 'components/checkbox/checkbox';
import { useDefinitionFeedback } from 'queries/definitions';
import { useState } from 'react';

import styles from './feedback-alert.module.css';

const feedbackReasons = [
  'I keep using it accidentally',
  "I don't find them useful",
  'The definition is wrong',
  "My teacher doesn't want me using them",
  'Other',
];

interface FeedbackAlertProps {
  definitionID: string;
}

export const FeedbackAlert = ({ definitionID }: FeedbackAlertProps) => {
  const [reasonsChecked, setCheckedReasons] = useState<Record<string, boolean>>({});
  const hasReasons = Object.values(reasonsChecked).some(v => v);

  const feedback = useDefinitionFeedback(definitionID);

  return (
    <ConfirmAlert
      title="Tell us why you're having trouble"
      onConfirm={() =>
        feedback.mutate({
          feedback: Object.keys(reasonsChecked)
            .filter(k => reasonsChecked[k])
            .join(', '),
          rating: WordDefinitionRating.WORD_DEFINITION_RATING_UNSPECIFIED,
        })
      }
      size="large"
      confirmText="Confirm"
      rejectText="Cancel"
      closeOnConfirm={true}
      confirmDisabled={!hasReasons}
      // This should stop us dismissing the definition popup when they click in the feedback
      // modal. This doesn't apply to the click-away area.
      onClick={e => e.stopPropagation()}
      confirmAnalytics={undefined}
      rejectAnalytics={undefined}
    >
      <div className={styles.ReasonList}>
        {feedbackReasons.map(reason => (
          <div
            className={styles.Reason}
            key={reason}
            onClick={() =>
              setCheckedReasons({
                ...reasonsChecked,
                [reason]: !reasonsChecked[reason],
              })
            }
          >
            <Checkbox checked={reasonsChecked[reason]} />
            <div className={styles.ReasonText}>{reason}</div>
          </div>
        ))}
      </div>
    </ConfirmAlert>
  );
};
