import { faThumbsDown, faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UserDefinitionState,
  WordDefinitionRating,
} from '@sparx/api/apis/sparx/reading/content/v1/definitions';
import classNames from 'classnames';
import { useAlert } from 'components/alert/alert';
import { Button } from 'components/buttons/button';
import { useClientEvent } from 'components/client-events/client-event-provider';
import {
  SelectionWithRef,
  useCADContext,
} from 'components/contextual-definitions/definition-provider';
import styles from 'components/contextual-definitions/definition-provider.module.css';
import { FeedbackAlert } from 'components/contextual-definitions/feedback-alert';
import { SpeakButton } from 'components/contextual-definitions/speak-button';
import { Loading } from 'components/loading/loading';
import {
  alreadyHaveWord,
  SelectedWord,
  useDefinitionFeedback,
  useUserDefinitionState,
  useWordDefinition,
} from 'queries/definitions';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useClickAwayListener } from 'utils/hooks';

interface WordPopoverProps {
  selected: SelectionWithRef;
  containerRef: HTMLDivElement | null;
  onClose: () => void;
}

export const WordPopover = ({ selected, containerRef, onClose }: WordPopoverProps) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [highlight, setHighlight] = useState<DOMRect>();

  const [offsetLeft, setOffsetLeft] = useState(0);
  const updatePositioning = useCallback(() => {
    const containerBounds = containerRef?.getBoundingClientRect();
    const descriptionBounds = popoverRef.current?.getBoundingClientRect();

    const containerLeft = containerBounds?.left || 0;
    const descriptionLeft = descriptionBounds?.left || 0;
    const containerRight = containerBounds?.right || 0;
    const descriptionRight = descriptionBounds?.right || 0;

    let newOffset = 0;
    if (descriptionLeft < containerLeft) {
      newOffset = containerLeft - descriptionLeft;
    } else if (descriptionRight > containerRight) {
      newOffset = containerRight - descriptionRight;
    }

    if (newOffset !== offsetLeft) {
      setOffsetLeft(newOffset);
    }

    // Update the bounding rectangle
    const rect = selected.ref.getBoundingClientRect();
    rect.y -= containerBounds?.y || 0;
    rect.x -= containerBounds?.x || 0;
    setHighlight(rect);
  }, [containerRef, selected, setOffsetLeft, offsetLeft]);

  // Close the popover if the ref is not in the DOM anymore
  useEffect(() => {
    if (!document.body.contains(selected.ref)) {
      onClose();
    }
  }, [selected.ref, onClose]);

  // Clickaway when they click something that is still mounted and is not
  // within the popover.
  useClickAwayListener(onClose, true, (ev: MouseEvent) => {
    const clickTarget = ev.target instanceof Element ? ev.target : undefined;
    return Boolean(
      clickTarget &&
        popoverRef.current &&
        document.body.contains(clickTarget) &&
        !popoverRef.current?.contains(clickTarget),
    );
  });

  useLayoutEffect(updatePositioning, [updatePositioning]);

  useEffect(() => {
    const onResize = () => updatePositioning();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [updatePositioning]);

  const ctx = useCADContext();
  const [wantWord, setWantWord] = useState(false);
  const { data: state, isLoading } = useUserDefinitionState(ctx?.bookId || '');

  useEffect(() => {
    if (state && !wantWord && selected && ctx?.bookId) {
      const existing = alreadyHaveWord(state, ctx.bookId, selected.word);
      const shouldWantWord = Boolean(existing);
      setWantWord(shouldWantWord);
    }
  }, [ctx?.bookId, state, wantWord, setWantWord, selected]);

  const outOfWords = !wantWord && (state?.wordsRemaining || 0) <= 0;
  const disabled = selected.word.blocked || outOfWords;

  const component = useMemo(() => {
    if (isLoading || !state) {
      return <LoadingState />;
    } else if (selected.word.blocked) {
      return <DisallowedWord onClose={onClose} />;
    } else if (wantWord) {
      return <WordDescription word={selected.word} onClose={onClose} />;
    } else if (outOfWords) {
      return <OutOfWords onClose={onClose} />;
    } else {
      return (
        <RequestWord
          state={state}
          analyticsLabels={{ word: selected.word.word }}
          onRequest={() => setWantWord(true)}
          onClose={() => onClose()}
        />
      );
    }
  }, [isLoading, state, wantWord, outOfWords, selected.word, onClose]);

  if (!highlight) {
    return null;
  }

  return (
    <div
      className={classNames(styles.DescriptionAnchor, disabled && styles.DescriptionAnchorDisabled)}
      style={{
        top: highlight.y + highlight.height,
        left: highlight.x + highlight.width / 2,
      }}
    >
      <div className={styles.DescriptionArrow} />
      <div className={styles.DescriptionPanelContainer} ref={popoverRef}>
        <div className={styles.Description} style={{ left: offsetLeft }}>
          {component}
        </div>
      </div>
    </div>
  );
};

const LoadingState = () => (
  <div className={classNames(styles.DescriptionContent, styles.DescriptionContentCentered)}>
    <Loading className={styles.Loading} />
  </div>
);

const DisallowedWord = ({ onClose }: { onClose: () => void }) => (
  <div className={classNames(styles.DescriptionContent, styles.DescriptionContentCentered)}>
    <CloseButton onClick={onClose} />
    <p className={styles.Instruction}>
      You can&apos;t look this word up. Try to only use it for words you don&apos;t know.
    </p>
    <Button
      size="small"
      onClick={onClose}
      analyticsEvent={{
        category: 'definitions',
        action: 'confirm definition disallowed',
      }}
    >
      Ok
    </Button>
  </div>
);

const OutOfWords = ({ onClose }: { onClose: () => void }) => (
  <div className={classNames(styles.DescriptionContent, styles.DescriptionContentCentered)}>
    <CloseButton onClick={onClose} />
    <p className={styles.Instruction}>
      You have looked up the maximum number of words today. Please check back tomorrow.
    </p>
    <Button
      size="small"
      onClick={onClose}
      analyticsEvent={{
        category: 'definitions',
        action: 'confirm out of words',
      }}
    >
      Ok
    </Button>
  </div>
);

const RequestWord = ({
  state,
  analyticsLabels: labels,
  onRequest,
  onClose,
}: {
  state: UserDefinitionState;
  analyticsLabels: Record<string, string>;
  onRequest: () => void;
  onClose: () => void;
}) => (
  <>
    <div className={classNames(styles.DescriptionContent, styles.DescriptionContentCentered)}>
      <CloseButton onClick={onClose} />
      <p className={styles.Instruction}>Would you like to see what this word means?</p>
      <div className={styles.Buttons}>
        <Button
          size="small"
          variant="secondary"
          onClick={onClose}
          analyticsEvent={{ category: 'definitions', action: 'cancel_request', labels }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          onClick={onRequest}
          analyticsEvent={{ category: 'definitions', action: 'request_word', labels }}
        >
          Show me
        </Button>
      </div>
      <p className={styles.WordsLeft}>
        You can look up <strong>{state.wordsRemaining}</strong> more words today.
      </p>
    </div>
    <div className={styles.DescriptionFooter}>
      <div style={{ flex: 1 }} />
      <FeedbackLink />
    </div>
  </>
);

const WordDescription = ({ word, onClose }: { word: SelectedWord; onClose: () => void }) => {
  const ctx = useCADContext();
  const {
    data: response,
    isLoading,
    isError,
  } = useWordDefinition(ctx?.bookId || '', word, {
    enabled: Boolean(ctx?.bookId),
  });

  const feedback = useDefinitionFeedback(response?.definition?.definitionId || '');
  const feedbackEnabled = Boolean(response?.definition?.definitionId);
  const [submittedFeedback, setSubmittedFeedback] = useState<WordDefinitionRating>();
  const submitFeedback = (rating: WordDefinitionRating) => {
    if (feedbackEnabled && rating !== submittedFeedback) {
      feedback.mutate({ feedback: '', rating });
      setSubmittedFeedback(rating);
    }
  };

  useEffect(() => {
    if (response?.definition?.feedback?.rating) {
      setSubmittedFeedback(response.definition.feedback.rating);
    }
  }, [response?.definition?.feedback?.rating]);

  const { sendEvent } = useClientEvent();
  const definition = response?.definition?.definition || '';

  return (
    <>
      <div className={styles.DescriptionContent}>
        <CloseButton onClick={onClose} />
        <h2>
          {response?.definition?.word || word.word.toLowerCase()}
          <SpeakButton
            word={word.word}
            onPlay={() =>
              sendEvent({
                category: 'definitions',
                action: 'play_word',
                labels: {
                  word: word.word,
                  definitionId: response?.definition?.definitionId || 'unknown',
                },
              })
            }
          />
        </h2>
        <div className={styles.DescriptionDefinition}>
          {isError ? (
            <i>Error loading definition, please try again later.</i>
          ) : isLoading ? (
            <Loading />
          ) : (
            <>
              {definition}
              {/*<SpeakButton*/}
              {/*  word={definition}*/}
              {/*  onPlay={() =>*/}
              {/*    sendEvent({*/}
              {/*      name: 'definitions_play_definition',*/}
              {/*      context: { word: word.word, definition },*/}
              {/*    })*/}
              {/*  }*/}
              {/*/>*/}
            </>
          )}
        </div>
      </div>
      <div className={styles.DescriptionFooter}>
        <div className={styles.Feedback}>
          <span>Helpful?</span>
          <div
            className={classNames(styles.FeedbackButton, styles.FeedbackButtonUp, {
              [styles.FeedbackButtonDisabled]: !feedbackEnabled,
              [styles.FeedbackButtonActive]: submittedFeedback === WordDefinitionRating.HELPFUL,
              [styles.FeedbackButtonInactive]: submittedFeedback === WordDefinitionRating.UNHELPFUL,
            })}
            onClick={() => submitFeedback(WordDefinitionRating.HELPFUL)}
          >
            <FontAwesomeIcon icon={faThumbsUp} />
          </div>
          <div
            className={classNames(styles.FeedbackButton, styles.FeedbackButtonDown, {
              [styles.FeedbackButtonDisabled]: !feedbackEnabled,
              [styles.FeedbackButtonActive]: submittedFeedback === WordDefinitionRating.UNHELPFUL,
              [styles.FeedbackButtonInactive]: submittedFeedback === WordDefinitionRating.HELPFUL,
            })}
            onClick={() => submitFeedback(WordDefinitionRating.UNHELPFUL)}
          >
            <FontAwesomeIcon icon={faThumbsDown} />
          </div>
        </div>
        <div style={{ flex: 1 }} />
        <FeedbackLink definitionID={response?.definition?.definitionId || ''} />
      </div>
      <div className={styles.NewFeature}>
        This is a new feature. Your feedback helps us to improve.
      </div>
    </>
  );
};

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <div onClick={onClick} className={styles.CloseIcon}>
    <FontAwesomeIcon icon={faTimes} />
  </div>
);

const FeedbackLink = ({ definitionID }: { definitionID?: string }) => {
  const alert = useAlert();
  const show = () => alert(<FeedbackAlert definitionID={definitionID || ''} />);
  return (
    <span className={styles.FeedbackLink} onClick={show}>
      Having trouble?
    </span>
  );
};
