import { useClientEvent } from 'components/client-events/client-event-provider';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useChangePageEvents = () => {
  const location = useLocation();
  const { sendEvent } = useClientEvent();
  const [lastPathKey, setLastPathKey] = useState('');
  const [previousPath, setPreviousPath] = useState<string | undefined>(undefined);

  useEffect(() => {
    const pathname = location.pathname + location.search;
    if (location.key !== lastPathKey) {
      setLastPathKey(location.key);
      sendEvent({
        action: 'pageview',
        category: 'change page',
        // Set previousPath if it is not undefined
        labels: { ...(previousPath && { previousPath }), path: pathname },
      });
      setPreviousPath(pathname);
    }
  }, [lastPathKey, setLastPathKey, location, sendEvent, previousPath]);
};
