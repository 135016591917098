import { BaselineAssessmentAnswer } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { useMutation } from '@tanstack/react-query';
import { sessionsClient } from 'api';

import { queryClient } from './client';

export const useSaveBaselineAssessmentAnswers = () =>
  useMutation(
    (answers: BaselineAssessmentAnswer[]) =>
      sessionsClient.saveBaselineAssessmentAnswers({ answers }).response,
    {
      onSuccess: answer => {
        queryClient.setQueryData(['user', 'baselineResponse'], { answers: [answer] });
      },
    },
  );
