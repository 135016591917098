import {
  Box,
  Button,
  Flex,
  IconButton,
  keyframes,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { faCheck, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInSeconds } from 'date-fns';
import { useRef, useState, MutableRefObject } from 'react';

import { useTrainingContext } from '../Context';
import { useCompleteTrainingStep } from '../queries';
import { ModuleStepState } from '../types';

const OUTRO_BUFFER_SECONDS = 5;

const completeAnim = keyframes`
  0% {transform: scale(0, 0);}
  50% {transform: scale(1.1,1.1);}
  100% {rransform: scale(1,1);}
`;

export const TrainingVideoModal = ({
  state,
  onClose: _onClose,
}: {
  state: ModuleStepState;
  onClose: () => void;
}) => {
  const { videoAssetDirURL, sendClientEvent } = useTrainingContext();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { mutate: completeStep } = useCompleteTrainingStep();
  const [feedbackGiven, setFeedbackgiven] = useState(false);

  const videoURL =
    state.spec.kind === 'video' ? `${videoAssetDirURL}${state.spec.video}` : 'not-a-video';

  // values for analytics
  const openTime = useRef(new Date());
  const timeToFirstComplete = useRef<number | undefined>();
  const revisitingStep = useRef(state.complete);
  const currentVideoTime = useRef(0);
  const videoDuration = useRef(0);

  const analyticsLabels = () => ({
    moduleName: state.module.spec.name,
    stepID: state.spec.id,
    videoURL: videoURL,
    modalOpenSecs: differenceInSeconds(new Date(), openTime.current).toString(),
    stepAlreadyComplete: revisitingStep.current ? 'true' : 'false',
    videoDuration: videoDuration.current.toString(),
    videoPosition: currentVideoTime.current.toString(),
    ...(timeToFirstComplete.current !== undefined
      ? { secsToComplete: timeToFirstComplete.current.toString() }
      : undefined),
    completedVideo: timeToFirstComplete.current !== undefined ? 'true' : 'false',
  });

  const onClose = () => {
    sendClientEvent(
      {
        action: 'close_video_modal',
        category: 'training',
      },
      analyticsLabels(),
    );
    _onClose();
  };

  const giveFeedback = (positive: boolean) => {
    setFeedbackgiven(true);
    sendClientEvent(
      {
        action: `video_opinion`,
        category: 'training',
      },
      {
        opionion: positive ? 'like' : 'dislike',
        ...analyticsLabels(),
      },
    );
  };

  if (state.spec.kind !== 'video') {
    console.error('training video component used for non-video step');
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      isCentered={true}
      size={{ base: '2xl', lg: '3xl', xl: '4xl' }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center" pb={3}>
          {state.spec.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" position="relative" pt={0}>
          {state.spec.description && <Text mb={4}>{state.spec.description}</Text>}
          <Flex flexDirection={'column'} mb={3}>
            <video
              ref={videoRef}
              src={videoURL}
              controls={true}
              poster={state.spec.videoCover && `${videoAssetDirURL}${state.spec.videoCover}`}
              style={{
                width: '100%',
                maxHeight: '70vh',
                background: 'gray',
              }}
              crossOrigin="anonymous"
              autoPlay={true}
              onLoadedMetadata={e => {
                videoDuration.current = e.currentTarget.duration;
              }}
              onTimeUpdate={e => {
                currentVideoTime.current = e.currentTarget.currentTime;
                if (
                  timeToFirstComplete.current === undefined &&
                  e.currentTarget.currentTime >= e.currentTarget.duration - OUTRO_BUFFER_SECONDS
                ) {
                  completeStep(state);
                  timeToFirstComplete.current = differenceInSeconds(new Date(), openTime.current);
                }
              }}
            >
              {state.spec.videoSubs && (
                <track
                  label="English"
                  kind="subtitles"
                  srcLang="en"
                  src={`${videoAssetDirURL}${state.spec.videoSubs}`}
                  default={false}
                />
              )}
            </video>
            <Box textAlign="right" height={5}>
              <Text display="flex" alignItems="center" justifyContent="center" height={12} as="div">
                <VideoSubtext
                  state={state}
                  feedbackGiven={feedbackGiven}
                  giveFeedback={giveFeedback}
                  timeToFirstComplete={timeToFirstComplete}
                />
              </Text>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter justifyContent="center">
          {state.complete && (
            <Text
              fontWeight="bold"
              fontSize="lg"
              animation={state.complete ? `${completeAnim} 0.7s` : undefined}
            >
              <Text as="span" color="green.400" fontSize={{ base: 'md', md: 'lg' }} pr={3}>
                Complete <FontAwesomeIcon icon={faCheck} fixedWidth={true} />
              </Text>
            </Text>
          )}
          <Button
            colorScheme="buttonTeal"
            onClick={onClose}
            variant="outline"
            size={{ base: 'sm', md: 'md' }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const VideoSubtext = ({
  state,
  timeToFirstComplete,
  feedbackGiven,
  giveFeedback,
}: {
  state: ModuleStepState;
  timeToFirstComplete: MutableRefObject<number | undefined>;
  feedbackGiven: boolean;
  giveFeedback: (positive: boolean) => void;
}) => {
  const { mutate: completeStep, isLoading, isError, reset } = useCompleteTrainingStep();
  if (!state.complete) {
    if (isLoading) {
      <Text as="span" fontWeight="normal">
        Completing step... <Spinner size="sm" ml={1} />
      </Text>;
    }
    if (isError) {
      <Text as="span" fontWeight="normal" color="red.700">
        Error saving step completion.{' '}
        <Link
          onClick={() => {
            reset();
            completeStep(state);
          }}
          opacity={0.7}
          textDecoration="underline"
          _hover={{
            opacity: 1,
          }}
        >
          Click here to retry.
        </Link>
      </Text>;
    }
    return (
      <Text fontSize={'md'} fontWeight={'bold'}>
        Watch this video to complete the step.
      </Text>
    );
  }
  if (timeToFirstComplete.current !== undefined) {
    if (feedbackGiven) {
      return <>Thank you for your feedback.</>;
    }
    return (
      <>
        Was this video useful?
        <IconButton
          aria-label="Thumbs Up"
          variant="ghost"
          icon={<FontAwesomeIcon icon={faThumbsUp} fixedWidth={false} />}
          onClick={() => giveFeedback(true)}
          m={1}
          color={'blue.500'}
          size={'sm'}
        />
        <IconButton
          aria-label="Thumbs Up"
          variant="ghost"
          icon={<FontAwesomeIcon icon={faThumbsDown} fixedWidth={false} />}
          onClick={() => giveFeedback(true)}
          color={'blue.500'}
          size={'sm'}
        />
      </>
    );
  }
  return null;
};
