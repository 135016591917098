// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/users/v1/onboarding.proto" (package "sparx.reading.users.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { MessageType } from '@protobuf-ts/runtime';
/**
 * @generated from protobuf message sparx.reading.users.v1.OnboardingTask
 */
export interface OnboardingTask {
  /**
   * @generated from protobuf field: sparx.reading.users.v1.OnboardingStep step = 1;
   */
  step: OnboardingStep;
  /**
   * @generated from protobuf field: bool is_complete = 2;
   */
  isComplete: boolean;
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.OnboardingStatus
 */
export enum OnboardingStatus {
  /**
   * @generated from protobuf enum value: ONBOARDING_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: ONBOARDING_STATUS_ONBOARDING = 1;
   */
  ONBOARDING = 1,
  /**
   * @generated from protobuf enum value: ONBOARDING_STATUS_ONBOARDED = 2;
   */
  ONBOARDED = 2,
  /**
   * @generated from protobuf enum value: ONBOARDING_STATUS_READY_TO_ONBOARD = 3;
   */
  READY_TO_ONBOARD = 3,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.OnboardingStep
 */
export enum OnboardingStep {
  /**
   * @generated from protobuf enum value: ONBOARDING_STEP_UNKNOWN = 0;
   */
  UNKNOWN = 0,
  /**
   * @generated from protobuf enum value: ONBOARDING_STEP_BASELINE_ASSESSMENT = 1;
   */
  BASELINE_ASSESSMENT = 1,
  /**
   * @generated from protobuf enum value: ONBOARDING_STEP_TRAINING_BOOKS = 2;
   */
  TRAINING_BOOKS = 2,
  /**
   * @generated from protobuf enum value: ONBOARDING_STEP_AWAITING_SRT_RESULT = 3;
   */
  AWAITING_SRT_RESULT = 3,
  /**
   * @generated from protobuf enum value: ONBOARDING_STEP_BOOK_CHOICE = 4;
   */
  BOOK_CHOICE = 4,
}
/**
 * @generated from protobuf enum sparx.reading.users.v1.OnboardingCompleteReason
 */
export enum OnboardingCompleteReason {
  /**
   * Onboarding was completed normally by the student.
   *
   * @generated from protobuf enum value: ONBOARDING_COMPLETE_REASON_USER_COMPLETED = 0;
   */
  USER_COMPLETED = 0,
  /**
   * Onboarding was skipped by an admin.
   *
   * @generated from protobuf enum value: ONBOARDING_COMPLETE_REASON_ADMIN = 1;
   */
  ADMIN = 1,
}
// @generated message type with reflection information, may provide speed optimized methods
class OnboardingTask$Type extends MessageType<OnboardingTask> {
  constructor() {
    super('sparx.reading.users.v1.OnboardingTask', [
      {
        no: 1,
        name: 'step',
        kind: 'enum',
        T: () => [
          'sparx.reading.users.v1.OnboardingStep',
          OnboardingStep,
          'ONBOARDING_STEP_',
        ],
      },
      { no: 2, name: 'is_complete', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.users.v1.OnboardingTask
 */
export const OnboardingTask = new OnboardingTask$Type();
