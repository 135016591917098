import { faBook, faMortarBoard } from '@fortawesome/free-solid-svg-icons';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { SuspenseStaffContextProvider } from 'views/teacher/staff-view/staff-manager-context';
import { TrainingContext } from 'views/teacher/teacher-training-view/training-context';

import { DepartmentWidget } from './department-widget';
import styles from './department-widgets.module.css';
import { useGetDepartmentDashStats } from './hooks';
import { UnviewedHomeworkWidget } from './unviewed-homework-widget/unviewed-homework-widget';

enum WidgetType {
  TrainingOverview = 'Training overview',
  HomeworkOverview = 'Homework overview',
  HandInPageUsage = 'Hand-in page usage',
}

const widgetThresholds: Record<WidgetType, [number, number]> = {
  [WidgetType.TrainingOverview]: [0.5, 1],
  [WidgetType.HomeworkOverview]: [0.5, 1],
  [WidgetType.HandInPageUsage]: [0.5, 1],
};

export const DepartmentWidgetsContent = () => {
  const navigate = useNavigate();
  const {
    loading,
    numClassesTotal,
    numStaffTotal,
    numStaffCompletedEssentialTraining,
    classesWithHomeworkSetThisWeek,
  } = useGetDepartmentDashStats();

  return (
    <div className={styles.Widgets}>
      <DepartmentWidget
        label="Training overview"
        title={`${numStaffCompletedEssentialTraining}/${numStaffTotal} staff members`}
        proportion={numStaffTotal > 0 ? numStaffCompletedEssentialTraining / numStaffTotal : 0}
        thresholds={widgetThresholds[WidgetType.TrainingOverview]}
        icon={faMortarBoard}
        subtitle="have completed their training"
        body="Video training helps explain the 'why' of Sparx Reader. Schools that complete training see much higher engagement with homework."
        buttonText="See all staff"
        buttonAction={() => navigate('/teacher/staff')}
        loading={loading}
      />
      <DepartmentWidget
        label="Homework overview"
        title={`${classesWithHomeworkSetThisWeek.length}/${numClassesTotal} classes`}
        proportion={
          numClassesTotal > 0 ? classesWithHomeworkSetThisWeek.length / numClassesTotal : 0
        }
        thresholds={widgetThresholds[WidgetType.HomeworkOverview]}
        icon={faBook}
        subtitle="have homework set this week"
        body="Use homework to set expectations and build reading habits. Homework plans set homework automatically each week."
        buttonText="Set a homework"
        buttonAction={() => navigate('/teacher/classes/homework')}
        loading={loading}
      />
      <UnviewedHomeworkWidget />
    </div>
  );
};

export const DepartmentWidgets = () => (
  <Suspense fallback={undefined}>
    <SuspenseStaffContextProvider>
      <TrainingContext>
        <DepartmentWidgetsContent />
      </TrainingContext>
    </SuspenseStaffContextProvider>
  </Suspense>
);
