import 'app/analytics';

import { ThemeProvider } from '@sparx/design/context';
import { SparxQueryProvider as SpxQuery } from '@sparx/query';
import { QueryClientProvider } from '@tanstack/react-query';
import { StatusNotification } from 'components/sections/status-notification';
import { queryClient } from 'queries/client';
import { useSchoolID } from 'queries/schools';
import { PropsWithChildren } from 'react';
import { CookiesProvider } from 'react-cookie';
import { RouterProvider } from 'react-router-dom';

import styles from './app.module.css';
import { DevTools } from './dev-tools/dev-tools';
import { routes } from './routes/routes';

/**
 * A wrapper around the provider so that we can fetch school ID, which is only
 * available within the QueryClientProvider.
 */
const SparxQueryProvider = ({ children }: PropsWithChildren) => {
  const schoolID = useSchoolID();
  return <SpxQuery schoolName={`schools/${schoolID}`}>{children}</SpxQuery>;
};

const App = () => {
  return (
    <ThemeProvider themeStyles={styles}>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <SparxQueryProvider>
            <StatusNotification />
            <RouterProvider router={routes} />
            <DevTools />
          </SparxQueryProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
};

export default App;
