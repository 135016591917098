import { DomainCheck } from '@sparx/analytics';
import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { CenteredEmpty } from 'components/centeredview/centered-view';
import { Loading } from 'components/loading/loading';
import { useChangePageEvents } from 'hooks/change-page-events';
import { useServerOffset, useSession, useUserActive, useUserEventStream } from 'queries/session';
import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { useMaintenanceFlag } from 'utils/featureflags';
import { MaintenanceView } from 'views/common/maintenance-view';
import { NotFoundView } from 'views/common/not-found-view/not-found-view';
import { ServerUnavailableView } from 'views/common/server-unavailable-view/server-unavailable-view';

import styles from './app-container.module.css';
import { useConfigureStatusEmbed } from './status-embed';

export const errorIsUnauthenticated = (error: Error | null) =>
  error?.message === 'not authenticated';

type Props = {
  children: React.ReactNode;
};

export const AppContainer = ({ children }: Props) => {
  useUserEventStream();
  useChangePageEvents();
  useServerOffset();
  useUserActive();
  useConfigureStatusEmbed();

  const error = useRouteError();

  // NOTE: We use isFetched here rather than isLoading as we don't want to show the loading spinner
  // in the case where the user arrives at the app but isn't logged on.
  const { isFetched: sessionRequested, isError, error: sessionError, data: session } = useSession();

  const maintenance = useMaintenanceFlag();
  if (maintenance) {
    return <MaintenanceView settings={maintenance} />;
  }

  if (isError && !errorIsUnauthenticated(sessionError)) {
    return <ServerUnavailableView error={sessionError} />;
  }

  if (!sessionRequested || isError) {
    return (
      <CenteredEmpty>
        <Loading white={true} />
      </CenteredEmpty>
    );
  }

  let userType = 'unknown';
  switch (session?.user?.type) {
    case UserType.TEACHER:
      userType = 'teacher';
      break;
    case UserType.STUDENT:
      userType = 'student';
  }

  return (
    <div className={styles.App}>
      {session?.user?.schoolId && (
        <DomainCheck
          schoolId={`schools/${session.user.schoolId}`}
          product={Product.SPARX_READER}
          userType={userType}
        />
      )}

      {children}

      {isRouteErrorResponse(error) && (
        <div className="view">
          <NotFoundView />
        </div>
      )}
    </div>
  );
};
