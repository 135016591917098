import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { useUser } from 'queries/session';

export const useIsStudentDemoMode = () => {
  const user = useUser();

  return (
    user?.type === UserType.TEACHER &&
    !location.pathname.startsWith('/teacher') &&
    !location.pathname.startsWith('/admin')
  );
};
