import { NotificationWithContext } from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import { ExperienceUpdate } from '@sparx/api/apis/sparx/reading/users/v1/experience';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notificationClient } from 'api';
import { setPreviousGoldReaderState } from 'queries/gold';
import { setExperience, setRewards } from 'queries/rewards';
import { useMemo } from 'react';

import { queryClient } from './client';

export const useNotifications = () =>
  useQuery(
    ['user', 'notifications'],
    () => notificationClient.getNotifications({}).response,
    { staleTime: Infinity, select: data => data.notifications }, // never stale
  );

// useFirstWarningNotificationUnread is a hook which gets the first unread warning notification
// If one exists, the onFetchSuccess callback is called with the notification.
// This hook currently only supports the case where there is a single matching notification.
export const useFirstWarningNotificationUnread = (
  onFetchSuccess: (notification: NotificationWithContext) => void,
) => {
  useQuery(['user', 'notifications'], () => notificationClient.getNotifications({}).response, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    onSuccess: (notifications: NotificationWithContext[]) => {
      if (notifications.length === 0) {
        return;
      }
      onFetchSuccess(notifications[0]);
    },
    select: data => {
      return data.notifications.filter(
        notification =>
          !notification.dismissed &&
          notification?.notification?.notification.oneofKind === 'warning',
      );
    },
  });
};

// The server returns a list; we then build a map.
// For each book, we have a list of notifications relevant to that book.
// The ID in the map is the ID of the book in question.
// Where it lives in the server response depends on the type of the notification.
export const getBookNotificationMapFromList = (
  notifications?: NotificationWithContext[],
): Record<string, NotificationWithContext[]> => {
  const bookNotificationMap: Record<string, NotificationWithContext[]> = {};
  const addNotification = (bookID: string, notification: NotificationWithContext) => {
    if (!bookNotificationMap[bookID]) {
      bookNotificationMap[bookID] = [];
    }
    bookNotificationMap[bookID].push(notification);
  };
  for (const n of notifications || []) {
    if (n.notification?.notification.oneofKind === 'book' && !n.dismissed) {
      addNotification(n.notification.notification.book.bookId, n);
    }
    if (n.notification?.notification.oneofKind === 'bankedReward' && !n.dismissed) {
      addNotification(n.notification.notification.bankedReward.bookId, n);
    }
  }
  return bookNotificationMap;
};

export const useBookNotifications = () => {
  // Ignore loading and error, just assume [] in these cases
  const { data: notifications } = useNotifications();

  return useMemo(() => getBookNotificationMapFromList(notifications), [notifications]);
};

export const useBookNotificationRead = () => {
  const client = useQueryClient();
  const mutation = useMutation(
    (notificationId: string) =>
      notificationClient.setNotificationStatus({ notificationId, read: true }).response,
    {
      onSuccess: data => {
        client.invalidateQueries(['user', 'notifications']);
        if (data.experienceUpdate) {
          if (data.experienceUpdate.newExperience) {
            setExperience(data.experienceUpdate.newExperience);
          }
          if (data.experienceUpdate.reward) {
            setRewards(data.experienceUpdate.reward);
          }
        }
      },
    },
  );
  return (id: string) => mutation.mutate(id);
};

export const useUpdateExperience = (taskID?: string) => {
  return (xpUpdate: ExperienceUpdate) => {
    const newXp = xpUpdate.newExperience;
    if (newXp) {
      setExperience(newXp);
    }
    const reward = xpUpdate.reward;
    if (reward) {
      setRewards(reward, taskID);
    }
    if (xpUpdate.goldReaderStateUpdated) {
      setPreviousGoldReaderState();
      queryClient.invalidateQueries(['user', 'gold']);
    }
  };
};
