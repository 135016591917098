import { BaselineAssessmentAnswer } from '@sparx/api/apis/sparx/reading/users/v1/sessions';

export enum BaselineAssessmentQuestionType {
  text,
  sentiment,
}

export type BaselineAssessmentQuestion = BaselineAssessmentAnswer & {
  type: BaselineAssessmentQuestionType;
};

export const BaselineAssessmentQuestions: BaselineAssessmentQuestion[] = [
  {
    type: BaselineAssessmentQuestionType.text,
    question: 'How much time did you spend reading a book on your own in the past week?',
    allOptions: [
      'None',
      'Less than 5 minutes',
      'Between 5 and 15 minutes',
      'Between 15 and 30 minutes',
      'More than 30 minutes',
    ],
    selectedOption: '',
  },
  {
    type: BaselineAssessmentQuestionType.sentiment,
    question: 'I enjoy reading',
    allOptions: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    selectedOption: '',
  },
  {
    type: BaselineAssessmentQuestionType.sentiment,
    question: 'I am a good reader',
    allOptions: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    selectedOption: '',
  },
];
