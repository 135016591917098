import { AnalyticEventFields } from 'components/client-events/client-event-provider';

const CATEGORY = 'unviewed-homework-widget';

export const openedModal = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'opened modal',
});

export const closedModal = (): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'closed modal',
});

export const clickedViewHandIn = (
  studentGroupId: string,
  homeworkId: string,
): AnalyticEventFields => ({
  category: CATEGORY,
  action: 'clicked view hand-in',
  labels: { groupId: studentGroupId, homeworkId },
});
