import {
  AnalyticsContext as AnalyticsContextShim,
  UserInteractionEventFields,
} from '@sparx/analytics';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { ReactNode, useCallback } from 'react';

// AnalyticsShimProvider supports components that expect a @sparx/analytics AnalyticsContext Provider,
// wiring in to our useClientEvents hook.
export const AnalyticsShimProvider = ({ children }: { children: ReactNode }) => {
  const { sendEvent: sendClientEvent } = useClientEvent();
  const sendEvent = useCallback(
    (evt: UserInteractionEventFields) => {
      const labels = evt.labels
        ? Object.keys(evt.labels).reduce<Record<string, string>>((obj, key) => {
            if (!evt.labels) {
              return obj;
            }
            // Stringify the value of the label
            obj[key] = evt.labels[key].valueOf().toString();
            return obj;
          }, {})
        : undefined;
      sendClientEvent({
        ...evt,
        category: evt.category,
        labels,
      });
    },
    [sendClientEvent],
  );
  return (
    <AnalyticsContextShim.Provider value={sendEvent}>{children}</AnalyticsContextShim.Provider>
  );
};
