import { UserRole, useUserHasRole } from 'queries/session';
import { NotFoundView } from 'views/common/not-found-view/not-found-view';

type Props = {
  role: UserRole;
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const RequiresRole = ({ role, children, fallback }: Props) => {
  const hasRole = useUserHasRole(role);

  if (hasRole) return children;

  return fallback || <NotFoundView />;
};
