import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { useClientEvent } from 'components/client-events/client-event-provider';
import styles from 'components/rating/book-rating.module.css';
import { HappinessRating } from 'components/rating/happinessrating';
import { StarsRatingEditor } from 'components/rating/stars-rating-editor';
import { useUpdateLibraryBook } from 'queries/library-books';
import { useState } from 'react';

interface IBookRatingProps {
  studentBook: StudentBook;
  difficultyOnly?: boolean;
  taskId: string;
}

export const BookRating: React.FC<IBookRatingProps> = ({ studentBook, difficultyOnly, taskId }) => {
  const [visibleStars, setVisibleStars] = useState(0);
  const [visibleRating, setVisibleRating] = useState(0);
  const updateStudentBook = useUpdateLibraryBook();
  const { sendEvent } = useClientEvent();

  const saveStars = (r: number) => {
    setVisibleStars(r);
    if (r !== visibleStars) {
      sendEvent({ action: 'book rating', category: 'rating', labels: { value: r, taskId } });
      updateStudentBook.mutate({
        studentBook: {
          ...studentBook,
          rating: r,
        },
        taskId: taskId,
      });
    }
  };
  const saveRating = (r: number) => {
    setVisibleRating(r);
    if (r !== visibleRating) {
      sendEvent({
        action: 'book rating',
        category: 'difficulty rating',
        labels: { value: r, taskId },
      });
      updateStudentBook.mutate({
        studentBook: {
          ...studentBook,
          difficultyRating: r,
        },
        taskId: taskId,
      });
    }
  };
  return (
    <>
      {!difficultyOnly && (
        <>
          <h3 className={styles.Question}>
            How much do you <strong>like</strong> this book?
          </h3>
          <StarsRatingEditor rating={visibleStars} onChange={saveStars} />
        </>
      )}
      <h3 className={styles.Question}>
        How <strong>easy or difficult</strong> is this book?
      </h3>
      <HappinessRating rating={visibleRating} onChange={saveRating} />
    </>
  );
};
