import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import classNames from 'classnames';
import { useAlert, useClearAlert } from 'components/alert/alert';
import { ConfirmAlert } from 'components/alert/confirm-alert';
import { Button } from 'components/buttons';
import { IBooklogActions } from 'utils/tasks';

import styles from '../booklog-task-view.module.css';
import { Countdown } from '../countdown';
import { getTimerStats } from '../utils';

export const StageTimingOrPaused = ({
  task,
  actions,
}: {
  task: Task;
  actions: IBooklogActions;
}) => {
  const setAlert = useAlert();
  const clearAlert = useClearAlert();
  const { paused, exceededSuggested } = getTimerStats(task);
  const onBack = () => {
    setAlert(
      <ConfirmAlert
        title={`This will cancel your session and reset your timer. Are you sure?`}
        confirmText={'Yes, go back'}
        rejectText={'No, stay here'}
        onConfirm={() => {
          clearAlert();
          actions.sendBackAction();
        }}
        confirmAnalytics={undefined}
        rejectAnalytics={undefined}
      />,
    );
  };

  return (
    <div>
      <h2 className={styles.StageTitle}>Enjoy your book</h2>
      <p className={styles.StageSubtitle}>
        Try to stop and record your progress after around{' '}
        <strong>20 minutes of careful reading</strong>. When you&apos;re ready, press{' '}
        <strong>Next</strong>.
      </p>
      <Countdown task={task} />
      <div className={classNames(styles.Instruction, paused && styles.Paused)}>
        {paused ? 'Timer paused!' : exceededSuggested ? 'Record your session now' : null}
      </div>

      <div className={styles.ActionButtons}>
        <div className={styles.ActionButton}>
          <Button variant={'secondary'} analyticsEvent={undefined} color="danger" onClick={onBack}>
            Cancel
          </Button>
        </div>
        <div className={styles.ActionButton}>
          {paused ? (
            <Button
              analyticsEvent={undefined}
              loading={actions.isLoading}
              onClick={actions.sendResumeAction}
              leftIcon={<FontAwesomeIcon icon={faPlay} />}
            >
              Resume
            </Button>
          ) : (
            <Button
              analyticsEvent={undefined}
              loading={actions.isLoading}
              variant="secondary"
              onClick={actions.sendPauseAction}
              leftIcon={<FontAwesomeIcon icon={faPause} />}
            >
              Pause
            </Button>
          )}
        </div>
        <div className={styles.ActionButton}>
          <Button
            analyticsEvent={undefined}
            loading={actions.isLoading}
            onClick={() =>
              actions.sendRangeAction({
                timeSpentMinutes: 0,
                markFinished: false,
              })
            }
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
