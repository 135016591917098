import classNames from 'classnames';
import { useClientEvent } from 'components/client-events/client-event-provider';
import { useState } from 'react';

import styles from './question.module.css';
import { BaselineAssessmentQuestion, BaselineAssessmentQuestionType } from './questions';

type QuestionProps = {
  question: BaselineAssessmentQuestion;
  options: string[];
  onSelectOption: (option: BaselineAssessmentQuestion) => void;
};

export const Question = ({ question, options, onSelectOption }: QuestionProps) => {
  const { sendEvent } = useClientEvent();
  const [selectedOption, setSelectedOption] = useState('');

  const selectOption = (option: string) => {
    setSelectedOption(option);
    sendEvent({
      category: 'baseline question',
      action: `clicked ${option} for question ${question}`,
    });
    onSelectOption({
      ...question,
      selectedOption: option,
    });
  };

  return (
    <>
      {question.type === BaselineAssessmentQuestionType.sentiment && <p>How much do you agree?</p>}
      <p
        className={classNames({
          [styles.SentimentQuestion]: question.type === BaselineAssessmentQuestionType.sentiment,
          [styles.TextQuestion]: question.type === BaselineAssessmentQuestionType.text,
        })}
      >
        {question.question}
      </p>

      <div>
        <div
          className={classNames(styles.Answers, {
            [styles.TextAnswers]: question.type === BaselineAssessmentQuestionType.text,
          })}
        >
          {options.map(option => (
            <div key={option}>
              <button
                onClick={() => selectOption(option)}
                className={classNames(styles.OptionButton, {
                  [styles.SelectedOptionButton]: selectedOption === option,
                })}
              >
                {option}
              </button>
              {question.type === BaselineAssessmentQuestionType.sentiment && option === '1' && (
                <div className={styles.ScaleText}>Strongly disagree</div>
              )}
              {question.type === BaselineAssessmentQuestionType.sentiment && option === '10' && (
                <div className={styles.ScaleText}>Strongly agree</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
