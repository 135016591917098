import { faAngleRight, faCalendarDays, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/buttons';
import { Link } from 'components/link';

import { clickedButton } from './events';
import styles from './interim-widget.module.css';

export const InterimWidget = () => {
  return (
    <div className={styles.Root}>
      <h3 className={styles.Label}>
        <FontAwesomeIcon icon={faCalendarDays} /> New academic year
      </h3>
      <div className={styles.Panel}>
        <div className={styles.Header}>ACTION REQUIRED</div>

        <div className={styles.Body}>
          <p>
            <strong>Your school has entered a new academic year.</strong>
          </p>

          <p>
            Your Sparx Leader or Head of Department needs to get your Sparx Reader site ready for
            new and existing students.
          </p>

          <Link to="/teacher/new-year-setup/overview" analyticsEvent={clickedButton()}>
            <Button analyticsEvent={undefined} className={styles.Button}>
              Follow our setup steps here
              <FontAwesomeIcon icon={faAngleRight} />
            </Button>
          </Link>
        </div>

        <FontAwesomeIcon icon={faSync} className={styles.SyncIcon} />
      </div>
    </div>
  );
};
