import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useOnMount = (fun: () => void): void => useEffect(fun, [fun]);

export const useClickAwayListener = (
  setOpen: (open: boolean) => void,
  open: boolean,
  filter?: (ev: MouseEvent) => boolean,
) => {
  const listener = useCallback(
    (ev: MouseEvent) => {
      if (!filter || filter(ev)) {
        setOpen(false);
      }
    },
    [setOpen, filter],
  );
  useEffect(() => {
    if (open) {
      document.addEventListener('click', listener);
      return () => document.removeEventListener('click', listener);
    } else {
      document.removeEventListener('click', listener);
    }
  }, [open, listener]);
};

export const useHoverClickMenu = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  useClickAwayListener(setMenuOpen, menuOpen);

  const [currentTimeout, setCurrentTimeout] = useState<ReturnType<typeof setTimeout>>();
  const [clickDisabled, setClickDisabled] = useState(false);
  const clearCurrentTimeout = () => currentTimeout && clearTimeout(currentTimeout);
  const onMouseAway = () => {
    clearCurrentTimeout();
    setCurrentTimeout(setTimeout(() => setMenuOpen(false), 500));
  };

  const onMouseEnter = () => {
    clearCurrentTimeout();
    // add a small delay to opening the menu –
    // If the user is very quickly hovering over the menu item
    // they are probably travelling past it to the class menu beneath
    setCurrentTimeout(
      setTimeout(() => {
        // after the delay, open the menu
        setMenuOpen(true);

        // but disable click so that the user doesn't click as the menu opens, closing it immediately
        setClickDisabled(true);
        // re-enable click after a reasonable amount of time
        setTimeout(() => setClickDisabled(false), 400);
      }, 150),
    );
  };

  const openMenu = (e: React.MouseEvent<HTMLDivElement> | undefined) => {
    e && e.stopPropagation();
    if (clickDisabled) return;
    clearCurrentTimeout();
    setMenuOpen(!menuOpen);
  };

  return { onMouseAway, onMouseEnter, openMenu, menuOpen };
};

// https://usehooks.com/useDebounce/
export const useDebounce = <T,>(value: T, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

// A version of useDebounce that returns true when the values are currently being debounced.
export const useDebouncing = <T,>(value: T, delay: number): [T, boolean] => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setIsDebouncing] = useState(true);
  useEffect(
    () => {
      // Track when the values have changed but we're still returning the old version.
      setIsDebouncing(true);
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setIsDebouncing(false);
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return [debouncedValue, isDebouncing];
};

export const useKeyPress = (callbacks: { [targetKey: string]: () => void }) => {
  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler({ key }: { key: string }) {
      if (callbacks[key]) {
        callbacks[key]();
      }
    }
    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [callbacks]); // Empty array ensures that effect is only run on mount and unmount
};

export const useKeypressScroll = (elementId: string) => {
  const scroll = (diff: number) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollTo({ top: element.scrollTop + diff * 200, behavior: 'smooth' });
    }
  };
  useKeyPress({
    ArrowUp: () => scroll(-1),
    ArrowDown: () => scroll(1),
  });
};

// Define general type for useWindowSize hook, which includes width and height
interface IWindowSize {
  width: number | undefined;
  height: number | undefined;
}

// https://usehooks.com/useWindowSize/
export const useWindowSize = (): IWindowSize => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

// https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
export const useOnScreen = (
  ref?: React.RefObject<HTMLElement>,
  options?: IntersectionObserverInit,
) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), options),
    [options],
  );

  useEffect(() => {
    if (ref && ref.current) {
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref, observer]);

  return isIntersecting;
};

export const useQueryParamStringArray = (value: string | (string | null)[] | null): string[] =>
  useMemo(() => {
    const values: string[] = [];
    if (Array.isArray(value)) {
      for (const val of value) {
        if (val) {
          values.push(val);
        }
      }
    } else if (value) {
      values.push(value);
    }
    return values;
  }, [value]);
