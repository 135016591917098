import styles from 'components/tasks/panel.module.css';
import { CSSTransition } from 'react-transition-group';
import { FCWithChildren } from 'utils/props';

interface IModalProps {
  className?: string;
}

export const Modal: FCWithChildren<IModalProps> = ({ children, className }) => {
  return (
    <div className={styles.ModalWrapper}>
      <CSSTransition classNames="slideup-modal" timeout={300} appear={true} in={true}>
        <div className={`${styles.ModalContainer} ${className}`}>
          <div className={`${styles.Panel} ${styles.Modal}`}>{children}</div>
        </div>
      </CSSTransition>
    </div>
  );
};
