import { IdentifierType } from '@sparx/api/apis/sparx/reading/books/v1/book';
import { BookAnnotation } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { create, windowScheduler } from '@yornaath/batshit';
import { booksClient, contentClient } from 'api';
import { queryClient } from 'queries/client';

import { LIBRARY_BOOKS_QUERY_KEY } from './library-books';

const bookBatchFetcher = create({
  fetcher: async (bookResourceNames: string[]) => {
    return booksClient.queryBooks({ names: bookResourceNames }).response.then(resp => resp.books);
  },
  resolver: (items, query) =>
    items.find(
      item =>
        // Find by the root book name
        item.name === query ||
        // Find by alternate identifiers
        item.identifiers.find(
          id =>
            id.type === IdentifierType.SPARX_READER_UUID &&
            id.value === query.replace('books/', ''),
        ),
    ),
  scheduler: windowScheduler(100),
});

const bookMetadataFetcher = (bookId?: string) => ({
  queryKey: ['books', 'metadata', `books/${bookId}`],
  queryFn: async () => bookBatchFetcher.fetch('books/' + bookId),
  enabled: Boolean(bookId),
  staleTime: Infinity,
  cacheTime: Infinity,
  keepPreviousData: true,
});

/**
 * Fetch book from bookkeeper. Uses batshit to batch requests made within 100ms
 * of each other into a single QueryBooks call.
 */
export const useBookMetadata = (bookId?: string) => useQuery(bookMetadataFetcher(bookId));

export const useBatchBookMetadata = (bookIDs: string[]) => {
  return useQueries({ queries: bookIDs.map(bookMetadataFetcher) });
};

export const setBooksStale = () => {
  queryClient.invalidateQueries(['books', 'available']);
  queryClient.invalidateQueries([LIBRARY_BOOKS_QUERY_KEY]);
};

export const clearAvailableBooks = () => queryClient.resetQueries(['books', 'available']);

export const useBookAnnotations = (bookId: string) =>
  useQuery(
    ['book', 'annotations', bookId],
    () => contentClient.getBookAnnotations({ bookId }).response,
    {
      enabled: Boolean(bookId),
      staleTime: 15000,
    },
  );

export const invalidateBookAnnotations = (bookID: string) =>
  queryClient.resetQueries(['book', 'annotations', bookID]);

interface IBookAnnotationUpdate {
  action: number;
  annotation: Partial<BookAnnotation>;
  wordCount?: number;
}

export const useApproveAllAnnotations = (bookId: string) =>
  useMutation(() => contentClient.approveAllAnnotations({ bookId }).response, {
    onSuccess: data => {
      // Update cache with the new annotation list
      queryClient.setQueryData(['book', 'annotations', bookId], data);
    },
  });

export const useCommitBookAnnotation = (bookId: string) =>
  useMutation(
    (req: IBookAnnotationUpdate) =>
      contentClient.commitBookAnnotation({
        bookId,
        action: req.action,
        annotation: {
          bookAnnotationId: '',
          partId: '',
          ...req.annotation,
        },
        wordCount: req.wordCount,
      }).response,
    {
      onSuccess: data => {
        // Update cache with the new annotation list
        queryClient.setQueryData(['book', 'annotations', bookId], data);
      },
    },
  );

export const useBookContentV2 = ({
  bookId,
  taskId,
  disabled,
}: {
  bookId: string | undefined;
  taskId?: string;
  disabled?: boolean;
}) =>
  useQuery(
    ['book', 'content', bookId, taskId],
    () => contentClient.getBook({ bookId: bookId || '', taskId: taskId || '' }).response,
    {
      staleTime: Infinity, // Book content should be selected once only
      refetchOnWindowFocus: false,
      enabled: Boolean(bookId) && !disabled,
    },
  );

export const invalidateBookContentV2 = (bookID: string) =>
  queryClient.resetQueries(['book', 'content', bookID]);
