import { SchoolStaffMember } from '@sparx/api/apis/sparx/school/staff/schoolstaff/v2/schoolstaff';
import { StaffRole, StaffRoleAssignment } from '@sparx/api/apis/sparx/school/staff/v2/staff';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { snakeCase } from 'change-case';
import { FieldNamesMarkedBoolean, useFormContext } from 'react-hook-form';

export const productKeyContactRoles: ReadonlyArray<StaffRole> = [
  StaffRole.SPARX_LEADER,
  StaffRole.SENIOR_LEADER,
  StaffRole.HEAD_OF_DEPARTMENT,
];

export type UniqueRole = (typeof productKeyContactRoles)[number];

export const isUniqueRole = (role: StaffRole): role is UniqueRole =>
  productKeyContactRoles.includes(role);

export const sharedKeyContactRoles: ReadonlyArray<StaffRole> = [
  StaffRole.NETWORK_MANAGER,
  StaffRole.FINANCE_OFFICER,
  StaffRole.DATA_PROTECTION_OFFICER,
  StaffRole.HEAD_OF_SCHOOL,
  StaffRole.BUSINESS_MANAGER,
];

export type StaffKeyContacts = Partial<Record<StaffRole, SchoolStaffMember>>;

const shouldError = [
  StaffRole.SPARX_LEADER,
  StaffRole.HEAD_OF_DEPARTMENT,
  StaffRole.SENIOR_LEADER,
  StaffRole.NETWORK_MANAGER,
  StaffRole.FINANCE_OFFICER,
  StaffRole.DATA_PROTECTION_OFFICER,
  StaffRole.HEAD_OF_SCHOOL,
];

export const getKeyContactsStatus = (keyContacts: StaffKeyContacts) => {
  const isMissing = (roles: Array<keyof StaffKeyContacts>) =>
    roles.some(r => keyContacts[r] === undefined);

  if (isMissing(shouldError)) {
    return {
      status: 'error',
      missing: shouldError.filter(r => keyContacts[r] === undefined),
    };
  }

  return { status: 'ok' as const };
};

export interface ContactEditModel {
  role: StaffRole | null;
  staffMember: SchoolStaffMember;
  blockSave?: boolean;
  willRemove?: boolean;
}

/**
 * Gets a description for a role and product - some roles have different descriptions
 * for different products and this function produces a generic version if the zero value
 * of the product is passed.
 * */
export const getRoleName = (role: StaffRole, product: Product): string => {
  const department = {
    [Product.SPARX_MATHS]: 'Maths',
    [Product.SPARX_READER]: 'English',
    [Product.SPARX_SCIENCE]: 'Science',
    [Product.SPARX_TEACHING]: 'Sparx Teaching',
    [Product.SPARX_ASSESSMENTS]: 'Assessments',
    [Product.SPARX_PRIMARY]: 'Primary',
    [Product.SPARXMATHS_CURRICULUM]: '',
    // Use 'department' when aggregating a department-specific role across multiple systems
    [Product.PRODUCT_UNKNOWN]: 'department',
  }[product];

  const system = {
    [Product.SPARX_MATHS]: 'Maths',
    [Product.SPARX_READER]: 'Reader',
    [Product.SPARX_SCIENCE]: 'Science',
    [Product.SPARXMATHS_CURRICULUM]: 'Curriculum',
    [Product.SPARX_TEACHING]: 'Sparx Teaching',
    [Product.SPARX_ASSESSMENTS]: 'Assessments',
    [Product.SPARX_PRIMARY]: 'Primary',
    // Omit the system name when aggregating a system-specific role across multiple systems
    [Product.PRODUCT_UNKNOWN]: '',
  }[product];

  const crossCurricular = {
    [Product.SPARX_MATHS]: 'Numeracy',
    [Product.SPARX_READER]: 'Literacy',
    [Product.SPARX_SCIENCE]: 'Science',
    [Product.SPARXMATHS_CURRICULUM]: 'Numeracy',
    [Product.SPARX_TEACHING]: 'Sparx Teaching',
    [Product.SPARX_ASSESSMENTS]: 'Assessments',
    [Product.SPARX_PRIMARY]: 'Primary',
    // Use 'Cross-curricular' when aggregating a curriculum-specific role across multiple systems
    [Product.PRODUCT_UNKNOWN]: 'Cross-curriculuar',
  }[product];

  switch (role) {
    case StaffRole.HEAD_OF_DEPARTMENT:
      return `Head of ${department}`;
    case StaffRole.DEPUTY_HEAD_OF_DEPARTMENT:
      return `Deputy head of ${department}`;
    case StaffRole.SPARX_LEADER:
      return `Sparx ${system} leader`.replace(/\s+/g, ' ');
    case StaffRole.TEACHER:
      return 'Teacher';

    case StaffRole.TRUST_DIRECTOR:
      return `Trust director for ${department}`;
    case StaffRole.SENIOR_LEADER:
      return `SLT - Line manager of ${department}`;
    case StaffRole.KEY_STAGE_LEAD:
      return `Key stage lead for ${department}`;
    case StaffRole.LEAD_PRACTITIONER:
      return `Lead practitioner for ${department}`;
    case StaffRole.DEPARTMENT_COORDINATOR:
      return `${crossCurricular} coordinator`;

    case StaffRole.LIBRARIAN:
      return 'Librarian';

    // Roles below here are intended to be used with PRODUCT_UNKNOWN so should
    // NOT use any of the subject-specific tokens

    case StaffRole.OTHER:
      return 'Other';
    case StaffRole.SENIOR_LEADER_OTHER:
      return `SLT - Other responsibility`;

    case StaffRole.SPECIAL_EDUCATIONAL_NEEDS_COORDINATOR:
      return `Special educational needs coordinator`;
    case StaffRole.HEAD_OF_SCHOOL:
      return 'Head of school';
    case StaffRole.NETWORK_MANAGER:
      return 'Network manager';
    case StaffRole.FINANCE_OFFICER:
      return 'Finance officer';
    case StaffRole.DATA_PROTECTION_OFFICER:
      return 'Data protection officer';
    case StaffRole.BUSINESS_MANAGER:
      return 'Business manager';
  }

  return 'Unknown role';
};

interface TemplateRole {
  role: StaffRole;
  /** If true, this role is expected to be specified WITH a product */
  hasProduct?: boolean;
  /**
   * If true, show in the list view when using the new UI. Note that original UI shows ALL roles,
   * and the Sparx-only 'All users' view deliberately ignores this flag.
   */
  showInList?: boolean;
}

/**
 * An ordered list of roles we *might* want to display in the UI (currently this is set up to work
 * for both of the 'v2' UIs and the boolean flags are used to distinguish between different scenarios)
 * */
const orderedRoleList: ReadonlyArray<TemplateRole> = [
  { role: StaffRole.SENIOR_LEADER, hasProduct: true, showInList: true },
  { role: StaffRole.SENIOR_LEADER_OTHER, hasProduct: true, showInList: true },
  { role: StaffRole.HEAD_OF_DEPARTMENT, hasProduct: true, showInList: true },
  { role: StaffRole.SPARX_LEADER, hasProduct: true, showInList: true },
  { role: StaffRole.DATA_PROTECTION_OFFICER },
  { role: StaffRole.FINANCE_OFFICER },
  { role: StaffRole.NETWORK_MANAGER },
  { role: StaffRole.LEAD_PRACTITIONER, hasProduct: true, showInList: true },
  { role: StaffRole.KEY_STAGE_LEAD, hasProduct: true, showInList: true },
  { role: StaffRole.LIBRARIAN, hasProduct: true, showInList: true },
  { role: StaffRole.TEACHER, hasProduct: true, showInList: true },
  { role: StaffRole.TRUST_DIRECTOR, hasProduct: true, showInList: true },
  { role: StaffRole.HEAD_OF_SCHOOL },
  { role: StaffRole.DEPUTY_HEAD_OF_DEPARTMENT, hasProduct: true, showInList: true },
  { role: StaffRole.OTHER, hasProduct: true, showInList: true },
];

/**
 * Orders and formats a given list of roles associated with a single subject. This function expects
 * the passed-in list to already be filtered to roles applicable to the subject.
 */
export const getProductRoleNames = (roles: StaffRoleAssignment[], filter: Product): string[] =>
  orderedRoleList.reduce<string[]>((acc, { role, hasProduct, showInList }) => {
    const show = showInList && hasRole(roles, role, hasProduct ? filter : Product.PRODUCT_UNKNOWN);
    return show ? acc.concat(getRoleName(role, filter)) : acc;
  }, []);

const hasRoleForSubject = (roles: readonly StaffRoleAssignment[], role: StaffRole) => {
  const [science, maths, reader] = [
    Product.SPARX_SCIENCE,
    Product.SPARX_MATHS,
    Product.SPARX_READER,
  ].map(p => hasRole(roles, role, p));
  const hasThisRole = [science, maths, reader].some(Boolean);

  return { science, maths, reader, hasThisRole };
};

/**
 * Orders and formats a given list of user / key contact roles. This function will display
 * *every* role it's passed, including ones not normally displayed in the list view.
 */
// TODO: check if this can be simplified?
// LH: There are multiple functions for converting roles to display names, but I believe that not all of them are unique enough to be required
export const getGlobalRoleName = (roles: StaffRoleAssignment[]): string[] =>
  orderedRoleList
    .map(({ role, hasProduct }) => {
      // User holds this cross-system role - return its description as-is
      if (!hasProduct && hasRole(roles, role, Product.PRODUCT_UNKNOWN)) {
        return getRoleName(role, Product.PRODUCT_UNKNOWN);
      }

      const { hasThisRole, science, maths, reader } = hasRoleForSubject(roles, role);

      // User holds this role for one or more products - get the products it applies to
      if (hasThisRole) {
        const productString = productsToStringList(
          [
            science && Product.SPARX_SCIENCE,
            maths && Product.SPARX_MATHS,
            reader && Product.SPARX_READER,
          ].filter((p): p is Product => Boolean(p)),
          { useShortName: true },
        );

        // Concatenate a generic version of the role description with a list of products
        return (
          getRoleName(role, Product.PRODUCT_UNKNOWN) + (productString ? ` (${productString})` : '')
        );
      }

      // User does NOT hold this role
      return '';
    })
    .filter(Boolean);

/** Determines equality of `IStaffRoleAssigment`s based on the value of their role and product */
export const roleAssignmentsEqual = (r1: StaffRoleAssignment, r2: StaffRoleAssignment) =>
  r1.role === r2.role && r1.product === r2.product;

/** Determines if the provided array contains an element matching the provided role and product */
export const hasRole = (
  roles: readonly StaffRoleAssignment[],
  role: StaffRole,
  product: Product,
): boolean => roles.some(r => roleAssignmentsEqual(r, { role, product }));

/**
 * An array of all staff roles which are also users. Librarian is a special case and at the moment
 * is the only subject-based user role which only applies to one product (Reader). Its not included
 * here and should be manually added when this component is used for Sparx Reader.
 * */
const userRoles = [
  StaffRole.TRUST_DIRECTOR,
  StaffRole.SENIOR_LEADER,
  StaffRole.SENIOR_LEADER_OTHER,
  StaffRole.HEAD_OF_DEPARTMENT,
  StaffRole.DEPUTY_HEAD_OF_DEPARTMENT,
  StaffRole.DEPARTMENT_COORDINATOR,
  StaffRole.LEAD_PRACTITIONER,
  StaffRole.KEY_STAGE_LEAD,
  StaffRole.SPARX_LEADER,
  StaffRole.TEACHER,
  StaffRole.OTHER,
];

/** Filter a set of role assignments to the user roles applicable to the specified product (including cross-product) */
export const filterUserRolesForProduct = (
  roles: StaffRoleAssignment[],
  product: Product,
): StaffRoleAssignment[] => {
  const ur = [...userRoles];
  if (product === Product.SPARX_READER) {
    ur.push(StaffRole.LIBRARIAN);
  }

  return roles.filter(
    assignment =>
      ur.includes(assignment.role) &&
      (assignment.product === product || assignment.product === Product.PRODUCT_UNKNOWN),
  );
};

/** Filter a set of role assignments to the user roles applicable to any product, including cross-product */
export const filterUserRoles = (roles: StaffRoleAssignment[]) => {
  const ur = [...userRoles, StaffRole.LIBRARIAN];
  return roles.filter(assignment => ur.includes(assignment.role));
};

export const makeGetUserRoleName = (defaultProduct: Product) => (role: StaffRole) =>
  getRoleName(role, defaultProduct);

const productName: Record<Product, [string, string]> = {
  [Product.SPARX_MATHS]: ['Sparx Maths', 'Maths'],
  [Product.SPARX_READER]: ['Sparx Reader', 'Reader'],
  [Product.SPARX_SCIENCE]: ['Sparx Science', 'Science'],
  [Product.SPARX_TEACHING]: ['Sparx Teaching', 'Sparx Teaching'], // Short version is also 'Sparx Teaching' to avoid confusion
  [Product.SPARX_ASSESSMENTS]: ['Sparx Assessments', 'Assessments'],
  [Product.SPARX_PRIMARY]: ['Sparx Primary', 'Primary'],

  // These products shouldn't appear
  [Product.SPARXMATHS_CURRICULUM]: ['', ''],
  [Product.PRODUCT_UNKNOWN]: ['', ''],
};

export const getProductName = (p: Product) => productName[p]?.[0] || '';
export const getShortProductName = (p: Product) => productName[p]?.[1] || '';

export const productOrder = {
  [Product.SPARX_SCIENCE]: 0,
  [Product.SPARX_MATHS]: 1,
  [Product.SPARX_READER]: 2,
  [Product.SPARX_ASSESSMENTS]: 3,
  [Product.SPARX_PRIMARY]: 4,
  [Product.PRODUCT_UNKNOWN]: 5,
  [Product.SPARXMATHS_CURRICULUM]: 6, // Just to make TS happy
  [Product.SPARX_TEACHING]: 7, // Just to make TS happy
};

export const productsToStringList = (
  products: Product[],
  options: { useShortName?: boolean } = {},
) => {
  const getName = options.useShortName ? getShortProductName : getProductName;
  let productsString = '';
  if (products.length === 1) {
    productsString = getName(products[0]);
  } else {
    // take last two
    productsString = products
      .slice(-2)
      .map(p => getName(p))
      .join(' and ');

    if (products.length > 2) {
      productsString =
        products
          .slice(0, -2)
          .map(p => getName(p))
          .join(', ') +
        ', ' +
        productsString;
    }
  }
  return productsString;
};

export const equalCaseInsensitive = (existing: string, input: string): boolean =>
  existing.localeCompare(input, undefined, { sensitivity: 'accent' }) === 0;

export const useFieldTouched = () => {
  const { getFieldState } = useFormContext<ContactEditModel>();
  return (fieldName: keyof SchoolStaffMember) => {
    const { isTouched } = getFieldState(`staffMember.${fieldName}`);
    return isTouched;
  };
};

// Takes a list of elements and returns them as a comma-separated string using
// string interpolation. Uses an "and" for the last two elements if applicable.
// For example:
// [1,2] => '1 and 2'
// [1,2,3,4] => '1, 2, 3 and 4'
export function listString<T>(values: T[]) {
  if (values.length === 0) {
    return '';
  }
  if (values.length === 1) {
    return `${values[0]}`;
  }
  return `${values.slice(0, -1).join(', ')} and ${values[values.length - 1]}`;
}

/**
 * Returns a function which can be called with an array or number and describe how many there are
 * based on the passed-in description. Pass a second description for the plural if it's not
 * a standard plural formed by appending an 's' character
 * */
export const makeCountDescriptor = (singularWord: string, pluralWord = `${singularWord}s`) =>
  function countDescriptor<T>(items: Array<T> | number, includeCount = true) {
    const length = Array.isArray(items) ? items.length : items;
    const count = includeCount ? `${length} ` : '';
    return count + (length === 1 ? singularWord : pluralWord);
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dirtyFieldsToMask = <T extends Partial<FieldNamesMarkedBoolean<Record<string, any>>>>(
  dirtyFields: T,
): string[] =>
  Object.entries(dirtyFields).reduce((acc, [key, value]) => {
    if (!value) {
      return acc;
    }
    key = snakeCase(key);
    if (typeof value === 'object') {
      return acc.concat(dirtyFieldsToMask(value).map(s => `${key}.${s}`));
    }
    return acc.concat(key);
  }, [] as string[]);

type DeepReadonly<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>;
};

export const willStealUniqueRoles = (
  proposedRoles: ReadonlyArray<StaffRoleAssignment>,
  currentRoles: ReadonlyArray<StaffRoleAssignment>,
  otherStaff?: DeepReadonly<SchoolStaffMember[]>,
): StaffRoleAssignment[] =>
  proposedRoles.filter(
    ({ role, product }) =>
      isUniqueRole(role) &&
      !hasRole(currentRoles, role, product) &&
      otherStaff?.some(s => hasRole(s.roles, role, product)),
  );

export const isUserOfSystem = (system: Product, staff: SchoolStaffMember) => {
  if (!staff.productAccess) {
    return false;
  }
  return userHasRoleInSystem(system, staff.roles);
};

export const userHasRoleInSystem = (system: Product, roles: StaffRoleAssignment[]) => {
  // Otherwise we consider staff members a user of this product if and only
  // if it has at least one role with that product
  return roles.some(r => r.product === system);
};
