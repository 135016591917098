import {
  LeaderboardDateRange,
  LeaderboardType,
} from '@sparx/api/apis/sparx/leaderboards/leaderboards/v1/leaderboards';
import { UserDisplayData } from '@sparx/api/apis/sparx/leaderboards/userdisplay/v1/userdisplay';
import { UserType } from '@sparx/api/apis/sparx/reading/users/v1/sessions';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { leaderboardsClient, userDisplayClient } from 'api';

import { useStudentGroups, useTeacherExperience } from './management';
import { useSchoolID } from './schools';
import { useUser } from './session';

export const LEAGUE_TABLE_QUERY_KEY = 'league-table';
export const LEAGUE_TABLE_DISPLAY_DATA_QUERY_KEY = [LEAGUE_TABLE_QUERY_KEY, 'display-data'];

// Userdisplay queries
export const useGetUserDisplayDataForCurrentUser = <TData = UserDisplayData>(
  options?: UseQueryOptions<UserDisplayData, Error, TData, string[]>,
) =>
  useQuery(
    LEAGUE_TABLE_DISPLAY_DATA_QUERY_KEY,
    () => userDisplayClient.getUserDisplayDataForCurrentUser({}).response,
    { staleTime: 30000, ...options },
  );

export const useGetUserOptedInToLeagueTable = () =>
  useGetUserDisplayDataForCurrentUser({
    select: data => !data.optedOutProducts.includes(Product.SPARX_READER),
  });

export const useGeneratePositiveNoun = () =>
  useQuery(
    [LEAGUE_TABLE_QUERY_KEY, 'positive-noun'],
    () => userDisplayClient.generatePositiveNoun({}).response,
    {
      enabled: false, // Only called on manual refetch
    },
  );

// Student leaderboard queries
export const useStudentClassLeaderboard = (dateRange: LeaderboardDateRange) => {
  return useStudentLeaderboard(LeaderboardType.CLASS, dateRange);
};
export const useStudentYearLeaderboard = (dateRange: LeaderboardDateRange) => {
  return useStudentLeaderboard(LeaderboardType.YEARGROUP, dateRange);
};
export const useStudentSchoolLeaderboard = (dateRange: LeaderboardDateRange) => {
  return useStudentLeaderboard(LeaderboardType.SCHOOL, dateRange);
};
export const useStudentLeaderboard = (
  leaderboardType: LeaderboardType,
  dateRange: LeaderboardDateRange,
) => {
  const user = useUser();
  const isTeacher = user?.type === UserType.TEACHER;
  const { data: optedIn } = useGetUserOptedInToLeagueTable();
  return useQuery(
    [LEAGUE_TABLE_QUERY_KEY, 'student', leaderboardType, dateRange],
    () =>
      leaderboardsClient.getLeaderboard({
        schoolName: `schools/${user?.schoolId}`,
        studentName: `students/${user?.userId}`,
        leaderboardType,
        dateRange,
        product: Product.SPARX_READER,
      }).response,
    {
      staleTime: 30000,
      enabled: Boolean(user) && Boolean(optedIn) && !isTeacher,
      refetchOnWindowFocus: true,
    },
  );
};

// Teacher leaderboard queries
export const useTeacherLeaderboard = (studentIds: string[], dateRange: LeaderboardDateRange) => {
  const schoolID = useSchoolID();

  return useQuery(
    [LEAGUE_TABLE_QUERY_KEY, 'teacher', studentIds, dateRange],
    () =>
      leaderboardsClient.getLeaderboardForTeacher({
        schoolName: `schools/${schoolID}`,
        dateRange,
        product: Product.SPARX_READER,
        studentIds,
      }).response,
    { staleTime: 30000, enabled: !!studentIds.length, refetchOnWindowFocus: true },
  );
};
export const useTeacherClassLeaderboard = (
  studentGroupId: string,
  dateRange: LeaderboardDateRange,
) => {
  const { data: studentIds } = useTeacherExperience({
    select: data =>
      data.users
        .filter(user => studentGroupId !== '' && user.studentGroupId === studentGroupId)
        .map(user => user.userId),
    enabled: Boolean(studentGroupId),
  });
  return useTeacherLeaderboard(studentIds || [], dateRange);
};
export const useTeacherYearGroupLeaderboard = (
  yearGroupId: string,
  dateRange: LeaderboardDateRange,
) => {
  const { data: studentGroupIdsInYear } = useStudentGroups({
    select: data =>
      data.groups
        .filter(group => group.yearGroupId === yearGroupId)
        .map(group => group.name.split('/')[3]),
  });
  const { data: studentIds } = useTeacherExperience({
    select: data =>
      data.users
        .filter(user => studentGroupIdsInYear?.includes(user.studentGroupId))
        .map(user => user.userId),
  });
  return useTeacherLeaderboard(studentIds || [], dateRange);
};
export const useTeacherSchoolLeaderboard = (dateRange: LeaderboardDateRange) => {
  const { data: studentIds } = useTeacherExperience({
    select: data => data.users.map(user => user.userId),
  });
  return useTeacherLeaderboard(studentIds || [], dateRange);
};
