import { Task } from '@sparx/api/apis/sparx/reading/tasks/v1/tasks';
import classNames from 'classnames';
import { useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useInterval } from 'utils/hooks';

import styles from './booklog-task-view.module.css';
import { getTimerStats } from './utils';

export const Countdown = ({ task }: { task: Task }) => {
  const { paused, frac, totalTimeMs, suggested } = getTimerStats(task);

  const [progress, setProgress] = useState(frac);
  useInterval(() => {
    const { frac } = getTimerStats(task);
    if (progress !== frac && !paused) {
      setProgress(frac);
    }
  }, 1000);

  const suggestedDurationMs = suggested.suggested * 60 * 1000;
  const secPassed = Math.min(totalTimeMs, suggestedDurationMs) / 1000;

  // get a string representating the time passed in minutes and seconds
  // up to the suggested time
  const mins = Math.floor(secPassed / 60);
  const secs = Math.floor(secPassed % 60);
  const tstr = `${mins}:${secs < 10 ? '0' : ''}${secs}`;

  // get a string representing the time passed beyond the suggested time
  const beyondSuggestedSec = ((progress - 1) * suggestedDurationMs) / 1000;
  const addmins = Math.floor(beyondSuggestedSec / 60);
  const addsecs = Math.floor(beyondSuggestedSec % 60);
  const addstr = beyondSuggestedSec > 0 ? `+ ${addmins}:${addsecs < 10 ? '0' : ''}${addsecs}` : '';
  const gradient = paused ? 'url(#gradient-paused)' : 'url(#gradient-doing)';

  return (
    <div className={styles.PieChart}>
      <PieChart
        animate={!paused}
        animationDuration={500}
        animationEasing="ease-out"
        center={[50, 50]}
        data={[
          {
            color: gradient,
            key: 1,
            value: 1,
          },
        ]}
        reveal={Math.min(progress * 100, 100)}
        totalValue={1}
        lineWidth={22}
        background="var(--carbon-grey-10)"
        startAngle={-90}
      >
        <defs>
          <linearGradient id="gradient-doing">
            <stop offset="0%" stopColor="var(--spring-green-100)" />
            <stop offset="100%" stopColor="var(--spring-green-100)" />
          </linearGradient>
          <linearGradient id="gradient-paused">
            <stop offset="0%" stopColor="var(--jaffa-orange-100)" />
            <stop offset="100%" stopColor="var(--jaffa-orange-100)" />
          </linearGradient>
        </defs>
      </PieChart>

      <div className={styles.PieChartChild}>
        <div
          className={classNames(styles.PieChartCounter, {
            [styles.CounterPaused]: paused,
            [styles.Paused]: paused,
          })}
        >
          {tstr}
        </div>
        {addstr && (
          <div className={classNames(styles.PieChartPlus, { [styles.Paused]: paused })}>
            {addstr}
          </div>
        )}
      </div>
    </div>
  );
};
