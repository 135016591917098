import { useSchoolMISStatus } from 'queries/school-status';
import { plural } from 'views/teacher/readingtest-view/utils';

import styles from './shared.module.css';

export const NotReady = ({ abbreviated }: { abbreviated?: boolean }) => {
  const { data, isLoading } = useSchoolMISStatus();

  if (isLoading || !data) return;

  return (
    <>
      <div className={styles.Box}>
        <p>
          We&apos;ve done some checking and it looks like the data in your MIS{' '}
          <strong>is not ready</strong> for the new academic year.
        </p>

        {abbreviated && (
          <p>We recommend that you check with your school&apos;s MIS manager before proceeding.</p>
        )}

        {!abbreviated && (
          <>
            <p className={styles.Subtle}>Here&apos;s how we know:</p>

            <p className={styles.Subtle}>
              <em>
                Of the {data.sparxStudents} {plural(data.sparxStudents, 'student', 'students')} who
                were using Sparx Reader last year, {data.movedStudents}{' '}
                {plural(data.sparxStudents, 'has', 'have')} been moved into their new classes in
                your MIS. This is a low percentage of students which suggests your new classes have
                not been set up.
              </em>
            </p>
          </>
        )}
      </div>
    </>
  );
};
