import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

const shadowColour = `188deg 13% 68%`;

const theme = extendTheme({
  fonts: {
    body: "'DM Sans', sans-serif",
    heading: "'DM Sans', sans-serif",
  },
  fontSizes: {}, // TODO: match Reader?
  shadows: {
    // From https://www.joshwcomeau.com/shadow-palette/
    elevationLow: `
    0px 0.7px 1px hsl(${shadowColour} / 0),
    0px 1.3px 2px hsl(${shadowColour} / 0.18),
    0px 2.5px 3.8px hsl(${shadowColour} / 0.37)`,
    elevationMedium: `
    0px 0.7px 1px hsl(${shadowColour} / 0),
    0px 3.1px 4.7px hsl(${shadowColour} / 0.15),
    0px 6.3px 9.5px hsl(${shadowColour} / 0.29)`,
    elevationHigh: `
    0px 0.7px 1px hsl(${shadowColour} / 0),
    0px 6.8px 10.2px hsl(${shadowColour} / 0.09),
    0.1px 12.1px 18.2px hsl(${shadowColour} / 0.18)
    0.1px 18.2px 27.3px hsl(${shadowColour} / 0.26)
    0.1px 26.7px 40.1px hsl(${shadowColour} / 0.35),
    0.2px 39px 58.5px hsl(${shadowColour} / 0.44),
    0.3px 56.8px 85.2px hsl(${shadowColour} / 0.53)`,
  },
  colors: {
    // These palettes are both the same and match the key that the shared staff manager e
    // expects for readerish colours instead.
    // TODO: Use a more generic name or better still, make each
    // application tell the context how to style staff manager. For now, I've
    // made this very naively from https://smart-swatch.netlify.app/var(--midnight-indigo-100)
    // (var(--midnight-indigo-100) is --secondary-blue)
    buttonTeal: {
      50: '#f0e9ff',
      100: '#cec0f4',
      200: '#ad98e8',
      300: '#8c6edd',
      400: '#6b45d3',
      500: '#512bb9',
      600: '#3f2291',
      700: '#2d1868',
      800: '#1b0d40',
      900: '#0a041b',
    },
    teal: {
      50: '#f0e9ff',
      100: '#cec0f4',
      200: '#ad98e8',
      300: '#8c6edd',
      400: '#6b45d3',
      500: '#512bb9',
      600: '#3f2291',
      700: '#2d1868',
      800: '#1b0d40',
      900: '#0a041b',
    },
    buttonBlue: {
      '500': '#3E51C4',
      '600': '#5062CA',
    },
    green: {
      '400': 'var(--spring-green-100)', // --turquoise
    },
  },
});

export const Chakra = ({ children }: PropsWithChildren) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
);
