// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/silver/v1/bookmarks.proto" (package "sparx.reading.silver.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
/**
 * @generated from protobuf message sparx.reading.silver.v1.Bookmark
 */
export interface Bookmark {
  /**
   * User that this bookmark is for.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * Unique token for this bookmark.
   *
   * @generated from protobuf field: bytes token = 2;
   */
  token: Uint8Array;
  /**
   * Timestamp when this bookmark was generated.
   *
   * @generated from protobuf field: google.protobuf.Timestamp generated_timestamp = 3;
   */
  generatedTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message sparx.reading.silver.v1.GenerateBookmarksRequest
 */
export interface GenerateBookmarksRequest {
  /**
   * School name that the student group is in.
   * In the format of "schools/{school_id}".
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * Student group that the bookmarks are being generated for.
   *
   * @generated from protobuf field: string group_id = 2;
   */
  groupId: string;
}
/**
 * @generated from protobuf message sparx.reading.silver.v1.GenerateBookmarksResponse
 */
export interface GenerateBookmarksResponse {
  /**
   * List of bookmarks that were generated.
   *
   * @generated from protobuf field: repeated sparx.reading.silver.v1.Bookmark bookmarks = 1;
   */
  bookmarks: Bookmark[];
}
/**
 * @generated from protobuf message sparx.reading.silver.v1.ListBookmarksRequest
 */
export interface ListBookmarksRequest {
  /**
   * School name that the bookmarks are being listed for.
   * In the format of "schools/{school_id}".
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
}
/**
 * @generated from protobuf message sparx.reading.silver.v1.ListBookmarksResponse
 */
export interface ListBookmarksResponse {
  /**
   * List of bookmarks that were found.
   *
   * @generated from protobuf field: repeated sparx.reading.silver.v1.Bookmark bookmarks = 1;
   */
  bookmarks: Bookmark[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Bookmark$Type extends MessageType<Bookmark> {
  constructor() {
    super('sparx.reading.silver.v1.Bookmark', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'token', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
      {
        no: 3,
        name: 'generated_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.silver.v1.Bookmark
 */
export const Bookmark = new Bookmark$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateBookmarksRequest$Type extends MessageType<GenerateBookmarksRequest> {
  constructor() {
    super('sparx.reading.silver.v1.GenerateBookmarksRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 2, name: 'group_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.silver.v1.GenerateBookmarksRequest
 */
export const GenerateBookmarksRequest = new GenerateBookmarksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateBookmarksResponse$Type extends MessageType<GenerateBookmarksResponse> {
  constructor() {
    super('sparx.reading.silver.v1.GenerateBookmarksResponse', [
      {
        no: 1,
        name: 'bookmarks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Bookmark,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.silver.v1.GenerateBookmarksResponse
 */
export const GenerateBookmarksResponse = new GenerateBookmarksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBookmarksRequest$Type extends MessageType<ListBookmarksRequest> {
  constructor() {
    super('sparx.reading.silver.v1.ListBookmarksRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.silver.v1.ListBookmarksRequest
 */
export const ListBookmarksRequest = new ListBookmarksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBookmarksResponse$Type extends MessageType<ListBookmarksResponse> {
  constructor() {
    super('sparx.reading.silver.v1.ListBookmarksResponse', [
      {
        no: 1,
        name: 'bookmarks',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Bookmark,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.silver.v1.ListBookmarksResponse
 */
export const ListBookmarksResponse = new ListBookmarksResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.silver.v1.Bookmarks
 */
export const Bookmarks = new ServiceType('sparx.reading.silver.v1.Bookmarks', [
  {
    name: 'GenerateBookmarks',
    options: {
      'sparx.api.auth': {
        action: 'write',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: GenerateBookmarksRequest,
    O: GenerateBookmarksResponse,
  },
  {
    name: 'ListBookmarks',
    options: {
      'sparx.api.auth': {
        action: 'read',
        resource: 'tp:student',
        domain: '{school_name}',
      },
    },
    I: ListBookmarksRequest,
    O: ListBookmarksResponse,
  },
]);
