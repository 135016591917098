import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationStar } from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import { useNavigate } from 'react-router-dom';
import { View } from 'views';
import { pathForView } from 'views/views';

import { INotificationWrapperProps, Notification } from './notification';
import styles from './notification.module.css';

interface Props extends INotificationWrapperProps {
  star: NotificationStar;
}

export const GoldStarNotification: React.FC<Props> = ({ notification }) => {
  const navigate = useNavigate();

  return (
    <Notification
      title={
        <>
          <FontAwesomeIcon icon={faStar} className={styles.GoldReaderStar} />
          You&apos;ve been awarded a Gold Reader star
        </>
      }
      notification={notification}
      onClick={() => navigate(pathForView(View.GoldReader))}
      clickMessage="Find out more"
    >
      Reach 5 to earn a Gold Reader pass
    </Notification>
  );
};
