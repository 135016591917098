// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/leaderboards/leaderboards/v1/leaderboards.proto" (package "sparx.leaderboards.leaderboards.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { UserDisplayData } from '../../userdisplay/v1/userdisplay';
import { Product } from '../../../types/product';
/**
 * @generated from protobuf message sparx.leaderboards.leaderboards.v1.GetLeaderboardRequest
 */
export interface GetLeaderboardRequest {
  /**
   * The school resource name
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The students resource name making the request
   *
   * @generated from protobuf field: string student_name = 2;
   */
  studentName: string;
  /**
   * The type of leaderboard we want
   *
   * @generated from protobuf field: sparx.leaderboards.leaderboards.v1.LeaderboardType leaderboard_type = 3;
   */
  leaderboardType: LeaderboardType;
  /**
   * The selected date range type
   *
   * @generated from protobuf field: sparx.leaderboards.leaderboards.v1.LeaderboardDateRange date_range = 4;
   */
  dateRange: LeaderboardDateRange;
  /**
   * The product to get the leaderboard for
   *
   * @generated from protobuf field: sparx.types.Product product = 5;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.leaderboards.leaderboards.v1.GetLeaderboardForTeacherRequest
 */
export interface GetLeaderboardForTeacherRequest {
  /**
   * The school resource name
   *
   * @generated from protobuf field: string school_name = 1;
   */
  schoolName: string;
  /**
   * The selected date range type
   *
   * @generated from protobuf field: sparx.leaderboards.leaderboards.v1.LeaderboardDateRange date_range = 2;
   */
  dateRange: LeaderboardDateRange;
  /**
   * The student ids
   *
   * @generated from protobuf field: repeated string student_ids = 3;
   */
  studentIds: string[];
  /**
   * The product to get the leaderboard for
   *
   * @generated from protobuf field: sparx.types.Product product = 4;
   */
  product: Product;
}
/**
 * @generated from protobuf message sparx.leaderboards.leaderboards.v1.LeaderboardUser
 */
export interface LeaderboardUser {
  /**
   * Is this the current user
   *
   * @generated from protobuf field: bool is_current_user = 1;
   */
  isCurrentUser: boolean;
  /**
   * The rank of this user in the leaderboard
   *
   * @generated from protobuf field: int32 rank = 2;
   */
  rank: number;
  /**
   * The xp gained during the leaderboard date range
   *
   * @generated from protobuf field: int32 xp = 3;
   */
  xp: number;
  /**
   * The user display data associated with this user
   *
   * @generated from protobuf field: sparx.leaderboards.userdisplay.v1.UserDisplayData user_display_data = 4;
   */
  userDisplayData?: UserDisplayData;
  /**
   * The first name of the user
   *
   * @generated from protobuf field: string first_name = 5;
   */
  firstName: string;
}
/**
 * @generated from protobuf message sparx.leaderboards.leaderboards.v1.GetLeaderboardResponse
 */
export interface GetLeaderboardResponse {
  /**
   * The users and their XP. This only returns those who are opted in to leaderboards
   *
   * @generated from protobuf field: repeated sparx.leaderboards.leaderboards.v1.LeaderboardUser users = 1;
   */
  users: LeaderboardUser[];
}
/**
 * Student is a subset of a smmsg.Student properties required for leaderboards
 *
 * @generated from protobuf message sparx.leaderboards.leaderboards.v1.Student
 */
export interface Student {
  /**
   * @generated from protobuf field: string student_id = 1;
   */
  studentId: string;
  /**
   * @generated from protobuf field: string first_name = 2;
   */
  firstName: string;
}
/**
 * @generated from protobuf message sparx.leaderboards.leaderboards.v1.Students
 */
export interface Students {
  /**
   * @generated from protobuf field: repeated sparx.leaderboards.leaderboards.v1.Student students = 1;
   */
  students: Student[];
}
/**
 * @generated from protobuf enum sparx.leaderboards.leaderboards.v1.LeaderboardType
 */
export enum LeaderboardType {
  /**
   * @generated from protobuf enum value: CLASS = 0;
   */
  CLASS = 0,
  /**
   * @generated from protobuf enum value: YEARGROUP = 1;
   */
  YEARGROUP = 1,
  /**
   * @generated from protobuf enum value: SCHOOL = 2;
   */
  SCHOOL = 2,
}
/**
 * @generated from protobuf enum sparx.leaderboards.leaderboards.v1.LeaderboardDateRange
 */
export enum LeaderboardDateRange {
  /**
   * @generated from protobuf enum value: THIS_WEEK = 0;
   */
  THIS_WEEK = 0,
  /**
   * @generated from protobuf enum value: LAST_WEEK = 1;
   */
  LAST_WEEK = 1,
  /**
   * @generated from protobuf enum value: THIS_MONTH = 2;
   */
  THIS_MONTH = 2,
  /**
   * @generated from protobuf enum value: LAST_MONTH = 3;
   */
  LAST_MONTH = 3,
  /**
   * @generated from protobuf enum value: THIS_YEAR = 4;
   */
  THIS_YEAR = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class GetLeaderboardRequest$Type extends MessageType<GetLeaderboardRequest> {
  constructor() {
    super('sparx.leaderboards.leaderboards.v1.GetLeaderboardRequest', [
      {
        no: 1,
        name: 'school_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 2,
        name: 'student_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'leaderboard_type',
        kind: 'enum',
        T: () => [
          'sparx.leaderboards.leaderboards.v1.LeaderboardType',
          LeaderboardType,
        ],
      },
      {
        no: 4,
        name: 'date_range',
        kind: 'enum',
        T: () => [
          'sparx.leaderboards.leaderboards.v1.LeaderboardDateRange',
          LeaderboardDateRange,
        ],
      },
      {
        no: 5,
        name: 'product',
        kind: 'enum',
        T: () => ['sparx.types.Product', Product],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.leaderboards.v1.GetLeaderboardRequest
 */
export const GetLeaderboardRequest = new GetLeaderboardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLeaderboardForTeacherRequest$Type extends MessageType<GetLeaderboardForTeacherRequest> {
  constructor() {
    super(
      'sparx.leaderboards.leaderboards.v1.GetLeaderboardForTeacherRequest',
      [
        {
          no: 1,
          name: 'school_name',
          kind: 'scalar',
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 2,
          name: 'date_range',
          kind: 'enum',
          T: () => [
            'sparx.leaderboards.leaderboards.v1.LeaderboardDateRange',
            LeaderboardDateRange,
          ],
        },
        {
          no: 3,
          name: 'student_ids',
          kind: 'scalar',
          repeat: 2 /*RepeatType.UNPACKED*/,
          T: 9 /*ScalarType.STRING*/,
        },
        {
          no: 4,
          name: 'product',
          kind: 'enum',
          T: () => ['sparx.types.Product', Product],
        },
      ],
    );
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.leaderboards.v1.GetLeaderboardForTeacherRequest
 */
export const GetLeaderboardForTeacherRequest =
  new GetLeaderboardForTeacherRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaderboardUser$Type extends MessageType<LeaderboardUser> {
  constructor() {
    super('sparx.leaderboards.leaderboards.v1.LeaderboardUser', [
      {
        no: 1,
        name: 'is_current_user',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 2, name: 'rank', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'xp', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'user_display_data',
        kind: 'message',
        T: () => UserDisplayData,
      },
      { no: 5, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.leaderboards.v1.LeaderboardUser
 */
export const LeaderboardUser = new LeaderboardUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLeaderboardResponse$Type extends MessageType<GetLeaderboardResponse> {
  constructor() {
    super('sparx.leaderboards.leaderboards.v1.GetLeaderboardResponse', [
      {
        no: 1,
        name: 'users',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => LeaderboardUser,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.leaderboards.v1.GetLeaderboardResponse
 */
export const GetLeaderboardResponse = new GetLeaderboardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Student$Type extends MessageType<Student> {
  constructor() {
    super('sparx.leaderboards.leaderboards.v1.Student', [
      { no: 1, name: 'student_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'first_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.leaderboards.v1.Student
 */
export const Student = new Student$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Students$Type extends MessageType<Students> {
  constructor() {
    super('sparx.leaderboards.leaderboards.v1.Students', [
      {
        no: 1,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.leaderboards.leaderboards.v1.Students
 */
export const Students = new Students$Type();
/**
 * @generated ServiceType for protobuf service sparx.leaderboards.leaderboards.v1.Leaderboards
 */
export const Leaderboards = new ServiceType(
  'sparx.leaderboards.leaderboards.v1.Leaderboards',
  [
    {
      name: 'GetLeaderboard',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'leaderboards:student',
          domain: 'leaderboards',
        },
      },
      I: GetLeaderboardRequest,
      O: GetLeaderboardResponse,
    },
    {
      name: 'GetLeaderboardForTeacher',
      options: {
        'sparx.api.auth': {
          action: 'read',
          resource: 'leaderboards:teacher',
          domain: 'leaderboards',
        },
      },
      I: GetLeaderboardForTeacherRequest,
      O: GetLeaderboardResponse,
    },
  ],
);
