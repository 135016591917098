// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/bonusquestions/v1/bonusquestions.proto" (package "sparx.reading.bonusquestions.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { BonusQuestions } from './bonusquestions';
import type { DismissBonusQuestionsResponse } from './bonusquestions';
import type { DismissBonusQuestionsRequest } from './bonusquestions';
import type { SubmitBonusQuestionEventResponse } from './bonusquestions';
import type { SubmitBonusQuestionEventRequest } from './bonusquestions';
import type { SubmitBonusQuestionResponse } from './bonusquestions';
import type { SubmitBonusQuestionRequest } from './bonusquestions';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetBonusQuestionsResponse } from './bonusquestions';
import type { GetBonusQuestionsRequest } from './bonusquestions';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.bonusquestions.v1.BonusQuestions
 */
export interface IBonusQuestionsClient {
  /**
   * @generated from protobuf rpc: GetBonusQuestions(sparx.reading.bonusquestions.v1.GetBonusQuestionsRequest) returns (sparx.reading.bonusquestions.v1.GetBonusQuestionsResponse);
   */
  getBonusQuestions(
    input: GetBonusQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBonusQuestionsRequest, GetBonusQuestionsResponse>;
  /**
   * @generated from protobuf rpc: SubmitBonusQuestion(sparx.reading.bonusquestions.v1.SubmitBonusQuestionRequest) returns (sparx.reading.bonusquestions.v1.SubmitBonusQuestionResponse);
   */
  submitBonusQuestion(
    input: SubmitBonusQuestionRequest,
    options?: RpcOptions,
  ): UnaryCall<SubmitBonusQuestionRequest, SubmitBonusQuestionResponse>;
  /**
   * @generated from protobuf rpc: SubmitBonusQuestionEvent(sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventRequest) returns (sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventResponse);
   */
  submitBonusQuestionEvent(
    input: SubmitBonusQuestionEventRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitBonusQuestionEventRequest,
    SubmitBonusQuestionEventResponse
  >;
  /**
   * @generated from protobuf rpc: DismissBonusQuestions(sparx.reading.bonusquestions.v1.DismissBonusQuestionsRequest) returns (sparx.reading.bonusquestions.v1.DismissBonusQuestionsResponse);
   */
  dismissBonusQuestions(
    input: DismissBonusQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<DismissBonusQuestionsRequest, DismissBonusQuestionsResponse>;
}
/**
 * @generated from protobuf service sparx.reading.bonusquestions.v1.BonusQuestions
 */
export class BonusQuestionsClient
  implements IBonusQuestionsClient, ServiceInfo
{
  typeName = BonusQuestions.typeName;
  methods = BonusQuestions.methods;
  options = BonusQuestions.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetBonusQuestions(sparx.reading.bonusquestions.v1.GetBonusQuestionsRequest) returns (sparx.reading.bonusquestions.v1.GetBonusQuestionsResponse);
   */
  getBonusQuestions(
    input: GetBonusQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBonusQuestionsRequest, GetBonusQuestionsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetBonusQuestionsRequest, GetBonusQuestionsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: SubmitBonusQuestion(sparx.reading.bonusquestions.v1.SubmitBonusQuestionRequest) returns (sparx.reading.bonusquestions.v1.SubmitBonusQuestionResponse);
   */
  submitBonusQuestion(
    input: SubmitBonusQuestionRequest,
    options?: RpcOptions,
  ): UnaryCall<SubmitBonusQuestionRequest, SubmitBonusQuestionResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitBonusQuestionRequest,
      SubmitBonusQuestionResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: SubmitBonusQuestionEvent(sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventRequest) returns (sparx.reading.bonusquestions.v1.SubmitBonusQuestionEventResponse);
   */
  submitBonusQuestionEvent(
    input: SubmitBonusQuestionEventRequest,
    options?: RpcOptions,
  ): UnaryCall<
    SubmitBonusQuestionEventRequest,
    SubmitBonusQuestionEventResponse
  > {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      SubmitBonusQuestionEventRequest,
      SubmitBonusQuestionEventResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: DismissBonusQuestions(sparx.reading.bonusquestions.v1.DismissBonusQuestionsRequest) returns (sparx.reading.bonusquestions.v1.DismissBonusQuestionsResponse);
   */
  dismissBonusQuestions(
    input: DismissBonusQuestionsRequest,
    options?: RpcOptions,
  ): UnaryCall<DismissBonusQuestionsRequest, DismissBonusQuestionsResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      DismissBonusQuestionsRequest,
      DismissBonusQuestionsResponse
    >('unary', this._transport, method, opt, input);
  }
}
