import { TooltipProvider } from '@radix-ui/react-tooltip';
import { createContext, PropsWithChildren } from 'react';

type Context = {
  themeStyles: Partial<Record<string, string>>;
};

export const ThemeContext = createContext<Context | undefined>(undefined);

interface ThemeProviderProps {
  themeStyles?: Partial<Record<string, string>>;
}

/**
 * Provides a ThemeContext which can be used to access global styles provided by the parent app
 */
export const ThemeProvider = ({ themeStyles, children }: PropsWithChildren<ThemeProviderProps>) => {
  const ctx = {
    themeStyles: themeStyles || {},
  };

  return (
    <ThemeContext.Provider value={ctx}>
      <TooltipProvider>{children}</TooltipProvider>
    </ThemeContext.Provider>
  );
};
