// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/monitoring/v1/monitoring.proto" (package "sparx.reading.monitoring.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Monitoring } from './monitoring';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { WatchStudentsResponse } from './monitoring';
import type { WatchStudentsRequest } from './monitoring';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.monitoring.v1.Monitoring
 */
export interface IMonitoringClient {
  /**
   * @generated from protobuf rpc: WatchStudents(sparx.reading.monitoring.v1.WatchStudentsRequest) returns (sparx.reading.monitoring.v1.WatchStudentsResponse);
   */
  watchStudents(
    input: WatchStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<WatchStudentsRequest, WatchStudentsResponse>;
}
/**
 * @generated from protobuf service sparx.reading.monitoring.v1.Monitoring
 */
export class MonitoringClient implements IMonitoringClient, ServiceInfo {
  typeName = Monitoring.typeName;
  methods = Monitoring.methods;
  options = Monitoring.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: WatchStudents(sparx.reading.monitoring.v1.WatchStudentsRequest) returns (sparx.reading.monitoring.v1.WatchStudentsResponse);
   */
  watchStudents(
    input: WatchStudentsRequest,
    options?: RpcOptions,
  ): UnaryCall<WatchStudentsRequest, WatchStudentsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<WatchStudentsRequest, WatchStudentsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
}
