import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import book from 'components/centeredview/book.png';
import styles from 'components/centeredview/centered-view.module.css';
import { Loading } from 'components/loading/loading';
import logo from 'components/sections/logo.svg';
import { FCWithChildren } from 'utils/props';
import { isTestEnv } from 'utils/settings';

interface ICenteredViewProps {
  loading?: boolean;
  complete?: boolean;
  completeChildren?: React.ReactNode;
  showTitle?: boolean;
  footer?: React.ReactNode;
}

export const CenteredView: FCWithChildren<ICenteredViewProps> = ({
  loading,
  complete,
  children,
  completeChildren,
  showTitle,
  footer,
}) => (
  <div className={styles.Container}>
    <div className={styles.BackgroundScroll} />
    <div className={styles.Content}>
      <img src={book} className={`${styles.Image} enter-down-1`} alt="" />
      {showTitle && (
        <div className={`${styles.Header} enter-down-2`}>
          <img src={logo} alt="Sparx Reader" className={styles.Logo} />
        </div>
      )}
      {isTestEnv() && <div className={styles.Test}>test</div>}
      <div className={`${styles.ContentBox} enter-down-3`}>
        {(loading || complete) && (
          <div className={`${styles.Overlay} enter-down-0`}>
            <Loading />
          </div>
        )}
        {complete && (
          <div className={`${styles.Overlay} ${styles.CompleteOverlay} enter-down-0`}>
            {completeChildren || <FontAwesomeIcon icon={faCheck} />}
          </div>
        )}
        {children}
      </div>
      {footer}
    </div>
  </div>
);

export const CenteredEmpty: FCWithChildren = ({ children }) => (
  <div className={styles.Container}>
    <div className={styles.BackgroundScroll} />
    <div className={styles.Content}>{children}</div>
  </div>
);
