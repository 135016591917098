import {
  StudentBook,
  StudentBook_SwapState,
} from '@sparx/api/apis/sparx/reading/content/v1/service';
import { MetadataAbridged } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import classNames from 'classnames';
import { useAlert, useClearAlert } from 'components/alert/alert';
import { StartBookAlert } from 'components/alert/start-book-alert';
import { BookImage } from 'components/book/book-image';
import { Button } from 'components/buttons';
import { MatureThemesWarning } from 'components/mature-themes-warning/MatureThemesWarning';
import { ProgressBar } from 'components/progress/progress-bar';
import { ShowMoreLess } from 'components/show-more/show-more';
import { useUpdateLibraryBook } from 'queries/library-books';
import { useGetBookTask } from 'queries/tasks';
import { makeItemList } from 'utils/names';

import styles from './new-book-item.module.css';

interface INewBookItemProps {
  book: MetadataAbridged;
  otherBooks: MetadataAbridged[];
  inline?: boolean;
  studentBook?: StudentBook;
  onConfirmAlternateAction?: (doOpenBook: () => void) => void;
}

export const NewBookItem = ({
  book,
  otherBooks,
  inline,
  studentBook,
  onConfirmAlternateAction,
}: INewBookItemProps) => {
  const updateLibraryBook = useUpdateLibraryBook();
  const isSwappedBook = studentBook !== undefined;
  const bookID = book?.name.replace('books/', '') || '';

  const doOpenBook = useGetBookTask(bookID, {
    requestReread: true,
    refetchCache: true,
  });
  const alert = useAlert();
  const close = useClearAlert();

  const startBook = (book: MetadataAbridged) =>
    alert(
      <StartBookAlert
        selectedBook={book}
        otherBooks={otherBooks}
        onConfirm={() => {
          close();
          if (isSwappedBook) {
            updateLibraryBook.mutate({
              studentBook: {
                ...studentBook,
                swapped: StudentBook_SwapState.STATE_KEPT,
              },
            });
          }
          doOpenBook.mutate();
        }}
        isSwappedBook={isSwappedBook}
      />,
    );

  if (!book) {
    return null;
  }

  const coverColour = book.coverImage?.prominentColours[0]
    ? '#' + book.coverImage?.prominentColours[0]
    : 'var(--sky-blue-80)';

  return (
    <div
      className={classNames(styles.Item, { [styles.Inline]: inline, [styles.NotInline]: !inline })}
      style={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ['--cover-colour' as any]: coverColour,
      }}
    >
      <h2 className={styles.Title}>{book.title}</h2>

      <span className={styles.Authors}>by {makeItemList(book.authors)}</span>

      <div className={styles.ItemContent}>
        <BookImage book={book} className={styles.Cover} />

        <div className={styles.Info}>
          <h2 className={styles.TitleWide}>{book.title}</h2>

          <span className={styles.AuthorsWide}>By {makeItemList(book.authors)}</span>

          {book.genres.length > 0 && (
            <ul className={styles.GenreList}>
              {book.genres.map(genre => (
                <li key={genre}>{genre}</li>
              ))}
            </ul>
          )}

          <div className={styles.MatureThemes}>
            <MatureThemesWarning book={book} />
          </div>
          <div className={classNames(styles.Description, styles.DescriptionWide)}>
            <ShowMoreLess
              content={book.description}
              maxLength={150}
              linkColour={'var(--midnight-indigo-100)'}
              isHTML
            />
          </div>
          <div className={classNames(styles.Description, styles.DescriptionNarrow)}>
            <ShowMoreLess
              content={book.description}
              maxLength={80}
              linkColour={'var(--slate-grey-100)'}
              isHTML
            />
          </div>
          <div className={styles.ButtonContainer}>
            <Button
              onClick={() =>
                onConfirmAlternateAction
                  ? onConfirmAlternateAction(() => doOpenBook.mutate())
                  : startBook(book)
              }
              analyticsEvent={{
                category: 'book choice',
                action: 'opened start book dialog',
                labels: { bookName: book?.name },
              }}
              data-test-id={`start-new-book-${book.name.replace('books/', '')}`}
            >
              {isSwappedBook ? 'Continue' : 'Start'} book
            </Button>
            {isSwappedBook && (
              <div className={styles.Progress}>
                <ProgressBar
                  percent={(studentBook?.progress || 0) * 100}
                  className={styles.BookProgressBar}
                  width="80%"
                />
                <span className={styles.ProgressLabel}>
                  {Math.floor((Math.min(studentBook?.progress, 1) || 0) * 100)}%
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
