import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StudentBook } from '@sparx/api/apis/sparx/reading/content/v1/service';
import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import {
  NotificationBook_Type,
  NotificationWithContext,
} from '@sparx/api/apis/sparx/reading/users/notifications/v1/notifications';
import classNames from 'classnames';
import { useAlert } from 'components/alert/alert';
import { BankRewardAlert } from 'components/alert/banking-alert';
import bank from 'components/alert/images/bank.svg';
import { BookImage } from 'components/book/book-image';
import { useBookNotifications } from 'queries/notifications';
import { useUserCanReadBook } from 'queries/session';
import React, { useMemo } from 'react';
import { FCWithChildren } from 'utils/props';
import styles from 'views/student/library-view/components/library-book.module.css';

interface ILibraryBookProps {
  book: LibraryBook;
  onClick: () => void;
  size?: 'small' | 'large';
}

export const LibraryBookItem: React.FC<ILibraryBookProps> = ({ book, onClick, size = 'small' }) => {
  const bookID = book.metadataAbridged?.name.replace('books/', '');
  const bookNotificationMap = useBookNotifications();
  const notifications = useMemo(() => {
    if (bookID) {
      return bookNotificationMap[bookID] || [];
    }
    return [];
  }, [bookNotificationMap, bookID]);
  const corner = getBookCornerForNotification(notifications, book.studentBook);

  const { allowedToRead } = useUserCanReadBook(book.metadataAbridged);

  const setAlert = useAlert();
  const onOpen = () => {
    for (const notification of notifications) {
      if (
        notification.notification?.notification.oneofKind === 'bankedReward' &&
        !notification.dismissed &&
        book.metadataAbridged
      ) {
        setAlert(
          <BankRewardAlert
            notification={notification}
            book={book.metadataAbridged}
            reward={notification.notification.notification.bankedReward}
          />,
        );
        return;
      }
    }
    onClick();
  };

  return (
    <div
      className={classNames(
        styles.Book,
        'enter-down-0',
        (book.studentBook?.atContentEnd || !allowedToRead || book.metadataAbridged?.blacklisted) &&
          styles.MissingContent,
      )}
      onClick={onOpen}
      data-test-id={`library-book-${book.metadataAbridged?.name.replace('books/', '')}`}
    >
      {book.metadataAbridged && (
        <BookImage
          book={book.metadataAbridged}
          className={classNames(styles.BookCover, {
            [styles.BookCoverSmall]: size === 'small',
            [styles.BookCoverLarge]: size === 'large',
          })}
        >
          {corner}
        </BookImage>
      )}
    </div>
  );
};

export const getBookCornerForNotification = (
  notifications?: NotificationWithContext[],
  studentBook?: StudentBook,
): JSX.Element => {
  for (const notification of notifications || []) {
    const rewardNotification = notification.notification?.notification.oneofKind === 'bankedReward';
    if (rewardNotification) {
      // Show the reward notification with priority over the other two
      return <BookCornerBanked />;
    }
  }

  if (studentBook && studentBook?.progress >= 1) {
    return (
      <BookCornerSmall>
        <FontAwesomeIcon icon={faTrophy} />
      </BookCornerSmall>
    );
  }

  for (const notification of notifications || []) {
    const bookNotificationType =
      notification.notification?.notification.oneofKind === 'book' &&
      notification.notification?.notification.book.type;
    if (bookNotificationType === NotificationBook_Type.NEW_BOOK) {
      return (
        <BookCorner>
          New
          <br />
          book
        </BookCorner>
      );
    } else if (bookNotificationType === NotificationBook_Type.NEW_CHAPTERS) {
      return (
        <BookCorner>
          New
          <br />
          chapters
        </BookCorner>
      );
    }
  }
  return <></>;
};

const BookCorner: FCWithChildren = ({ children }) => (
  <strong className={styles.BookCorner}>
    <span className={styles.BookCornerText}>{children}</span>
  </strong>
);

const BookCornerSmall: FCWithChildren = ({ children }) => (
  <strong className={`${styles.BookCorner} ${styles.BookCornerSmall}`}>
    <span className={styles.BookCornerText}>{children}</span>
  </strong>
);

const BookCornerBanked = () => (
  <strong className={`${styles.BookCorner} ${styles.BookCornerBanked}`}>
    <span className={styles.BookCornerText}>
      <img src={bank} alt="" />
    </span>
  </strong>
);

export const LibraryBookProgress: React.FC<{
  progress?: number;
  fullWidth?: boolean;
  tall?: boolean;
  fixedWidthProgress?: boolean;
  className?: string;
}> = ({ progress, fullWidth, tall, fixedWidthProgress, className }) =>
  progress !== undefined ? (
    <div
      className={classNames(styles.BookProgressBarBox, className, {
        [styles.BookProgressBarBoxFW]: fullWidth,
        [styles.BookProgressBarTall]: tall,
      })}
    >
      <LibraryBookProgressBar progress={progress} />
      <span
        className={classNames(
          styles.BookProgressBarValue,
          fixedWidthProgress && styles.BookProgressBarValueFW,
        )}
      >
        {Math.floor((Math.min(progress, 1) || 0) * 100)}%
      </span>
    </div>
  ) : null;

export const LibraryBookProgressBar: React.FC<{ progress?: number }> = ({ progress }) => (
  <span className={styles.BookProgressBarContainer}>
    <div className={styles.BookProgressBar} style={{ width: `${(progress || 0) * 100}%` }} />
  </span>
);
