import { OnboardingTask } from '@sparx/api/apis/sparx/reading/users/v1/onboarding';

import CompletedIcon from './icons/wizard_complete.svg';
import LockedIcon from './icons/wizard_locked.svg';
import CurrentIcon from './icons/wizard_selected.svg';

export const findCurrentTask = (tasks: OnboardingTask[]): OnboardingTask | undefined => {
  if (!tasks.length) return undefined;

  const currentTask = tasks.find(task => !task.isComplete);

  return currentTask ? currentTask : tasks[tasks.length - 1];
};

export const taskToIcon = (task: OnboardingTask, currentTask: OnboardingTask): string => {
  return task.isComplete
    ? CompletedIcon
    : task.step === currentTask.step
      ? CurrentIcon
      : LockedIcon;
};
