import { faStar as faOutlineStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faFilledStar, faStarHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

export const StarRating = ({ rating }: { rating: number }) => {
  const stars = useMemo(() => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (rating > i * 2 + 1) {
        stars.push(faFilledStar);
      } else if (rating > i * 2) {
        stars.push(faStarHalfStroke);
      } else {
        stars.push(faOutlineStar);
      }
    }
    return stars;
  }, [rating]);

  return (
    <>
      {stars.map((icon, i) => (
        <FontAwesomeIcon icon={icon} key={i} />
      ))}
    </>
  );
};
