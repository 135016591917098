import { useEnglishStudentGroups } from 'queries/management';
import { useHomeworkSetThisWeek } from 'queries/tasks';
import { useTrainingTab } from 'views/teacher/new-year-setup-view/tabs/training-tab/hooks';
import { TRAINING_MODULE_ESSENTIAL } from 'views/teacher/teacher-training-view/module-specs';

export const useGetDepartmentDashStats = () => {
  const trainingTab = useTrainingTab();
  const { data: classes, isLoading: classesLoading } = useEnglishStudentGroups();
  const { data: homeworkSetThisWeek, isLoading: isLoadingHomeworkSetThisWeek } =
    useHomeworkSetThisWeek();

  const numClassesTotal = classes?.length ?? 0;
  const numStaffTotal =
    trainingTab.trainingSummaries === undefined
      ? 0
      : Object.keys(trainingTab.trainingSummaries).length;
  const numStaffCompletedEssentialTraining = Object.entries(
    trainingTab.trainingSummaries ?? {},
  ).filter(([, summary]) =>
    summary?.modules.find(
      module => module.complete && module.spec.name === TRAINING_MODULE_ESSENTIAL,
    ),
  ).length;

  const classesWithHomeworkSetThisWeek = (classes || []).filter(cls =>
    homeworkSetThisWeek?.find(homework => homework.studentGroupId === cls.name.split('/')[3]),
  );

  return {
    loading: classesLoading || isLoadingHomeworkSetThisWeek,
    numClassesTotal,
    numStaffTotal,
    numStaffCompletedEssentialTraining,
    classesWithHomeworkSetThisWeek,
  };
};
