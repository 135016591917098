import mathsIcon from '../logos/appicons/maths-192x192.png';
import readerIcon from '../logos/appicons/reader-192x192.png';
import scienceIcon from '../logos/appicons/science-192x192.png';
import teachingIcon from '../logos/appicons/teaching-192x192.png';

export const productIcons = {
  SPARX_MATHS: mathsIcon,
  SPARX_READER: readerIcon,
  SPARX_SCIENCE: scienceIcon,
  SPARX_TEACHING: teachingIcon,
};
