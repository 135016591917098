import { GettingStartedWidget } from './getting-started-widget/getting-started-widget';
import styles from './home-view.module.css';
import { InterimWidget } from './interim-widget/interim-widget';
import { PostResetWidget } from './post-reset-widget/post-reset-widget';
import { PraiseStats } from './praise-stats/praise-stats';
import { TrainingDashboardWidget } from './training-dashboard-widget/training-dashboard-widget';

export const HomeViewTeacher = ({
  isInterim,
  showPostReset,
  showGettingStarted,
  trainingWidgetWidth,
}: {
  showPostReset: boolean;
  showGettingStarted: boolean;
  trainingWidgetWidth: 'narrow' | 'wide';
  isInterim: boolean;
}) => (
  <>
    <div className={styles.Widgets}>
      {isInterim && <InterimWidget />}
      {showPostReset && <PostResetWidget />}
      {showGettingStarted && <GettingStartedWidget />}
      <TrainingDashboardWidget width={trainingWidgetWidth} />
    </div>

    {<PraiseStats />}
  </>
);
