export enum View {
  Landing = 'landing-view',
  Task = 'task-view',
  Library = 'library-view',
  Explore = 'explore-view',
  ChooseNewBook = 'choose-new-book-view',
  AddGoldReaderBook = 'add-gold-reader-book-view',
  ClassLeague = 'league-view',
  EQM = 'eqm-view',
  EQMCustomBooks = 'eqm-custom-books-view',
  EQMCustomBookQuestions = 'eqm-custom-books-questions-view',
  Teacher = 'teacher-xp-view',
  HandIn = 'hand-in-view',
  VocabLanding = 'vocab-landing-view',
  Vocab = 'vocab-view',
  GoldReader = 'gold-reader-view',
  Onboarding = 'onboarding-view',
  TeacherCPD = 'teacher-cpd-view',
  TeacherReports = 'teacher-reports-view',
  WhatsNew = 'whats-new-view',
  TeacherLibrary = 'teacher-library-view',
  StaffManagerList = 'staff-manager-list',
  StaffKeyContacts = 'staff-key-contacts',
  TeacherTraining = 'teacher-training-view',
  MISSyncImport = 'mis-sync-import',
  NewYearSetup = 'new-year-setup',
  NewYearSetupImportClasses = 'new-year-setup/import-classes-and-students',
  SchoolSubscription = 'school-subscription',
  TeacherLeaderboard = 'teacher-leaderboard',
  Feedback = 'feedback-view',
  GettingStarted = 'getting-started',
  Dashboard = 'dashboard-view',
  DepartmentDashboard = 'department-dashboard-view',
}

export interface ViewOptions {
  path: string;
  backView?: View;
}

export const Views: Record<View, ViewOptions> = {
  [View.Landing]: {
    path: '/',
  },
  [View.Task]: {
    path: '/task',
    backView: View.Library,
  },
  [View.Library]: {
    path: '/library',
  },
  [View.GoldReader]: {
    path: '/library/goldreader',
  },
  [View.Explore]: {
    path: '/library/explore',
  },
  [View.ChooseNewBook]: {
    path: '/library/explore/new',
  },
  [View.AddGoldReaderBook]: {
    path: '/library/add',
  },
  [View.ClassLeague]: {
    path: '/library/league/class',
  },
  [View.VocabLanding]: {
    path: '/library/vocab',
  },
  [View.Vocab]: {
    path: '/vocab',
    backView: View.Library,
  },
  [View.Onboarding]: {
    path: '/onboarding',
  },

  // Admin view
  [View.EQM]: {
    path: '/admin',
  },
  [View.EQMCustomBooks]: {
    path: '/admin/custombooks',
  },
  [View.EQMCustomBookQuestions]: {
    path: '/admin/custombooks/questions',
  },

  // Teacher views
  [View.Teacher]: {
    path: '/teacher',
  },
  [View.HandIn]: {
    path: '/teacher/classes/homework',
  },
  [View.TeacherCPD]: {
    path: '/teacher/reading-matters',
  },
  [View.TeacherReports]: {
    path: '/teacher/reports',
  },
  [View.WhatsNew]: {
    path: '/teacher/whats-new',
  },
  [View.TeacherLibrary]: {
    path: '/teacher/library',
  },
  [View.StaffManagerList]: {
    path: '/teacher/staff',
  },
  [View.StaffKeyContacts]: {
    path: '/teacher/staff/keycontacts',
  },
  [View.TeacherTraining]: {
    path: '/teacher/training',
  },
  [View.MISSyncImport]: {
    path: '/teacher/mis-sync-import',
  },
  [View.NewYearSetup]: {
    path: '/teacher/new-year-setup',
  },
  [View.NewYearSetupImportClasses]: {
    path: '/teacher/new-year-setup/import-classes-and-students',
  },
  [View.SchoolSubscription]: {
    path: '/teacher/school-settings/subscription',
  },
  [View.TeacherLeaderboard]: {
    path: '/teacher/leaderboard',
  },
  [View.Feedback]: {
    path: '/teacher/feedback',
  },
  [View.GettingStarted]: {
    path: '/teacher/getting-started',
  },
  [View.Dashboard]: {
    path: '/teacher/dashboard',
  },
  [View.DepartmentDashboard]: {
    path: '/teacher/dashboard/department',
  },
};
