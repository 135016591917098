import { loadProductUrls, ProductWithUrl } from '@sparx/api/utils/products';
import { productIcons } from '@sparx/sparx-design/icons/ProductIcons';
import { useQuery } from '@tanstack/react-query';
import { requestAccessToken } from 'api';
import { useAlert } from 'components/alert/alert';
import { ConfirmAlert } from 'components/alert/confirm-alert';
import { Button } from 'components/buttons';
import { useClientEvent } from 'components/client-events/client-event-provider';
import styles from 'components/sections/header-menu.module.css';
import { useUser } from 'queries/session';

// List of paths that should not prompt the user whether they want to switch app
const noPromptPaths = [
  '',
  '/library',
  '/library/explore',
  '/library/league',
  '/library/vocab',
  '/library/goldreader',
];

const indexweaverApiURL = window?.settings?.indexweaverApiURL || 'https://sparxmaths.uk';

export const ProductSwitch = () => {
  const user = useUser();

  // Send page event with immediate: true to ensure it is sent immediately
  const { sendEvent: sendClientEvent } = useClientEvent();
  const sendEvent = (
    action: 'switched product' | 'prompt switch',
    product: string,
    labels?: Record<string, string>,
  ) =>
    sendClientEvent(
      { action, category: 'product switch', labels: { product, ...labels } },
      { immediate: true },
    );

  const { data } = useQuery({
    queryKey: ['school', 'products'],
    queryFn: async () =>
      loadProductUrls({
        indexweaverApiURL,
        schoolID: user?.schoolId || '',
        authorizationToken: await requestAccessToken(),
        product: 'SPARX_READER',
      }),
    enabled: Boolean(user?.schoolId),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const showAlert = useAlert();

  const clickProduct = (product: ProductWithUrl) => {
    // Trim any trailing slashes
    const pathname = location.pathname.replace(/\/$/, '');
    if (noPromptPaths.includes(pathname)) {
      // Redirect instantly
      sendEvent('switched product', product.key, { confirmed: 'no' });
      // eslint-disable-next-line react-compiler/react-compiler
      window.location.href = product.url;
    } else {
      // Open modal to prompt the user before redirecting
      sendEvent('prompt switch', product.key);
      showAlert(
        <ConfirmAlert
          title={`Switch to ${product?.name || 'another app'}`}
          confirmText={'Switch'}
          rejectText={'Cancel'}
          onConfirm={() => {
            sendEvent('switched product', product.key, { confirmed: 'yes' });
            window.location.href = product.url;
          }}
          // Manage events manually to ensure they are sent immediately
          confirmAnalytics={undefined}
          rejectAnalytics={undefined}
        >
          Are you sure you want to switch to {product?.name || 'another app'}?
        </ConfirmAlert>,
      );
    }
  };

  if (!data || data.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.ProductSwitchOptions}>
      <li className={styles.MenuDivider} />
      <li className={styles.MenuGroupHeader}>Switch to:</li>
      {data.map(product => (
        <li key={product.key}>
          <Button
            onClick={e => {
              e.preventDefault();
              clickProduct(product);
            }}
            className={styles.MenuButton}
            leftIcon={
              <div className={styles.ProductSwitchIcon}>
                <img alt="" width={20} src={productIcons[product.key]} />
              </div>
            }
            analyticsEvent={{ category: 'profile dropdown', action: "clicked what's new" }}
          >
            {product.name}
          </Button>
        </li>
      ))}
    </div>
  );
};
