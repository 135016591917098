import { ErrorMessage } from 'components/errors/error-message';
import { Loading } from 'components/loading/loading';
import { useTeacherSchoolLeaderboard } from 'queries/leagueTables';
import { useTeacherStudentsWithExperience } from 'queries/management';
import styles from 'views/student/league-view/league-view.module.css';

import { LeagueTableRow } from '../components/row/league-table-row';
import { AllUsersOptedOut } from './all-users-opted-out';
import { findStudent } from './helpers';
import { LeaderboardProps } from './leaderboards';

export const TeacherSchoolLeaderboard = ({ dateRange }: LeaderboardProps) => {
  const { data, isLoading, isError, isFetched } = useTeacherSchoolLeaderboard(dateRange);
  const { data: students, isLoading: isLoadingStudents } = useTeacherStudentsWithExperience();

  if (isError) {
    return <ErrorMessage message="Failed to load leaderboard" />;
  }

  if (isLoading || isLoadingStudents || !students) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loading text="Loading leaderboard..." />
      </div>
    );
  }

  // If the query is disabled, there are no students in the school
  if (!isFetched && !isLoading) {
    return <>There are no students in this school</>;
  }

  return (
    <AllUsersOptedOut users={data?.users}>
      <div className={styles.Block}>
        {data.users.map((leaderboardUser, i) => (
          <LeagueTableRow
            key={i}
            student={findStudent(students, leaderboardUser)}
            leaderboardUser={leaderboardUser}
          />
        ))}
      </div>
    </AllUsersOptedOut>
  );
};
