import useLocalStorageState from 'use-local-storage-state';
import { FCWithChildren } from 'utils/props';

import { SparxStaffFeaturesContext } from './sparx-staff-features-context';

const STAFF_FEATURES_KEY = 'sparx-staff-features';

/**
 * SparxStaffFeaturesProvider provides the Sparx staff features context to all child elements (they
 * can use the useSparxStaffFeatures hook)
 * @param children
 * @constructor
 */
export const SparxStaffFeaturesProvider: FCWithChildren = ({ children }) => {
  const [sparxStaffFeaturesEnabled, setSparxStaffFeaturesEnabled] = useLocalStorageState(
    STAFF_FEATURES_KEY,
    {
      defaultValue: false,
    },
  );

  const toggleSparxStaffFeaturesEnabled = () =>
    setSparxStaffFeaturesEnabled(!sparxStaffFeaturesEnabled);
  return (
    <SparxStaffFeaturesContext.Provider
      value={{
        sparxStaffFeaturesEnabled,
        toggleSparxStaffFeaturesEnabled,
      }}
    >
      {children}
    </SparxStaffFeaturesContext.Provider>
  );
};
