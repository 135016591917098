import blocklistFile from './blocklist.txt?raw';

const blocklist = new Set<string>();

// blocklist file contains list of base64 encoded strings of bad words
// each line is a single word
for (const line of blocklistFile.split('\n')) {
  const word = line.trim();
  if (word) blocklist.add(word);
}

// btoa the word and check if it's in the blocklist
export const isWordBlocked = (word: string) =>
  blocklist.has(btoa(unescape(encodeURIComponent(word.toLowerCase().trim()))));
