import { ErrorMessage } from 'components/errors/error-message';
import { Loading } from 'components/loading/loading';
import { useUserOnboardingProgress } from 'queries/onboarding';
import { useUser } from 'queries/session';
import { useExtraPackages, useLockedInPackage } from 'queries/tasks';

import { ExtraPackage } from '../library-view/subviews/extra-package';
import styles from './onboarding-view.module.css';
import { Wizard } from './wizard/wizard';

export const OnboardingView = () => {
  const user = useUser();
  const {
    data: onboardingProgress,
    isLoading: onboardingProgressIsLoading,
    isError: onboardingProgressIsError,
  } = useUserOnboardingProgress();
  const {
    data: extraPackages,
    isLoading: extraPackagesIsLoading,
    isError: extraPackagesIsError,
  } = useExtraPackages();
  const { data: isLockedInPackage } = useLockedInPackage();

  if (onboardingProgressIsError || extraPackagesIsError) {
    return (
      <ErrorMessage message="Sorry, something has gone wrong. Please try refreshing the page." />
    );
  }
  if (onboardingProgressIsLoading || extraPackagesIsLoading || !user) {
    return <Loading />;
  }
  const hasReadingTestSet = extraPackages.length && isLockedInPackage;

  return (
    <>
      <div className={styles.Background}></div>
      <div className={styles.ViewContainer}>
        <div className={styles.Container}>
          <div>
            <h1 className={styles.Title}>👋 Hi {user.firstName}! Welcome to Sparx Reader</h1>
            <h2 className={styles.Subtitle}>
              {hasReadingTestSet
                ? 'Your teacher has set a reading assessment.'
                : "Let's get you started with a few easy steps..."}
            </h2>

            {hasReadingTestSet ? (
              <>
                {extraPackages.map(p => (
                  <ExtraPackage key={p.packageId} pkg={p} />
                ))}
              </>
            ) : (
              <Wizard tasks={onboardingProgress.tasks} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
