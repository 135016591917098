import { atom, useAtom } from 'jotai';
import { useUser } from 'queries/session';
import { useEffect } from 'react';
import { useGettingStartedHomepageBanner } from 'views/teacher/new-year-setup-view/hooks';

const dismissedGettingStarted = atom(false);

/** useShowGettingStarted is a hook for managing the dismissal and loading
 * state of the getting started widget. */
export const useShowGettingStarted = () => {
  const { isGettingStarted, isLoading, isError } = useGettingStartedHomepageBanner();
  const user = useUser();
  const localStorageKey = `dismiss-getting-started-widget-${user?.userId}`;
  const [isDismissed, setIsDismissed] = useAtom(dismissedGettingStarted);

  useEffect(() => {
    if (localStorage.getItem(localStorageKey) === 'true') {
      setIsDismissed(true);
    }
  }, [localStorageKey, setIsDismissed]);

  const dismiss = () => {
    localStorage.setItem(localStorageKey, 'true');
    setIsDismissed(true);
  };

  return {
    showGettingStarted: !(isDismissed || !user || !isGettingStarted || isError),
    dismiss,
    isLoading,
  };
};
