import { DepartmentWidgets } from './department-widgets/department-widgets';
import { GettingStartedWidget } from './getting-started-widget/getting-started-widget';
import styles from './home-view.module.css';
import { InterimWidget } from './interim-widget/interim-widget';
import { PostResetWidget } from './post-reset-widget/post-reset-widget';

export const HomeViewDepartment = ({
  isInterim,
  showPostReset,
  showGettingStarted,
}: {
  showPostReset: boolean;
  showGettingStarted: boolean;
  isInterim: boolean;
}) => (
  <>
    <div className={styles.Widgets}>
      {isInterim && <InterimWidget />}
      {showPostReset && <PostResetWidget />}
      {showGettingStarted && <GettingStartedWidget />}
    </div>

    <DepartmentWidgets />
  </>
);
