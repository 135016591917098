import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CenteredView } from 'components/centeredview/centered-view';
import { Copyright } from 'components/copyright/copyright';

import styles from './maintenance-mode.module.css';

export interface IMaintenanceModeSettings {
  enabled: boolean;
  title?: '';
  reason?: '';
  additional?: '';
}

export const MaintenanceView = ({ settings }: { settings: IMaintenanceModeSettings }) => (
  <CenteredView showTitle={true} footer={<Copyright />}>
    <FontAwesomeIcon icon={faWrench} className={styles.Wrench} />
    <h2>{settings.title || 'Maintenance mode'}</h2>
    <p className={styles.Info}>
      We&apos;re sorry, <strong>Sparx Reader</strong> is currently{' '}
      {settings.reason || 'undergoing scheduled maintenance'}.{' '}
      {settings.additional || 'Please check back later.'}
    </p>
  </CenteredView>
);
