import { useIsUserOnboarded } from 'queries/session';
import { useEffect } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { View } from 'views';
import { pathForView } from 'views/views';

type Props = {
  children: React.ReactNode;
};

export const WithStudentRedirects = ({ children }: Props) => {
  const navigate = useNavigate();
  const onboarded = useIsUserOnboarded();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!onboarded)
      navigate(
        {
          pathname: pathForView(View.Onboarding),
          search: createSearchParams(searchParams).toString(),
        },
        { replace: true },
      );
  }, [navigate, onboarded, searchParams]);

  return children;
};
