import { Homework } from '@sparx/api/apis/sparx/reading/tasks/v1/homework';
import { LibraryBook } from '@sparx/api/apis/sparx/reading/users/librarybooks/v1/librarybooks';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import classnames from 'classnames';
import { BookImage } from 'components/book/book-image';
import { Button } from 'components/buttons/button';
import { HomeBox } from 'components/home-box/home-box';
import { Link } from 'components/link';
import { useStartBookDialog } from 'hooks/start-book-dialog';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { timestampToMoment } from 'utils/time';
import { View } from 'views';
import { pathForView } from 'views/views';

import {
  GoldReaderProgressBar,
  GoldReaderStars,
} from '../../library-view/components/homework-components';
import completePass from '../img/complete-pass.png';
import pass from '../img/gold-reader-pass.png';
import styles from './gold-reader-progress.module.css';

interface IGoldReaderProgressProps {
  isFetched: boolean;
  stars: number;
  progress: number;
  passExpiry: Timestamp | undefined;
  latestEbook: LibraryBook | undefined;
  currentHomework: Homework | undefined;
}

export const GoldReaderProgress = ({
  isFetched,
  stars,
  progress,
  passExpiry,
  latestEbook,
  currentHomework,
}: IGoldReaderProgressProps) => {
  if (!isFetched) {
    return null;
  }

  return (
    <HomeBox variant={'normal'} lgw={100}>
      <div className={styles.Main}>
        <div className={styles.PassWrapper}>
          <GoldReaderPass passExpiry={passExpiry} />
        </div>
        <div className={styles.Wide}>
          <GoldReaderNextPass
            progress={progress}
            stars={stars}
            latestEbook={latestEbook}
            passExpiry={passExpiry}
            currentHomework={currentHomework}
          />
        </div>
      </div>
    </HomeBox>
  );
};

const getDaysRemaining = (
  passExpiry: Timestamp | undefined,
): { daysLeftText: string; daysRemaining: number } => {
  const expiryMoment = timestampToMoment(passExpiry).utc();
  const daysRemaining = expiryMoment.diff(moment.utc(), 'days');
  let daysLeftText = 'Final day';
  if (daysRemaining === 1) {
    daysLeftText = '1 day';
  } else if (daysRemaining > 1) {
    daysLeftText = `${daysRemaining} days`;
  }
  return { daysLeftText, daysRemaining };
};

interface IGoldReaderPassProps {
  passExpiry: Timestamp | undefined;
  showGoldReaderLink?: boolean;
}

export const GoldReaderPass = ({
  passExpiry,
  showGoldReaderLink = false,
}: IGoldReaderPassProps) => {
  const { daysRemaining, daysLeftText } = getDaysRemaining(passExpiry);
  return (
    <div className={styles.BoxContent}>
      <h3 className={styles.TitleGold}>You&apos;re a Gold Reader!</h3>
      <div className={styles.Image} style={{ backgroundImage: `url(${pass})` }}>
        <div className={classnames(styles.PassText, { [styles.EndingSoon]: daysRemaining < 3 })}>
          {daysRemaining < 1 ? daysLeftText : `${daysLeftText} left`}
        </div>
      </div>
      <p className={styles.FinalPara}>
        Your Gold Reader pass expires on {timestampToMoment(passExpiry).format('Do MMMM')}.
      </p>
      {showGoldReaderLink && (
        <Link
          className={styles.BookButtonsLink}
          to={pathForView(View.GoldReader)}
          analyticsEvent={{
            category: 'gold reader',
            action: 'clicked go to gold reader',
          }}
        >
          Go to Gold Reader
        </Link>
      )}
    </div>
  );
};

interface IGoldReaderNextPassProps {
  latestEbook: LibraryBook | undefined;
  progress: number;
  stars: number;
  passExpiry: Timestamp | undefined;
  currentHomework: Homework | undefined;
}

export const GoldReaderNextPass = ({
  latestEbook,
  progress,
  stars,
  passExpiry,
  currentHomework,
}: IGoldReaderNextPassProps) => {
  const navigate = useNavigate();
  const [, openStartBookDialog] = useStartBookDialog();
  const { daysRemaining, daysLeftText } = getDaysRemaining(passExpiry);

  if (
    currentHomework !== undefined &&
    currentHomework.experience < currentHomework.targetExperience
  ) {
    return (
      <div className={styles.BoxContentCentred}>
        <p>
          You can work towards another Gold Reader pass by earning SRP after you&apos;ve completed
          your active homework task.
        </p>
        {latestEbook ? (
          <>
            <Button
              size="small"
              color="danger"
              variant="secondary"
              onClick={() => latestEbook && openStartBookDialog(latestEbook.metadataAbridged)}
              analyticsEvent={{ category: 'gold reader', action: 'clicked continue last ebook' }}
            >
              Continue last ebook
            </Button>
            <Link
              to={pathForView(View.Explore)}
              className={classnames(styles.BookButtonsLink, styles.MarginTop)}
              analyticsEvent={{ category: 'gold reader', action: 'clicked go to library' }}
            >
              Go to library
            </Link>
          </>
        ) : (
          <Button
            size="small"
            color="danger"
            variant="secondary"
            onClick={() => navigate(pathForView(View.Explore))}
            analyticsEvent={{ category: 'gold reader', action: 'clicked choose an ebook' }}
          >
            Choose an ebook
          </Button>
        )}
      </div>
    );
  }
  if (stars === 5) {
    return (
      <div className={styles.BoxContent}>
        <h2 className={styles.Title}>Your next pass</h2>
        <div className={styles.BodyText}>
          You&apos;ve earned another Gold Reader pass! It will start when your current pass expires{' '}
          {daysRemaining < 1 ? 'tomorrow' : `in ${daysLeftText}`}.
        </div>
        <div className={styles.CompleteImage} style={{ backgroundImage: `url(${completePass})` }}>
          <div className={styles.CompletePassText}>5-week pass</div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.BoxContent}>
      <h2 className={styles.Title}>Earn your next pass</h2>
      <div className={styles.BodyText}>
        To earn your next pass, reach 5 stars by reading ebooks.
      </div>
      <GoldReaderProgressBar progress={progress} className={styles.Bar} width="100%" />
      <div className={styles.Stars}>
        <GoldReaderStars count={stars} startCount={stars} size="small" />
      </div>
      {latestEbook && latestEbook.metadataAbridged && (
        <div className={styles.Book}>
          <div className={styles.BookImageButton}>
            <BookImage book={latestEbook.metadataAbridged} className={styles.BookImage} />
          </div>
          <div className={styles.BookMeta}>
            <div className={styles.MetaLabel}>Your latest ebook:</div>
            <div className={styles.BookTitle}>{latestEbook.metadataAbridged.title}</div>
            <div className={styles.BookAuthors}>
              {latestEbook.metadataAbridged.authors.join(', ')}
            </div>
            <div className={styles.BookButtons}>
              <Button
                size="small"
                color="danger"
                onClick={() => latestEbook && openStartBookDialog(latestEbook.metadataAbridged)}
                className={styles.KeepReadingButton}
                analyticsEvent={{ category: 'gold reader', action: 'clicked keep reading' }}
              >
                Keep reading
              </Button>
              <Link
                to={pathForView(View.Explore)}
                className={styles.BookButtonsLink}
                analyticsEvent={{
                  category: 'gold reader',
                  action: 'clicked change book',
                }}
              >
                Change book
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
